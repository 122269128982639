import React, { Component,useState,useRef } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom'
import Backdrop from '../backdrop/backdrop'
import { IconButton } from '@material-ui/core'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from 'react-select'
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { InputGroupAddon, Input, InputGroupText, CardHeader, CardTitle, CardText } from 'reactstrap';
import { toast } from 'react-toastify';
import fire from '../../Configs/firebase'
import { Tab, Row, Col, Form, Dropdown, ProgressBar, Tabs, Image, InputGroup, Button, DropdownButton, Breadcrumb, Table, ButtonGroup, Container, Accordion, Card, Navbar } from 'react-bootstrap'

toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
});

const SearchLoosePackTableHeader = () => {
    return (
        <thead className="thead-light">
            <tr>
                <th>Order Date</th>
                <th>OrderNumber</th>
                <th>Bag ID</th>
                <th>DeliveryPlanDate</th>
                <th>No of Bags</th>
                <th>Weight of Bags</th>
                <th>Short Item</th>
                <th>Packed By</th>
            </tr>
        </thead>
    );
}

const SearchLoosePackTableBody = props => {
    let rows;
    if (props.loosePackOrdersData) {
        rows = props.loosePackOrdersData.map((row, index) => {
          let totalweight = parseFloat(row.weight1_bags)+parseFloat(row.weight2_bags)+parseFloat(row.weight3_bags)+parseFloat(row.weight4_bags)+parseFloat(row.weight5_bags)+parseFloat(row.weight6_bags)+parseFloat(row.weight7_bags)+parseFloat(row.weight8_bags)+parseFloat(row.weight9_bags)+parseFloat(row.weight10_bags);
            return (
                <tr key={index}>
                    <td>{row.order_date}</td>
                    <td>{row.order_no}</td>
                    <td>{row.bagid}</td>
                    <td>{row.delivery_plan_date}</td>
                    <td>{row.no_bags}</td>
                    <td>{!isNaN(totalweight)?totalweight:""}</td>
                    <td>{row.short_item}</td>
                    <td>{row.packed_by}</td>
                </tr>
            );
        });
    } else {
        rows = [];
    }
    return (<><tbody>{rows}</tbody></>);
}

const SearchLoosePackTable = (props) => {

    const { loosePackOrdersData } = props;

    return (
        <table className="table table-striped">
            <SearchLoosePackTableHeader />
            <SearchLoosePackTableBody loosePackOrdersData={loosePackOrdersData}  />
        </table>
    );
}

export default SearchLoosePackTable
