import React from "react";
import NavBar from "../Navigation bar/Navbar";
import {
  Row,
  Col,
  Form,
  Button,
  Container,
  Modal,
  Alert,
  Spinner,
  Dropdown,
} from "react-bootstrap";

import cashIcon from "../../assests/images/money.jpeg";
import chequeIcon from "../../assests/images/cheque.jpeg";
import paymentPendingIcon from "../../assests/images/payment-method.png";
import superKreditIcon from "../../assests/images/receipt.png";
import scratchIcon from "../../assests/images/scratch.png";
import rupifiIcon from "../../assests/images/receipt.png";

import { Link } from "react-router-dom";

function PaymentManagement() {
  return (
    <div
      style={{
        background:
          "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%)",
        height: "100vh",
      }}
    >
      <NavBar />
      <br />
      <br /> <br /> <br />
      <div style={{ margin: 20 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "30px",
          }}
        >
          <div
            class="center-box"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              alignSelf: "center",
              backgroundColor: "#F9F9F9",
              opacity: "80%",
              width: "500px",
              margin: "20px",
              borderRadius: "10px",
            }}
          >
            <br></br>
            <h1>Payment Management</h1>
            <br></br>
            <Link to="/Cheque-Management">
              <Button
                variant="outline-secondary"
                style={{
                  color: "secondary",
                  width: "250px",
                  backgroundImage: `url(${chequeIcon})`,
                  backgroundSize: "40px 40px",
                  backgroundRepeat: "no-repeat",
                }}
              >
                Cheque Management
              </Button>
            </Link>

            <br></br>
            <Link to="/cash-management">
              <Button
                variant="outline-secondary"
                style={{
                  color: "secondary",
                  width: "250px",
                  backgroundImage: `url(${cashIcon})`,
                  backgroundSize: "40px 40px",
                  backgroundRepeat: "no-repeat",
                }}
              >
                Cash Management
              </Button>
            </Link>

            <br></br>
            <Link to="/payment-pending">
              <Button
                variant="outline-secondary"
                style={{
                  color: "secondary",
                  width: "250px",
                  backgroundImage: `url(${paymentPendingIcon})`,
                  backgroundSize: "40px 40px",
                  backgroundRepeat: "no-repeat",
                }}
              >
                Payment Pending
              </Button>
            </Link>

            <br></br>
            <Link to="/Super-Kredit">
              <Button
                variant="outline-secondary"
                style={{
                  color: "secondary",
                  width: "250px",
                  backgroundImage: `url(${superKreditIcon})`,
                  backgroundSize: "40px 40px",
                  backgroundRepeat: "no-repeat",
                }}
              >
                Super Kredit
              </Button>
            </Link>

            <br></br>
            <Link to="/scratch-card-transactions">
              <Button
                variant="outline-secondary"
                style={{
                  color: "secondary",
                  width: "250px",
                  backgroundImage: `url(${scratchIcon})`,
                  backgroundSize: "40px 40px",
                  backgroundRepeat: "no-repeat",
                }}
              >
                Scratch Card
              </Button>
            </Link>

            <br></br>
            <Link to="/Rupifi">
              <Button
                variant="outline-secondary"
                style={{
                  color: "secondary",
                  width: "250px",
                  backgroundImage: `url(${rupifiIcon})`,
                  backgroundSize: "40px 40px",
                  backgroundRepeat: "no-repeat",
                }}
              >
                Rupifi Management
              </Button>
            </Link>

            <br></br>
            <br></br>
            <br></br>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentManagement;
