import React, { useState, useEffect } from "react";
import { Button, Modal, Row, Col } from "react-bootstrap";
import moment from "moment";
import { service_url, token } from "../../Configs/mysqlconfig";
import { toast } from "react-toastify";
import Select from "react-select";
import TransitItemsTable from "./Tables/TransitItemsTable";
import fire from "../../Configs/firebase";

import {
  greenHighlightColor,
  sortByTypeCategoryItemId,
} from "../Inventory/common/constants";

import Backdrop from "../backdrop/backdrop";
import ExportToExcel from "../InventoryManagementExport/TransitStockTransferExport/ExportToExcel";
import ShowDiscrepancyCheckbox from "../Inventory/common/ShowDiscrepancyCheckbox";
import { Refresh } from "@material-ui/icons";
const GLOBAL_PARENT_WAREHOUSE = "Bhiwandi";
const userCity = sessionStorage.getItem("userCity") || "";
const userType = sessionStorage.getItem("userType") || "";

function StockMovementItemsModal({ transitID, ...props }) {
  const [routeDropDownValues, setRouteDropDownValues] = useState([]);
  const [currentSelectedRoute, setCurrentSelectedRoute] = useState("");

  const [overallRouteSummaryDetailsCopy, setOverallRouteSummaryDetailsCopy] =
    useState({});
  const [overallRouteSummaryDetails, setOverallRouteSummaryDetails] = useState(
    {}
  );
  const [isApprovedQtyFieldsShown, setIsApprovedQtyFieldsShown] =
    useState(false);

  const [isApprovedQtyFieldsEditable, setIsApprovedQtyFieldsEditable] =
    useState(false);

  const [selectedItemId, setSelectedItemId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showDiscrepancyItemsChecked, setShowDiscrepancyItemsChecked] =
    useState(false);

  const [productMasterDataRaw, setProductMasterDataRaw] = useState({});
  const [addTransitItemDropdownList, setAddTransitItemDropdownList] = useState(
    []
  );

  const refetchData = props.refetchData;
  const warehouse = props.warehouse;

  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });

  const setApprovedFieldsStatus = (_route, _overallRouteSummaryDetails) => {
    let _isApprovedFieldsShown = false;
    let _isApprovedFieldsEditable = true;

    for (let routeItems of _overallRouteSummaryDetails[_route]) {
      if (routeItems.received_qty != null && routeItems.received_qty >= 0) {
        _isApprovedFieldsShown = true;
      }
      if (routeItems.approved_qty != null && routeItems.approved_qty >= 0) {
        _isApprovedFieldsEditable = false;
      }
    }

    setIsApprovedQtyFieldsShown(_isApprovedFieldsShown);
    setIsApprovedQtyFieldsEditable(_isApprovedFieldsEditable);
  };

  const getApprovedFieldsStatus = (_route, _overallRouteSummaryDetails) => {
    let _isApprovedFieldsShown = false;
    let _isApprovedFieldsEditable = true;

    for (let routeItems of _overallRouteSummaryDetails[_route]) {
      if (routeItems.received_qty != null && routeItems.received_qty >= 0) {
        _isApprovedFieldsShown = true;
      }
      if (routeItems.approved_qty != null && routeItems.approved_qty >= 0) {
        _isApprovedFieldsEditable = false;
      }
    }

    // if (!_isApprovedFieldsEditable && userType === "Admin") {
    //   _isApprovedFieldsEditable = true;
    // }

    return [_isApprovedFieldsShown, _isApprovedFieldsEditable];
  };

  const isBhiwandiUser = () => {
    if (
      (userType === "Operation" && userCity === "") ||
      (userType === "Operation" && userCity === "Bhiwandi") ||
      userCity === "Bhiwandi"
    ) {
      return true;
    }
    return false;
  };

  const fetchProductDataFromDB = () => {
    return new Promise((resolve, reject) => {
      let product_base_ref = fire.database().ref("Product_Master_New/DG");
      product_base_ref.once(
        "value",
        (snapshot) => {
          let data = snapshot.val();
          resolve(data);
        },
        (err) => {
          reject("error occured while fetching data from firebase");
        }
      );
    });
  };

  const generateProductMasterData = async () => {
    const products = await fetchProductDataFromDB();
    // console.log("debug check", products);
    generateTransitItemsList(products);
    setProductMasterDataRaw(products);
  };

  const generateTransitItemsList = (product_master) => {
    if (!product_master || Object.keys(product_master).length === 0) {
      return console.log("Function did not receive proper arguements");
    }
    const itemList = [];
    Object.keys(product_master).forEach((key, index) => {
      const product = product_master[key];
      const item_id = product.item_id;
      const item_description = product.item_description1 || "";
      const brand = product.brand || "";
      const item = {
        value: item_id,
        label: `${item_id}-${brand}-${item_description}`,
      };
      itemList.push(item);
    });

    setAddTransitItemDropdownList(itemList);
  };

  const itemAlreadyInList = (item_id) => {
    const existingItems = overallRouteSummaryDetails[currentSelectedRoute];
    let itemAlreadyPresent = false;
    existingItems.forEach((item, index) => {
      if (Number(item_id) === Number(item.item_id)) {
        itemAlreadyPresent = true;
      }
    });

    return itemAlreadyPresent;
  };

  const init = () => {
    setIsLoading(true);
    const url = `${service_url}/api/stock-movements-planned-transit-items?transit_id=${transitID}&token=${token}`;

    fetch(url, {
      headers: { "x-access-token": sessionStorage.getItem("x-access-token") },
    })
      .then((res) => res.json())
      .then((result) => {
        let itemsData = result.data || [];
        console.log("itemsData", itemsData);
        let _overallRouteSummDetails = {};

        let _routeDropdownValues = [];

        for (let routeItems of itemsData) {
          const route = "stockMove";
          routeItems.rec_remarks = routeItems.received_remarks;
          routeItems.app_remarks = routeItems.approved_remarks;

          routeItems.item_modified_qty = routeItems.shipped_qty;

          if (
            routeItems.received_qty != null &&
            routeItems.item_modified_qty &&
            routeItems.received_qty != routeItems.item_modified_qty
          ) {
            routeItems.showApprovedQtyField = true;
          } else {
            routeItems.showApprovedQtyField = false;
          }

          if (!_overallRouteSummDetails[route]) {
            _overallRouteSummDetails[route] = [];
          }

          console.log("pushing", route, routeItems);
          _overallRouteSummDetails[route].push(routeItems);
        }

        console.log(
          "setting _overallRouteSumm Details",
          _overallRouteSummDetails
        );

        Object.keys(_overallRouteSummDetails).forEach((key, index) => {
          _overallRouteSummDetails[key] = sortByTypeCategoryItemId(
            _overallRouteSummDetails[key]
          );
        });

        console.log(
          "setting _overallRouteSumm Details",
          _overallRouteSummDetails
        );

        _routeDropdownValues = Object.keys(_overallRouteSummDetails) || [];
        setIsLoading(false);

        setOverallRouteSummaryDetails(_overallRouteSummDetails);
        setOverallRouteSummaryDetailsCopy(
          JSON.parse(JSON.stringify(_overallRouteSummDetails))
        );

        setRouteDropDownValues(
          _routeDropdownValues.sort((a, b) => {
            if (b === "All") {
              return 1;
            } else {
              return -1;
            }
          })
        );

        setCurrentSelectedRoute("stockMove");
      })
      .catch((err) => {
        setIsLoading(false);
        toast(`Error in fetching items: ${err}`, { type: toast.TYPE.ERROR });
      });
  };

  useEffect(() => {
    init();
    generateProductMasterData();
  }, []);

  useEffect(() => {
    if (currentSelectedRoute && overallRouteSummaryDetailsCopy) {
      console.log("running useEffect");
      setApprovedFieldsStatus(
        currentSelectedRoute,
        overallRouteSummaryDetailsCopy
      );
    }
  }, [currentSelectedRoute, overallRouteSummaryDetailsCopy]);

  // const resetInputValuesOnRouteChange = () => {
  //   const initialData = overallRouteSummaryDetailsRaw;
  //   setOverallRouteSummaryDetails({ ...initialData });
  //   setOverallRouteSummaryDetailsCopy({ ...initialData });
  // };

  const addItemToSummaryDetails = (item_id, route) => {
    if (!item_id || !route) {
      return console.log(" Error:  Missing arguements ");
    }

    if (itemAlreadyInList(item_id)) {
      toast(`This item is already present in the list`, {
        type: toast.TYPE.WARNING,
      });
      return;
    }
    const item = productMasterDataRaw[item_id];

    if (!item) {
      return console.log("Error : Unable to retrieve product info");
    }
    const category = item.category || "";
    const brand = item.brand || "";
    const variety = item.variety || "";
    const sub_category = item.sub_category;
    const item_disc = item.description || "";
    const approved_qty = 1;

    const itemToAdd = {
      transit_id: transitID,
      item_id,
      category,
      brand,
      sub_category,
      item_disc,
      route,
      warehouse: userCity || "",
      approved_qty,
      bgColor: greenHighlightColor,
      delivery_date:
        props.dateType === "transit"
          ? moment(props.transit_date).format("YYYY-MM-DD")
          : "",
      transit_date:
        props.dateType === "delivery"
          ? moment(props.delivery_date).format("YYYY-MM-DD")
          : "",
    };
    console.log("ready to add data", itemToAdd);

    toast(`Successfully added new item`, {
      type: toast.TYPE.SUCCESS,
    });
    const existingRouteSummaryDetails = { ...overallRouteSummaryDetails };
    existingRouteSummaryDetails[route].push(itemToAdd);
    setOverallRouteSummaryDetails(existingRouteSummaryDetails);
    setSelectedItemId({});
  };

  const removeItemFromSummaryDetails = (item_id, route, transit_id) => {
    return new Promise((resolve, reject) => {
      let url = `${service_url}/api/transit-route-item?token=${token}`;
      const data = { item_id, route, transit_id };
      console.log("check data", data);

      fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": sessionStorage.getItem("x-access-token"),
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          toast(`Successfully removed item`, {
            type: toast.TYPE.SUCCESS,
          });

          resolve();
        })
        .catch((err) => {
          toast(`Unable to remove item`, {
            type: toast.TYPE.ERROR,
          });
          reject(err);
        });
    });
  };

  // const updateReceivedDate = () => {
  //   let url = `${service_url}/api/received-date?token=${token}`;

  //   let data = {
  //     approved_at: moment().format("YYYY-MM-DD HH:mm:ss"),
  //     id: transitID,
  //     isStockTransfer: 'N',
  //     route_id: currentSelectedRoute,
  //     warehouse: warehouse || "",
  //     delivery_date:
  //       props.dateType === "transit"
  //         ? moment(props.transit_date).format("YYYY-MM-DD")
  //         : "",
  //     transit_date:
  //       props.dateType === "delivery"
  //         ? moment(props.delivery_date).format("YYYY-MM-DD")
  //         : "",
  //   };

  //   fetch(url, {
  //     method: "PUT",
  //     headers: {
  //       "Content-Type": "application/json",
  //       "x-access-token": sessionStorage.getItem("x-access-token"),
  //     },
  //     body: JSON.stringify(data),
  //   })
  //     .then((res) => res.json())
  //     .then((result) => {
  //       setIsLoading(false);

  //       if (result.success == 1) {
  //         toast(`${result.message}`, { type: toast.TYPE.SUCCESS });
  //         refetchData();
  //         props.onHide();
  //       } else {
  //         toast(`${result.message}`, { type: toast.TYPE.ERROR });
  //       }
  //     })
  //     .catch((err) => {
  //       setIsLoading(false);
  //       toast(`Error in creating transit route records: ${err}`, {
  //         type: toast.TYPE.ERROR,
  //       });
  //     });
  // }

  const updateItemsQty = () => {
    console.log(transitID);
    let itemsList = [];
    const looseItemsList = [];

    for (let routeItem of overallRouteSummaryDetails[currentSelectedRoute]) {
      let dataToPush = {};
      let item_id = routeItem.item_id;
      let received_qty = routeItem.received_qty;
      const received_remarks = routeItem.rec_remarks;
      const approved_remarks = routeItem.app_remarks;

      if (!received_qty && received_qty !== 0) {
        return toast(`Please fill the quantity for all rows`, {
          type: toast.TYPE.WARNING,
        });
      } else if (received_qty < 0) {
        return toast(`Received quantity cannot be negative`, {
          type: toast.TYPE.ERROR,
        });
      }

      if (!received_remarks) {
        return toast(`Please enter remarks for all the fields`, {
          type: toast.TYPE.ERROR,
        });
      }

      dataToPush = { item_id, received_qty, received_remarks };
      if (isApprovedQtyFieldsShown && isApprovedQtyFieldsEditable) {
        if (
          routeItem.showApprovedQtyField &&
          !routeItem.approved_qty &&
          routeItem.approved_qty !== 0
        ) {
          return toast(`Please fill the quantity for all rows`, {
            type: toast.TYPE.WARNING,
          });
        } else if (
          routeItem.showApprovedQtyField &&
          routeItem.approved_qty < 0
        ) {
          return toast(`Approved quantity cannot be negative`, {
            type: toast.TYPE.ERROR,
          });
        } else if (routeItem.showApprovedQtyField && !approved_remarks) {
          return toast(`Please enter remarks for all the fields`, {
            type: toast.TYPE.ERROR,
          });
        } else if (routeItem.showApprovedQtyField) {
          dataToPush["approved_qty"] = routeItem.approved_qty;
          dataToPush["approved_remarks"] = approved_remarks;
          itemsList.push(dataToPush);
        }
      } else {
        itemsList.push(dataToPush);
      }
    }

    const url = `${service_url}/api/stock-movements-approved-item-qty?token=${token}`;

    let data = {
      updatedItems: itemsList,
      transit_id: transitID,
      route_id: currentSelectedRoute,
      warehouse: warehouse || "",
      delivery_date:
        props.dateType === "transit"
          ? moment(props.transit_date).format("YYYY-MM-DD")
          : "",
      transit_date:
        props.dateType === "delivery"
          ? moment(props.delivery_date).format("YYYY-MM-DD")
          : "",
    };
    if (isApprovedQtyFieldsShown && isApprovedQtyFieldsEditable) {
      data["approved_by"] = JSON.parse(sessionStorage.getItem(`user`))[0].email;
      data["approved_at"] = new Date().toJSON().slice(0, 19).replace("T", " ");
    } else {
      data["received_by"] = JSON.parse(sessionStorage.getItem(`user`))[0].email;
      data["received_at"] = new Date().toJSON().slice(0, 19).replace("T", " ");
    }

    console.log(data);

    setIsLoading(true);

    fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": sessionStorage.getItem("x-access-token"),
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((result) => {
        setIsLoading(false);

        if (result.success == 1) {
          toast(`${result.message}`, { type: toast.TYPE.SUCCESS });
          refetchData();
          props.onHide();
        } else {
          toast(`${result.message}`, { type: toast.TYPE.ERROR });
        }
      })
      .catch((err) => {
        setIsLoading(false);

        toast(`Error in creating transit route records: ${err}`, {
          type: toast.TYPE.ERROR,
        });
      });
  };

  const excelHeaders = React.useMemo(
    () => [
      {
        label: "Item ID",
        value: "item_id",
      },
      {
        label: "Route",
        value: "route",
      },
      {
        label: "Type",
        value: "variety",
      },
      {
        label: "Category",
        value: "category",
      },
      {
        label: "Brand",
        value: "brand",
      },
      {
        label: "Sub Category",
        value: "sub_category",
      },

      {
        label: "Description",
        value: "item_disc",
      },
      {
        label: "Shipped Qty",
        value: "item_modified_qty",
      },
      {
        label: "Received Qty",
        value: "received_qty",
      },
      {
        label: "Approved Qty",
        value: "approved_qty",
      },
    ],
    []
  );
  const excelHeadersLooseItems = React.useMemo(
    () => [
      {
        label: "Item ID",
        value: "item_id",
      },
      {
        label: "Order No",
        value: "order_number",
      },
      {
        label: "Route",
        value: "route",
      },
      {
        label: "Type",
        value: "variety",
      },
      {
        label: "Category",
        value: "category",
      },
      {
        label: "Brand",
        value: "brand",
      },
      {
        label: "Sub Category",
        value: "sub_category",
      },
      {
        label: "Description",
        value: "item_disc",
      },

      {
        label: "Bag Id",
        value: "bag_id",
      },
      {
        label: "Quantity",
        value: "item_modified_qty",
      },
      {
        label: "Received",
        value: "received",
      },
      {
        label: "Approved",
        value: "approved",
      },
    ],
    []
  );

  return (
    <div>
      <Modal
        {...props}
        id="transitStockModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Transit Items List
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {overallRouteSummaryDetails[currentSelectedRoute] &&
          overallRouteSummaryDetails[currentSelectedRoute].length > 0 ? (
            <>
              <div>
                <div
                  className="clearFix"
                  style={{
                    float: "right",
                    marginBottom: "20px",
                    marginLeft: "30px",
                  }}
                >
                  {/* <ExportToExcel
                    sheet1={overallRouteSummaryDetails[currentSelectedRoute]}
                    filename={"transit_stock_transfer_summary"}
                    sheet1Headers={excelHeaders}
                    sheet1Name={warehouse}
                  /> */}
                </div>
                <div
                  className="clearFix"
                  style={{ float: "right", marginBottom: "20px" }}
                >
                  <ShowDiscrepancyCheckbox
                    showDiscrepancyItemsChecked={showDiscrepancyItemsChecked}
                    setShowDiscrepancyItemsChecked={
                      setShowDiscrepancyItemsChecked
                    }
                  />
                </div>

                <RenderTransitRouteTable
                  overallRouteSummaryDetails={overallRouteSummaryDetails}
                  routeDropDownValues={routeDropDownValues}
                  currentSelectedRoute={currentSelectedRoute}
                  removeItemFromSummaryDetails={removeItemFromSummaryDetails}
                  setOverallRouteSummaryDetails={setOverallRouteSummaryDetails}
                  getApprovedFieldsStatus={getApprovedFieldsStatus}
                  showDiscrepancyItemsChecked={showDiscrepancyItemsChecked}
                  init={init}
                  isApprovedQtyFieldsEditable={isApprovedQtyFieldsEditable}
                  isApprovedQtyFieldsShown={isApprovedQtyFieldsShown}
                />
              </div>
            </>
          ) : (
            ""
          )}

          {!isUpdateBtnDisabled(
            userType,
            userCity,
            isApprovedQtyFieldsShown,
            isApprovedQtyFieldsEditable
          ) && !isApprovedQtyFieldsShown ? (
            <>
              <Row style={{ width: "100%" }}>
                <Col style={{ width: "150%" }}>
                  <Select
                    options={addTransitItemDropdownList}
                    value={selectedItemId}
                    onChange={(val) => {
                      console.log("selected value", val);
                      setSelectedItemId(val);
                    }}
                    isClearable={true}
                  />
                </Col>

                <Col style={{ width: "10%" }}>
                  <Button
                    onClick={() => {
                      if (selectedItemId && selectedItemId.value) {
                        addItemToSummaryDetails(
                          selectedItemId.value,
                          currentSelectedRoute
                        );
                      } else {
                        toast(`Please select a valid item id from the list`, {
                          type: toast.TYPE.WARNING,
                        });
                      }
                    }}
                  >
                    Add item
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer>
          {!isUpdateBtnDisabled(
            userType,
            userCity,
            isApprovedQtyFieldsShown,
            isApprovedQtyFieldsEditable
          ) && currentSelectedRoute !== "All" ? (
            <Button
              disabled={isLoading}
              onClick={() => {
                updateItemsQty();
              }}
            >
              Update
            </Button>
          ) : null}

          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>

      {isLoading ? <Backdrop parentLoadStatus={isLoading} /> : null}
    </div>
  );
}

const submitNewItemToAPI = (data) => {
  return new Promise((resolve, reject) => {
    if (!data) {
      console.log("Did not receive required arguements");
      reject();
      return;
    }

    let url = `${service_url}/api/transit-route-item?token=${token}`;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": sessionStorage.getItem("x-access-token"),
      },
      body: JSON.stringify(data),
    }).then((res) => {
      console.log("check response", res.body);
      resolve();
    });
  });
};

const isUpdateBtnDisabled = (
  user_type,
  user_city,
  is_approved_qty_fields_shown,
  is_approved_qty_fields_editable
) => {
  if (is_approved_qty_fields_shown && !is_approved_qty_fields_editable) {
    return true;
  }

  if (user_type === "Admin") {
    return false;
  } else if (is_approved_qty_fields_shown && !is_approved_qty_fields_editable) {
    return true;
  } else if (!is_approved_qty_fields_shown) {
    //Received Qty Field
    if (user_city === GLOBAL_PARENT_WAREHOUSE) {
      return true;
    } else if (user_type === "Operation" && user_city === "") {
      return true;
    } else {
      return false;
    }
  } else if (is_approved_qty_fields_shown && is_approved_qty_fields_editable) {
    if (
      user_city === GLOBAL_PARENT_WAREHOUSE ||
      (user_type === "Operation" && user_city === "")
    ) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};

const RenderTransitRouteTable = (props) => {
  const {
    overallRouteSummaryDetails,
    currentSelectedRoute,
    removeItemFromSummaryDetails,
    setOverallRouteSummaryDetails,
    init,
    routeDropDownValues,
    isApprovedQtyFieldsShown,
    isApprovedQtyFieldsEditable,
    showDiscrepancyItemsChecked,
  } = props;

  let routeDataToShow =
    currentSelectedRoute && overallRouteSummaryDetails[currentSelectedRoute]
      ? overallRouteSummaryDetails[currentSelectedRoute]
      : [];

  if (routeDataToShow.length > 0) {
    return (
      <div key={currentSelectedRoute} style={{ pageBreakBefore: "always" }}>
        <TransitItemsTable
          routeProductData={routeDataToShow}
          routeDropDownValues={routeDropDownValues}
          currentSelectedRoute={currentSelectedRoute}
          currentRoute={currentSelectedRoute}
          overallRouteSummaryDetails={overallRouteSummaryDetails}
          removeItemFromSummaryDetails={removeItemFromSummaryDetails}
          setOverallRouteSummaryDetails={setOverallRouteSummaryDetails}
          init={init}
          isApprovedQtyFieldsShown={isApprovedQtyFieldsShown}
          isApprovedQtyFieldsEditable={isApprovedQtyFieldsEditable}
          showDiscrepancyItemsChecked={showDiscrepancyItemsChecked}
        />
      </div>
    );
  } else {
    return <></>;
  }
};

export default StockMovementItemsModal;
