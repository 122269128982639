import { Table } from "react-bootstrap";
import React, { useState } from "react";

import { SegmentedControl, Textarea } from "@mantine/core";
import "../../Inventory/Inventory.css";
import {
  checkForLooseItemsDiscrepancy,
  getLooseItemRowColor,
} from "../../Inventory/common/utils";
const userCity = sessionStorage.getItem("userCity") || "";
const userType = sessionStorage.getItem("userType") || "";

const getReceivedToggleColor = (received_toggle_val) => {
  if (!received_toggle_val || received_toggle_val === "none") {
    return "dark";
  }

  if (received_toggle_val === "Y") {
    return "green";
  } else if (received_toggle_val === "N") {
    return "red";
  }
};

const getApprovedToggleColor = (approved_toggle_val) => {
  if (!approved_toggle_val || approved_toggle_val === "none") {
    return "dark";
  }

  if (approved_toggle_val === "Y") {
    return "green";
  } else if (approved_toggle_val === "N") {
    return "red";
  }
};

const BagItemsList = (props) => {
  const { bagItems } = props;
  const [showItemsList, setShowItemsList] = useState(false);

  return (
    <>
      <span
        onClick={(event) => {
          setShowItemsList((oldVal) => {
            return !oldVal;
          });
        }}
        className="items_toggle"
      >
        Show Items List
      </span>
      {showItemsList ? (
        <table style={{ fontSize: "12px", width: "130px" }}>
          <tbody>
            <tr>
              <th>Item Id</th>
              <th>Item</th>
              <th>Quantity</th>
            </tr>
            {bagItems.map((item, index) => {
              return (
                <tr>
                  <td>{item.item_id}</td>
                  <td>{item.item_disc}</td>
                  <td>{item.item_modified_qty}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : null}
    </>
  );
};

const TransitLooseItemsTableHeader = ({ isApprovedFieldsShown }) => {
  return (
    <thead className="thead-light">
      <tr>
        <th>Order No</th>
        <th>Bag ID</th>
        <th>Received</th>
        <th>Remarks</th>
        {/* {isApprovedFieldsShown ? <th>Approved</th> : null}
        {isApprovedFieldsShown ? <th>Remarks</th> : null} */}
      </tr>
    </thead>
  );
};

const ReceivedToggleTableCell = (props) => {
  const {
    row,
    receivedToggleColor,
    itemsListData,
    index,
    isApprovedFieldsEditable,
    isApprovedFieldsShown,
    loosePackingTableData,
    currentSelectedRoute,

    route,
    setLoosePackingTableData,
  } = props;

  if (currentSelectedRoute === "All") {
    if (row.received_qty != 0 && !row.received_qty) {
      return <td>Not Entered</td>;
    }
    return (
      <td>
        {row.receivedToggleVal === "Y"
          ? "Yes"
          : row.receivedToggleVal === "N"
          ? "No"
          : null}
      </td>
    );
  }

  return (
    <td>
      {" "}
      <SegmentedControl
        color={receivedToggleColor}
        disabled={isApprovedFieldsShown}
        value={itemsListData[index].receivedToggleVal || "none"}
        onChange={(val) => {
          const _itemsListData = { ...loosePackingTableData };
          _itemsListData[route][index].receivedToggleVal = val;
          setLoosePackingTableData(_itemsListData);
        }}
        data={[
          { label: "Select", value: "none" },
          { label: "Yes", value: "Y" },
          { label: "No", value: "N" },
        ]}
      />
    </td>
  );
};

// const ApprovedToggleTableCell = (props) => {
//   const {
//     approvedToggleColor,
//     itemsListData,
//     row,
//     index,
//     isApprovedFieldsEditable,
//     currentSelectedRoute,
//     isApprovedFieldsShown,
//     loosePackingTableData,
//     route,
//     setLoosePackingTableData,
//   } = props;

//   if (
//     isApprovedFieldsShown &&
//     row.receivedToggleVal === "Y" &&
//     userType === "Operation"
//   ) {
//     return <td></td>;
//   }

//   if (currentSelectedRoute === "All") {
//     if (!isApprovedFieldsShown) {
//       return "";
//     }
//     if (
//       isApprovedFieldsShown &&
//       isApprovedFieldsEditable &&
//       row.approved_qty != 0 &&
//       !row.approved_qty
//     ) {
//       return <td>Not Entered</td>;
//     }
//     return (
//       <td>
//         {row.approvedToggleVal === "Y"
//           ? "Yes"
//           : row.approvedToggleVal === "N"
//           ? "No"
//           : null}
//       </td>
//     );
//   }

//   return (
//     <>
//       {isApprovedFieldsShown ? (
//         <td>
//           <SegmentedControl
//             color={approvedToggleColor}
//             value={itemsListData[index].approvedToggleVal || "N"}
//             disabled={!isApprovedFieldsEditable}
//             onChange={(val) => {
//               const _itemsListData = { ...loosePackingTableData };
//               _itemsListData[route][index].approvedToggleVal = val;
//               setLoosePackingTableData(_itemsListData);
//             }}
//             data={[
//               { label: "Select", value: "none" },
//               { label: "Yes", value: "Y" },
//               { label: "No", value: "N" },
//             ]}
//           />
//         </td>
//       ) : null}
//     </>
//   );
// };

const TransitLooseItemsTableBody = ({
  loosePackingTableData,
  isApprovedFieldsShown,
  isApprovedFieldsEditable,
  bagDataInit,
  setLoosePackingTableData,
  currentSelectedRoute,
  showDiscrepancyItemsChecked,
  bagDataToShow,
  bagItemsById,
}) => {
  let itemsListData = bagDataToShow;
  let rows = itemsListData.map((row, index) => {
    const bagItems = row.bag_id ? bagItemsById[row.bag_id] : [];
    const route = row.route || "";

    const receivedToggleColor = getReceivedToggleColor(
      itemsListData[index].receivedToggleVal
    );

    const approvedToggleColor = getApprovedToggleColor(
      itemsListData[index].approvedToggleVal
    );

    row.bgColor = getLooseItemRowColor(
      row,
      isApprovedFieldsEditable,
      isApprovedFieldsShown
    );

    if (
      showDiscrepancyItemsChecked &&
      !checkForLooseItemsDiscrepancy(
        row,
        isApprovedFieldsEditable,
        isApprovedFieldsShown
      )
    ) {
      return null;
    }

    return (
      <tr
        key={index}
        style={{ backgroundColor: row.bgColor ? row.bgColor : "" }}
      >
        <td>{row.order_number}</td>
        <td>
          {" "}
          {row.bag_id}
          <br />
          <BagItemsList bagItems={bagItems} />{" "}
        </td>
        <ReceivedToggleTableCell
          row={row}
          receivedToggleColor={receivedToggleColor}
          itemsListData={itemsListData}
          index={index}
          isApprovedFieldsEditable={isApprovedFieldsEditable}
          isApprovedFieldsShown={isApprovedFieldsShown}
          loosePackingTableData={loosePackingTableData}
          currentSelectedRoute={currentSelectedRoute}
          route={route}
          setLoosePackingTableData={setLoosePackingTableData}
        />

        <td>
          <fieldset
            disabled={isApprovedFieldsShown && !row.showApprovedQtyField}
          >
            <Textarea
              placeholder="Enter received remarks"
              defaultValue={row.rec_remarks || ""}
              onChange={(e) => {
                const _itemsListData = { ...loosePackingTableData };
                _itemsListData[route][index].rec_remarks = e.target.value;
                setLoosePackingTableData(_itemsListData);
              }}
            />
          </fieldset>
        </td>

        {/* <ApprovedToggleTableCell
          row={row}
          approvedToggleColor={approvedToggleColor}
          itemsListData={itemsListData}
          index={index}
          isApprovedFieldsEditable={isApprovedFieldsEditable}
          isApprovedFieldsShown={isApprovedFieldsShown}
          loosePackingTableData={loosePackingTableData}
          currentSelectedRoute={currentSelectedRoute}
          route={route}
          setLoosePackingTableData={setLoosePackingTableData}
        /> */}

        {/* {isApprovedFieldsShown ? (
          <td>
            <fieldset disabled={!isApprovedFieldsEditable}>
              <Textarea
                placeholder="Enter remarks"
                defaultValue={row.app_remarks || ""}
                onChange={(e) => {
                  const _itemsListData = { ...loosePackingTableData };
                  _itemsListData[route][index].app_remarks = e.target.value;
                  setLoosePackingTableData(_itemsListData);
                }}
              />
            </fieldset>
          </td>
        ) : null} */}
      </tr>
    );
  });

  return (
    <>
      <tbody>{rows}</tbody>
    </>
  );
};

const TransitLooseItemsTable = ({
  loosePackingTableData,
  bagDataInit,
  setLoosePackingTableData,
  bagDataToShow,
  bagItemsById,
  isApprovedFieldsShown,
  currentSelectedRoute,
  isApprovedFieldsEditable,
  showDiscrepancyItemsChecked,
}) => {
  return (
    <Table striped bordered hover style={{ marginBottom: "30px" }}>
      <TransitLooseItemsTableHeader
        loosePackingTableData={loosePackingTableData}
        isApprovedFieldsShown={isApprovedFieldsShown}
      />
      <TransitLooseItemsTableBody
        loosePackingTableData={loosePackingTableData}
        isApprovedFieldsShown={isApprovedFieldsShown}
        isApprovedFieldsEditable={isApprovedFieldsEditable}
        currentSelectedRoute={currentSelectedRoute}
        bagDataInit={bagDataInit}
        bagDataToShow={bagDataToShow}
        setLoosePackingTableData={setLoosePackingTableData}
        showDiscrepancyItemsChecked={showDiscrepancyItemsChecked}
        bagItemsById={bagItemsById}
      />
    </Table>
  );
};

export default TransitLooseItemsTable;
