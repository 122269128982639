function getDistanceFromLatLonInMeters(lat1, lon1, lat2, lon2) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1);
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  d = d * 1000; //Distance in meters
  return d;
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

/**
 *
 * @param {Array} table_data Array of order objects
 * @param {Object} vehicle_location_data Object containing the locations visited by the driver.
 * @returns Modified table_data with key "visited_proximity" set to true for the retailer order if driver was within the 30m radius
 */
export const findIfDriverVisitedLocationVicinity = (
  table_data,
  vehicle_location_data
) => {
  if (!table_data) {
    return console.log("did not recieve required arguements");
  }

  if (
    !vehicle_location_data ||
    Object.keys(vehicle_location_data).length === 0
  ) {
    table_data.forEach((item, index) => {
      table_data[index]["visited_proximity"] = false;
    });
    return table_data;
  }

  table_data.forEach((item, index) => {
    let driver_in_range = false;
    Object.keys(vehicle_location_data).forEach((key, index) => {
      const veh_data = vehicle_location_data[key];
      if (
        Math.round(
          getDistanceFromLatLonInMeters(
            item.latitude,
            item.longitude,
            veh_data.latitude,
            veh_data.longitude
          )
        ) <= 100
      ) {
        driver_in_range = true;
      }
      // console.log(
      //   "Distance : ",
      //   Math.round(
      //     getDistanceFromLatLonInMeters(
      //       item.latitude,
      //       item.longitude,
      //       veh_data.latitude,
      //       veh_data.longitude
      //     )
      //   )
      // );
    });

    table_data[index]["visited_proximity"] = driver_in_range;
  });
  return table_data;
};

export function getPendingAndCancelledOrders(orders_data, allocation_data) {
  const dataToReturn = [];

  const visitedOrders = [];
  // console.log(
  //   "iterating for ,",
  //   allocationHistory[key].current_Route_Id
  // );

  Object.keys(allocation_data).forEach((phone_key, index) => {
    let route_id = allocation_data[phone_key].current_Route_Id;
    console.log("checking", route_id, "in", orders_data);
    if (route_id in orders_data) {
      let route_orders = orders_data[route_id];
      Object.keys(route_orders).forEach((order_key) => {
        let orderId = order_key.split("-")[0] + "/" + order_key.split("-")[1];
        // console.log("iterating", route_orders[order_key]);
        if (visitedOrders.includes(orderId)) {
          return;
        }
        visitedOrders.push(orderId);

        if (
          route_orders[order_key].status === "Pending" &&
          route_orders[order_key].reason !== "Not Visited"
        ) {
          dataToReturn.push({
            delivery_status: "Pending",
            order_id: orderId,
            driver_phno: phone_key,
            ...route_orders[order_key],
          });
        } else if (route_orders[order_key].status === "Cancelled") {
          dataToReturn.push({
            delivery_status: "Cancelled",
            order_id: orderId,
            driver_phno: phone_key,
            ...route_orders[order_key],
          });
        }
      });
    }
  });

  console.log("returning :", dataToReturn);
  return dataToReturn;
}
