import React from "react";
import ReactExport from "react-data-export";
import { Button } from "react-bootstrap";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const userType = sessionStorage.getItem("userType") || "";

class ExportToExcel extends React.Component {
  constructor(props) {
    super(props);
  }

  formatDataBeforeExport(looseItemsData) {
    if (!looseItemsData) {
      return [];
    }
    const dataToReturn = [];

    looseItemsData.forEach((item, index) => {
      const looseItem = { ...item };

      if (item.receivedToggleVal === "Y") {
        looseItem.received = "Yes";
      } else if (item.receivedToggleVal === "N") {
        looseItem.received = "No";
      }

      if (item.receivedToggleVal === "N" && item.approvedToggleVal === "Y") {
        looseItem.approved = "Yes";
      } else if (
        item.receivedToggleVal === "N" &&
        item.approvedToggleVal === "N"
      ) {
        looseItem.approved = "No";
      }

      dataToReturn.push(looseItem);
    });
    return dataToReturn;
  }

  render() {
    this.sheet1 = this.props.sheet1;
    this.sheet1Headers = this.props.sheet1Headers;

    
    this.filename = this.props.filename;
    this.sheet1Name = this.props.sheet1Name;
    this.buttonStyles = this.props.styles

    // console.log("check data", this.sheet2);
    // console.log("check headers", this.sheetHeaders);
    return (
      <>
        {userType === "Admin" ? (
          <ExcelFile
            element={<Button style={this.buttonStyles} variant="warning">Export</Button>}
            filename={this.filename}
          >
            <ExcelSheet data={this.sheet1} name={this.sheet1Name}>
              {Object.keys(this.sheet1Headers).map((key, index) => {
                return (
                  <ExcelColumn
                    key={index}
                    label={this.sheet1Headers[key].label}
                    value={this.sheet1Headers[key].value}
                  />
                );
              })}
            </ExcelSheet>
          </ExcelFile>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default ExportToExcel;
