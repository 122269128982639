import React, { memo } from "react";
import { Button, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";

const AddNewProductItem = memo(
  (props) => {
    const addTransitItemDropdownList = props.addTransitItemDropdownList;
    const selectedItemId = props.selectedItemId;
    const setSelectedItemId = props.setSelectedItemId;
    const addItemToSummaryDetails = props.addItemToSummaryDetails;
    return (
      <>
        <Row style={{ width: "100%" }}>
          <Col style={{ width: "150%" }}>
            <Select
              options={addTransitItemDropdownList}
              value={selectedItemId}
              onChange={(val) => {
                console.log("selected value", val);
                setSelectedItemId(val);
              }}
              isClearable={true}
            />
          </Col>

          <Col style={{ width: "10%" }}>
            <Button
              onClick={() => {
                if (selectedItemId && selectedItemId.value) {
                  addItemToSummaryDetails(selectedItemId.value);
                } else {
                  toast(`Please select a valid item id from the list`, {
                    type: toast.TYPE.WARNING,
                  });
                }
              }}
            >
              Add item
            </Button>
          </Col>
        </Row>
      </>
    );
  },

  (prevProps, nextProps) => {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
  }
);

export default AddNewProductItem;
