import React, { useState,useEffect, useRef, createRef } from 'react';
import fire from '../../Configs/firebase'
import {Modal,Button,Form,InputGroup,Dropdown,DropdownButton,Card,Accordion} from 'react-bootstrap'
import { InputGroupAddon, Input, InputGroupText, CardHeader, CardTitle, CardText } from 'reactstrap';

function MyVerticallyCenteredModal(props) {

const [getStatus,setStatus] = useState();


const handleMainSubmit = (event)=>{
  event.preventDefault();
  event.stopPropagation();
  if(getStatus) {
    props.callback(props.driver_id,props.order_id,{
      'status' : 'Cancelled',
      'reason' : getStatus
    })
  } else {
    return;
  }
}

  const submit_ref = createRef()

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Describe the reason for cancellation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleMainSubmit}>  
          <Form.Group>
            <InputGroup>   
              <Form.Control type="input" value={getStatus} placeholder={"Select an option from the dropdown"} required readOnly/>
              <DropdownButton
                as={InputGroup.Append}
                variant="outline-primary"
                title="Edit"
                >
                <Dropdown.Item href="#" name="Late Delivery" onClick={(e)=>{e.preventDefault();setStatus(e.target.name)}}>Late Delivery</Dropdown.Item>
                <Dropdown.Item href="#" name="Store Closed" onClick={(e)=>{e.preventDefault();setStatus(e.target.name)}}>Store Closed</Dropdown.Item>
                <Dropdown.Item href="#" name="Quality Issue" onClick={(e)=>{e.preventDefault();setStatus(e.target.name)}}>Quality Issue</Dropdown.Item>
                <Dropdown.Item href="#" name="Wrongly Ordered" onClick={(e)=>{e.preventDefault();setStatus(e.target.name)}}>Wrongly Ordered</Dropdown.Item>
                <Dropdown.Item href="#" name="Rate Issue" onClick={(e)=>{e.preventDefault();setStatus(e.target.name)}}>Rate Issue</Dropdown.Item>
                <Dropdown.Item href="#" name="Weight Issue" onClick={(e)=>{e.preventDefault();setStatus(e.target.name)}}>Weight Issue</Dropdown.Item>
                <Dropdown.Item href="#" name="Cash Not Available" onClick={(e)=>{e.preventDefault();setStatus(e.target.name)}}>Cash Not Available</Dropdown.Item>
                <Dropdown.Item href="#" name="Stock Unavailable" onClick={(e)=>{e.preventDefault();setStatus(e.target.name)}}>Stock Unavailable</Dropdown.Item>
              </DropdownButton>
            </InputGroup>
            </Form.Group>
            <Form.Group>
              <Button type="submit" hidden ref={submit_ref}>Submit</Button>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button 
                type="submit" onClick={(e)=>{e.preventDefault();submit_ref.current.click()}}>Submit</Button>
          <Button variant="light" onClick={()=>{setStatus();props.onHide()}}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  
  
  function App(props) {
      
    const [modalShow, setModalShow] = React.useState(props.ModalValue);
  
    return (
        <MyVerticallyCenteredModal
          show={props.ModalValue}
          onHide={() => {setModalShow(false);props.parentCallBack(false)}}
          driver_id = {props.driver_id}
          order_id = {props.order_id}
          childfirebaseInsert = {props.childfirebaseInsert}
          callback = {props.callback}
        />
    );
  }
  
 export default App
