import React, { useState, useEffect } from 'react'
import { Table, Form, Alert, Button,Modal } from 'react-bootstrap'
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import Backdrop from '../backdrop/backdrop'

function ModalDuplicateReturnOrders({duplicateOrderNumber,...props}) {
  console.log(duplicateOrderNumber);


  const placeDuplicateRetrunOrder = () => {
    props.onHide();
    props.callback();  
  }

    return (<>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    Return Orders
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div>
                  A Return Order already exists. Do you want to place an another order?
                </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={()=>{placeDuplicateRetrunOrder()}}>Yes</Button>
                    <Button onClick={props.onHide}>No</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ModalDuplicateReturnOrders
