import React, { useState, useEffect, useRef } from "react";
import fire from "../../Configs/firebase";
import { Input } from "reactstrap";
import {
  Modal,
  Button,
  Form,
  InputGroup,
  Dropdown,
  DropdownButton,
  Table,
  Jumbotron,
  FormControl,
  Spinner,
  Alert,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import ReactToPrint from "react-to-print";
import { keys } from "underscore";
import Switch from "react-switch";
import { toast } from "react-toastify";
import { InputGroupAddon } from "reactstrap";
import { IconButton } from "@material-ui/core";
import UpdatedDate from "./UpdatedDate";
import {
  validateIsOperationUser,
  validateIsBillingUser,
  validateIsAdminUser,
  validateIsFinanceUser,
  validateIsSystemsUser,
  validateIsFinanceHeadUser,
} from "../UserCredentials";

function formatOrderDate(date) {
  var d = new Date(date),
    month = d.getMonth() + 1,
    day = "" + d.getDate(),
    year = d.getFullYear();

  var monthNames = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "June",
    7: "July",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };

  //dd/mm/yyyy hh:mm
  return [day, monthNames[month], year].join(" ");
}

function MyVerticallyCenteredModal(props) {
  console.log(props);

  const [getDenom1, setDenom1] = useState(0);
  const [getDenom3, setDenom3] = useState(0);
  const [getDenom4, setDenom4] = useState(0);
  const [getDenom5, setDenom5] = useState(0);
  const [getDenom6, setDenom6] = useState(0);
  const [getDenom7, setDenom7] = useState(0);
  const [getDenom8, setDenom8] = useState(0);
  const [getDenom9, setDenom9] = useState(0);
  const [getDenom10, setDenom10] = useState(0);
  const [penalty, setPenalty] = useState(0);

  const [getSpinnerData, setSpinnerData] = useState(false);
  const [validated, setValidated] = useState(false);
  const [getPushDetails, setPushDetails] = useState(false);
  const [driverName, setDriverName] = useState("");
  const [driverPhn, setDriverPhn] = useState("");
  const [completedDelivery, setCompletedDelivery] = useState("");
  const [pushCompleteDelFlag, setPushCompleteDelFlag] = useState(false);
  const [totalAmt, setTotalAmt] = useState("");
  const [viewAllPayments, setViewAllPayments] = useState(false);
  const [allRoutePaymentObj, setAllRoutePaymentObj] = useState({});

  const [viewCompletedDeliveries, setViewCompletedDeliveries] = useState(true);
  const [completedDeliveriesRouteObj, setCompletedDeliveriesRouteObj] =
    useState({});

  const [consolidatedFlag, setConsolidatedFlag] = useState(false);
  const [consolidationPopupOpen, setConsolidationPopupOpen] = useState(false);
  const [consolidationSwitchChecked, setConsolidationSwitchChecked] =
    useState(false);

  const [dispatchedVehicleFlag, setDispatchedVehicleFlag] = useState(false);
  const [dispatchedVehiclePopupOpen, setDispatchedVehiclePopupOpen] =
    useState(false);
  const [dispatchedVehicleSwitchChecked, setDispatchedVehicleSwitchChecked] =
    useState(false);

  const [completedDeliveryFlag, setCompletedDeliveryFlag] = useState(false);
  const [stockCheckedFlag, setStockCheckedFlag] = useState(false);
  const [stockCheckedPopupOpen, setStockCheckedPopupOpen] = useState(false);
  const [stockCheckedSwitchChecked, setStockCheckedSwitchChecked] =
    useState(false);

  const paymentHeaderRef = useRef();

  useEffect(() => {
    console.log("DETAILS MODAL");
    console.log(props.selectedDriver);
    console.log(props.allRouteOrdersData);
    let driver_id = props.selectedDriver;
    let allRouteOrdersData = props.allRouteOrdersData;
    let currRouteOrdersData = allRouteOrdersData[driver_id];
    console.log(currRouteOrdersData);

    let isAllOrdersConsolidated = true;
    let isAllOrdersDispatched = true;
    Object.keys(currRouteOrdersData).map((key, index) => {
      let status = currRouteOrdersData[key].status;
      //Processed or Shipped or Shipped-Modified
      if (status == "Processed" || status.includes("Shipped")) {
        isAllOrdersConsolidated = false;
      }

      if (status != "Hold" && !status.includes("Shipped")) {
        isAllOrdersDispatched = false;
      }
    });
    setConsolidatedFlag(isAllOrdersConsolidated);
    setDispatchedVehicleFlag(isAllOrdersDispatched);
  }, []);

  useEffect(() => {
    let driverAllocationPath =
      props.selectDate.getFullYear() +
      "/" +
      (props.selectDate.getMonth() + 1) +
      "/" +
      (props.selectDate.getDate() +
        "-" +
        (props.selectDate.getMonth() + 1) +
        "-" +
        props.selectDate.getFullYear());

    fire
      .database()
      .ref("Driver_Allocation_History/" + driverAllocationPath)
      .once("value", (snapShot) => {
        console.log(snapShot.val());

        if (snapShot.val()) {
          const driverInfo = snapShot.val();
          let completedDeliveriesObj = {};
          Object.keys(driverInfo).forEach((driverPhn) => {
            if (
              driverInfo[driverPhn].current_Route_Id === props.selectedDriver
            ) {
              setConsolidationSwitchChecked(
                driverInfo[driverPhn].completed_conslidation
              );
              setDispatchedVehicleSwitchChecked(
                driverInfo[driverPhn].dispatched_vehicle
              );
              setStockCheckedSwitchChecked(driverInfo[driverPhn].stock_checked);
              setCompletedDeliveryFlag(
                driverInfo[driverPhn].completed_delivery
              );
            }

            let currRoute = driverInfo[driverPhn].current_Route_Id;
            completedDeliveriesObj[currRoute] =
              driverInfo[driverPhn].completed_delivery == "true" ? true : false;
          });
          setCompletedDeliveriesRouteObj(completedDeliveriesObj);
        }
      });
  }, []);

  // const PaymentModes = ["Paytm_Upload","RazorPay_Upload","Cheque1_Upload"]
  // const fileNames = ["champa_rice.jpg","login_header_logo.png",""]
  //   //fetch from storage
  // var storage = fire.storage();
  // PaymentModes.forEach((item,index)=>{

  //   var storageRef = storage.ref(`disha-payments/${item}/${props.selectDate.getFullYear()}/${(props.selectDate.getMonth()+1)}/${(props.selectDate.getDate()+"-"+(props.selectDate.getMonth()+1)
  // +"-"+props.selectDate.getFullYear())}/${fileNames[index]}`)
  // storageRef.getDownloadURL().then((url)=>{
  //   setImgFileObj([...imgFileObj,url])
  //   console.log(url)
  // }).catch((error)=>{
  //   console.error(error)
  // })
  // })
  const rendertotalAmt = () => {
    let total =
      2000 * getDenom1 +
      500 * getDenom3 +
      200 * getDenom4 +
      100 * getDenom5 +
      50 * getDenom6 +
      20 * getDenom7 +
      10 * getDenom8 +
      1 * getDenom9;

    return total;
  };

  const PushData = () => {
    if (getPushDetails) {
      console.log("Total denomination amount:: " + rendertotalAmt());

      fire
        .database()
        .ref(
          "Cash_Denomination/" +
            props.selectDate.getFullYear() +
            "/" +
            (props.selectDate.getMonth() + 1) +
            "/" +
            (props.selectDate.getDate() +
              "-" +
              (props.selectDate.getMonth() + 1) +
              "-" +
              props.selectDate.getFullYear()) +
            "/" +
            props.selectedDriver +
            "/"
        )
        .update({
          amount: rendertotalAmt().toString(),
          cash_payment: props.tableData["cash"].toString(),
          penalty_amt: penalty.toString(),
          currency_count: {
            _2000: getDenom1.toString(),
            _500: getDenom3.toString(),
            _200: getDenom4.toString(),
            _100: getDenom5.toString(),
            _50: getDenom6.toString(),
            _20: getDenom7.toString(),
            _10: getDenom8.toString(),
            coins: getDenom9.toString(),
            adjustments: getDenom10.toString(),
          },
          updated_by: JSON.parse(sessionStorage.getItem(`user`))[0].email,
          updated_at: UpdatedDate(),
        })
        .then(() => {
          setSpinnerData(false);
          props.onHide();
        });
    }
  };
  useEffect(() => {
    if (getPushDetails) PushData();
  }, [getPushDetails]);

  useEffect(() => {
    if (viewAllPayments == true) {
      generateAllRoutePaymentsData();
    }
  }, [viewAllPayments, viewCompletedDeliveries]);

  const generateAllRoutePaymentsData = () => {
    console.log("running generateAllRoutePaymentsData");
    let finalRouteOrdersData = props.allRouteOrdersData;
    console.log(finalRouteOrdersData);

    let temp_payment_summary = {};

    let adjustment_amt = 0;
    let cash_amt = 0;
    let cheque_amt = 0;
    let payment_pending_amt = 0;
    let razorpay_amt = 0;
    let superkredit_amt = 0;
    let internalsuperkredit_amt = 0;
    let paytm_amt = 0;
    let card_amt = 0;
    let rupifi_amt = 0;
    let returns_amt = 0;
    let total_amount_diff_replacement = 0;
    let total_amount_paid_amt = 0;
    let ordersSet = new Set();

    Object.keys(finalRouteOrdersData).map((key, value) => {
      let route = key;

      if (
        !viewCompletedDeliveries ||
        (viewCompletedDeliveries && completedDeliveriesRouteObj[route])
      ) {
        let routeOrdersData = finalRouteOrdersData[key];
        Object.keys(routeOrdersData).map((rkey, rvalue) => {
          let ordernokey = rkey;
          let orderDetails = routeOrdersData[rkey];

          if (!ordersSet.has(orderDetails.order_number)) {
            if (
              orderDetails.status &&
              (orderDetails.status.toLowerCase().indexOf("delivered") != -1 ||
                orderDetails.status.toLowerCase().indexOf("partial") != -1) &&
              "payment_details" in orderDetails
            ) {
              if (
                Object.keys(orderDetails["payment_details"]).indexOf(
                  "payment_mode"
                ) != -1
              ) {
                let payment_mode =
                  orderDetails["payment_details"]["payment_mode"].toLowerCase();

                console.log("debug payment mode", payment_mode);

                if (
                  payment_mode == "adjustments" ||
                  payment_mode == "adjustment"
                ) {
                  adjustment_amt =
                    adjustment_amt +
                    Number(
                      "total_amount_paid" in orderDetails["payment_details"]
                        ? orderDetails["payment_details"]["total_amount_paid"]
                        : orderDetails["payment_details"]["total_amt_paid"]
                    );
                } else if (payment_mode == "cash") {
                  cash_amt =
                    cash_amt +
                    Number(
                      "total_amount_paid" in orderDetails["payment_details"]
                        ? orderDetails["payment_details"]["total_amount_paid"]
                        : orderDetails["payment_details"]["total_amt_paid"]
                    );
                } else if (payment_mode == "rupifi") {
                  rupifi_amt =
                    rupifi_amt +
                    Number(
                      "total_amount_paid" in orderDetails["payment_details"]
                        ? orderDetails["payment_details"]["total_amount_paid"]
                        : orderDetails["payment_details"]["total_amt_paid"]
                    );
                } else if (payment_mode == "cheque") {
                  cheque_amt =
                    cheque_amt +
                    Number(
                      "total_amount_paid" in orderDetails["payment_details"]
                        ? orderDetails["payment_details"]["total_amount_paid"]
                        : orderDetails["payment_details"]["total_amt_paid"]
                    );
                } else if (
                  payment_mode == "payment_pending" ||
                  payment_mode == "paymentpending"
                ) {
                  payment_pending_amt =
                    payment_pending_amt +
                    Number(
                      "total_amount_paid" in orderDetails["payment_details"]
                        ? orderDetails["payment_details"]["total_amount_paid"]
                        : orderDetails["payment_details"]["total_amt_paid"]
                    );
                } else if (payment_mode == "razorpay") {
                  razorpay_amt =
                    razorpay_amt +
                    Number(
                      "total_amount_paid" in orderDetails["payment_details"]
                        ? orderDetails["payment_details"]["total_amount_paid"]
                        : orderDetails["payment_details"]["total_amt_paid"]
                    );
                } else if (payment_mode == "superkredit") {
                  superkredit_amt =
                    superkredit_amt +
                    Number(
                      "total_amount_paid" in orderDetails["payment_details"]
                        ? orderDetails["payment_details"]["total_amount_paid"]
                        : orderDetails["payment_details"]["total_amt_paid"]
                    );
                } else if (payment_mode == "paytm") {
                  paytm_amt =
                    paytm_amt +
                    Number(
                      "total_amount_paid" in orderDetails["payment_details"]
                        ? orderDetails["payment_details"]["total_amount_paid"]
                        : orderDetails["payment_details"]["total_amt_paid"]
                    );
                } else if (payment_mode == "card") {
                  card_amt =
                    card_amt +
                    Number(
                      "total_amount_paid" in orderDetails["payment_details"]
                        ? orderDetails["payment_details"]["total_amount_paid"]
                        : orderDetails["payment_details"]["total_amt_paid"]
                    );
                } else if (payment_mode == "return") {
                  returns_amt =
                    returns_amt +
                    Number(
                      "total_amount_paid" in orderDetails["payment_details"]
                        ? orderDetails["payment_details"]["total_amount_paid"]
                        : orderDetails["payment_details"]["total_amt_paid"]
                    );
                } else if (payment_mode == "internalsuperkredit") {
                  internalsuperkredit_amt =
                    internalsuperkredit_amt +
                    Number(
                      "total_amount_paid" in orderDetails["payment_details"]
                        ? orderDetails["payment_details"]["total_amount_paid"]
                        : orderDetails["payment_details"]["total_amt_paid"]
                    );
                }

                let replacement_after_delivery = orderDetails[
                  "replacement_after_delivery"
                ]
                  ? orderDetails["replacement_after_delivery"]
                  : "";
                if (replacement_after_delivery == "Yes") {
                  let new_dealer_price = orderDetails["new_dealer_price"]
                    ? Number(orderDetails["new_dealer_price"])
                    : 0;
                  let dealer_price = orderDetails["dealer_price"]
                    ? Number(orderDetails["dealer_price"])
                    : 0;
                  let finalOrderQty = orderDetails["order_qty"]
                    ? Number(orderDetails["order_qty"])
                    : 0;
                  if (orderDetails["item_modified_qty"]) {
                    finalOrderQty = Number(orderDetails["item_modified_qty"]);
                  }

                  if (orderDetails["item_delivered_qty"]) {
                    finalOrderQty = Number(orderDetails["item_delivered_qty"]);
                  }

                  let amtDiffDueToReplacement =
                    (new_dealer_price - dealer_price) * finalOrderQty;
                  amtDiffDueToReplacement = amtDiffDueToReplacement
                    ? Number(amtDiffDueToReplacement)
                    : 0;
                  total_amount_diff_replacement =
                    total_amount_diff_replacement + amtDiffDueToReplacement;
                }

                total_amount_paid_amt =
                  total_amount_paid_amt +
                  Number(
                    "total_amount_paid" in orderDetails["payment_details"]
                      ? orderDetails["payment_details"]["total_amount_paid"]
                      : orderDetails["payment_details"]["total_amt_paid"]
                  );
              } else {
                let paymentKeys = Object.keys(orderDetails["payment_details"]);
                paymentKeys.splice(paymentKeys.indexOf("total_amount_paid"), 1);

                paymentKeys.forEach((tkey) => {
                  console.log(ordernokey);
                  let payment_mode =
                    orderDetails["payment_details"][tkey][
                      "payment_mode"
                    ].toLowerCase();

                  console.log("debug payment mode", payment_mode);
                  if (
                    payment_mode == "adjustments" ||
                    payment_mode == "adjustment"
                  ) {
                    adjustment_amt =
                      adjustment_amt +
                      Number(
                        "total_amount_paid" in
                          orderDetails["payment_details"][tkey]
                          ? orderDetails["payment_details"][tkey][
                              "total_amount_paid"
                            ]
                          : orderDetails["payment_details"][tkey][
                              "total_amt_paid"
                            ]
                      );
                  } else if (payment_mode == "cash") {
                    cash_amt =
                      cash_amt +
                      Number(
                        "total_amount_paid" in
                          orderDetails["payment_details"][tkey]
                          ? orderDetails["payment_details"][tkey][
                              "total_amount_paid"
                            ]
                          : orderDetails["payment_details"][tkey][
                              "total_amt_paid"
                            ]
                      );
                  } else if (payment_mode == "rupifi") {
                    rupifi_amt =
                      rupifi_amt +
                      Number(
                        "total_amount_paid" in
                          orderDetails["payment_details"][tkey]
                          ? orderDetails["payment_details"][tkey][
                              "total_amount_paid"
                            ]
                          : orderDetails["payment_details"][tkey][
                              "total_amt_paid"
                            ]
                      );
                  } else if (payment_mode == "cheque") {
                    cheque_amt =
                      cheque_amt +
                      Number(
                        "total_amount_paid" in
                          orderDetails["payment_details"][tkey]
                          ? orderDetails["payment_details"][tkey][
                              "total_amount_paid"
                            ]
                          : orderDetails["payment_details"][tkey][
                              "total_amt_paid"
                            ]
                      );
                  } else if (
                    payment_mode == "payment_pending" ||
                    payment_mode == "paymentpending"
                  ) {
                    payment_pending_amt =
                      payment_pending_amt +
                      Number(
                        "total_amount_paid" in
                          orderDetails["payment_details"][tkey]
                          ? orderDetails["payment_details"][tkey][
                              "total_amount_paid"
                            ]
                          : orderDetails["payment_details"][tkey][
                              "total_amt_paid"
                            ]
                      );
                  } else if (payment_mode == "razorpay") {
                    razorpay_amt =
                      razorpay_amt +
                      Number(
                        "total_amount_paid" in
                          orderDetails["payment_details"][tkey]
                          ? orderDetails["payment_details"][tkey][
                              "total_amount_paid"
                            ]
                          : orderDetails["payment_details"][tkey][
                              "total_amt_paid"
                            ]
                      );
                  } else if (payment_mode == "superkredit") {
                    superkredit_amt =
                      superkredit_amt +
                      Number(
                        "total_amount_paid" in
                          orderDetails["payment_details"][tkey]
                          ? orderDetails["payment_details"][tkey][
                              "total_amount_paid"
                            ]
                          : orderDetails["payment_details"][tkey][
                              "total_amt_paid"
                            ]
                      );
                  } else if (payment_mode == "paytm") {
                    paytm_amt =
                      paytm_amt +
                      Number(
                        "total_amount_paid" in
                          orderDetails["payment_details"][tkey]
                          ? orderDetails["payment_details"][tkey][
                              "total_amount_paid"
                            ]
                          : orderDetails["payment_details"][tkey][
                              "total_amt_paid"
                            ]
                      );
                  } else if (payment_mode == "card") {
                    card_amt =
                      card_amt +
                      Number(
                        "total_amount_paid" in
                          orderDetails["payment_details"][tkey]
                          ? orderDetails["payment_details"][tkey][
                              "total_amount_paid"
                            ]
                          : orderDetails["payment_details"][tkey][
                              "total_amt_paid"
                            ]
                      );
                  } else if (payment_mode == "return") {
                    returns_amt =
                      returns_amt +
                      Number(
                        "total_amount_paid" in
                          orderDetails["payment_details"][tkey]
                          ? orderDetails["payment_details"][tkey][
                              "total_amount_paid"
                            ]
                          : orderDetails["payment_details"][tkey][
                              "total_amt_paid"
                            ]
                      );
                  } else if (payment_mode == "internalsuperkredit") {
                    internalsuperkredit_amt =
                      internalsuperkredit_amt +
                      Number(
                        "total_amount_paid" in
                          orderDetails["payment_details"][tkey]
                          ? orderDetails["payment_details"][tkey][
                              "total_amount_paid"
                            ]
                          : orderDetails["payment_details"][tkey][
                              "total_amt_paid"
                            ]
                      );
                  }
                });

                let replacement_after_delivery = orderDetails[
                  "replacement_after_delivery"
                ]
                  ? orderDetails["replacement_after_delivery"]
                  : "";
                if (replacement_after_delivery == "Yes") {
                  let new_dealer_price = orderDetails["new_dealer_price"]
                    ? Number(orderDetails["new_dealer_price"])
                    : 0;
                  let dealer_price = orderDetails["dealer_price"]
                    ? Number(orderDetails["dealer_price"])
                    : 0;
                  let finalOrderQty = orderDetails["order_qty"]
                    ? Number(orderDetails["order_qty"])
                    : 0;
                  if (orderDetails["item_modified_qty"]) {
                    finalOrderQty = Number(orderDetails["item_modified_qty"]);
                  }

                  if (orderDetails["item_delivered_qty"]) {
                    finalOrderQty = Number(orderDetails["item_delivered_qty"]);
                  }

                  let amtDiffDueToReplacement =
                    (new_dealer_price - dealer_price) * finalOrderQty;

                  amtDiffDueToReplacement = amtDiffDueToReplacement
                    ? Number(amtDiffDueToReplacement)
                    : 0;
                  total_amount_diff_replacement =
                    total_amount_diff_replacement + amtDiffDueToReplacement;
                }

                total_amount_paid_amt =
                  total_amount_paid_amt +
                  Number(
                    "total_amount_paid" in orderDetails["payment_details"]
                      ? orderDetails["payment_details"]["total_amount_paid"]
                      : orderDetails["payment_details"]["total_amt_paid"]
                  );
              }
            }
            ordersSet.add(orderDetails.order_number);
          }
        });
      }
    });
    console.log("PAYMENT DETAILS");
    console.log(adjustment_amt);
    console.log(cash_amt);
    console.log(cheque_amt);
    console.log(payment_pending_amt);
    console.log(razorpay_amt);
    console.log(superkredit_amt);
    console.log(internalsuperkredit_amt);
    console.log(paytm_amt);
    console.log(card_amt);
    console.log(total_amount_paid_amt);
    console.log(total_amount_diff_replacement);

    temp_payment_summary["adjustments"] = adjustment_amt;
    temp_payment_summary["cash"] = cash_amt;
    temp_payment_summary["rupifi"] = rupifi_amt;
    temp_payment_summary["cheque"] = cheque_amt;
    temp_payment_summary["paymentpending"] = payment_pending_amt;
    temp_payment_summary["razorpay"] = razorpay_amt;
    temp_payment_summary["superkredit"] = superkredit_amt;
    temp_payment_summary["internalsuperkredit"] = internalsuperkredit_amt;
    temp_payment_summary["paytm"] = paytm_amt;
    temp_payment_summary["card"] = card_amt;
    temp_payment_summary["total_amount_paid"] = total_amount_paid_amt;

    temp_payment_summary["amount_diff_replacement"] =
      total_amount_diff_replacement
        ? Number(total_amount_diff_replacement).toFixed(2)
        : 0;

    console.log("temp_payment_summary");
    console.log(temp_payment_summary);
    setAllRoutePaymentObj(temp_payment_summary);
  };

  useEffect(() => {
    if (pushCompleteDelFlag) {
      fire
        .database()
        .ref(
          "Driver_Allocation_History/" +
            props.selectDate.getFullYear() +
            "/" +
            (props.selectDate.getMonth() + 1) +
            "/" +
            (props.selectDate.getDate() +
              "-" +
              (props.selectDate.getMonth() + 1) +
              "-" +
              props.selectDate.getFullYear()) +
            "/" +
            driverPhn
        )
        .update({
          completed_delivery: completedDelivery,
          completed_delivery_updatedAt: new Date().getTime(),
        })
        .then(() =>
          toast(`Updated the complete delivery flag status`, {
            type: toast.TYPE.SUCCESS,
          })
        );
      setPushCompleteDelFlag(false);
    }
  }, [pushCompleteDelFlag]);

  const fetchDriverDetails = () => {
    fire
      .database()
      .ref(
        "Driver_Allocation_History/" +
          props.selectDate.getFullYear() +
          "/" +
          (props.selectDate.getMonth() + 1) +
          "/" +
          (props.selectDate.getDate() +
            "-" +
            (props.selectDate.getMonth() + 1) +
            "-" +
            props.selectDate.getFullYear())
      )
      .once("value", (snapShot) => {
        console.log(snapShot.val());

        if (snapShot.val()) {
          const driverInfo = snapShot.val();
          console.log(Object.keys(driverInfo));
          Object.keys(driverInfo).forEach((driverPhn) => {
            if (
              driverInfo[driverPhn].current_Route_Id === props.selectedDriver
            ) {
              setDriverName(driverInfo[driverPhn].actual_Driver_Name);
              setDriverPhn(driverInfo[driverPhn].driver_Phone_Number);
              setCompletedDelivery(driverInfo[driverPhn].completed_delivery);
            }
          });
        }
      });
  };

  useEffect(() => {
    fetchDriverDetails();
  }, []);

  const FetchData = () => {
    console.log(props.selectDate);
    fire
      .database()
      .ref(
        "Cash_Denomination/" +
          props.selectDate.getFullYear() +
          "/" +
          (props.selectDate.getMonth() + 1) +
          "/" +
          (props.selectDate.getDate() +
            "-" +
            (props.selectDate.getMonth() + 1) +
            "-" +
            props.selectDate.getFullYear()) +
          "/" +
          props.selectedDriver
      )
      .once("value", (snapShot) => {
        if (snapShot.hasChildren()) {
          const DenomData = snapShot.val();
          console.log(DenomData);

          if (DenomData.hasOwnProperty("currency_count")) {
            Object.keys(DenomData.currency_count).forEach((key) => {
              if (key === "coins")
                setDenom9(Number(DenomData.currency_count[key]));
              if (key === "adjustments")
                setDenom10(Number(DenomData.currency_count[key]));
              if (key === "_10")
                setDenom8(Number(DenomData.currency_count[key]));
              else if (key === "_20")
                setDenom7(Number(DenomData.currency_count[key]));
              else if (key === "_50")
                setDenom6(Number(DenomData.currency_count[key]));
              else if (key === "_100")
                setDenom5(Number(DenomData.currency_count[key]));
              else if (key === "_200")
                setDenom4(Number(DenomData.currency_count[key]));
              else if (key === "_500")
                setDenom3(Number(DenomData.currency_count[key]));
              else if (key === "_2000")
                setDenom1(Number(DenomData.currency_count[key]));
            });
          }
        }
      });
  };

  useEffect(() => {
    FetchData();
  }, []);

  const DispatchedVehicleModalPopup = () => {
    let text = `Do you want change as 'Dispatched Vehicle'? You won't be able to edit Delivery Orders for the Route-${props.selectedDriver}.`;
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Dispatched Vehicle
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>{text}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              updateDispatchedVehicleFlag();
              props.onHide();
            }}
          >
            OK
          </Button>
          <Button
            onClick={() => {
              setDispatchedVehiclePopupOpen(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const updateDispatchedVehicleFlag = () => {
    let driverAllocationPath =
      props.selectDate.getFullYear() +
      "/" +
      (props.selectDate.getMonth() + 1) +
      "/" +
      (props.selectDate.getDate() +
        "-" +
        (props.selectDate.getMonth() + 1) +
        "-" +
        props.selectDate.getFullYear());

    fire
      .database()
      .ref("Driver_Allocation_History/" + driverAllocationPath)
      .once("value", (snapShot) => {
        console.log(snapShot.val());

        if (snapShot.val()) {
          const driverInfo = snapShot.val();
          Object.keys(driverInfo).forEach((driverPhn) => {
            let routeKey = driverInfo[driverPhn].current_Route_Id;
            if (
              driverInfo[driverPhn].current_Route_Id === props.selectedDriver &&
              props.getAccessibleRoutes[routeKey]
            ) {
              fire
                .database()
                .ref(
                  `Driver_Allocation_History/${driverAllocationPath}/${driverPhn}`
                )
                .update({
                  dispatched_vehicle: true,
                  dispatched_vehicle_updatedAt: new Date().getTime(),
                });
              setDispatchedVehicleSwitchChecked(true);
            }
          });

          toast(`Updated the dispatched vehicle flag status`, {
            type: toast.TYPE.SUCCESS,
          });
          sessionStorage.setItem("vehicleReloaded", props.selectedDriver);
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        }
      });
  };

  const StockCheckedModalPopup = () => {
    let text = `Do you want change as 'Stock Checked'? You won't be able to edit Delivery Orders for the Route-${props.selectedDriver}.`;
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Stock Checked
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>{text}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              updateStockCheckedFlag();
              props.onHide();
            }}
          >
            OK
          </Button>
          <Button
            onClick={() => {
              setStockCheckedPopupOpen(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const updateStockCheckedFlag = () => {
    let driverAllocationPath =
      props.selectDate.getFullYear() +
      "/" +
      (props.selectDate.getMonth() + 1) +
      "/" +
      (props.selectDate.getDate() +
        "-" +
        (props.selectDate.getMonth() + 1) +
        "-" +
        props.selectDate.getFullYear());

    fire
      .database()
      .ref("Driver_Allocation_History/" + driverAllocationPath)
      .once("value", (snapShot) => {
        console.log(snapShot.val());

        if (snapShot.val()) {
          const driverInfo = snapShot.val();
          Object.keys(driverInfo).forEach((driverPhn) => {
            let routeKey = driverInfo[driverPhn].current_Route_Id;
            if (
              driverInfo[driverPhn].current_Route_Id === props.selectedDriver &&
              routeKey != "8888" &&
              routeKey != "9999" &&
              props.getAccessibleRoutes[routeKey]
            ) {
              fire
                .database()
                .ref(
                  `Driver_Allocation_History/${driverAllocationPath}/${driverPhn}`
                )
                .update({
                  stock_checked: true,
                  stock_checked_updatedAt: new Date().getTime().toString(),
                });
              setStockCheckedSwitchChecked(true);
            }
          });

          toast(`Updated the stock checked flag status`, {
            type: toast.TYPE.SUCCESS,
          });
          sessionStorage.setItem("vehicleReloaded", props.selectedDriver);
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        }
      });
  };

  const ConsolidationModalPopup = () => {
    let text = `Do you want change as 'Completed Tally'? You won't be able to edit Delivery Orders for the Route-${props.selectedDriver}.`;
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Consolidation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>{text}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              updateConsolidationFlag();
              props.onHide();
            }}
          >
            OK
          </Button>
          <Button
            onClick={() => {
              setConsolidationPopupOpen(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const revisedAmt = (itemsInOrders, orderNo) => {
    let undeliveredItemAmt = 0;
    let totalAmtPaid = 0;
    let totalOrderValue;
    Object.keys(itemsInOrders).forEach((orderNumber) => {
      if (
        !orderNumber.includes(
          `${orderNo.split("-")[0]}-${orderNo.split("-")[1]}`
        )
      )
        return;

      if (
        (itemsInOrders[orderNumber].item_delivered_qty &&
          itemsInOrders[orderNumber].mrp) ||
        (itemsInOrders[orderNumber].item_modified_qty &&
          itemsInOrders[orderNumber].mrp)
      ) {
        if (
          itemsInOrders[orderNumber].status
            .toLowerCase()
            .includes(`partially-delivered`) &&
          itemsInOrders[orderNumber].item_delivered_qty
        ) {
          // console.log( itemsInOrders[orderNumber].item_id+" ItemDeliveredQty is present::: "+itemsInOrders[orderNumber].order_qty+" - DeliveredQty:: "+itemsInOrders[orderNumber].item_delivered_qty)
          undeliveredItemAmt +=
            (Number(itemsInOrders[orderNumber].mrp) /
              Number(itemsInOrders[orderNumber].order_qty)) *
            (Number(itemsInOrders[orderNumber].order_qty) -
              Number(itemsInOrders[orderNumber].item_delivered_qty));
        } else if (
          (itemsInOrders[orderNumber].status
            .toLowerCase()
            .includes(`shipped`) ||
            itemsInOrders[orderNumber].status
              .toLowerCase()
              .includes(`partially-delivered`)) &&
          itemsInOrders[orderNumber].item_modified_qty
        ) {
          // console.log(itemsInOrders[orderNumber].item_id+" ItemModifiedQty is present::: "+itemsInOrders[orderNumber].order_qty+" - ModifiedQty:: "+itemsInOrders[orderNumber].item_modified_qty)
          undeliveredItemAmt +=
            (Number(itemsInOrders[orderNumber].mrp) /
              Number(itemsInOrders[orderNumber].order_qty)) *
            (Number(itemsInOrders[orderNumber].order_qty) -
              Number(itemsInOrders[orderNumber].item_modified_qty));
        }
      }

      totalOrderValue = Number(itemsInOrders[orderNumber].total_amt);
      totalAmtPaid = itemsInOrders[orderNumber]["payment_details"]
        ? itemsInOrders[orderNumber]["payment_details"]["total_amount_paid"]
        : 0;
    });
    console.log("UndeliveredAmt:: " + undeliveredItemAmt);
    console.log("TotalOrderValue:: " + totalOrderValue);
    if (!undeliveredItemAmt) {
      undeliveredItemAmt = 0;
    }
    let orderDisc = itemsInOrders[orderNo].order_disc
      ? Number(itemsInOrders[orderNo].order_disc)
      : 0;
    let revisedOrderDisc = itemsInOrders[orderNo].revised_order_disc
      ? Number(itemsInOrders[orderNo].revised_order_disc)
      : orderDisc;

    return totalOrderValue - undeliveredItemAmt;
  };

  const updateConsolidationFlag = () => {
    let driverAllocationPath =
      props.selectDate.getFullYear() +
      "/" +
      (props.selectDate.getMonth() + 1) +
      "/" +
      (props.selectDate.getDate() +
        "-" +
        (props.selectDate.getMonth() + 1) +
        "-" +
        props.selectDate.getFullYear());

    let orderValueCheckerObj = {};
    let issueInExport = false;

    let driver_id = props.selectedDriver;
    let allRouteOrdersData = props.allRouteOrdersData;
    let currRouteOrdersData = allRouteOrdersData[driver_id];
    console.log(currRouteOrdersData);

    let isAllOrdersConsolidated = true;
    Object.keys(currRouteOrdersData).map((key, index) => {
      let status = currRouteOrdersData[key].status;
      //Processed or Shipped or Shipped-Modified
      if (status == "Processed" || status.includes("Shipped")) {
        isAllOrdersConsolidated = false;
      }

      let ordered_amt = currRouteOrdersData[key]["total_amt"]
        ? Number(currRouteOrdersData[key]["total_amt"])
        : 0;
      let delivered_amt = revisedAmt(currRouteOrdersData, key);
      let order_number = currRouteOrdersData[key]["order_number"];
      let credit_amt = currRouteOrdersData[key]["credit_amt"]
        ? Number(currRouteOrdersData[key]["credit_amt"])
        : 0;

      let itemID = currRouteOrdersData[key]["item_id"];
      let mrp = currRouteOrdersData[key]["mrp"]
        ? Number(currRouteOrdersData[key]["mrp"])
        : 0;
      let order_disc = currRouteOrdersData[key]["order_disc"]
        ? Number(currRouteOrdersData[key]["order_disc"])
        : 0;
      let cashback_redeemed = currRouteOrdersData[key]["cashback_redeemed"]
        ? Number(currRouteOrdersData[key]["cashback_redeemed"])
        : 0;

      let srevisedOrderAmt = currRouteOrdersData[key].revised_order_amt
        ? Number(currRouteOrdersData[key].revised_order_amt)
        : Number(currRouteOrdersData[key].total_amt);
      let sorderDisc = currRouteOrdersData[key].order_disc
        ? Number(currRouteOrdersData[key].order_disc)
        : 0;
      let srevisedOrderDisc = currRouteOrdersData[key].revised_order_disc
        ? Number(currRouteOrdersData[key].revised_order_disc)
        : sorderDisc;
      let srevisedOrderAmtWithDisc = (
        srevisedOrderAmt +
        sorderDisc -
        srevisedOrderDisc
      ).toFixed(2);

      let orderele = {};
      orderele["order_number"] = order_number;
      orderele["route"] = driver_id;
      orderele["calc_order_amount"] = mrp;
      if (orderValueCheckerObj[order_number]) {
        let existingAmt = orderValueCheckerObj[order_number][
          "calc_order_amount"
        ]
          ? Number(orderValueCheckerObj[order_number]["calc_order_amount"])
          : 0;
        orderele["calc_order_amount"] = existingAmt + mrp;
      }
      orderele["order_disc"] = order_disc;
      orderele["cashback_redeemed"] = cashback_redeemed;
      orderele["ordered_amt"] = ordered_amt;
      orderele["status"] = status;
      orderValueCheckerObj[order_number] = orderele;

      let finalPaidAmt = 0;
      if (currRouteOrdersData[key]["payment_details"]) {
        finalPaidAmt = currRouteOrdersData[key]["payment_details"][
          "total_amount_paid"
        ]
          ? Number(
              currRouteOrdersData[key]["payment_details"]["total_amount_paid"]
            )
          : 0;
      }
      let paidAmtDiff = srevisedOrderAmtWithDisc - finalPaidAmt;

      if (
        (paidAmtDiff > 10 || paidAmtDiff < -10) &&
        currRouteOrdersData[key].status.toLowerCase().includes("delivered")
      ) {
        toast(
          `Discrepancy in the payments. Can't export order ${order_number} | ${driver_id}`,
          { type: toast.TYPE.ERROR }
        );
        issueInExport = true;
        return;
      }

      // if (currRouteOrdersData[key].hasOwnProperty("payment_details") && currRouteOrdersData[key]['status'].includes("Delivered")){
      //   if(Number(delivered_amt) - currRouteOrdersData[key]["payment_details"]["total_amount_paid"] > 100) {
      //     toast(`Discrepancy in the payments. Can't export order ${order_number} | ${driver_id}`, { type: toast.TYPE.ERROR })
      //     issueInExport = true;
      //     return;
      //   }
      // }
    });

    console.log(orderValueCheckerObj);

    Object.keys(orderValueCheckerObj).map((key, index) => {
      let order_disc = orderValueCheckerObj[key].order_disc;
      let cashback_redeemed = orderValueCheckerObj[key].cashback_redeemed;
      let order_number = orderValueCheckerObj[key].order_number;
      let sumTotalAmt = orderValueCheckerObj[key].calc_order_amount;
      let net_order_amt = orderValueCheckerObj[key].ordered_amt;
      let route = orderValueCheckerObj[key].route;
      let status = orderValueCheckerObj[key].status
        ? orderValueCheckerObj[key].status
        : "";

      let calcOrderValue = sumTotalAmt - order_disc - cashback_redeemed;

      let orderValueDiff = calcOrderValue - net_order_amt;
      if (
        net_order_amt != calcOrderValue &&
        (!order_number.includes("R") ||
          (status.includes("Delivered") && order_number.includes("R"))) &&
        orderValueCheckerObj[key].route != "9999" &&
        (orderValueDiff > 10 || orderValueDiff < -10)
      ) {
        toast(
          `${route} : ${order_number} has Order Value discrepancy with difference of ${orderValueDiff.toFixed(
            2
          )}`,
          { type: toast.TYPE.ERROR }
        );
        issueInExport = true;
        return;
      }
    });

    if (issueInExport == true) {
      return;
    }

    fire
      .database()
      .ref("Driver_Allocation_History/" + driverAllocationPath)
      .once("value", (snapShot) => {
        console.log(snapShot.val());

        if (snapShot.val()) {
          let isEndingReadingPresent = false;

          const driverInfo = snapShot.val();
          Object.keys(driverInfo).forEach((driverPhn) => {
            let routeKey = driverInfo[driverPhn].current_Route_Id;
            if (
              driverInfo[driverPhn].current_Route_Id === props.selectedDriver &&
              props.getAccessibleRoutes[routeKey] &&
              driverInfo[driverPhn].hasOwnProperty("ending_odometer_reading")
            ) {
              fire
                .database()
                .ref(
                  `Driver_Allocation_History/${driverAllocationPath}/${driverPhn}`
                )
                .update({
                  completed_conslidation: true,
                  completed_conslidation_updatedAt: new Date().getTime(),
                });
              setConsolidationSwitchChecked(true);
            }

            if (
              driverInfo[driverPhn].hasOwnProperty("ending_odometer_reading")
            ) {
              isEndingReadingPresent = true;
            }
          });

          if (!isEndingReadingPresent) {
            toast(`Ending vehicle reading not present`, {
              type: toast.TYPE.ERROR,
            });
            return;
          }

          toast(`Updated the order consolidation flag status`, {
            type: toast.TYPE.SUCCESS,
          });
          sessionStorage.setItem("vehicleReloaded", props.selectedDriver);
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        } else {
          toast(`no data in driver allocation table`, {
            type: toast.TYPE.ERROR,
          });
        }
      });
  };

  const componentRef = useRef();

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Payment Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: "none" }}></div>

          {props.selectedDriver != "8888" &&
            props.selectedDriver != "9999" &&
            (validateIsAdminUser(
              JSON.parse(sessionStorage.getItem(`user`))[0].email
            ) ||
              validateIsOperationUser(
                JSON.parse(sessionStorage.getItem(`user`))[0].email
              )) && (
              <Table size="sm" style={{ width: 250 }} borderless>
                <tr>
                  <td>Dispatched Vehicle?</td>
                  <td>
                    <Switch
                      onChange={(val) => {
                        if (
                          !validateIsAdminUser(
                            JSON.parse(sessionStorage.getItem(`user`))[0].email
                          )
                        ) {
                          return toast(
                            "Only admin user can modify dispatched status",
                            { type: toast.TYPE.ERROR }
                          );
                        }
                        setDispatchedVehiclePopupOpen(val);
                      }}
                      checked={dispatchedVehicleSwitchChecked}
                      disabled={
                        validateIsOperationUser(
                          JSON.parse(sessionStorage.getItem(`user`))[0].email
                        ) && !dispatchedVehicleFlag
                          ? true
                          : false
                      }
                    />
                  </td>
                </tr>
              </Table>
            )}

          {(validateIsAdminUser(
            JSON.parse(sessionStorage.getItem(`user`))[0].email
          ) ||
            validateIsBillingUser(
              JSON.parse(sessionStorage.getItem(`user`))[0].email
            )) && (
            <Table size="sm" style={{ width: 250 }} borderless>
              <tr>
                <td>Completed Deliveries?</td>
                <td>
                  <Switch
                    onChange={(val) => {
                      setCompletedDelivery(val);
                      setPushCompleteDelFlag(true);
                    }}
                    disabled={true}
                    checked={completedDelivery}
                  />
                </td>
              </tr>
            </Table>
          )}

          {(validateIsAdminUser(
            JSON.parse(sessionStorage.getItem(`user`))[0].email
          ) ||
            (completedDeliveryFlag &&
              props.selectedDriver != "8888" &&
              props.selectedDriver != "9999" &&
              (validateIsAdminUser(
                JSON.parse(sessionStorage.getItem(`user`))[0].email
              ) ||
                validateIsOperationUser(
                  JSON.parse(sessionStorage.getItem(`user`))[0].email
                )))) && (
            <Table size="sm" style={{ width: 250 }} borderless>
              <tr>
                <td>Stock Checked?</td>
                <td>
                  <Switch
                    onChange={(val) => {
                      if (
                        !validateIsAdminUser(
                          JSON.parse(sessionStorage.getItem(`user`))[0].email
                        )
                      ) {
                        return toast(
                          "Only admin user can modify stock checked status",
                          { type: toast.TYPE.ERROR }
                        );
                      }
                      setStockCheckedPopupOpen(val);
                    }}
                    checked={stockCheckedSwitchChecked}
                  />
                </td>
              </tr>
            </Table>
          )}

          {(validateIsAdminUser(
            JSON.parse(sessionStorage.getItem(`user`))[0].email
          ) ||
            (consolidatedFlag &&
              props.selectedDriver != "8888" &&
              props.selectedDriver != "9999" &&
              (validateIsAdminUser(
                JSON.parse(sessionStorage.getItem(`user`))[0].email
              ) ||
                validateIsBillingUser(
                  JSON.parse(sessionStorage.getItem(`user`))[0].email
                )))) && (
            <Table size="sm" style={{ width: 250 }} borderless>
              <tr>
                <td>Completed Tally?</td>
                <td>
                  <Switch
                    onChange={(val) => {
                      setConsolidationPopupOpen(val);
                    }}
                    disabled={!completedDeliveryFlag ? true : false}
                    checked={consolidationSwitchChecked}
                  />
                </td>
              </tr>
            </Table>
          )}

          {completedDelivery && (
            <ReactToPrint
              trigger={() => (
                <IconButton style={{ float: "right" }}>
                  <FontAwesomeIcon size="lg" icon={faPrint} />
                </IconButton>
              )}
              content={() => {
                return componentRef.current;
              }}
              onAfterPrint={() => {
                props.parentAlert("Print Successful", "success");
              }}
              onPrintError={() => {
                props.parentAlert("Print Unsuccessful", "error");
              }}
              onBeforePrint={() => {
                props.parentAlert("Print Started", "info");
              }}
            />
          )}
          <div ref={componentRef}>
            <h4 ref={paymentHeaderRef} style={{ textAlign: "center" }}>
              Payment Summary - {formatOrderDate(props.selectDate)} -{" "}
              {props.selectedDriver}
            </h4>
            <Table
              size="sm"
              bordered
              className={"bg-light"}
              style={{ width: "50%", marginLeft: "auto", marginRight: "auto" }}
            >
              <tr>
                <td>Delivery Boy Name</td>
                <td>{driverName}</td>
              </tr>
              <tr>
                <td>Delivery Boy Phone</td>
                <td>{driverPhn}</td>
              </tr>
              <tr>
                <td>Route ID</td>
                <td>{props.selectedDriver}</td>
              </tr>
            </Table>
            <Table
              size="sm"
              striped
              bordered
              hover
              style={{ width: "80%", marginLeft: "auto", marginRight: "auto" }}
            >
              <thead>
                <tr>
                  <th>Payment Mode</th>
                  <th>Total</th>
                  <th>Payment Mode</th>
                  <th>Total</th>
                </tr>
              </thead>
              {viewAllPayments == true ? (
                <tbody>
                  <tr className={"table-default"}>
                    <td>Paytm </td>
                    <td>{`₹ ${allRoutePaymentObj["paytm"]} `}</td>
                    <td>RazorPay </td>
                    <td>{`₹ ${allRoutePaymentObj["razorpay"]} `}</td>
                  </tr>
                  <tr className={"table-default"}>
                    <td>Card</td>
                    <td>{`₹ ${allRoutePaymentObj["card"]}`}</td>
                    <td>Cash </td>
                    <td>{`₹ ${allRoutePaymentObj["cash"]} `}</td>
                  </tr>
                  <tr className={"table-default"}>
                    <td>Cheque</td>
                    <td>{` ₹ ${allRoutePaymentObj["cheque"]} `}</td>
                    <td>Rupifi</td>
                    <td>{` ₹ ${allRoutePaymentObj["rupifi"]} `}</td>
                  </tr>
                  <tr className={"table-default"}>
                    <td>PaymentPending</td>
                    <td>{`₹ ${allRoutePaymentObj["paymentpending"]} `}</td>
                    <td>Adjustments</td>
                    <td>{`₹ ${allRoutePaymentObj["adjustments"]}`}</td>
                  </tr>
                  <tr className={"table-default"}>
                    <td>SuperKredit</td>
                    <td>{`₹ ${allRoutePaymentObj["internalsuperkredit"]} `}</td>
                    <td>Amount Difference Due to Replacement</td>
                    <td>{`₹ ${allRoutePaymentObj["amount_diff_replacement"]} `}</td>
                  </tr>
                  <tr>
                    <td>All Routes?</td>
                    <td style={{ margin: "10" }}>
                      <Input
                        type="checkbox"
                        onChange={(val) => {
                          setViewCompletedDeliveries(true);
                          setViewAllPayments(!viewAllPayments);
                        }}
                        checked={viewAllPayments}
                      />
                    </td>
                    <td>Completed Deliveries?</td>
                    <td style={{ margin: "10" }}>
                      <Input
                        type="checkbox"
                        onChange={(val) => {
                          setViewCompletedDeliveries(!viewCompletedDeliveries);
                        }}
                        checked={viewCompletedDeliveries}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className={"bg-warning"}>Total Amount</td>
                    <td className={"bg-warning"}>{`₹ ${
                      allRoutePaymentObj["total_amount_paid"]
                        ? Number(
                            allRoutePaymentObj["total_amount_paid"]
                          ).toFixed(2)
                        : allRoutePaymentObj["total_amount_paid"]
                    }`}</td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  <tr className={"table-default"}>
                    <td>Paytm </td>
                    <td>{`₹ ${props.tableData["paytm"]} `}</td>
                    <td>RazorPay</td>
                    <td>{`₹ ${props.tableData["razorpay"]}`}</td>
                  </tr>
                  <tr className={"table-default"}>
                    <td>Card</td>
                    <td>{`₹ ${props.tableData["card"]}`}</td>
                    <td>Cash</td>
                    <td>{`₹ ${props.tableData["cash"]}`}</td>
                  </tr>
                  <tr className={"table-default"}>
                    <td>Cheque</td>
                    <td>{`₹ ${props.tableData["cheque"]}`}</td>
                    <td>Rupifi</td>
                    <td>{`₹ ${props.tableData["rupifi"]}`}</td>
                  </tr>
                  <tr className={"table-default"}>
                    <td>PaymentPending</td>
                    <td>{`₹ ${props.tableData["paymentpending"]} `}</td>
                    <td>Adjustments</td>
                    <td>{`₹ ${
                      Number(props.tableData["adjustments"]) +
                      Number(props.tableData["adjustment"])
                    }`}</td>
                  </tr>
                  <tr className={"table-default"}>
                    <td>SuperKredit</td>
                    <td>{`₹ ${props.tableData["internalsuperkredit"]}`}</td>
                    <td>Amount Difference Due to Replacement</td>
                    <td>{`₹ ${props.tableData["amount_diff_replacement"]} `}</td>
                  </tr>
                  <tr className={"table-default"}>
                    <td>Returns</td>
                    <td>{`₹ ${props.tableData["returns"]}`}</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>All Routes?</td>
                    <td style={{ margin: "10" }}>
                      <Input
                        type="checkbox"
                        onChange={(val) => {
                          setViewAllPayments(!viewAllPayments);
                        }}
                        checked={viewAllPayments}
                      />
                    </td>
                    <td className={"bg-warning"}>Total Amount</td>
                    <td className={"bg-warning"}>{`₹ ${props.tableData[
                      "total_amount_paid"
                    ].toFixed(2)}`}</td>
                  </tr>
                </tbody>
              )}
            </Table>

            <div>
              <Form noValidate={validated}>
                <div>
                  <center>
                    <h5>Denomination Details</h5>
                  </center>
                  <Table
                    class="center"
                    size="sm"
                    style={{
                      width: "70%",
                      marginLeft: "auto",
                      marginRight: "auto",
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    <thead>
                      <th>Currency</th>
                      <th>Count</th>
                      <th>Amount</th>
                      <th>Cashier Amt</th>
                    </thead>
                    <tbody>
                      <tr>
                        <td>2000</td>
                        <td>
                          <FormControl
                            type="number"
                            value={getDenom1 && getDenom1}
                            onChange={(e) => {
                              setDenom1(e.target.value);
                            }}
                          ></FormControl>
                        </td>
                        <td>{2000 * getDenom1} </td>
                        <td width="40%"></td>
                      </tr>
                      <tr>
                        <td>500</td>
                        <td>
                          <FormControl
                            type="number"
                            value={getDenom3 && getDenom3}
                            onChange={(e) => {
                              setDenom3(e.target.value);
                            }}
                          ></FormControl>
                        </td>
                        <td>{500 * getDenom3}</td>
                        <td width="40%"></td>
                      </tr>
                      <tr>
                        <td>200</td>
                        <td>
                          <FormControl
                            type="number"
                            value={getDenom4 && getDenom4}
                            onChange={(e) => {
                              setDenom4(e.target.value);
                            }}
                          ></FormControl>
                        </td>
                        <td>{200 * getDenom4}</td>
                        <td width="40%"></td>
                      </tr>
                      <tr>
                        <td>100</td>
                        <td>
                          <FormControl
                            type="number"
                            value={getDenom5 && getDenom5}
                            onChange={(e) => {
                              setDenom5(e.target.value);
                            }}
                          ></FormControl>
                        </td>
                        <td>{100 * getDenom5}</td>
                        <td width="40%"></td>
                      </tr>

                      <tr>
                        <td>50</td>
                        <td>
                          <FormControl
                            type="number"
                            value={getDenom6 && getDenom6}
                            onChange={(e) => {
                              setDenom6(e.target.value);
                            }}
                          ></FormControl>
                        </td>
                        <td>{50 * getDenom6}</td>
                        <td width="40%"></td>
                      </tr>
                      <tr>
                        <td>20</td>
                        <td>
                          <FormControl
                            type="number"
                            value={getDenom7 && getDenom7}
                            onChange={(e) => {
                              setDenom7(e.target.value);
                            }}
                          ></FormControl>
                        </td>
                        <td>{20 * getDenom7}</td>
                        <td width="40%"></td>
                      </tr>
                      <tr>
                        <td>10</td>
                        <td>
                          <FormControl
                            type="number"
                            value={getDenom8 && getDenom8}
                            onChange={(e) => {
                              setDenom8(e.target.value);
                            }}
                          ></FormControl>
                        </td>
                        <td>{10 * getDenom8}</td>
                        <td width="40%"></td>
                      </tr>
                      <tr>
                        <td>Coins</td>
                        <td>
                          <FormControl
                            type="number"
                            value={getDenom9 && getDenom9}
                            onChange={(e) => {
                              setDenom9(e.target.value);
                            }}
                          ></FormControl>
                        </td>
                        <td>{1 * getDenom9}</td>
                        <td width="40%"></td>
                      </tr>

                      <tr>
                        <td colSpan="2">Total Amount (Denomination)</td>
                        <td>{rendertotalAmt()}</td>
                        <td width="40%"></td>
                      </tr>
                      <tr>
                        <td>Total Cash Short</td>
                        <td>
                          <FormControl
                            type="number"
                            value={getDenom10 && getDenom10}
                            onChange={(e) => {
                              setDenom10(e.target.value);
                            }}
                          ></FormControl>
                        </td>
                        <td>{1 * getDenom10}</td>
                        <td width="40%"></td>
                      </tr>
                      <tr>
                        <td colSpan="2">Total Cash Payment (System)</td>
                        <td>
                          {props.tableData["cash"] && props.tableData["cash"]}
                        </td>
                        <td width="40%"></td>
                      </tr>
                      {(validateIsSystemsUser(
                        JSON.parse(sessionStorage.getItem(`user`))[0].email
                      ) ||
                        validateIsAdminUser(
                          JSON.parse(sessionStorage.getItem(`user`))[0].email
                        )) && (
                        <tr>
                          <td>Penalty</td>
                          <td>
                            <FormControl
                              type="number"
                              value={penalty && penalty}
                              onChange={(e) => {
                                setPenalty(e.target.value);
                              }}
                            ></FormControl>
                          </td>
                          <td>{1 * penalty}</td>
                          <td width="40%"></td>
                        </tr>
                      )}
                    </tbody>
                  </Table>

                  <Jumbotron>
                    <Table bordered>
                      <tr>
                        <td>DB Signature</td>
                        <td width="50%"></td>
                      </tr>
                      <tr>
                        <td>Cashier Signature</td>
                        <td width="50%"></td>
                      </tr>
                    </Table>
                  </Jumbotron>
                </div>
              </Form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {!(
            validateIsOperationUser(
              JSON.parse(sessionStorage.getItem(`user`))[0].email
            ) ||
            validateIsFinanceHeadUser(
              JSON.parse(sessionStorage.getItem(`user`))[0].email
            ) ||
            validateIsFinanceUser(
              JSON.parse(sessionStorage.getItem(`user`))[0].email
            ) ||
            JSON.parse(sessionStorage.getItem(`user`))[0]?.user_type ===
              "systems"
          ) ? (
            <Button
              onClick={() => {
                if (
                  validateIsBillingUser(
                    JSON.parse(sessionStorage.getItem(`user`))[0].email
                  ) &&
                  !completedDeliveryFlag
                ) {
                  return toast(
                    "Billing user cannot submit without completed delivery flag",
                    { type: toast.TYPE.ERROR }
                  );
                }
                setPushDetails(true);
                setSpinnerData(true);
              }}
            >
              Confirm
            </Button>
          ) : null}

          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>

      {consolidationPopupOpen == true ? <ConsolidationModalPopup /> : null}
      {dispatchedVehiclePopupOpen == true ? (
        <DispatchedVehicleModalPopup />
      ) : null}
      {stockCheckedPopupOpen == true ? <StockCheckedModalPopup /> : null}
    </>
  );
}

function App(props) {
  const [modalShow, setModalShow] = React.useState(false);

  let showModalStatus = props.getPaymentSummaryVisibility;
  console.log(props.getSelecetdDate);

  useEffect(() => {
    setModalShow(showModalStatus);
  }, [showModalStatus]);

  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        selectedDriver={props.getSelectedDriver}
        selectDate={props.getSelectedDate}
        onHide={() => {
          props.setPaymentSummaryVisibility(false);
          setModalShow(false);
        }}
        tableData={props.tableData}
        allRouteOrdersData={props.allRouteOrdersData}
        parentAlert={props.generateAlert}
        statusCheck={props.statusCheck}
        getAccessibleRoutes={props.getAccessibleRoutes}
      />
    </>
  );
}

export default App;
