import {
  redHighlightColor,
  greenHighlightColor,
  orangeHighlightColor,
  sortByTypeCategoryItemId,
} from "./constants";

export const getColorBasedOnRowData = (
  row,
  _isAppQtyFieldsEditable,
  _isAppQtyFieldsShown
) => {
  let hightlightColor = "white";
  const inventory = !row.inventory && row.inventory != 0 ? null : row.inventory;
  const shipped_qty =
    !row.item_modified_qty || row.item_modified_qty == 0
      ? null
      : row.item_modified_qty;
  const received_qty =
    !row.received_qty && row.received_qty != 0 ? null : row.received_qty;

  const approved_qty =
    !row.approved_qty && row.approved_qty != 0 ? null : row.approved_qty;

  if (!shipped_qty) {
    return hightlightColor;
  }

  //After entering approved qty stage
  if (!_isAppQtyFieldsEditable && _isAppQtyFieldsShown) {
    if (row.showApprovedQtyField) {
      if (received_qty != row.approved_qty) {
        return redHighlightColor;
      }
      return orangeHighlightColor;
    }

    if (!row.showApprovedQtyField) {
      if (received_qty && shipped_qty && received_qty == shipped_qty) {
        return greenHighlightColor;
      } else {
        return orangeHighlightColor;
      }
    }
  }

  //After entering received qty
  if (_isAppQtyFieldsShown && _isAppQtyFieldsEditable) {
    if (row.showApprovedQtyField) {
      if (!approved_qty || approved_qty !== received_qty) {
        return redHighlightColor;
      } else {
        return orangeHighlightColor;
      }
    }
    if (!row.showApprovedQtyField) {
      if (received_qty === shipped_qty) {
        return greenHighlightColor;
      } else {
        return hightlightColor;
      }
    }
  }

  //When entering received qty
  if (!_isAppQtyFieldsShown) {
    if (received_qty && shipped_qty != received_qty) {
      return redHighlightColor;
    } else if (received_qty && shipped_qty == received_qty) {
      return greenHighlightColor;
    }
  }
  return hightlightColor;
};

export const getColorBasedOnRowDataTransitReturn = (
  row,
  _isAppQtyFieldsEditable,
  _isAppQtyFieldsShown
) => {
  let hightlightColor = "white";
  const inventory = !row.inventory && row.inventory != 0 ? null : row.inventory;
  const return_qty =
    !row.return_qty && row.return_qty != 0 ? null : row.return_qty;
  const received_qty =
    !row.received_qty && row.received_qty != 0 ? null : row.received_qty;

  const approved_qty =
    !row.approved_qty && row.approved_qty != 0 ? null : row.approved_qty;

  //After entering approved qty stage
  if (!_isAppQtyFieldsEditable && _isAppQtyFieldsShown) {
    if (row.showApprovedQtyField) {
      if (received_qty != row.approved_qty) {
        return redHighlightColor;
      }
      return orangeHighlightColor;
    }

    if (!row.showApprovedQtyField) {
      if (received_qty && return_qty && inventory === return_qty) {
        return greenHighlightColor;
      } else if (inventory !== return_qty) {
        return orangeHighlightColor;
      } else {
        return hightlightColor;
      }
    }
  }

  //After entering received qty
  if (_isAppQtyFieldsShown && _isAppQtyFieldsEditable) {
    if (row.showApprovedQtyField) {
      if (!approved_qty || approved_qty !== received_qty) {
        return redHighlightColor;
      } else {
        return orangeHighlightColor;
      }
    }
    if (!row.showApprovedQtyField) {
      if (received_qty && return_qty && inventory === return_qty) {
        return greenHighlightColor;
      } else if (inventory !== return_qty) {
        return orangeHighlightColor;
      } else {
        return hightlightColor;
      }
    }
  }

  //When entering received qty
  if (!_isAppQtyFieldsShown) {
    if (return_qty !== inventory && received_qty !== return_qty) {
      return redHighlightColor;
    } else if (return_qty !== inventory && received_qty === return_qty) {
      return orangeHighlightColor;
    } else {
      return greenHighlightColor;
    }
  }
  return hightlightColor;
};

export const checkForApprovedQtyDiscrepancy = (
  row,
  _isAppQtyFieldsEditable,
  _isAppQtyFieldsShown,
  type
) => {
  if (type === "transit") {
    if (
      (row.received_qty || row.received_qty === 0) &&
      row.item_modified_qty !== row.received_qty
    ) {
      return true;
    }

    if (_isAppQtyFieldsShown && !_isAppQtyFieldsEditable) {
      if (row.showApprovedQtyField && row.approved_qty !== row.received_qty) {
        return true;
      }
    }
    return false;
  } else if (type === "return") {
    const inventory =
      !row.inventory && row.inventory != 0 ? null : row.inventory;
    const return_qty =
      !row.return_qty && row.return_qty != 0 ? null : row.return_qty;

    if (
      !_isAppQtyFieldsShown &&
      (!inventory || !return_qty || inventory !== return_qty)
    ) {
      return true;
    }
    if (_isAppQtyFieldsShown && (row.isNewItem || return_qty != inventory)) {
      return true;
    }
    if (_isAppQtyFieldsShown) {
      if (row.showApprovedQtyField && row.approved_qty !== row.received_qty) {
        return true;
      }
    }
    return false;
  }
};
export const checkForLooseItemsDiscrepancy = (
  row,
  _isAppFieldsEditable,
  _isAppFieldsShown
) => {
  if (row.receivedToggleVal === "N") {
    return true;
  }
  if (_isAppFieldsShown && !_isAppFieldsEditable) {
    if (row.approvedToggleVal === "N" || row.receivedToggleVal === "N") {
      return true;
    }
  }
  return false;
};
export const getLooseItemRowColor = (
  row,
  _isAppFieldsEditable,
  _isAppFieldsShown
) => {
  if (row.receivedToggleVal === "N") {
    if (row.approvedToggleVal === "none") {
      return redHighlightColor;
    } else if (row.approvedToggleVal === "Y") {
      return orangeHighlightColor;
    } else {
      return redHighlightColor;
    }
  } else if (row.receivedToggleVal === "Y") {
    return greenHighlightColor;
  }

  return "White";
};
