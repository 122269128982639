import React, { useState, useEffect, useRef } from 'react'
import { Table, Form, Alert, Button,Modal } from 'react-bootstrap'
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import {support_portal_url} from '../../Configs/mysqlconfig';
import Select from 'react-select';

function formatDate(date) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

Date.prototype.timeNow = function () {
     return ((this.getHours() < 10)?"0":"") + this.getHours() +":"+ ((this.getMinutes() < 10)?"0":"") + this.getMinutes() +":"+ ((this.getSeconds() < 10)?"0":"") + this.getSeconds();
}

function UpdateLoosePackModal({updateDetails,updateOrder,deliveryPlanDate,...props}) {
  // console.log(updateOrder);
  // console.log(updateDetails);
    const [itemDetailsObject, setItemDetailsObject] = useState({})
    const [shortItemOptions,setShortItemOptions] = useState([]);
    const [shortItemQuantity,setShortItemQuantity] = useState([]);
    const [orderQty,setOrderQty] = useState([]);
    const [maxNumBags,setMaxNumBags] = useState(10);
    const [numBagsOptions,setNumBagsOptions] = useState([]);

    const weighttwoRef = useRef();
    const weightthreeRef=useRef();
    const weight4Ref = useRef();
    const weight5Ref = useRef();
    const weight6Ref = useRef();
    const weight7Ref = useRef();
    const weight8Ref = useRef();
    const weight9Ref = useRef();
    const weight10Ref = useRef();
    const shortitemref = useRef();
    const shortItemFlagRef = useRef();
    const shortSelectedItemRef = useRef();

    useEffect(() => {
      setShortItemOptions([]);
      if(updateOrder==true){
        fetchShortItems();
      }
    },[updateOrder])

    const fetchShortItems = () => {
      let shortItemList=updateDetails['item_disc_list'];
      weighttwoRef.current.style.display='none';
      weightthreeRef.current.style.display='none';
      weight4Ref.current.style.display='none';
      weight5Ref.current.style.display='none';
      weight6Ref.current.style.display='none';
      weight7Ref.current.style.display='none';
      weight8Ref.current.style.display='none';
      weight9Ref.current.style.display='none';
      weight10Ref.current.style.display='none';
      if(updateDetails["flagchecked"]==true){
        shortitemref.current.style.display='table-row';
      }else{
        shortitemref.current.style.display='none';
      }

      let origShortItems=updateDetails['shortitem'];
      let origiinalShortItemsObject={};
      if(origShortItems){
        shortitemref.current.style.display='table-row';
        shortItemFlagRef.current.checked=true;
        let origShortItemsList=origShortItems.split(',');
        for(let z=0;z<origShortItemsList.length;z++){
          let origshortitem=origShortItemsList[z];
          let origshortitemlist=origshortitem.split(':');
          let origshortitemdesc=origshortitemlist[0].trim();
          let origshortitemweight=origshortitemlist[1];
          if(origshortitemweight.includes("KG")){
            origshortitemweight=origshortitemweight.replace(' KG','');
            // console.log(origshortitemweight);
            origiinalShortItemsObject[origshortitemdesc]=origshortitemweight;
          }
        }
      }

      let shortItemDiscOptions=[];
      if(shortItemList){
        for(let i=0;i<shortItemList.length;i++){
            let shortitemstr = shortItemList[i];

            let ele={ "value": shortitemstr, "label": shortitemstr };
            let flag=false;
            for(let itm in origiinalShortItemsObject){
              if(itm==shortitemstr){
                console.log(document.getElementById(itm));
                // document.getElementById(itm).readOnly = false;
                ele['checked']=true;
                ele['weight']=origiinalShortItemsObject[itm];
                flag=true;
              }
            }
            if(flag==false){
              ele['checked']=false;
              ele['weight']="0";
            }
          shortItemDiscOptions.push(ele);
        }
        // console.log(shortItemDiscOptions);
      }


      let numbagsoptionslist=[];
      for(let k=0;k<=maxNumBags;k++){
        numbagsoptionslist.push({"value":k,"label":k});
      }
      setShortItemOptions(shortItemDiscOptions);
      setNumBagsOptions(numbagsoptionslist);

      handleWeightVisibilty(updateDetails.numbags?updateDetails.numbags:1);

      setShortItemQuantity([]);
      setItemDetailsObject({});
      setOrderQty(updateDetails.orderqtylist);
      // console.log(shortItemOptions);
    }

    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_LEFT,
    });

    const updateLoosePackDetails = () => {
      // console.log('updateLoosePackDetails');
      console.log(itemDetailsObject);

      if(itemDetailsObject["weight1"]==undefined && updateDetails.weight1==0){
        toast("Please enter weight1", { type: toast.TYPE.ERROR });
      }else if(itemDetailsObject["packedby"]==undefined && updateDetails.packedby=='' && !sessionStorage.getItem("packedby")){
        toast("Please select packedby", { type: toast.TYPE.ERROR });
      }else if(itemDetailsObject["weight2"]==undefined && itemDetailsObject["numbags"]>=2 && updateDetails.weight2==0){
        toast("Please enter weight2", { type: toast.TYPE.ERROR });
      }else if(itemDetailsObject["weight3"]==undefined && itemDetailsObject["numbags"]>=3 && updateDetails.weight3==0){
        toast("Please enter weight3", { type: toast.TYPE.ERROR });
      }else if(itemDetailsObject["weight4"]==undefined && itemDetailsObject["numbags"]>=4 && updateDetails.weight4==0){
        toast("Please enter weight4", { type: toast.TYPE.ERROR });
      }else if(itemDetailsObject["weight5"]==undefined && itemDetailsObject["numbags"]>=5 && updateDetails.weight5==0){
        toast("Please enter weight5", { type: toast.TYPE.ERROR });
      }else if(itemDetailsObject["weight6"]==undefined && itemDetailsObject["numbags"]>=6 && updateDetails.weight6==0){
        toast("Please enter weight6", { type: toast.TYPE.ERROR });
      }else if(itemDetailsObject["weight7"]==undefined && itemDetailsObject["numbags"]>=7 && updateDetails.weight7==0){
        toast("Please enter weight7", { type: toast.TYPE.ERROR });
      }else if(itemDetailsObject["weight8"]==undefined && itemDetailsObject["numbags"]>=8 && updateDetails.weight8==0){
        toast("Please enter weight8", { type: toast.TYPE.ERROR });
      }else if(itemDetailsObject["weight9"]==undefined && itemDetailsObject["numbags"]>=9 && updateDetails.weight9==0){
        toast("Please enter weight9", { type: toast.TYPE.ERROR });
      }else if(itemDetailsObject["weight10"]==undefined && itemDetailsObject["numbags"]>=10 && updateDetails.weight10==0){
        toast("Please enter weight10", { type: toast.TYPE.ERROR });
      }else{
        let finalele={};

        let shortQuantSet=new Set();
        let shortitemmquantstr='';
        for(let z=shortItemQuantity.length-1;z>=0;z--){
          if(!shortQuantSet.has(shortItemQuantity[z]['item_disc'])){
            shortitemmquantstr=shortitemmquantstr+shortItemQuantity[z]['item_disc']+' :'+shortItemQuantity[z]['quantity']+' KG,';
            shortQuantSet.add(shortItemQuantity[z]['item_disc']);
          }
        }


        // console.log('shortitem');
        // console.log(updateDetails.shortitem);
        let previous_short_item=updateDetails.shortitem;
        if(previous_short_item && previous_short_item!=''){
          let previous_short_item_list=previous_short_item.split(',');
          for(let x=0;x<previous_short_item_list.length;x++){
            let previous_short_item_value=previous_short_item_list[x];
            let previous_short_item_desc=previous_short_item_value.split(':')[0];
            previous_short_item_desc=previous_short_item_desc.trim();
            let previous_short_item_quantity=previous_short_item_value.split(':')[1];
            if(!shortQuantSet.has(previous_short_item_desc)){
              shortitemmquantstr=shortitemmquantstr+previous_short_item_desc+' :'+previous_short_item_quantity+',';
            }
          }
        }
        shortitemmquantstr=shortitemmquantstr.slice(0,-1);
        // console.log(shortitemmquantstr);
        let numbags=itemDetailsObject["numbags"]!=undefined?itemDetailsObject["numbags"]:(updateDetails.numbags?updateDetails.numbags:1);
        let totalWeight=0;
        let weight1=itemDetailsObject["weight1"]!=undefined?itemDetailsObject["weight1"]:(updateDetails.weight1?updateDetails.weight1:0);
        let weight2=itemDetailsObject["weight2"]!=undefined?itemDetailsObject["weight2"]:(updateDetails.weight2?updateDetails.weight2:0);
        let weight3=itemDetailsObject["weight3"]!=undefined?itemDetailsObject["weight3"]:(updateDetails.weight3?updateDetails.weight3:0);
        let weight4=itemDetailsObject["weight4"]!=undefined?itemDetailsObject["weight4"]:(updateDetails.weight4?updateDetails.weight4:0);
        let weight5=itemDetailsObject["weight5"]!=undefined?itemDetailsObject["weight5"]:(updateDetails.weight5?updateDetails.weight5:0);
        let weight6=itemDetailsObject["weight6"]!=undefined?itemDetailsObject["weight6"]:(updateDetails.weight6?updateDetails.weight6:0);
        let weight7=itemDetailsObject["weight7"]!=undefined?itemDetailsObject["weight7"]:(updateDetails.weight7?updateDetails.weight7:0);
        let weight8=itemDetailsObject["weight8"]!=undefined?itemDetailsObject["weight8"]:(updateDetails.weight8?updateDetails.weight8:0);
        let weight9=itemDetailsObject["weight9"]!=undefined?itemDetailsObject["weight9"]:(updateDetails.weight9?updateDetails.weight9:0);
        let weight10=itemDetailsObject["weight10"]!=undefined?itemDetailsObject["weight10"]:(updateDetails.weight10?updateDetails.weight10:0);

        totalWeight=parseFloat(weight1)+parseFloat(weight2)+parseFloat(weight3)+parseFloat(weight4)+parseFloat(weight5)+parseFloat(weight6)+parseFloat(weight7)+parseFloat(weight8)+parseFloat(weight9)+parseFloat(weight10);

        let packedBy=itemDetailsObject["packedby"]!=undefined?itemDetailsObject["packedby"]:(updateDetails.packedby?updateDetails.packedby:sessionStorage.getItem("packedby")?sessionStorage.getItem("packedby"):"");

        finalele["weightbags"]=(totalWeight).toFixed(2);
        finalele["packedby"]=packedBy;
        finalele["numbags"]=numbags;
        finalele["shortitem"]=shortitemmquantstr;
        finalele["weight1"]=weight1;
        finalele["weight2"]=weight2;
        finalele["weight3"]=weight3;
        finalele["weight4"]=weight4;
        finalele["weight5"]=weight5;
        finalele["weight6"]=weight6;
        finalele["weight7"]=weight7;
        finalele["weight8"]=weight8;
        finalele["weight9"]=weight9;
        finalele["weight10"]=weight10;
        finalele["bagid"]=updateDetails.bagid?updateDetails.bagid:"";
        finalele["flagchecked"]=itemDetailsObject["flagchecked"]!=undefined?itemDetailsObject["flagchecked"]:(updateDetails.flagchecked?updateDetails.flagchecked:false);

        console.log(finalele);
        // let datetimestamp=formatDate(deliveryPlanDate);
        let delivrydate=formatDate(new Date(deliveryPlanDate));
        let currtime=new Date().timeNow();
        let currdatetime = formatDate(new Date())+' '+currtime ;


        let apiele={};
        let usermail=JSON.parse(sessionStorage.getItem(`user`))[0].email;
        apiele['delivery_plan_date']=delivrydate;
        apiele['order_date']=updateDetails.order_date_conv;
        apiele['order_no']=updateDetails.order_number;
        apiele['numbags']=numbags;
        apiele['weight1']=weight1;
        apiele['weight2']=weight2;
        apiele['weight3']=weight3;
        apiele['weight4']=weight4;
        apiele['weight5']=weight5;
        apiele['weight6']=weight6;
        apiele['weight7']=weight7;
        apiele['weight8']=weight8;
        apiele['weight9']=weight9;
        apiele['weight10']=weight10;
        apiele['shortitem']=shortitemmquantstr;
        apiele['packedby']=packedBy;
        apiele['created_at']=currdatetime;
        apiele['updated_at']=currdatetime;
        apiele['created_by']=usermail;
        apiele['updated_by']=usermail;
        apiele['bagid']=updateDetails.bagid?updateDetails.bagid:"";

        // console.log(apiele);
        console.log(JSON.stringify(apiele));

        fetch(`${support_portal_url}/api/addloosepackorder`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': sessionStorage.getItem("apiToken")
            },
            body: JSON.stringify(apiele)
        }).then(res => {
            return res.json()
        }).then(data => {
            // console.log(data);
            if(data['success']==1){
              props.onHide();
              props.callback(finalele);
              toast("Updated order successfully", { type: toast.TYPE.SUCCESS });
            }else{
              let errMessage=data['message']?data['message']:"";
              toast("Couldn't update the order : "+errMessage, { type: toast.TYPE.ERROR });
              if(data['status']==401 || data['status']==403){
                sessionStorage.clear();
                window.location.href="/"
              }else{
                props.onHide();
                props.callback(finalele);
              }
            }

        }).catch(err => {
          props.onHide();
          props.callback(finalele);
          toast("Couldn't update the order : "+err, { type: toast.TYPE.ERROR });
        })
      }

    }

    const generateRandomBagId = () => {
      let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      let charactersLength = characters.length;
      let firstchar=characters.charAt(Math.floor(Math.random() * charactersLength));
      let secchar = Math.floor(Math.random() * 10);
      let thirdchar = Math.floor(Math.random() * 10);
      let fourthchar = Math.floor(Math.random() * 10);
      let bagid=firstchar+secchar+thirdchar+fourthchar;
      return bagid;
    }

    const handleWeightVisibilty = (numBagValue) => {
      if(numBagValue==1){
        weighttwoRef.current.style.display='none';
        weightthreeRef.current.style.display='none';
        weight4Ref.current.style.display='none';
        weight5Ref.current.style.display='none';
        weight6Ref.current.style.display='none';
        weight7Ref.current.style.display='none';
        weight8Ref.current.style.display='none';
        weight9Ref.current.style.display='none';
        weight10Ref.current.style.display='none';
      }else if(numBagValue==2){
        weighttwoRef.current.style.display='table-row';
        weightthreeRef.current.style.display='none';
        weight4Ref.current.style.display='none';
        weight5Ref.current.style.display='none';
        weight6Ref.current.style.display='none';
        weight7Ref.current.style.display='none';
        weight8Ref.current.style.display='none';
        weight9Ref.current.style.display='none';
        weight10Ref.current.style.display='none';
      }else if(numBagValue==3){
        weighttwoRef.current.style.display='table-row';
        weightthreeRef.current.style.display='table-row';
        weight4Ref.current.style.display='none';
        weight5Ref.current.style.display='none';
        weight6Ref.current.style.display='none';
        weight7Ref.current.style.display='none';
        weight8Ref.current.style.display='none';
        weight9Ref.current.style.display='none';
        weight10Ref.current.style.display='none';
      }else if(numBagValue==4){
        weighttwoRef.current.style.display='table-row';
        weightthreeRef.current.style.display='table-row';
        weight4Ref.current.style.display='table-row';
        weight5Ref.current.style.display='none';
        weight6Ref.current.style.display='none';
        weight7Ref.current.style.display='none';
        weight8Ref.current.style.display='none';
        weight9Ref.current.style.display='none';
        weight10Ref.current.style.display='none';
      }else if(numBagValue==5){
        weighttwoRef.current.style.display='table-row';
        weightthreeRef.current.style.display='table-row';
        weight4Ref.current.style.display='table-row';
        weight5Ref.current.style.display='table-row';
        weight6Ref.current.style.display='none';
        weight7Ref.current.style.display='none';
        weight8Ref.current.style.display='none';
        weight9Ref.current.style.display='none';
        weight10Ref.current.style.display='none';
      }else if(numBagValue==6){
        weighttwoRef.current.style.display='table-row';
        weightthreeRef.current.style.display='table-row';
        weight4Ref.current.style.display='table-row';
        weight5Ref.current.style.display='table-row';
        weight6Ref.current.style.display='table-row';
        weight7Ref.current.style.display='none';
        weight8Ref.current.style.display='none';
        weight9Ref.current.style.display='none';
        weight10Ref.current.style.display='none';
      }else if(numBagValue==7){
        weighttwoRef.current.style.display='table-row';
        weightthreeRef.current.style.display='table-row';
        weight4Ref.current.style.display='table-row';
        weight5Ref.current.style.display='table-row';
        weight6Ref.current.style.display='table-row';
        weight7Ref.current.style.display='table-row';
        weight8Ref.current.style.display='none';
        weight9Ref.current.style.display='none';
        weight10Ref.current.style.display='none';
      }else if(numBagValue==8){
        weighttwoRef.current.style.display='table-row';
        weightthreeRef.current.style.display='table-row';
        weight4Ref.current.style.display='table-row';
        weight5Ref.current.style.display='table-row';
        weight6Ref.current.style.display='table-row';
        weight7Ref.current.style.display='table-row';
        weight8Ref.current.style.display='table-row';
        weight9Ref.current.style.display='none';
        weight10Ref.current.style.display='none';
      }else if(numBagValue==9){
        weighttwoRef.current.style.display='table-row';
        weightthreeRef.current.style.display='table-row';
        weight4Ref.current.style.display='table-row';
        weight5Ref.current.style.display='table-row';
        weight6Ref.current.style.display='table-row';
        weight7Ref.current.style.display='table-row';
        weight8Ref.current.style.display='table-row';
        weight9Ref.current.style.display='table-row';
        weight10Ref.current.style.display='none';
      }else if(numBagValue==10){
        weighttwoRef.current.style.display='table-row';
        weightthreeRef.current.style.display='table-row';
        weight4Ref.current.style.display='table-row';
        weight5Ref.current.style.display='table-row';
        weight6Ref.current.style.display='table-row';
        weight7Ref.current.style.display='table-row';
        weight8Ref.current.style.display='table-row';
        weight9Ref.current.style.display='table-row';
        weight10Ref.current.style.display='table-row';
      }
    }

    const handleShortItemQuant = (itemDisc,itemQuant) => {
      let ele={};
      ele['item_disc']=itemDisc;
      ele['quantity']=itemQuant;
      setShortItemQuantity(shortItemQuantity.concat(ele));
    }

    const handleShortItemChecked = (itemDesc,itemChecked) => {
      console.log('handleShortItemChecked');
      console.log(itemDesc);
      console.log(itemChecked);
      document.getElementById(itemDesc).readOnly = !itemChecked;
    }

    const getPackedBy = () => {
      return updateDetails.packedby?updateDetails.packedby:(sessionStorage.getItem("packedby")?sessionStorage.getItem("packedby"):"");
    }

    return (<>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    Update Loose Pack Order of {updateDetails.order_number}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Table>
                    <tbody>
                        <tr>
                            <td>No of Bags</td>
                            <td><Select defaultValue={{"value":updateDetails.numbags?updateDetails.numbags:1,"label":updateDetails.numbags?updateDetails.numbags:1}} options={numBagsOptions} onChange={(val)=>{
                              handleWeightVisibilty(val.value); setItemDetailsObject({...itemDetailsObject,"numbags":(val.value)}) }} /></td>
                        </tr>
                        <tr>
                            <td>Weight1</td>
                            <td><Form.Control type="number" defaultValue={updateDetails.weight1} onChange={(e)=>setItemDetailsObject({...itemDetailsObject,"weight1":(e.target.value)})}/></td>
                        </tr>
                        <tr ref={weighttwoRef}>
                            <td>Weight2</td>
                            <td><Form.Control type="number" defaultValue={updateDetails.weight2} onChange={(e)=>setItemDetailsObject({...itemDetailsObject,"weight2":(e.target.value)})}/></td>
                        </tr>
                        <tr ref={weightthreeRef}>
                            <td>Weight3</td>
                            <td><Form.Control type="number" defaultValue={updateDetails.weight3} onChange={(e)=>setItemDetailsObject({...itemDetailsObject,"weight3":(e.target.value)})}/></td>
                        </tr>
                        <tr ref={weight4Ref}>
                            <td>Weight4</td>
                            <td><Form.Control type="number" defaultValue={updateDetails.weight4} onChange={(e)=>setItemDetailsObject({...itemDetailsObject,"weight4":(e.target.value)})}/></td>
                        </tr>
                        <tr ref={weight5Ref}>
                            <td>Weight5</td>
                            <td><Form.Control type="number" defaultValue={updateDetails.weight5} onChange={(e)=>setItemDetailsObject({...itemDetailsObject,"weight5":(e.target.value)})}/></td>
                        </tr>
                        <tr ref={weight6Ref}>
                            <td>Weight6</td>
                            <td><Form.Control type="number" defaultValue={updateDetails.weight6} onChange={(e)=>setItemDetailsObject({...itemDetailsObject,"weight6":(e.target.value)})}/></td>
                        </tr>
                        <tr ref={weight7Ref}>
                            <td>Weight7</td>
                            <td><Form.Control type="number" defaultValue={updateDetails.weight7} onChange={(e)=>setItemDetailsObject({...itemDetailsObject,"weight7":(e.target.value)})}/></td>
                        </tr>
                        <tr ref={weight8Ref}>
                            <td>Weight8</td>
                            <td><Form.Control type="number" defaultValue={updateDetails.weight8} onChange={(e)=>setItemDetailsObject({...itemDetailsObject,"weight8":(e.target.value)})}/></td>
                        </tr>
                        <tr ref={weight9Ref}>
                            <td>Weight9</td>
                            <td><Form.Control type="number" defaultValue={updateDetails.weight9} onChange={(e)=>setItemDetailsObject({...itemDetailsObject,"weight9":(e.target.value)})}/></td>
                        </tr>
                        <tr ref={weight10Ref}>
                            <td>Weight10</td>
                            <td><Form.Control type="number" defaultValue={updateDetails.weight10} onChange={(e)=>setItemDetailsObject({...itemDetailsObject,"weight10":(e.target.value)})}/></td>
                        </tr>
                        <tr>
                            <td>Short Item</td>
                            <td><input type="checkbox" ref={shortItemFlagRef} defaultChecked={updateDetails.flagchecked} onChange={(e)=>{
                              if(e.target.checked){
                                shortitemref.current.style.display='table-row';
                              }else{
                                shortitemref.current.style.display='none';
                              }
                              setItemDetailsObject({...itemDetailsObject,"flagchecked":(e.target.checked)})
                             }}/></td>
                        </tr>
                        <div ref={shortitemref}>
                          <tr>
                              <td>Item</td>
                              <td>Short Qty in KG/PC</td>
                              <td>Order Qty</td>
                          </tr>
                        {(shortItemOptions.length > 0)? shortItemOptions.map((items,index) => (
                          <tr key={index}>
                                <td>{items.value}</td>
                                <td><InputGroup>
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <Input addon type="checkbox" defaultChecked={items.checked} name={items.value} onChange={(e)=>{ handleShortItemChecked(items.value,e.target.checked) }} />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input type="number" id={items.value} defaultValue={items.weight} name={items.value} readOnly onChange={(e)=>{ handleShortItemQuant(e.target.name,e.target.value) }} />
                                  <InputGroupAddon addonType="append">
                                    <InputGroupText>
                                      KG
                                    </InputGroupText>
                                  </InputGroupAddon>
                                </InputGroup></td>
                                <td>{orderQty[index]}</td>
                            </tr>
                        )):null }
                        </div>
                        <tr>
                            <td>PackedBy</td>
                            <td><Select defaultValue={{"value":getPackedBy(),"label":getPackedBy()}} options={[{"value":"Vikram Gole","label":"Vikram Gole"},{"value":"Abhijit Kirdat","label":"Abhijit Kirdat"},{"value":"Arun Yadav","label":"Arun Yadav"},{"value":"Nilesh Pokharkar","label":"Nilesh Pokharkar"},{"value":"Vishal Mane","label":"Vishal Mane"},{"value":"Shivaji Gholap","label":"Shivaji Gholap"}]} onChange={(val)=>{ setItemDetailsObject({...itemDetailsObject,"packedby":(val.value)}); sessionStorage.setItem("packedby",val.value); }}/></td>
                        </tr>
                        <tr>
                            <td>Bag ID</td>
                            <td><Form.Control value={updateDetails.bagid} readOnly/></td>
                        </tr>
                      </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"success"} onClick={updateLoosePackDetails}>Submit</Button>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default UpdateLoosePackModal
