import React, { useState, useEffect } from "react";
import { Table, Form, Alert, Button, Modal } from "react-bootstrap";


function HighValueItemsModal({ crateDetails,route, ...props }) {
  console.log("Crate data ", crateDetails);

 

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4>
              Crate Details For Route : <span>{route}</span>
            </h4>
            <h4>
              Crate Id : <span>{crateDetails[0].crate_id} </span>
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {crateDetails.length > 0 ? (
            <Table>
              <thead>
                <tr>
                  <th>Item Image</th>
                  <th>Brand</th>
                  <th>Description</th>
                  <th>Weight</th>
                  {/* <th>Picked Qty</th>
                  <th>Packed in Bag</th> */}
                </tr>
              </thead>
              <tbody>
                {crateDetails.length > 0
                  ? crateDetails.map((item, index) => {
                      return (
                       <>
                      <tr>

                      <td
                                  style={{
                                    height: "100px",
                                    width: "100px",
                                  }}
                                >
                                  {item.product_image_url ? (
                                    <img
                                      style={{
                                        height: "100%",
                                        width: "100%",
                                        display: "inline-block",
                                        textAlign: "center",
                                      }}
                                      src={item.product_image_url}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </td>
                 
                        <td>{item.brand}</td>
                        <td>{item.long_description}</td>
                        <td>{item.weight} Kg(s)</td>
                        {/* <td>{item}</td>
                        <td>{item}</td> */}
                      </tr>

                       </>
                    )}) : null }
              </tbody>
            </Table>
          ) : (
            <div></div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default HighValueItemsModal;
