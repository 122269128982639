import React,{Component} from 'react';
import {Route,Redirect} from 'react-router-dom';
import IdleTimeOutModal from './IdleModal'
import IdleTimer from 'react-idle-timer';
import PageNotFound from './PageNotFound';
import { validateIsOperationUser, validateIsBillingUser, validateIsAdminUser,validateIsPackingUser,validateIsFinanceUser } from './components/UserCredentials'
import fire from './Configs/firebase'

let allowedRoutesSet=new Set();


class ProtectedRoute extends Component {
  constructor(props){
      super(props)

      this.state = {
          timeout:1000 * 60 * 60, // 60 minute
          showModal: false,
          userLoggedIn: false
      }
      sessionStorage.setItem("isTimedOut","N");

      this.idleTimer = null
      this.onAction = this._onAction.bind(this)
      this.onActive = this._onActive.bind(this)
      this.onIdle = this._onIdle.bind(this)
      this.handleClose = this.handleClose.bind(this)

      let userType = sessionStorage.getItem('originalUserType');

      if(userType && this.state.userLoggedIn==false){
        fire.database().ref(`User_Role_Access/DeliveryPortal/${userType}/allowed_routes`).once('value').then((snapshot) => {
            let userRoleDetails = snapshot.val();
            // console.log(userRoleDetails);

            if(userRoleDetails){
              allowedRoutesSet=new Set();
              let userRoutes=Object.values(userRoleDetails);
              for(let i=0;i<userRoutes.length;i++){
                let route=`/${userRoutes[i]}`;
                if(!allowedRoutesSet.has(route)){
                  allowedRoutesSet.add(route);
                }
              }

              sessionStorage.setItem("allowedRoutes",JSON.stringify(userRoutes));
              // console.log(allowedRoutesSet);
            }else{
              sessionStorage.setItem("allowedRoutes",JSON.stringify([]));
            }
            this.setState({ userLoggedIn:true });
        })
      }
  }

  _onAction(e) {
    // console.log('user did something', e)
    sessionStorage.removeItem("modalOpenAt",Date.now());
    sessionStorage.setItem("isTimedOut","N");
  }

  _onActive(e) {
    // console.log('user is active', e)
    sessionStorage.removeItem("modalOpenAt",Date.now());
    sessionStorage.setItem("isTimedOut","N");
  }

  _onIdle(e) {

    const isTimedOut = sessionStorage.getItem("isTimedOut")
    if (isTimedOut=="Y") {
        sessionStorage.clear();
        window.location.href="/";
    } else {
      this.setState({showModal: true,timeout:1000 * 60 * 60})
      this.idleTimer.start();
      sessionStorage.setItem("isTimedOut","Y");
    }

  }

  handleClose() {
    sessionStorage.setItem("isTimedOut","N");
    sessionStorage.removeItem("modalOpenAt",Date.now());
    this.setState({showModal: false,timeout:1000 * 60 * 60})
  }

  render() {
    const { component: Component, ...props } = this.props
    var sessionStartTimestamp = Number(sessionStorage.getItem("session-start-date"));
    let currentTime=Date.now();
    let ttl=(currentTime-sessionStartTimestamp)/1000;
    // console.log("ttl:"+ttl);
    //86400 -1 day
    if(ttl>86400 && sessionStartTimestamp>0){
      sessionStorage.clear();
      window.location.href="/";
    }

    return (
      <>
      <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={this.state.timeout} />

      {this.state.showModal && sessionStorage.getItem("isTimedOut")=="Y" && !sessionStorage.getItem("modalOpenAt") && <IdleTimeOutModal
          showModal={this.state.showModal}
          callback={this.handleClose}
      />}

      {sessionStorage.getItem(`user`) && validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email))?(
        <Route
            {...props}
            render={props => (
                sessionStorage.getItem('user')?(<Component {...props} />):<Redirect to='/login' />
            )}
        />
      ):(this.state.userLoggedIn==true && <Route
          {...props}
          render={props => {
            let url = props.match?.url || ''
              
            //Add this route to the allowed routes in firebase
            if(sessionStorage.getItem('userType') === 'Operation' && url === '/transit-stock-transfer'){
              if(!allowedRoutesSet.has('/transit-stock-transfer')){
                allowedRoutesSet.add('/transit-stock-transfer');
              }
            }
            return sessionStorage.getItem('user')?(
              allowedRoutesSet.has(props.match.url)?<Component {...props} /> : <PageNotFound />):
              <Redirect to='/login' />
          }}
        />
      )}

      </>
    )
  }
}

export default ProtectedRoute
