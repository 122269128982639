import React, { useState, useEffect } from 'react'
import { Table, Form, Alert, Button,Modal } from 'react-bootstrap'
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import Backdrop from '../backdrop/backdrop'
import fire from '../../Configs/firebase'

function VehicleDispatchModalPopup({driver_id,selectDate,...props}) {
  let text=`Do you want change as 'Dispatched Vehicle'? You won't be able to edit Delivery Orders for the Route-${driver_id}.`;

  const updateDispatchedVehicleFlag = () => {
    let driverAllocationPath = selectDate.getFullYear() + "/" + (selectDate.getMonth() + 1) + "/" + (selectDate.getDate() + "-" + (selectDate.getMonth() + 1)
      + "-" + selectDate.getFullYear())

    fire.database().ref("Driver_Allocation_History/" + driverAllocationPath).once("value", snapShot => {

      console.log(snapShot.val())

      if (snapShot.val()) {

        const driverInfo = snapShot.val()
        Object.keys(driverInfo).forEach(driverPhn => {
          let routeKey=driverInfo[driverPhn].current_Route_Id;
          if (driverInfo[driverPhn].current_Route_Id === driver_id && routeKey!="8888" && routeKey!="9999") {
            fire.database().ref(`Driver_Allocation_History/${driverAllocationPath}/${driverPhn}`).update({"dispatched_vehicle":true})
          }
        })

        toast(`Updated the dispatched vehicle flag status`, { type: toast.TYPE.SUCCESS })
        props.callback(driver_id);
        props.onHide();
      }
    })
  }

  return (<Modal
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
          >
              <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                  Dispatched Vehicle
                  </Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <div>
                {text}
              </div>

              </Modal.Body>
              <Modal.Footer>
                  <Button onClick={()=>{updateDispatchedVehicleFlag(); }}>OK</Button>
                  <Button onClick={()=>{props.onHide();}}>Close</Button>
              </Modal.Footer>
          </Modal>
  )
}

export default VehicleDispatchModalPopup
