import React, { useState, useMemo, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NavBar from "../../Navigation bar/Navbar";
import { Container, Row, Col, Button, Modal, Form } from "react-bootstrap";

import {
  useTable,
  useGlobalFilter,
  useFilters,
  useAsyncDebounce,
} from "react-table";
import { CSVLink } from "react-csv";

function TransporterSummaryT1({TableData, amount_per_service ,additional_amount,max_km_for_fixed_amt  } ) {
  const [show1, setShow1] = useState(false);
  let csv_data = useRef("");

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => {
    toast(" showing tost massage please remove this on  handleShow1 fun");
    setShow1(true);
  };


  function createCsv(data, columns) {
    let final_csv = [];
    for (let row of data) {
      let one_row = [];
      for (let col of columns) {
        one_row.push(row[col.accessor]);
      }
      final_csv.push(one_row);
    }

    let one_row = [];
    for (let col of columns) {
      one_row.push(col.accessor);
    }
    final_csv.unshift(one_row);

    csv_data.current = final_csv;
  }

  function filterData(data) {

    let obj = {};
    let data2 = [];
    let driver_obj = {} 

    //  driver level calculation for km kms_travelled 
    for (let dt of data) {

      if(dt.vehicle_status == "absent" || dt.vehicle_status == "vehicle_breakdown" ){
        continue
      }

      if (driver_obj[dt?.driver_Phone_Number] == undefined) {
        driver_obj[dt?.driver_Phone_Number] = {
          driver_sum_kms: 0,
        };
      }

      let curr_obj = driver_obj[dt?.driver_Phone_Number];
      let driver_sum_kms = curr_obj?.driver_sum_kms + Number(dt?.kms_travelled || 0);

      let new_obj = {
        driver_sum_kms: driver_sum_kms,
      }

      driver_obj[dt.driver_Phone_Number] = new_obj;

    }

    console.log('------------driver_obj ------------');
    console.log(driver_obj);
    console.log('----------- driver_obj -------------');


    // main calculation
    for (let dt of data) {

      if(dt.vehicle_status == "absent" || dt.vehicle_status == "vehicle_breakdown" ){
          continue
      }

      if (obj[dt.company_type] == undefined) { // for a Transporter
        obj[dt.company_type] = {
          driver_Phone_Numbers_str : "",
          transporter: dt.company_type,
          routes_serviced: 0,
          sum_kms: 0,
          additional_kms: 0,
          fixed_amt: 0,
          additional_amt: 0,
          Short_Cash_Amt: 0,
          stock_short_amt: 0,
          penalty_amt: 0,
          payable_amt: 0,
        };
      }

      let curr_obj = obj[dt.company_type];
      let sum_kms = curr_obj.sum_kms + Number(dt?.kms_travelled || 0);
      let Short_Cash_Amt =  Number(curr_obj?.Short_Cash_Amt) + Number(dt?.adjustments || 0);
      let stock_short_amt = Number(curr_obj?.stock_short_amt) + Number(dt?.short_items || 0);
      let penalty_amt = Number(curr_obj?.penalty_amt) + Number(dt?.penalty_amt || 0);
      let driver_Phone_Numbers_str = curr_obj.driver_Phone_Numbers_str  + dt.driver_Phone_Number + ","

      let new_obj = {
        sum_kms: sum_kms,
        transporter: curr_obj.transporter,
        routes_serviced: Number(curr_obj?.routes_serviced || 0) + 1,
        Short_Cash_Amt: Short_Cash_Amt,
        stock_short_amt: stock_short_amt,
        penalty_amt: penalty_amt,
        driver_Phone_Numbers_str : driver_Phone_Numbers_str
      };

      obj[dt.company_type] = new_obj;
    }

    console.log('------------ obj ------------');
    console.log(obj);
    console.log('----------- obj -------------');



    for (let ob in obj) {

      
      let current = obj[ob];
      let dpns = new Set(current.driver_Phone_Numbers_str.split(',')) 
      
      console.log('------------ dpns ------------');
      console.log(current.driver_Phone_Numbers_str);
      console.log(dpns);                            
      console.log('------------ dpns ------------'); 

      let  total_km_for_driver  =  0
      for ( let  k of dpns ){
        console.log(k);
        console.log(driver_obj[k]?.driver_sum_kms);
        total_km_for_driver += ((driver_obj[k]?.driver_sum_kms  - max_km_for_fixed_amt ) > 0) ? (driver_obj[k]?.driver_sum_kms  - max_km_for_fixed_amt ) : 0;
      }
      console.log( "total_km_for_driver " ,total_km_for_driver );
        
      let fixed_amt = Number(current?.routes_serviced) * amount_per_service;
      // let additional_kms = ((current.sum_kms - max_km_for_fixed_amt) > 0 ) ? current.sum_kms - max_km_for_fixed_amt : 0;

      let additional_kms = total_km_for_driver
      let additional_amt = additional_kms > 0 ? Number(additional_kms) * additional_amount : 0;
      let payable_amt = fixed_amt + additional_amt - current.Short_Cash_Amt - current.stock_short_amt -  current.penalty_amt;

      current["fixed_amt"] = fixed_amt;
      current["additional_kms"] = additional_kms;
      current["additional_amt"] = additional_amt;
      current["payable_amt"] = Number(payable_amt).toFixed(2);

      data2.push(current);

    }

    return data2;
    
  }

  function Table() {

    let data2 = filterData(TableData);
    const data = useMemo(() => data2, [TableData]);

    const columns = useMemo(
      () => [
        {
          Header: "Transporter",
          accessor: "transporter",
          Filter: SelectColumnFilter,
        },
        {
          Header: "Routes serviced",
          accessor: "routes_serviced",
        },
        // {
        //   Header: "driver_Phone_Number",
        //   accessor: "driver_Phone_Number",
        // },
        {
          Header: "Additional kms",
          accessor: "additional_kms",
        },
        {
          Header: "Fixed amt",
          accessor: "fixed_amt",
        },
        {
          Header: "Additional amt",
          accessor: "additional_amt",
        },
        {
          Header: "Short Cash Amt",
          accessor: "Short_Cash_Amt",
        },
        {
          Header: "Stock short amt",
          accessor: "stock_short_amt",
        },
        {
          Header: "Penalty amt",
          accessor: "penalty_amt",
        },
        {
          Header: "Payable amt",
          accessor: "payable_amt",
        },
      ],
      []
    );

    createCsv(data, columns);

    function SelectColumnFilter({
      column: { filterValue, setFilter, preFilteredRows, id },
    }) {
      const options = useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
          options.add(row.values[id]);
        });
        return [...options.values()];
      }, [id, preFilteredRows]);

      options.sort()
      
      return (
        <select
          value={filterValue}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
        >
          <option value="">All</option>
          {options.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </select>
      );
    }

    const defaultColumn = useMemo(
      () => ({
        Filter: () => null,
      }),
      []
    );

    const GlobalFilter = ({ filter, setFilter }) => {
      const [value, setValue] = useState(filter);
      const onChange = useAsyncDebounce((value) => {
        setFilter(value || undefined);
      }, 1000);
      return (
        <span>
          <input
            placeholder="scarch..."
            value={value || ""}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
          />
        </span>
      );
    };

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      setGlobalFilter,
      preGlobalFilteredRows,
    } = useTable({ columns, data, defaultColumn }, useFilters, useGlobalFilter);

    const { globalFilter } = state;

    return (
      <div>
        <Row>
          <Col>
            <GlobalFilter
              filter={globalFilter}
              setFilter={setGlobalFilter}
            ></GlobalFilter>
          </Col>
          <Col>
            <CSVLink
              className="btn btn-warning export_btn"
              filename={"Detailed_Report.csv"}
              data={csv_data.current}
            >
              Export Data
            </CSVLink>{" "}
          </Col>
          <Col></Col>
          <Col>{/* <Button variant="warning">Primary</Button>{" "} */}</Col>
          <Col>{/* <Button variant="success">Primary</Button>{" "} */}</Col>
          <Col>{/* <Button variant="warning">Primary</Button>{" "} */}</Col>
          <Col>{/* <Button variant="info">Primary</Button>{" "} */}</Col>
        </Row>
        <br />

        <table
          {...getTableProps()}
          style={{ border: "solid 1px blue", width: "100%" }}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{
                      borderBottom: "solid 3px red",
                      background: "aliceblue",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {column.render("Header")}
                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: "10px",
                          border: "solid 1px gray",
                          background: "papayawhip",
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <>
      <Table />
    </>
  );
}

export default TransporterSummaryT1;
