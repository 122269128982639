import React, { useState, useEffect, useRef } from 'react'
import { Table, Form, Alert, Button, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify';

function ProcessCancelModal({ ...props }) {

  toast.configure({
      autoClose: 4000,
      draggable: true,
      pauseOnHover: true,
      position: toast.POSITION.TOP_LEFT,
  });

  const formOrdersList = () => {
    let ordersStr=document.getElementById("orderNumbersList").value;
    console.log(ordersStr);

    if(ordersStr==''){
      return toast("Please enter order numbers", { type: toast.TYPE.ERROR })
    }
    let orderList=ordersStr.split('\n');
    console.log(orderList);

    orderList = orderList.filter(function(entry) { return entry.trim() != ''; });
    console.log(orderList);

    if(orderList.length>0){

      const cancelledOrders = orderList.filter(orderNumber => {
        const order = props.listedOrders.find(o => o.order_number == orderNumber.trim());
        return order && order.status == 'Store Cancelled';
      });
      if (cancelledOrders.length > 0) {
        toast(`Action allowed only for orders with statuses: processed, cancelled, or ordered. Store-cancelled orders cannot be processed: ${cancelledOrders.join(', ')}`, { type: toast.TYPE.ERROR });
      } else {
        // If no store cancelled orders, proceed with the update
        props.updateProcessCancelled(orderList);
      }
      }else{
        return toast("Please enter order number.", { type: toast.TYPE.ERROR })
      }

  }


  return (
      <div>
          <Modal
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
          >
              <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                      Process Cancelled Orders
                  </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div class="row">
                  <div class="form-group col-md-6">
                    <label>Order Numbers</label>
                    <textarea class="form-control" id="orderNumbersList" rows="10"></textarea>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                  <Button variant={"success"} onClick={formOrdersList}>OK</Button>
                  <Button onClick={props.onHide}>Close</Button>
              </Modal.Footer>
          </Modal>
      </div>
  )
}

export default ProcessCancelModal
