import React from "react";
import ReactExport from "react-data-export";
import { Button } from "react-bootstrap";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ExportToExcel extends React.Component {
  constructor(props) {
    super(props);
    this.sheet1 = this.props.sheet1;
    this.sheet2 = this.props.sheet2;
    this.sheet1Headers = this.props.sheet1Headers;
    this.sheet2Headers = this.props.sheet2Headers;

    // console.log("DEBUG sheet 1", this.sheet1);
    // console.log("DEBUG sheet 2", this.sheet2);
    // console.log("DEBUG sheet 1 Headers", this.sheet1Headers);
    // console.log("DEBUG sheet 2 Headers", this.sheet2Headers);

    this.filename = this.props.filename;
    this.sheet1Name = this.props.sheet1Name;
    this.sheet2Name = this.props.sheet2Name;
    this.sheet2DataSet = [
      {
        columns: this.formatColumnsForDataSet(this.sheet2Headers),
        data: this.formatRowsForDataSet(this.sheet2),
      },
    ];
  }
  formatColumnsForDataSet(headers) {
    const dataToReturn = [];
    headers.forEach((item, index) => {
      dataToReturn.push({ title: item.label });
    });

    return dataToReturn;
  }
  formatRowsForDataSet(tableData) {
    const dataToReturn = [];

    if (tableData.length === 0) {
      //Returns an empty row if no data is available so that the sheet headers is not affected by empty data
      dataToReturn.push([
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
      ]);
      return dataToReturn;
    }

    tableData.forEach((item, index) => {
      const cellStyle = item.visited_proximity
        ? { fill: { fgColor: { rgb: "FF00FF00" } } }
        : { fill: { fgColor: { rgb: "FFFF0000" } } };
      dataToReturn.push([
        { value: item.status },
        { value: item.route_no },
        { value: item.driver_name },
        { value: item.driver_no },
        { value: item.transporter },
        { value: item.shop_name },
        { value: item.area },
        { value: item.order_number },
        { value: item.order_date },
        { value: item.order_amt },
        { value: item.visited_proximity, style: cellStyle },
      ]);
    });
    return dataToReturn;
  }
  render() {
    return (
      <ExcelFile
        element={<Button variant="warning">Export</Button>}
        filename={this.filename}
      >
        <ExcelSheet data={this.sheet1} name={this.sheet1Name}>
          {Object.keys(this.sheet1Headers).map((key, index) => {
            return (
              <ExcelColumn
                key={index}
                label={this.sheet1Headers[key].label}
                value={this.sheet1Headers[key].value}
              />
            );
          })}
        </ExcelSheet>
        <ExcelSheet
          dataSet={this.sheet2DataSet}
          name={this.sheet2Name}
        ></ExcelSheet>
      </ExcelFile>
    );
  }
}

export default ExportToExcel;
