import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Dropdown, Navbar, Jumbotron, InputGroup, Button, DropdownButton, ButtonGroup, Table } from 'react-bootstrap'
import { InputGroupAddon } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import XLSX from 'xlsx'
import Backdrop from '../../backdrop/backdrop'

function OrderSummaryPage() {

    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [orderDetails, setOrderDetails] = useState("")
    const [orderStatus, setOrderStatus] = useState("")
    const [exportData, setExportData] = useState(false)
    const [orderSearch, setOrderSearch] = useState(false)
    const [getBackDrop,setBackDrop] = useState(false)



    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_CENTER,
    });

    const notify= (unavailability) => {

        if(unavailability)
        return toast("No Details Available for selected date range or with order status", { type: toast.TYPE.ERROR })

    }


    useEffect(() => {

        setBackDrop(true)
        const startDateConversion = `${startDate.getFullYear()}-${startDate.getMonth() + 1 < 10 ? "0" + (startDate.getMonth() + 1) : startDate.getMonth() + 1}-${startDate.getDate() < 0 ? "0" + startDate.getDate() : startDate.getDate()}`
        const endDateConversion = `${endDate.getFullYear()}-${endDate.getMonth() + 1 < 10 ? "0" + (endDate.getMonth() + 1) : endDate.getMonth() + 1}-${endDate.getDate() < 0 ? "0" + endDate.getDate() : endDate.getDate()}`
        fetch(`http://services2.superzop.com:3000/api/superzop/delivery/itemsbasedondeliverydate?start_date=${startDateConversion}&end_date=${endDateConversion}`,{ headers: { 'x-access-token' : sessionStorage.getItem('x-access-token') } }).then(res => {
            return res.json()
        }).then(data => { console.log(data); setOrderDetails(data);setBackDrop(false);if(!data.length>0){notify(true)} }).catch(err => console.log(err))

    }, [])



    const handleDateRangeChange = () => {

        if(startDate>endDate){

            setBackDrop(false)
            setOrderDetails("")
            return toast("Please enter a valid date range", { type: toast.TYPE.ERROR })

        }
        setOrderDetails("")
        const startDateConversion = `${startDate.getFullYear()}-${startDate.getMonth() + 1 < 10 ? "0" + (startDate.getMonth() + 1) : startDate.getMonth() + 1}-${startDate.getDate() < 0 ? "0" + startDate.getDate() : startDate.getDate()}`
        const endDateConversion = `${endDate.getFullYear()}-${endDate.getMonth() + 1 < 10 ? "0" + (endDate.getMonth() + 1) : endDate.getMonth() + 1}-${endDate.getDate() < 0 ? "0" + endDate.getDate() : endDate.getDate()}`
        if(!orderStatus || orderStatus==="reset"){

            fetch(`http://services2.superzop.com:3000/api/superzop/delivery/itemsbasedondeliverydate?start_date=${startDateConversion}&end_date=${endDateConversion}`,{ headers: { 'x-access-token' : sessionStorage.getItem('x-access-token') } }).then(res => {
                return res.json()
                }).then(data => { console.log(data);setOrderDetails(data);setBackDrop(false);if(!data.length>0){notify(true)} }).catch(err => console.log(err))
                return
        }
        fetch(`http://services2.superzop.com:3000/api/superzop/delivery/itemsbasedondeliverydate?start_date=${startDateConversion}&end_date=${endDateConversion}&status=${orderStatus ? orderStatus : "Delivered"}`,{ headers: { 'x-access-token' : sessionStorage.getItem('x-access-token') } }).then(res => {
        return res.json()
        }).then(data => { console.log(data);setOrderDetails(data);setBackDrop(false);if(!data.length>0){notify(true)} }).catch(err => console.log(err))
    }




    useEffect(() => {
        if (orderSearch || orderStatus)
            handleDateRangeChange()
    }, [orderSearch, orderStatus])

    let orderDetailsRecords = ["Delivery Date", "Vehicle No.", "Order No.", "Status", "Reason", "Item Details", "Item Id","Item Modified Qty","Item Secondary Reason","Item Undelivered Reason", "Item Status", "Delivered Qty", "Returned Qty", "Order Qty", "Order Value", "Order Date",
        "Payment Mode", "payment_cash_amount", "payment_cheque1_amount", "payment_cheque1_number", "payment_cheque1_date", "payment_cheque2_amount", "payment_cheque2_number", "payment_cheque2_date",
        "payment_card_amount", "payment_card_transactionid", "payment_paytm_amount", "payment_razorpay_amount", "payment_pending_payment_amount", "payment_adjustments",
        "Phone", "Priority", "Retailer Id", "Shop Name", "Total Amount", "Total Amount Paid", "loose_allocated_by", "vehicle_loaded_by","GST Charges","Variety",
        "itemDeliveredUpdatedAt","itemDeliveredUpdatedBy","itemModifiedUpdatedAt","itemModifiedUpdatedBy","updatedAt","updatedBy","ordered_item_id","ordered_item_disc","new_dealer_price"]

    let excelHeader = ["Delivery Date", "Vehicle No.", "Order No.", "Status", "Reason", "Item Details", "Item Id","Item Modified Qty","Item Secondary Reason","Item Undelivered Reason", "Delivered Qty", "Order Qty", "Order Value", "Order Date",
    "Payment Mode", "payment_cash_amount", "payment_cheque1_amount", "payment_cheque1_number", "payment_cheque1_date", "payment_cheque2_amount", "payment_cheque2_number", "payment_cheque2_date",
    "payment_card_amount", "payment_card_transactionid", "payment_paytm_amount", "payment_razorpay_amount", "payment_pending_payment_amount", "payment_adjustments",
        "Phone", "Priority", "Retailer Id", "Shop Name", "Total Amount", "Total Amount Paid", "loose_allocated_by", "vehicle_loaded_by","GST Charges","Variety",
        "itemDeliveredUpdatedAt","itemDeliveredUpdatedBy","itemModifiedUpdatedAt","itemModifiedUpdatedBy","updatedAt","updatedBy","ordered_item_id","ordered_item_disc","new_dealer_price" ]


    const orderDetailsArray = ["delivery_date", "vehicle", "order_number", "status", "reason", "item_disc", "item_id",
        "item_primary_reason", "item_status", "item_delivered_qty", "order_qty", "mrp", "order_date", "payment_mode",
        "payment_cash_amount", "payment_cheque1_amount", "payment_cheque1_number", "payment_cheque1_date", "payment_cheque2_amount", "payment_cheque2_number", "payment_cheque2_date",
        "payment_card_amount", "payment_card_transactionid", "payment_paytm_amount", "payment_razorpay_amount", "payment_pending_payment_amount", "payment_adjustments",
        "phone", "priority", "retailer_id", "shop_name", "total_amt", "total_amount_paid", "gst_charge","variety"]



    const exportOrderSummary = () => {

    const wb = XLSX.utils.book_new()

        setBackDrop(true)
        let exportOrderDetails = [excelHeader]
        orderDetails.map(item => {
            let exportOrderFields = []
            item.total_amt = Number(item.total_amt);
            item["mrp"] = Number(item["mrp"]);
            item["total_amount_paid"] = Number(item["total_amount_paid"]);
            if(!item["status"].includes("Delivered")) {
                item["total_amount_paid"] = 0;
                item["payment_mode"] = "";
                item["payment_cash_amount"] = "";
            }
            //Setting Item Status
            var itemUndeliveredReason = item["item_reason"];
            if(item.item_primary_reason){
                item["item_status"]=item.item_primary_reason
            } else if(itemUndeliveredReason) {
                if(itemUndeliveredReason==="Short supply".trim().toLowerCase()){item["item_status"]="Bill Modified" }
                if(itemUndeliveredReason==="Short Loaded".trim().toLowerCase()||itemUndeliveredReason==="Weight Issue".trim().toLowerCase()||itemUndeliveredReason==="Other Short".trim().toLowerCase())
                {item["item_status"]="Short" }
                if(itemUndeliveredReason==="Rate Issue".trim().toLowerCase()||itemUndeliveredReason==="Cash Not Available".trim().toLowerCase()||itemUndeliveredReason==="Wrongly Ordered".trim().toLowerCase()||itemUndeliveredReason==="Quality Issue".trim().toLowerCase()||itemUndeliveredReason==="Weight Issue".trim().toLowerCase())
                {item["item_status"]="Returns" }
            } else {
                item["item_status"]=""
            }
            delete item["item_primary_reason"]

            //Setting Item Delivered Quantity
            if(item.item_status == 'cancel') {
                item["item_delivered_qty"] = 0;
            } else if (item.item_delivered_qty && item["status"] == 'Partially-Delivered') {
                item["item_delivered_qty"] = item.item_delivered_qty;
            } else if (String(item["status"]).includes("Delivered")){
                item["item_delivered_qty"] = item["order_qty"]
            }

            exportOrderFields.push(item.delivery_date)
            exportOrderFields.push(item.driver_id)
            exportOrderFields.push(item.order_number)
            exportOrderFields.push(item.status)
            exportOrderFields.push(item.reason)
            exportOrderFields.push(item.item_disc)
            exportOrderFields.push(item.item_id)
            exportOrderFields.push(item.item_modified_qty)
            exportOrderFields.push(item.item_primary_reason)
            exportOrderFields.push(itemUndeliveredReason)
            exportOrderFields.push(item.item_delivered_qty)
            exportOrderFields.push(item.order_qty)
            exportOrderFields.push(item.total_amt)
            exportOrderFields.push(item.order_date)
            exportOrderFields.push(item.payment_mode)

            exportOrderFields.push(item.payment_cash_amount)
            exportOrderFields.push(item.payment_cheque1_amount)
            exportOrderFields.push(item.payment_cheque1_number)
            exportOrderFields.push(item.payment_cheque1_date)
            exportOrderFields.push(item.payment_cheque2_amount)
            exportOrderFields.push(item.payment_cheque2_number)
            exportOrderFields.push(item.payment_cheque2_date)
            exportOrderFields.push(item.payment_card_amount)
            exportOrderFields.push(item.payment_card_transactionid)
            exportOrderFields.push(item.payment_paytm_amount)
            exportOrderFields.push(item.payment_razorpay_amount)
            exportOrderFields.push(item.payment_pending_payment_amount)
            exportOrderFields.push(item.payment_adjustments)
            exportOrderFields.push(item.phone)
            exportOrderFields.push(item.priority)
            exportOrderFields.push(item.retailer_id)
            exportOrderFields.push(item.shop_name)
            exportOrderFields.push(item.total_amt)
            exportOrderFields.push(item.total_amount_paid)
            exportOrderFields.push(item.loose_allocated_by)
            exportOrderFields.push(item.vehicle_loaded_by)
            exportOrderFields.push(item.gst_charge)
            exportOrderFields.push(item.variety)
            exportOrderFields.push(item.itemDeliveredUpdatedAt)
            exportOrderFields.push(item.itemDeliveredUpdatedBy)
            exportOrderFields.push(item.itemModifiedUpdatedAt)
            exportOrderFields.push(item.itemModifiedUpdatedBy)
            exportOrderFields.push(item.updatedAt)
            exportOrderFields.push(item.updatedBy)
            exportOrderFields.push(item.ordered_item_id)
            exportOrderFields.push(item.ordered_item_disc)
            exportOrderFields.push(item.new_dealer_price)


            exportOrderDetails.push(exportOrderFields)
        })
        const orderDetailedData = XLSX.utils.aoa_to_sheet(exportOrderDetails)
        XLSX.utils.book_append_sheet(wb, orderDetailedData, `Order Details`)
        XLSX.writeFile(wb, `Order_Details.xlsx`)
        setExportData(false)
        setBackDrop(false)


    }

    useEffect(() => {
        if (exportData)
            exportOrderSummary()
    }, [exportData])
    return (
        <div style={{width:"5000px"}}>
            <Navbar className="bg-light mr-auto justify-content-between" >
                <Form inline>
                    <InputGroup size="sm" style={{ marginLeft: 10, marginRight: 20 }}>
                        <InputGroupAddon addonType="prepend">Select Start Date</InputGroupAddon>
                        <DatePicker
                            selected={startDate}
                            onChange={(val) => { setStartDate(val) }}
                            minDate={new Date("2017/01/01")}
                            maxDate={new Date()}
                            aria-describedby="basic-addon1"
                            dateFormat="dd/MM/yyyy"

                        />
                    </InputGroup>
                    <InputGroup size="sm" style={{ marginLeft: 10, marginRight: 20 }}>
                        <InputGroupAddon addonType="prepend">Select End Date</InputGroupAddon>
                        <DatePicker
                            selected={endDate}
                            onChange={(val) => { setEndDate(val) }}
                            minDate={new Date("2017/01/01")}
                            maxDate={new Date()}
                            aria-describedby="basic-addon1"
                            dateFormat="dd/MM/yyyy"

                        />
                    </InputGroup>
                    <Button size="sm" onClick={() => {setOrderSearch(true);setBackDrop(true)}}>Search Orders</Button>
                    <InputGroup size="sm" style={{ marginLeft: 100, marginRight: 200 }}>
                        <InputGroupAddon addonType="prepend">Select Order Status</InputGroupAddon>
                        <DropdownButton size="sm"
                        as={ButtonGroup}
                        key={"right"}
                        drop={"down"}
                        variant={"outline-primary"}
                        title={orderStatus?orderStatus:"All"}
                    >
                        <Dropdown.Item eventKey="1" name={'Delivered'} onClick={(e) => { setOrderStatus(e.target.name) }} >Delivered</Dropdown.Item>
                        <Dropdown.Item eventKey="2" name={'Partially-Delivered'} onClick={(e) => { setOrderStatus(e.target.name) }} >Partial Delivered</Dropdown.Item>
                        <Dropdown.Item eventKey="3" name={'Cancelled'} onClick={(e) => { setOrderStatus(e.target.name) }} >Cancelled</Dropdown.Item>
                        <Dropdown.Item eventKey="4" name={'shop closed'} onClick={(e) => { setOrderStatus(e.target.name) }} >Shop Closed</Dropdown.Item>
                        <Dropdown.Item eventKey="5" name={'not visited'} onClick={(e) => { setOrderStatus(e.target.name) }} >Not Visited</Dropdown.Item>
                        <Dropdown.Item eventKey="6" name={'Hold'} onClick={(e) => { setOrderStatus(e.target.name) }} >Hold</Dropdown.Item>
                        <Dropdown.Item eventKey="7" name={'processed'} onClick={(e) => { setOrderStatus(e.target.name) }} >Processed</Dropdown.Item>
                        <Dropdown.Item eventKey="8" name={'Pending'} onClick={(e) => { setOrderStatus(e.target.name) }} >Pending</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item eventKey="9" name={'reset'} onClick={(e) => { setOrderStatus(e.target.name) }} >Reset</Dropdown.Item>
                    </DropdownButton>
                    </InputGroup>
                    <InputGroup size="sm" style={{ marginLeft: 10, marginRight: 50 }}>
                        <Button size="sm" onClick={() => setExportData(true)}>Export</Button>
                    </InputGroup>
                </Form>
            </Navbar>
            {orderDetails.length > 0 &&
                <Table>
                    <Jumbotron>
                    <thead>
                        <tr>{orderDetailsRecords.map(items => (
                            <td>{(items)}</td>
                        ))}
                        </tr>
                    </thead>
                    <tbody>
                        {orderDetails.length > 0 && orderDetails.length < 1000 && orderDetails.map((item, index) => (
                            <tr>
                                <td>{item.delivery_date}</td>
                                <td>{item.driver_id}</td>
                                <td>{item.order_number}</td>
                                <td>{item.status}</td>
                                <td>{item.reason}</td>
                                <td>{item.item_disc}</td>
                                <td>{item.item_id}</td>
                                <td>{item.item_modified_qty}</td>
                                <td>{item.item_primary_reason}</td>
                                <td>{item.item_reason}</td>
                                <td>{item.item_status}</td>
                                <td>{item.item_delivered_qty}</td>
                                <td>{item.item_returned_qty}</td>
                                <td>{item.order_qty}</td>
                                <td>{item.total_amt}</td>
                                <td>{item.order_date}</td>
                                <td>{item.payment_mode}</td>
                                <td>{item.payment_cash_amount}</td>
                                <td>{item.payment_cheque1_amount}</td>
                                <td>{item.payment_cheque1_number}</td>
                                <td>{item.payment_cheque1_date}</td>
                                <td>{item.payment_cheque2_amount}</td>
                                <td>{item.payment_cheque2_number}</td>
                                <td>{item.payment_cheque2_date}</td>
                                <td>{item.payment_card_amount}</td>
                                <td>{item.payment_card_transactionid}</td>
                                <td>{item.payment_paytm_amount}</td>
                                <td>{item.payment_razorpay_amount}</td>
                                <td>{item.payment_pending_payment_amount}</td>
                                <td>{item.payment_adjustments}</td>
                                <td>{item.phone}</td>
                                <td>{item.priority}</td>
                                <td>{item.retailer_id}</td>
                                <td>{item.shop_name}</td>
                                <td>{item.total_amt}</td>
                                <td>{item.total_amount_paid}</td>
                                <td>{item.loose_allocated_by}</td>
                                <td>{item.vehicle_loaded_by}</td>
                                <td>{item.gst_charge}</td>
                                <td>{item.variety}</td>
                                <td>{item.itemDeliveredUpdatedAt}</td>
                                <td>{item.itemDeliveredUpdatedBy}</td>
                                <td>{item.itemModifiedUpdatedAt}</td>
                                <td>{item.itemModifiedUpdatedBy}</td>
                                <td>{item.updatedAt}</td>
                                <td>{item.updatedBy}</td>
                                <td>{item.ordered_item_id}</td>
                                <td>{item.ordered_item_disc}</td>
                                <td>{item.new_dealer_price}</td>
                            </tr>
                        ))}
                    </tbody>
                    </Jumbotron>
                </Table>
            }
            {getBackDrop?(<Backdrop parentLoadStatus={getBackDrop}/>):(null)}

        </div>
    )
}

export default OrderSummaryPage
