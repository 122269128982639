import React, { useState, useEffect, useRef } from 'react'
import { Table, Form, Alert, Button,Modal, InputGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Select from 'react-select';
import Backdrop from '../backdrop/backdrop';
import ShipModifyOrdersModal from './ShipModifyOrdersModal.js'
import ReceivedModifyOrdersModal from './ReceivedModifyOrdersModal.js'
import fire from '../../Configs/firebase'
import { support_portal_url, orderingApiUrl, service_url } from "../../Configs/mysqlconfig";
import {validateIsAdminUser,validateIsSystemsUser} from "../UserCredentials";

function formatDate(date) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

function PickingModal({dohData,productDataObj,deliveryDate,pickingDate,...props}) {
  const [loading, setLoading] = useState(false);
  const [cityRouteMap, setCityRouteMap] = useState({});
  const [cityDropdownList, setCityDropdownList] = useState([]);
  const [vehicleDropdownList, setVehicleDropdownList] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [plannedRoutesVarietyMap, setPlannedRoutesVarietyMap] = useState({});
  const [plannedTaskIdList, setPlannedTaskIdList] = useState([]);
  const [getOffersObject, setOffersObject] = useState({});
  const [getCustomOffersObject, setCustomOffersObject] = useState({});
  const [varietyOption, setVarietyOption] = useState("");
  const [getShipModifyPopupShow, setShipModifyPopupShow] = useState(false);
  const [receivedModifyPopupShow, setReceivedModifyPopupShow] = useState(false);
  const [pickedDate, setPickedDate] = useState(pickingDate?pickingDate:formatDate(new Date()));
  const [deliverDate, setDeliverDate] = useState(deliveryDate);

  const bulkRouteRef = useRef();
  const looseRouteRef = useRef();

  const convertCity = (cityVal) => {
    // cityVal=cityVal.toLowerCase();
    // if(cityVal=="pune"){
    //   return "Pune";
    // } else if(cityVal=="surat"){
    //   return "Surat";
    // } else if(cityVal=="nashik"){
    //   return "Nashik";
    // } else {
    //   return "Mumbai";
    // }
    return cityVal;
  }

  useEffect(() => {
    let routeList = [];
    let cityVehicleMap = {};
    let userCity = sessionStorage.getItem("userCity") || "";

    Object.keys(dohData).map((rkey,rindex) => {
      let origRouteNumber = Number(rkey);
      if(origRouteNumber!=9999 && origRouteNumber!=9998){
        let vehicleOrders = dohData[rkey] || {};
        let routeCitySet = new Set();

        Object.keys(vehicleOrders).map((okey,oindex) => {
          let city = vehicleOrders[okey].city || "";
          city = convertCity(city);

          if(!routeCitySet.has(city)){
            routeCitySet.add(city);
          }
        })

        let routeCityList = [...routeCitySet];

        for(let z=0;z<routeCityList.length;z++){
          let cityVal = routeCityList[z];

          if(!cityVehicleMap[cityVal]){
            cityVehicleMap[cityVal] = [];
          }

          cityVehicleMap[cityVal].push(rkey);
        }
      }
    })

    console.log(cityVehicleMap);

    let cityList = Object.keys(cityVehicleMap);
    let cityDropdown = [];

    for(let i=0;i<cityList.length;i++){
      let cityVal = cityList[i];
      if(!userCity || (userCity && userCity==cityVal) || validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email))) {
        cityDropdown.push({"value":cityVal, "label":cityVal});
      }
    }

    setCityDropdownList(cityDropdown);
    setCityRouteMap(cityVehicleMap);

    if(bulkRouteRef.current){
      bulkRouteRef.current.state.value = cityDropdown;
    }
  },[])

  useEffect(() => {
    if(selectedCity) {
      let vehicleList = cityRouteMap[selectedCity] || [];
      let vehicleDropdown = [];
      for(let vehicle of vehicleList) {
        vehicleDropdown.push({ "label": vehicle, "value": vehicle });
      }
      setVehicleDropdownList(vehicleDropdown);
    } else {
      setVehicleDropdownList([]);
    }
  },[selectedCity])

  const getSelectedRoute = () => {
    let routeList=[];
    if(bulkRouteRef.current){
      routeList = bulkRouteRef.current.state.value || [];
    }

    let finalRouteList = [];
    for(let i=0;i<routeList.length;i++){
      finalRouteList.push({ "label": routeList[i].value, "value": routeList[i].value });
    }

    return finalRouteList;
  }

  const generatePickingData = () => {
    let routeList=[];
    if(bulkRouteRef.current){
      routeList = bulkRouteRef.current.state.value || [];
    }
    console.log(routeList);
    console.log(selectedCity);

    let finalRouteSet = new Set();
    for(let i=0;i<routeList.length;i++){
      if(!finalRouteSet.has(routeList[i].value)) {
        finalRouteSet.add(routeList[i].value);
      }
    }

    if(finalRouteSet.size==0){
      toast(`Please select route`, { type: toast.TYPE.ERROR });
    }else if(!selectedCity){
      toast(`Please select city`, { type: toast.TYPE.ERROR });
    }else if(Object.keys(dohData)==0) {
      toast(`No delivery data`, { type: toast.TYPE.ERROR });
    }else{

      let formattedDeliveryDate = new Date();
      console.log(formattedDeliveryDate);
      let year = formattedDeliveryDate.getFullYear();
      let month = formattedDeliveryDate.getMonth() + 1;
      let date = formattedDeliveryDate.getDate();

      Promise.all([
        fire.database().ref(`Picking_Orders_History/${year}/${month}/${date}-${month}-${year}`).once("value")
      ]).then(([pickingSnapshot]) => {
        let pickingData = pickingSnapshot.val() || {};

        console.log(`Picking_Orders_History/${year}/${month}/${date}-${month}-${year}`);
        console.log(pickingData);
        if(Object.keys(pickingData).length>0) {
          let alreadyPickedRoutesSet = new Set();
          Object.keys(pickingData).map((rkey,rindex) => {
            let routePickingObj = pickingData[rkey] || {};

            Object.keys(routePickingObj).map((key,index) => {
              let vehicle_no = routePickingObj[key].vehicle_no || "";
              if(vehicle_no && finalRouteSet.has(vehicle_no)) {
                if(!alreadyPickedRoutesSet.has(vehicle_no)) {
                  alreadyPickedRoutesSet.add(vehicle_no);
                }
              }
            })
          })
          console.log(alreadyPickedRoutesSet);

          if(alreadyPickedRoutesSet.size>0) {
            let alreadyPickedRoutesList = [...alreadyPickedRoutesSet];
            let alreadyPickedRoutesStr = "";
            for(let alreadyPickedRoute of alreadyPickedRoutesList) {
              alreadyPickedRoutesStr += `${alreadyPickedRoute},`
            }
            if(alreadyPickedRoutesStr) {
              alreadyPickedRoutesStr = alreadyPickedRoutesStr.slice(0,-1);
            }
            console.log(alreadyPickedRoutesStr);

            toast(`Picking data already created for routes : ${alreadyPickedRoutesStr}`, { type: toast.TYPE.ERROR });
            return;
          }
        }

        let pickingOrdersObj = {};

        Object.keys(dohData).map((rkey,rindex) => {
          let vehicle_no = rkey;
          let vehicleOrders = dohData[rkey] || {};

          if(finalRouteSet.has(rkey)) {
            Object.keys(vehicleOrders).map((okey,oindex) => {
              let variety = vehicleOrders[okey].variety || "";
              // variety = variety.replaceAll(" ","_");
              let status = vehicleOrders[okey].status || "";
              let order_num = vehicleOrders[okey].order_number || "";

              if(status!="Hold" && !order_num.includes("OR")) {
                let finalVariety = "";
                if(variety=="SMALL PACK") {
                  finalVariety = "LOOSE PACK";
                } else if(variety=="HIGH VALUE LOOSE" || variety=="CRATE LINES" || variety=="BOX") {
                  finalVariety = "HIGH VALUE";
                } else if (variety=="FMCG"){
                  finalVariety = "FMCG";
                } else {
                  finalVariety = "BAG";
                }

                if(variety=="SMALL PACK") {
                  let modifyFinalVariety = finalVariety.replaceAll(" ","_");
                  let item_id = vehicleOrders[okey].item_id || "";
                  let picking_key = `${vehicle_no}-${modifyFinalVariety}`;
                  let ean = vehicleOrders[okey].ean || "";

                  if(!pickingOrdersObj[picking_key]) {
                    pickingOrdersObj[picking_key] = {};
                  }

                  if(!pickingOrdersObj[picking_key][okey]) {
                    pickingOrdersObj[picking_key][okey] = {};
                  }

                  let itemele = {};
                  let order_no = vehicleOrders[okey].order_number || "";
                  itemele['order_number'] = order_no;
                  itemele['item_id'] = item_id;
                  itemele['vehicle_no'] = vehicle_no;
                  itemele['type'] = finalVariety;
                  itemele['city'] = vehicleOrders[okey].city || "";
                  itemele['retailer_id'] = vehicleOrders[okey].retailer_id || Number(vehicleOrders[okey].customer_id) || "";
                  itemele['order_number'] = vehicleOrders[okey].order_number || "";
                  itemele['shop_name'] = vehicleOrders[okey].shop_name || "";
                  itemele['address1'] = vehicleOrders[okey].address1 || "";
                  itemele['address2'] = vehicleOrders[okey].address2 || "";
                  itemele['sub_category'] = vehicleOrders[okey].sub_category || "";
                  itemele['case_size'] = vehicleOrders[okey].case_size || "";
                  itemele['category'] = vehicleOrders[okey].category || "";
                  itemele['brand'] = vehicleOrders[okey].brand || "";
                  itemele['long_description'] = vehicleOrders[okey].item_disc || "";
                  itemele['long_description_hindi'] = vehicleOrders[okey].long_description_hindi || "";
                  itemele['product_image_url'] = vehicleOrders[okey].goni_image_url || vehicleOrders[okey].image_url || "";
                  itemele['order_qty'] = Number(vehicleOrders[okey].order_qty) || 0;
                  itemele['weight'] = vehicleOrders[okey].weight || "";
                  itemele['ean'] = ean;

                  pickingOrdersObj[picking_key][okey] = itemele;
                }else{
                  let modifyFinalVariety = finalVariety.replaceAll(" ","_");
                  let picking_key = `${vehicle_no}-${modifyFinalVariety}`;
                  let item_id = vehicleOrders[okey].item_id || "";

                  if(!pickingOrdersObj[picking_key]) {
                    pickingOrdersObj[picking_key] = {};
                  }

                  if(!pickingOrdersObj[picking_key][item_id]) {
                    pickingOrdersObj[picking_key][item_id] = {};
                  }

                  let itemele = {};
                  itemele['item_id'] = item_id;
                  itemele['vehicle_no'] = vehicle_no;
                  itemele['type'] = finalVariety;
                  itemele['city'] = vehicleOrders[okey].city || "";
                  itemele['sub_category'] = vehicleOrders[okey].sub_category || "";
                  itemele['case_size'] = vehicleOrders[okey].case_size || "";
                  itemele['category'] = vehicleOrders[okey].category || "";
                  itemele['brand'] = vehicleOrders[okey].brand || "";
                  itemele['long_description'] = vehicleOrders[okey].item_disc || "";
                  itemele['long_description_hindi'] = vehicleOrders[okey].long_description_hindi || "";
                  itemele['product_image_url'] = vehicleOrders[okey].goni_image_url || vehicleOrders[okey].image_url || "";
                  itemele['weight'] = vehicleOrders[okey].weight || "";

                  let existingQty = Number(pickingOrdersObj[picking_key][item_id].order_qty) || 0;
                  let currentQty = Number(vehicleOrders[okey].order_qty) || 0;
                  itemele['order_qty'] = existingQty + currentQty;

                  // Add EAN for variety other than 'Bag'
                  if (finalVariety !== 'Bag') {
                    itemele['ean'] = vehicleOrders[okey].ean || "";
                  }
                  pickingOrdersObj[picking_key][item_id] = itemele;
                }
              }
            })
          }
        })

        console.log(pickingOrdersObj);

        fire.database().ref(`Picking_Orders_History/${year}/${month}/${date}-${month}-${year}`).update(pickingOrdersObj).then(() => {
          let apiBody = {};
          apiBody['delivery_date'] = deliverDate;
          apiBody['picking_date'] = formatDate(new Date());
          Promise.all([
            fetch(`${service_url}/delivery-picking-date`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': sessionStorage.getItem("apiToken")

                },
                body: JSON.stringify(apiBody)
            }).then(response => response.json())
          ]).then(([apiData]) => {
            return toast(`Picking data created`, { type: toast.TYPE.SUCCESS });
          })
        })


      })

    }
  }

  const resetPickingData = () => {
    let routeList=[];
    if(bulkRouteRef.current){
      routeList = bulkRouteRef.current.state.value || [];
    }
    console.log(routeList);
    console.log(selectedCity);

    let finalRouteSet = new Set();
    for(let i=0;i<routeList.length;i++){
      if(!finalRouteSet.has(routeList[i].value)) {
        finalRouteSet.add(routeList[i].value);
      }
    }

    if(finalRouteSet.size==0){
      toast(`Please select route`, { type: toast.TYPE.ERROR });
    }else if(!selectedCity){
      toast(`Please select city`, { type: toast.TYPE.ERROR });
    }else if(!pickingDate){
      toast(`Invalid picking date`, { type: toast.TYPE.ERROR });
    }else if(Object.keys(dohData)==0) {
      toast(`No delivery data`, { type: toast.TYPE.ERROR });
    }else{

      Promise.all([
        fetch(`${service_url}/api/transit-stock-transfer?delivery_date=${deliverDate}&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MTU0OWIwMTIwMWUyZjMzZWE3NmFkZjYiLCJlbWFpbCI6InNtdHdpbmtsZTQ1MkBnbWFpbC5jb20iLCJpYXQiOjE2MzI5MzQ2NTgsImV4cCI6MTYzMjkzODI1OH0._oHr3REme2pjDDdRliArAeVG_HuimbdM5suTw8HI7ut`).then(response=>response.json())
      ]).then(([transitData]) => {
        let transitList = transitData.data || [];
        let routeStr = ``;
        for(let transit of transitList) {
          let route = transit.route || "";

          if(finalRouteSet.has(route)) {
            routeStr += `${route},`
          }
        }

        if(routeStr) {
          routeStr = routeStr.slice(0,-1);
          return toast(`Transit already done for routes : ${routeStr}`, { type: toast.TYPE.ERROR });;
        } else {
          resetDohData(finalRouteSet);
        }
      }).catch((err) => {
        toast(`Error in fetching transfer details : ${err}`, { type: toast.TYPE.ERROR });
      })

    }
  }

  const resetDohData = (finalRouteSet) => {
    console.log("test");

    let p = new Date(pickingDate);
    let pyear=p.getFullYear();
    let pmonth=p.getMonth()+1;
    let pdate=p.getDate();

    Promise.all([
      fetch(`${support_portal_url}/api/deliveryorders?token=${sessionStorage.getItem("apiToken")}`).then(response=>response.json()),
      fetch(`${orderingApiUrl}/retailer-group-details?token=eyJhbGciOiJIUzI1NiIsInR5cCI6Ik&group_id=prime`).then(response=>response.json()),
      fire.database().ref(`Picking_Allocation_History/${pyear}/${pmonth}/${pdate}-${pmonth}-${pyear}`).once('value')
    ]).then(([result, userData, pickingAllocationSnap]) => {
      let pickingAllocationData = pickingAllocationSnap.hasChildren()?pickingAllocationSnap.val():{};

      if(result['success']==1) {
        console.log(result);
        let tableData=result['ordersData'] || [];
        let primeUserList = userData.data || [];

        let d = new Date(deliveryDate);
        let year=d.getFullYear();
        let month=d.getMonth()+1;
        let ddate=d.getDate()+'-'+month+'-'+year;

        let updatedDohData = {};
        let driverordersele={};
        let shiprouteele={};
        let update_check_flag = 0;
        let count_flag = 0;
        for(let o in tableData){
          let routeId=tableData[o]['vehicle'];

          let order_no=tableData[o]['order_number'].replace("-", "_");
          let order_no_list=order_no.split('/');

          let item_id=tableData[o]['item_id'];
          let dorefstr=order_no_list[0]+'-'+order_no_list[1]+'-'+item_id;

          let driverordershistorydata=JSON.parse(JSON.stringify(tableData[o]));

          driverordershistorydata['vehicle']=parseInt(String(routeId).replace("F", "0"));

          let retailer_id = driverordershistorydata['retailer_id'];
          driverordershistorydata['item_id']=Number(driverordershistorydata['item_id']);
          driverordershistorydata['goni_image_url']=driverordershistorydata['goni_image_url'] || "";

          let customer_id = Number(driverordershistorydata['customer_id']) || 0;
          let is_retained = Number(driverordershistorydata['is_retained']) || 0;

          driverordershistorydata['case_size']=driverordershistorydata['case_size'] || "";
          driverordershistorydata['ean']=driverordershistorydata['ean'] || "";

          driverordershistorydata['region']=driverordershistorydata['region_name'] || "";
          driverordershistorydata['hsn_code']=driverordershistorydata['hsn_code'] || "";
          driverordershistorydata['status']='Processed';
          driverordershistorydata['mrp']=tableData[o]['price'];
          driverordershistorydata['market_price']=String(Number(tableData[o]['market_price']) || 0) || "";
          driverordershistorydata['driver_id']=''+tableData[o]['vehicle'];
          driverordershistorydata['total_amt']=tableData[o]['order_value'];
          driverordershistorydata['order_qty']=''+parseInt(tableData[o]['order_qty']);
          driverordershistorydata['created_from']='web';
          driverordershistorydata['created_by']="picking_reset";
          driverordershistorydata['cheque_date']=0;
          driverordershistorydata['delivery_date']=0;
          driverordershistorydata['distributor_id']=0;
          driverordershistorydata['exp_delivery_date']=0;
          driverordershistorydata['paid_date']=0;
          driverordershistorydata['se_phone_number']=tableData[o]['phone_number'] || "";
          driverordershistorydata['order_date']=tableData[o]['orderddate'];
          let actualCity = tableData[o]['city'];
          driverordershistorydata['actual_city'] = actualCity || "";
          if(Number(routeId)==8801 || Number(routeId)==8802) {
            driverordershistorydata['city']="Dhansar";
          } else {
            driverordershistorydata['city']=driverordershistorydata['warehouse'] || "";
          }

          let zsm_id = tableData[o].zsm_id || "";
          let deputy_asm_id = tableData[o].deputy_asm_id || "";

          if(deputy_asm_id) {
            driverordershistorydata['deputyasm'] = deputy_asm_id || "";
          }
          if(zsm_id) {
            driverordershistorydata['zsm'] = zsm_id || "";
          }

          delete driverordershistorydata['type'];
          delete driverordershistorydata['zsm_id'];
          delete driverordershistorydata['deputy_asm_id'];

          let asm_type = tableData[o]['type'];
          if(asm_type=="deputyasm") {
            driverordershistorydata['deputyasm'] = driverordershistorydata['asmphone'] || "";
          } else if(asm_type=="zsm") {
            driverordershistorydata['zsm'] = driverordershistorydata['asmphone'] || "";
          }

          delete driverordershistorydata['type'];

          driverordershistorydata['prime_user'] = primeUserList.includes(String(retailer_id))?"Y":"N";
          delete driverordershistorydata['phone_number'];
          delete driverordershistorydata['orderddate'];
          delete driverordershistorydata['region_name'];
          delete driverordershistorydata['warehouse'];

          if(tableData[o]['offer_price']){
            driverordershistorydata['dealer_price']=tableData[o]['offer_price'];
          }

          if(tableData[o]['reason']){
            driverordershistorydata['reason']=tableData[o]['reason'];
          }
          let couponCode=tableData[o]['coupon_code'];
          if(tableData[o]['coupon_code']){
            let lastChar=couponCode.slice(-1);
            if(lastChar==","){
              couponCode=couponCode.slice(0,-1);
            }
            driverordershistorydata['coupon_code']=couponCode;
          }

          if(tableData[o]['customer_returned_items']){
            driverordershistorydata['customer_returned_items']=tableData[o]['customer_returned_items'];
          }
          if(tableData[o]['customer_adjusted_payment']){
            driverordershistorydata['customer_adjusted_payment']=tableData[o]['customer_adjusted_payment'];
          }
          if(tableData[o]['order_picked_up']){
            driverordershistorydata['order_picked_up']=tableData[o]['order_picked_up'];
          }

          let curdate = new Date();
          let curtime = curdate.getTime();
          driverordershistorydata['saved_at']=curtime;

          let driverOrderNumber = driverordershistorydata['order_number'];

          if(finalRouteSet.has(routeId)) {
            if(!updatedDohData[routeId]) {
              updatedDohData[routeId] = {};
            }

            if(!updatedDohData[routeId][dorefstr]) {
              updatedDohData[routeId][dorefstr] = driverordershistorydata;
            }

            driverordersele[`/${routeId}/${dorefstr}`]=driverordershistorydata;
            shiprouteele[`/${routeId}`] = null;
          }

        }
        if(Object.keys(shiprouteele).length > 0) {
          fire.database().ref(`Driver_Orders_History/${year}/${month}/${ddate}`).update(shiprouteele).then(() => {
            fire.database().ref(`Driver_Orders_History/${year}/${month}/${ddate}`).update(driverordersele,function(error){
              if (error) {
                alert('Error in adding driver orders data : ' + error);
              } else {
                // update picking
                let pickingOrdersObj = {};
  
                Object.keys(updatedDohData).map((rkey,rindex) => {
                  let vehicle_no = rkey;
                  let vehicleOrders = updatedDohData[rkey] || {};
  
  
                    Object.keys(vehicleOrders).map((okey,oindex) => {
                      let variety = vehicleOrders[okey].variety || "";
                      // variety = variety.replaceAll(" ","_");
                      let status = vehicleOrders[okey].status || "";
                      let order_num = vehicleOrders[okey].order_number || "";
  
                      if(status!="Hold" && !order_num.includes("OR")) {
                        let finalVariety = "";
                        if(variety=="SMALL PACK") {
                          finalVariety = "LOOSE PACK";
                        } else if(variety=="HIGH VALUE LOOSE" || variety=="CRATE LINES" || variety=="BOX") {
                          finalVariety = "HIGH VALUE";
                        } else if (variety=="FMCG"){
                          finalVariety = "FMCG";
                        } else {
                          finalVariety = "BAG";
                        }
  
                        if(variety=="SMALL PACK") {
                          let modifyFinalVariety = finalVariety.replaceAll(" ","_");
                          let item_id = vehicleOrders[okey].item_id || "";
                          let picking_key = `${vehicle_no}-${modifyFinalVariety}`;
  
                          if(!pickingOrdersObj[picking_key]) {
                            pickingOrdersObj[picking_key] = {};
                          }
  
                          if(!pickingOrdersObj[picking_key][okey]) {
                            pickingOrdersObj[picking_key][okey] = {};
                          }
  
                          let itemele = {};
                          let order_no = vehicleOrders[okey].order_number || "";
                          itemele['order_number'] = order_no;
                          itemele['item_id'] = item_id;
                          itemele['vehicle_no'] = vehicle_no;
                          itemele['type'] = finalVariety;
                          itemele['city'] = vehicleOrders[okey].city || "";
                          itemele['retailer_id'] = vehicleOrders[okey].retailer_id || vehicleOrders[okey].customer_id || "";
                          itemele['order_number'] = vehicleOrders[okey].order_number || "";
                          itemele['shop_name'] = vehicleOrders[okey].shop_name || "";
                          itemele['address1'] = vehicleOrders[okey].address1 || "";
                          itemele['address2'] = vehicleOrders[okey].address2 || "";
                          itemele['sub_category'] = vehicleOrders[okey].sub_category || "";
                          itemele['case_size'] = vehicleOrders[okey].case_size || "";
                          itemele['category'] = vehicleOrders[okey].category || "";
                          itemele['brand'] = vehicleOrders[okey].brand || "";
                          itemele['long_description'] = vehicleOrders[okey].item_disc || "";
                          itemele['long_description_hindi'] = vehicleOrders[okey].long_description_hindi || "";
                          itemele['product_image_url'] = vehicleOrders[okey].goni_image_url || vehicleOrders[okey].image_url || "";
                          itemele['order_qty'] = Number(vehicleOrders[okey].order_qty) || 0;
                          itemele['weight'] = vehicleOrders[okey].weight || "";
                          itemele['ean'] = vehicleOrders[okey].ean || "";
  
                          pickingOrdersObj[picking_key][okey] = itemele;
                        }else{
                          let modifyFinalVariety = finalVariety.replaceAll(" ","_");
                          let picking_key = `${vehicle_no}-${modifyFinalVariety}`;
                          let item_id = vehicleOrders[okey].item_id || "";
  
                          if(!pickingOrdersObj[picking_key]) {
                            pickingOrdersObj[picking_key] = {};
                          }
  
                          if(!pickingOrdersObj[picking_key][item_id]) {
                            pickingOrdersObj[picking_key][item_id] = {};
                          }
  
                          let itemele = {};
                          itemele['item_id'] = item_id;
                          itemele['vehicle_no'] = vehicle_no;
                          itemele['type'] = finalVariety;
                          itemele['city'] = vehicleOrders[okey].city || "";
                          itemele['sub_category'] = vehicleOrders[okey].sub_category || "";
                          itemele['case_size'] = vehicleOrders[okey].case_size || "";
                          itemele['category'] = vehicleOrders[okey].category || "";
                          itemele['brand'] = vehicleOrders[okey].brand || "";
                          itemele['long_description'] = vehicleOrders[okey].item_disc || "";
                          itemele['long_description_hindi'] = vehicleOrders[okey].long_description_hindi || "";
                          itemele['product_image_url'] = vehicleOrders[okey].goni_image_url || vehicleOrders[okey].image_url || "";
                          itemele['weight'] = vehicleOrders[okey].weight || "";
  
                          let existingQty = Number(pickingOrdersObj[picking_key][item_id].order_qty) || 0;
                          let currentQty = Number(vehicleOrders[okey].order_qty) || 0;
                          itemele['order_qty'] = existingQty + currentQty;

                          // Add EAN for variety other than 'Bag'
                          if (finalVariety !== 'Bag') {
                            itemele['ean'] = vehicleOrders[okey].ean || "";
                          }
                          pickingOrdersObj[picking_key][item_id] = itemele;
                        }
                      }
                    })
  
                })
  
                console.log("pickingOrdersObj");
                console.log(pickingOrdersObj);
  
                fire.database().ref(`Picking_Orders_History/${pyear}/${pmonth}/${pdate}-${pmonth}-${pyear}`).update(pickingOrdersObj).then(() => {
                  fire.database().ref(`Driver_Orders_Short_Orders/${year}/${month}/${ddate}`).update(shiprouteele).then(() => {
                    let picking_allocation_obj = {};
                    Object.keys(pickingAllocationData).map((key,index) => {
                      let routeObj = pickingAllocationData[key] || {};
  
                      Object.keys(routeObj).map((rkey,rindex) => {
                        let route = routeObj[rkey].picker_route;
                        if(finalRouteSet.has(route)) {
                          picking_allocation_obj[`/${key}/${rkey}/short_picking_update`] = null;
                          picking_allocation_obj[`/${key}/${rkey}/bag_id_update`] = null;
                          picking_allocation_obj[`/${key}/${rkey}/status`] = "Assigned";
                        }
  
                      })
                    })
  
                    let details = {};
                    details['picking_date'] = pickingDate;
                    details['routes'] = [...finalRouteSet];
  
                    let bag_tracking_details = {};
                    bag_tracking_details['warehouse'] = selectedCity;
                    bag_tracking_details['routes'] = [...finalRouteSet];
                    bag_tracking_details['delivery_date'] = deliverDate;
  
                    if(Object.keys(picking_allocation_obj).length>0) {
                      fire.database().ref(`Picking_Allocation_History/${pyear}/${pmonth}/${pdate}-${pmonth}-${pyear}`).update(picking_allocation_obj).then(() => {
                        
                        fetch(`${service_url}/api/delete-picking-update`, {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                            "x-access-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MTU0OWIwMTIwMWUyZjMzZWE3NmFkZjYiLCJlbWFpbCI6InNtdHdpbmtsZTQ1MkBnbWFpbC5jb20iLCJpYXQiOjE2MzI5MzQ2NTgsImV4cCI6MTYzMjkzODI1OH0._oHr3REme2pjDDdRliArAeVG_HuimbdM5suTw8HI7ut",
                          },
                          body: JSON.stringify(details),
                        })
                        .then((res) => {
                          return res.json();
                        })
                        .then((result) => {
                          if (result["success"] != 1) {
                            let errMessage = result["message"] ? result["message"] : "";
                            alert("Error in deleting picking update table : " + errMessage);
                          } else {
                            fetch(`${service_url}/api/reset-picking-bag-tracking`, {
                              method: "DELETE",
                              headers: {
                                "Content-Type": "application/json",
                                "x-access-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MTU0OWIwMTIwMWUyZjMzZWE3NmFkZjYiLCJlbWFpbCI6InNtdHdpbmtsZTQ1MkBnbWFpbC5jb20iLCJpYXQiOjE2MzI5MzQ2NTgsImV4cCI6MTYzMjkzODI1OH0._oHr3REme2pjDDdRliArAeVG_HuimbdM5suTw8HI7ut",
                              },
                              body: JSON.stringify(bag_tracking_details),
                            })
                            .then((res) => res.json())
                            .then((result) => {
                              if (result["success"] != 1) {
                                let errMessage = result["message"] ? result["message"] : "";
                                alert("Error in resetting picking bag tracking: " + errMessage);
                              } else {
                                return toast(`Successfully reset reset doh, picking data,bag tracking`, { type: toast.TYPE.SUCCESS });
                              }
                            })
                            .catch((err) => {
                              alert("Error in resetting picking bag tracking: " + err);
                            });
                          }
                        })
                        .catch((err) => {
                          alert("Error in deleting picking update table : " + err);
                        });
                      })
                    } else {
                      fetch(`${service_url}/api/delete-picking-update`, {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                          "x-access-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MTU0OWIwMTIwMWUyZjMzZWE3NmFkZjYiLCJlbWFpbCI6InNtdHdpbmtsZTQ1MkBnbWFpbC5jb20iLCJpYXQiOjE2MzI5MzQ2NTgsImV4cCI6MTYzMjkzODI1OH0._oHr3REme2pjDDdRliArAeVG_HuimbdM5suTw8HI7ut",
                        },
                        body: JSON.stringify(details),
                      })
                      .then((res) => {
                        return res.json();
                      })
                      .then((result) => {
                        if (result["success"] != 1) {
                          let errMessage = result["message"] ? result["message"] : "";
                          alert("Error in deleting picking update table : " + errMessage);
                        } else {
                          fetch(`${service_url}/api/reset-picking-bag-tracking`, {
                            method: "DELETE",
                            headers: {
                              "Content-Type": "application/json",
                              "x-access-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MTU0OWIwMTIwMWUyZjMzZWE3NmFkZjYiLCJlbWFpbCI6InNtdHdpbmtsZTQ1MkBnbWFpbC5jb20iLCJpYXQiOjE2MzI5MzQ2NTgsImV4cCI6MTYzMjkzODI1OH0._oHr3REme2pjDDdRliArAeVG_HuimbdM5suTw8HI7ut",
                            },
                            body: JSON.stringify(bag_tracking_details),
                          })
                          .then((res) => res.json())
                          .then((result) => {
                            if (result["success"] != 1) {
                              let errMessage = result["message"] ? result["message"] : "";
                              alert("Error in resetting picking bag tracking: " + errMessage);
                            } else {
                              return toast(`Successfully reset reset doh, picking data, bag tracking`, { type: toast.TYPE.SUCCESS });
                            }
                          })
                          .catch((err) => {
                            alert("Error in resetting picking bag tracking: " + err);
                          });
                        }
                      })
                      .catch((err) => {
                        alert("Error in deleting picking update table : " + err);
                      });
                    }
  
                  });
                })
              }
            });
          });
        } else {
          toast(`No data to reset`, { type: toast.TYPE.ERROR });
        }
        

      } else {
        alert("Error in fetching delivery orders");
      }
    }).catch((error) => {
      alert("Error in fetching delivery orders :- "+error);
    });
  }

  return (<>
          <Modal
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
          >
              <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                    Picking Tasks
                  </Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <Table>
                  <tbody>
                      <tr>
                          <td>Delivery Date</td>
                          <td><Form.Control type="date" value={deliverDate} readOnly onChange={(e) => { setDeliverDate(e.target.value); }} /></td>
                      </tr>
                      <tr>
                          <td>Picking Date</td>
                          <td><Form.Control type="date" value={pickedDate} readOnly onChange={(e) => { setPickedDate(e.target.value); }} /></td>
                      </tr>
                      <tr>
                          <td>WareHouse</td>
                          <td><Select options={cityDropdownList} placeholder={selectedCity} onChange={(val)=>{ setSelectedCity(val.value); }}/></td>
                      </tr>

                      {selectedCity && vehicleDropdownList.length>0 && <tr>
                          <td>Route</td>
                          <td><Select key={Math.random()} ref={bulkRouteRef} options={vehicleDropdownList} defaultValue={vehicleDropdownList} isMulti /></td>
                      </tr>}
                  </tbody>
              </Table>

              </Modal.Body>
              <Modal.Footer>
                  <Button onClick={()=>{ generatePickingData(); }}>Create Picking Tasks</Button>
                  {(validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email)) || validateIsSystemsUser((JSON.parse(sessionStorage.getItem(`user`))[0].email))) && <Button onClick={()=>{ resetPickingData(); }}>Reset Picking Tasks</Button>}
                  {(validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email)) || validateIsSystemsUser((JSON.parse(sessionStorage.getItem(`user`))[0].email))) && <Button onClick={()=>{ setShipModifyPopupShow(true); }}>Modify Shipped/Processed Orders</Button>}
                  <Button onClick={props.onHide}>Close</Button>
              </Modal.Footer>
          </Modal>
          {loading ? (<Backdrop parentLoadStatus={loading} />) : (null)}

          {getShipModifyPopupShow==true?<ShipModifyOrdersModal
              show={getShipModifyPopupShow}
              onHide={() => setShipModifyPopupShow(false)}
              callback = {() => setShipModifyPopupShow(false)}
              dohData={dohData}
              deliveryDate={deliveryDate}
              vehicleDropdownList={getSelectedRoute()}
          />:null}
      </>
  )
}

export default PickingModal

