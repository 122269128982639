import React, { useState, useMemo, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NavBar from "../Navigation bar/Navbar";
import axios from "axios";

import {
  Container,
  Table,
  Row,
  Col,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useAsyncDebounce,
} from "react-table";
import { service_url } from "../../Configs/mysqlconfig";

function PendingEscalations() {
  const [PEData, setPEData] = useState([]);
  const [show1, setShow1] = useState(false);
  const [OrderNo, setOrderNo] = useState();
  const [OrderDetail, setOrderDetail] = useState();
  const [PendingFilter, setPendingFilter] = useState(true)
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (val) => {
    setOrderNo(val);
    setShow1(true);
  };

  useEffect(() => {
    axios
      .get(
        `${service_url}/api/superzop/delivery/drivers_orders_history/getpendingescalations?not_visited=${PendingFilter}`
      )
      .then((result) => {
        setPEData(result.data);

      })
      .catch((error) => {
        console.log(error);
       
      });

    return () => {};
  }, [PendingFilter]);

  useEffect(() => {
    axios
      .get(
        `${service_url}/api/superzop/delivery/drivers_orders_history/getOrderDetailsForPendingEscalations?order_number=${OrderNo}`
      )
      .then((result) => {
        console.log(result.data);
        setOrderDetail(result.data);
      })
      .catch((error) => {
        console.log(error);
      });

    return () => {};
  }, [OrderNo]);



  function Table1() {

    function filterPending(e) {
      setPendingFilter(!PendingFilter)
    }

    let table = PEData.map((itm) => {
      return {
        order_number: itm?.order_number,
        latest_delivery_date: itm?.latest_delivery_date,
        total_planned: itm?.total_planned,
        reason: itm?.reason
        
      };
    });

    console.log(table);

    const data = useMemo(() => table, []);

    const columns = useMemo(
      () => [
        {
          Header: "Order number",
          accessor: "order_number",
          Cell: ({ row }) => {
            return (
              <Button
                variant="link"
                onClick={() => handleShow1(row.original.order_number)}
              >
                {" "}
                {row.original.order_number}
              </Button>
            );
          },
        },
        {
          Header: "Latest delivery date",
          accessor: "latest_delivery_date",
        },
        {
          Header: "Total planned",
          accessor: "total_planned",
          Filter: SelectColumnFilter,
        },
        {
          Header: "reason",
          accessor: "reason",
          Filter: SelectColumnFilter,
        },
      ],
      []
    );

    function SelectColumnFilter({
      column: { filterValue, setFilter, preFilteredRows, id },
    }) {
      const options = useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
          options.add(row.values[id]);
        });
        return [...options.values()];
      }, [id, preFilteredRows]);

      return (
        <select
          value={filterValue}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
        >
          <option value="">All</option>
          {options.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </select>
      );
    }

    const defaultColumn = useMemo(
      () => ({
        Filter: () => null,
      }),
      []
    );

    const GlobalFilter = ({ filter, setFilter }) => {
      const [value, setValue] = useState(filter);
      const onChange = useAsyncDebounce((value) => {
        setFilter(value || undefined);
      }, 1000);
      return (
        <span>
          <Form.Control
            placeholder="search..."
            value={value || ""}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
          />
        </span>
      );
    };

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      setFilter,
      setGlobalFilter,
      preGlobalFilteredRows,
    } = useTable({ columns, data, defaultColumn }, useFilters, useGlobalFilter);

    const { globalFilter } = state;


 

    return (
      <div>
        <Row>
          <Col>
            <GlobalFilter
              filter={globalFilter}
              setFilter={setGlobalFilter}
            ></GlobalFilter>

          </Col>
          <Col>
          <Form.Check 
            type="checkbox"
            label={`Ignore Not Visited`}
            onChange = {filterPending}
            value ={PendingFilter}
            checked = {PendingFilter}

            
          />
          </Col>
          <Col>{/* <Button variant="warning">Primary</Button>{" "} */}</Col>
          <Col>{/* <Button variant="success">Primary</Button>{" "} */}</Col>
          <Col>{/* <Button variant="warning">Primary</Button>{" "} */}</Col>
          <Col>{/* <Button variant="info">Primary</Button>{" "} */}</Col>
        </Row>
        <br />

        <table
          {...getTableProps()}
          style={{ border: "solid 1px blue", width: "100%" }}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{
                      borderBottom: "solid 3px red",
                      background: "aliceblue",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {column.render("Header")}
                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: "10px",
                          border: "solid 1px gray",
                          background: "papayawhip",
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <>
      <NavBar />
      <br /> <br /> <br /> <br />
      <div style={{ margin: 20 }}>
        <h2> Pending Escalations </h2>


        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <Row>
          <Col>
            <Table1 />
          </Col>
        </Row>

        <Modal show={show1} onHide={handleClose1} size="lg">
          <Modal.Header closeButton>
            <Modal.Title> Order Details </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* {JSON.stringify(OrderDetail)} */}

            <Table striped bordered hover>
              <thead>
              <tr>
                  <td>Order number</td>
                  <td>Delivery date</td>
                  <td>Route</td>
                  <td>Status</td>
                  <td>Reason</td>
                  <td>Order date</td>
                  <td>Order value</td>
           
                </tr>
              </thead>
              <tbody>
                {
                  OrderDetail && OrderDetail.map((itm)=>{
                    return (
                      <tr>
                    
                        <td>{OrderDetail && itm?.order_number}</td>
                        <td>{OrderDetail && itm?.delivery_date}</td>
                        <td>{OrderDetail && itm?.route}</td>
                        <td>{OrderDetail && itm?.status}</td>
                        <td>{OrderDetail && itm?.reason}</td>
                        <td>{OrderDetail && itm?.order_date}</td>
                        <td>{OrderDetail && itm?.order_value}</td>
                     
                    </tr>
                    )
                  })
                }
           
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose1}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default PendingEscalations;
