import { token, service_url } from "../Configs/mysqlconfig";

export function fetchItemInventoryMovements(
  startDate,
  endDate,
  warehouse,
  itemId
) {
  let url = `${service_url}/api/item-inventory-movements?start_date=${startDate}&end_date=${endDate}&warehouse=${warehouse}&item_id=${itemId}&token=${token}`;
  return fetch(url).then((res) => res.json());
}

export function fetchInventoryMovements(date, warehouse) {
  let url = `${service_url}/api/inventory-movements?start_date=${date}&end_date=${date}&warehouse=${warehouse}&token=${token}`;
  return fetch(url).then((res) => res.json());
}

export function fetchInventoryMovementsExportAll(date) {
  let url = `${service_url}/api/inventory-movements-export-all?start_date=${date}&token=${token}`;
  return fetch(url).then((res) => res.json());
}

export function fetchInventoryReports(startDate, endDate, warehouse) {
  let url = `${service_url}/api/inventory-reports?start_date=${startDate}&end_date=${endDate}&warehouse=${warehouse}&token=${token}`;
  return fetch(url).then((res) => res.json());
}

export function fetchStockDiscrepancyReports(startDate, endDate, warehouse) {
  let url = `${service_url}/api/stock-correction-report?isAuto=Y&start_date=${startDate}&end_date=${endDate}&warehouse=${warehouse}&token=${token}`;
  return fetch(url).then((res) => res.json());
}

export function fetchStockCorrectionReports(startDate, endDate, warehouse) {
  let url = `${service_url}/api/stock-correction-report?isAuto=N&start_date=${startDate}&end_date=${endDate}&warehouse=${warehouse}&token=${token}`;
  return fetch(url).then((res) => res.json());
}

export function fetchPriceDetails() {
  let url = `${service_url}/api/superzop/pricelist/price-details?token=${token}`;
  return fetch(url).then((res) => res.json());
}
