import React from "react";
import {
  redHighlightColor,
  greenHighlightColor,
  orangeHighlightColor,
  sortByTypeCategoryItemId,
} from "../../Inventory/common/constants";
import { Table, Form } from "react-bootstrap";
import {
  checkForApprovedQtyDiscrepancy,
  getColorBasedOnRowDataTransitReturn,
} from "../../Inventory/common/utils";
import { MultiSelect, Textarea } from "@mantine/core";
const GLOBAL_PARENT_WAREHOUSE = "Bhiwandi";
const userCity = sessionStorage.getItem("userCity") || "";
const userType = sessionStorage.getItem("userType") || "";

const isReceivedQtyFieldsDisabled = (
  user_type,
  user_city,
  is_approved_qty_fields_shown
) => {
  if (is_approved_qty_fields_shown) {
    return true;
  } else if (user_type === "Admin") {
    return false;
  } else if (
    (user_type === "Operation" && user_city === "") ||
    user_city === "Bhiwandi"
  ) {
    return false;
  } else {
    return true;
  }
};

const isApprovedQtyFieldsDisabled = (
  user_type,
  user_city,
  is_approved_qty_fields_editable
) => {
  if (!is_approved_qty_fields_editable) {
    return true;
  } else if (user_type === "Admin") {
    return false;
  } else if (
    (user_type === "Operation" && user_city === "") ||
    user_city === "Bhiwandi"
  ) {
    return true;
  } else {
    return false;
  }
};

export const TransitReturnLooseTableHeader = (props) => {
  const isApprovedQtyFieldsShown = props.isApprovedQtyFieldsShown;
  return (
    <thead className="thead-light">
      <tr>
        <th>Order No </th>
        <th>Bag ID</th>
        <th>Item ID</th>
        <th>Item description</th>
        <th>Category</th>
        <th>Inventory</th>
        <th>Customer Returns</th>
        <th>Return Qty</th>
        <th>Received Qty</th>
        <th>QR Ids</th>
        <th>Remarks</th>
        {/* {isApprovedQtyFieldsShown ? <th>Approved Qty</th> : null}
        {isApprovedQtyFieldsShown ? <th>Remarks</th> : null} */}
      </tr>
    </thead>
  );
};
const TransitReturnLooseTableBody = ({
  routeProductData,
  overallReturnSummaryDetails,
  setOverallReturnSummaryDetails,
  showDiscrepancyItemsChecked,
  isApprovedQtyFieldsShown,
  isApprovedQtyFieldsEditable,
}) => {
  let itemsListData = routeProductData;

  let rows = itemsListData.map((row, index) => {
    row.bgColor = getColorBasedOnRowDataTransitReturn(
      row,
      isApprovedQtyFieldsEditable,
      isApprovedQtyFieldsShown
    );

    if (
      showDiscrepancyItemsChecked &&
      !checkForApprovedQtyDiscrepancy(
        row,
        isApprovedQtyFieldsEditable,
        isApprovedQtyFieldsShown,
        "return"
      )
    ) {
      return null;
    }
    return (
      <TransitReturnLooseTableRow
        row={row}
        index={index}
        isApprovedQtyFieldsShown={isApprovedQtyFieldsShown}
        isApprovedQtyFieldsEditable={isApprovedQtyFieldsEditable}
        overallReturnSummaryDetails={overallReturnSummaryDetails}
        setOverallReturnSummaryDetails={setOverallReturnSummaryDetails}
      />
    );
  });

  return (
    <>
      <tbody>{rows}</tbody>
    </>
  );
};

const TransitReturnLooseTableRow = ({
  row,
  index,
  isApprovedQtyFieldsShown,
  isApprovedQtyFieldsEditable,
  overallReturnSummaryDetails,
  setOverallReturnSummaryDetails,
}) => {
  return (
    <tr key={index} style={{ backgroundColor: row.bgColor ? row.bgColor : "" }}>
      <td>{row.order_number}</td>
      <td>{row.bag_id}</td>
      <td>{row.item_id}</td>
      <td>{row.item_disc}</td>
      <td>{row.category}</td>
      <td>{row.inventory}</td>
      <td>{row.returned_qty}</td> {/*Customer Returns */}
      <td>{row.return_qty}</td>
      <td>
        <fieldset
          disabled={isReceivedQtyFieldsDisabled(
            userType,
            userCity,
            isApprovedQtyFieldsShown
          )}
        >
          <Form.Control
            type="number"
            defaultValue={row.received_qty ? Number(row.received_qty) : ""}
            onChange={(e) => {
              let _routeSumDetails = [...overallReturnSummaryDetails];
              _routeSumDetails[index].received_qty = e.target.value;
              if (
                row.id &&
                _routeSumDetails[index].received_qty !== row.return_qty
              ) {
                _routeSumDetails[index].bgColor = redHighlightColor;
              } else if (
                row.id &&
                _routeSumDetails[index].received_qty === row.return_qty
              ) {
                _routeSumDetails[index].bgColor = "white";
              }
              setOverallReturnSummaryDetails(_routeSumDetails);
            }}
          />
        </fieldset>
      </td>
      <td style={{ width: 280 }}>
        <MultiSelect
          styles={{
            item: { fontSize: 12 },
            dropdown: { backgroundColor: "#d3dbd5" },
          }}
          value={row.qr_id}
          name="qr_id_selection"
          data={row.qr_id.map((item) => {
            return { label: item, value: item };
          })}
          placeholder="Pick one"
          nothingFound="No options"
          searchable
          zIndex={999999999999}
        />
      </td>
      <td>
        <fieldset
          disabled={isReceivedQtyFieldsDisabled(
            userType,
            userCity,
            isApprovedQtyFieldsShown
          )}
        >
          <Textarea
            placeholder="Enter received remarks"
            defaultValue={row.rec_remarks || ""}
            onChange={(e) => {
              let _routeSumDetails = [...overallReturnSummaryDetails];
              _routeSumDetails[index].rec_remarks = e.target.value;
              setOverallReturnSummaryDetails(_routeSumDetails);
            }}
          />
        </fieldset>
      </td>
      {/* {isApprovedQtyFieldsShown &&
      (row.showApprovedQtyField || userType !== "Operation") ? (
        <td>
          <fieldset
            disabled={isApprovedQtyFieldsDisabled(
              userType,
              userCity,
              isApprovedQtyFieldsEditable
            )}
          >
            <Form.Control
              type="number"
              defaultValue={row.approved_qty ? Number(row.approved_qty) : ""}
              onChange={(e) => {
                let _routeSumDetails = [...overallReturnSummaryDetails];
                _routeSumDetails[index].approved_qty = e.target.value;

                setOverallReturnSummaryDetails(_routeSumDetails);
              }}
            />
          </fieldset>
        </td>
      ) : null} */}
      {/* {isApprovedQtyFieldsShown &&
      (row.showApprovedQtyField || userType !== "Operation") ? (
        <td>
          <fieldset
            disabled={isApprovedQtyFieldsDisabled(
              userType,
              userCity,
              isApprovedQtyFieldsEditable
            )}
          >
            <Textarea
              placeholder="Enter approved remarks"
              defaultValue={row.app_remarks || ""}
              onChange={(e) => {
                let _routeSumDetails = [...overallReturnSummaryDetails];
                _routeSumDetails[index].app_remarks = e.target.value;
                setOverallReturnSummaryDetails(_routeSumDetails);
              }}
            />
          </fieldset>
        </td>
      ) : null} */}
    </tr>
  );
};

export const TransitReturnLooseTable = ({
  routeProductData,
  overallReturnSummaryDetails,
  currentSelectedRoute,
  setOverallReturnSummaryDetails,
  removeItemFromSummaryDetails,
  transitID,
  init,
  isApprovedQtyFieldsShown,
  warehouse,
  isApprovedQtyFieldsEditable,
  showDiscrepancyItemsChecked,
}) => {
  return (
    <>
      <Table striped bordered hover>
        <TransitReturnLooseTableHeader
          isApprovedQtyFieldsShown={isApprovedQtyFieldsShown}
        />
        <TransitReturnLooseTableBody
          routeProductData={routeProductData}
          overallReturnSummaryDetails={overallReturnSummaryDetails}
          currentSelectedRoute={currentSelectedRoute}
          setOverallReturnSummaryDetails={setOverallReturnSummaryDetails}
          removeItemFromSummaryDetails={removeItemFromSummaryDetails}
          init={init}
          transitID={transitID}
          isApprovedQtyFieldsShown={isApprovedQtyFieldsShown}
          warehouse={warehouse}
          isApprovedQtyFieldsEditable={isApprovedQtyFieldsEditable}
          showDiscrepancyItemsChecked={showDiscrepancyItemsChecked}
        />
      </Table>
    </>
  );
};
