import fire from '../../Configs/firebase'
function getShops(vehicle_id,year,month,date,props,callback_hook,callback_locate_json){
    //first goto driver details and get driver_id
    let driver_id = 0
    let co_ordinates = []
    let visited = []


    fire.database().ref('Driver_Details/'+vehicle_id+'/driver_id').once('value',(snapshot)=>{
        driver_id=snapshot.val()
    }).then(()=>{
        fire.database().ref(`Driver_Orders_History/${year}/${month}/${date}/${driver_id}`).once('value',(snapshot)=>{
            const data = snapshot.val()
            if(!data)
                return
            Object.keys(data).forEach((value)=>{
                const hashValue = value.split('-')[0]
                // console.log(hashValue)
                if(visited.indexOf(hashValue)!=-1)
                    return
                const link = 'Driver_Orders_History/'+year+'/'+month+'/'+date+'/'+driver_id+'/'+value
                // console.log(link)
                const delivery=data[value].status;
                const actualStatus=delivery.split("/")[0]

                visited.push(hashValue)
                co_ordinates.push({lat:data[value].latitude,lng:data[value].longitude,priority:data[value].priority,del:actualStatus,link:link,orderNumber:data[value]['order_number'],vehicle:data[value]['vehicle'],shop:data[value]['shop_name'],address:data[value]['address1']+" "+data[value]['address2']})
            })
        }).then(()=>{
            callback_hook(co_ordinates)
            callback_locate_json(props)
        })

    }
    )

}

export default getShops
