import React, { Component } from 'react';
import { Link,Redirect } from 'react-router-dom';
import {Form,Button, Card,Alert} from 'react-bootstrap';
import database from '../../Configs/firebase'
import {support_portal_url} from '../../Configs/mysqlconfig';
import { LoginContainer,LoginHeader,UserDetailsContainer } from './wrapper';
import { validateIsOperationUser, validateIsBillingUser, validateIsAdminUser,validateIsPackingUser,validateIsFinanceUser,validateIsFinanceHeadUser, validateIsSystemsUser, validateIsDistributorUser } from '../UserCredentials'

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hour = '' + d.getHours(),
        minute = '' + d.getMinutes();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    if (hour.length < 2)
        hour = '0' + hour;
    if (minute.length < 2)
        minute = '0' + minute;

    //dd/mm/yyyy hh:mm
    return [day, month, year].join('/')+" "+[hour,minute].join(':');
}

class Login extends Component {

  constructor(props) {
    super(props);
    this.login = this.login.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.signInWithOTP = this.signInWithOTP.bind(this);
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);

    this.state = {
      email: '',
      password: '',
      user : null,
      error : false,
      inputUserObj : {},
      hideEmailForm : false,
      otpErrMsg : "",
      otp: "",
      otpSentMsg: "",
      seconds: 0,
      resendOtpGreyed: true,
      isTimerActive: false
    };
  }

  startTimer() {
    // console.log("startTimer");
    if (this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    // console.log(this.state);
    let seconds = this.state.seconds - 1;

    // Check if we're at zero.
    if (seconds == 0) {
      this.setState({resendOtpGreyed: false});
      clearInterval(this.state.seconds);
    }

    if(seconds>=0){
      this.setState({
        seconds: seconds,
      });
    }

  }


  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });

  }

  login(e) {
    e.preventDefault();

    let apiurlstr=`${support_portal_url}/api/deliveryportallogin`
    let details = {
      'email': this.state.email,
      'password':this.state.password
    };
    // console.log(JSON.stringify(details));

    fetch(apiurlstr, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(details),
    })
    .then(response => {
        return response.json();
     }).then((result)=>{

       if(result.success==1){
          let loggedInUserDetails=result['data']?result['data']:{};
          if(result['otpauth']==0){
            sessionStorage.setItem('user',JSON.stringify([loggedInUserDetails]));

            let currentTimestamp=Date.now();
            sessionStorage.setItem("session-start-date",currentTimestamp);

            sessionStorage.setItem("apiToken",result.token);
            sessionStorage.setItem('originalUserType',loggedInUserDetails.user_type)
            sessionStorage.setItem("userCity",loggedInUserDetails.city || "")
            if(validateIsBillingUser(this.state.email))
            sessionStorage.setItem('userType',"Billing")
            if(validateIsOperationUser(this.state.email))
            sessionStorage.setItem('userType',"Operation")
            if(validateIsPackingUser(this.state.email))
            sessionStorage.setItem('userType',"Packing")
            if(validateIsFinanceUser(this.state.email))
            sessionStorage.setItem('userType',"Finance")
            if(validateIsFinanceHeadUser(this.state.email))
            sessionStorage.setItem('userType',"FinanceHead")
            if(validateIsSystemsUser(this.state.email))
            sessionStorage.setItem('userType',"Systems")
            if(validateIsDistributorUser(this.state.email))
            sessionStorage.setItem('userType',"Distributor")
            if(validateIsAdminUser(this.state.email))
            sessionStorage.setItem('userType',"Admin")

            localStorage.removeItem('bulkOrdersRouteWise');
            localStorage.removeItem('orderHistory');
            localStorage.removeItem('orderData');
            localStorage.removeItem("offersObj");
            localStorage.removeItem("customOffersObj");

            this.props.history.push('/home')

            window.location.href='/home';
          }else{
            let mobile=loggedInUserDetails.mobile?loggedInUserDetails.mobile:"";
            let otp_bypass=loggedInUserDetails.otp_bypass=="1"?true:false;

            if(otp_bypass){
              //username,password login
            }else{
              console.log("otp auth");
              //otp auth
              if(mobile){
                this.setState({ inputUserObj : loggedInUserDetails,
                      hideEmailForm : true,
                      otpErrMsg : ""
                });

                this.generateOtp(mobile);
              }else{
                this.setState({
                      hideEmailForm : true,
                      otpErrMsg : "Invalid mobile number."
                });
              }
            }
          }
       }else{
         let errorMessage=result.message?result.message:"";
         this.setState({error:errorMessage});
       }
     })
    .catch((error) => {
        alert(error)
    });
  }

  generateOtp(phoneNumber) {
    if(!phoneNumber){
      phoneNumber=this.state.inputUserObj.mobile;
    }
    // console.log(phoneNumber);
    let userID=this.state.inputUserObj.id;

    if(!phoneNumber){
      alert("Please enter phone number");
    }else if(phoneNumber.length!=10){
      alert("Please enter 10 digit phone number");
    }else{
      let apiurlstr=`${support_portal_url}/api/otp?phone=${phoneNumber}&ID=${userID}&portal=delivery`
      // console.log(apiurlstr);
      fetch(apiurlstr)
      .then((res) => {
        return res.json();
      })
      .then((data)=>{
        if(data['success']==1){
          // console.log(data);
          this.setState({
            otpSentMsg: `OTP has been sent to ${phoneNumber}`,
            otpErrMsg : "",
            seconds: 30,
            resendOtpGreyed: true,
            isTimerActive: true
          });

          this.startTimer();
        }else{
          let errMessage=data['message']?data['message']:"";
          this.setState({
            otpSentMsg: ``,
            otpErrMsg : ""
          });
        }
      })
      .catch((error) => {
        console.log(error);
        alert("Error in sending OTP"+error);
      });
    }
  }

  signInWithOTP(e) {
    e.preventDefault();
    // console.log("signInWithOTP");
    // console.log(this.state);

    let userObj={"ID":this.state.inputUserObj.id,"otp":this.state.otp,"portal":"delivery"};

    fetch(`${support_portal_url}/api/validateotp`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(userObj)
    }).then(res => {
      return res.json();
    }).then(result => {
      // console.log(result);
      if(result['success']==1){
        this.setState({otpErrMsg:"",otpSentMsg:""});
        sessionStorage.setItem('user',JSON.stringify([this.state.inputUserObj]));

        let currentTimestamp=Date.now();
        sessionStorage.setItem("session-start-date",currentTimestamp);

        sessionStorage.setItem("apiToken",result.token);
        sessionStorage.setItem('originalUserType',this.state.inputUserObj.user_type)
        sessionStorage.setItem("userCity",this.state.inputUserObj.city || "")

        if(validateIsAdminUser(this.state.email))
        sessionStorage.setItem('userType',"Admin")
        if(validateIsBillingUser(this.state.email))
        sessionStorage.setItem('userType',"Billing")
        if(validateIsOperationUser(this.state.email))
        sessionStorage.setItem('userType',"Operation")
        if(validateIsPackingUser(this.state.email))
        sessionStorage.setItem('userType',"Packing")
        if(validateIsFinanceUser(this.state.email))
        sessionStorage.setItem('userType',"Finance")
        if(validateIsDistributorUser(this.state.email))
        sessionStorage.setItem('userType',"Distributor")

        localStorage.removeItem('bulkOrdersRouteWise');
        localStorage.removeItem('orderHistory');
        localStorage.removeItem('orderData');
        localStorage.removeItem("offersObj");
        localStorage.removeItem("customOffersObj");

        this.props.history.push('/home')

        window.location.href='/home';
      }else{
        this.setState({otpErrMsg:"OTP is incorrect.",otpSentMsg:""});
      }

    })
  }

  render() {
    return (

     <LoginContainer>
        <LoginHeader><br/><br/>
                <span></span>   {/* for logo, so don't remove the span */}
            </LoginHeader>
            <UserDetailsContainer>
              <h3>User Login</h3>
   {!this.state.hideEmailForm && <Form >
      <Form.Group controlId="formBasicEmail">
        <Form.Control type="email" placeholder="Enter email" name="email" onChange={this.handleChange}/>
        <Form.Text className="text-muted">
          We will never share your email with anyone else.
        </Form.Text>
      </Form.Group>
      <Form.Group controlId="formBasicPassword">
        <Form.Control type="password" placeholder="Password" name="password" onChange={this.handleChange}/>
      </Form.Group>
      <Button variant="primary" id="loginBtn" type="submit" onClick={this.login}>
        Submit
      </Button>
    </Form>}

    {this.state.hideEmailForm && <Form >
       <Form.Group controlId="formBasicOtp">
         <Form.Control type="text" placeholder="Enter OTP" name="otp" onChange={this.handleChange}/>
       </Form.Group>

       {this.state.otpErrMsg?(<span>
          <Alert variant="danger">
             {this.state.otpErrMsg}
          </Alert>
       </span>):null}

       {this.state.otpSentMsg?(<span>
          <Alert variant="success">
             {this.state.otpSentMsg}
          </Alert>
       </span>):null}

        <div style={{textAlign:"center",marginTop:"10px"}}>
          {this.state.isTimerActive == true ? (<p>Time Left : 00:{this.state.seconds < 10 ? '0' + this.state.seconds : this.state.seconds}</p>) : null}
        </div>

        <div style={{textAlign:"center",marginTop:"10px"}}>
          {this.state.isTimerActive == true ? ((this.state.resendOtpGreyed == true) ? (<Button variant="success" color="primary" type="submit" disabled={true} onClick={(e) => { e.preventDefault(); this.generateOtp(); }}>Resend OTP</Button>) : (<Button variant="success" type="submit" onClick={(e) => { e.preventDefault(); this.generateOtp(); }}>Resend OTP</Button>)) : null}
        </div>

       <center><Button variant="primary" style={{fontSize: "1.3rem"}} type="submit" onClick={this.signInWithOTP}>
         Submit OTP
       </Button></center>

     </Form>}

{
  this.state.success?(<span>
    <Alert variant="danger">
        Invalid email/password.Please try again
      </Alert>
      </span>):null
}
{this.state.error?
(<span>
<Alert variant="danger">
    Invalid email/password.Please try again
  </Alert>
  </span>):null
  }

 </UserDetailsContainer>
 </LoginContainer>


    );
  }
}

export default Login;
