import React, { Component,useState,useRef } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom'
import Backdrop from '../backdrop/backdrop'
import { IconButton } from '@material-ui/core'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from 'react-select'
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { InputGroupAddon, Input, InputGroupText, CardHeader, CardTitle, CardText } from 'reactstrap';
import { toast } from 'react-toastify';
import fire from '../../Configs/firebase'
import { Tab, Row, Col, Form, Dropdown, ProgressBar, Tabs, Image, InputGroup, Button, DropdownButton, Breadcrumb, Table, ButtonGroup, Container, Accordion, Card, Navbar } from 'react-bootstrap'
import { validateIsAdminUser,validateIsOperationUser } from '../UserCredentials'
import {support_portal_url} from '../../Configs/mysqlconfig';

toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
});


let bulkPrintOrdersList=[];

function formatDateTime(datetimeval) {

    let datetimestr = datetimeval.split(' ');
    let datestr = datetimestr[0];
    let timestr = datetimestr[1];
    let timestring=""

    let datestrsplit = datestr.split('-');
    let datestring = datestrsplit[2] + '/' + datestrsplit[1] + '/' + datestrsplit[0];
    if(timestr){
    let timestrsplit = timestr.split(':');
    timestring = ' ' + timestrsplit[0] + ':' + timestrsplit[1];
    }
    return datestring + timestring;
}

const ReturnOrdrListTableHeader = () => {
    return (
        <thead className="thead-light">
            <tr>
                <th>OrderNumber</th>
                <th>Retailer Id</th>
                <th>OrderValue</th>
                <th>ShopName</th>
                <th>ShopPhoneNumber</th>
                <th>OrderDate</th>
                <th>Status</th>
                <th>Update Status</th>
                <th>Return Image</th>
                <th>Print</th>
            </tr>
        </thead>
    );

}

function updateOrderStatus(updatedStatus,orderNumber){
  console.log(updatedStatus);
  console.log(orderNumber);
  const data = { 'status':updatedStatus , 'ordernumber':orderNumber };

  fetch(`${support_portal_url}/api/updatereturnorderstatus`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': sessionStorage.getItem("apiToken")
    },
    body: JSON.stringify(data)
  })
  .then(response => response.json())
  .then(data => {
    if(data['success']==1){
      toast("Updated order status successfully in MySQL", { type: toast.TYPE.SUCCESS });
    }else{
      let errMessage=data['message']?data['message']:"";
      toast("Couldn't update the order status in MySQL : "+errMessage, { type: toast.TYPE.ERROR });
      if(data['status']==401 || data['status']==403){
        sessionStorage.clear();
        window.location.href="/"
      }
    }

  })
  .catch((error) => {
    toast("Couldn't update the order status in MySQL : "+error, { type: toast.TYPE.ERROR });
  });

  //update in firebase
  let orderNumberExistInFirebase=false;
  let orderNumberList=orderNumber.split('/');
  let orderNumberKey=orderNumberList[0]+'-'+orderNumberList[1];
  console.log(orderNumberKey);
  let ordersNewRef = fire.database().ref('Orders_New/');
  ordersNewRef.on('value', function(snapshot) {
    if(snapshot.hasChildren()){
      let ordersItemsList=snapshot.val();
      Object.keys(ordersItemsList).map((key, index) => {
        if(key.includes(orderNumberKey)){
          console.log(key);
          orderNumberExistInFirebase=true;
          ordersNewRef.child(key).update({'status':updatedStatus}).then(() => {
              toast("Updated order status successfully in Firebase", { type: toast.TYPE.SUCCESS });
          }).catch(()=>{
              toast("Couldn't update the order status in Firebase", { type: toast.TYPE.ERROR });
          })
        }
      });
      console.log(orderNumberExistInFirebase);
    }
  });
}

function openReturnImage(orderNumber){
  console.log(orderNumber);
  let orderNumberList=orderNumber.split('/');
  let orderNumberKey=orderNumberList[0]+'-'+orderNumberList[1]+'-1';
  fire.database().ref('Orders_New/'+orderNumberKey+'/return_image_url/').once('value',snapshot=>{
    let image_url=snapshot.val();
    console.log(image_url);
    if(image_url!=null){
      window.open(image_url, "_blank");
    }else{
      toast("Return image is not available", { type: toast.TYPE.ERROR });
    }
  })
}

const ReturnOrdrListTableBody = props => {
  let userRoutes=sessionStorage.getItem("allowedRoutes")?JSON.parse(sessionStorage.getItem("allowedRoutes")):[];
  console.log(userRoutes);
  let allowedRoutesSet = new Set();

  for(let i=0;i<userRoutes.length;i++){
    let route=`/${userRoutes[i]}`;
    if(!allowedRoutesSet.has(route)){
      allowedRoutesSet.add(route);
    }
  }
  console.log(allowedRoutesSet);

    let rows;
    const options = [
      { value: 'Processed', label: 'Processed' },
      { value: 'Rejected', label: 'Rejected' }
    ];
    if (props.orderListData) {
        rows = props.orderListData.map((row, index) => {
          let ordrNumber=row.order_number;
          let orderstatus=row.status;
            return (
                <tr key={index}>
                    <td>{row.order_number}</td>
                    <td>{row.retailer_id}</td>
                    <td>{row.net_order_amt}</td>
                    <td>{row.shop_name}</td>
                    <td>{row.user_name}</td>
                    <td>{formatDateTime(row.order_date_conv)}</td>
                    <td>{row.status}</td>
                    <td>{(ordrNumber.includes("-R/") && (orderstatus=="Ordered" || orderstatus=="Returned"))?(<Select options={options} onChange={(val)=>{ updateOrderStatus(val.value,row.order_number)}} />):null}</td>
                    <td><Button onClick={()=>{openReturnImage(row.order_number)}}>View</Button></td>
                    <td>{(allowedRoutesSet.has(`/orderdetails`) || validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email))) && <Link onClick={() => {sessionStorage.setItem("orderIdToPrint", row.order_number);window.open(`/orderdetails`, "_blank");}}><IconButton><FontAwesomeIcon size="1x" icon={faPrint} /></IconButton></Link>}</td>
                </tr>
            );
        });
    } else {
        rows = [];
    }
    return (<><tbody>{rows}</tbody></>);
}

const ReturnOrderListTable = (props) => {

    const { orderListData } = props;

    return (
        <table className="table table-striped">
            <ReturnOrdrListTableHeader />
            <ReturnOrdrListTableBody orderListData={orderListData}  />
        </table>
    );
}

export default ReturnOrderListTable
