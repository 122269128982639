import React, { useState, useEffect, useRef } from 'react'
import { Table, Form, Alert, Button,Modal } from 'react-bootstrap'

function AddNewItemModal(props) {

    const [itemDetailsObject, setItemDetailsObject] = useState({})

    return (
        <div>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add New Item Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Table>
                        <tr>
                            <td>Item description</td>
                            <td><Form.Control onChange={(e)=>setItemDetailsObject({...itemDetailsObject,"item_disc":(e.target.value)})}/></td>
                        </tr>
                        <tr>
                            <td>HSN No</td>
                            <td><Form.Control onChange={(e)=>setItemDetailsObject({...itemDetailsObject,"item_id":(e.target.value)})}/></td>
                        </tr>
                        <tr>
                            <td>Qty(Kg/Pc)</td>
                            <td><Form.Control onChange={(e)=>setItemDetailsObject({...itemDetailsObject,"order_qty":(e.target.value)})}/></td>
                        </tr>
                        <tr>
                            <td>Total Weight</td>
                            <td><Form.Control onChange={(e)=>setItemDetailsObject({...itemDetailsObject,"weight":(e.target.value)})}/></td>
                        </tr>
                        <tr>
                            <td>Rate</td>
                            <td><Form.Control onChange={(e)=>setItemDetailsObject({...itemDetailsObject,"dealer_price":(e.target.value)})}/></td>
                        </tr>
                        <tr>
                            <td>Amount</td>
                            <td><Form.Control onChange={(e)=>setItemDetailsObject({...itemDetailsObject,"net_amt":(e.target.value)})}/></td>
                        </tr>
                        <tr>
                            <td>GST %</td>
                            <td><Form.Control onChange={(e)=>setItemDetailsObject({...itemDetailsObject,"gst_per":(e.target.value)})}/></td>
                        </tr>
                        <tr>
                            <td>GST Amount</td>
                            <td><Form.Control onChange={(e)=>setItemDetailsObject({...itemDetailsObject,"gst_charge":(e.target.value)})}/></td>
                        </tr>
                        <tr>
                            <td>Total Amount</td>
                            <td><Form.Control onChange={(e)=>setItemDetailsObject({...itemDetailsObject,"total_amt":(e.target.value)})}/></td>
                        </tr>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"success"}>Submit</Button>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AddNewItemModal
