let dev_support_url = "http://dev-support.superzop.com";
let prod_support_url = "http://support.superzop.com:3000";
let localhost_support_url = "http://localhost:3000";

export const support_portal_url = process.env.REACT_APP_SUPPORT_URL || dev_support_url;

let development_url = "http://dev-services2.superzop.com";
let production_url = "http://services2.superzop.com:3000";
let localhost_url = "http://localhost:5000";

export const service_url =   process.env.REACT_APP_SERVICE_URL || development_url;

let dev_ordering_url = "http://dev-api.superzop.com";
let prod_ordering_url = "http://api.superzop.com:3000";
let localhost_ordering_url = "http://localhost:3000";

export const orderingApiUrl = process.env.REACT_APP_ORDERING_API_URL || dev_ordering_url ;
export const BASE_URL_EPAY = process.env.REACT_APP_BASE_URL_EPAY ||'https://api-blackbox.epaylater.in';
export const EPAY_TOKEN = process.env.REACT_APP_EPAY_TOKEN || 'Bearer secret_c5aa3458-cd74-4e9b-8c9c-651dfa2e6b1b';

export const token = process.env.REACT_APP_TOKEN || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MTU0OWIwMTIwMWUyZjMzZWE3NmFkZjYiLCJlbWFpbCI6InNtdHdpbmtsZTQ1MkBnbWFpbC5jb20iLCJpYXQiOjE2MzI5MzQ2NTgsImV4cCI6MTYzMjkzODI1OH0._oHr3REme2pjDDdRliArAeVG_HuimbdM5suTw8HI7ut';

export const apiKey = process.env.REACT_APP_FIREBASE_API_KEY || "AIzaSyA_Sfz85Z6StnlSA_65XJ3yJCgSqUZgCos";
export const authDomain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "superzop-ordering-development.firebaseapp.com";
export const databaseURL = process.env.REACT_APP_FIREBASE_DATABASE_URL || "https://superzop-ordering-development.firebaseio.com/";
export const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID || "superzop-ordering-development";
export const storageBucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "superzop-ordering-development.appspot.com";


export const warehouseNames = process.env.REACT_APP_WAREHOUSE_MAPPING || ['All', 'Bhiwandi','Dhansar','Mira Road', 'Wadala'];
export const warehouseNamesTransit = process.env.REACT_APP_WAREHOUSE_TRANSIT_MAPPING || ['All','Dhansar','Mira Road', 'Wadala'];