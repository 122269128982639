import React, { useState, useMemo, useEffect } from "react";
import TransporterSummaryT1 from "./Depo/TransporterSummaryT1";
import DriverwiseSummaryT2 from "./Depo/DriverwiseSummaryT2";
import DetailedReportT3 from "./Depo/DetailedReportT3";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Form,
  InputGroup,
  Dropdown,
} from "react-bootstrap";
import { service_url } from "../../Configs/mysqlconfig";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

// let service_url = ''
let today = new Date();
let dd = String(today.getDate());
let mm = String(today.getMonth() + 1);
let yyyy = today.getFullYear();
today = yyyy + "-" + mm + "-" + dd;

function TransporterPaymentsDepo() {
  const [Reload, setReload] = useState(false)
  const [TableData, setTableData] = useState([]);
  const [AmtPerService, setAmtPerService] = useState(2500);
  const [TableNo, setTableNo] = useState(1);
  const [TableName, setTableName] = useState("Transporter Summary");
  const [TableDate, setTableDate] = useState({
    yyyy: yyyy,
    mm: mm,
    dd: dd,
  });
  const [HidePrams, setHidePrams] = useState(false);

  useEffect(() => {
    get_transporter_payments_config();

    const url = `${service_url}/api/superzop/transporter_payments/get-Vehicle-Stock-Transfer?date=${TableDate.yyyy}-${TableDate.mm}`;
    console.log(url);
    axios
      .get(url)
      .then(function (response) {
        setTableData(response.data);
      })
      .catch(function (error) {
        console.log("ERROR while fetching the table data : ", error);
      });

    return () => {};
  }, [TableDate, Reload]);

  async function get_transporter_payments_config() {
    const apiUrl = `${service_url}/api/superzop/transporter_payments/get-transporter-payments-config?date_1st_day=${
      TableDate.yyyy
    }-${TableDate.mm.padStart(2, "0")}-01 `;

    await axios
      .get(apiUrl)
      .then((response) => {
        console.log("setAmtPerService ", response.data);
        if (response.data.length > 0) {
          let data = response.data[0];
          setAmtPerService(data.amount_per_service_depo);
        }
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      });
  }

  const handleSelectTable = (eventKey) => {
    setTableNo(Number(eventKey));
    if (Number(eventKey) == 1) {
      setTableName("Transporter Summary");
    }
    if (Number(eventKey) == 2) {
      setTableName("Driverwise Summary");
    }
    if (Number(eventKey) == 3) {
      setTableName("Detailed Report");
    }
  };

  function handelDateChange(date) {
    let today = new Date(date);
    let dd = String(today.getDate());
    let mm = String(today.getMonth() + 1);
    let yyyy = today.getFullYear();

    setTableDate({
      yyyy: yyyy,
      mm: mm,
      dd: dd,
    });
  }

  function update_transporter_payments_config() {
    let formData = {
      date_1st_day: `${TableDate.yyyy}-${TableDate.mm.padStart(2, "0")}-01`,
      amount_per_service_Depo: AmtPerService,
    };

    axios
      .put(
        `${service_url}/api/superzop/transporter_payments/update-transporter-payments-config`,
        formData
      )
      .then((response) => {
        // setMessage(response.data.message);
        setReload(!Reload)
        toast.success("Transporter payments config updated", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((error) => {
        console.error("Error updating data ", error);
        toast.error("Error updating data ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  }

  return (
    <div style={{ margin: 20 }}>
      <Row>
        <Col>
          <Dropdown onSelect={handleSelectTable}>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              {TableName}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item eventKey="1">Transporter Summary</Dropdown.Item>
              <Dropdown.Item eventKey="2">Driverwise Summary</Dropdown.Item>
              <Dropdown.Item eventKey="3">Detailed Report</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>

        <Col>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon3"> Month </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="month"
              value={`${TableDate.yyyy}-${TableDate.mm.padStart(2, "0")}`}
              onChange={(e) => handelDateChange(e.target.value)}
            />
          </InputGroup>
        </Col>
        <Col>
          {HidePrams ? (
            <Button onClick={() => setHidePrams(false)}>
              Hide Parameters{" "}
            </Button>
          ) : (
            <Button onClick={() => setHidePrams(true)}>Show Parameters</Button>
          )}
        </Col>
      </Row>

      {HidePrams && (
        <Row>
          <Col>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon3">
                  Amt Per Service
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="number"
                value={AmtPerService}
                onChange={(e) => setAmtPerService(e.target.value)}
              />
            </InputGroup>
          </Col>
          <Col>
            <Col>
              {" "}
              <Button onClick={() => update_transporter_payments_config()}>
                {" "}
                Update{" "}
              </Button>
            </Col>
          </Col>
        </Row>
      )}

      {TableData && TableNo == 1 && (
        <TransporterSummaryT1
          TableData={TableData}
          amount_per_service={AmtPerService}
        />
      )}
      {TableData && TableNo == 2 && (
        <DriverwiseSummaryT2
          TableData={TableData}
          amount_per_service={AmtPerService}
        />
      )}
      {TableData && TableNo == 3 && (
        <DetailedReportT3
          TableData={TableData}
          amount_per_service={AmtPerService}
        />
      )}
    </div>
  );
}

export default TransporterPaymentsDepo;
