import React, { useState, useEffect } from 'react'
import { Table, Form, Alert, Button,Modal } from 'react-bootstrap'
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import Backdrop from '../backdrop/backdrop';
import fire from '../../Configs/firebase';

function DeliveryErrorsModalPopup({getDB,...props}) {
  const [loading,setLoading] = useState(false);
  const [deliveryErrorsData,setDeliveryErrorsData]=useState({});

  useEffect(() => {
    let deliveryErrorParentKey=getDB;
    deliveryErrorParentKey = deliveryErrorParentKey.replace("Driver_Orders_History", "Delivery_Errors");

    setLoading(true);
    fire.database().ref(`${deliveryErrorParentKey}`).once('value').then((snapshot) => {
      if(snapshot.hasChildren()){
        let errorObj = snapshot.val();

        let finalErrorObj={};

        Object.keys(errorObj).map((key,index)=>{
          let routeErrorObj=errorObj[key];

          Object.keys(routeErrorObj).map((rkey,rindex)=>{
            let ele={};
            ele['route']=key;
            let orderkey=`${rkey.split("-")[0]}/${rkey.split("-")[1]}`;
            ele['orderkey']=orderkey;
            ele['old_revised_order_amt']=routeErrorObj[rkey].old_revised_order_amt;
            ele['new_revised_order_amt']=routeErrorObj[rkey].new_revised_order_amt;
            ele['old_revised_order_disc']=routeErrorObj[rkey].old_revised_order_disc;
            ele['new_revised_order_disc']=routeErrorObj[rkey].new_revised_order_disc;
            ele['old_total_amt']=routeErrorObj[rkey].old_total_amt;
            ele['new_total_amt']=routeErrorObj[rkey].new_total_amt;
            ele['updated_by']=routeErrorObj[rkey].updated_by;
            ele['updated_at']=routeErrorObj[rkey].updated_at;

            finalErrorObj[orderkey]=ele;
          });
        });

        console.log(finalErrorObj);
        setDeliveryErrorsData(finalErrorObj);
        setLoading(false);
      }else{
        setDeliveryErrorsData({});
        setLoading(false);
      }
    });
  },[])

  const DeliveryErrorsTableBody = () => {
    let rows = Object.keys(deliveryErrorsData).map((orderID, index) => {
          return (
              <tr key={index}>
                  <td>{deliveryErrorsData[orderID].route}</td>
                  <td>{deliveryErrorsData[orderID].orderkey}</td>
                  <td>{deliveryErrorsData[orderID].old_total_amt}</td>
                  <td>{deliveryErrorsData[orderID].new_total_amt}</td>
                  <td>{deliveryErrorsData[orderID].old_revised_order_amt}</td>
                  <td>{deliveryErrorsData[orderID].new_revised_order_amt}</td>
                  <td>{deliveryErrorsData[orderID].old_revised_order_disc}</td>
                  <td>{deliveryErrorsData[orderID].new_revised_order_disc}</td>
                  <td>{deliveryErrorsData[orderID].updated_by}</td>
                  <td>{deliveryErrorsData[orderID].updated_at}</td>
              </tr>
          );
      });

      return (<><tbody>{rows}</tbody></>);
  }

    return (<>
            <Modal
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Delivery Errors
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Table>
                    <thead>
                      <tr>
                        <th>Route</th>
                        <th>Order Number</th>
                        <th>Old Total Amt</th>
                        <th>New Total Amt</th>
                        <th>Old Revised Order Amt</th>
                        <th>New Revised Order Amt</th>
                        <th>Old Revised Order Disc</th>
                        <th>New Revised Order Disc</th>
                        <th>Updated By</th>
                        <th>Updated At</th>
                      </tr>
                    </thead>
                    <DeliveryErrorsTableBody />
                </Table>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
            {loading ? (<Backdrop parentLoadStatus={loading} />) : (null)}
        </>
    )
}

export default DeliveryErrorsModalPopup
