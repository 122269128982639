import React, { useState, useEffect, useRef } from 'react'
import { Table,Badge } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import szlogo from '../../assests/images/sz_logo.png';
import khetika from '../../assests/images/khetika.png';
import khetika_qr from '../../assests/images/khetika_qr.png';
import footer from '../../assests/images/footer.png';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import ReactToPrint from 'react-to-print';
import Backdrop from '../backdrop/backdrop';
import { toast } from 'react-toastify';
import superprimelogo from '../../assests/images/superprimelogo.png';
import { support_portal_url, orderingApiUrl } from "../../Configs/mysqlconfig";

function formatDate(selecteddate){
  let dt=new Date(selecteddate);
  let monthNames =["Jan","Feb","Mar","Apr",
                      "May","Jun","Jul","Aug",
                      "Sep", "Oct","Nov","Dec"];

    let day = dt.getDate();

    let monthIndex = dt.getMonth();
    let monthName = monthNames[monthIndex];

    let year = dt.getFullYear().toString().substr(2,2);
    let formattedDate = `${day}-${monthName}-${year}`;
    return formattedDate;
}

function getRetailerIdFromOrderNumber(orderNumber) {
  if(!orderNumber) {
    return "";
  }

  if(orderNumber.includes("SC")) {
    let retailerStr = orderNumber.split("/")[0];
    return retailerStr.substr(retailerStr.length - 5);;
  }else if(orderNumber.includes("-")) {
    return orderNumber.split("-")[0];
  }else{
    return orderNumber.split("/")[0];
  }
}


function convertCity(cityVal) {
  cityVal = cityVal.toLowerCase();
  if(cityVal=="pune"){
    return "Pune";
  } else if(cityVal=="surat"){
    return "Surat";
  } else{
    return 'Mumbai';
  }
}

function formatPhone(phone,secondary_number){
  let phonestr=""+phone;
  if(secondary_number && secondary_number!="null"){
    phonestr+=","+secondary_number;
  }
  return phonestr;
}

function formatGstNumber(gst_number){
  return `GSTIN : ${gst_number}`;
}

function formatFssai(fssai){
  return `FSSAI : ${fssai}`;
}

function formatChequeDate(deliveredDate){
  let tomorrow = new Date(deliveredDate)
  tomorrow.setDate(tomorrow.getDate() + 12)

  let date=tomorrow.getDate();
  let month=tomorrow.getMonth()+1;
  let year=tomorrow.getFullYear();

  return `${date}/${month}/${year}`;
}

function formatDeliveryDate(dt){
  let dateList=dt.split("-");
  let year=dateList[2];
  let month=''+dateList[1];
  let date=''+dateList[0];

  if(date.length<2){
    date='0'+date;
  }

  if(month.length<2){
    month='0'+month;
  }

  return `${year}-${month}-${date}`;
}

const KhetikaBill = () => {
  let deliveryDate=sessionStorage.getItem("getDB")?sessionStorage.getItem("getDB"):"";
  let deliveryDateList = deliveryDate.split("/");
  let finalDeliveryDate = deliveryDateList[deliveryDateList.length - 1];
  finalDeliveryDate=formatDeliveryDate(finalDeliveryDate);

    const [itemDetails, setItemDetails] = useState()
    const [updateditemDetails, setUpdatedItemDetails] = useState()
    const [orderdetails, setOrderdetails] = useState("")
    const [getBackDrop, setBackDrop] = useState(false)
    const [totalOrderQty, setTotalOrderQty] = useState("")
    const [amtExclGst, setAmtExclGst] = useState("")
    const [CGSTAmt, setCGSTAmt] = useState("")
    const [SGSTAmt, setSGSTAmt] = useState("")
    const [IGSTAmt, setIGSTAmt] = useState("")
    const [amtInclGst, setAmtInclGst] = useState("")
    const [replacedItem,setReplacedItem] = useState(JSON.parse(sessionStorage.getItem("replaced_item_details")));
    const [itemAmountErrorMessage,setItemAmountErrorMessage] = useState([]);
    const [orderAmountErrorMessage,setOrderAmountErrorMessage] = useState([]);
    const [getPrimeUser, setPrimeUser] = useState(false);

    const dataToPrint = useRef()
    const itemWeightRef = useRef([])


    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_CENTER,
    });
    const itemQtyDetails = JSON.parse(sessionStorage.getItem("item_qty_details"));
    let invoiceDate = new Date()
    let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    invoiceDate = `${invoiceDate.getDate()}-${monthNames[invoiceDate.getMonth()]}-${invoiceDate.getFullYear().toString().substr(2,2)}`

    useEffect(() => {
        if (!sessionStorage.getItem("orderNumberToDisplay"))
            return;
        const orderNumberSelected = sessionStorage.getItem("orderNumberToDisplay").replace("_","-");
        setBackDrop(true)

        let returnOrderNum=sessionStorage.getItem("orderNumberToDisplay");
        let orderKey = returnOrderNum.split("/").join("-");

        console.log(orderKey);
        let finalOrderData = [];
        let driverOrdersData = localStorage.getItem("orderData")?JSON.parse(localStorage.getItem("orderData")):{};
        console.log(driverOrdersData);

        let orderNumber = sessionStorage.getItem("orderNumberToDisplay");

        setBackDrop(false);

        let sumOfItems = 0; let sumAmtInclGst = 0; let sumIGSTAmt = 0; let sumSGSTAmt = 0; let sumCGSTAmt = 0;
        let bagStr = "";
        let bagSet = new Set();

        Object.keys(driverOrdersData).map((key,index)=>{
          if(key.includes(orderKey)){
            let ele = driverOrdersData[key];
            let mrp = ele['mrp']?ele['mrp']:"";
            let netOrderAmt=ele['total_amt'];
            ele['net_order_amt']=netOrderAmt?Number(netOrderAmt):0;
            let bagId = ele['bag_id'] || "";
            if(bagId && !bagSet.has(bagId)){
              bagStr += `${bagId},`;
              bagSet.add(bagId);
            }

            ele['total_amt'] = mrp;
            ele['original_total_amt']=mrp;
            let gstCharge=ele.gst_charge;
            ele['original_gst_charge']=gstCharge;
            let order_qty=ele['order_qty']?Number(ele['order_qty']):0;
            ele['original_order_qty']=order_qty;
            let item_quantity=0;
            if(ele.hasOwnProperty("item_delivered_qty")) {
              item_quantity=Number(ele['item_delivered_qty']);
            } else if(ele.hasOwnProperty("item_modified_qty")) {
              item_quantity=Number(ele['item_modified_qty']);
            } else {
              item_quantity=order_qty;
            }

            ele['item_quantity']=item_quantity;

            // quantity modified case
            if(order_qty!=item_quantity){
              ele.total_amt = Number(ele.total_amt) * item_quantity / order_qty;
              ele.gst_charge = Number(ele.gst_charge) * item_quantity / order_qty;
            }

            if(isNumber(ele.gst_charge) == 0 && getItemsWeight(ele.weight,ele.item_disc,1) <=25 && (ele.category == "FLOURS/SOOJI" || ele.category =="GRAINS/POHA" || ele.category == "RICE")) {
              ele['gst_charge'] = ((Number(ele.dealer_price)* item_quantity)+Number(ele.apmc_charge)+Number(ele.transport_charge)) * 5/105
            }

            if(item_quantity != 0){
              sumOfItems += 1
              sumAmtInclGst += Number(ele.total_amt)
              sumIGSTAmt += ele.gst_charge?Number(ele.gst_charge):0;
              sumCGSTAmt += isNumber(ele.gst_charge)/ 2
              sumSGSTAmt += isNumber(ele.gst_charge)/ 2
            }

            // if(item_quantity>0){
              finalOrderData.push(ele);
            // }
          }
        });
        console.log(finalOrderData);
        if(bagStr) {
          bagStr = bagStr.slice(0,-1);
        }

        let net_order_amt=0;
        let order_disc=0;
        let cashback_redeemed=0;
        let sumTotalAmt=0;

        let orderAmountErrors=[];
        let itemAmountErrors=[];
        let newFinalOrderData=[];

        for(let i=0;i<finalOrderData.length;i++){
          let orderele=finalOrderData[i];
          orderele['item_mrp_sum']=sumAmtInclGst;
          net_order_amt=finalOrderData[i]['net_order_amt']?Number(finalOrderData[i]['net_order_amt']):0;
          order_disc=finalOrderData[i]['order_disc']?Number(finalOrderData[i]['order_disc']):0;
          cashback_redeemed=finalOrderData[i]['cashback_redeemed']?Number(finalOrderData[i]['cashback_redeemed']):0;

          //item level
          let weight=finalOrderData[i]['weight'];
          let category=finalOrderData[i]['category'];
          let item_disc=finalOrderData[i]['item_disc'];
          let total_amt=finalOrderData[i]['original_total_amt']?Number(finalOrderData[i]['original_total_amt']):0;
          let dealer_price=finalOrderData[i]['dealer_price']?Number(finalOrderData[i]['dealer_price']):0;
          let order_qty=finalOrderData[i]['original_order_qty']?Number(finalOrderData[i]['original_order_qty']):0;
          let transport_charge=finalOrderData[i]['transport_charge']?Number(finalOrderData[i]['transport_charge']):0;
          let apmc_charge=finalOrderData[i]['apmc_charge']?Number(finalOrderData[i]['apmc_charge']):0;
          let gst_charge=finalOrderData[i]['original_gst_charge']?Number(finalOrderData[i]['original_gst_charge']):0;

          let calcItemValue=(dealer_price * order_qty) + transport_charge + apmc_charge + gst_charge;
          calcItemValue=calcItemValue.toFixed(2);
          calcItemValue=Number(calcItemValue);

          let itemValueDiff=calcItemValue-total_amt;
          if(calcItemValue!=total_amt && (itemValueDiff>10 || itemValueDiff<-10)){
            itemAmountErrors.push(`${item_disc} has Item Value discrepancy with difference of ${itemValueDiff.toFixed(2)}`)
          }

          sumTotalAmt=sumTotalAmt+total_amt;

          if(bagStr) {
            orderele['bagdetails'] = bagStr;
            orderele['numbags'] = bagStr.split(",").length;
          }

          let item_quantity=finalOrderData[i]['item_quantity'];
          if(item_quantity>0){
            newFinalOrderData.push(orderele);
          }
        }

        let calcOrderValue=sumTotalAmt-order_disc-cashback_redeemed;
        calcOrderValue=calcOrderValue.toFixed(2);
        calcOrderValue=Number(calcOrderValue);

        let orderValueDiff=calcOrderValue-net_order_amt;
        if(net_order_amt!=calcOrderValue && !orderNumber.includes("R") && (orderValueDiff>10 || orderValueDiff<-10)){
          orderAmountErrors.push(`Order value discrepancy with difference of ${orderValueDiff.toFixed(2)}`);
        }

        setItemAmountErrorMessage(itemAmountErrors);
        setOrderAmountErrorMessage(orderAmountErrors);

        setAmtExclGst((sumAmtInclGst - sumIGSTAmt).toFixed(2))
        setIGSTAmt(sumIGSTAmt)
        setCGSTAmt(sumCGSTAmt)
        setSGSTAmt(sumSGSTAmt)
        setAmtInclGst(sumAmtInclGst.toFixed(2))
        // alert("1")
        setItemDetails(newFinalOrderData);

        if(newFinalOrderData.length>0){
          setOrderdetails(newFinalOrderData[0])
        }
    }, [])


    const getItemsWeight = (indItemWeight, itemDesc, quantityOrdered) => {

        if (!indItemWeight)
            return 1
        let itemWeight = Number(indItemWeight.replace(/\D/g, ''));
        if (indItemWeight.includes("gm") || indItemWeight.includes("ml")) {
            itemWeight = itemWeight / 1000;
        }

        if ((indItemWeight.includes("1 pc") || indItemWeight.includes("1 Pc")) && itemDesc.includes("gm")) {
            let itemWtExtract = itemDesc.split("gm")
            let itemWtExtract2 = itemWtExtract[0].split("-")
            itemWeight = parseFloat(itemWtExtract2[itemWtExtract2.length-1].replace(/[^0-9]/g,''))/1000;
        }
        if(itemWeight <=0){
            itemWeight = 1;
        }
        indItemWeight = itemWeight * Number(quantityOrdered)
        console.log(itemDesc)
        console.log(indItemWeight)
        return `${indItemWeight.toFixed(2)}`
    }

    const isAlreadyPaid = (payment_mode) => {
      return payment_mode && (payment_mode.includes("Credit")|| payment_mode.includes("RazorPay"))
    }

    const isNumber = (item) => {
        return isNaN(Number(item))?"0":Number(item).toFixed(2)
    }

    const getOrderDiscount = () => {
      return orderdetails["revised_order_disc"]?orderdetails["revised_order_disc"]:orderdetails["order_disc"]?orderdetails["order_disc"]:0;
    }

    const calcSuperKreditAmt = () => {
      let revised_order_amt = orderdetails["item_mrp_sum"]?Number(orderdetails["item_mrp_sum"]):0;
      let revised_order_disc = orderdetails["revised_order_disc"]?Number(orderdetails["revised_order_disc"]):orderdetails["order_disc"]?Number(orderdetails["order_disc"]):0;
      let cashback_redeemed = orderdetails["cashback_redeemed"]?Number(orderdetails["cashback_redeemed"]):0;

      return (revised_order_amt-revised_order_disc-cashback_redeemed).toFixed(2);
    }

    const getSavedAmount = () => {
      let savedAmt = 0;

      if(itemDetails) {
        Object.keys(itemDetails).map((key,index) => {
          let updateditem = itemDetails[key];
          let mrp = Number(updateditem.total_amt) || 0;
          let market_price = Number(updateditem.market_price) || 0;
          let qty = updateditem.hasOwnProperty("item_delivered_qty")?Number(updateditem.item_delivered_qty):updateditem.hasOwnProperty("item_modified_qty")?Number(updateditem.item_modified_qty):Number(updateditem.order_qty);

          savedAmt += ((market_price * qty) - mrp);
        })
      }

      return savedAmt.toFixed(2);
    }

    const getApartmentBlock = (apartment,block) => {
      let result = "";
      apartment = apartment || "";
      result += apartment;

      if(block) {
        result = result + ", "
        result += block;
      }

      return result;
    }


    return (
        <>
            <div style={{ textAlign: "center", width: "1000px", margin: "auto", border: "2px solid black" }}>
                <ReactToPrint
                    trigger={() => <a href="#" style={{ 'float': 'right' }} ><FontAwesomeIcon size="3x" icon={faPrint} /></a>}
                    content={() => { return dataToPrint.current }}
                    pageStyle={{ margin: "50px", border: "2px" }}
                />

                {itemAmountErrorMessage.map((itemError, key) => {
                    return (
                        <div style={{ display: "flex", backgroundColor: "#FF6347", padding: "10px", margin: "15px", borderRadius: "5px" }}>{itemError}</div>
                    )
                })}

                {orderAmountErrorMessage.map((orderError, key) => {
                    return (
                        <div style={{ display: "flex", backgroundColor: "#FF6347", padding: "10px", margin: "15px", borderRadius: "5px" }}>{orderError}</div>
                    )
                })}

                <div ref={dataToPrint}>
                <Table><tbody><tr><td><img src={khetika} alt={"KhetikaLogo"} style={{  width: "300px", height: "100px" }} /></td>
                    <td style={{ width: "400px", textAlign: "left" }}><b>Retranz Infolabs Private Limited</b><br />
                      Godown D1, Indian Corporation Compound,<br />Diva - Anjur Village, Bhiwandi – 421302<br />
                Phone:  8010173173<br />
                Mail : support@superzop.com<br />
                GSTIN: 27AAHCR2573F1ZA </td>
                    </tr>
                    </tbody>
                    </Table>
                    <div><div style={{ borderTop: "2px solid black", borderBottom: "2px solid black", textAlign: "center" }}>
                        <b style={{ fontSize: "20px" }}>CUSTOMER INVOICE</b>
                    </div>
                        <Table>
                          <tbody>
                            <tr>
                                <td style={{ textAlign: "left" }}><div>
                                  {orderdetails.customer_id && <b>{orderdetails.retailer_name}</b>}<br />
                                    {orderdetails.shop_name}<br />
                                    {getApartmentBlock(orderdetails.apartment,orderdetails.block)}<br />
                                    {orderdetails.address1}<br />
                                    {orderdetails.address2}<br />
                                    {convertCity(orderdetails.city || "")}-{orderdetails.pincode}<br />
                                    ✆{formatPhone(orderdetails.phone,orderdetails.secondary_number)}<br />
                                    {orderdetails.gst_number?formatGstNumber(orderdetails.gst_number):null}<br />
                                    {orderdetails.fssai_document_number?formatFssai(orderdetails.fssai_document_number):null}<br />
                                </div></td>
                                <td style={{ width: "400px", textAlign: "left" }}><div><Table borderless style={{ cellSpacing: "0px" }}><tbody>
                                    <tr style={{ cellSpacing: "0px", cellPadding: "0px" }}>
                                        <td style={{ padding: "0px" }}>Retailer ID</td>
                                        <td style={{ textAlign: "right", padding: "0px", }}>{orderdetails.customer_id}</td>
                                    </tr>
                                    <tr style={{ cellSpacing: "0px", cellPadding: "0px" }}>
                                        <td style={{ padding: "0px" }}>Invoice Number</td>
                                        <td style={{ textAlign: "right", padding: "0px", }}>{orderdetails.order_number}</td>
                                    </tr>
                                    <tr style={{ cellSpacing: "0px" }}>
                                        <td style={{ padding: "0px" }}>Invoice Date</td>
                                        <td style={{ textAlign: "right", padding: "0px", }}>{finalDeliveryDate}</td>
                                    </tr>
                                    <tr style={{ cellSpacing: "0px" }}>
                                        <td style={{ padding: "0px" }}>Order Date</td>
                                        <td style={{ textAlign: "right", padding: "0px", }}>{orderdetails.order_date?formatDate(orderdetails.order_date):""}</td>
                                    </tr>
                                    <tr style={{ cellSpacing: "0px" }}>
                                        <td style={{ padding: "0px" }}>No of Items</td>
                                        <td style={{ textAlign: "right", padding: "0px", }}>{itemDetails?itemDetails.length:""}</td>
                                    </tr>
                                    {orderdetails.numbags && orderdetails.numbags!=0 &&
                                    <tr style={{ cellSpacing: "0px" }}>
                                        <td style={{ padding: "0px" }}>No of Loose Bags</td>
                                        <td style={{ textAlign: "right", padding: "0px", }}>{orderdetails.numbags}</td>
                                    </tr>}
                                    {orderdetails.bagdetails && orderdetails.bagdetails!='null' &&
                                    <tr style={{ cellSpacing: "0px" }}>
                                        <td style={{ padding: "0px" }}>Loose Bag Id</td>
                                        <td style={{ textAlign: "right", padding: "0px", }}>{orderdetails.bagdetails && orderdetails.bagdetails!='null'?orderdetails.bagdetails:""}</td>
                                    </tr>}
                                    <tr style={{ cellSpacing: "0px" }}>
                                        <td style={{ padding: "0px" }}>Sales Agent</td>
                                        <td style={{ textAlign: "right", padding: "0px", }}>{orderdetails.agent_id}</td>
                                    </tr>
                                  </tbody>
                                </Table>
                                </div>
                                </td>
                            </tr>
                          </tbody>
                        </Table>
                    </div>
                    <Table>
                        <thead style={{ borderTop: "2px solid black", borderBottom: "2px solid black" }}>
                            <tr>
                                <td style={{ paddingRight: "10px" }}><b>Sl No</b></td>
                                <td style={{ paddingRight: "10px" }}><b>Item description</b></td>
                                <td style={{ paddingRight: "10px" }}><b>HSN Code</b></td>
                                <td style={{ paddingRight: "10px" }}><b>Qty/No</b></td>
                                <td style={{ paddingRight: "10px" }}><b>Mrp</b></td>
                                <td style={{ paddingRight: "10px" }}><b>Total Weight</b></td>
                                <td style={{ paddingRight: "10px" }}><b>Dealer Price (per pack)</b></td>
                                <td style={{ paddingRight: "10px" }}><b>Amount(Rs.)</b></td>
                                {orderdetails.city=="Surat" && <td style={{ paddingRight: "10px" }}><b>IGST Amount</b></td>}
                                {orderdetails.city!="Surat" && <td style={{ paddingRight: "10px" }}><b>CGST Amount</b></td>}
                                {orderdetails.city!="Surat" && <td style={{ paddingRight: "10px" }}><b>SGST Amount</b></td>}
                                <td style={{ paddingRight: "10px" }}><b>Total Amount</b></td>
                            </tr>
                        </thead>
                        <tbody style={{ padding: "0" }}>
                            {(itemDetails) ? Object.keys(itemDetails).map((items, index) => (
                                <tr>
                                    <td style={{ padding: "2px", border: "none" }}>{index + 1}</td>
                                    <td style={{ width: "500px", padding: "2px", textAlign: "left", border: "none", fontSize: "16px" }}>{itemDetails[items].item_disc}</td>
                                    <td style={{ padding: "2px", border: "none" }}>{itemDetails[items].hsn_code || ""}</td>
                                    <td style={{ padding: "2px", border: "none" }}>{Number(itemDetails[items].item_quantity).toFixed(2)}</td>
                                    <td style={{ padding: "2px", border: "none" }}>{Number(itemDetails[items].market_price) || 0}</td>
                                    <td style={{ padding: "2px", border: "none" }} ref={(value => itemWeightRef.current[index] = value)}> {getItemsWeight(itemDetails[items].weight,itemDetails[items].item_disc, itemDetails[items].item_quantity)}</td>
                                    <td style={{ padding: "2px", border: "none" }}>{isNumber((Number(itemDetails[items].total_amt) -  Number(itemDetails[items].gst_charge))/Number(itemDetails[items].item_quantity))}</td>
                                    <td style={{ padding: "2px", border: "none" }}>{isNumber(Number(itemDetails[items].total_amt) - Number(itemDetails[items].gst_charge))}</td>
                                    {orderdetails.city=="Surat" && <td style={{ padding: "2px", border: "none" }}>{isNumber(itemDetails[items].gst_charge)}</td>}
                                    {orderdetails.city!="Surat" && <td style={{ padding: "2px", border: "none" }}>{isNumber(itemDetails[items].gst_charge / 2)}</td>}
                                    {orderdetails.city!="Surat" && <td style={{ padding: "2px", border: "none" }}>{isNumber(itemDetails[items].gst_charge / 2)}</td>}
                                    <td style={{ padding: "2px", border: "none" }}>{isNumber(itemDetails[items].total_amt)}</td>
                                </tr>
                            )) : ""}
                        </tbody>
                    </Table>

                    <div style={{ borderTop: "1px solid black" }}>
                        <Table ><tbody><tr>
                            <td style={{ textAlign: "left" }}><div>
                                <br /><br /><br />
                                <div style={{ textAlign: "center" }}><i>I/We hereby certify that the goods mentioned in the invoice are warranted
                                to be of the same nature<br />
                                Certified that the particulars given above are true and correct</i><br /><br />
                                <b><i>Goods Once Sold Will Not Be Taken Back.</i></b><br />
                                Subject to Mumbai Jurisdiction<br />
                                <i><b>E&OE</b></i></div><br />
                                <div><b>You have saved Rs {getSavedAmount()} by ordering on Khetika</b></div>
                                <i><b>*For any queries please contact support@superzop.com or 8010173173</b></i>
                                <div style={{textAlign:"center"}}>{(orderdetails["order_payment_mode"]=='Credit')?<h1><Badge pill variant="primary">Paid - Rupifi</Badge></h1>:(orderdetails["order_payment_mode"] && orderdetails["order_payment_mode"].includes('RazorPay'))?<h1><Badge pill variant="primary">Paid - RazorPay</Badge></h1>:(orderdetails["order_payment_mode"]=='Internal_Credit')?<h1><Badge pill variant="primary">Paid - SuperKredit</Badge></h1>:<img src={khetika_qr} alt={"KhetikaQR"} style={{  width: "200px", height: "300px" }} />}</div>
                                <div style={{textAlign:"center"}}>{(orderdetails["order_payment_mode"]=='Internal_Credit')?<h1><Badge pill variant="primary">Cheque Date - {formatChequeDate(finalDeliveryDate)}</Badge></h1>:null}</div>
                            </div></td>
                            <td style={{ width: "400px", textAlign: "right", cellSpacing: "0px" }}><div>
                                <Table bordered style={{ border: "2px solid black" }}>
                                  <tbody>
                                    <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black", textAlign: "left" }}>Amount (Exl GST)</td>
                                        <td style={{ border: "2px solid black", textAlign: "right" }}>{amtExclGst && amtExclGst}</td>
                                    </tr>
                                    {orderdetails.city!="Surat" && <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black", textAlign: "left" }}>CGST Amount</td>
                                        <td style={{ border: "2px solid black", textAlign: "right" }}>{CGSTAmt && CGSTAmt.toFixed(2)}</td>
                                    </tr>}
                                    {orderdetails.city!="Surat" && <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black", textAlign: "left" }}>SGST Amount</td>
                                        <td style={{ border: "2px solid black", textAlign: "right" }}>{SGSTAmt && SGSTAmt.toFixed(2)}</td>
                                    </tr>}
                                    {orderdetails.city=="Surat" && <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black", textAlign: "left" }}>IGST Amount</td>
                                        <td style={{ border: "2px solid black", textAlign: "right" }}>{IGSTAmt && IGSTAmt.toFixed(2)}</td>
                                    </tr>}
                                    <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black", textAlign: "left" }}><b>Total Amount</b></td>
                                        <td style={{ border: "2px solid black", textAlign: "right" }}>{amtInclGst}</td>
                                    </tr>
                                    <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black", textAlign: "left" }}>Discount/Coupon</td>
                                        <td style={{ border: "2px solid black", textAlign: "right" }}>{orderdetails["revised_order_disc"]?orderdetails["revised_order_disc"]:orderdetails["order_disc"]}</td>
                                    </tr>
                                    <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black", textAlign: "left" }}>SZ Wallet Paid</td>
                                        <td style={{ border: "2px solid black", textAlign: "right" }}>{orderdetails["cashback_redeemed"]}</td>
                                    </tr>
                                    <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black", textAlign: "left" }}>SuperKredit/RazorPay</td>
                                        <td style={{ border: "2px solid black", textAlign: "right" }}>
    {(orderdetails["order_payment_mode"]=="Credit" || orderdetails["order_payment_mode"]=="Internal_Credit")?
    (orderdetails["status"]=="Delivered"?orderdetails["credit_amt"]:calcSuperKreditAmt()):isAlreadyPaid(orderdetails["order_payment_mode"])?orderdetails['net_order_amt']:0}</td>
                                    </tr>
                                    <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black", textAlign: "left" }}><b>Net Amount Payable</b></td>
                                        <td style={{ border: "2px solid black", textAlign: "right" }}><b>{(orderdetails["order_payment_mode"]=="Internal_Credit" || orderdetails["order_payment_mode"]=="Credit")?(orderdetails["status"]=="Delivered"?orderdetails["credit_amt"]:calcSuperKreditAmt()):isAlreadyPaid(orderdetails["order_payment_mode"])?0:Math.round((Number(amtInclGst) - Number(Number(getOrderDiscount()) + Number(orderdetails["cashback_redeemed"])))).toFixed(2)}</b></td>
                                    </tr>
                                  </tbody>
                                </Table><br /><br />
                                For Retranz Infolabs Private Limited<br /><br />
                                <i>(Authorized Signatory)</i>
                            </div></td>
                        </tr></tbody></Table>
                        <Table>
                          <tr>
                            <td><img src={footer} alt={"Khetika"} style={{  width: "100%", height: "120px" }} /></td>
                          </tr>
                        </Table>
                    </div>
                </div>
            </div>
            {getBackDrop ? (<Backdrop parentLoadStatus={getBackDrop} />) : (null)}
        </>
    )
}

export default KhetikaBill
