import firebase from 'firebase';
import {apiKey,authDomain,databaseURL,projectId,storageBucket} from "./mysqlconfig";

const firebaseConfig = {
    apiKey: apiKey,
    authDomain: authDomain,
    databaseURL: databaseURL,
    projectId: projectId,
    storageBucket: storageBucket
};

const fire = firebase.initializeApp(firebaseConfig);
export default fire
