import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Modal, Button, Table, Figure } from "react-bootstrap";
import "./DeliverySummary.css";
import correctImg from "../../assests/images/correct.png";
import crossImg from "../../assests/images/cross.png";

const UnDeliveredOrdersModal = (props) => {
  const show = props.show;
  const setShow = props.setShow;
  const allPendingCancelledOrders = props.allPendingCancelledOrders;
  console.log("debug", allPendingCancelledOrders);

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
      }}
      id="undelivered_status_modal"
      dialogClassName="undelivered_status_modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>All Pending / Cancelled Orders</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Status</th>
              <th>Route Id</th>
              <th>Driver Name</th>
              <th>Driver No</th>
              <th>Transporter</th>
              <th>Store</th>
              <th>Area</th>
              <th>Order No</th>
              <th>Order Date</th>
              <th>Order Amt</th>
              <th>Driver visited retailer?</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(allPendingCancelledOrders).map((key, index) => {
              return (
                <tr key={index}>
                  <td>{allPendingCancelledOrders[key].status}</td>
                  <td>{allPendingCancelledOrders[key].route_no}</td>
                  <td>{allPendingCancelledOrders[key].driver_name}</td>
                  <td>{allPendingCancelledOrders[key].driver_no}</td>
                  <td>{allPendingCancelledOrders[key].transporter}</td>
                  <td>{allPendingCancelledOrders[key].shop_name}</td>
                  <td>{allPendingCancelledOrders[key].area}</td>
                  <td>{allPendingCancelledOrders[key].order_number}</td>
                  <td>{allPendingCancelledOrders[key].order_date}</td>
                  <td>{allPendingCancelledOrders[key].order_amt}</td>
                  <td style={{ textAlign: "center" }}>
                    {allPendingCancelledOrders[key].visited_proximity ===
                    true ? (
                      <Figure>
                        <Figure.Image
                          width={30}
                          height={30}
                          alt="YES"
                          src={correctImg}
                        />
                      </Figure>
                    ) : (
                      <Figure>
                        <Figure.Image
                          width={30}
                          height={30}
                          alt="NO"
                          src={crossImg}
                        />
                      </Figure>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setShow(false);
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UnDeliveredOrdersModal;
