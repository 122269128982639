import React, { useState, useEffect, useRef } from "react";
import { service_url } from "../../Configs/mysqlconfig";
import fire from "../../Configs/firebase";
import NavBar from "../Navigation bar/Navbar";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useAsyncDebounce,
} from "react-table";
import {
  Row,
  Col,
  ListGroup,
  InputGroup,
  DropdownButton,
  Dropdown,
  Modal,
  Button,
} from "react-bootstrap";
import { InputGroupAddon, InputGroupText, Input } from "reactstrap";
import DatePicker from "react-datepicker";
import axios from "axios";
import moment from "moment";
import { CSVLink } from "react-csv";
import Select from "react-select";
import { object } from "underscore";



function getDistanceFromLatLonInMeters(lat1, lon1, lat2, lon2) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1);
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  // d = d * 1000; //Distance in meters
  return d;
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}


function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows

  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      if (row.values[id] && row.values[id] !== "") options.add(row.values[id]);
    });
    const sortedOptions = Array.from(options).sort();
    return [...sortedOptions.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}


const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <div className="search_container">
      <input
        style={{ width: "50%" }}
        className="search_bar"
        value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}
        placeholder="Search"
      />{" "}
    </div>
  );
};

function Driverperformance() {

  const [reqParams, setReqParams] = useState({
    date_from: new Date(),
    date_to: new Date(),
  });

  const [tableDataRows, setTableDataRows] = useState([]);
  const [dataForCsv, setDataForCsv] = useState([]);
  const [cityListRaw, setCityListRaw] = useState();
  const [vehicleLocationMap, setVehicleLocationMap] = useState();
  const [vehicleLocationMapMysql, setVehicleLocationMapMysql] = useState([]);
  const [dateNameValue, setDateNameValue] = useState() 
  const [StatusType, setStatusType] = useState()
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (val) => {
    setDateNameValue(val);
    setShow(true)
  };

  const handleClose2 = () => setShow2(false);
  const handleShow2 = (val) => {
    
    let [date_name, statusType] = val.split("@");

    setDateNameValue(date_name);
    setStatusType(statusType)
    setShow2(true);
  }

  const fetchCityList = () => {
    return new Promise((resolve, reject) => {
      fire
        .database()
        .ref("Region_List")
        .once("value")
        .then((snapshot) => {
          const data = snapshot.val();
          resolve(data);
        })
        .catch((err) => {
          console.log(
            "An error occured while attempting to fetch the city list",
            err
          );
          reject("ERROR unable to fetch city list");
        });
    });
  };

  const fetchTableData = (from, to) => {
    if (!from || !to) {
      return console.log("ERROR missing arguements");
    }
    return new Promise((resolve, reject) => {
      const url = `${service_url}/api/superzop/delivery/driverperformancesummery?date_from=${from}&date_to=${to}`;

      axios
        .get(url)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          console.log("ERROR while fetching the table data : ", error);
          reject("ERROR while fetching the table data");
        });
    });
  };

  const init = () => {
    Promise.all([
      fetchCityList(),
      fetchTableData(reqParams.date_from, reqParams.date_to),
    ])
      .then((values) => {
        let city_list = values[0];
        let table_data = values[1][0];
        populateTableData(table_data, city_list);
        setCityListRaw(city_list);
      })
      .catch((err) => {
        console.log("ERROR could not run init : ", err);
      });
  };

  const refreshTableData = (from, to, city_list) => {
    Promise.all([fetchTableData(from, to)])
      .then((results) => {
        let table_data = results[0][0];
        let store_location_date = results[0][1]
        console.log(store_location_date);
        let vehicle_store_location = mapVehicleLocationMysql(store_location_date);
        populateTableData(table_data, city_list,  vehicle_store_location );
      })
      .catch((err) => {
        console.log("ERROR could not run init : ", err);
      });
  };

  const populateTableData = (table_data, city_list,vehicle_store_location) => {

    if (!table_data || !city_list) {
      return console.log("Error missing arguements");
    }

    const data_rows = table_data.map((item) => {
      let city = city_list[item.region_id.slice(0, 2)]?.city || "N/A";
      let region = city_list[item.region_id.slice(0, 2)]?.region || "N/A";
      let map_num_key = item.mobile_number + "-" + moment(item.date).format("YYYY-MM-DD");
      let pending_cancelled
      
      if(vehicle_store_location[map_num_key] !== undefined){
        pending_cancelled = vehicle_store_location[map_num_key].length
      }else{
        pending_cancelled = 0
      }
      
      let percentage_val  = (item.order_delivered/item.total_order)*100
      return {
        company_type: item.company_type,
        driver_no: item.mobile_number,
        driver_name: item.driver_name,
        total_order: item.total_order,
        date_unformatted :  moment(item.date).format("YYYY-MM-DD"),
        date: moment(item.date).format("DD-MMM"),
        assigned_route: item.assigned_route,
        cancelled_count: item.order_cancelled,
        delivered_count: item.order_delivered,
        partial_delivered_count: item.order_partially_delivered,
        pending_count: item.order_pending,
        not_visited_count: item.order_not_visited,
        vehicle_number: item.vehicle_number,
        city: city,
        region_id: `${region} (${item.region_id})`,
        pending_cancelled : pending_cancelled,
        percentage:  percentage_val.toFixed(2)

      };
    });

    setTableDataRows(data_rows);
    populateCSVData(data_rows);
  };

  const populateCSVData = (csv_data) => {
    if (!csv_data) {
      return console.log("ERROR missing arguements");
    }
    setDataForCsv(csv_data);
  };

  const fetchVehicleLocation = () =>{

    let this_year = moment(reqParams.date_from).format("YYYY");
    fire
    .database()
    .ref("Vehicle_Location/"+this_year)
    .once("value")
    .then((snapshot) => {
      const data = snapshot.val();
      let obj = {}
      for (let m in data){
        for (let d in data[m]){
            for (let pn in data[m][d] ){
                let  dt = d.split("-")
                let dates = dt[2] + "-";
                dates +=  (Number(dt[1]) < 10 ) ?   "0" + dt[1] + "-" : dt[1]
                dates +=  (Number(dt[0]) < 10 ) ?   "0" + dt[0]       : dt[0]
                obj[pn+"-"+dates] = data[m][d][pn];
            }
        }
      }

      setVehicleLocationMap(obj)
    })
    .catch((err) => {
      console.log("fetchVehicleLocation err :=  ", err );
    });
  }

  const mapVehicleLocationMysql = (data) =>{

    let obj = {}
    for(let item  of  data){
      if (obj[item.driver_phone_number+"-"+item.date] == undefined ){
          obj[item.driver_phone_number+"-"+item.date] = [{ latitude: item.latitude, longitude : item.longitude, order_number : item.order_number, shop_name : item.shop_name, status : item.status, area : item.area }];
      }else{
          obj[item.driver_phone_number+"-"+item.date].push({ latitude: item.latitude , longitude : item.longitude , order_number : item.order_number, shop_name : item.shop_name, status : item.status, area : item.area});
      }
    }

    setVehicleLocationMapMysql(obj);
    return obj
  }

  useEffect(() => {
    init();
    fetchVehicleLocation();
  }, []);

  useEffect(() => {
    let from = moment(reqParams.date_from).format("YYYY-MM-DD");
    let to = moment(reqParams.date_to).format("YYYY-MM-DD");
    refreshTableData(from, to, cityListRaw);
    
  }, [reqParams, cityListRaw]);



  const columns = React.useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
      },
      {
        Header: "Mobile number",
        accessor: "driver_no",
      },
      {
        Header: "Driver name",
        accessor: "driver_name",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "City",
        accessor: "city",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Region Id",
        accessor: "region_id",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Company type",
        accessor: "company_type",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Assigned route",
        accessor: "assigned_route",
      },
      {
        Header: "Total order",
        accessor: "total_order",
        Cell:  ({ row }) => {  return ( <Button  variant="link" onClick={() => handleShow2( row.original.driver_no +"-"+ row.original.date_unformatted +"@"+ "all" )} > {row.original.total_order} </Button> ) }
      },
      {
        Header: "Order cancelled",
        accessor: "cancelled_count",
        Cell:  ({ row }) => {  return ( <Button  variant="link" onClick={() => handleShow2( row.original.driver_no +"-"+ row.original.date_unformatted +"@"+ "Cancelled" )} > {row.original.cancelled_count} </Button> ) }
      },
      {
        Header: "Order delivered",
        accessor: "delivered_count",
        Cell:  ({ row }) => {  return ( <Button  variant="link" onClick={() => handleShow2( row.original.driver_no +"-"+ row.original.date_unformatted +"@"+ "Delivered" )} > {row.original.cancelled_count} </Button> ) }
      },
      {
        Header: "Order partially delivered",
        accessor: "partial_delivered_count",
        Cell:  ({ row }) => {  return ( <Button  variant="link" onClick={() => handleShow2( row.original.driver_no +"-"+ row.original.date_unformatted +"@"+ "Partially-Delivered" )} > {row.original.cancelled_count} </Button> ) }
      },
      {
        Header: "Order pending",
        accessor: "pending_count",
        Cell:  ({ row }) => {  return ( <Button  variant="link" onClick={() => handleShow2( row.original.driver_no +"-"+ row.original.date_unformatted +"@"+ "Pending" )} > {row.original.cancelled_count} </Button> ) }
      },
      {
        Header: "Order not visited",
        accessor: "not_visited_count",
        Cell:  ({ row }) => {  return ( <Button  variant="link" onClick={() => handleShow2( row.original.driver_no +"-"+ row.original.date_unformatted +"@"+ "Not Visited" )} > {row.original.cancelled_count} </Button> ) }
      },
      {
        Header: "Vehicle number",
        accessor: "vehicle_number",
      },
      {
        Header: "Pending Cancelled",
        accessor: "pending_cancelled",
        Cell:  ({ row }) => {  return ( <Button  variant="link" onClick={() => handleShow( row.original.driver_no +"-"+ row.original.date_unformatted )} > {row.original.pending_cancelled} </Button> ) }
      },
      {
        Header: "Percentage",
        accessor: "percentage",
      },
      
    ],
    []
  );



  const Table = ({ columns, data }) => {
    //!Due to an issue with the react-table library, Select filter does not work without this dummmy function.
    //!Also this is being passed to useTable
    const defaultColumn = React.useMemo(
      () => ({
        Filter: () => null,
      }),
      []
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      setGlobalFilter,
      preGlobalFilteredRows,
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
      },
      useFilters,
      useGlobalFilter
    );

    const { globalFilter } = state;


    return (
      <div>
        <Row>
          <Col sm={10}>
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          </Col>
          <Col sm={2}>
            <CSVLink
              className="btn btn-warning export_btn"
              filename={"driver_performance.csv"}
              data={dataForCsv}
            >
              Export Data
            </CSVLink>
          </Col>
        </Row>

        <br></br>
        <br></br>
        <br></br>

        <table
          {...getTableProps()}
          style={{
            border: "solid 1px blue",
            fontSize: ".85rem",
            width: "100%",
          }}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    key={Math.random()}
                    {...column.getHeaderProps()}
                    style={{
                      padding: "10px",
                      color: "black",
                      fontWeight: "bold",
                      border: "solid 1px gray",
                    }}
                  >
                    {column.render("Header")}
                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        key={Math.random()}
                        {...cell.getCellProps()}
                        style={{
                          minWidth: "70px",
                          padding: "10px",
                          border: "solid 1px gray",
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <>
      <NavBar />
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      {/* {JSON.stringify(vehicleLocationMap)} */}
      <InputGroup size="sm" style={{ marginLeft: 20 }}>
        <InputGroupAddon addonType="prepend">Date Range From</InputGroupAddon>
        <DatePicker
          dateFormat={"dd/MM/yyyy"}
          selected={reqParams.date_from}
          onChange={(date) =>
            setReqParams({ ...reqParams, date_from: new Date(date) })
          }
        />
        <> &nbsp; </>
        <InputGroupAddon addonType="prepend">Date Range To</InputGroupAddon>
        <DatePicker
          dateFormat={"dd/MM/yyyy"}
          selected={reqParams.date_to}
          onChange={(date) =>
            setReqParams({ ...reqParams, date_to: new Date(date) })
          }
        />
      </InputGroup>
      <br></br>


      <div style={{ margin: 20 }}>
        {columns ? (
          <Table columns={columns} data={tableDataRows} />
        ) : (
          "Please Enter Values"
        )}
        {columns && tableDataRows && tableDataRows.length == 0
          ? " Zero record found "
          : ""}
      </div>

      <Modal show={show} onHide={handleClose}  size = "xl" >
        <Modal.Header closeButton>
          <Modal.Title> Vehicle Location Details </Modal.Title>
        </Modal.Header>
        
        <Modal.Body> {dateNameValue}</Modal.Body> 

        <ListGroup horizontal >
              <ListGroup.Item style={{"width" : "50%"}} >Store location latitude</ListGroup.Item>
              <ListGroup.Item style={{"width" : "50%"}} >Store location longitude</ListGroup.Item>
              <ListGroup.Item style={{"width" : "50%"}} >Nearest visited point latitude</ListGroup.Item>
              <ListGroup.Item style={{"width" : "50%"}} >Nearest visited point longitude</ListGroup.Item>
              <ListGroup.Item style={{"width" : "50%"}} >Distance from store</ListGroup.Item>
              <ListGroup.Item style={{"width" : "50%"}} >Remarks</ListGroup.Item> 
        </ListGroup>
    
        { 
            vehicleLocationMapMysql[dateNameValue] !== undefined ?   vehicleLocationMapMysql[dateNameValue].map((item)=>{


              if (vehicleLocationMap[dateNameValue] !== undefined){
                
                let nearest_point = [Number.MAX_VALUE,0,0]

                for ( let location in vehicleLocationMap[dateNameValue] ){

                  let distance = getDistanceFromLatLonInMeters( item.latitude,item.longitude, vehicleLocationMap[dateNameValue][location].latitude, vehicleLocationMap[dateNameValue][location].longitude )

                  if(Math.round(distance) < nearest_point[0]){
                    nearest_point[0] = Math.round(distance);
                    nearest_point[1] = vehicleLocationMap[dateNameValue][location].latitude;
                    nearest_point[2] = vehicleLocationMap[dateNameValue][location].longitude;
                  }

                  if (   Math.round(distance) <= 30){
                    return (
                      <ListGroup horizontal  key = { Math.floor(Math.random() * 10000000 )}  >
                          <ListGroup.Item style={{"width" : "50%"}} >{item.latitude}</ListGroup.Item>
                          <ListGroup.Item style={{"width" : "50%"}} >{item.longitude}</ListGroup.Item>
                          <ListGroup.Item style={{"width" : "50%"}} >{vehicleLocationMap[dateNameValue][location].latitude}</ListGroup.Item>
                          <ListGroup.Item style={{"width" : "50%"}} >{vehicleLocationMap[dateNameValue][location].longitude}</ListGroup.Item>
                          <ListGroup.Item style={{"width" : "50%"}} >{Math.round(distance)}</ListGroup.Item>
                          <ListGroup.Item style={{"width" : "50%"}} >OK</ListGroup.Item>
                      </ListGroup>
                      )
                  }
                }

                
                return (
                  <ListGroup horizontal  key = { Math.floor(Math.random() * 10000000 )}  >
                      <ListGroup.Item style={{"width" : "50%"}} >{item.latitude}</ListGroup.Item>
                      <ListGroup.Item style={{"width" : "50%"}} >{item.latitude}</ListGroup.Item>
                      <ListGroup.Item style={{"width" : "50%"}} >{nearest_point[1]}</ListGroup.Item>
                      <ListGroup.Item style={{"width" : "50%"}} >{nearest_point[2]}</ListGroup.Item>
                      <ListGroup.Item style={{"width" : "50%"}} >{nearest_point[0]}</ListGroup.Item>
                      <ListGroup.Item style={{"width" : "50%"}} >X</ListGroup.Item>
                  </ListGroup>
                  )

              }else{
                return (
                  <ListGroup horizontal  key = { Math.floor(Math.random() * 10000000 )}  >
                      <ListGroup.Item style={{"width" : "50%"}} >{item.latitude}</ListGroup.Item>
                      <ListGroup.Item style={{"width" : "50%"}} >{item.latitude}</ListGroup.Item>
                      <ListGroup.Item style={{"width" : "50%"}} >Not found </ListGroup.Item>
                      <ListGroup.Item style={{"width" : "50%"}} >Not found </ListGroup.Item>
                      <ListGroup.Item style={{"width" : "50%"}} >Not found </ListGroup.Item>
                      <ListGroup.Item style={{"width" : "50%"}} >X</ListGroup.Item>
                  </ListGroup>
                  )
              }
            })  : ""
        }

      <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>



       
      <Modal show={show2} onHide={handleClose2}  size = "xl">
        <Modal.Header closeButton>
          <Modal.Title>  Delivery Order Details </Modal.Title>
        </Modal.Header>
        <Modal.Body> {dateNameValue } {StatusType}  </Modal.Body>

        <ListGroup horizontal >
              <ListGroup.Item style={{"width" : "50%"}} >shop_name</ListGroup.Item>
              <ListGroup.Item style={{"width" : "50%"}} >area</ListGroup.Item>
              <ListGroup.Item style={{"width" : "50%"}} >order_number</ListGroup.Item>
              <ListGroup.Item style={{"width" : "50%"}} >status</ListGroup.Item>
          
        </ListGroup>
       
        {
          vehicleLocationMapMysql[dateNameValue] !== undefined ?   vehicleLocationMapMysql[dateNameValue].map((item)=> {


            if (StatusType ===  item.status  || StatusType === "all"  ){
              return ( 
                <ListGroup horizontal  key = { Math.floor(Math.random() * 10000000 )} >
                  <ListGroup.Item style={{"width" : "50%"}} >{item.shop_name}</ListGroup.Item>
                  <ListGroup.Item style={{"width" : "50%"}} >{item.area}</ListGroup.Item>
                  <ListGroup.Item style={{"width" : "50%"}} >{item.order_number}</ListGroup.Item>
                  <ListGroup.Item style={{"width" : "50%"}} >{item.status}</ListGroup.Item>
                </ListGroup>)
            }


          }) : "loading..."
        }





        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Driverperformance;
