import React, { useState } from "react";
import TransporterPaymentsRetailer from "./TransporterPaymentsRetailer";
import TransporterPaymentsDepo from "./TransporterPaymentsDepo";
import NavBar from "../Navigation bar/Navbar";

import { Dropdown } from "react-bootstrap";

function TransporterPayments() { 
  const [TableNo, setTableNo] = useState(1);
  const [TableName, setTableName] = useState("Retailer");

  const handleSelectTable = (eventKey) => {
    setTableNo(Number(eventKey));
    if (Number(eventKey) == 1) {
      setTableName("Retailer");
    }
    if (Number(eventKey) == 2) {
      setTableName("Depo");
    }
  };

  return (
    <div>
      <NavBar />

      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div style={{ margin: 20 }}>
        <h2>Transporter Payments </h2>
 
      <Dropdown onSelect={handleSelectTable}>
        <Dropdown.Toggle id="dropdown-basic">{TableName}</Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item eventKey="1"> Retailer </Dropdown.Item>
          <Dropdown.Item eventKey="2"> Depo </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      </div>

      {TableNo == 1 && <TransporterPaymentsRetailer />}

      {TableNo == 2 && <TransporterPaymentsDepo />}
    </div>
  );
}

export default TransporterPayments;
