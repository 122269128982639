import React, { useRef, useState } from "react";
import { TimeInput } from "@mantine/dates";
import { ScrollArea } from "@mantine/core";
import moment from "moment/moment";
import {
  Drawer,
  Button,
  Group,
  Text,
  TextInput,
  NativeSelect,
  Space,
  Select,
} from "@mantine/core";

const VehicleDetailsDrawer = (props) => {
  const getTimeStampBasedOnTime = (hours, minutes) => {
    let dateToday = new Date();
    let year = dateToday.getFullYear();
    let monthIndex = dateToday.getMonth();
    return new Date(year, monthIndex + 1, 1, hours, minutes);
  };

  const opened = props.opened;
  const setOpened = props.setOpened;
  const routeId = props.routeId;
  const vehicleLoadedByOptions = props.vehicleLoadedByOptions || [];
  const looseAllocatedByOptions = props.looseAllocatedByOptions || [];
  const onSubmit = props.onSubmit;
  const existingData = props.existingData;
  const companyList = props.companyList || [];

  const loggedInUserType = sessionStorage.getItem("userType");

  const vehicleLoadedByRef = useRef();
  const looseAllocatedByRef = useRef();

  const vehicleReportingTimeRef = useRef(
    existingData.Vehicle_Reporting_Time
      ? new Date(Number(existingData.Vehicle_Reporting_Time))
      : ""
  );
  const vehicleDispatchTimeRef = useRef(
    existingData.Vehicle_Dispatch_Time
      ? new Date(Number(existingData.Vehicle_Dispatch_Time))
      : ""
  );
  const DBReturnTimeRef = useRef(
    existingData.DB_Return_Time
      ? new Date(Number(existingData.DB_Return_Time))
      : ""
  );
  const DBReleaseTimeRef = useRef(
    existingData.completed_conslidation_updatedAt
      ? new Date(Number(existingData.completed_conslidation_updatedAt))
      : ""
  );
  const vehicleNumberRef = useRef();
  const companyNameRef = useRef(existingData.company_type || "");

  const onFormSubmit = (event) => {
    event.preventDefault();
    const vehicle_loaded_by = vehicleLoadedByRef.current.value;
    const loose_allocated_by = looseAllocatedByRef.current.value;
    const vehicle_reporting_time = vehicleReportingTimeRef.current;
    const vehicle_dispatch_time = vehicleDispatchTimeRef.current;
    const db_return_time = DBReturnTimeRef.current;

    const vehicle_number = vehicleNumberRef.current.value;
    const company_name = companyNameRef.current;

    if (
      !vehicle_loaded_by ||
      !loose_allocated_by ||
      !vehicle_reporting_time ||
      !vehicle_dispatch_time ||
      !db_return_time ||
      !vehicle_number ||
      !company_name
    ) {
      alert("Please enter data for all the fields");
      return;
    }

    const dataToSave = {
      Vehicle_Loaded_By: vehicle_loaded_by,
      Loose_Allocated_By: loose_allocated_by,
      Vehicle_Reporting_Time: moment(vehicle_reporting_time)
        .valueOf()
        .toString(),
      Vehicle_Dispatch_Time: moment(vehicle_dispatch_time).valueOf().toString(),
      DB_Return_Time: moment(db_return_time).valueOf().toString(),
      vehicle_number: vehicle_number,
      company_type: company_name,
    };

    console.log("dataToSave", dataToSave);
    onSubmit(dataToSave);
  };

  return (
    <Drawer
      opened={opened}
      onClose={() => {
        setOpened(false);
      }}
      title={
        <h4>
          <b>Editing details for route : {routeId}</b>
        </h4>
      }
      padding="xl"
      size="xl"
    >
      <ScrollArea style={{ height: "100%" }}>
        <form onSubmit={onFormSubmit}>
          <Select
            name="vechicle_loaded_by"
            data={vehicleLoadedByOptions}
            placeholder="Pick one"
            label="Vehicle Loaded By"
            nothingFound="No options"
            searchable
            required
            defaultValue={existingData.Vehicle_Loaded_By}
            ref={vehicleLoadedByRef}
          />
          <Space h="md" />
          <Select
            name="loose_allocated_by"
            data={looseAllocatedByOptions}
            placeholder="Pick one"
            label="Loose Allocated By"
            searchable
            nothingFound="No options"
            required
            defaultValue={existingData.Loose_Allocated_By}
            ref={looseAllocatedByRef}
          />
          <Space h="md" />
          <TimeInput
            label="Vehicle Reporting Time"
            format="24"
            defaultValue={vehicleReportingTimeRef.current}
            onChange={(time) => {
              vehicleReportingTimeRef.current = time;
            }}
            required
          />
          <Space h="md" />

          <TimeInput
            label="Vehicle Dispatch Time"
            format="24"
            defaultValue={vehicleDispatchTimeRef.current}
            onChange={(time) => {
              vehicleDispatchTimeRef.current = time;
            }}
            required
          />
          <Space h="md" />

          <TimeInput
            label="DB return time"
            format="24"
            defaultValue={DBReturnTimeRef.current}
            onChange={(time) => {
              DBReturnTimeRef.current = time;
            }}
            required
          />
          <Space h="md" />

          <TimeInput
            label="DB release time"
            format="24"
            defaultValue={DBReleaseTimeRef.current}
            disabled={true}
            required
          />
          <Space h="md" />

          <TextInput
            label="Vehicle Number"
            ref={vehicleNumberRef}
            defaultValue={existingData.vehicle_number}
            required
          />
          <Space h="md" />
          <Select
            label="Company name"
            placeholder="Pick one"
            data={companyList}
            onChange={(selection) => {
              companyNameRef.current = selection;
            }}
            defaultValue={companyNameRef.current}
            required
          />
          <Space h="md" />

          {/* disabled={loggedInUserType !== 'Admin'} */}
          <Button type="submit">Submit</Button>

          <Space h="md" />
          <Space h="md" />
          <Space h="md" />
        </form>
      </ScrollArea>
    </Drawer>
  );
};

export default VehicleDetailsDrawer;
