import React, { useState, useMemo, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CSVLink } from "react-csv";

import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Form,
  InputGroup,
} from "react-bootstrap";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useAsyncDebounce,
} from "react-table";

function DetailedReportT3({ TableData, amount_per_service }) {
  let csv_data = useRef("");

  let AmtPerService = amount_per_service;
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => {
    toast(" showing tost massage please remove this on  handleShow1 fun");
    setShow1(true);
  };

  function createCsv(data, columns) {


    let final_csv = [];
    for (let row of data) {
      let one_row = [];
      for (let col of columns) {
        if (
          col.accessor === "actual_reason" &&
          row[col.accessor] === undefined
        ) {
          one_row.push(row["reason"]);
          continue;
        }

        one_row.push(row[col.accessor]);
      }
      final_csv.push(one_row);
    }

    let one_row = [];
    for (let col of columns) {
      one_row.push(col.accessor);
    }
    final_csv.unshift(one_row);

    csv_data.current = final_csv;
  }

  function Table1() {
    const data = useMemo(() => TableData, []);


    const columns = useMemo(
      () => [
        {
          Header: "Date",
          accessor: "allocation_date",
        },
        {
          Header: "Transit id",
          accessor: "transit_id",
        },
        {
          Header: "Transporter name",
          accessor: "transporter_name",
        },
        {
          Header: "Driver name",
          accessor: "driver_name",
        },
        {
          Header: "Vehicle number",
          accessor: "vehicle_number",
        },
        {
          Header: "Warehouse src",
          accessor: "src_warehouse",
          Filter: SelectColumnFilter,
        },
        {
          Header: "Destination",
          accessor: "destination",
          Filter: SelectColumnFilter,
        },
        {
          Header: "Driver phone",
          accessor: "driver_phone",
        },

        {
          Header: "Allocated by",
          accessor: "allocated_by",
        },
      ],
      []
    );

    createCsv(data, columns);


    function SelectColumnFilter({
      column: { filterValue, setFilter, preFilteredRows, id },
    }) {
      const options = useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
          options.add(row.values[id]);
        });
        return [...options.values()];
      }, [id, preFilteredRows]);

      return (
        <select
          value={filterValue}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
        >
          <option value="">All</option>
          {options.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </select>
      );
    }

    const defaultColumn = useMemo(
      () => ({
        Filter: () => null,
      }),
      []
    );

    const GlobalFilter = ({ filter, setFilter }) => {
      const [value, setValue] = useState(filter);
      const onChange = useAsyncDebounce((value) => {
        setFilter(value || undefined);
      }, 1000);
      return (
        <span>
          <input
            placeholder="scarch..."
            value={value || ""}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
          />
        </span>
      );
    };

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      setGlobalFilter,
      preGlobalFilteredRows,
    } = useTable({ columns, data, defaultColumn }, useFilters, useGlobalFilter);

    const { globalFilter } = state;

    return (
      <div>
        <Row>
          <Col>
            <GlobalFilter
              filter={globalFilter}
              setFilter={setGlobalFilter}
            ></GlobalFilter>
          </Col>
          <Col>
          <CSVLink
            className="btn btn-warning export_btn"
            filename={"DetailedReportDepo.csv"}
            data={csv_data.current}
          >
            Export Data
          </CSVLink>{" "}
        </Col>
        </Row>
        <br/>

        <table
          {...getTableProps()}
          style={{ border: "solid 1px blue", width: "100%" }}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{
                      borderBottom: "solid 3px red",
                      background: "aliceblue",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {column.render("Header")}
                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: "10px",
                          border: "solid 1px gray",
                          background: "papayawhip",
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Row>
        <Col>
          <Table1 />
        </Col>
      </Row>

      <Modal show={show1} onHide={handleClose1} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>name</Form.Label>
            <Form.Control type="test" onChange={(e) => {}} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose1}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DetailedReportT3;
