import React, { useState, useEffect, useRef } from "react";
import { useTable } from "react-table";
import axios from "axios";
import NavBar from "../Navigation bar/Navbar";
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import moment from "moment";
import Select from "react-select";
import { CSVLink } from "react-csv";

import { service_url } from "../../Configs/mysqlconfig";

function Inventorystock() {
  const [inventoryStack, setInventoryStack] = useState([]);
  const [wareHouse, setWareHouse] = useState();
  const [csvData, setCsvData] = useState();

  const [filters, setFilters] = useState({
    warehouse: "",
    stock_date: "",
  });

  useEffect(() => {
    const url = `${service_url}/api/superzop/systeminventory/getInventoryStock`;

    axios
      .get(url, { params: filters })
      .then(function (response) {
        setInventoryStack(response.data);

        let csv = response.data.map((item) => {
          return [
            item.item_id,
            item.item_disc,
            item.variety,
            item.category,
            item.sub_category,
            item.transferred_stock,
            item.shipped_stock,
            item.returned_stock,
            item.resultant_stock
          ];
        });

        csv.unshift( ["Item Id" , "Item Description", "Variety", "Category" , "Sub Category", "Transit Qty", "Shipped Qty" ,"Returned Qty", " System Qty" ] );

        setCsvData(csv);
      })
      .catch(function (error) { 
        console.log(error);
      });
  }, [filters]);

  useEffect(() => {
    const url = `${service_url}/api/superzop/systeminventory/getWareHouse`;

    axios
      .get(url)
      .then(function (response) {
        let wh = response.data.warehouse.map((item) => {
          return { value: item.warehouse, label: item.warehouse };
        });
        setWareHouse(wh);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  function Table() {
    inventoryStack.map((item) => {
      return [
        {
         
        
          // stock_date: item.stock_date,
          // warehouse: item.warehouse,
          // id: item.id,
          // brand: item.brand,
          // transit_id: item.transit_id,
          // item_modified_qty: item.item_modified_qty

          variety: item.variety,
          item_id: item.item_id,
          returned_stock: item.returned_stock,
          resultant_stock: item.resultant_stock,
          item_disc: item.item_disc,
          category: item.category,
          sub_category: item.sub_category,
          order_qty: item.order_qty,
          transferred_stock: item.transferred_stock,
          shipped_stock: item.shipped_stock,
        },
      ];
    });

    const data = React.useMemo(() => inventoryStack, []);
    const columns = React.useMemo(
      () => [
        {
          Header: "Item Id",  
          accessor: "item_id",
        },
        {
          Header: "Item Description",
          accessor: "item_disc",
        },
        {
          Header: "Variety",
          accessor: "variety",
        },
        {
          Header: "Category",
          accessor: "category",
        },
        {
          Header: "Sub Category",
          accessor: "sub_category",
        },
        {
          Header: "Transit Qty",  
          accessor: "transferred_stock",
        },
        {
          Header: "Shipped Qty",
          accessor: "shipped_stock",
        },
        {
          Header: "Returned Qty",
          accessor: "returned_stock",
        },
        {
          Header: "System Qty",
          accessor: "resultant_stock", 
        },
        // {
        //   Header: "stock_date",
        //   accessor: "stock_date",
        // },
        // {
        //   Header: "warehouse",
        //   accessor: "warehouse",
        // },
        // {
        //   Header: "id",
        //   accessor: "id",
        // },
        // {
        //   Header: "transit_id",
        //   accessor: "transit_id",
        // },
        // {
        //   Header: "brand",
        //   accessor: "brand",
        // },
        // {
        //   Header: "order_qty",
        //   accessor: "order_qty",
        // },
        // {
        //   Header: "item_modified_qty",
        //   accessor: "item_modified_qty",
        // },
      ],
      []
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      setGlobalFilter,
      preGlobalFilteredRows,
    } = useTable({ columns, data });

    return (
      <div>
        <table
          {...getTableProps()}
          style={{ border: "solid 1px blue", width: "100%" }}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{
                      padding: "10px",
                      color: "black",
                      fontWeight: "bold",
                      border: "solid 1px gray",
                    }}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: "10px",
                          border: "solid 1px gray",
                          background: "yellow",
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div>
      <NavBar />
      <br />
      <br />
      <br />
      <br />
      <br />

      <div style={{ margin: 20 }}>
        <h2> Inventory Stock</h2>
        <br />
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Control
                type="date"
                onChange={(e) => {
                  setFilters({ ...filters, stock_date: e.target.value });
                }}
              />
              <small>Please select both to see results </small>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Select
                defaultValue={"selectedOption"}
                onChange={(e) => {
                  setFilters({ ...filters, warehouse: e.value });
                }}
                options={wareHouse}
              />
            </Form.Group>
          </Col>
          <Col>
            {csvData && (
              <CSVLink
                className="btn btn-warning export_btn"
                filename={"inventory_stock.csv"}
                data={csvData}
              >
                Export Data
              </CSVLink>
            )}
          </Col>
          <Col></Col>
          <Col></Col>
          <Col></Col>
        </Row>

        <Table />
      </div>
    </div>
  );
}

export default Inventorystock;
