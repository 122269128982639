import React, { useState, useEffect } from "react";
import { Table, Form, Alert, Button, Modal } from "react-bootstrap";
import { Image } from "@mantine/core";
import { InputGroup, InputGroupAddon, InputGroupText, Input } from "reactstrap";
import { toast } from "react-toastify";

function ShowLoosePackBagDetails({ bagDetails, ...props }) {
  console.log("received data ", bagDetails);

  const { bag_items, bagid: bag_id } = bagDetails;

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4>
              Bag Details of order no : <span>{bagDetails.order_number}</span>
            </h4>
            <h4>
              Bag Id : <span>{bag_id} </span>
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Object.keys(bag_items).length > 0 ? (
            <Table>
              <thead>
                <tr>
                  <th>Item Image</th>
                  <th>Brand</th>
                  <th>Description</th>
                  <th>Weight</th>
                  <th>Picked Qty</th>
                  <th>Packed in Bag</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(bag_items).length > 0
                  ? Object.keys(bag_items).map((bagId, index) => {
                      return (
                        <>
                          {bag_items[bagId].map((item, index) => {
                            return (
                              <tr>
                                <td
                                  style={{
                                    height: "100px",
                                    width: "100px",
                                  }}
                                >
                                  {item.image_url ? (
                                    <img
                                      style={{
                                        height: "100%",
                                        width: "100%",
                                        display: "inline-block",
                                        textAlign: "center",
                                      }}
                                      src={item.image_url}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td>{item["brand"]}</td>
                                <td>{item["item_disc"]}</td>
                                <td>{item["weight"] + " Kg(s)"}</td>
                                <td>x {item["picked_qty"]}</td>
                                <td>{bagId}</td>
                              </tr>
                            );
                          })}
                        </>
                      );
                    })
                  : null}
              </tbody>
            </Table>
          ) : (
            <div></div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ShowLoosePackBagDetails;
