import React, { useState, useMemo, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { service_url } from "../../Configs/mysqlconfig";
import axios from "axios";
import NavBar from "../Navigation bar/Navbar";
import FileUploader from "react-firebase-file-uploader";
import fire from "../../Configs/firebase";

import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Form,
  Alert,
  Figure,
  Spinner,
} from "react-bootstrap";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useAsyncDebounce,
} from "react-table";

let today = new Date();
let dd = String(today.getDate()).padStart(2, "0");
let mm = String(today.getMonth() + 1).padStart(2, "0");
let yyyy = today.getFullYear();
today = yyyy + "-" + mm + "-" + dd;

function PaymentPending() {
  const [Loading, setLoading] = useState(false)
  const [ModelSlipImage, setModelSlipImage] = useState();
  const [DohData, setDohData] = useState([]);
  const [PaymentPending, setPaymentPending] = useState([]);
  const [EditPayLoad, setEditPayLoad] = useState();
  const [Reload, setReload] = useState();
  const [TableDate, setTableDate] = useState({
    start_date: today,
    end_date: today,
  });
  const [ImageUploadState, setImageUploadState] = useState({
    username: "",
    avatar: "",
    isUploading: false,
    progress: 0,
    avatarURL: "",
  });

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (row) => {
    setEditPayLoad({
      id: row?.id,
      delivery_date: row?.delivery_date,
      order_number: row?.order_number,
      proof_img: row?.proof_img,
      asm: row?.asm || "",
      paid_amt: row?.paid_amt || "",
      payment_pending_payment_amount: row?.payment_pending_payment_amount || "",
      total_amt: row?.total_amt || "",
      payment_mode: row?.payment_mode || "",
    });
    setModelSlipImage(row.proof_img);
    setShow1(true);
  };


  useEffect(() => {
    
    CombineApiCall();

    return () => {};
  }, [Reload, TableDate]);

  function syncDriverOrdersHistory() {
    let todaysDate = new Date();
    const todaysDateYear = todaysDate.getFullYear();
    const todaysDateMonth = todaysDate.getMonth() + 1;
    const todaysDateDay = todaysDate.getDate();

    toast("Sync started... ", {
      type: toast.TYPE.SUCCESS,
    });

    const url =
      `${service_url}/api/superzop/delivery/savedriverordershistorytable?day=` +
      todaysDateDay +
      "&month=" +
      todaysDateMonth +
      "&year=" +
      todaysDateYear;

    axios({
      method: "post",
      url: url,
    })
      .then(function (response) {
        toast("Successfully sync Driver Orders History ", {
          type: toast.TYPE.SUCCESS,
        });
      })
      .catch(function (error) {
        console.log("ERROR on sync Driver Orders History : ", error);
        toast(" ERROR on sync Driver Orders History ", {
          type: toast.TYPE.ERROR,
        });
      });
  }

  async function CombineApiCall() {

    setLoading(true)
    const url = `${service_url}/api/superzop/paymentpending/getDOHforPaymentPending?delivery_date_start=${TableDate?.start_date}&delivery_date_end=${TableDate?.end_date}`;
    axios
      .get(url)
      .then((result) => {
        setDohData(result.data);
        setLoading(false)
        if(result.data.length == 0){
          toast("NO data fround for this date  ", {
            type: toast.TYPE.ERROR,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    const url1 = `${service_url}/api/superzop/paymentpending/getPaymentPending`;
    axios
      .get(url1)
      .then((result) => {
        let PayPen = {};
        for (let itm of result.data) {
          PayPen[itm.order_number + "@" + itm.delivery_date] = itm;
        }
        setPaymentPending(PayPen);
    
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function updatePaymentPending() {
    const url = `${service_url}/api/superzop/paymentpending/updatePaymentPending`;
    axios
      .put(url, EditPayLoad)
      .then((result) => {
        console.log(result);
        toast("Successfully sync Driver Orders History ", {
          type: toast.TYPE.SUCCESS,
        });
        setReload(!Reload);
        setShow1(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function handelUploadImage(filename) {
    try {
      let ref = fire
        .storage()
        .ref(`/disha-payments/Payment_Management/${filename}`);
      let url = await ref.getDownloadURL();

      setModelSlipImage(url);
      await axios.put(
        `${service_url}/api/superzop/paymentpending/updateProofImg`,
        {
          delivery_date: EditPayLoad?.delivery_date,
          order_number: EditPayLoad?.order_number,
          proof_img: url,
        }
      );
    } catch (error) {
      console.log(error);
    }

    setImageUploadState({
      ...ImageUploadState,
      isUploading: false,
    });

    toast(` Upload Success `, {
      type: toast.TYPE.SUCCESS,
    });

    setReload(!Reload);
  }

  function Table1() {

    let table_Date = DohData.map((itm) => {
      return {
        id: itm.order_number + "@" + itm.delivery_date,
        delivery_date: itm?.delivery_date,
        order_number: itm?.order_number,
        Warehouse: itm?.Warehouse,
        shop_name: itm?.shop_name,
        route: itm?.route,
        asmphone : itm?.asmphone,
        payment_pending_payment_amount : itm?.payment_pending_payment_amount,
        total_amount_paid : itm?.total_amount_paid,
        proof_img:  PaymentPending[itm.order_number + "@" + itm.delivery_date]?.proof_img,
        paid_amt: PaymentPending[itm.order_number + "@" + itm.delivery_date]?.paid_amt,
        payment_mode:   PaymentPending[itm.order_number + "@" + itm.delivery_date]?.payment_mode,
      };
    });

    const data = useMemo(() => table_Date, []);

    const columns = useMemo(
      () => [
        {
          Header: "Delivery date",
          accessor: "delivery_date",
          Filter: SelectColumnFilter,
        },
        {
          Header: "Order number",
          accessor: "order_number",
        },
        {
          Header: "Warehouse",
          accessor: "Warehouse",
          Filter: SelectColumnFilter,
        },
        {
          Header: "Shop name",
          accessor: "shop_name",
        },
        {
          Header: "Route",
          accessor: "route",
        },
        {
          Header: "ASM",
          accessor: "asmphone",
        },
        {
          Header: "Payment Pending amount",
          accessor: "payment_pending_payment_amount",
        },
        {
          Header: "Total order amount",
          accessor: "total_amount_paid",
        },
        {
          Header: "Payment mode",
          accessor: "payment_mode",
        },
        {
          Header: "Paid amt",
          accessor: "paid_amt",
        },
        {
          Header: "Edit",
          accessor: "id",
          Cell: ({ row }) => {
            console.log(row.original);
            if (
              row.original.proof_img !== null &&
              row.original.proof_img !== undefined
            ) {
              return (
                <img
                  src={row.original.proof_img}
                  onClick={() => handleShow1(row.original)}
                  style={{ width: "50px", height: "60px" }}
                />
              );
            } else {
              return (
                <Button onClick={() => handleShow1(row.original)}>
                  {" "}
                  Add Slip{" "}
                </Button>
              );
            }

            // return (
            //   <Button onClick={() => handleShow1(row.original)}> Edit </Button>
            // );
          },
        },
      ],
      []
    );

    function SelectColumnFilter({
      column: { filterValue, setFilter, preFilteredRows, id },
    }) {
      const options = useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
          options.add(row.values[id]);
        });
        return [...options.values()];
      }, [id, preFilteredRows]);

      return (
        <select
          value={filterValue}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
        >
          <option value="">All</option>
          {options.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </select>
      );
    }

    const defaultColumn = useMemo(
      () => ({
        Filter: () => null,
      }),
      []
    );

    const GlobalFilter = ({ filter, setFilter }) => {
      const [value, setValue] = useState(filter);
      const onChange = useAsyncDebounce((value) => {
        setFilter(value || undefined);
      }, 1000);
      return (
        <span>
          <Form.Control
            placeholder="scarch..."
            value={value || ""}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
          />
        </span>
      );
    };

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      setGlobalFilter,
      preGlobalFilteredRows,
    } = useTable({ columns, data, defaultColumn }, useFilters, useGlobalFilter);

    const { globalFilter } = state;

    return (
      <div>
        <Row>
          <Col>
            <GlobalFilter
              filter={globalFilter}
              setFilter={setGlobalFilter}
            ></GlobalFilter>
          </Col>
          {/* <Col>
            <Form.Control
              value={TableDate?.start_date}
              type="date"
              onChange={(e) =>
                setTableDate({...TableDate, start_date : e.target.value})
              }
            />
          </Col>
          <Col>
            <Form.Control
              value={TableDate?.end_date}
              type="date"
              onChange={(e) =>
                setTableDate({...TableDate, end_date : e.target.value})
              }
            />
          </Col> */}
          {/* <Col>
            <Button onClick={syncDriverOrdersHistory} variant="primary">
              sync
            </Button>
          </Col> */}

          <Col>{/* <Button variant="info">Primary</Button>{" "} */}</Col>
          <Col>{/* <Button variant="info">Primary</Button>{" "} */}</Col>
          <Col>{/* <Button variant="info">Primary</Button>{" "} */}</Col>
          <Col>{/* <Button variant="info">Primary</Button>{" "} */}</Col>
        </Row>
        <br />

        <table
          {...getTableProps()}
          style={{ border: "solid 1px blue", width: "100%" }}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{
                      borderBottom: "solid 3px red",
                      background: "aliceblue",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {column.render("Header")}
                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: "10px",
                          border: "solid 1px gray",
                          background: "papayawhip",
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    ); 
  }

  return (
    <div>
      <NavBar />
      <br />
      <br />
      <br />
      <br />
      <div style={{ margin: 20 }}>
        <h2> Payment Pending {Loading && ( <Spinner animation="border" role="status"> </Spinner>)} </h2>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <Row>
          <Col>
            <Form.Control
              value={TableDate?.start_date}
              type="date"
              onChange={(e) =>
                setTableDate({ ...TableDate, start_date: e.target.value })
              }
            />
          </Col>
          <Col>
            <Form.Control
              value={TableDate?.end_date}
              type="date"
              onChange={(e) =>
                setTableDate({ ...TableDate, end_date: e.target.value })
              }
            />
          </Col>
          <Col>
            <Button onClick={syncDriverOrdersHistory} variant="primary">
              sync
            </Button>
          </Col>
        </Row>
        <br />

        <Row>
          <Col>
            <Table1 />
          </Col>
        </Row>

        <Modal show={show1} onHide={handleClose1} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Edit Payment Pending</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert variant="primary">
              Delivery date
              <Alert.Link> {EditPayLoad?.delivery_date} </Alert.Link> <br />
              Order number
              <Alert.Link> {EditPayLoad?.order_number} </Alert.Link>
            </Alert>

            <Form.Group>
              <Form.Label>Paid amt</Form.Label>
              <Form.Control
                value={EditPayLoad?.paid_amt}
                type="number"
                onChange={(e) =>
                  setEditPayLoad({ ...EditPayLoad, paid_amt: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group controlId="formBasicSelect">
              <Form.Label>Payment mode</Form.Label>
              <Form.Control
                as="select"
                value={EditPayLoad?.payment_mode}
                onChange={(e) => {
                  setEditPayLoad({
                    ...EditPayLoad,
                    payment_mode: e.target.value,
                  });
                }}
              >
                <option value="">Select</option>
                <option value="Cash">Cash</option>
                <option value="Cheque">Cheque</option>
                <option value="RazorPay">RazorPay</option>
                <option value="Paytm">Paytm</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formBasicSelect">
              <Form.Label> Upload proof img</Form.Label>
              <br />
              <Row>
                <Col>
                  <FileUploader
                    accept="image/*"
                    name="image-uploader-multiple"
                    storageRef={fire
                      .storage()
                      .ref(`/disha-payments/Payment_Management`)}
                    randomizeFilename
                    onUploadStart={() =>
                      setImageUploadState({
                        ...ImageUploadState,
                        isUploading: true,
                        progress: 0,
                      })
                    }
                    onProgress={(prog) =>
                      setImageUploadState({
                        ...ImageUploadState,
                        progress: prog,
                      })
                    }
                    onUploadError={() => {
                      toast(" Upload Error", {
                        type: toast.TYPE.ERROR,
                      });
                    }}
                    onUploadSuccess={handelUploadImage}
                  />

                  <br />
                  {(ImageUploadState.progress !== 0 && (
                    <div>
                      <Spinner animation="border" size="sm" /> Uploading Started
                    </div>
                  )) || (
                    <b>Select a file uploading will start automatically </b>
                  )}
                </Col>
                <Col>
                  {ModelSlipImage ? (
                    <Figure.Image
                      width={1000}
                      height={2000}
                      alt=" "
                      src={ModelSlipImage}
                    />
                  ) : (
                    <h5> </h5>
                  )}
                </Col>
              </Row>
            </Form.Group>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
            <Button variant="primary" onClick={updatePaymentPending}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    </div>
  );
}

export default PaymentPending;
