import React, { useState, useEffect, useRef } from "react";
import { Table, Form, Alert, Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { Collapse, IconButton } from "@material-ui/core";
import ReactToPrint from "react-to-print";
import { service_url, token } from "../../Configs/mysqlconfig";
import { toast } from "react-toastify";
import Backdrop from "../backdrop/backdrop";
const GLOBAL_PARENT_WAREHOUSE = "Bhiwandi";

function InventoryTransactionsModal({
  transaction_date,
  warehouse,
  item_id,
  item_description,
  ...props
}) {
  const [inventoryList, setInventoryList] = useState([]);
  const [backDrop, setBackDrop] = useState(false);
  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });

  useEffect(() => {
    setBackDrop(true);
    console.log("check props", transaction_date, warehouse, item_id);
    let url = `${service_url}/api/inventory-transactions?transaction_date=${transaction_date}&warehouse=${warehouse}&item_id=${item_id}&token=${token}`;
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        setInventoryList(result.data || []);
        setBackDrop(false);
      })
      .catch((err) => {
        toast(`Error in fetching transactions: ${err}`, {
          type: toast.TYPE.ERROR,
        });
      });
  }, []);

  const InventoryTransactionsTableHeader = () => {
    return (
      <thead className="thead-light">
        <tr>
          <th>ID</th>
          <th>Quantity</th>
          <th>Activity</th>
          <th>Date</th>
          <th>Indicator</th>
        </tr>
      </thead>
    );
  };
  const InventoryTransactionsTableBody = () => {
    let rows = inventoryList.map((row, index) => {
      return (
        <tr key={index}>
          <td>{row.ID}</td>
          <td>{row.Quantity}</td>
          <td>{row.Activity}</td>
          <td>{row.Date}</td>
          <td>{row.Indicator}</td>
        </tr>
      );
    });
    return (
      <>
        <tbody>{rows}</tbody>
      </>
    );
  };
  const InventoryTransactionsTable = () => {
    return (
      <>
        <table className="table table-striped">
          <InventoryTransactionsTableHeader />
          <InventoryTransactionsTableBody />
        </table>
      </>
    );
  };
  return (
    <div>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {`Inventory Transactions || ${warehouse} || ${item_id} || ${item_description}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {inventoryList.length > 0 ? (
            <InventoryTransactionsTable />
          ) : (
            <div>No Transactions</div>
          )}
        </Modal.Body>
      </Modal>
      {backDrop ? <Backdrop parentLoadStatus={backDrop} /> : null}
    </div>
  );
}
export default InventoryTransactionsModal;
