import React, { useState, useEffect, useRef } from 'react'
import { Table, Form, Alert, Button, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify';

function OrderItemsListingModal({ itemDetails, orderNum, orderAmt, ...props }) {
  console.log(itemDetails);

  const RenderItemTableBody = () => {
    let rows=[];
    let count=0;
    if(itemDetails.length>0){

      rows = itemDetails.map((itemDetail, index) => {
          count=count+1;
          return (
              <tr key={index}>
                  <td>{count}</td>
                  <td>{itemDetail.item_disc}</td>
                  <td>{itemDetail.quantity}</td>
                  <td>{itemDetail.weight}</td>
                  <td>{itemDetail.variety}</td>
                  <td>{itemDetail.item_amt}</td>
              </tr>
          );
      });
    }

    return (<tbody>
              {rows}
              <tr key={count+1}>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td><strong>OrderValue</strong></td>
                  <td><strong>{orderAmt}</strong></td>
              </tr>
            </tbody>)
  }

    return (
        <div>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Product Details of {orderNum}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table>
                        <thead>
                          <tr>
                            <th>Sl No.</th>
                            <th>Item Description</th>
                            <th>Qty</th>
                            <th>Weight</th>
                            <th>Variety</th>
                            <th>Item Amount</th>
                          </tr>
                        </thead>
                        <RenderItemTableBody/>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default OrderItemsListingModal
