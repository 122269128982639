import React, { useState, useEffect } from 'react'
import { Table, Form, Alert, Button,Modal } from 'react-bootstrap'
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import Backdrop from '../backdrop/backdrop'

function formatDate(selecteddate){
  if(selecteddate){
    let dt=new Date(selecteddate);
    let monthNames =["Jan","Feb","Mar","Apr",
                        "May","Jun","Jul","Aug",
                        "Sep", "Oct","Nov","Dec"];

      let day = dt.getDate();

      let monthIndex = dt.getMonth();
      let monthName = monthNames[monthIndex];

      let year = dt.getFullYear();

      return `${day}-${monthName}-${year}`;
  }else{
    return null;
  }

}

function ShowReturnOrderItemModal({returnOrderNumber,deliveryDate,showReturnOrderItems,...props}) {
  const [backDrop, setBackDrop] = useState(false);
  const [orderDetails,setOrderDetails]=useState({});
  const [itemDetails,setItemDetails]=useState([]);

  useEffect(()=>{
    if(showReturnOrderItems){
      setOrderDetails({});
      setItemDetails([]);
      setBackDrop(true);
      fetch(`http://services2.superzop.com:3000/api/superzop/delivery/itemsbasedondeliverydate?start_date=${deliveryDate}&end_date=${deliveryDate}&order_number=${returnOrderNumber}`).then(res => {
          return res.json()
      }).then(data => {
        console.log(data);
        if(data.length>0){
          let order_number,status,total_amount_paid,payment_mode,payment_cash_amount,payment_cheque1_amount,payment_cheque1_number,payment_cheque1_date,payment_cheque2_amount,payment_cheque2_number,
          payment_cheque2_date,payment_card_amount,payment_card_transactionid,payment_paytm_amount,payment_razorpay_amount,payment_pending_payment_amount,payment_adjustments;

          let ordrItemDetails=[];
          for(let i=0;i<data.length;i++){
            // order level
            order_number=data[i]['order_number'];
            status=data[i]['status'];
            total_amount_paid=data[i]['total_amount_paid'];
            payment_mode=data[i]['payment_mode'];
            payment_cash_amount=data[i]['payment_cash_amount'];
            payment_cheque1_amount=data[i]['payment_cheque1_amount'];
            payment_cheque1_number=data[i]['payment_cheque1_number'];
            payment_cheque1_date=data[i]['payment_cheque1_date'];
            payment_cheque2_amount=data[i]['payment_cheque2_amount'];
            payment_cheque2_number=data[i]['payment_cheque2_number'];
            payment_cheque2_date=data[i]['payment_cheque2_date'];
            payment_card_amount=data[i]['payment_card_amount'];
            payment_card_transactionid=data[i]['payment_card_transactionid'];
            payment_paytm_amount=data[i]['payment_paytm_amount'];
            payment_razorpay_amount=data[i]['payment_razorpay_amount'];
            payment_pending_payment_amount=data[i]['payment_pending_payment_amount'];
            payment_adjustments=data[i]['payment_adjustments'];

            //item level
            let ele={};
            ele['item_id']=data[i]['item_id'];
            ele['item_disc']=data[i]['item_disc'];
            ele['item_reason']=data[i]['item_reason'];
            ele['item_primary_reason']=data[i]['item_primary_reason'];
            ele['item_status']=data[i]['item_status'];
            ele['order_qty']=data[i]['order_qty'];
            ele['item_modified_qty']=data[i]['item_modified_qty'];
            ele['item_delivered_qty']=data[i]['item_delivered_qty'];
            ele['item_returned_qty']=data[i]['item_returned_qty'];
            ele['gst_charge']=data[i]['gst_charge'];
            ele['mrp']=data[i]['mrp'];
            ele['total_amt']=data[i]['total_amt'];
            ordrItemDetails.push(ele);
          }

          let orderele={};
          orderele['order_number']=order_number;
          orderele['status']=status;
          orderele['total_amount_paid']=total_amount_paid;
          orderele['payment_mode']=payment_mode;
          orderele['payment_cash_amount']=payment_cash_amount;
          orderele['payment_cheque1_amount']=payment_cheque1_amount;
          orderele['payment_cheque1_number']=payment_cheque1_number;
          orderele['payment_cheque1_date']=payment_cheque1_date;
          orderele['payment_cheque2_amount']=payment_cheque2_amount;
          orderele['payment_cheque2_number']=payment_cheque2_number;
          orderele['payment_cheque2_date']=payment_cheque2_date;
          orderele['payment_card_amount']=payment_card_amount;
          orderele['payment_card_transactionid']=payment_card_transactionid;
          orderele['payment_paytm_amount']=payment_paytm_amount;
          orderele['payment_razorpay_amount']=payment_razorpay_amount;
          orderele['payment_pending_payment_amount']=payment_pending_payment_amount;
          orderele['payment_adjustments']=payment_adjustments;

          setItemDetails(ordrItemDetails);
          setOrderDetails(orderele);
          setBackDrop(false);
        }else{
          setOrderDetails({});
          setItemDetails([]);
          setBackDrop(false);
          alert("No items found");
        }
      }).catch(err => {
        setOrderDetails({});
        setItemDetails([]);
        setBackDrop(false);
        alert(err);
      })
    }
  },[showReturnOrderItems])

    return (<>
            <Modal
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    Order Information of {returnOrderNumber}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="table-wrapper" style={{marginTop:15,paddingBottom:40}}>
                  <Table className="table table-striped" style={{width:300,marginLeft:"auto",marginRight:"auto"}}>
                    <tbody>
                        <tr>
                            <td>OrderNumber</td>
                            <td>{orderDetails.order_number}</td>
                        </tr>
                        <tr>
                            <td>OrderStatus</td>
                            <td>{orderDetails.status}</td>
                        </tr>
                        <tr>
                            <td>OrderAmount</td>
                            <td>{orderDetails.total_amount_paid}</td>
                        </tr>
                        <tr>
                            <td>DeliveryDate</td>
                            <td>{deliveryDate?formatDate(deliveryDate):""}</td>
                        </tr>
                    </tbody>
                  </Table>
                </div>
                <div className="table-wrapper" style={{marginTop:15,paddingBottom:40}}>
                  <Table>
                    <tbody>
                      <tr>
                          <td>PaymentMode</td>
                          <td>{orderDetails['payment_mode']}</td>
                          <td>Cash</td>
                          <td>{orderDetails['payment_cash_amount']}</td>
                      </tr>
                      <tr>
                          <td>Cheque1 Amt</td>
                          <td>{orderDetails['payment_cheque1_amount']}</td>
                          <td>Cheque1 Number</td>
                          <td>{orderDetails['payment_cheque1_number']}</td>
                      </tr>
                      <tr>
                          <td>Cheque1 Date</td>
                          <td>{orderDetails['payment_cheque1_date']}</td>
                          <td>Cheque2 Amt</td>
                          <td>{orderDetails['payment_cheque2_amount']}</td>
                      </tr>
                      <tr>
                          <td>Cheque1 Number</td>
                          <td>{orderDetails['payment_cheque2_number']}</td>
                          <td>Cheque2 Date</td>
                          <td>{orderDetails['payment_cheque2_date']}</td>
                      </tr>
                      <tr>
                          <td>Card</td>
                          <td>{orderDetails['payment_card_amount']}</td>
                          <td>Card Transaction ID</td>
                          <td>{orderDetails['payment_card_transactionid']}</td>
                      </tr>
                      <tr>
                          <td>Paytm</td>
                          <td>{orderDetails['payment_paytm_amount']}</td>
                          <td>RazorPay</td>
                          <td>{orderDetails['payment_razorpay_amount']}</td>
                      </tr>
                      <tr>
                          <td>Pending Payments</td>
                          <td>{orderDetails['payment_pending_payment_amount']}</td>
                          <td>Adjustments</td>
                          <td>{orderDetails['payment_adjustments']}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                <Table>
                  <thead>
                    <tr>
                      <th>Item Id</th>
                      <th>Item Description</th>
                      <th>MRP</th>
                      <th>Order Qty</th>
                      <th>Delivered Qty</th>
                      <th>Modified Qty</th>
                      <th>Returned Qty</th>
                      <th>GST Details</th>
                      <th>Total Amount</th>
                      <th>Reason</th>
                      <th>Primary Reason</th>
                      <th>Item Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(itemDetails && itemDetails.length>0) ? itemDetails.map(item => (
                        <tr>
                            <td>{item['item_id']}</td>
                            <td>{item['item_disc']}</td>
                            <td>{item['mrp']}</td>
                            <td>{item['order_qty']}</td>
                            <td>{item['item_delivered_qty']}</td>
                            <td>{item['item_modified_qty']?item['item_modified_qty']:""}</td>
                            <td>{item['item_returned_qty']?item['item_returned_qty']:""}</td>
                            <td>{item['gst_charge']}</td>
                            <td>{item['total_amt']}</td>
                            <td>{item['item_reason']}</td>
                            <td>{item['item_primary_reason']}</td>
                            <td>{item['item_status']}</td>
                        </tr>
                      )) :null}
                  </tbody>
                </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
            {backDrop?(<Backdrop parentLoadStatus={backDrop}/>):(null)}
        </>
    )
}

export default ShowReturnOrderItemModal
