import styled from 'styled-components';
import sz_logo from '../../assests/images/sz_logo.png';
export const NavBarContainer = styled.div`
    position: fixed;
    z-index: 5;
    width: 100%;
    padding: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    background-color: #fff;
    justify-content: space-between;
    box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.1);
    opacity:0.9;
`;

export const LeftContainer = styled.div`
    width: auto;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0rem 1rem;
`;
export const MidContainer = styled.div`
    width: 15%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0rem 1rem;
`;
export const RightContainer = styled.div`
    width: auto;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin: 0rem 1rem;
`;


export const SZLogoContainer = styled.div`
    padding: 2rem 6rem;
    border-radius: 0.5rem; 
    margin: 0rem 1rem;
    background-image: url(${sz_logo});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    &:hover {
        cursor: pointer;
        box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.2);
    }
`;