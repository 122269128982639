import React, { useEffect, useState,useRef } from 'react';
import { CSVLink } from 'react-csv';
import { Button, FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { service_url } from "../../Configs/mysqlconfig";
import NavBar from "../Navigation bar/Navbar";
import { warehouseNames } from "../../Configs/mysqlconfig";


function Main() {
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedWarehouse, setSelectedWarehouse] = useState('All');
  const [selectedRoute, setSelectedRoute] = useState('All');
  const [data, setData] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [fetchDataOnClick, setFetchDataOnClick] = useState(true);
  const [routeDropdown,setRouteDropdown] =useState(["All"])
   // List of values for dropdown selection
  const [warehouseDropdown,setWarehouseDropdown] = useState(warehouseNames); //useState(["All", "Wadala","Taloja","Mira"])

  // To track Delivery date
  const prevDeliveryDateRef = useRef(null);

  const [csvData, setCSVData] = useState([]); // State for CSV data
  const [fileName, setFileName] = useState('');  // State for CSV filename

  // Get today as the default date
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().split('T')[0];

 

 
  // API calls
  useEffect(() => {
    if(fetchDataOnClick){
    fetchData(); // Fetch data For rendering when fetchDataOnClick is true
    setFetchDataOnClick(false); // Reset fetchDataOnClick to false after fetching data
    }
  }, [fetchDataOnClick]); // useEffect will run whenever fetchDataOnClick changes

  const fetchData = async () => {
    const deliveryDate = selectedDate || formattedDate;
    setSelectedDate(deliveryDate)

     // set the dropdown
    //  setWarehouseDropdown(["All", "Wadala","Taloja","Mira"])
    setWarehouseDropdown(warehouseNames);

    const route = selectedRoute || 'All';
    const warehouse = selectedWarehouse || 'All';

    const API_url = `${service_url}/api/superzop/get-delivery-stock-check-report?delivery_date=${deliveryDate}&export=false&route=${route}&warehouse=${warehouse}`;

   

    try {

      const response = await fetch(API_url);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      setData(result.data);

      // Routes to Bind According to DeliveryDate
      // Do not make the list again for the same API call
      // helps in maintaining all the route values for that delivery date
      if ( prevDeliveryDateRef.current!== deliveryDate )
      {

        let routeDropdownList=["All"]
            for(let routes of result.data)
            {
              routeDropdownList.push(routes.route)
            }
          
          
          setRouteDropdown(routeDropdownList)
  
      }
      prevDeliveryDateRef.current = deliveryDate;


    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  

  const fetchExportData = async () => {
    const deliveryDate = selectedDate || formattedDate;
    setSelectedDate(deliveryDate)
    
    // By default in the API it is set to ALL
    // const route = selectedRoute || 'All';
    // const warehouse = selectedWarehouse || 'All';
  
    const Export_API_url = `${service_url}/api/superzop/get-delivery-stock-check-report?delivery_date=${deliveryDate}&export=true`;
  
    try {
      const response = await fetch(Export_API_url);
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const result = await response.json();
      setExportData(result.data);
  
      // Create CSV data
      const csvData = [
        [
          'QR ID', 'Delivery Date', 'Item ID', 'Warehouse', 'Route',
          'Picking', 'Loading', 'Transit Transfer', 'Received at Depot',
          'Delivered', 'Stock Check', 'Transit Return', 'Received at DC',
          'Customer Return', 'Replacement Return', 'Stock Conversion', 'Dump'
        ],
        ...result.data.map(item => [
          item.qr_id, item.delivery_date, item.item_id, item.warehouse, item.route,
          item.picking, item.loading, item.transit_transfer, item.received_at_depot,
          item.delivered, item.stock_check, item.transit_return, item.received_at_dc,
          item.customer_return, item.replacement_return, item.stock_conversion, item.dump
        ])
      ];
  
      // Define CSV file name
      const fileName = 'stock_check_report.csv';
  
      // Trigger CSV download
      setCSVData(csvData);
      setFileName(fileName);
  
      // Trigger download
      const csvLink = document.createElement('a');
      csvLink.href = encodeURI('data:text/csv;charset=utf-8,' + csvData.map(row => row.join(',')).join('\n'));
      csvLink.target = '_blank';
      csvLink.download = fileName;
      csvLink.click();
    } catch (error) {
      console.error('Error fetching export data:', error);
    }
  };


  const handleDateChange = (event) => {
    
    setSelectedDate(event.target.value);
    // After Date Change reset Every value
    // Helps in Avoiding Errors
    setSelectedRoute("All")
    setSelectedWarehouse("All")
    setRouteDropdown(["All"])
    setWarehouseDropdown(["All"])

  };

  const handleWarehouseChange = (event) => {
    setSelectedWarehouse(event.target.value);
  };

  const handleRouteChange = (event) => {
    setSelectedRoute(event.target.value);
  };

  const handleGoButtonClick = () => {
    setFetchDataOnClick(true);
  };


  // EXPORT CODE

  const handleExportButtonClick = async () => {

    await fetchExportData(); // Fetch data for Export Data

  }

  //STYLE
  
   // Calculate the width based on the label text length
   const getFormControlWidth = (labelText) => {
    return labelText.length * 8;
  };

  return (
    <> 
    <NavBar/>

          <br />
          <br />
          <br />
          <span></span> {/* for logo, so don't remove the span */}

    <div style={{marginLeft:"8px",marginRight:"5px"}}>
  
     <div style={{ display: 'flex', marginTop:"8px", alignItems: 'center', justifyContent: 'center', height: '10vh' }}>
          <Typography variant="h5" gutterBottom>Delivery Stock Check Report</Typography>
      </div>

      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        {/* Date Selection */}
        <FormControl variant="outlined" style={{ marginRight: '10px' }}>
          <TextField
            type="date"
            id="date"
            onChange={handleDateChange}
            value={selectedDate}
            label="Select Date"
          />
        </FormControl>

        {/* Warehouse Selection */}
       <FormControl variant="outlined" style={{ marginRight: '10px', width: getFormControlWidth('Select Warehouse:') }}>
          <InputLabel id="warehouse-label">Select Warehouse:</InputLabel>
          <Select
            labelId="warehouse-label"
            id="warehouse"
            value={selectedWarehouse}
            onChange={handleWarehouseChange}
            label="Select Warehouse"
          >
            {warehouseDropdown.map((item, index) => (
              <MenuItem key={index} value={item}>{item}</MenuItem>
            ))}
          </Select>
        </FormControl>


        {/* Route Selection */}
        <FormControl variant="outlined" style={{ marginRight: '10px' , width: getFormControlWidth('Select Route:')}}>
          <InputLabel id="route-label">Select Route:</InputLabel>
          <Select
            labelId="route-label"
            id="route"
            value={selectedRoute}
            onChange={handleRouteChange}
            label="Select Route"
          >
            {routeDropdown.map((item, index) => (
              <MenuItem key={index} value={item}>{item}</MenuItem>
            ))}
          </Select>
        </FormControl>
        
        {/* GO Button */}
        <Button variant="contained" onClick={handleGoButtonClick} style={{ marginRight: '10px' }}>Go</Button>
        {/* Export Button */}
        <Button variant="contained" style={{ marginLeft: '20px', backgroundColor: '#32de84' }} onClick={handleExportButtonClick}>Export</Button>
      
      </div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Route</TableCell>
              <TableCell>Number Of Bags Picked</TableCell>
              <TableCell>Number Of Bags Loaded</TableCell>
              <TableCell>Number Of Bags Transferred</TableCell>
              <TableCell>Number Of Bags Delivered</TableCell>
              <TableCell>Number Of Bags Returned To Depot</TableCell>
              <TableCell>Number Of Bags Returned To DC</TableCell>
              <TableCell>Missing Qty</TableCell>
              <TableCell>Additional Bags Returned</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.route}</TableCell>
                <TableCell>{item.number_of_bags_picked}</TableCell>
                <TableCell>{item.number_of_bags_loaded}</TableCell>
                <TableCell>{item.number_of_bags_transferred}</TableCell>
                <TableCell>{item.number_of_bags_delivered}</TableCell>
                <TableCell>{item.number_of_bags_returned_to_depot}</TableCell>
                <TableCell>{item.number_of_bags_returned_to_dc}</TableCell>
                <TableCell>{item.missing_qty}</TableCell>
                <TableCell>{item.additional_bags_returned}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>

    </>
  );
}

export default Main;
