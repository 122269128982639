import React, { useEffect,useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import ReturnOrderListTable from './viewreturnorderstable'
import NavBar from '../Navigation bar/Navbar'
import fire from '../../Configs/firebase'
import Backdrop from '../backdrop/backdrop'
import XLSX from 'xlsx'
import { InputGroupAddon, Input, InputGroupText, CardHeader, CardTitle, CardText } from 'reactstrap';
import {Tab,Row,Col,Nav,Form,Dropdown,ProgressBar,Tabs,Image,InputGroup,Button,DropdownButton,Breadcrumb,Table,ButtonGroup,Container, Accordion,Card, Navbar} from 'react-bootstrap'
import { validateIsAdminUser,validateIsOperationUser } from '../UserCredentials'
import {support_portal_url} from '../../Configs/mysqlconfig';

function formatDate(date) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}


const ViewReturnOrders = () => {

  let userRoutes=sessionStorage.getItem("allowedRoutes")?JSON.parse(sessionStorage.getItem("allowedRoutes")):[];
  console.log(userRoutes);
  let allowedRoutesSet = new Set();

  for(let i=0;i<userRoutes.length;i++){
    let route=`/${userRoutes[i]}`;
    if(!allowedRoutesSet.has(route)){
      allowedRoutesSet.add(route);
    }
  }
  console.log(allowedRoutesSet);

  const [ordersList, setOrdersList] = useState([]);
  const [searchOrdersList, setSearchOrdersList] = useState([]);
  const [exportData,setExportData]=useState(false);
  let selecteddate=new Date();
  selecteddate.setDate(selecteddate.getDate()-1);
  const [ordersListStartDate, setOrdersListStartDate] = useState(formatDate(new Date(selecteddate))+'T00:00');
  const [ordersListEndDate, setOrdersListEndDate] = useState(formatDate(new Date())+'T23:59');

  const [backDrop, setBackDrop] = useState(false);


  useEffect(() => {
    setBackDrop(true);
    let curdate=formatDate(new Date());
    console.log(ordersListStartDate);
    console.log(ordersListEndDate);
    let orderstartdatelist=ordersListStartDate.split('T');
    let orderenddatelist=ordersListEndDate.split('T');


    let apiurlstr=`${support_portal_url}/api/viewreturnorders?startDate=${curdate}T00%3A00&endDate=${curdate}T23%3A59&token=${sessionStorage.getItem("apiToken")}`;
    console.log(apiurlstr);
    fetch(apiurlstr)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      if(result['success']==1){
        let tempOrdersList=result['data'];
        let finalOrdersList=[];
        for(let i=0;i<tempOrdersList.length;i++){
          let ele=tempOrdersList[i];
          let order_number=tempOrdersList[i]['order_number'];
          // console.log(order_number);
          let parentordernumber=order_number.split("-")[0]+"/"+order_number.split("/")[1];
          ele['parent_order_number']=parentordernumber;
          finalOrdersList.push(ele);
        }
        setBackDrop(false);
        setOrdersList(finalOrdersList)
        setSearchOrdersList(finalOrdersList)
      }else{
        setBackDrop(false);
        setOrdersList([])
        setSearchOrdersList([])

        let errMessage=result['message']?result['message']:"";
        alert(errMessage);
        if(result['status']==401 || result['status']==403){
          sessionStorage.clear();
          window.location.href="/"
        }
      }
    })
    .catch((error) => {
      alert(error)
      setBackDrop(false);
      setOrdersList([]);
      setSearchOrdersList([]);
    });
  }, [])

  useEffect(() => {
    if(exportData){
      exportReturnedOrders();
    }
  }, [exportData])

  const exportReturnedOrders = () => {
      const wb = XLSX.utils.book_new()

      setBackDrop(true);
      let returnOrderSummary = [["Parent OrderNumber","OrderNumber", "Retailer Id", "OrderValue", "ShopName", "ShopPhoneNumber","OrderDate","Status","Reason"]];

      ordersList.map(ord => {
          let returnOrderDetails = [];
          returnOrderDetails.push(ord.parent_order_number)
          returnOrderDetails.push(ord.order_number)
          returnOrderDetails.push(ord.retailer_id)
          returnOrderDetails.push(ord.net_order_amt)
          returnOrderDetails.push(ord.shop_name)
          returnOrderDetails.push(ord.user_name)
          returnOrderDetails.push(ord.order_date_conv)
          returnOrderDetails.push(ord.status)
          returnOrderDetails.push(ord.reason)
          returnOrderSummary.push(returnOrderDetails)

      })
      const returnOrderExportDetails = XLSX.utils.aoa_to_sheet(returnOrderSummary)

      let wscols = [
          {wch:20},
          {wch:15},
          {wch:15},
          {wch:30},
          {wch:20},
          {wch:20},
          {wch:15},
          {wch:25}
      ];

      returnOrderExportDetails['!cols'] = wscols;

      XLSX.utils.book_append_sheet(wb, returnOrderExportDetails, `Return Orders List`)
      XLSX.writeFile(wb, `Return_Orders_List.xlsx`);
      setBackDrop(false);
      setExportData(false);
  }


  const fetchAddedReturnOrders = () => {
    console.log(ordersListStartDate);
    console.log(ordersListEndDate);
    setBackDrop(true);
    let orderlistformstartdate=ordersListStartDate;
    let orderlistformenddate=ordersListEndDate;

    let apiurlstr=`${support_portal_url}/api/viewreturnorders?token=${sessionStorage.getItem("apiToken")}&`;
    if(orderlistformstartdate){
      apiurlstr+='startDate='
      let orderlistformstartdt=orderlistformstartdate.split('T');
      apiurlstr+=orderlistformstartdt[0];
      apiurlstr+='T';
      let orderlistformstartdatetime=orderlistformstartdt[1].split(':');
      apiurlstr+=orderlistformstartdatetime[0];
      apiurlstr+='%3A'
      apiurlstr+=orderlistformstartdatetime[1];
    }

    if(orderlistformenddate){
      apiurlstr+='&endDate='
      let orderlistformenddt=orderlistformenddate.split('T');
      apiurlstr+=orderlistformenddt[0];
      apiurlstr+='T';
      let orderlistformenddatetime=orderlistformenddt[1].split(':');
      apiurlstr+=orderlistformenddatetime[0];
      apiurlstr+='%3A'
      apiurlstr+=orderlistformenddatetime[1];
    }

    console.log(apiurlstr);

    fetch(apiurlstr)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      if(result['success']==1){
        let tempOrdersList=result['data'];
        let finalOrdersList=[];
        for(let i=0;i<tempOrdersList.length;i++){
          let ele=tempOrdersList[i];
          let order_number=tempOrdersList[i]['order_number'];
          let parentordernumber=order_number.split("-")[0]+"/"+order_number.split("/")[1];
          ele['parent_order_number']=parentordernumber;
          finalOrdersList.push(ele);
        }
        setBackDrop(false);
        setOrdersList(finalOrdersList)
        setSearchOrdersList(finalOrdersList)
      }else{
        setBackDrop(false);
        setOrdersList([])
        setSearchOrdersList([])

        let errMessage=result['message']?result['message']:"";
        alert(errMessage);
        if(result['status']==401 || result['status']==403){
          sessionStorage.clear();
          window.location.href="/"
        }
      }
    })
    .catch((error) => {
      alert(error)
      setBackDrop(false);
      setOrdersList([]);
      setSearchOrdersList([]);
    });
  }

  const syncOrdersNewTable = () => {
    console.log('syncOrdersNewTable');
    fetch("http://services.superzop.com:3000/api/superzop/admin/saveordersnewtablesql",
    { headers: { 'x-access-token' : sessionStorage.getItem('x-access-token') } })
    .then((response) => {
      alert("Synced Orders New table");
      setTimeout(function(){
         window.location.reload();
      }, 2000);
    }).catch((error) => {
      alert("Error : "+error);
    });
  }



    return (<>
      <NavBar/>
      <div className="container col-sm-12" style={{paddingTop:100}}>
        <center><h2 className="h2">Orders List</h2></center>
        <div className="row" style={{float: "right"}}>
          <Button style={{margin:10}}  size="sm" variant="dark" onClick={()=>{ syncOrdersNewTable(); }}>Sync</Button>
          <Button style={{margin:10}}  size="sm" variant="warning" onClick={()=>{ setExportData(true); }}>Export</Button>
          {(allowedRoutesSet.has(`/deliveryschedules`) || validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email))) && <Button style={{margin:10}} size="sm" variant="warning" onClick={()=>{window.open("/deliveryschedules", "_blank")}}>DeliverySchedules</Button>}
        </div>
        <div className="row" style={{width: "50%",marginLeft: "auto", marginRight: "auto", marginTop:10}}>
          <div className="col" style={{marginLeft: "auto", marginRight: "auto",margin:10}}>
            <label htmlFor="orderliststartdate" className="control-label">StartDate</label>
            <input type="datetime-local" className="form-control" value={ordersListStartDate} onChange={(e)=>{setOrdersListStartDate(e.target.value)}} />
          </div>
          <div className="col" style={{marginLeft: "auto", marginRight: "auto",margin:10}}>
            <label htmlFor="orderlistenddate" className="control-label">EndDate</label>
            <input type="datetime-local" className="form-control" value={ordersListEndDate} onChange={(e)=>{setOrdersListEndDate(e.target.value)}} />
          </div>
        </div>
        <div className="row" style={{width: "10%" , marginLeft: "auto", marginRight: "auto", marginTop:10}}>
          <input type="button" style={{marginLeft: "auto", marginRight: "auto",margin:10}} className="btn btn-primary" value="View Orders" onClick={()=>{fetchAddedReturnOrders(); }} />
        </div>

        <ReturnOrderListTable orderListData={ordersList} />
        {backDrop?(<Backdrop parentLoadStatus={backDrop}/>):(null)}
      </div></>
    )
}

export default ViewReturnOrders
