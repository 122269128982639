export const redHighlightColor = "rgba(255, 0, 0, 0.55)";
export const greenHighlightColor = "rgba(0, 255, 0, 0.55)";
export const orangeHighlightColor = "rgba(255, 183, 2, 0.737)";

export const sortByTypeCategoryItemId = (data) => {
  const sortedData = data;
  sortedData.sort(function (a, b) {
    if (
      a.type === "" ||
      b.type === "" ||
      a.category === "" ||
      b.category === "" ||
      a.item_id === "" ||
      b.item_id === ""
    ) {
      return 0;
    }
    if (a.type < b.type || a.category < b.category || a.item_id < b.item_id) {
      return -1;
    }
    if (a.type > b.type || a.category > b.category || a.item_id > b.item_id) {
      return 1;
    }
    return 0;
  });

  return sortedData;
};
