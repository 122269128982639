import React, { useState, useEffect, useRef, memo } from "react";
import {
  Table,
  Form,
  Alert,
  Button,
  Modal,
  Row,
  Col,
  Toast,
} from "react-bootstrap";
import {
  Button as MantineButton,
  MultiSelect,
  Select as MantineSelect,
} from "@mantine/core";

import { service_url, token } from "../../Configs/mysqlconfig";
import moment from "moment";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";

import Select from "react-select";
import {
  redHighlightColor,
  greenHighlightColor,
  sortByTypeCategoryItemId,
} from "../Inventory/common/constants";

import Backdrop from "../backdrop/backdrop";
import styled from "styled-components";
import QRSelectionDrawer from "./QRSelectionDrawer";
const GLOBAL_PARENT_WAREHOUSE = "Bhiwandi";
const userCity = sessionStorage.getItem("userCity") || "";
const userType = sessionStorage.getItem("userType") || "";

const DateSelector = styled(DatePicker)`
  margin-left: 1rem;
  margin-right: 1rem;
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CenterText = styled.div`
  text-align: center;
`;

function TransitReturnCreationModal({
  transitID,
  warehouse,
  returnDate,
  freshReturnCreationItems,
  ...props
}) {
  const [selectedEntryIndex, setSelectedEntryIndex] = useState(null);
  const [isQREntryDrawerDisplayed, setIsQREntryDrawerDisplayed] =
    useState(false);
  const [receivingDate, setReceivingDate] = useState();
  const [isCreationAllowed, setIsCreationAllowed] = useState(false);
  const [currentSelectedRoute, setCurrentSelectedRoute] = useState("");
  const [overallReturnSummaryDetails, setOverallReturnSummaryDetails] =
    useState([]);
  const [overallLooseSummaryDetails, setOverallLooseSummaryDetails] = useState(
    []
  );

  const [selectedItemId, setSelectedItemId] = useState("");
  const [addItemBagId, setAddItemBagId] = useState("");
  const [addItemOrderNo, setAddItemOrderNo] = useState("");
  const [addItemDetailsEntryDisabled, setAddItemDetailsEntryDisabled] =
    useState(true);
  const [type, setType] = useState("GT");
  const [backDrop, setBackDrop] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const productMasterDataRaw = props.productMasterDataRaw;
  const productMasterDataRawFresh = props.productMasterDataRawFresh;
  const addTransitItemDropdownList = Object.keys(productMasterDataRaw).map(
    (key, index) => {
      const product = productMasterDataRaw[key];
      const item_id = product.item_id;
      const item_description = product.item_description1 || "";
      const brand = product.brand || "";
      const item = {
        value: item_id,
        label: `${item_id}-${brand}-${item_description}`,
      };
      return item;
    }
  );
  const addTransitItemDropdownListFresh = Object.keys(productMasterDataRawFresh)
    .filter((key, index) => {
      if (!productMasterDataRaw[key]?.category) {
        return false;
      }
      return productMasterDataRaw[key].category === "FRESH";
    })
    .map((key, index) => {
      const product = productMasterDataRawFresh[key];
      const item_id = product.item_id;
      const item_description = product.item_description1 || "";
      const brand = product.brand || "";
      const item = {
        value: item_id,
        label: `${item_id}-${brand}-${item_description}`,
      };
      return item;
    });

  // console.log("check proMaster", productMasterDataRaw);

  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });

  const init = () => {
    if (!type || !warehouse) return;
    setIsLoading(true);

    let url = `${service_url}/api/system-stock?type=${type}&warehouse=${warehouse}&token=${token}&delivery_date=${moment(
      returnDate
    ).format("YYYY-MM-DD")}`;

    fetch(url, {
      headers: { "x-access-token": sessionStorage.getItem("x-access-token") },
    })
      .then((res) => res.json())
      .then((result) => {
        let itemsData = result.data || [];
        let bag_items = result.bag_data || [];

        if (type === "Fresh") {
          itemsData = freshReturnCreationItems[warehouse] || [];
        }

        let finalItemsData = [];
        for (let item of itemsData) {
          let stock_return_qty = Math.round(item.stock_return_qty) || 0;
          item.received_remarks = "";
          if (stock_return_qty > 0) {
            item.stock_return_qty = Math.round(item.stock_return_qty);

            item.selected_qr_ids = [];
            finalItemsData.push(item);
          }
        }
        let finalBagItemsData = [];
        for (let item of bag_items) {
          let stock_return_qty = Math.round(item.stock_return_qty) || 0;
          item.received_remarks = "";
          if (stock_return_qty > 0) {
            item.stock_return_qty = Math.round(item.stock_return_qty);
            item.selected_qr_ids = [];
            finalBagItemsData.push(item);
          }
        }

        finalItemsData = sortByTypeCategoryItemId(finalItemsData);

        setIsLoading(false);
        setOverallReturnSummaryDetails(finalItemsData);
        setOverallLooseSummaryDetails(finalBagItemsData);
      })
      .catch((err) => {
        toast(`Error in fetching items: ${err}`, { type: toast.TYPE.ERROR });
      });
  };

  useEffect(() => {
    init();
    setReceivingDate(new Date());
  }, []);

  useEffect(() => {
    init();
    setSelectedItemId({});
    checkIfReturnCanBeCreated();
  }, [type]);

  useEffect(() => {
    if (!selectedItemId || !selectedItemId.value) {
      setAddItemBagId("");
      setAddItemOrderNo("");
      setAddItemDetailsEntryDisabled(true);
      return;
    }

    try {
      const item_id = selectedItemId.value;
      const item = productMasterDataRaw[item_id];

      const variety = item.variety ? item.variety.toLowerCase() : "";

      console.log("check variety", variety);
      if (isLoosePackItem(variety)) {
        setAddItemDetailsEntryDisabled(false);
      } else {
        setAddItemBagId("");
        setAddItemOrderNo("");
        setAddItemDetailsEntryDisabled(true);
      }
    } catch (e) {
      console.log(e);
    }
  }, [selectedItemId]);

  const checkIfReturnCanBeCreated = () => {
    // const date = moment(new Date(returnDate)).format("YYYY-MM-DD");

    if (!warehouse || !type) {
      setIsCreationAllowed(false);
      return;
    }

    let url = `${service_url}/api/return-received-updated?src=${warehouse}&destination=Bhiwandi&type=${type}&token=${token}`;

    fetch(url, {
      headers: { "x-access-token": sessionStorage.getItem("x-access-token") },
    })
      .then((res) => res.json())
      .then((result) => {
        const { success = 0, data = "N" } = result || "N";

        if (success && data === "Y") setIsCreationAllowed(true);
        else setIsCreationAllowed(false);
      });
  };

  const isLoosePackItem = (variety) => {
    if (!variety) return false;
    variety = variety.toLowerCase();
    if (variety === "small pack") {
      return true;
    }
    return false;
  };

  const autoFillTableData = () => {
    setIsLoading(true);

    const _overallReturnSummaryDetails = [...overallReturnSummaryDetails];
    overallReturnSummaryDetails.forEach((item) => {
      // if (item.stock_return_qty) {
      //   item.return_qty = item.return_qty ?? item.stock_return_qty;
      // }
      if (item.qr_ids.length > 0) {
        item.selected_qr_ids = [...item.qr_ids];
        item.return_qty = item.selected_qr_ids.length;

        if (Number(item.return_qty) !== Number(item.stock_return_qty)) {
          item.bgColor = redHighlightColor;
        }
      } else if (item.stock_return_qty) {
        item.return_qty = item.return_qty ?? item.stock_return_qty;
      }
    });
    const _overallLooseSummaryDetails = [...overallLooseSummaryDetails];
    overallLooseSummaryDetails.forEach((item) => {
      if (item.qr_ids.length > 0) {
        item.selected_qr_ids = [...item.qr_ids];
        item.return_qty = item.stock_return_qty;

        if (Number(item.return_qty) !== Number(item.stock_return_qty)) {
          item.bgColor = redHighlightColor;
        }
      } else if (item.stock_return_qty) {
        item.return_qty = item.return_qty ?? item.stock_return_qty;
      }
    });

    setIsLoading(false);
    setOverallReturnSummaryDetails(_overallReturnSummaryDetails);
    setOverallLooseSummaryDetails(_overallLooseSummaryDetails);
    toast(`Auto fill successful !`, { type: toast.TYPE.SUCCESS });
  };

  const itemAlreadyInList = (item_id) => {
    let existingItems = overallReturnSummaryDetails;
    let itemAlreadyPresent = false;
    existingItems.forEach((item, index) => {
      if (Number(item_id) === Number(item.item_id)) {
        itemAlreadyPresent = true;
      }
    });

    existingItems = overallLooseSummaryDetails;
    existingItems.forEach((item, index) => {
      if (Number(item_id) === Number(item.item_id)) {
        itemAlreadyPresent = true;
      }
    });

    return itemAlreadyPresent;
  };

  const addItemToSummaryDetails = (item_id) => {
    if (!item_id) {
      return console.log(" Error:  Missing arguements ");
    }

    if (!type) {
      toast(`Please select type`, {
        type: toast.TYPE.WARNING,
      });
      return;
    }

    if (itemAlreadyInList(item_id)) {
      toast(`This item is already present in the list`, {
        type: toast.TYPE.WARNING,
      });
      return;
    }

    if (!addItemDetailsEntryDisabled) {
      if (!addItemOrderNo) {
        toast(`Please enter order no for loose pack items`, {
          type: toast.TYPE.WARNING,
        });
        return;
      }

      if (!addItemBagId) {
        toast(`Please enter Bag Id for loose pack items`, {
          type: toast.TYPE.WARNING,
        });
        return;
      }

      if (!/^\d{5}\/\d{7}$/.test(addItemOrderNo)) {
        toast(`Invalid order id entered`, {
          type: toast.TYPE.WARNING,
        });
        return;
      }
      if (!/^[A-Za-z0-9]{5}-\d-\d$/.test(addItemBagId)) {
        toast(`Invalid bag id entered`, {
          type: toast.TYPE.WARNING,
        });
        return;
      }
    }

    const item = productMasterDataRaw[item_id];

    if (!item) {
      return console.log("Error : Unable to retrieve product info");
    }
    const category = item.category || "";
    const brand = item.brand || "";
    const variety = item.variety || "";
    const sub_category = item.sub_category;
    const long_disc = item.long_description || "";
    const item_disc = `${brand}-${long_disc}`;
    const return_qty = 1;
    const received_remarks = "";
    const return_date = moment(returnDate).format("YYYY-MM-DD");

    const itemToAdd = {
      item_id,
      category,
      variety,
      brand,
      return_qty,
      warehouse,
      sub_category,
      item_disc,
      return_date,
      received_remarks,
      bgColor: greenHighlightColor,
      selected_qr_ids: [],
      qr_ids: [],
      isNewItem: true,
    };

    //If loose pack item is added then also directly insert order no and bag id
    if (isLoosePackItem(variety)) {
      itemToAdd.order_number = addItemOrderNo;
      itemToAdd.bag_id = addItemBagId;
    }
    console.log("ready to add data", itemToAdd);

    toast(`Successfully added new item`, {
      type: toast.TYPE.SUCCESS,
    });

    //If loose pack insert to loose pack table
    if (isLoosePackItem(variety)) {
      const existingLooseSummaryDetails = [...overallLooseSummaryDetails];
      existingLooseSummaryDetails.push(itemToAdd);
      setOverallLooseSummaryDetails(existingLooseSummaryDetails);
      setSelectedItemId({});
      setAddItemBagId("");
      setAddItemBagId("");
      return;
    }

    const existingRouteSummaryDetails = [...overallReturnSummaryDetails];
    existingRouteSummaryDetails.push(itemToAdd);
    setOverallReturnSummaryDetails(existingRouteSummaryDetails);
    setSelectedItemId({});
  };

  const removeItemFromSummaryDetails = (item_id, route, transit_id) => {
    return new Promise((resolve, reject) => {
      let url = `${service_url}/api/transit-route-item?token=${token}`;
      const data = { item_id, route, transit_id };
      // console.log("check data", data);

      fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": sessionStorage.getItem("x-access-token"),
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          toast(`Successfully removed item`, {
            type: toast.TYPE.SUCCESS,
          });

          resolve();
        })
        .catch((err) => {
          toast(`Unable to remove item`, {
            type: toast.TYPE.ERROR,
          });
          reject(err);
        });
    });
  };

  const updateApprovedItemsQty = () => {
    let itemsList = [];
    for (let returnItem of overallReturnSummaryDetails) {
      let item_id = returnItem.item_id;
      let inventory = returnItem.stock_return_qty || null;

      const return_qty = returnItem.return_qty;
      const stock_return_qty = returnItem.stock_return_qty || 0;
      if (return_qty < 0) {
        return toast(`Return quantity cannot be negative`, {
          type: toast.TYPE.ERROR,
        });
      }

      if (returnItem.isNewItem && return_qty <= 0) {
        return toast(
          `Return quantity cannot be less than 1 for newly added items`,
          {
            type: toast.TYPE.ERROR,
          }
        );
      }

      const received_remarks = returnItem.received_remarks.trim();

      if (
        Number(stock_return_qty) !== Number(return_qty) &&
        !received_remarks
      ) {
        return toast(`Please enter remarks for entries with discrepancy`, {
          type: toast.TYPE.ERROR,
        });
      }

      // if (!returnItem.isNewItem && return_qty > stock_return_qty) {
      //   return toast(`${item_id} Return qty can't be more than inventory`, {
      //     type: toast.TYPE.ERROR,
      //   });
      // }

      if (!receivingDate) {
        return toast(`Please select a receiving date`, {
          type: toast.TYPE.ERROR,
        });
      }

      let item_disc = returnItem.item_disc || "";
      let category = returnItem.category || "";
      let variety = returnItem.variety || "";
      const item_modified_qty = returnItem.item_modified_qty || "";
      const item_delivered_qty = returnItem.delivered_qty || "";
      const stock_transfer_qty = returnItem.item_qty || "";
      const qr_ids = returnItem.selected_qr_ids || [];
      itemsList.push({
        item_modified_qty,
        item_delivered_qty,
        stock_transfer_qty,
        item_id,
        inventory,
        received_remarks,
        return_qty,
        item_disc,
        category,
        variety,
        qr_ids: qr_ids,
      });
    }

    for (let looseItem of overallLooseSummaryDetails) {
      let item_id = looseItem.item_id;
      let inventory = looseItem.stock_return_qty || null;
      const stock_return_qty = looseItem.stock_return_qty;
      if (looseItem.return_qty !== 0 && !looseItem.return_qty) {
        return toast(`Please enter return quantity for all loose items`, {
          type: toast.TYPE.WARNING,
        });
      }
      let return_qty = looseItem.return_qty ? Number(looseItem.return_qty) : 0;
      if (return_qty < 0) {
        return toast(`Return quantity cannot be negative`, {
          type: toast.TYPE.ERROR,
        });
      }

      const received_remarks = looseItem.received_remarks.trim();

      if (
        Number(stock_return_qty) !== Number(return_qty) &&
        !received_remarks
      ) {
        return toast(`Please enter remarks for entries with discrepancy`, {
          type: toast.TYPE.ERROR,
        });
      }

      // if (return_qty > stock_return_qty) {
      //   return toast(`${item_id} Return qty can't be more than inventory`, {
      //     type: toast.TYPE.ERROR,
      //   });
      // }
      let item_disc = looseItem.item_disc || "";
      let category = looseItem.category || "";
      let variety = looseItem.variety || "";
      let bag_id = looseItem.bag_id || "";
      let order_number = looseItem.order_number || "";
      const qr_ids = looseItem.selected_qr_ids || [];
      itemsList.push({
        item_id,
        inventory,
        return_qty,
        item_disc,
        category,
        received_remarks,
        variety,
        bag_id,
        order_number,
        qr_ids: qr_ids,
      });
    }

    let url = `${service_url}/api/transit-stock-return?token=${token}`;

    let data = {
      routeItemsData: itemsList,
      source: warehouse,
      destination: "Bhiwandi",
      return_date: moment(returnDate).format("YYYY-MM-DD"),
      delivery_date: moment(returnDate).format("YYYY-MM-DD"),
      receiving_date: moment(receivingDate).format("YYYY-MM-DD"),
      created_by: JSON.parse(sessionStorage.getItem(`user`))[0].email,
      created_at: new Date().toJSON().slice(0, 19).replace("T", " "),
      type,
    };

    console.log("check data", data);
    setIsLoading(true);
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": sessionStorage.getItem("x-access-token"),
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success == 1) {
          toast(`${result.message}`, { type: toast.TYPE.SUCCESS });
          setIsLoading(false);
          props.onHide();
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        } else {
          toast(`${result.message}`, { type: toast.TYPE.ERROR });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast(`Error in creating transit route records: ${err}`, {
          type: toast.TYPE.ERROR,
        });
      });
  };

  return (
    <>
      <Modal
        {...props}
        id="transitReturnModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ fontSize: 6 }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Create transit return
          </Modal.Title>

          <DateContainer>
            <CenterText>Receiving Date</CenterText>
            <DateSelector
              minDate={new Date()}
              selected={receivingDate}
              onChange={setReceivingDate}
              // dateFormat="dd/MM/yyyy"
            />
          </DateContainer>

          <div style={{ minWidth: "10rem", marginTop: "-.5rem" }}>
            {"Type"}
            <Select
              isDisabled={true}
              value={{ value: "GT", label: "GT" }}
              options={[
                { value: "GT", label: "GT" },
                { value: "Fresh", label: "Fresh" },
              ]}
              onChange={(val) => {
                // setType(val.value);
              }}
            />
          </div>

          <MantineButton
            style={{ marginLeft: "1rem", alignSelf: "flex-end" }}
            compact
            variant="gradient"
            gradient={{ from: "orange", to: "red" }}
            color="yellow"
            onClick={() => {
              autoFillTableData();
            }}
          >
            Auto fill return qty
          </MantineButton>
        </Modal.Header>

        <Modal.Body style={{ paddingLeft: 10, paddingRight: 10, fontSize: 12 }}>
          {overallReturnSummaryDetails.length > 0 ||
          overallLooseSummaryDetails.length > 0 ? (
            <>
              <div>
                <OverallTransitReturnSummaryTable
                  productMasterDataRaw={productMasterDataRaw}
                  overallReturnSummaryDetails={overallReturnSummaryDetails}
                  overallLooseSummaryDetails={overallLooseSummaryDetails}
                  setOverallLooseSummaryDetails={setOverallLooseSummaryDetails}
                  currentSelectedRoute={currentSelectedRoute}
                  removeItemFromSummaryDetails={removeItemFromSummaryDetails}
                  setOverallReturnSummaryDetails={
                    setOverallReturnSummaryDetails
                  }
                  init={init}
                  setBackDrop={setBackDrop}
                  setSelectedEntryIndex={setSelectedEntryIndex}
                  setIsQREntryDrawerDisplayed={setIsQREntryDrawerDisplayed}
                />
              </div>
            </>
          ) : (
            ""
          )}

          {userType === "Admin" || userCity !== GLOBAL_PARENT_WAREHOUSE ? (
            <>
              <Row
                style={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Col style={{ width: "150%", flex: 2 }}>
                  <Select
                    options={
                      type === "GT"
                        ? addTransitItemDropdownList
                        : addTransitItemDropdownListFresh
                    }
                    value={selectedItemId}
                    onChange={(val) => {
                      setSelectedItemId(val);
                    }}
                    isClearable={true}
                  />
                </Col>
                <Col>
                  <div className="input-group">
                    <div className="custom-file">
                      <Form.Control
                        disabled={addItemDetailsEntryDisabled}
                        placeholder="Order No"
                        value={addItemOrderNo}
                        onChange={(e) => {
                          setAddItemOrderNo(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="input-group">
                    <Form.Control
                      placeholder="Bag id"
                      disabled={addItemDetailsEntryDisabled}
                      value={addItemBagId}
                      onChange={(e) => {
                        setAddItemBagId(e.target.value);
                      }}
                    />
                  </div>
                </Col>

                <Col className="col-sm" style={{ width: "2rem" }}>
                  <Button
                    onClick={() => {
                      if (selectedItemId && selectedItemId.value) {
                        addItemToSummaryDetails(selectedItemId.value);
                      } else {
                        toast(`Please select a valid item id from the list`, {
                          type: toast.TYPE.WARNING,
                        });
                      }
                    }}
                  >
                    Add item
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer>
          {userType === "Admin" || userCity !== "Bhiwandi" ? (
            <Button
              disabled={isLoading}
              onClick={() => {
                if (!isCreationAllowed) {
                  toast(
                    "Error : Received qty not entered for previous date, cannot create return",
                    {
                      type: toast.TYPE.WARNING,
                    }
                  );
                  return;
                }

                updateApprovedItemsQty();
              }}
            >
              Create
            </Button>
          ) : null}
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>

      {isLoading ? <Backdrop parentLoadStatus={isLoading} /> : null}
    </>
  );
}

const submitNewItemToAPI = (data) => {
  return new Promise((resolve, reject) => {
    if (!data) {
      console.log("Did not receive required arguements");
      reject();
      return;
    }

    let url = `${service_url}/api/return-transit-route-item?token=${token}`;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": sessionStorage.getItem("x-access-token"),
      },
      body: JSON.stringify(data),
    }).then((res) => {
      // console.log("check response", res.body);
      resolve();
    });
  });
};

const TransitRoutesTableHeader = () => {
  return (
    <thead className="thead-light">
      <tr>
        <th>Item ID</th>
        <th>Item description</th>
        <th>Type</th>
        <th>Category</th>
        <th>Shipped Qty</th>
        <th>Delivered Qty</th>
        <th>Stock Transfer Qty</th>
        <th>Customer Returns</th>
        <th>Inventory</th>
        {userType === "Admin" || userCity !== GLOBAL_PARENT_WAREHOUSE ? (
          <>
            <th>Return Qty</th>
            <th>Add QR</th>
            <th>Remarks</th>
          </>
        ) : null}
      </tr>
    </thead>
  );
};

const TransitRoutesTableBody = ({
  routeProductData,
  overallReturnSummaryDetails,
  setOverallReturnSummaryDetails,
}) => {
  let itemsListData = routeProductData;

  const modifySummaryDetails = function (data, index) {
    setOverallReturnSummaryDetails((prevState) => {
      let _routeSumDetails = [...prevState];
      _routeSumDetails[index] = { ...data };

      return _routeSumDetails;
    });
  };

  let rows = itemsListData.map((row, index) => {
    // console.log("data", { ...overallReturnSummaryDetails[index] }, "row", row);
    return (
      <TransitRoutesTableRow
        key={index}
        index={index}
        row={{ ...row }}
        data={{ ...overallReturnSummaryDetails[index] }}
        modifySummaryDetails={modifySummaryDetails}
      />
    );
  });

  return (
    <>
      <tbody>{rows}</tbody>
    </>
  );
};

const TransitRoutesTableRow = memo(
  ({ index, row, data, modifySummaryDetails }) => {
    return (
      <tr
        key={index}
        style={{ backgroundColor: row.bgColor ? row.bgColor : "" }}
      >
        <td>{row.item_id}</td>
        <td>{`${row.item_disc} - ${row.brand || ""}`}</td>
        <td>{row.variety}</td>
        <td>{row.category}</td>
        <td>{row.item_modified_qty}</td> {/*shipped qty*/}
        <td>{row.delivered_qty}</td>
        <td>{row.item_qty}</td> {/* Stock Transfer Qty*/}
        <td>{row.returned_qty}</td> {/*Customer Returns*/}
        <td>{row.stock_return_qty}</td> {/*Inventory */}
        {/* {row.variety !== "BAG" ? ( */}
        <td>
          <Form.Control
            type="number"
            value={row.return_qty}
            onBlur={(e) => {
              if (row.return_qty === "") {
                e.target.value = "0";
              }
            }}
            onChange={(e) => {
              let _routeSumDetails = data;
              _routeSumDetails.return_qty = e.target.value;

              if (
                "stock_return_qty" in _routeSumDetails &&
                _routeSumDetails.return_qty !==
                  _routeSumDetails.stock_return_qty.toString()
              ) {
                _routeSumDetails.bgColor = redHighlightColor;
              } else if (
                "stock_return_qty" in _routeSumDetails &&
                _routeSumDetails.return_qty ===
                  _routeSumDetails.stock_return_qty.toString()
              ) {
                _routeSumDetails.bgColor = "white";
              }

              modifySummaryDetails(_routeSumDetails, index);
            }}
          />
        </td>
        {/* ) : (
        <td>{row.return_qty}</td>
      )} */}
        <td style={{ width: 280 }}>
          <MultiSelect
            styles={{
              item: { fontSize: 12 },
              dropdown: { backgroundColor: "#d3dbd5" },
            }}
            // disabled={row.variety !== "BAG"}
            value={row.selected_qr_ids}
            onChange={(val) => {
              let _routeSumDetails = data;
              _routeSumDetails.selected_qr_ids = [...val];
              _routeSumDetails.return_qty = val.length;

              if (
                "stock_return_qty" in _routeSumDetails &&
                Number(_routeSumDetails.return_qty) !==
                  Number(_routeSumDetails.stock_return_qty)
              ) {
                _routeSumDetails.bgColor = redHighlightColor;
              } else if (
                "stock_return_qty" in _routeSumDetails &&
                Number(_routeSumDetails.return_qty) ===
                  Number(_routeSumDetails.stock_return_qty)
              ) {
                _routeSumDetails.bgColor = "white";
              }

              modifySummaryDetails(_routeSumDetails, index);
            }}
            name="qr_id_selection"
            data={row.qr_ids.map((item) => {
              return { label: item, value: item };
            })}
            placeholder="Pick one"
            nothingFound="No options"
            searchable
            zIndex={999999999999}
          />
        </td>
        <td>
          <Form.Control
            type="text"
            value={row.received_remarks}
            onChange={(e) => {
              let _routeSumDetails = data;
              _routeSumDetails.received_remarks = e.target.value;

              modifySummaryDetails(_routeSumDetails, index);
            }}
          />
        </td>
      </tr>
    );
  },
  (prevProps, nextProps) => {
    return JSON.stringify(prevProps.row) === JSON.stringify(nextProps.row);
  }
);

const TransitRoutesTable = ({
  routeProductData,
  overallReturnSummaryDetails,
  currentSelectedRoute,
  setOverallReturnSummaryDetails,
  removeItemFromSummaryDetails,
  transitID,
  init,
  setSelectedEntryIndex,
  setIsQREntryDrawerDisplayed,
}) => {
  return (
    <>
      <table className="table table-striped">
        <TransitRoutesTableHeader />
        <TransitRoutesTableBody
          routeProductData={routeProductData}
          overallReturnSummaryDetails={overallReturnSummaryDetails}
          currentSelectedRoute={currentSelectedRoute}
          setOverallReturnSummaryDetails={setOverallReturnSummaryDetails}
          removeItemFromSummaryDetails={removeItemFromSummaryDetails}
          init={init}
          transitID={transitID}
          setSelectedEntryIndex={setSelectedEntryIndex}
          setIsQREntryDrawerDisplayed={setIsQREntryDrawerDisplayed}
        />
      </table>
    </>
  );
};

const TransitReturnLooseTableHeader = () => {
  return (
    <thead className="thead-light">
      <tr>
        <th>Order No</th>
        <th>Bag ID</th>
        <th>Item ID</th>
        <th>Item description</th>
        <th>Type</th>
        <th>Category</th>
        <th>Customer Returns</th>
        <th>Inventory</th>
        {userType === "Admin" || userCity !== GLOBAL_PARENT_WAREHOUSE ? (
          <>
            <th>Return Qty</th>
            <th>Add QR</th>
            <th>Remarks</th>
          </>
        ) : null}
      </tr>
    </thead>
  );
};

const TransitReturnLooseTableBody = ({
  routeProductData,
  overallReturnSummaryDetails,
  setOverallReturnSummaryDetails,
}) => {
  let itemsListData = routeProductData;

  const modifySummaryDetails = function (data, index) {
    setOverallReturnSummaryDetails((prevState) => {
      let _routeSumDetails = [...prevState];
      _routeSumDetails[index] = { ...data };

      return _routeSumDetails;
    });
  };

  let rows = itemsListData.map((row, index) => {
    return (
      <TransitReturnLooseTableRow
        key={index}
        index={index}
        row={{ ...row }}
        data={{ ...overallReturnSummaryDetails[index] }}
        modifySummaryDetails={modifySummaryDetails}
      />
    );
  });

  return (
    <>
      <tbody>{rows}</tbody>
    </>
  );
};

const TransitReturnLooseTableRow = memo(
  ({ row, index, data, modifySummaryDetails }) => {
    return (
      <tr
        key={index}
        style={{ backgroundColor: row.bgColor ? row.bgColor : "" }}
      >
        <td>{row.order_number}</td>
        <td>{row.bag_id}</td>
        <td>{row.item_id}</td>
        <td>{`${row.item_disc} - ${row.brand || ""}`}</td>
        <td>{row.variety}</td>
        <td>{row.category}</td>
        <td>{row.returned_qty}</td>
        <td>{row.stock_return_qty}</td>
        {/* <td>{row.return_qty}</td> */}

        <td>
          <Form.Control
            type="number"
            value={row.return_qty}
            onBlur={(e) => {
              if (row.return_qty === "") {
                e.target.value = "0";
              }
            }}
            onChange={(e) => {
              let _routeSumDetails = data;
              _routeSumDetails.return_qty = e.target.value;

              if (
                "stock_return_qty" in _routeSumDetails &&
                _routeSumDetails.return_qty !==
                  _routeSumDetails.stock_return_qty.toString()
              ) {
                _routeSumDetails.bgColor = redHighlightColor;
              } else if (
                "stock_return_qty" in _routeSumDetails &&
                _routeSumDetails.return_qty ===
                  _routeSumDetails.stock_return_qty.toString()
              ) {
                _routeSumDetails.bgColor = "white";
              }

              modifySummaryDetails(_routeSumDetails, index);
            }}
          />
        </td>

        <td style={{ width: 280 }}>
          <MultiSelect
            styles={{
              item: { fontSize: 12 },
              dropdown: { backgroundColor: "#d3dbd5" },
            }}
            value={row.selected_qr_ids}
            onChange={(val) => {
              let _routeSumDetails = data;
              _routeSumDetails.selected_qr_ids = [...val];

              if (
                _routeSumDetails.selected_qr_ids.length ===
                _routeSumDetails.qr_ids.length
              ) {
                _routeSumDetails.return_qty = _routeSumDetails.stock_return_qty;
              } else {
                _routeSumDetails.return_qty = 0;
              }

              if (
                "stock_return_qty" in _routeSumDetails &&
                Number(_routeSumDetails.return_qty) !==
                  Number(_routeSumDetails.stock_return_qty)
              ) {
                _routeSumDetails.bgColor = redHighlightColor;
              } else if (
                "stock_return_qty" in _routeSumDetails &&
                Number(_routeSumDetails.return_qty) ===
                  Number(_routeSumDetails.stock_return_qty)
              ) {
                _routeSumDetails.bgColor = "white";
              }

              modifySummaryDetails(_routeSumDetails, index);
            }}
            name="qr_id_selection"
            data={row.qr_ids.map((item) => {
              return { label: item, value: item };
            })}
            placeholder="Pick one"
            nothingFound="No options"
            searchable
            zIndex={999999999999}
          />
        </td>
        <td>
          <Form.Control
            type="text"
            value={row.received_remarks}
            onChange={(e) => {
              let _routeSumDetails = data;
              _routeSumDetails.received_remarks = e.target.value;

              modifySummaryDetails(_routeSumDetails, index);
            }}
          />
        </td>
      </tr>
    );
  },
  (prevProps, nextProps) => {
    return JSON.stringify(prevProps.row) === JSON.stringify(nextProps.row);
  }
);

const TransitReturnLooseTable = ({
  routeProductData,
  overallReturnSummaryDetails,
  currentSelectedRoute,
  setOverallReturnSummaryDetails,
  removeItemFromSummaryDetails,
  transitID,
  init,
}) => {
  return (
    <>
      <table className="table table-striped">
        <TransitReturnLooseTableHeader />
        <TransitReturnLooseTableBody
          routeProductData={routeProductData}
          overallReturnSummaryDetails={overallReturnSummaryDetails}
          currentSelectedRoute={currentSelectedRoute}
          setOverallReturnSummaryDetails={setOverallReturnSummaryDetails}
          removeItemFromSummaryDetails={removeItemFromSummaryDetails}
          init={init}
          transitID={transitID}
        />
      </table>
    </>
  );
};

const OverallTransitReturnSummaryTable = (props) => {
  const productMasterDataRaw = props.productMasterDataRaw || {};
  const overallReturnSummaryDetails = props.overallReturnSummaryDetails;
  const overallLooseSummaryDetails = props.overallLooseSummaryDetails;
  const currentSelectedRoute = props.currentSelectedRoute;
  const removeItemFromSummaryDetails = props.removeItemFromSummaryDetails;
  const setOverallReturnSummaryDetails = props.setOverallReturnSummaryDetails;
  const setOverallLooseSummaryDetails = props.setOverallLooseSummaryDetails;
  const setSelectedEntryIndex = props.setSelectedEntryIndex;
  const setIsQREntryDrawerDisplayed = props.setIsQREntryDrawerDisplayed;
  const init = props.init;
  const setBackDrop = props.setBackDrop;

  setBackDrop(true);

  if (Object.keys(productMasterDataRaw).length === 0) return <></>;
  setBackDrop(false);
  let routeDataToShow = overallReturnSummaryDetails;
  overallReturnSummaryDetails.forEach((item, index) => {
    let data = item;
    data.item_disc = data.item_disc
      ? data.item_disc
      : productMasterDataRaw[item.item_id].long_description || "";
    data.variety = data.variety
      ? data.variety
      : productMasterDataRaw[item.item_id].variety || "";
    data.category = data.category
      ? data.category
      : productMasterDataRaw[item.item_id].category || "";
    overallReturnSummaryDetails[index] = data;
  });

  return (
    <div style={{ pageBreakBefore: "always" }}>
      {routeDataToShow.length > 0 ? (
        <TransitRoutesTable
          routeProductData={routeDataToShow}
          currentSelectedRoute={currentSelectedRoute}
          overallReturnSummaryDetails={overallReturnSummaryDetails}
          removeItemFromSummaryDetails={removeItemFromSummaryDetails}
          setOverallReturnSummaryDetails={setOverallReturnSummaryDetails}
          init={init}
          setSelectedEntryIndex={setSelectedEntryIndex}
          setIsQREntryDrawerDisplayed={setIsQREntryDrawerDisplayed}
        />
      ) : (
        ""
      )}

      {overallLooseSummaryDetails.length ? (
        <>
          {" "}
          <center>
            <h4>Loose Items</h4>
          </center>
          <TransitReturnLooseTable
            routeProductData={overallLooseSummaryDetails}
            currentSelectedRoute={currentSelectedRoute}
            overallReturnSummaryDetails={overallLooseSummaryDetails}
            removeItemFromSummaryDetails={removeItemFromSummaryDetails}
            setOverallReturnSummaryDetails={setOverallLooseSummaryDetails}
            init={init}
          />
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default TransitReturnCreationModal;
