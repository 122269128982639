import React, { useState, useEffect, useRef } from "react";
import {
  Breadcrumb,
  Navbar,
  Dropdown,
  DropdownButton,
  Alert,
  Table,
  Button,
  Form,
  Jumbotron,
  InputGroup,
  Accordion,
  Toast,
  Badge,
} from "react-bootstrap";
import { InputGroupAddon, Input } from "reactstrap";
import LoosePackingTable from "./loosepackingtable";
import LoosePackingPrintBody from "./LoosePackingPrintBody";
import NavBar from "../Navigation bar/Navbar";
import database from "../../Configs/firebase";
import DatePicker from "react-datepicker";
import ReactToPrint from "react-to-print";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import Backdrop from "../backdrop/backdrop";
import { toast } from "react-toastify";
import XLSX from "xlsx";
import Select from "react-select";
import { Switch } from "@material-ui/core";
import { support_portal_url } from "../../Configs/mysqlconfig";
import { validateIsAdminUser } from "../UserCredentials";
import fire from "../../Configs/firebase";

const warehouseOptions = [
  "All",
  "Bhiwandi",
  "Nashik",
  "Vashi",
  "Mira Road",
  "Wadala",
];
function formatDate(date) {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

const LoosePacking = () => {
  let userRoutes = sessionStorage.getItem("allowedRoutes")
    ? JSON.parse(sessionStorage.getItem("allowedRoutes"))
    : [];
  // console.log(userRoutes);
  let allowedRoutesSet = new Set();

  for (let i = 0; i < userRoutes.length; i++) {
    let route = `/${userRoutes[i]}`;
    if (!allowedRoutesSet.has(route)) {
      allowedRoutesSet.add(route);
    }
  }
  // console.log(allowedRoutesSet);
  const [warehouseOptionsList, setWarehouseOptionsList] = useState([]);
  const [warehouse, setWarehouse] = useState({});
  const [route, setRoute] = useState({});
  const [routeOptionsList, setRouteOptionsList] = useState([]);
  const [allLoosePackingOrders, setAllLoosePackingOrders] = useState([]);
  const [loosePackingOrders, setLoosePackingOrders] = useState([]);
  const [isHistoricalDate, setIsHistoricalDate] = useState(true);
  const [backDrop, setBackDrop] = useState(true);
  const [orderType, setOrderType] = useState(true);
  const [pickingOrdersHistoryRaw, setPickingOrdersHistory] = useState({});

  let today = new Date();
  let tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [deliveryPlanDate, setDeliveryPlanDate] = useState(today);
  const printComponentRef = [];

  const [exportData, setExportData] = useState(false);

  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });

  function filterLooseOrders() {
    let filteredLooseOrders = [];

    if (warehouse.value === "All") {
      filteredLooseOrders = allLoosePackingOrders.map((item) => item);
    } else {
      filteredLooseOrders = allLoosePackingOrders.filter(
        (item) => item.area === warehouse.value
      );
    }

    if (route.value !== "All") {
      filteredLooseOrders = filteredLooseOrders.filter(
        (item) => item.route === route.value
      );
    }

    setLoosePackingOrders(filteredLooseOrders);
  }

  useEffect(() => {
    if (exportData) exportLoosePackingOrderDetails();
  }, [exportData]);

  useEffect(() => {
    filterLooseOrders();
  }, [warehouse, route]);

  useEffect(() => {
    fetchLoosePackingOrders(new Date(tomorrow), "doh");
    let _warehouse_options_list = [];
    warehouseOptions.forEach((item, index) => {
      _warehouse_options_list.push({ label: item, value: item });
    });
    setWarehouseOptionsList(_warehouse_options_list);
    setWarehouse(_warehouse_options_list[0]);
  }, []);

  const fetchLoosePackingOrders = async (selecteddate, switchValue) => {
    let apiUrl = `${support_portal_url}/api/alloosepackorders?token=${sessionStorage.getItem(
      "apiToken"
    )}`;

    let newDelivryPlanDate = formatDate(new Date(selecteddate));
    let checktomorrowdate = formatDate(new Date(tomorrow));
    console.log(checktomorrowdate);
    console.log(newDelivryPlanDate == checktomorrowdate);

    if (switchValue == "doh") {
      let year = new Date(selecteddate).getFullYear();
      let month = new Date(selecteddate).getMonth() + 1;
      let date = new Date(selecteddate).getDate();
      let deliveryDate = `${date}-${month}-${year}`;
      const picking_allocation_by_route = {};

      Promise.all([
        fire
          .database()
          .ref(`Picking_Orders_History/${year}/${month}/${deliveryDate}/`)
          .once("value"),
        fire
          .database()
          .ref(`Picking_Allocation_History/${year}/${month}/${deliveryDate}/`)
          .once("value"),
      ])
        .then(([PoHSnapshot, PaHSnapshot]) => {
          if (PaHSnapshot.hasChildren()) {
            const picking_allocation_history = PaHSnapshot.val() || {};

            Object.keys(picking_allocation_history).forEach((phone_key) => {
              Object.keys(picking_allocation_history[phone_key]).forEach(
                (key) => {
                  if (key.includes("LOOSE_PACK")) {
                    const data_for_route =
                      picking_allocation_history[phone_key][key];
                    const route = key.split("-")[0];
                    picking_allocation_by_route[route] = data_for_route;
                  }
                }
              );
            });
          }

          // console.log("debug check pah", picking_allocation_by_route);
          if (!PoHSnapshot.hasChildren()) {
            setBackDrop(false);
            setIsHistoricalDate(true);
            setAllLoosePackingOrders([]);
            setLoosePackingOrders([]);
          } else {
            const picking_orders_history = PoHSnapshot.val() || {};
            const loose_pack_orders_map = {};
            const routeList = [];
            routeList.push("All");

            // console.log("picking orders history ", picking_orders_history);

            Object.keys(picking_orders_history).forEach((key, index) => {
              const item = picking_orders_history[key];
              if (key.includes("LOOSE_PACK")) {
                const route = key.split("-")[0];
                routeList.push(route);
                // console.log("check key", route);

                Object.keys(item).forEach((key, index) => {
                  const loose_order = item[key];
                  let order_number = loose_order.order_number || "";
                  let variety = loose_order.variety || "";
                  let order_qty = Number(loose_order.order_qty) || 0;
                  let item_disc = loose_order.long_description || "";
                  let status = loose_order.status || "";
                  const packed_in_bag = loose_order.packed_in_bag || "";

                  let bagWeightRaw = loose_order.weight
                    ? loose_order.weight.split(" ")
                    : ["", ""];
                  let bagWeight = loose_order.weight
                    ? Number(bagWeightRaw[0])
                    : 0;
                  let bagWeightType = loose_order.weight ? bagWeightRaw[1] : "";

                  if (bagWeightType.toLowerCase() === "gm") {
                    bagWeight /= 1000;
                  }

                  let orderObj = loose_pack_orders_map[order_number] || {};

                  if (!loose_pack_orders_map[order_number]) {
                    orderObj["item_disc_list"] = [];
                    orderObj["varietysplist"] = [];
                    orderObj["orderqtylist"] = [];
                    orderObj["short_items"] = [];
                    orderObj["numbags"] = 0;
                    orderObj["bag_items"] = {};
                    orderObj["weightbags"] = 0;
                    orderObj["sub_bag_weights"] = {};
                  }

                  orderObj["order_number"] = order_number;
                  orderObj["brand"] = loose_order.brand;
                  orderObj["retailer_id"] = loose_order.retailer_id || "";
                  orderObj["shop_name"] = loose_order.shop_name || "";
                  orderObj["route"] = loose_order.vehicle_no;
                  orderObj["area"] = loose_order.city || "";
                  orderObj["picked_status"] = loose_order.picked_status || "";
                  orderObj["can_print"] =
                    picking_allocation_by_route[route]?.bag_id_update ===
                      "true" || false;
                  orderObj["net_order_amt"] =
                    Number(loose_order.total_amt) || 0;
                  orderObj["order_date_conv"] = loose_order.order_date || "";
                  orderObj["item_disc_list"].push(item_disc);
                  orderObj["varietysplist"].push(variety);
                  orderObj["orderqtylist"].push(order_qty);

                  let total_split_bags = null;
                  if (loose_order.split_bags) {
                    total_split_bags = Object.keys(
                      loose_order.split_bags
                    ).length;
                    Object.keys(loose_order.split_bags).forEach(
                      (key, index) => {
                        console.log("split bag weight", bagWeight);
                        const split_bag_qty = loose_order.split_bags[key];
                        orderObj["bag_items"][key] = [];
                        orderObj["bag_items"][key].push({
                          brand: loose_order.brand,
                          item_disc,
                          image_url: loose_order.product_image_url,
                          weight: bagWeight,
                          picked_qty: split_bag_qty || "",
                          packed_in_bag: key,
                        });

                        orderObj["sub_bag_weights"][key] = 0;

                        orderObj["sub_bag_weights"][key] +=
                          bagWeight * Number(split_bag_qty || 1);
                      }
                    );
                  } else {
                    if (!orderObj["bag_items"][packed_in_bag]) {
                      orderObj["bag_items"][packed_in_bag] = [];
                      orderObj["sub_bag_weights"][packed_in_bag] = 0;
                    }
                    orderObj["bag_items"][packed_in_bag].push({
                      brand: loose_order.brand,
                      item_disc,
                      image_url: loose_order.product_image_url,
                      weight: bagWeight,
                      picked_qty: loose_order.picked_qty || "",
                      packed_in_bag: packed_in_bag,
                    });
                    orderObj["sub_bag_weights"][packed_in_bag] +=
                      bagWeight * Number(loose_order.picked_qty || 1);
                  }

                  orderObj["variety"] = variety;
                  orderObj["numbags"] += total_split_bags
                    ? total_split_bags
                    : 1;
                  orderObj["weightbags"] +=
                    bagWeight * Number(loose_order.picked_qty || 1);

                  if (
                    Number(loose_order.picked_qty) <
                    Number(loose_order.order_qty)
                  ) {
                    orderObj["short_items"].push(loose_order.item_id);
                  }

                  orderObj["packedby"] = loose_order["packed_by"] || "";
                  orderObj["bagid"] = loose_order["bag_id"] || "";
                  orderObj["flagchecked"] = false;

                  loose_pack_orders_map[order_number] = orderObj;
                });
              }
            });

            // console.log("loose", loose_pack_orders_map);
            const loose_pack_orders_arr = Object.keys(
              loose_pack_orders_map
            ).map((key, index) => {
              return loose_pack_orders_map[key];
            });
            const _routeOptionsList = routeList.map((item) => {
              return { label: item, value: item };
            });
            // console.log("check routeList", _routeOptionsList);
            setBackDrop(false);
            setIsHistoricalDate(true);
            setLoosePackingOrders(loose_pack_orders_arr);
            setAllLoosePackingOrders(loose_pack_orders_arr);
            setRouteOptionsList(_routeOptionsList);
            setRoute(_routeOptionsList[0]);
            setPickingOrdersHistory(picking_orders_history);
          }
        })
        .catch((error) => {
          alert(error);
          setBackDrop(false);
          setIsHistoricalDate(true);
          setLoosePackingOrders([]);
          setAllLoosePackingOrders();
        });
    } else {
      let apiurlstr = `${support_portal_url}/api/loosepackingorders?token=${sessionStorage.getItem(
        "apiToken"
      )}`;

      Promise.all([
        fetch(apiurlstr).then((response) => response.json()),
        fetch(apiUrl).then((response2) => response2.json()),
      ])
        .then(([result, result2]) => {
          if (result["success"] == 1 && result2["success"] == 1) {
            let originalLoosePackOrders = result["data"];
            let loosePackTableAllOrders = result2["data"];
            // console.log({ originalLoosePackOrders, loosePackTableAllOrders });

            let loosePackOrderObj = {};
            for (let z = 0; z < loosePackTableAllOrders.length; z++) {
              let orderObj = {};
              let order_no = loosePackTableAllOrders[z]["order_no"] || "";

              let weight1 = parseFloat(
                loosePackTableAllOrders[z]["weight1_bags"]
              );
              let weight2 = parseFloat(
                loosePackTableAllOrders[z]["weight2_bags"]
              );
              let weight3 = parseFloat(
                loosePackTableAllOrders[z]["weight3_bags"]
              );
              let weight4 = parseFloat(
                loosePackTableAllOrders[z]["weight4_bags"]
              );
              let weight5 = parseFloat(
                loosePackTableAllOrders[z]["weight5_bags"]
              );
              let weight6 = parseFloat(
                loosePackTableAllOrders[z]["weight6_bags"]
              );
              let weight7 = parseFloat(
                loosePackTableAllOrders[z]["weight7_bags"]
              );
              let weight8 = parseFloat(
                loosePackTableAllOrders[z]["weight8_bags"]
              );
              let weight9 = parseFloat(
                loosePackTableAllOrders[z]["weight9_bags"]
              );
              let weight10 = parseFloat(
                loosePackTableAllOrders[z]["weight10_bags"]
              );
              orderObj["numbags"] = loosePackTableAllOrders[z]["no_bags"];
              orderObj["weightbags"] = (
                weight1 +
                weight2 +
                weight3 +
                weight4 +
                weight5 +
                weight6 +
                weight7 +
                weight8 +
                weight9 +
                weight10
              ).toFixed(2);
              orderObj["shortitem"] = loosePackTableAllOrders[z]["short_item"];
              orderObj["packedby"] = loosePackTableAllOrders[z]["packed_by"];
              orderObj["weight1"] = loosePackTableAllOrders[z]["weight1_bags"];
              orderObj["weight2"] = loosePackTableAllOrders[z]["weight2_bags"];
              orderObj["weight3"] = loosePackTableAllOrders[z]["weight3_bags"];
              orderObj["weight4"] = loosePackTableAllOrders[z]["weight4_bags"];
              orderObj["weight5"] = loosePackTableAllOrders[z]["weight5_bags"];
              orderObj["weight6"] = loosePackTableAllOrders[z]["weight6_bags"];
              orderObj["weight7"] = loosePackTableAllOrders[z]["weight7_bags"];
              orderObj["weight8"] = loosePackTableAllOrders[z]["weight8_bags"];
              orderObj["weight9"] = loosePackTableAllOrders[z]["weight9_bags"];
              orderObj["weight10"] =
                loosePackTableAllOrders[z]["weight10_bags"];
              orderObj["bagid"] = loosePackTableAllOrders[z]["bagid"];
              orderObj["flagchecked"] = false;

              loosePackOrderObj[order_no] = orderObj;
            }

            let finalLoosePackOrders = [];
            //process data
            for (let i = 0; i < originalLoosePackOrders.length; i++) {
              let ele = originalLoosePackOrders[i];
              let order_number =
                originalLoosePackOrders[i]["order_number"] || "";

              if (loosePackOrderObj[order_number]) {
                let loosePackOrderDetails =
                  loosePackOrderObj[order_number] || {};
                ele = Object.assign({}, ele, loosePackOrderDetails);
              } else {
                ele["numbags"] = "";
                ele["weightbags"] = "";
                ele["shortitem"] = "";
                ele["packedby"] = "";
                ele["weight1"] = 0;
                ele["weight2"] = 0;
                ele["weight3"] = 0;
                ele["weight4"] = 0;
                ele["weight5"] = 0;
                ele["weight6"] = 0;
                ele["weight7"] = 0;
                ele["weight8"] = 0;
                ele["weight9"] = 0;
                ele["weight10"] = 0;
                ele["flagchecked"] = false;
                ele["bagid"] = "";
              }

              finalLoosePackOrders.push(ele);
            }

            // console.log(finalLoosePackOrders);

            setBackDrop(false);
            setIsHistoricalDate(true);
            setLoosePackingOrders(finalLoosePackOrders);
          } else {
            let errMessage = result2["message"] ? result2["message"] : "";
            alert(errMessage);
            if (result2["status"] == 401 || result2["status"] == 403) {
              sessionStorage.clear();
              window.location.href = "/";
            } else {
              setBackDrop(false);
              setIsHistoricalDate(true);
              setLoosePackingOrders([]);
            }
          }
        })
        .catch((error) => {
          alert(error);
          setBackDrop(false);
          setIsHistoricalDate(true);
          setLoosePackingOrders([]);
        });
    }
  };

  const generateRandomBagId = () => {
    let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let charactersLength = characters.length;
    let firstchar = characters.charAt(
      Math.floor(Math.random() * charactersLength)
    );
    let secchar = Math.floor(Math.random() * 10);
    let thirdchar = Math.floor(Math.random() * 10);
    let fourthchar = Math.floor(Math.random() * 10);
    let bagid = firstchar + secchar + thirdchar + fourthchar;
    return bagid;
  };

  useEffect(() => {
    handleDeliveryPlanDateChange(deliveryPlanDate);
  }, [orderType]);

  const handleDeliveryPlanDateChange = (selecteddate) => {
    let switchValue = orderType ? "doh" : "cutoff";
    setDeliveryPlanDate(new Date(selecteddate));
    setBackDrop(true);
    fetchLoosePackingOrders(new Date(selecteddate), switchValue);
    setRouteOptionsList([]);
    setRoute({});
  };

  const CustomDateInput = ({ value, onClick }) => (
    <input
      value={value}
      onClick={onClick}
      style={{ border: "solid 1px black", height: "30px" }}
    />
  );

  const exportLoosePackingOrderDetails = () => {
    const wb = XLSX.utils.book_new();

    let loosepackOrderSummary = [
      [
        "Sr No",
        "OrderDate",
        "Order Number",
        "Shop Name",
        "Area",
        "Order Value",
        "Bag ID",
        "No of Bags",
        "Total Weight of bags",
        "Weight1",
        "Weight2",
        "Weight3",
        "Weight4",
        "Weight5",
        "Weight6",
        "Weight7",
        "Weight8",
        "Weight9",
        "Weight10",
        "Short Item",
        "PackedBy",
      ],
    ];
    let count = 1;
    loosePackingOrders.map((order) => {
      let loosepackOrderDetails = [];
      loosepackOrderDetails.push(count);
      loosepackOrderDetails.push(order.order_date_conv);
      loosepackOrderDetails.push(order.order_number);
      loosepackOrderDetails.push(order.shop_name);
      loosepackOrderDetails.push(order.area);
      loosepackOrderDetails.push(order.net_order_amt);
      loosepackOrderDetails.push(order.bagid);
      loosepackOrderDetails.push(order.numbags);
      loosepackOrderDetails.push(order.weightbags);
      loosepackOrderDetails.push(order.weight1);
      loosepackOrderDetails.push(order.weight2);
      loosepackOrderDetails.push(order.weight3);
      loosepackOrderDetails.push(order.weight4);
      loosepackOrderDetails.push(order.weight5);
      loosepackOrderDetails.push(order.weight6);
      loosepackOrderDetails.push(order.weight7);
      loosepackOrderDetails.push(order.weight8);
      loosepackOrderDetails.push(order.weight9);
      loosepackOrderDetails.push(order.weight10);
      loosepackOrderDetails.push(order.shortitem);
      loosepackOrderDetails.push(order.packedby);
      loosepackOrderSummary.push(loosepackOrderDetails);
      count = count + 1;
    });

    let wscols = [
      { wch: 10 },
      { wch: 20 },
      { wch: 25 },
      { wch: 25 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 35 },
      { wch: 15 },
    ];

    let loosepackOrderExportDetails = XLSX.utils.aoa_to_sheet(
      loosepackOrderSummary
    );
    loosepackOrderExportDetails["!cols"] = wscols;
    XLSX.utils.book_append_sheet(
      wb,
      loosepackOrderExportDetails,
      `Loose Pack Orders`
    );
    XLSX.writeFile(wb, `LoosePack_Order_List.xlsx`);
    setExportData(false);
  };

  const handleSearchRedirection = () => {
    window.open("/searchloosepackorders", "_blank");
  };

  const getNextBagId = (day, month, prevBagId) => {
    const monthChar = String.fromCharCode("A".charCodeAt(0) + month);
    const dayChar = String.fromCharCode("A".charCodeAt(0) + (day % 20));

    let nextBagIdNumber;
    if (prevBagId) {
      const prevBagIdNumber = prevBagId.substring(2, 5);
      nextBagIdNumber = Number(prevBagIdNumber) + 1;
    } else {
      nextBagIdNumber = "001";
    }

    return `${monthChar}${dayChar}${nextBagIdNumber
      .toString()
      .padStart(3, "0")}`;
  };

  const bulkAssignBagIds = () => {
    if (!orderType) {
      return;
    }

    setBackDrop(true);

    const delivery_day = deliveryPlanDate.getDate();
    const delivery_month = deliveryPlanDate.getMonth();
    const bagIdsByOrderNumber = {};
    let lastAssignedBagId = null;
    let bagIdsList = [];
    const dataToUpdate = {};

    Object.keys(pickingOrdersHistoryRaw).forEach((categoryKey, index) => {
      const ordersByCategory = pickingOrdersHistoryRaw[categoryKey];
      if (!categoryKey.includes("LOOSE_PACK")) {
        return;
      }
      Object.keys(ordersByCategory).forEach((orderKey, index) => {
        const order = ordersByCategory[orderKey];
        if (order.bag_id) {
          bagIdsList.push(order.bag_id.toUpperCase());
        }
      });
    });

    bagIdsList = bagIdsList.sort().reverse();

    lastAssignedBagId = bagIdsList[0];

    Object.keys(pickingOrdersHistoryRaw).forEach((categoryKey, index) => {
      const ordersByCategory = pickingOrdersHistoryRaw[categoryKey];
      if (!categoryKey.includes("LOOSE_PACK")) {
        return;
      }
      Object.keys(ordersByCategory).forEach((orderKey, index) => {
        const order = ordersByCategory[orderKey];
        const order_number = order.order_number;
        if (!order.bag_id) {
          //check if associated order no already has a bag id assigned
          if (!(order_number in bagIdsByOrderNumber)) {
            //generate bag id
            const newBagId = getNextBagId(
              delivery_day,
              delivery_month,
              lastAssignedBagId
            );
            lastAssignedBagId = newBagId;
            bagIdsByOrderNumber[order_number] = newBagId;
            dataToUpdate[`${categoryKey}/${orderKey}/bag_id`] = newBagId;
          } else {
            const bagId = bagIdsByOrderNumber[order_number];
            dataToUpdate[`${categoryKey}/${orderKey}/bag_id`] = bagId;
          }
        }
      });
    });

    let year = new Date(deliveryPlanDate).getFullYear();
    let month = new Date(deliveryPlanDate).getMonth() + 1;
    let date = new Date(deliveryPlanDate).getDate();
    let deliveryDate = `${date}-${month}-${year}`;
    // console.log(
    //   `Assigned bag id obj /${year}/${month}/${deliveryDate}/  `,
    //   dataToUpdate
    // );

    fire
      .database()
      .ref(`Picking_Orders_History/${year}/${month}/${deliveryDate}/`)
      .update(dataToUpdate)
      .then(() => {
        setBackDrop(false);
        toast("Successfully assigned bag ids", {
          type: toast.TYPE.SUCCESS,
        });
        fetchLoosePackingOrders(deliveryPlanDate, "doh");
        return;
      })
      .catch((err) => {
        setBackDrop(false);
        return toast("Error: Could not assign bag ids", {
          type: toast.TYPE.ERROR,
        });
      });
  };

  const removeCutoffOrder = () => {
    fetch(`${support_portal_url}/api/removecutofforder`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": sessionStorage.getItem("apiToken"),
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result.success == 1) {
          window.location.reload();
          return toast("Removed Cutoff Order", { type: toast.TYPE.SUCCESS });
        } else {
          setBackDrop(false);
          let errorMessage = result.message ? result.message : "";
          toast("Couldn't remove cutoff order : " + errorMessage, {
            type: toast.TYPE.ERROR,
          });

          if (result["status"] == 401 || result["status"] == 403) {
            sessionStorage.clear();
            window.location.href = "/";
          }
        }
      })
      .catch((error) => {
        setBackDrop(false);
        return toast("Couldn't remove cutoff order : " + error, {
          type: toast.TYPE.ERROR,
        });
      });
  };

  return (
    <>
      <NavBar />
      <Navbar
        className="bg-light m-auto justify-content-center"
        style={{ paddingTop: 100, textAlign: "center" }}
      >
        <h3>Loose Packing</h3>
      </Navbar>
      <div className="row" style={{ fontSize: "14px" }}>
        <InputGroup size="sm" style={{ margin: 10 }}>
          <InputGroupAddon addonType="prepend">Packing Date</InputGroupAddon>
          <DatePicker
            selected={deliveryPlanDate}
            onChange={handleDeliveryPlanDateChange}
            minDate={new Date("2017/01/01")}
            aria-describedby="basic-addon1"
            dateFormat="dd/MM/yyyy"
            customInput={<CustomDateInput />}
          />

          <label>Cutoff</label>
          <Switch
            name="order_type"
            color="primary"
            checked={orderType}
            onChange={(e) => {
              setOrderType(e.target.checked);
            }}
          />
          <label>Packing Date</label>
          <label style={{ marginLeft: "10px", marginRight: "10px" }}>
            Warehouse
          </label>

          <div
            style={{
              width: "130px",
              height: "20px",
              marginRight: "10px",
            }}
          >
            <Select
              options={warehouseOptionsList}
              value={warehouse}
              onChange={(val) => {
                setWarehouse(val);
              }}
            />
          </div>
          <label style={{ marginRight: "7px" }}>Route</label>

          <div
            style={{
              width: "130px",
              height: "20px",
              marginRight: 10,
            }}
          >
            <Select
              options={routeOptionsList}
              value={route}
              onChange={(val) => {
                console.log("selected route:", val);
                setRoute(val);
              }}
            />
          </div>

          {isHistoricalDate == true ? (
            <Button
              size="sm"
              style={{ marginRight: 10 }}
              variant="warning"
              onClick={() => {
                bulkAssignBagIds();
              }}
            >
              Assign Bag IDs
            </Button>
          ) : null}
          {(allowedRoutesSet.has(`/searchloosepackorders`) ||
            validateIsAdminUser(
              JSON.parse(sessionStorage.getItem(`user`))[0].email
            )) && (
            <Button
              size="sm"
              style={{ marginRight: 10 }}
              variant="warning"
              onClick={() => {
                handleSearchRedirection();
              }}
            >
              Search
            </Button>
          )}
          {validateIsAdminUser(
            JSON.parse(sessionStorage.getItem(`user`))[0].email
          ) ? (
            <Button
              size="sm"
              style={{ marginRight: 10 }}
              variant="warning"
              onClick={() => {
                removeCutoffOrder();
              }}
            >
              Remove Cutoff Order
            </Button>
          ) : null}
          <Button
            size="sm"
            style={{ marginLeft: 0, marginRight: 10 }}
            variant="warning"
            onClick={() => setExportData(true)}
          >
            Export
          </Button>

          <div style={{ display: "none" }}>
            <LoosePackingPrintBody
              printComponentRef={printComponentRef}
              loosePackingOrdersData={loosePackingOrders}
            />
          </div>
          <ReactToPrint
            trigger={() => {
              return (
                // <IconButton style={{ float: "right" }}>
                //   <FontAwesomeIcon size={"2x"} icon={faPrint} />
                // </IconButton>
                <Button size="sm" variant="primary">
                  Print
                </Button>
              );
            }}
            content={() => printComponentRef[0]}
          />
        </InputGroup>
      </div>

      {loosePackingOrders.length > 0 ? (
        <LoosePackingTable
          loosePackingOrdersData={loosePackingOrders}
          deliveryPlanDate={deliveryPlanDate}
          isHistoricalDate={isHistoricalDate}
          orderType={orderType}
        />
      ) : backDrop ? (
        ""
      ) : (
        <div>No loose pack orders</div>
      )}
      {backDrop ? <Backdrop parentLoadStatus={backDrop} /> : null}
    </>
  );
};

export default LoosePacking;
