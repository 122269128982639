import React from "react";
import { Badge } from "@mantine/core";

function badgeColorForStatus(status) {
  if (!status) return "";
  status = status.toLowerCase();

  switch (status) {
    case "ordered":
      return "red";

    case "processed":
      return "grape";

    case "cancelled":
      return "dark";

    default:
      return " ";
  }
}

function OrderStatusBadge({ status, size }) {
  return (
    status && (
      <Badge size={size || "md"} color={badgeColorForStatus(status)}>
        {status}
      </Badge>
    )
  );
}

export default OrderStatusBadge;
