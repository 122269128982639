import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import { Button, Select } from "@mantine/core";

import { StockCorrectionReportTable } from "./StockCorrectionReportTable";
import NavBar from "../../Navigation bar/Navbar";
import moment from "moment";
import Backdrop from "../../backdrop/backdrop";
import { toast } from "react-toastify";
import {
  fetchPriceDetails,
  fetchStockCorrectionReports,
} from "../../../services/InventoryMovements.services";
import ExportToExcel from "../Export/ExportToExcel";

const Header = styled.div`
  display: flex;
  height: 18vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 1rem;
`;

const PageTitle = styled.div`
  font-weight: bolder;
  font-size: 2rem;
  text-align: center;
`;

const DateTitle = styled.div`
  text-align: center;
`;
const DateSelector = styled(DatePicker)`
  margin-left: 1rem;
  margin-right: 1rem;
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PageContainer = styled.div`
  width: 100%;
`;

const TableContainer = styled.div`
  width: 100%;
  padding: 1rem;
  flex: 1;
`;

const WarehouseSelectContainer = styled.div`
  margin: 0 1rem;
  align-self: flex-end;
`;

const FetchDataButton = styled(Button)`
  margin-top: 1rem;
`;

const ButtonStyle = styled(Button)`
  margin-right: 1rem;
`;

const ExportBtnStyles = styled.div`
  margin-top: 1rem;
`;

const warehouseList = [
  // { value: "All", label: "All" },
  // { value: "Goa", label: "Goa" },
  // { value: "Vashi", label: "Vashi" },
  // { value: "Virar", label: "Virar" },
  // { value: "Wadala", label: "Wadala" },
  { value: "Bhiwandi", label: "Bhiwandi" },
];

function ValueColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows

  const options = React.useMemo(() => {
    return ["GAIN", "LOSS"];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows

  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      if (row.values[id] && row.values[id] !== "") options.add(row.values[id]);
    });
    // return [...options.values()];
    return [...options].sort();
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

function getFooterSum(data, field) {
  if (!data.rows || data.rows.length === 0 || !field) {
    return 0;
  }

  let footerSum = data.rows.reduce((sum, row) => {
    return Number(row.values[field]) + sum;
  }, 0);
  return footerSum.toFixed(1);
}

const StockCorrectionReport = ({ history }) => {
  const [tableData, setTableData] = useState([]);
  const [dateRangeStart, setDateRangeStart] = useState(new Date());
  const [dateRangeEnd, setDateRangeEnd] = useState(new Date());
  const [warehouse, setWarehouse] = useState(warehouseList[0].value);
  const [isLoading, setIsLoading] = useState(false);

  const greenColumnHeaderHighlight = "rgba(0,200,0,.6)";
  const redColumnHeaderHighlight = "rgba(200,0,0,.6)";

  const parentWarehouseColumns = React.useMemo(
    () => [
      {
        Header: "Item Id",
        label: "Item Id",
        accessor: "item_id",
        value: "item_id",
      },
      {
        Header: "Warehouse",
        label: "Warehouse",
        accessor: "warehouse",
        value: "warehouse",
      },
      {
        Header: "Item Description",
        label: "Item Description",
        accessor: "formatted_item_desc",
        value: "formatted_item_desc",
      },
      {
        Header: "Category",
        label: "Category",
        accessor: "category",
        value: "category",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Sub Category",
        label: "Sub Category",
        accessor: "sub_category",
        value: "sub_category",
        Filter: SelectColumnFilter,
      },

      // {
      //   Header: "Base Price",
      //   label: "Base Price",
      //   accessor: "base_price",
      //   value: "base_price",
      // },

      {
        Header: "Correction Quantity",
        label: "Correction Quantity",

        accessor: "approved_qty",
        value: "approved_qty",
      },
      // {
      //   Header: "Approved Qty",
      //   label: "Approved Qty",

      //   accessor: "approved_qty",
      //   value: "approved_qty",
      //   filter: "equals",
      // },

      {
        Header: "Loss/Gain Value",
        label: "Loss/Gain Value",
        accessor: "approved_qty_price",
        value: "approved_qty_price",
        Filter: ValueColumnFilter,
        filter: (rows, id, filterValue) => {
          if (filterValue === "GAIN") {
            return rows.filter((row) => {
              const rowValue = row.values[id];
              return rowValue >= 0;
            });
          } else if (filterValue === "LOSS") {
            return rows.filter((row) => {
              const rowValue = row.values[id];
              return rowValue < 0;
            });
          } else {
            return rows;
          }
        },
      },
      // {
      //   Header: "Entered By",
      //   label: "Entered By",
      //   accessor: "entered_by",
      //   value: "entered_by",
      // },
      // {
      //   Header: "Approved By",
      //   label: "Approved By",
      //   accessor: "approved_by",
      //   value: "approved_by",
      // },
      // {
      //   Header: "Approved At",
      //   label: "Approved At",
      //   accessor: "approved_at",
      //   value: "approved_at",
      // },
      {
        Header: "Inventory",
        label: "Inventory",
        accessor: "inventory_after_shipped",
        value: "inventory_after_shipped",
      },
      {
        Header: "Inventory after Correction",
        label: "Inventory after Correction",

        accessor: "inventory_after_correction",
        value: "inventory_after_correction",
      },
    ],
    []
  );

  const parentWarehouseColumnsForExport = React.useMemo(
    () => [
      {
        Header: "Item Id",
        label: "Item Id",
        accessor: "item_id",
        value: "item_id",
      },
      {
        Header: "Warehouse",
        label: "Warehouse",
        accessor: "warehouse",
        value: "warehouse",
      },
      {
        Header: "Item Description",
        label: "Item Description",
        accessor: "formatted_item_desc",
        value: "formatted_item_desc",
      },
      {
        Header: "Category",
        label: "Category",
        accessor: "category",
        value: "category",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Sub Category",
        label: "Sub Category",
        accessor: "sub_category",
        value: "sub_category",
        Filter: SelectColumnFilter,
      },

      // {
      //   Header: "Base Price",
      //   label: "Base Price",
      //   accessor: "base_price",
      //   value: "base_price",
      // },

      {
        Header: "Correction Quantity",
        label: "Correction Quantity",

        accessor: "approved_qty",
        value: "approved_qty",
      },
      // {
      //   Header: "Approved Qty",
      //   label: "Approved Qty",

      //   accessor: "approved_qty",
      //   value: "approved_qty",
      //   filter: "equals",
      // },

      {
        Header: "Loss/Gain Value",
        label: "Loss/Gain Value",
        accessor: "approved_qty_price",
        value: "approved_qty_price",
        Filter: ValueColumnFilter,
        filter: (rows, id, filterValue) => {
          if (filterValue === "GAIN") {
            return rows.filter((row) => {
              const rowValue = row.values[id];
              return rowValue >= 0;
            });
          } else if (filterValue === "LOSS") {
            return rows.filter((row) => {
              const rowValue = row.values[id];
              return rowValue < 0;
            });
          } else {
            return rows;
          }
        },
      },
      {
        Header: "Entered By",
        label: "Entered By",
        accessor: "entered_by",
        value: "entered_by",
      },
      {
        Header: "Approved By",
        label: "Approved By",
        accessor: "approved_by",
        value: "approved_by",
      },
      {
        Header: "Approved At",
        label: "Approved At",
        accessor: "approved_at",
        value: "approved_at",
      },
      {
        Header: "Inventory",
        label: "Inventory",
        accessor: "inventory_after_shipped",
        value: "inventory_after_shipped",
      },
      {
        Header: "Inventory after Correction",
        label: "Inventory after Correction",

        accessor: "inventory_after_correction",
        value: "inventory_after_correction",
      },
    ],
    []
  );

  function getStockCorrectionTableData() {
    setIsLoading(true);

    Promise.all([
      fetchStockCorrectionReports(
        moment(dateRangeStart).format("YYYY-MM-DD"),
        moment(dateRangeEnd).format("YYYY-MM-DD"),
        warehouse
      ),
      fetchPriceDetails(),
    ])
      .then((result) => {
        const stockCorrectionData = result[0].data;
        const itemPriceDetails = result[1].data;

        const totalValues = {
          quantity: 0,
          approved_qty: 0,
          base_price: 0,
          approved_qty_price: 0,
          inventory_after_shipped: 0,
          inventory_after_correction: 0,
        };

        stockCorrectionData.sort((a, b) => {
          console.log("check", a.approved_at, b.approved_at);

          let first =
            !a.approved_at || a.approved_at == "undefined"
              ? null
              : a.approved_at;
          let second =
            !b.approved_at || b.approved_at == "undefined"
              ? null
              : b.approved_at;

          if (first && !second) return -1;
          else if (!first && second) return 1;
          else if (!first && !second) return 0;
          first = moment(first, "YYYY-MM-DD HH:mm:ss");
          second = moment(second, "YYYY-MM-DD HH:mm:ss");

          return -first.diff(second, "seconds");
        });

        stockCorrectionData.forEach((item) => {
          const item_price =
            itemPriceDetails[item.item_id]?.landing_cost || "N/A";

          item.quantity = Number(item.quantity || 0).toFixed(2);
          item.approved_qty = Number(item.approved_qty || 0).toFixed(2);
          item.formatted_item_desc = `${item.item_disc || ""} || ${
            item.brand || ""
          }`;
          item.inventory_after_shipped = Number(
            item.inventory_after_shipped || 0
          ).toFixed(2);

          item.inventory_after_correction =
            Number(item.inventory_after_shipped) +
            Number(item.approved_qty ?? item.quantity);

          item.base_price =
            item_price !== "N/A" ? Number(item_price).toFixed(2) : "N/A";
          let approved_qty_price =
            item_price !== "N/A"
              ? Number(item_price * item.approved_qty).toFixed(2)
              : "N/A";
          approved_qty_price = item.outer_size
            ? approved_qty_price * item.outer_size
            : approved_qty_price;
          item.approved_qty_price = Number(approved_qty_price).toFixed(2);
          totalValues.quantity += Number(item.quantity);
          totalValues.base_price += Number(item.base_price);
          totalValues.approved_qty += Number(item.approved_qty);
          totalValues.approved_qty_price += Number(item.approved_qty_price);
          totalValues.inventory_after_shipped += Number(
            item.inventory_after_shipped
          );

          totalValues.inventory_after_correction += Number(
            item.inventory_after_correction
          );
        });

        stockCorrectionData.sort((a, b) => {
          const categoryA = a.category?.toLowerCase() || "";
          const categoryB = b.category?.toLowerCase() || "";
          const subCategoryA = a.sub_category?.toLowerCase() || "";
          const subCategoryB = b.sub_category?.toLowerCase() || "";

          if (categoryA < categoryB) return -1;
          if (categoryA > categoryB) return 1;
          if (subCategoryA < subCategoryB) return -1;
          if (subCategoryA > subCategoryB) return 1;
          return 0;
        });

        stockCorrectionData.push({
          item_id: "Total",
          quantity: parseFloat(totalValues.quantity).toFixed(2),
          base_price: parseFloat(totalValues.base_price).toFixed(2),
          approved_qty: parseFloat(totalValues.approved_qty).toFixed(2),
          approved_qty_price: totalValues.approved_qty_price.toFixed(2),
          inventory_after_correction: parseFloat(
            totalValues.inventory_after_correction
          ).toFixed(2),
          inventory_after_shipped: parseFloat(
            totalValues.inventory_after_shipped
          ).toFixed(2),
        });

        // console.warn({stockCorrectionData})

        setTableData(stockCorrectionData);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast(`Error : ${err}`, {
          type: toast.TYPE.ERROR,
        });
      });
  }

  useEffect(() => {
    if (warehouse && dateRangeStart && dateRangeEnd)
      getStockCorrectionTableData();
  }, [warehouse, dateRangeStart, dateRangeEnd]);

  return (
    <>
      <NavBar />
      <br />
      <br />
      <br />
      <PageContainer>
        <Header>
          <PageTitle> Stock Correction Report</PageTitle>
          <DateContainer>
            <DateTitle>Start Date</DateTitle>
            <DateSelector
              selected={dateRangeStart}
              onChange={setDateRangeStart}
              maxDate={new Date()}
              // dateFormat="dd/MM/yyyy"
            />
          </DateContainer>
          <DateContainer>
            <DateTitle>End Date</DateTitle>
            <DateSelector
              selected={dateRangeEnd}
              onChange={setDateRangeEnd}
              minDate={new Date()}
              // dateFormat="dd/MM/yyyy"
            />
          </DateContainer>
          <WarehouseSelectContainer>
            <Select
              label="Warehouse"
              placeholder="Select"
              value={warehouse}
              data={warehouseList}
              onChange={setWarehouse}
            />
          </WarehouseSelectContainer>

          <ExportBtnStyles>
            <ExportToExcel
              sheet1={tableData}
              filename={"stock_correction_report"}
              sheet1Headers={parentWarehouseColumnsForExport}
              sheet1Name={warehouse}
            />
          </ExportBtnStyles>
        </Header>

        <TableContainer>
          <StockCorrectionReportTable
            tableData={tableData}
            parentWarehouseColumns={parentWarehouseColumns}
            warehouse={warehouse}
            history={history}
          />
        </TableContainer>
      </PageContainer>
      {isLoading ? <Backdrop parentLoadStatus={isLoading} /> : null}
    </>
  );
};

export default StockCorrectionReport;
