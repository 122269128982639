import React, { useState, useEffect, useRef } from "react";
import { Table, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import fire from "../../Configs/firebase";

import szlogo from "../../assests/images/sz_logo.png";
import footer from "../../assests/images/footer.png";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { service_url, token } from "../../Configs/mysqlconfig";
import ReactToPrint from "react-to-print";
import Backdrop from "../backdrop/backdrop";
import { toast } from "react-toastify";
import superprimelogo from "../../assests/images/superprimelogo.png";
import priorityshipping from '../../assests/images/priority_shipping.png';
import queryString from "query-string";
import { support_portal_url, orderingApiUrl } from "../../Configs/mysqlconfig";
import moment from "moment";

function formatDate(selecteddate) {
  let dt = new Date(selecteddate);
  let monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  let day = dt.getDate();

  let monthIndex = dt.getMonth();
  let monthName = monthNames[monthIndex];

  let year = dt.getFullYear().toString().substr(2, 2);
  let formattedDate = `${day}-${monthName}-${year}`;
  return formattedDate;
}

function getRetailerIdFromOrderNumber(orderNumber) {
  if (!orderNumber) {
    return "";
  }

  if (orderNumber.includes("SC")) {
    let retailerStr = orderNumber.split("/")[0];
    return retailerStr.substr(retailerStr.length - 5);
  } else if (orderNumber.includes("-")) {
    return orderNumber.split("-")[0];
  } else {
    return orderNumber.split("/")[0];
  }
}

const convertCity = (cityVal, retailerid) => {
  if(cityVal) 
    cityVal = cityVal.toLowerCase();
  if(cityVal=="pune"){
    return "Pune";
  } else if(cityVal=="surat"){
    return "Surat";
  } else if(cityVal=="nashik"){
    return "Nashik";
  } else if(cityVal=="bhiwandi"){
    return "Bhiwandi";
  } else {
    if(Number(retailerid)>100000 || cityVal.includes("goa")) {
      return "Goa";
    } else {
      return "Mumbai";
    }
  }
}

function formatPhone(phone, secondary_number) {
  let phonestr = "" + phone;
  if (secondary_number && secondary_number != "null") {
    phonestr += "," + secondary_number;
  }
  return phonestr;
}

function formatGstNumber(gst_number) {
  return `GSTIN : ${gst_number}`;
}

function formatFssai(fssai) {
  return `FSSAI : ${fssai}`;
}

function formatChequeDate(deliveredDate) {
  let tomorrow = new Date(deliveredDate);
  tomorrow.setDate(tomorrow.getDate() + 12);

  let date = tomorrow.getDate();
  let month = tomorrow.getMonth() + 1;
  let year = tomorrow.getFullYear();

  return `${date}/${month}/${year}`;
}

function formatDeliveryDate(dt) {
  let dateList = dt.split("-");
  let year = dateList[2];
  let month = "" + dateList[1];
  let date = "" + dateList[0];

  if (date.length < 2) {
    date = "0" + date;
  }

  if (month.length < 2) {
    month = "0" + month;
  }

  return `${year}-${month}-${date}`;
}

const StockMovementPrint = (props) => {
  let deliveryDate = sessionStorage.getItem("getDB")
    ? sessionStorage.getItem("getDB")
    : "";
  let deliveryDateList = deliveryDate.split("/");
  let finalDeliveryDate = deliveryDateList[deliveryDateList.length - 1];
  finalDeliveryDate = formatDeliveryDate(finalDeliveryDate);
  finalDeliveryDate = moment(new Date()).format("DD-MMM-YYYY");

  const [itemDetails, setItemDetails] = useState();
  const [updateditemDetails, setUpdatedItemDetails] = useState();
  const [orderdetails, setOrderdetails] = useState("");
  const [getBackDrop, setBackDrop] = useState(false);
  const [totalOrderQty, setTotalOrderQty] = useState("");
  const [amtExclGst, setAmtExclGst] = useState("");
  const [CGSTAmt, setCGSTAmt] = useState("");
  const [SGSTAmt, setSGSTAmt] = useState("");
  const [IGSTAmt, setIGSTAmt] = useState("");
  const [amtInclGst, setAmtInclGst] = useState("");
  const [replacedItem, setReplacedItem] = useState(
    JSON.parse(sessionStorage.getItem("replaced_item_details"))
  );
  const [itemAmountErrorMessage, setItemAmountErrorMessage] = useState([]);
  const [orderAmountErrorMessage, setOrderAmountErrorMessage] = useState([]);
  const [getPrimeUser, setPrimeUser] = useState(false);

  const dataToPrint = useRef();
  const itemWeightRef = useRef([]);

  const fetchRetailerDataFromDB = (retailer_id) => {
    return new Promise((resolve, reject) => {
      const retailer_master = fire
        .database()
        .ref(`Retailer_Master_New/${retailer_id}`);

      retailer_master.once(
        "value",
        (snapshot) => {
          let data = snapshot.val();
          // console.log("retailer data", data);
          resolve(data);
        },
        (err) => {
          reject("error occured while fetching data from firebase");
        }
      );
    });
  };

  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_CENTER,
  });
  const itemQtyDetails = JSON.parse(sessionStorage.getItem("item_qty_details"));
  let invoiceDate = new Date();
  let monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  invoiceDate = `${invoiceDate.getDate()}-${
    monthNames[invoiceDate.getMonth()]
  }-${invoiceDate.getFullYear().toString().substr(2, 2)}`;

  useEffect(() => {
    const { transitId } = queryString.parse(props.location.search);
    if (!transitId) return;
    let url = `${service_url}/api/bill?transit_id=${transitId}&token=${token}`;

    fetch(url, {
      headers: { "x-access-token": sessionStorage.getItem("x-access-token") },
    })
      .then((res) => res.json())
      .then(async (result) => {
        console.log("fetched data", result);

        let finalOrderData = [];
        let driverOrdersData = result.data;

        setBackDrop(false);

        let sumOfItems = 0;
        let sumAmtInclGst = 0;
        let sumIGSTAmt = 0;
        let sumSGSTAmt = 0;
        let sumCGSTAmt = 0;
        let bagStr = "";
        let bagSet = new Set();
        let sumTotalAmt = 0;

        const retailerPh =
          driverOrdersData[Object.keys(driverOrdersData)[0]]["retailer_id"];

        console.log("check retailer id", retailerPh);

        const retailerMasterData = await fetchRetailerDataFromDB(retailerPh);
        console.log("check data", retailerMasterData);

        Object.keys(driverOrdersData).map((key, index) => {
          // console.log("iterating", driverOrdersData[key]);
          let ele = driverOrdersData[key];

          let mrp = ele["dealer_price"] ? ele["dealer_price"] : "";
          let netOrderAmt = ele["total_amt"];
          ele["net_order_amt"] = netOrderAmt ? Number(netOrderAmt) : 0;
          let bagId = ele["bag_id"] || "";
          if (bagId && !bagSet.has(bagId)) {
            bagStr += `${bagId},`;
            bagSet.add(bagId);
          }

          ele["retailer_id"] = retailerMasterData["retailer_id"];
          ele["total_amt"] = mrp;
          ele["original_total_amt"] = mrp;
          let gstCharge = ele.gst_charge;
          ele["original_gst_charge"] = gstCharge;
          let order_qty = ele["shipped_qty"] ? Number(ele["shipped_qty"]) : 0;
          ele["original_order_qty"] = order_qty;
          let item_quantity = 0;

          if (ele.approved_qty) {
            item_quantity = Number(ele["approved_qty"]);
          } else if (ele.received_qty) {
            item_quantity = Number(ele["received_qty"]);
          } else if (ele.hasOwnProperty("shipped_qty")) {
            item_quantity = Number(ele["shipped_qty"]);
          } else if (ele.hasOwnProperty("item_delivered_qty")) {
            item_quantity = Number(ele["item_delivered_qty"]);
          } else {
            item_quantity = order_qty;
          }

          ele["item_quantity"] = item_quantity;

          // quantity modified case
          if (order_qty != item_quantity) {
            ele.total_amt = (Number(ele.total_amt) * item_quantity) / order_qty;
            ele.gst_charge =
              (Number(ele.gst_charge) * item_quantity) / order_qty;
          }

          if (item_quantity != 0) {
            sumOfItems += 1;
            sumTotalAmt += Number(ele.dealer_price) * Number(item_quantity);
            sumIGSTAmt += ele.gst_charge ? Number(ele.gst_charge)* Number(item_quantity) : 0;
          }

          // if(item_quantity>0){
          finalOrderData.push(ele);
          // }
        });
        // console.log('check final order datas',finalOrderData);
        if (bagStr) {
          bagStr = bagStr.slice(0, -1);
        }

        let net_order_amt = 0;
        let order_disc = 0;
        let cashback_redeemed = 0;
        

        let orderAmountErrors = [];
        let itemAmountErrors = [];
        let newFinalOrderData = [];

        for (let i = 0; i < finalOrderData.length; i++) {
          let orderele = finalOrderData[i];
          orderele["item_mrp_sum"] = sumAmtInclGst;

          net_order_amt = finalOrderData[i]["net_order_amt"]
            ? Number(finalOrderData[i]["net_order_amt"])
            : 0;
          order_disc = finalOrderData[i]["order_disc"]
            ? Number(finalOrderData[i]["order_disc"])
            : 0;
          cashback_redeemed = finalOrderData[i]["cashback_redeemed"]
            ? Number(finalOrderData[i]["cashback_redeemed"])
            : 0;

          //item level
          let weight = finalOrderData[i]["weight"];
          let category = finalOrderData[i]["category"];
          let item_disc = finalOrderData[i]["item_disc"];
          let total_amt = finalOrderData[i]["original_total_amt"]
            ? Number(finalOrderData[i]["original_total_amt"])
            : 0;
          let dealer_price = finalOrderData[i]["dealer_price"]
            ? Number(finalOrderData[i]["dealer_price"])
            : 0;
          let order_qty = finalOrderData[i]["original_order_qty"]
            ? Number(finalOrderData[i]["original_order_qty"])
            : 0;
          let transport_charge = finalOrderData[i]["transport_charge"]
            ? Number(finalOrderData[i]["transport_charge"])
            : 0;
          let apmc_charge = finalOrderData[i]["apmc_charge"]
            ? Number(finalOrderData[i]["apmc_charge"])
            : 0;
          let gst_charge = finalOrderData[i]["original_gst_charge"]
            ? Number(finalOrderData[i]["original_gst_charge"])
            : 0;


          if (bagStr) {
            orderele["bagdetails"] = bagStr;
            orderele["numbags"] = bagStr.split(",").length;
          }

          let item_quantity = finalOrderData[i]["item_quantity"];
          if (item_quantity > 0) {
            newFinalOrderData.push(orderele);
          }
        }

        let calcOrderValue = sumTotalAmt - order_disc - cashback_redeemed;
        calcOrderValue = calcOrderValue.toFixed(2);
        calcOrderValue = Number(calcOrderValue);

        let orderValueDiff = calcOrderValue - net_order_amt;
        setItemAmountErrorMessage(itemAmountErrors);
        setOrderAmountErrorMessage(orderAmountErrors);

        setAmtExclGst((sumTotalAmt).toFixed(2));
        setIGSTAmt(sumIGSTAmt);
        setCGSTAmt(sumCGSTAmt);
        setSGSTAmt(sumSGSTAmt);
        setAmtInclGst((sumTotalAmt+sumIGSTAmt).toFixed(2));
        // alert("1")
        setItemDetails(newFinalOrderData);

        if (newFinalOrderData.length > 0) {
          setOrderdetails({
            ...retailerMasterData,
            ...newFinalOrderData[0],
          });
        }
      });
  }, []);

  const getItemsWeight = (indItemWeight, itemDesc, quantityOrdered) => {
    if (!indItemWeight) return 1;
    let itemWeight = Number(indItemWeight.replace(/\D/g, ""));
    if (indItemWeight.includes("gm") || indItemWeight.includes("ml")) {
      itemWeight = itemWeight / 1000;
    }

    if (
      (indItemWeight.includes("1 pc") || indItemWeight.includes("1 Pc")) &&
      itemDesc.includes("gm")
    ) {
      let itemWtExtract = itemDesc.split("gm");
      let itemWtExtract2 = itemWtExtract[0].split("-");
      itemWeight =
        parseFloat(
          itemWtExtract2[itemWtExtract2.length - 1].replace(/[^0-9]/g, "")
        ) / 1000;
    }
    if (itemWeight <= 0) {
      itemWeight = 1;
    }
    indItemWeight = itemWeight * Number(quantityOrdered);
    console.log(itemDesc);
    console.log(indItemWeight);
    return `${indItemWeight.toFixed(2)}`;
  };

  const isAlreadyPaid = (payment_mode) => {
    return (
      payment_mode == "Credit" ||
      payment_mode == "RazorPay" ||
      payment_mode == "Internal_Credit"
    );
  };

  const isNumber = (item) => {
    return isNaN(Number(item)) ? "0" : Number(item).toFixed(2);
  };

  const getOrderDiscount = () => {
    return orderdetails["revised_order_disc"]
      ? orderdetails["revised_order_disc"]
      : orderdetails["order_disc"]
      ? orderdetails["order_disc"]
      : 0;
  };

  const calcSuperKreditAmt = () => {
    let revised_order_amt = orderdetails["item_mrp_sum"]
      ? Number(orderdetails["item_mrp_sum"])
      : 0;
    let revised_order_disc = orderdetails["revised_order_disc"]
      ? Number(orderdetails["revised_order_disc"])
      : orderdetails["order_disc"]
      ? Number(orderdetails["order_disc"])
      : 0;
    let cashback_redeemed = orderdetails["cashback_redeemed"]
      ? Number(orderdetails["cashback_redeemed"])
      : 0;

    return (revised_order_amt - revised_order_disc - cashback_redeemed).toFixed(
      2
    );
  };

  return (
    <>
      <div
        style={{
          textAlign: "center",
          width: "1000px",
          margin: "auto",
          border: "2px solid black",
        }}
      >
        <ReactToPrint
          trigger={() => (
            <a href="#" style={{ float: "right" }}>
              <FontAwesomeIcon size="3x" icon={faPrint} />
            </a>
          )}
          content={() => {
            return dataToPrint.current;
          }}
          pageStyle={{ margin: "50px", border: "2px" }}
        />

        {itemAmountErrorMessage.map((itemError, key) => {
          return (
            <div
              style={{
                display: "flex",
                backgroundColor: "#FF6347",
                padding: "10px",
                margin: "15px",
                borderRadius: "5px",
              }}
            >
              {itemError}
            </div>
          );
        })}

        {orderAmountErrorMessage.map((orderError, key) => {
          return (
            <div
              style={{
                display: "flex",
                backgroundColor: "#FF6347",
                padding: "10px",
                margin: "15px",
                borderRadius: "5px",
              }}
            >
              {orderError}
            </div>
          );
        })}

        <div ref={dataToPrint}>
          <Table>
            <tbody>
              <tr>
                <td>
                  <img
                    src={szlogo}
                    alt={"superzopLogo"}
                    style={{ width: "200px", height: "50px" }}
                  />
                </td>
                {orderdetails.prime_user == "Y" && (
                  <td>
                    <img
                      src={superprimelogo}
                      alt={"superprimeLogo"}
                      style={{ width: "150px", height: "120px" }}
                    />
                  </td>
                )}
                {orderdetails.is_priority == "Y" && (
                  <td>
                    <img
                      src={priorityshipping}
                      alt={"priority shipping"}
                      style={{ width: "150px", height: "120px" }}
                    />
                  </td>
                )}
                {orderdetails.retailer_id == 89998 ?
                  (<td style={{ width: "400px", textAlign: "left" }}>
                  <b>Retranz Infolabs Private Limited Goa</b>
                  <br />
                  3/7693, Cartybon Factory, Murida Fatorda
                  <br />
                  Margao, Goa – 403602
                  <br />
                  Phone : 9900089999
                  <br />
                  Email : support@superzop.com
                  <br />
                  GSTIN : 30AAHCR2573F1ZN <br />
                  FSSAI : 11522998000504
                </td>

                ) :  (<td style={{ width: "400px", textAlign: "left" }}>
                <b>Retranz Infolabs Private Limited</b>
                <br />
                Godown D1, Indian Corporation Compound,
                <br />
                Diva - Anjur Village, Bhiwandi – 421302
                <br />
                Phone : 9901044177
                <br />
                Email : support@superzop.com
                <br />
                GSTIN : 27AAHCR2573F1ZA <br />
                FSSAI : 11522998000504
              </td>)}
              </tr>
            </tbody>
          </Table>
          <div>
            <div
              style={{
                borderTop: "2px solid black",
                borderBottom: "2px solid black",
                textAlign: "center",
              }}
            >
              <b style={{ fontSize: "20px" }}>RETAILER TAX INVOICE</b>
            </div>
            <Table>
              <tbody>
                <tr>
                  <td style={{ textAlign: "left" }}>
                    <div>
                      <b>{orderdetails.shop_name}</b>
                      <br />
                      {orderdetails.address1}
                      <br />
                      {orderdetails.address2}
                      <br />
                      {orderdetails.area}
                      <br />
                      {convertCity(orderdetails.city || "")}-
                      {orderdetails.pincode}
                      <br />✆
                      {formatPhone(
                        orderdetails.phone,
                        orderdetails.secondary_number
                      )}
                      <br />
                      {orderdetails.gst_number
                        ? formatGstNumber(orderdetails.gst_number)
                        : null}
                      <br />
                      {orderdetails.fssai_document_number
                        ? formatFssai(orderdetails.fssai_document_number)
                        : null}
                      <br />
                    </div>
                  </td>
                  <td style={{ width: "400px", textAlign: "left" }}>
                    <div>
                      <Table borderless style={{ cellSpacing: "0px" }}>
                        <tbody>
                          <tr
                            style={{ cellSpacing: "0px", cellPadding: "0px" }}
                          >
                            <td style={{ padding: "0px" }}>Retailer ID</td>
                            <td style={{ textAlign: "right", padding: "0px" }}>
                              {orderdetails.retailer_id}
                            </td>
                          </tr>
                          <tr
                            style={{ cellSpacing: "0px", cellPadding: "0px" }}
                          >
                            <td style={{ padding: "0px" }}>Invoice Number</td>
                            <td style={{ textAlign: "right", padding: "0px" }}>
                              {orderdetails.retailer_id +"/"+ orderdetails.order_number}
                            </td>
                          </tr>
                          <tr style={{ cellSpacing: "0px" }}>
                            <td style={{ padding: "0px" }}>Invoice Date</td>
                            <td style={{ textAlign: "right", padding: "0px" }}>
                              {orderdetails.order_date
                                ? formatDate(orderdetails.order_date)
                                : ""}
                            </td>
                          </tr>
                          <tr style={{ cellSpacing: "0px" }}>
                            <td style={{ padding: "0px" }}>Order Date</td>
                            <td style={{ textAlign: "right", padding: "0px" }}>
                              {orderdetails.order_date
                                ? formatDate(orderdetails.order_date)
                                : ""}
                            </td>
                          </tr>
                          <tr style={{ cellSpacing: "0px" }}>
                            <td style={{ padding: "0px" }}>No of Items</td>
                            <td style={{ textAlign: "right", padding: "0px" }}>
                              {itemDetails ? itemDetails.length : ""}
                            </td>
                          </tr>
                          {orderdetails.numbags &&
                            orderdetails.numbags != 0 && (
                              <tr style={{ cellSpacing: "0px" }}>
                                <td style={{ padding: "0px" }}>
                                  No of Loose Bags
                                </td>
                                <td
                                  style={{ textAlign: "right", padding: "0px" }}
                                >
                                  {orderdetails.numbags}
                                </td>
                              </tr>
                            )}
                          {orderdetails.bagdetails &&
                            orderdetails.bagdetails != "null" && (
                              <tr style={{ cellSpacing: "0px" }}>
                                <td style={{ padding: "0px" }}>Loose Bag Id</td>
                                <td
                                  style={{ textAlign: "right", padding: "0px" }}
                                >
                                  {orderdetails.bagdetails &&
                                  orderdetails.bagdetails != "null"
                                    ? orderdetails.bagdetails
                                    : ""}
                                </td>
                              </tr>
                            )}
                        </tbody>
                      </Table>
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <Table>
            <thead
              style={{
                borderTop: "2px solid black",
                borderBottom: "2px solid black",
              }}
            >
              <tr>
                <td style={{ paddingRight: "10px" }}>
                  <b>Sl No</b>
                </td>
                <td style={{ paddingRight: "10px" }}>
                  <b>Item description</b>
                </td>
                <td style={{ paddingRight: "10px" }}>
                  <b>HSN Code</b>
                </td>
                <td style={{ paddingRight: "10px" }}>
                  <b>Qty/No</b>
                </td>
                <td style={{ paddingRight: "10px" }}>
                  <b>Dealer Price (per pack)</b>
                </td>
                <td style={{ paddingRight: "10px" }}>
                  <b>Amount(Rs.)</b>
                </td>
                {orderdetails.city != "Surat" && (
                  <td style={{ paddingRight: "10px" }}>
                    <b>IGST Amount</b>
                  </td>
                )}
                <td style={{ paddingRight: "10px" }}>
                  <b>Total Amount</b>
                </td>
              </tr>
            </thead>
            <tbody style={{ padding: "0" }}>
              {itemDetails
                ? Object.keys(itemDetails).map((items, index) => (
                    <tr>
                      <td style={{ padding: "2px", border: "none" }}>
                        {/* Sl No */}
                        {index + 1}
                      </td>
                      <td
                        style={{
                          width: "500px",
                          padding: "2px",
                          textAlign: "left",
                          border: "none",
                          fontSize: "16px",
                        }}
                      >
                        {/* Item description */}
                        {itemDetails[items].item_disc}
                      </td>
                      <td style={{ padding: "2px", border: "none" }}>
                        {/* HSN Code */}
                        {itemDetails[items].hsn_code || ""}
                      </td>
                      <td style={{ padding: "2px", border: "none" }}>
                        {/* Qty/No */}
                        {Number(itemDetails[items].item_quantity).toFixed(0)}
                      </td>
                      <td style={{ padding: "2px", border: "none" }}>
                        {/* Dealer Price (per pack) */}
                        {isNumber(
                          Number(itemDetails[items].dealer_price) 
                        )}
                      </td>
                      <td style={{ padding: "2px", border: "none" }}>
                        {/* Dealer Price (per pack) */}

                        {console.log(
                          "check dealer price calc",
                          Number(itemDetails[items].total_amt),
                          Number(itemDetails[items].gst_charge),
                          Number(itemDetails[items].item_quantity)
                        )}
                        {isNumber(
                          Number(itemDetails[items].dealer_price) * Number(itemDetails[items].item_quantity) 
                        )}
                      </td>
                      <td style={{ padding: "2px", border: "none" }}>
                        {/* Amount(Rs.) */}
                        {isNumber(
                          Number(itemDetails[items].item_quantity) *
                            Number(itemDetails[items].gst_charge) 
                        )}
                      </td>
                      <td style={{ padding: "2px", border: "none" }}>
                        {/* Total Amount */}
                        {isNumber(Number(itemDetails[items].item_quantity) *(Number(itemDetails[items].dealer_price) + 
                            Number(itemDetails[items].gst_charge)))}
                      </td>
                    </tr>
                  ))
                : ""}
            </tbody>
          </Table>

          <div style={{ borderTop: "1px solid black" }}>
            <Table>
              <tbody>
                <tr>
                  <td style={{ textAlign: "left" }}>
                    <div>
                      <br />
                      <br />
                      <br />
                      <div style={{ textAlign: "center" }}>
                        <i>
                          I/We hereby certify that the goods mentioned in the
                          invoice are warranted to be of the same nature
                          <br />
                          <br />
                          Certified that the particulars given above are true
                          and correct
                        </i>
                        <br />
                        <br />
                        <b>
                          <i>Goods Once Sold Will Not Be Taken Back.</i>
                        </b>
                        <br />
                        Subject to Mumbai Jurisdiction
                        <br />
                        <i>
                          <b>E&OE</b>
                        </i>
                      </div>
                      <br />
                      <div style={{ textAlign: "center" }}>
                        {orderdetails["order_payment_mode"] == "Credit" ? (
                          <h1>
                            <Badge pill variant="primary">
                              Paid - Rupifi
                            </Badge>
                          </h1>
                        ) : orderdetails["order_payment_mode"] == "RazorPay" ? (
                          <h1>
                            <Badge pill variant="primary">
                              Paid - RazorPay
                            </Badge>
                          </h1>
                        ) : orderdetails["order_payment_mode"] ==
                          "Internal_Credit" ? (
                          <h1>
                            <Badge pill variant="primary">
                              Paid - SuperKredit
                            </Badge>
                          </h1>
                        ) : (
                          <h1>
                            <Badge pill variant="primary">
                              Cash Only
                            </Badge>
                          </h1>
                        )}
                      </div>
                      <div style={{ textAlign: "center" }}>
                        {orderdetails["order_payment_mode"] ==
                        "Internal_Credit" ? (
                          <h1>
                            <Badge pill variant="primary">
                              Cheque Date -{" "}
                              {formatChequeDate(finalDeliveryDate)}
                            </Badge>
                          </h1>
                        ) : null}
                      </div>
                    </div>
                  </td>
                  <td
                    style={{
                      width: "400px",
                      textAlign: "right",
                      cellSpacing: "0px",
                    }}
                  >
                    <div>
                      <Table bordered style={{ border: "2px solid black" }}>
                        <tbody>
                          <tr style={{ border: "2px solid black" }}>
                            <td
                              style={{
                                border: "2px solid black",
                                textAlign: "left",
                              }}
                            >
                              Amount (Exl GST)
                            </td>
                            <td
                              style={{
                                border: "2px solid black",
                                textAlign: "right",
                              }}
                            >
                              {amtExclGst && amtExclGst}
                            </td>
                          </tr>
                            <tr style={{ border: "2px solid black" }}>
                              <td
                                style={{
                                  border: "2px solid black",
                                  textAlign: "left",
                                }}
                              >
                                IGST Amount
                              </td>
                              <td
                                style={{
                                  border: "2px solid black",
                                  textAlign: "right",
                                }}
                              >
                                {IGSTAmt && IGSTAmt.toFixed(2)}
                              </td>
                            </tr>
                          <tr style={{ border: "2px solid black" }}>
                            <td
                              style={{
                                border: "2px solid black",
                                textAlign: "left",
                              }}
                            >
                              <b>Total Amount</b>
                            </td>
                            <td
                              style={{
                                border: "2px solid black",
                                textAlign: "right",
                              }}
                            >
                              {amtInclGst}
                            </td>
                          </tr>
                          <tr style={{ border: "2px solid black" }}>
                            <td
                              style={{
                                border: "2px solid black",
                                textAlign: "left",
                              }}
                            >
                              Discount/Coupon
                            </td>
                            <td
                              style={{
                                border: "2px solid black",
                                textAlign: "right",
                              }}
                            >
                              {orderdetails["revised_order_disc"]
                                ? orderdetails["revised_order_disc"]
                                : orderdetails["order_disc"]}
                            </td>
                          </tr>
                          <tr style={{ border: "2px solid black" }}>
                            <td
                              style={{
                                border: "2px solid black",
                                textAlign: "left",
                              }}
                            >
                              SZ Wallet Paid
                            </td>
                            <td
                              style={{
                                border: "2px solid black",
                                textAlign: "right",
                              }}
                            >
                              {orderdetails["cashback_redeemed"]}
                            </td>
                          </tr>
                          <tr style={{ border: "2px solid black" }}>
                            <td
                              style={{
                                border: "2px solid black",
                                textAlign: "left",
                              }}
                            >
                              SuperKredit/RazorPay
                            </td>
                            <td
                              style={{
                                border: "2px solid black",
                                textAlign: "right",
                              }}
                            >
                              {orderdetails["order_payment_mode"] == "Credit" ||
                              orderdetails["order_payment_mode"] ==
                                "Internal_Credit"
                                ? orderdetails["status"] == "Delivered"
                                  ? orderdetails["credit_amt"]
                                  : calcSuperKreditAmt()
                                : isAlreadyPaid(
                                    orderdetails["order_payment_mode"]
                                  )
                                ? orderdetails["net_order_amt"]
                                : 0}
                            </td>
                          </tr>
                          <tr style={{ border: "2px solid black" }}>
                            <td
                              style={{
                                border: "2px solid black",
                                textAlign: "left",
                              }}
                            >
                              <b>Net Amount Payable</b>
                            </td>
                            <td
                              style={{
                                border: "2px solid black",
                                textAlign: "right",
                              }}
                            >
                              <b>
                                {Math.round(
                                  Number(amtInclGst) -
                                    Number(
                                      Number(getOrderDiscount()) +
                                        (Number(
                                          orderdetails["cashback_redeemed"]
                                        ) || 0)
                                    )
                                ).toFixed(2)}
                              </b>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                      <br />
                      <br />
                      <br />
                      For Retranz Infolabs Private Limited
                      <br />
                      <br />
                      <br />
                      <i>(Authorized Signatory)</i>
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      {getBackDrop ? <Backdrop parentLoadStatus={getBackDrop} /> : null}
    </>
  );
};

export default StockMovementPrint;
