import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  InputGroupAddon,
  Input,
  InputGroupText,
  CardHeader,
  CardTitle,
  CardText,
} from "reactstrap";
import {
  Tab,
  Row,
  Col,
  Form,
  Dropdown,
  ProgressBar,
  Tabs,
  Image,
  InputGroup,
  Button,
  DropdownButton,
  Breadcrumb,
  Table,
  ButtonGroup,
  Container,
  Accordion,
  Card,
  Navbar,
} from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { faPrint } from "@fortawesome/free-solid-svg-icons";

import { FaPencilAlt } from "react-icons/fa";
import ShowLoosePackBagModal from "./ShowLoosePackBag";
import UpdateLoosePackModal from "./UpdateLoosePack";
import ShowLoosePackBagDetails from "./ShowLoosePackBagDetails";
import LoosePackingPrintBody from "./LoosePackingPrintBody";
import { IconButton } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function objectsAreSame(x, y) {
  var objectsAreSame = true;
  for (var propertyName in x) {
    if (x[propertyName] !== y[propertyName]) {
      objectsAreSame = false;
      break;
    }
  }
  return objectsAreSame;
}

const LoosePackingTableHeader = (props) => {
  return (
    <thead className="thead-light">
      <tr>
        <th>Sr No</th>

        {props.orderType && <th>Route</th>}
        <th>OrderNumber</th>
        <th>Shop Name</th>
        <th>Area</th>
        <th>Order Value</th>
        <th>Bag-ID</th>
        <th>No of Bags</th>
        <th>Total weight of Bags</th>
        <th>Short Items</th>
        <th>Packed By</th>
        <th>Print</th>
      </tr>
    </thead>
  );
};

const LoosePackingTableBody = (props) => {
  const [rows, setRows] = useState([]);
  const [showViewBagDetailsModal, setShowViewBagDetailsModal] = useState(false);
  const [loosePackBagDetails, setLoosePackBagDetails] = useState("");
  const [rowIndex, setRowIndex] = useState();
  // const [loosePackingOrdersDetailsData, setLoosePackingOrdersDetailsData] =
  //   useState();

  const [showOrderBags, setShowOrderBags] = useState(false);
  const [loosePackBagInfo, setLoosePackBagInfo] = useState({});

  const loosePackingOrdersDetailsData = props.loosePackingOrdersData;
  const printComponentRef = [];

  // if (
  //   objectsAreSame(
  //     props.loosePackingOrdersData,
  //     loosePackingOrdersDetailsData
  //   ) == false
  // ) {
  //   setLoosePackingOrdersDetailsData(props.loosePackingOrdersData);
  // }

  // const updateLoosePackOrdersList = (updatedData) => {
  //   let updatedLoosePackOrderList = [...loosePackingOrdersDetailsData];
  //   updatedLoosePackOrderList[rowIndex]["numbags"] = updatedData["numbags"];
  //   updatedLoosePackOrderList[rowIndex]["weightbags"] =
  //     updatedData["weightbags"];
  //   updatedLoosePackOrderList[rowIndex]["shortitem"] = updatedData["shortitem"];
  //   updatedLoosePackOrderList[rowIndex]["packedby"] = updatedData["packedby"];
  //   updatedLoosePackOrderList[rowIndex]["weight1"] = updatedData["weight1"];
  //   updatedLoosePackOrderList[rowIndex]["weight2"] = updatedData["weight2"];
  //   updatedLoosePackOrderList[rowIndex]["weight3"] = updatedData["weight3"];
  //   updatedLoosePackOrderList[rowIndex]["weight4"] = updatedData["weight4"];
  //   updatedLoosePackOrderList[rowIndex]["weight5"] = updatedData["weight5"];
  //   updatedLoosePackOrderList[rowIndex]["weight6"] = updatedData["weight6"];
  //   updatedLoosePackOrderList[rowIndex]["weight7"] = updatedData["weight7"];
  //   updatedLoosePackOrderList[rowIndex]["weight8"] = updatedData["weight8"];
  //   updatedLoosePackOrderList[rowIndex]["weight9"] = updatedData["weight9"];
  //   updatedLoosePackOrderList[rowIndex]["weight10"] = updatedData["weight10"];
  //   updatedLoosePackOrderList[rowIndex]["bagid"] = updatedData["bagid"];
  //   updatedLoosePackOrderList[rowIndex]["flagchecked"] =
  //     updatedData["flagchecked"];
  //   setLoosePackingOrdersDetailsData(updatedLoosePackOrderList);
  // };

  useEffect(() => {
    if (loosePackingOrdersDetailsData) {
      setRows(
        loosePackingOrdersDetailsData.map((row, index) => {
          return (
            <tr key={index}>
              <td>{index + 1}</td>
              {props.orderType && <td>{row.route}</td>}
              <td>{row.order_number}</td>
              <td>{row.shop_name}</td>
              <td>{row.area}</td>
              <td>{row.net_order_amt}</td>
              <td>{row.bagid}</td>
              <td>
                {row.bag_items ? (
                  <Button
                    onClick={() => {
                      setShowViewBagDetailsModal(true);
                      setLoosePackBagDetails(row);
                      setRowIndex(index);
                    }}
                  >
                    {Object.keys(row.bag_items).length}
                  </Button>
                ) : (
                  ""
                )}
              </td>
              <td>
                {row.weightbags && !isNaN(row.weightbags)
                  ? `${Number.parseFloat(row.weightbags).toFixed(2)} Kg(s)`
                  : ""}
              </td>
              <td>
                {row.short_items
                  ? row.short_items.join()
                  : row.shortitem
                  ? row.shortitem
                  : ""}
              </td>
              <td>{row.packedby}</td>
              {/* {props.isHistoricalDate == true && props.orderType ? (
                <td>
                  <Button
                    onClick={() => {
                      setShowViewBagDetailsModal(true);
                      setLoosePackBagDetails(row);
                      setRowIndex(index);
                    }}
                  >
                    {" "}
                    View
                  </Button>
                </td>
              ) : null} */}
              <td>
                {row.can_print ? (
                  <ReactToPrint
                    trigger={() => {
                      return <Button variant="primary">Print</Button>;
                    }}
                    content={() => printComponentRef[index]}
                  />
                ) : (
                  ""
                )}
              </td>

              <div style={{ display: "none" }}>
                <LoosePackingPrintBody
                  printComponentRef={printComponentRef}
                  loosePackingOrdersData={[row]}
                />
              </div>
            </tr>
          );
        })
      );
    }
  }, [loosePackingOrdersDetailsData]);

  return (
    <>
      <tbody>{rows}</tbody>
      {showOrderBags && (
        <ShowLoosePackBagModal
          show={showOrderBags}
          onHide={() => setShowOrderBags(false)}
          bagDetails={loosePackBagInfo}
          showOrderBags={showOrderBags}
        />
      )}

      {showViewBagDetailsModal && (
        <ShowLoosePackBagDetails
          show={showViewBagDetailsModal}
          onHide={() => setShowViewBagDetailsModal(false)}
          bagDetails={loosePackBagDetails}
          deliveryPlanDate={props.deliveryPlanDate}
        />
      )}
    </>
  );
};

const LoosePackingTable = (props) => {
  const {
    loosePackingOrdersData,
    deliveryPlanDate,
    isHistoricalDate,
    orderType,
  } = props;

  return (
    <table
      className="table table-striped"
      style={{ marginTop: 10, fontSize: "14px" }}
    >
      <LoosePackingTableHeader
        isHistoricalDate={isHistoricalDate}
        orderType={orderType}
      />
      <LoosePackingTableBody
        loosePackingOrdersData={loosePackingOrdersData}
        deliveryPlanDate={deliveryPlanDate}
        isHistoricalDate={isHistoricalDate}
        orderType={orderType}
      />
    </table>
  );
};

export default LoosePackingTable;
