import React, { useState, useEffect, useRef } from 'react'
import { Table, Form, Alert, Button, Modal } from 'react-bootstrap'
import database from '../../Configs/firebase'
import { toast } from 'react-toastify';
import Select from 'react-select';
import {support_portal_url} from '../../Configs/mysqlconfig';

function UpdateRetailers({ updateDetails, ...props }) {


    const Retailers_Details_Db = database.database().ref(`Retailer_Master_New`)
    const City_Area_Db = database.database().ref(`City_Area`)

    const [itemDetailsObject, setItemDetailsObject] = useState({})
    const [retailerData, setRetailerData] = useState("");
    const [cityData,setCityData]=useState([]);
    const [areaData,setAreaData]=useState([]);
    const [finalCityAreaData,setFinalCityAreaData]=useState([]);
    const [currentCity,setCurrentCity]=useState("");


    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_LEFT,
    });

    useEffect(() => {
      Promise.all([
        Retailers_Details_Db.child(`${updateDetails.user_name}`).once('value'),
        City_Area_Db.once('value')
      ]).then(([retailerSnapShot, cityAreaSnapShot]) => {

        if (retailerSnapShot.val()) {
          let retailerdata=retailerSnapShot.val();
          console.log(retailerdata)
          setRetailerData(retailerdata);

          if (cityAreaSnapShot.val()) {
            let cityAreaObj=cityAreaSnapShot.val();
            let cityList=[];
            let cityWiseAreaList=[];
            let allAreaList=[];
            cityList.push({"value":"Mumbai","label":"Mumbai"});
            Object.keys(cityAreaObj).map((key,value)=>{
              cityList.push({"value":key,"label":key});

              let cityWiseAreaObj=cityAreaObj[key];
              let areaList=[];
              Object.keys(cityWiseAreaObj).map((akey,avalue)=>{
                areaList.push(cityWiseAreaObj[akey]);
                allAreaList.push(cityWiseAreaObj[akey]);
              });
              cityWiseAreaList.push({"city":key,"arealist":areaList});
            });
            console.log(cityList);
            setCityData(cityList);
            cityWiseAreaList.push({"city":"Mumbai","arealist":allAreaList});
            console.log(cityWiseAreaList);
            setFinalCityAreaData(cityWiseAreaList);
            let selectedAreaList=[];
            for(let i=0;i<cityWiseAreaList.length;i++){
              if(cityWiseAreaList[i]['city']==retailerdata.city){
                let arealist=cityWiseAreaList[i]['arealist'];
                for(let j=0;j<arealist.length;j++){
                  selectedAreaList.push({"value":arealist[j],"label":arealist[j]})
                }
              }
            }

            console.log(selectedAreaList);

            setAreaData(selectedAreaList);
          }
        }
      });
    }, [])

    useEffect(()=>{
      console.log(itemDetailsObject.city);
      console.log(finalCityAreaData);

      let selectedAreaList=[];
      for(let i=0;i<finalCityAreaData.length;i++){
        if(finalCityAreaData[i]['city']==itemDetailsObject.city){
          let arealist=finalCityAreaData[i]['arealist'];
          for(let j=0;j<arealist.length;j++){
            selectedAreaList.push({"value":arealist[j],"label":arealist[j]})
          }
        }
      }

      console.log(selectedAreaList);
      setAreaData(selectedAreaList);
    },[itemDetailsObject.city])

    const updateRetailersDetails = () => {
        let updateRetailerDetailsObj = {};

        updateRetailerDetailsObj['user_name'] = (itemDetailsObject.user_name != undefined ? itemDetailsObject.user_name : retailerData.user_name)
        updateRetailerDetailsObj['area'] = (itemDetailsObject.area != undefined ? itemDetailsObject.area : retailerData.area)
        updateRetailerDetailsObj['sub_area1'] = (itemDetailsObject.sub_area1 != undefined ? itemDetailsObject.sub_area1 : retailerData.sub_area1?retailerData.sub_area1:retailerData.sub_area)
        updateRetailerDetailsObj['city'] = (itemDetailsObject.city != undefined ? itemDetailsObject.city : retailerData.city)
        updateRetailerDetailsObj['address1'] = (itemDetailsObject.address1 != undefined ? itemDetailsObject.address1 : retailerData.address1)
        updateRetailerDetailsObj['address2'] = (itemDetailsObject.address2 != undefined ? itemDetailsObject.address2 : retailerData.address2)
        updateRetailerDetailsObj['shop_name'] = (itemDetailsObject.shop_name != undefined ? itemDetailsObject.shop_name : retailerData.shop_name)
        updateRetailerDetailsObj['latitude'] = (itemDetailsObject.latitude != undefined ? itemDetailsObject.latitude : retailerData.latitude)
        updateRetailerDetailsObj['longitude'] = (itemDetailsObject.longitude != undefined ? itemDetailsObject.longitude : retailerData.longitude)
        updateRetailerDetailsObj['gst_number'] = (itemDetailsObject.gst_number != undefined ? itemDetailsObject.gst_number : retailerData.gst_number?retailerData.gst_number:"")

        console.log(updateRetailerDetailsObj);
        console.log(JSON.stringify(updateRetailerDetailsObj))
        console.log(typeof JSON.stringify(updateRetailerDetailsObj))

        //update in firebase
        Retailers_Details_Db.child(`${updateDetails.user_name}`).update(updateRetailerDetailsObj).then(() => {
            props.onHide();
            return toast("Updated Retailer Details in firebase", { type: toast.TYPE.SUCCESS })
        }).catch(() => {
            props.onHide();
            return toast("Couldn't update the retailer in firebase", { type: toast.TYPE.ERROR });
        })

        //update in mysql
        fetch(`${support_portal_url}/api/updateretailers`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': sessionStorage.getItem("apiToken")
            },
            body: JSON.stringify(updateRetailerDetailsObj)
        })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                if(data['success']==1){
                  props.onHide();
                  return toast("Updated Retailer Details in MySQL", { type: toast.TYPE.SUCCESS });
                }else{
                  props.onHide();
                  toast(`${data['message']}`, { type: toast.TYPE.ERROR });
                  if(data['status']==401 || data['status']==403){
                    sessionStorage.clear();
                    window.location.href="/"
                  }
                }


            })
            .catch((error) => {
                props.onHide();
                return toast("Couldn't update the retailer in MySQL", { type: toast.TYPE.ERROR });
            });
    }

  
  function handleLatLongValues(value) {
        const regex = /([0-9]*[\.]{0,1}[0-9]{0,10})/s;
        return value.match(regex)[0];
    }


    return (
        <div>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Update Retailers of {updateDetails.user_name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table>
                        <tr>
                            <td>Enter new address</td>
                            <td><Form.Control  defaultValue={retailerData.address1} onChange={(e) => {setItemDetailsObject({ ...itemDetailsObject, "address1": (e.target.value) })}} /></td>
                        </tr>
                        <tr>
                            <td>Enter new address2</td>
                            <td><Form.Control  defaultValue={retailerData.address2} onChange={(e) => setItemDetailsObject({ ...itemDetailsObject, "address2": (e.target.value) })} /></td>
                        </tr>
                        {retailerData.city && <tr>
                            <td>City</td>
                            <td><Select defaultValue={{"value":retailerData.city,"label":retailerData.city}} options={cityData} onChange={(val)=>{ setItemDetailsObject({...itemDetailsObject,"city":(val.value)}); }}/></td>
                        </tr>}
                        {retailerData.area && <tr>
                            <td>Area</td>
                            <td><Select defaultValue={{"value":retailerData.area,"label":retailerData.area}} options={areaData} onChange={(val)=>{ setItemDetailsObject({...itemDetailsObject,"area":(val.value)}); }}/></td>
                        </tr>}
                        <tr>
                            <td>SubArea1</td>
                            <td><Form.Control  defaultValue={retailerData.sub_area1?retailerData.sub_area1:retailerData.sub_area} onChange={(e) => setItemDetailsObject({ ...itemDetailsObject, "sub_area1": (e.target.value) })} /></td>
                        </tr>
                        <tr>
                            <td>Shop</td>
                            <td><Form.Control defaultValue={updateDetails.shop_name}  onChange={(e) => setItemDetailsObject({ ...itemDetailsObject, "shop_name": (e.target.value) })} /></td>
                        </tr>
                        <tr>
                            <td>Latitude</td>
                            <td><Form.Control  type='number' value={itemDetailsObject.latitude?itemDetailsObject.latitude:retailerData.latitude} onChange={(e) => setItemDetailsObject({ ...itemDetailsObject, "latitude": handleLatLongValues(e.target.value) })} /></td>
                        </tr>
                        <tr>
                            <td>Longitude</td>
                            <td><Form.Control type='number' value={itemDetailsObject.longitude?itemDetailsObject.longitude:retailerData.longitude} onChange={(e) => setItemDetailsObject({ ...itemDetailsObject, "longitude": handleLatLongValues(e.target.value) })} /></td>
                        </tr>
                        <tr>
                            <td>GST</td>
                            <td><Form.Control  defaultValue={retailerData.gst_number} onChange={(e) => setItemDetailsObject({ ...itemDetailsObject, "gst_number": (e.target.value) })} /></td>
                        </tr>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"success"} onClick={updateRetailersDetails}>Submit</Button>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default UpdateRetailers
