import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fire from '../../../Configs/firebase'
import { Tab, Row, Col, Nav, Form, Alert, ProgressBar, Tabs, Modal, InputGroup, Button, DropdownButton, Jumbotron, Table, ButtonGroup, Container, Accordion, Card, Navbar } from 'react-bootstrap'
import OrderSummary from './OrderSummaryPage'
import OrderDetails from './OrderDetailsPage'
import NavBar from '../../Navigation bar/Navbar';
import VehicleDetails from './VehicleDetailsPage'

function OrderReportModal(props) {
    return (
        <>
        <NavBar/>
        <div style={{paddingTop:100}}>
        <Alert style={{textAlign:"center"}} variant="info">Order Reports</Alert>
        <div>
            <Tabs defaultActiveKey="order_summary" id="uncontrolled-tab-example" unmountOnExit={true}>
                <Tab eventKey="order_summary" title="Order Summary">
                    <OrderSummary />
                </Tab>
                <Tab eventKey="order_details" title="Order Details">
                    <OrderDetails />
                </Tab>
                <Tab eventKey="vehicle_details" title="Vehicle Details">
                    <VehicleDetails/>
                </Tab>
            </Tabs>
        </div></div></>
    );



}

export default OrderReportModal
