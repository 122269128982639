import React from "react";
import DatePicker from "react-datepicker";
import getid from '../components/getVehicleID'
import "react-datepicker/dist/react-datepicker.css";

// CSS Modules, react-datepicker-cssmodules.css
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

class DateUI extends React.Component {


  constructor(props) {
      super(props)

      this.state = {
        startDate: new Date()
      }

      let currdate=new Date();
      this.props.loading(true)
      getid(currdate,currdate.getFullYear(),currdate.getMonth()+1,currdate.getDate()+'-'+(currdate.getMonth()+1)+'-'+currdate.getFullYear(),this.setDateOfParent,this.props.loading)
  }

  // if(this.state.startDate==new Date()){
  //   console.log('yes');
  //   console.log(default date);
  //
  //     let currdate=new Date();
  //     this.props.loading(true)
  //     getid(currdate,currdate.getFullYear(),currdate.getMonth()+1,currdate.getDate()+'-'+(currdate.getMonth()+1)+'-'+currdate.getFullYear(),this.setDateOfParent,this.props.loading)
  // }


  vehicle_status = (data)=>{
      this.props.vehicleCallback(data)
  }

  setDateOfParent = (value,data,driver_name,routeId)=>{
    this.props.parentCallback(value,data,driver_name,routeId)
    console.log(routeId)
  }

  handleChange =  date => {
    this.setState({
      startDate: date
    });
    this.props.parentCallback(date,[],[])
    let user = sessionStorage.getItem('user')
    console.log('date ui');
    console.log(date);
    sessionStorage.clear()
    sessionStorage.setItem('user',user)
    this.props.loading(true)
    getid(date,date.getFullYear(),date.getMonth()+1,date.getDate()+'-'+(date.getMonth()+1)+'-'+date.getFullYear(),this.setDateOfParent,this.props.loading)


  };

  render() {
    return (
      <DatePicker
        selected={this.state.startDate}
        onChange={this.handleChange}
        minDate={new Date("2017/01/01")}
        maxDate={new Date()}
      />
    );
  }
}

export default DateUI
