import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Dropdown, Navbar, Jumbotron, InputGroup, Button, DropdownButton, ButtonGroup, Table } from 'react-bootstrap'
import { InputGroupAddon } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import XLSX from 'xlsx'
import Backdrop from '../../backdrop/backdrop'

function VehicleDetailsPage() {

    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [vehicleDetails, setVehicleDetails] = useState("")
    const [exportData, setExportData] = useState(false)
    const [vehicleSearch, setVehicleSearch] = useState(false)
    const [getBackDrop, setBackDrop] = useState(false)
    const [vehicleRenderList, setVehicleRenderList] = useState("")



    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_CENTER,
    });

    const notify = (unavailability) => {

        if (unavailability)
            return toast("No Vehicle Details Available for selected date range ", { type: toast.TYPE.ERROR })

    }


    useEffect(() => {

        setBackDrop(true)
        const startDateConversion = `${startDate.getFullYear()}-${startDate.getMonth() + 1 < 10 ? "0" + (startDate.getMonth() + 1) : startDate.getMonth() + 1}-${startDate.getDate() < 0 ? "0" + startDate.getDate() : startDate.getDate()}`
        const endDateConversion = `${endDate.getFullYear()}-${endDate.getMonth() + 1 < 10 ? "0" + (endDate.getMonth() + 1) : endDate.getMonth() + 1}-${endDate.getDate() < 0 ? "0" + endDate.getDate() : endDate.getDate()}`
        fetch(`http://services2.superzop.com:3000/api/superzop/delivery/driverallocationfordaterange?start_date=${startDateConversion}&end_date=${endDateConversion}`,{ headers: { 'x-access-token' : sessionStorage.getItem('x-access-token') } }).then(res => {
            return res.json()
        }).then(data => { console.log(data); setVehicleDetails(data); setBackDrop(false); if (!data.length > 0) { notify(true) } }).catch(err => console.log(err))

    }, [])



    const handleDateRangeChange = () => {

        if (startDate > endDate) {

            setBackDrop(false)
            setVehicleDetails("")
            return toast("Please enter a valid date range", { type: toast.TYPE.ERROR })

        }
        const startDateConversion = `${startDate.getFullYear()}-${startDate.getMonth() + 1 < 10 ? "0" + (startDate.getMonth() + 1) : startDate.getMonth() + 1}-${startDate.getDate() < 0 ? "0" + startDate.getDate() : startDate.getDate()}`
        const endDateConversion = `${endDate.getFullYear()}-${endDate.getMonth() + 1 < 10 ? "0" + (endDate.getMonth() + 1) : endDate.getMonth() + 1}-${endDate.getDate() < 0 ? "0" + endDate.getDate() : endDate.getDate()}`
        fetch(`http://services2.superzop.com:3000/api/superzop/delivery/driverallocationfordaterange?start_date=${startDateConversion}&end_date=${endDateConversion}`,{ headers: { 'x-access-token' : sessionStorage.getItem('x-access-token') } }).then(res => {
            return res.json()
        }).then(data => { console.log(data); setVehicleDetails(data); setVehicleSearch(false); setBackDrop(false); if (!data.length > 0) { notify(true) } }).catch(err => console.log(err))
    }




    useEffect(() => {
        if (vehicleSearch)
            handleDateRangeChange()
    }, [vehicleSearch])



    useEffect(() => {
        if (vehicleDetails.length > 0){
            vehicleDetails.forEach(items=>{
                Object.keys(items).forEach(objectVal=>{

                    Reflect.deleteProperty(items, "createdAt")
                    Reflect.deleteProperty(items, "updatedAt")
                    Reflect.deleteProperty(items, "unique_key")
                })
            })
            setVehicleRenderList(vehicleDetails)}
    }, [vehicleDetails])
    

    let vehicleDetailsRecords = ["Lead Delivery Name", "Lead Delivery Phone", "Current Route Id", "Date", "Driver Name", "Driver Phone", "Helper Boy Name","Helper Boy Phone","Helper Boy-2 Name","Helper Boy-2 Phone",
        "Loose Allocated By", "Vehicle Dispatch Time", "Vehicle Loaded By", "Vehicle Number"]

    const vehicleDetailsArray = ["current_route_id", "actual_driver_name", "actual_driver_phone",
        "date", "driver_name", "driver_phone_number", "helper_boy_name","helper_boy_phone", "helper_2","helper_2_phone",
        "vehicle_loaded_by", "loose_allocated_by",
        "vehicle_dispatch_time", "vehicle_number"]


    const exportVehicleDetails = () => {

        const wb = XLSX.utils.book_new()
        setBackDrop(true)
        let exportVehicles = [vehicleDetailsRecords]
        vehicleDetails.map(item => {
            let exportVehicleFields = []
            Object.keys(item).map(itemListField => {
                exportVehicleFields.push(item[itemListField])
            })
            exportVehicles.push(exportVehicleFields)
        })
        const exportVehicleSheet = XLSX.utils.aoa_to_sheet(exportVehicles)
        XLSX.utils.book_append_sheet(wb, exportVehicleSheet, `Vehicle Details`)
        XLSX.writeFile(wb, `Vehicle_Details.xlsx`)
        setExportData(false)
        setBackDrop(false)

    }

    useEffect(() => {
        if (exportData)
            exportVehicleDetails()
    }, [exportData])
    return (
        <div style={{ width: "5000px" }}>
            <Navbar className="bg-light mr-auto justify-content-between" >
                <Form inline>
                    <InputGroup size="sm" style={{ marginLeft: 10, marginRight: 20 }}>
                        <InputGroupAddon addonType="prepend">Select Start Date</InputGroupAddon>
                        <DatePicker
                            selected={startDate}
                            onChange={(val) => { setStartDate(val) }}
                            minDate={new Date("2017/01/01")}
                            maxDate={new Date()}
                            aria-describedby="basic-addon1"
                            dateFormat="dd/MM/yyyy"

                        />
                    </InputGroup>
                    <InputGroup size="sm" style={{ marginLeft: 10, marginRight: 10 }}>
                        <InputGroupAddon addonType="prepend">Select End Date</InputGroupAddon>
                        <DatePicker
                            selected={endDate}
                            onChange={(val) => { setEndDate(val) }}
                            minDate={new Date("2017/01/01")}
                            maxDate={new Date()}
                            aria-describedby="basic-addon1"
                            dateFormat="dd/MM/yyyy"

                        />
                    </InputGroup>
                    <Button size="sm" onClick={() => { setVehicleSearch(true); setBackDrop(true) }}>Search Vehicles</Button>
                    <InputGroup size="sm" style={{ marginLeft: 200, marginRight: 50 }}>
                        <Button size="sm" onClick={() => setExportData(true)}>Export</Button>
                    </InputGroup>
                </Form>
            </Navbar>
            {vehicleDetails.length > 0 && vehicleDetails.length < 1000 &&
                <Table>
                    <Jumbotron>
                        <thead>
                            <tr>{vehicleDetailsRecords.map(items => (
                                <td><center>{(items)}</center></td>
                            ))}
                            </tr>
                        </thead>
                        <tbody>
                            {vehicleRenderList.length > 0 && vehicleDetails.length < 1000 && vehicleRenderList.map((items, index) => (
                                <tr>
                                    {Object.keys(items).map(itemListField => (
                                        <td><center>{(items[itemListField])}</center></td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </Jumbotron>
                </Table>
            }
            {getBackDrop ? (<Backdrop parentLoadStatus={getBackDrop} />) : (null)}

        </div>
    )
}

export default VehicleDetailsPage
