import React, { useState, useEffect } from "react";
import { Table, Form, Alert, Button, Modal } from "react-bootstrap";

function ShowLoosePackBagModal({ bagDetails, showOrderBags, ...props }) {
  const { bag_items, bagid: bag_id } = bagDetails;
  console.log({ bag_items });

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Bag Details of {bagDetails.order_number}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Object.keys(bag_items).length > 0 ? (
            <Table>
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>Weight</th>
                  <th>Picked Qty</th>
                  <th>ID</th>
                </tr>
              </thead>

              <tbody>
                {Object.keys(bag_items).length > 0
                  ? Object.keys(bag_items).map((bagId, index) => {
                      return (
                        <>
                          {bag_items[bagId].map((item, index) => {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{item["weight"] + " Kg(s)"}</td>
                                <td>x {item["picked_qty"]}</td>
                                <td>{bagId}</td>
                              </tr>
                            );
                          })}
                        </>
                      );
                    })
                  : null}
              </tbody>
            </Table>
          ) : (
            <div></div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ShowLoosePackBagModal;
