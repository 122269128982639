import React, { useState, useEffect, useRef } from 'react';
import { Breadcrumb, Navbar, Dropdown, DropdownButton, Alert, Table, Button, Form, Jumbotron, InputGroup, Accordion, Toast, Badge } from 'react-bootstrap';
import { InputGroupAddon, Input } from 'reactstrap';
import NavBar from '../Navigation bar/Navbar'
import Backdrop from '../backdrop/backdrop'
import { toast } from 'react-toastify';
import SearchLoosePackTable from './searchloosepacktable';
import {support_portal_url} from '../../Configs/mysqlconfig';

const SearchLoosePack = () => {
  console.log('SearchLoosePack');
  const [searchText, setSearchText] = useState("");
  const [loosePackOrders,setLoosePackOrders] = useState([]);
  const [backDrop, setBackDrop] = useState(true)

  useEffect(() => {
    setBackDrop(false)
  }, [])

  useEffect(() => {
      if (searchText){
        fetchLoosePackOrders();
      }else{
        setLoosePackOrders([]);
      }
  }, [searchText])

  const fetchLoosePackOrders = () => {
    let apiurlstr=`${support_portal_url}/api/searchloosepackorders?search=${searchText}&token=${sessionStorage.getItem("apiToken")}`
    fetch(apiurlstr)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      console.log(data);
      if(data['success']==1){
        setBackDrop(false)
        setLoosePackOrders(data['data']);
      }else{
        setBackDrop(false)
        setLoosePackOrders([]);

        let errMessage=data['message']?data['message']:"";
        alert(errMessage);
        if(data['status']==401 || data['status']==403){
          sessionStorage.clear();
          window.location.href="/"
        }
      }
    })
    .catch((error) => {
      alert(error)
      setBackDrop(false)
      setLoosePackOrders([]);
    });
  }

  const handleKeyPress = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <>
    <NavBar/>
      <Navbar className="bg-light m-auto justify-content-center" style={{ paddingTop: 100,textAlign: "center"}}>
        <h3>Loose Packing Orders</h3>
        </Navbar>
        <Form.Control style={{ marginTop: 30,marginBottom: 30, marginLeft: "auto", marginRight: "auto", width:"30%"}} type="search" placeholder="Search OrderNumber / Bag ID " value={searchText} onChange={(e)=>{ handleKeyPress(e); }}/>

        {(loosePackOrders.length>0)?(<SearchLoosePackTable loosePackOrdersData={loosePackOrders} />):((backDrop || !searchText)?(""):(<div>No loose pack orders</div>))}

          {backDrop?(<Backdrop parentLoadStatus={backDrop}/>):(null)}
    </>
  )
}

export default SearchLoosePack
