import React, { useEffect, useState } from "react";
import moment from "moment";

import {
  Modal,
  Button,
  Table,
  Figure,
  Dropdown,
  DropdownButton,
  Row,
  Col,
} from "react-bootstrap";

const VehicleStatusModal = (props) => {
  const show = props.show;
  const setShow = props.setShow;
  const allocationHistoryRaw = props.allocationHistoryRaw;
  const ordersHistoryRaw = props.ordersHistoryRaw;
  const cityList = props.cityList;

  const [selectedCityFilter, setSelectedCityFilter] = useState("All");
  const [selectedCompanyFilter, setSelectedCompanyFilter] = useState("All");
  const [cityFilterOptions, setCityFilterOptions] = useState([]);
  const [companyFilterOptions, setCompanyFilterOptions] = useState([]);
  const [showUnallocatedRoutes, setShowUnallocatedRoutes] = useState(false);

  const [tableData, setTableData] = useState([]);

  function generateCityFilterOptions(table_data) {
    let unique_options = ["All"];
    table_data.forEach((item, index) => {
      if (!item.city) {
        return;
      }
      if (!unique_options.includes(item.city)) {
        unique_options.push(item.city);
      }
    });
    return unique_options;
  }

  function generateCompanyFilterOptions(table_data) {
    let unique_options = ["All"];
    table_data.forEach((item, index) => {
      if (!item.transporter) {
        return;
      }
      if (!unique_options.includes(item.transporter)) {
        unique_options.push(item.transporter);
      }
    });
    return unique_options;
  }

  function populateTableData(allocation_history, orders_history, city_list) {
    if (!allocation_history || !city_list) {
      console.log("Error : Missing arguements");
    }
    const table_data = [];
    Object.keys(allocation_history).forEach((key, index) => {
      const route_data = {};
      let route_no = allocation_history[key].current_Route_Id;
      let city = city_list[route_no.slice(0, 2)]?.city || "N/A";
      let region = city_list[route_no.slice(0, 2)]?.region || "N/A";
      route_data["city"] = city;
      route_data["region"] = region;
      route_data["route_no"] = route_no;
      route_data["driver_name"] = allocation_history[key].actual_Driver_Name;
      route_data["transporter"] = allocation_history[key].company_type;
      route_data["driver_no"] = allocation_history[key].driver_Phone_Number;
      table_data.push(route_data);
    });

    Object.keys(orders_history).forEach((key, index) => {
      if (
        !table_data.find((element) => {
          return element.route_no === key;
        })
      ) {
        const route_data = {};
        let route_no = key;
        let city = city_list[route_no.slice(0, 2)]?.city || "N/A";
        let region = city_list[route_no.slice(0, 2)]?.region || "N/A";
        route_data["city"] = city;
        route_data["region"] = region;
        route_data["route_no"] = route_no;
        table_data.push(route_data);
      }
    });

    table_data.sort(function (a, b) {
      var keyA = a.route_no,
        keyB = b.route_no;
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
    return table_data;
  }

  const FilterRoutesCheckbox = () => {
    return (
      <>
        <input
          style={{ height: "15px", width: "15px" }}
          type="checkbox"
          id="routeFilter"
          name="routeFilter"
          value="routeFilter"
          checked={showUnallocatedRoutes}
          onChange={(event) => {
            setShowUnallocatedRoutes(event.target.checked);
          }}
        />
        <label
          style={{ marginLeft: "5px", fontWeight: "bold" }}
          htmlFor="routeFilter"
        >
          Also show unallocated routes
        </label>
      </>
    );
  };

  useEffect(() => {
    const table_data = populateTableData(
      allocationHistoryRaw,
      ordersHistoryRaw,
      cityList
    );
    const city_options = generateCityFilterOptions(table_data);
    const company_options = generateCompanyFilterOptions(table_data);
    setCityFilterOptions(city_options);
    setCompanyFilterOptions(company_options);
    setTableData(table_data);
  }, []);

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
      }}
      id="status_details_modal"
      dialogClassName="status_details_modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Vehicle Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FilterRoutesCheckbox />
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th style={{ verticalAlign: "middle" }}>Route</th>
              <th style={{ verticalAlign: "middle" }}>Region</th>
              <th>
                Warehouse{" "}
                <DropdownButton
                  style={{ marginTop: "5px" }}
                  id="dropdown-basic-button"
                  title={
                    selectedCityFilter != "All" ? selectedCityFilter : "All"
                  }
                  size="sm"
                  variant="info"
                  onSelect={() => {
                    // setMapOrdersData([]);
                  }}
                >
                  {cityFilterOptions.map((item, index) => {
                    return (
                      <Dropdown.Item
                        key={item}
                        name={item}
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedCityFilter(e.target.name);
                        }}
                      >
                        {item}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
              </th>
              <th style={{ verticalAlign: "middle" }}>Driver</th>
              <th>
                Company
                <DropdownButton
                  style={{ marginTop: "5px" }}
                  id="dropdown-basic-button"
                  title={
                    selectedCompanyFilter != "All"
                      ? selectedCompanyFilter
                      : "All"
                  }
                  size="sm"
                  variant="info"
                  onSelect={() => {
                    // setMapOrdersData([]);
                  }}
                >
                  {companyFilterOptions.map((item, index) => {
                    return (
                      <Dropdown.Item
                        key={item}
                        name={item}
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedCompanyFilter(e.target.name);
                        }}
                      >
                        {item}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
              </th>
              <th style={{ verticalAlign: "middle" }}>Status</th>
              <th style={{ verticalAlign: "middle" }}>Time</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((item, index) => {
              //Check filters
              if (
                selectedCityFilter !== "All" &&
                item.city !== selectedCityFilter
              ) {
                return undefined;
              }

              if (
                selectedCompanyFilter !== "All" &&
                item.transporter !== selectedCompanyFilter
              ) {
                return undefined;
              }

              /////////////

              let driver_allocation_info = allocationHistoryRaw[item.driver_no];
              let status = "Assigned";
              const all_status_time = {
                assigned_time: "N/A",
                dispatched_time: "N/A",
                delivering_time: "N/A",
                delivery_complete_time: "N/A",
                reported_time: "N/A",
                unloading_complete_time: "N/A",
                consolidation_complete_time: "N/A",
              };

              let status_time = "N/A";

              if (driver_allocation_info) {
                status_time = driver_allocation_info["allocation_Time"]
                  ? moment(
                      Number(driver_allocation_info["allocation_Time"])
                    ).format("HH:mm") || "N/A"
                  : "N/A";
                all_status_time.assigned_time = status_time;
                if (driver_allocation_info["image1"]) {
                  status = "Dispatched";
                  status_time = driver_allocation_info["Vehicle_Dispatch_Time"];
                  all_status_time.dispatched_time = status_time;
                }
                if (isDriverDelivering(ordersHistoryRaw[item.route_no])) {
                  status = "Delivering";
                  status_time =
                    driver_allocation_info["Vehicle_Dispatch_Time"] || "N/A";
                  all_status_time.delivering_time = status_time;
                }
                if (
                  driver_allocation_info["completed_delivery"] &&
                  driver_allocation_info.completed_delivery === "true"
                ) {
                  status = "Delivery complete";
                  status_time =
                    moment(
                      Number(
                        driver_allocation_info["endDeliveryFlag_updatedAt"]
                      )
                    ).format("HH:mm") || "N/A";
                  all_status_time.delivery_complete_time = status_time;
                }
                if (driver_allocation_info["DB_Return_Time"]) {
                  status = "Reported";
                  status_time = driver_allocation_info["DB_Return_Time"];
                  all_status_time.reported_time = status_time;
                }
                if (driver_allocation_info["DB_Release_Time"]) {
                  status = "Unloading complete";
                  status_time = driver_allocation_info["DB_Release_Time"];
                  all_status_time.unloading_complete_time = status_time;
                }
                if (
                  driver_allocation_info["completed_conslidation"] &&
                  driver_allocation_info.completed_conslidation === true
                ) {
                  status = "Consolidation complete";
                  status_time =
                    moment(
                      Number(
                        driver_allocation_info[
                          "completed_conslidation_updatedAt"
                        ]
                      )
                    ).format("HH:mm") || "N/A";
                  all_status_time.consolidation_complete_time = status_time;
                }
              } else if (!driver_allocation_info && !showUnallocatedRoutes) {
                return undefined;
              } else {
                status = "N/A";
              }
              return (
                <TableItemToRender
                  key={index}
                  item={item}
                  status={status}
                  status_time={status_time}
                  all_status_time={all_status_time}
                />
              );
            })}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setShow(false);
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const TableItemToRender = ({ item, status, status_time, all_status_time }) => {
  const [showTimeHistory, setShowTimeHistory] = useState(false);

  return (
    <tr>
      <td>{item.route_no || "-"}</td>
      <td>{item.region || "-"}</td>
      <td>{item.city || "-"}</td>
      <td>
        {item.driver_no || ""} - {item.driver_name || ""}
      </td>
      <td>{item.transporter || "-"}</td>
      <td>{status || "-"}</td>
      <td>
        <span
          onClick={(event) => {
            setShowTimeHistory((oldVal) => {
              return !oldVal;
            });
          }}
          className="history_toggle"
        >
          Toggle history
        </span>
        {showTimeHistory ? (
          <table style={{ fontSize: "12px", width: "130px" }}>
            <tbody>
              <tr>
                <td>Assigned</td>
                <td>{all_status_time.assigned_time}</td>
              </tr>
              <tr>
                <td>Dispatched</td>
                <td>{all_status_time.dispatched_time}</td>
              </tr>
              <tr>
                <td>Delivering</td>
                <td>{all_status_time.delivering_time}</td>
              </tr>

              <tr>
                <td>Delivery comp</td>
                <td>{all_status_time.delivery_complete_time}</td>
              </tr>
              <tr>
                {" "}
                <td>Reported</td>
                <td>{all_status_time.reported_time}</td>{" "}
              </tr>
              <tr>
                <td>Unloading</td>
                <td>{all_status_time.unloading_complete_time}</td>
              </tr>
              <tr>
                <td>Consolidation</td>
                <td>{all_status_time.consolidation_complete_time}</td>
              </tr>
            </tbody>
          </table>
        ) : (
          status_time
        )}
      </td>
    </tr>
  );
};

function isDriverDelivering(orders) {
  for (let i = 0; i < Object.keys(orders).length; i++) {
    let order_key = Object.keys(orders)[i];
    if (
      orders[order_key].status !== "Shipped" &&
      orders[order_key].status !== "Shipped-Modified" &&
      orders[order_key].status !== "Processed" &&
      orders[order_key].status !== "Hold"
    ) {
      return true;
    }
  }
  return false;
}

export default VehicleStatusModal;
