import React, { useState, useEffect } from 'react'
import { Table, Form, Alert, Button,Modal } from 'react-bootstrap'
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import Backdrop from '../backdrop/backdrop'

function RevisedOrderDiscountModalPopup({revisedOrderDiscToBeUpdated,driver_id,order_id,...props}) {
  const [loading,setLoading] = useState(false);
  const [revisedDisc,setRevisedDisc] = useState(revisedOrderDiscToBeUpdated);

  const updateRevisedDiscValue = () => {
    let revisedOrderDisc=Number(revisedDisc);
    revisedOrderDisc=revisedOrderDisc.toFixed(2);
    props.callback(driver_id,order_id,revisedOrderDisc);
    props.onHide();
  }

    return (<>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    Total Amount
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Table>
                    <tbody>
                        <tr>
                            <td>Revised Order Discount</td>
                            <td><Form.Control type="text" defaultValue={revisedDisc} onChange={(e)=>{ setRevisedDisc(e.target.value); }}/></td>
                        </tr>
                    </tbody>
                </Table>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={()=>{updateRevisedDiscValue(); }}>OK</Button>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
            {loading ? (<Backdrop parentLoadStatus={loading} />) : (null)}
        </>
    )
}

export default RevisedOrderDiscountModalPopup
