import React, { useState, useEffect } from "react";
import fire from "../../Configs/firebase";
import {
  Map,
  GoogleApiWrapper,
  Polyline,
  Marker,
  InfoWindow,
  Circle,
} from "google-maps-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import Geocode from "react-geocode";
import { Button, Row, Col } from "react-bootstrap";
import FooterComponent from "../Footer/footer";
import UpdateWarehouseModal from "./UpdateWarehouseModal";
import UpdateVehicleOrderPriorityModal from "./UpdateVehiclePriority";

let wareHouseLatLng = { lat: 19.070279, lng: 73.005516 };
let wareHouseLatLongDict = {
  surat: { lat: 21.1966092, lng: 72.8545132 },
  pune: { lat: 18.5247663, lng: 73.7929267 },
  nashik: { lat: 19.9910547, lng: 73.5163678 },
  bhiwandi: { lat: 19.2818569, lng: 73.0264525 },
  wadala: { lat: 19.0139451, lng: 72.8524243 },
  virar: { lat: 19.4796228, lng: 72.896112 },
  vashi: { lat: 19.0617039, lng: 72.9734505 },
  andheri: { lat: 19.23531669919817, lng: 73.03272326848142 },
};
Geocode.setApiKey("AIzaSyBhWjIBx_EM-hyCJlg2zbfoYpyaxmPHmKs");

const VehicleRoutingMapOrders = ({
  mapOrdersData,
  selectedVehicleId,
  routeDate,
  cityList,
  driverInfo,
  selectedWarehouse,
  refetchVehicleDetails,
  selectedRouteId,
  orderListForSelectedRoute,
  ...props
}) => {
  const [mapCenter, setMapCenter] = useState(
    wareHouseLatLongDict[selectedWarehouse.toLowerCase()] || wareHouseLatLng
  );
  const [mapZoom, setMapZoom] = useState(10);
  const [activeMarker, setActiveMarker] = useState({});
  const [isInfoWindowOrderOpened, setIsInfoWindowOrderOpened] = useState(false);
  const [mapOrderDetails, setMapOrderDetails] = useState({});
  const [vmapOrderDetails, setVmapOrderDetails] = useState({});
  const [selectedOrderLatLng, setSelectedOrderLatLng] = useState({});
  const [vehicleCurrentLocation, setVehicleCurrentLocation] = useState({});
  const [mapVehicleLocationTraversed, setMapVehicleLocationTraversed] =
    useState([]);
  const [
    mapVehicleLocationTraversedWithTime,
    setMapVehicleLocationTraversedWithTime,
  ] = useState([]);
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [vehicleMarkerTime, setVehicleMarkerTime] = useState(null);
  const [vehicleActiveMarker, setVehicleActiveMarker] = useState({});

  const [updateOrder, setUpdateOrder] = useState(false);
  const [showUpdateWarehouseModal, setShowUpdateWarehouseModal] = useState();
  const isAdminUser = sessionStorage.getItem("originalUserType") === "admin";
  const isOperationUser = sessionStorage.getItem("originalUserType") === "operation";

  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });

  useEffect(() => {
    function getCurrentDriverLocation() {
      let year = routeDate.getFullYear();
      let month = routeDate.getMonth() + 1;
      let date = routeDate.getDate() + "-" + month + "-" + year;
      let phone = selectedVehicleId.split("-")[0];
      const vehicleLatestLocationRef = fire
        .database()
        .ref(
          "Vehicle_Location/" +
            year +
            "/" +
            month +
            "/" +
            date +
            "/" +
            phone +
            "/"
        );

      vehicleLatestLocationRef
        .orderByChild("time_stamp")
        .limitToLast(1)
        .once("value", (snapshot) => {
          let vehicledata = snapshot.val();
          if (vehicledata) {
            // console.log(vehicledata);
            let keyy = Object.keys(vehicledata)[0];
            let currLocation = vehicledata[keyy];
            // console.log(currLocation);
            setVehicleCurrentLocation({
              lat: currLocation.latitude,
              lng: currLocation.longitude,
            });
          } else {
            setVehicleCurrentLocation({});
          }
          fetchVehicleLocationsForMap(year, month, date, phone);
        });
    }

    getCurrentDriverLocation();
    const interval = setInterval(() => getCurrentDriverLocation(), 180000);
    return () => {
      clearInterval(interval);
    };
  }, [selectedVehicleId]);


  const fetchVehicleLocationsForMap = (year, month, date, phone) => {
    fire
      .database()
      .ref(
        "Vehicle_Location/" +
          year +
          "/" +
          month +
          "/" +
          date +
          "/" +
          phone +
          "/"
      )
      .once("value", (snapshotdata) => {
        if (snapshotdata.hasChildren()) {
          let vehiclelocationdata = snapshotdata.val();
          let finalVehicleLocationDataList = [];
          let finalVehicleLocationDataWithTimeList = [];
          Object.keys(vehiclelocationdata).forEach((value) => {
            finalVehicleLocationDataList.push({
              lat: Number(vehiclelocationdata[value]["latitude"]),
              lng: Number(vehiclelocationdata[value]["longitude"]),
            });
            finalVehicleLocationDataWithTimeList.push({
              lat: Number(vehiclelocationdata[value]["latitude"]),
              lng: Number(vehiclelocationdata[value]["longitude"]),
              time: vehiclelocationdata[value]["time_stamp"],
            });
          });
          // console.log(finalVehicleLocationDataList);
          setMapVehicleLocationTraversed(finalVehicleLocationDataList);
          setMapVehicleLocationTraversedWithTime(
            finalVehicleLocationDataWithTimeList
          );
        } else {
          setMapVehicleLocationTraversed([]);
          setMapVehicleLocationTraversedWithTime([]);
          return toast("Vehicle location data is not available", {
            type: toast.TYPE.ERROR,
          });
        }
      });
  };

  const displayMarkerOrderDetails = (shopOrder) => {
    console.log(props);
    setMapOrderDetails(shopOrder);
    setMapCenter({
      lat: Number(shopOrder.latitude),
      lng: Number(shopOrder.longitude),
    });
    setMapZoom(15);
    setSelectedOrderLatLng({
      lat: Number(shopOrder.latitude),
      lng: Number(shopOrder.longitude),
    });
    //set active marker
    // console.log(shopOrder);
  };

  const MapOrdersTableHeader = () => {
    return (
      <thead className="thead-light">
        <tr>
          <th>OrderNumber</th>
        </tr>
      </thead>
    );
  };

  const MapOrdersTableBody = () => {
    let color_code = {
      cancelled: "red",
      "not visited": "blue",
      hold: "blue",
      "shop closed": "blue",
      pending: "blue",
      delivered: "green",
      "partially-delivered": "yellow",
      shipped: "orange",
      "shipped-modified": "purple",
      default: "grey",
    };

    let rows = [];

    if (mapOrdersData.length > 0) {
      rows = mapOrdersData.map((shp, index) => {
        let current_order_color = "";
        if (shp.status) {
          current_order_color =
            shp["status"].toLowerCase() in color_code
              ? color_code[shp["status"].toLowerCase()]
              : color_code["default"];
        }
        return (
          <tr key={index}>
            <td>
              <FontAwesomeIcon
                icon={faCircle}
                style={{
                  fontSize: "10px",
                  verticalAlign: "middle",
                  color: current_order_color,
                }}
              />
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                  displayMarkerOrderDetails(shp);
                  console.log(e.target);
                  e.target.style.backgroundColor = "red";
                  sessionStorage.setItem("orderIdToPrint", shp.orderNumber);
                }}
              >
                {" "}
                {shp.orderNumber} | {shp.priority}
              </a>
            </td>
          </tr>
        );
      });
    } else {
      rows = [];
    }

    return (
      <>
        <tbody>{rows}</tbody>
      </>
    );
  };

  const generateShopMarkers = () => {
    let markers = [];
    let delStatus = "";
    let deliveryCode = {
      Delivered: `http://maps.google.com/mapfiles/ms/icons/green-dot.png`,
      PartiallyDelivered: `http://maps.google.com/mapfiles/ms/icons/yellow-dot.png`,
      Cancelled: `http://maps.google.com/mapfiles/ms/icons/red-dot.png`,
      Pending: `http://maps.google.com/mapfiles/ms/icons/blue-dot.png`,
      ShopClosed: `http://maps.google.com/mapfiles/ms/icons/blue-dot.png`,
      Default: `http://maps.google.com/mapfiles/ms/icons/purple-dot.png`,
    };
    for (let item in mapOrdersData) {
      switch (mapOrdersData[item].status) {
        case "Delivered":
          delStatus = deliveryCode.Delivered;
          break;
        case "Partially-Delivered":
          delStatus = deliveryCode.PartiallyDelivered;
          break;
        case "Cancelled":
          delStatus = deliveryCode.Cancelled;
          break;
        case "Pending":
          delStatus = deliveryCode.Pending;
          break;
        case "Shop Closed":
          delStatus = deliveryCode.ShopClosed;
          break;
        default:
          delStatus = deliveryCode.Default;
      }

      markers.push(
        <Marker
          key={item}
          icon={{
            url: delStatus,
            scale: 200,
          }}
          position={{
            lat: Number(mapOrdersData[item].latitude),
            lng: Number(mapOrdersData[item].longitude),
          }}
          label={`${mapOrdersData[item].priority}`}
          orderInfo={mapOrdersData[item]}
          onClick={vmarkInfoShow}
        ></Marker>
      );
    }
    return markers;
  };

  const generateMapRoute = () => {
    let mapPathLocations = [];
    for (let val in mapOrdersData) {
      if (
        mapOrdersData[val].latitude != "" &&
        mapOrdersData[val].longitude != ""
      ) {
        mapPathLocations.push({
          lat: Number(mapOrdersData[val].latitude),
          lng: Number(mapOrdersData[val].longitude),
        });
      }
    }
    return (
      <Polyline
        path={mapPathLocations}
        options={{ strokeColor: "red" }}
        strokeOpacity={0.3}
      />
    );
  };

  const generateMapRouteTakenByVehicle = () => {
    let mapPathLocations = [];
    for (let location of mapVehicleLocationTraversed) {
      const latitude = location.lat;
      const longitude = location.lng;
  
      // Check if latitude and longitude are not NaN
      if (!isNaN(latitude) && !isNaN(longitude)) {
        mapPathLocations.push({
          lat: latitude,
          lng: longitude,
        });
      }
    }
    return (
      <Polyline
        path={mapPathLocations}
        options={{ strokeColor: "blue" }}
        strokeOpacity={0.8}
        strokeWeight={2}
      />
    );
  };

  const generateMapRouteMarkersTakenByVehicle = () => {
    let vehicledatapointmarkers = [];
    for (let val in mapVehicleLocationTraversedWithTime) {
      let vtime = new Date(mapVehicleLocationTraversedWithTime[val]["time"]);
      let hour = vtime.getHours();
      let minute = vtime.getMinutes();
      let seconds = vtime.getSeconds();
      if (hour < 10) {
        hour = "0" + hour;
      }
      if (minute < 10) {
        minute = "0" + minute;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      let displayTime = hour + ":" + minute + ":" + seconds;

      vehicledatapointmarkers.push(
        <Marker
          key={val}
          icon={{
            url: "https://maps.gstatic.com/intl/en_us/mapfiles/markers2/measle_blue.png",
            scale: 500,
          }}
          position={{
            lat: Number(mapVehicleLocationTraversedWithTime[val].lat),
            lng: Number(mapVehicleLocationTraversedWithTime[val].lng),
          }}
          title={displayTime}
          onClick={vehicleInfoShow}
        />
      );
    }
    return vehicledatapointmarkers;
  };

  const handleOrderMarkerClose = () => {
    setMapOrderDetails({});
    setActiveMarker({});
    setVehicleActiveMarker({});
    setSelectedOrderLatLng({});
    setIsInfoWindowOrderOpened(false);
  };

  const onOrderLocMarkerClick = (props, marker, e) => {
    setActiveMarker(marker);
    setIsInfoWindowOrderOpened(true);
  };

  const vmarkInfoShow = (props, marker, e) => {
    console.log(marker.orderInfo);
    setMapOrderDetails(marker.orderInfo);
    setActiveMarker(marker);
    setIsInfoWindowOrderOpened(true);
  };

  const vehicleInfoShow = (props, marker, e) => {
    console.log("MarkerTitle:: " + marker.title);
    getReverseGeoCode(props.position, marker.title);
    setVehicleActiveMarker(marker);
  };

  const getReverseGeoCode = (latlng, time) => {
    Geocode.fromLatLng(latlng.lat, latlng.lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        console.log(address);
        setVmapOrderDetails({ time: time, place: address });
        setShowInfoWindow(true);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  return (
    <>
      <div className="row">
        <Row style={{ marginLeft: "5px" }}>
          <Button
            size="sm"
            style={{ marginLeft: 20, marginTop: 10 }}
            onClick={() => {
              setUpdateOrder(true);
            }}
          >
            Update Vehicle Priority
          </Button>

          {(isAdminUser)? (
            <Button
              size="sm"
              style={{ marginLeft: 20, marginTop: 10 }}
              onClick={() => {
                setShowUpdateWarehouseModal(true);
              }}
            >
              Update warehouse
            </Button>
          ) : (
            ""
          )}
          {Object.keys(driverInfo).length > 0 ? (
            <div
              style={{
                fontSize: "1.1rem",
                marginLeft: 20,
                marginTop: 10,
              }}
            >
              <b>Driver Name :</b> {driverInfo.actual_Driver_Name},
              <b>Transporter Name :</b> {driverInfo.company_type},{" "}
              <b>Vehicle Number :</b> {driverInfo.vehicle_number}
            </div>
          ) : (
            " "
          )}
        </Row>
      </div>
      <div className="row" style={{ marginTop: 10, marginRight: 0 }}>
        <div className="col-md-2" style={{ marginLeft: 10 }}>
          <div className="table-wrapper" style={{ paddingBottom: 60 }}>
            <table className="table table-striped">
              <MapOrdersTableHeader />
              <MapOrdersTableBody />
            </table>
          </div>
        </div>
        <div className="col-md-9">
          <Map
            google={props.google}
            zoom={mapZoom}
            initialCenter={mapCenter}
            center={mapCenter}
            style={{ width: "100%", height: "80vh" }}
          >
            {mapOrdersData.length > 0 ? generateShopMarkers() : null}
            {mapOrdersData.length > 0 ? generateMapRoute() : null}
            {mapOrdersData.length > 0 ? generateMapRouteTakenByVehicle() : null}
            {mapOrdersData.length > 0
              ? generateMapRouteMarkersTakenByVehicle()
              : null}
            {Object.keys(selectedOrderLatLng).length > 0 ? (
              <Marker
                position={selectedOrderLatLng}
                onClick={onOrderLocMarkerClick}
              />
            ) : null}
            {mapOrdersData.length > 0 ? (
              <Marker
                position={vehicleCurrentLocation}
                icon={{
                  url: require("../../assests/images/vehicle.png"),
                  anchor: new window.google.maps.Point(10, 10),
                  scaledSize: new window.google.maps.Size(30, 30),
                }}
              />
            ) : null}
            <InfoWindow
              marker={activeMarker}
              visible={isInfoWindowOrderOpened}
              onCloseClick={handleOrderMarkerClose}
            >
              {mapOrderDetails ? (
                <div>
                  <h6>
                    OrderNumber :{" "}
                    <a href={`/orderdetails?order_number=${mapOrderDetails.orderNumber}`} target="_blank">{mapOrderDetails.orderNumber}</a>
                  </h6>
                  <h6>Status : {mapOrderDetails.status}</h6>
                  <h6>Shop : {mapOrderDetails.shop}</h6>
                  <h6>Address : {mapOrderDetails.address}</h6>
                  <h6>UpdatedAt : {mapOrderDetails.updatedAt}</h6>
                </div>
              ) : (
                <div>
                  <h6>Loading</h6>
                </div>
              )}
            </InfoWindow>
            <InfoWindow
              marker={vehicleActiveMarker}
              visible={showInfoWindow}
              onCloseClick={handleOrderMarkerClose}
            >
              {vmapOrderDetails ? (
                <div>
                  <h6>Address : {vmapOrderDetails.place}</h6>
                  <h6>Time Stamp: {vmapOrderDetails.time}</h6>
                </div>
              ) : (
                <div>
                  <h6>Loading</h6>
                </div>
              )}
            </InfoWindow>
          </Map>
        </div>
      </div>
      <UpdateVehicleOrderPriorityModal
        show={updateOrder}
        onHide={() => setUpdateOrder(false)}
        mapOrdersData={mapOrdersData}
        updateOrder={updateOrder}
        route={selectedVehicleId}
        routeDate={routeDate}
      />
      <UpdateWarehouseModal
        show={showUpdateWarehouseModal}
        setShow={setShowUpdateWarehouseModal}
        refetchVehicleDetails={refetchVehicleDetails}
        cityList={cityList}
        routeDate={routeDate}
        ordersArr={orderListForSelectedRoute}
        selectedWarehouse={selectedWarehouse}
        selectedRouteId={selectedRouteId}
      />
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyBhWjIBx_EM-hyCJlg2zbfoYpyaxmPHmKs",
})(VehicleRoutingMapOrders);
