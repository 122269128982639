import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from '@mui/material';
import axios from 'axios';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL_EPAY, service_url } from '../../Configs/mysqlconfig';

const RefundModal = ({open ,handleClose,orderNumber,deliveryDate, onRefundSuccess}) => {

    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_RIGHT,
      });

    const [refundAmount,setRefundAmount] = useState('');
   

    useEffect(() => {
        if (open) {
          setRefundAmount('');
        }
      }, [open]);

      const updateRefundDetails = async (orderNumber) => {
        try {
          const response = await fetch(`${service_url}/api/superzop/epay/epay-submission`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              order_number: orderNumber,
              isRefund: true
            }),
          });
    
          const data = await response.json();
          if (data.success !== 1) {
            toast('Failed to update EPay refund in Epay details', { type: toast.TYPE.ERROR });
          }else{
            onRefundSuccess(); 
          }
        } catch (error) {
          console.error('Error updating EPay details:', error);
          toast('Failed to update EPay details', { type: toast.TYPE.ERROR });
        }
      };
  
    const handleSaveChanges = async () => {
        // refund amount from rupees to paise
        const refundAmountInPaise = refundAmount * 100;
        const formattedOrderNumber = orderNumber.replace(/\//g, '_');
        const formattedDeliveryDate = deliveryDate.replace(/-/g, '');
        const currentDate = new Date();
        // unique refund ID using order number and current timestamp and delivery date
        const refundId = `${formattedOrderNumber}_${formattedDeliveryDate}_${currentDate.toISOString().replace(/[-:.TZ]/g, '').slice(0, 8)}`;
        const formattOrderNumber = orderNumber.replace(/-R|-OR/, '');

        const body = JSON.stringify({
          marketplaceOrderId: formattOrderNumber,
          returnAmount: refundAmountInPaise,
          returnAcceptedDate: currentDate.toISOString(),
          returnShipmentReceivedDate: currentDate.toISOString(),
          refundDate: currentDate.toISOString(),
          marketPlaceRefundId: refundId,
        });
      
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Authorization', 'Bearer secret_c5aa3458-cd74-4e9b-8c9c-651dfa2e6b1b');
        // myHeaders.append('Cache-Control', 'no-cache');
    

        const requestOptions = {
          method: 'PUT',
          headers:myHeaders,
          body: body,
          redirect: 'follow',
        };
      
        const encodedOrderNumber = encodeURIComponent(formattOrderNumber);
      
        try {
          const apiUrl = `${BASE_URL_EPAY}/transaction/v4/marketplaceorderid/${encodedOrderNumber}/returned`;
          const response = await fetch(apiUrl, requestOptions);
      
          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Unknown error occurred');
          }
      
          const responseData = await response.json();
      
          // Check if the response contains the returned status and other details
          // used .status as sometimes confirmed_update sometime return_accepted coming
          if (responseData.status) {
       
            await updateRefundDetails(orderNumber)
            toast(`Refund processed successfully for ${responseData.marketplaceOrderId}.`, { type: toast.TYPE.SUCCESS })
            
          } else {
            toast(`Refund processed but status is not returned.`, { type: toast.TYPE.ERROR })
          }
        } catch (error) {
          if (error.message) {
            toast(`Error: ${error.message}`,{type: toast.TYPE.ERROR});
          } else {
            toast('Network error or server is unreachable. Please try again later.',{type: toast.TYPE.ERROR});
          }
        } finally {
          handleClose(); 
        }
      };
      
    

      return(
        <>
    <Dialog open={open} onClose={handleClose}>
    <DialogTitle>Process Refund</DialogTitle>
    <DialogContent>
      <TextField
        label="Refund Amount In Rupee"
        type="number"
        value={refundAmount}
        onChange={(e) => setRefundAmount(e.target.value)}
        fullWidth
        margin="dense"
        error={refundAmount === ""}  
        helperText={refundAmount === "" ? "Refund amount is required" : ""}
      />
      <TextField
        label="Marketplace Order ID"
        value={orderNumber}
        InputProps={{ readOnly: true }}
        fullWidth
        margin="dense"
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="secondary">Cancel</Button>
      <Button onClick={handleSaveChanges} color="primary" disabled={refundAmount === ""}>Save</Button>
    </DialogActions>
    </Dialog>
    </>
      );
}

export default RefundModal;