import React, { useState, useEffect, useRef } from 'react'
import { Table, Form, Alert, Button, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify';

function BulkHoldModal({ ...props }) {

  toast.configure({
      autoClose: 4000,
      draggable: true,
      pauseOnHover: true,
      position: toast.POSITION.TOP_LEFT,
  });

  const updateBulkHoldStatusForOrders = () => {
    let ordersStr=document.getElementById("bulkHoldOrderNumbersList").value;
    console.log(ordersStr);

    if(ordersStr==''){
      return toast("Please enter order numbers", { type: toast.TYPE.ERROR })
    }
    let orderList=ordersStr.split('\n');
    console.log(orderList);

    orderList = orderList.filter(function(entry) { return entry.trim() != ''; });
    console.log(orderList);

    if(orderList.length>0){
       props.updateBulkHold(orderList);
    }else{
      return toast("Please enter order number.", { type: toast.TYPE.ERROR })
    }

  }


  return (
      <div>
          <Modal
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
          >
              <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                      Bulk Hold Orders
                  </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div class="row">
                  <div class="form-group col-md-6">
                    <label>Order Numbers</label>
                    <textarea class="form-control" id="bulkHoldOrderNumbersList" rows="10"></textarea>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                  <Button variant={"success"} onClick={updateBulkHoldStatusForOrders}>OK</Button>
                  <Button onClick={props.onHide}>Close</Button>
              </Modal.Footer>
          </Modal>
      </div>
  )
}

export default BulkHoldModal
