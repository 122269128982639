import React, { useState, useEffect, useRef } from "react";
import fire from "../../Configs/firebase";

import { mergeFile, mergeBase64 } from "canvas-merge-images";
import firebase from "firebase";
import FileUploader from "react-firebase-file-uploader";

import {
  useTable,
  useGlobalFilter,
  useFilters,
  useAsyncDebounce,
} from "react-table";

import NavBar from "../Navigation bar/Navbar";
import axios from "axios";
import Select from "react-select";
import { service_url } from "../../Configs/mysqlconfig";
import {
  Row,
  Col,
  Form,
  Button,
  Modal,
  ProgressBar,
  Alert,
  Spinner,
  Dropdown,
  Figure,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";

toast.configure({
  autoClose: 4000,
  draggable: true,
  pauseOnHover: true,
  position: toast.POSITION.TOP_RIGHT,
});

let today = new Date();
let dd = String(today.getDate()).padStart(2, "0");
let mm = String(today.getMonth() + 1).padStart(2, "0");
let yyyy = today.getFullYear();
today = yyyy + "-" + mm + "-" + dd;
 
function CashManagement() {
  let userType = sessionStorage.getItem("userType");
  const [CashDetails, setCashDetails] = useState({});
  const [CashDetails2, setCashDetails2] = useState([]);
  const [Loading, setLoading] = useState(false)

  const [DepositSlipFile, setDepositSlipFile] = useState({});
  const [Reload, setReload] = useState(true);
  const [TableDenomination, setTableDenomination] = useState({});
  const [tableData, setTableData] = useState([]);
  const [tableDataEarlier, setTableDataEarlier] = useState([]);
  const [cashDenomination, setCashDenomination] = useState();
  const [tableEditStatus, setTableEditStatus] = useState(true);
  const [csvData, setCsvData] = useState([]);
  let csv_data = useRef("")
  const [tableDate, setTableDate] = useState(today);
  const [tableDateStart, setTableDateStart] = useState(today);
  const [tableDateEnd, setTableDateEnd] = useState(today);
  const [ModelSlipImage, setModelSlipImage] = useState();

  const [createCashPayload, setCreateCashPayload] = useState({
    route: "",
    total_expenses: "",
    coin_converted_to_cash: "",
    cash_deposited_amount: "",
    coins_not_deposited : "",
    rs_10: 0,
    rs_20: 0,
    rs_50: 0,
    rs_100: 0,
    rs_200: 0,
    rs_500: 0,
    rs_2000: 0,
  });

  const [createCashPayload2, setCreateCashPayload2] = useState({
    route: "",
    city: "",
    cash_deposit_slip_url: "",
  });

  const [ImageUploadState, setImageUploadState] = useState({
    username: "",
    avatar: "",
    isUploading: false,
    progress: 0,
    avatarURL: "",
  });

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (val) => {
    setCreateCashPayload({
      ...createCashPayload,

      city: val.city,
      delivery_date: val.delivery_date,
      total_expenses: val.total_expenses,
      coin_converted_to_cash: val.coin_converted_to_cash,
      cash_deposited_amount: val.cash_deposited_amount,
      coins_not_deposited : val.coins_not_deposited,
      rs_10: 0,
      rs_20: 0,
      rs_50: 0,
      rs_100: 0,
      rs_200: 0,
      rs_500: 0,
      rs_2000: 0,
    });
    setShow(true);
  };

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (val) => {
    setModelSlipImage(val.cash_deposit_slip_url);
    setCreateCashPayload2({
      delivery_date: val.delivery_date,
      city: val.city,
      cash_deposit_slip_url: val.cash_deposit_slip_url,
    });
    setShow2(true);
  };

  function handleUpdate() {
    const url = `${service_url}/api/superzop/cashmanagement/updateCashDetails`;

    axios
      .put(url, createCashPayload)
      .then((result) => {
        toast("cash details update ", {
          type: toast.TYPE.SUCCESS,
        });
        setReload(!Reload);
        setShow(false);
      })
      .catch((error) => {
        console.log(error);
        toast("cash details update failed ", {
          type: toast.TYPE.ERROR,
        });
      });
  }

  async function handelUploadImage(filename) {
    try {
      let ref = fire
        .storage()
        .ref(`/disha-payments/Cash_Deposit_Slip/${filename}`);
      let url = await ref.getDownloadURL();
      setModelSlipImage(url);
      await axios.put(
        `${service_url}/api/superzop/cashmanagement/updateCashDepositSlip`,
        {
          imageUrl: url,
          delivery_date: createCashPayload2.delivery_date,
          city: createCashPayload2.city,
        }
      );
    } catch (error) {
      console.log(error);
    }

    setImageUploadState({
      ...ImageUploadState,
      isUploading: false,
    });

    toast(` Upload Success `, {
      type: toast.TYPE.SUCCESS,
    });

    setReload(!Reload);
    setShow2(false);
  }

  function handleCloseForToday() {
    if (userType !== "Admin") {
      toast("You are not a admin ", {
        type: toast.TYPE.ERROR,
      });
      return;
    }

    let answer = window.confirm(
      "Do you want to close editing option for today ?"
    );

    if (answer) {
      const url = `${service_url}/api/superzop/cashmanagement/createCashManagementClosing?date=${tableDate}`;

      axios
        .post(url)
        .then((result) => {
          toast("Editing option closed For Today for non admin user", {
            type: toast.TYPE.SUCCESS,
          });

          setReload(!Reload);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  function syncDriverOrdersHistory() {
    let todaysDate = new Date();
    const todaysDateYear = todaysDate.getFullYear();
    const todaysDateMonth = todaysDate.getMonth() + 1;
    const todaysDateDay = todaysDate.getDate();

    toast("Sync started... ", {
      type: toast.TYPE.SUCCESS,
    });

    const url =
      `${service_url}/api/superzop/delivery/savedriverordershistorytable?day=` +
      todaysDateDay +
      "&month=" +
      todaysDateMonth +
      "&year=" +
      todaysDateYear;

    axios({
      method: "post",
      url: url,
    })
      .then(function (response) {
        toast("Successfully sync Driver Orders History ", {
          type: toast.TYPE.SUCCESS,
        });
      })
      .catch(function (error) {
        console.log("ERROR on sync Driver Orders History : ", error);
        toast(" ERROR on sync Driver Orders History ", {
          type: toast.TYPE.ERROR,
        });
      });
  }

  function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
      <select
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  }

  function processTableData(params) {
    let consolidate_amount_by_route = {};
    let consolidate_amount_by_route_earlier = {};
    let consolidate_amount_of_coins = {};
    let consolidate_col = {};
    let Cash_Details = {};  

    // console.log(CashDetails2); // delivery_date: '2022-09-14'

    // console.log("-------------------");
    // console.log(tableData);
    // console.log("-------------------");
    // console.log(tableDataEarlier);
    // console.log("-------------------");


    // Cash Details table date consolation
    CashDetails2.map((itm) => {
      if (Cash_Details[itm.warehouse+"@"+itm.delivery_date] === undefined) {
        Cash_Details[itm.warehouse+"@"+itm.delivery_date] = itm;
      }
    });


    //  Amount By Route date consolation
    for (let itm of tableData) {

      if (consolidate_amount_by_route[itm.city +"@"+ itm.delivery_date] === undefined) {
        consolidate_amount_by_route[itm.city +"@"+ itm.delivery_date ] = 0;
        consolidate_col[itm.city+"@"+itm.delivery_date] = itm;
        consolidate_amount_of_coins[itm.city+"@"+itm.delivery_date] = 0;
      }

      if (TableDenomination !== null && TableDenomination !== undefined) {
        consolidate_amount_of_coins[itm.city+"@"+itm.delivery_date] = consolidate_amount_of_coins[itm.city+"@"+itm.delivery_date] +  Number(TableDenomination[itm.route]?.currency_count?.coins || 0);
      }

      consolidate_amount_by_route[itm.city+"@"+itm.delivery_date] = consolidate_amount_by_route[itm.city+"@"+itm.delivery_date] + itm.amount_by_route;
    }


    // Amount By Route date consolation Earlier
    for (let itm of tableDataEarlier){
        if(consolidate_amount_by_route_earlier[itm.city] === undefined){
          consolidate_amount_by_route_earlier[itm.city] = 0;
        }
        consolidate_amount_by_route_earlier[itm.city] = consolidate_amount_by_route_earlier[itm.city] + itm.amount_by_route;
    }

    // console.log(consolidate_amount_by_route_earlier);
    // console.log(consolidate_amount_by_route);
    // console.log(consolidate_amount_of_coins);
    // console.log(consolidate_col);
    // console.log("CashDetails");
    // console.log(CashDetails);

    let table_data = [];

    for (let cites in consolidate_col) {
      let total_cash_amount = consolidate_amount_by_route[cites] - consolidate_amount_of_coins[cites];
      let cash_deposited_amount = Cash_Details[cites]?.cash_deposited_amount;
      let balance_cash = total_cash_amount - cash_deposited_amount + Cash_Details[cites]?.total_expenses || null;


      table_data.push({
        delivery_date: consolidate_col[cites]?.delivery_date,
        city: cites.split("@")[0],
        total_amount :  consolidate_amount_by_route[cites],
        total_amount_earlier : consolidate_amount_by_route_earlier[cites.split("@")[0]],
        total_cash_amount : total_cash_amount,
        total_coins_amount : consolidate_amount_of_coins[cites],
        total_expenses : Cash_Details[cites]?.total_expenses,
        coins_not_deposited : Cash_Details[cites]?.coins_not_deposited,
        coin_converted_to_cash : Cash_Details[cites]?.coin_converted_to_cash,
        cash_deposited_amount : cash_deposited_amount,
        balance_cash : balance_cash,
        cash_deposit_slip_url : Cash_Details[cites]?.cash_deposit_slip_url,
      });
    }
    
    console.log(table_data);

    return table_data

  }

  function Table1() {

    let consolidate_amount_by_route = {};
    let consolidate_amount_by_route_earlier = {};
    let consolidate_amount_of_coins = {};
    let consolidate_col = {};
    let Cash_Details = {};  

    // console.log(CashDetails2); // delivery_date: '2022-09-14'

    // console.log("-------------------");
    // console.log(tableData);
    // console.log("-------------------");
    // console.log(tableDataEarlier);
    // console.log("-------------------");


    // Cash Details table date consolation
    CashDetails2.map((itm) => {
      if (Cash_Details[itm.warehouse+"@"+itm.delivery_date] === undefined) {
        Cash_Details[itm.warehouse+"@"+itm.delivery_date] = itm;
      }
    });


    //  Amount By Route date consolation
    for (let itm of tableData) {

      if (consolidate_amount_by_route[itm.city +"@"+ itm.delivery_date] === undefined) {
        consolidate_amount_by_route[itm.city +"@"+ itm.delivery_date ] = 0;
        consolidate_col[itm.city+"@"+itm.delivery_date] = itm;
        consolidate_amount_of_coins[itm.city+"@"+itm.delivery_date] = 0;
      }

      if (TableDenomination !== null && TableDenomination !== undefined) {
        consolidate_amount_of_coins[itm.city+"@"+itm.delivery_date] = consolidate_amount_of_coins[itm.city+"@"+itm.delivery_date] +  Number(TableDenomination[itm.route]?.currency_count?.coins || 0);
      }

      consolidate_amount_by_route[itm.city+"@"+itm.delivery_date] = consolidate_amount_by_route[itm.city+"@"+itm.delivery_date] + itm.amount_by_route;
    }


    // Amount By Route date consolation Earlier
    for (let itm of tableDataEarlier){
        if(consolidate_amount_by_route_earlier[itm.city] === undefined){
          consolidate_amount_by_route_earlier[itm.city] = 0;
        }
        consolidate_amount_by_route_earlier[itm.city] = consolidate_amount_by_route_earlier[itm.city] + itm.amount_by_route;
    }

    // console.log(consolidate_amount_by_route_earlier);
    // console.log(consolidate_amount_by_route);
    // console.log(consolidate_amount_of_coins);
    // console.log(consolidate_col);
    // console.log("CashDetails");
    // console.log(CashDetails);

    let table_data = [];

    for (let cites in consolidate_col) {
      let total_cash_amount = consolidate_amount_by_route[cites] - consolidate_amount_of_coins[cites];
      let cash_deposited_amount = Cash_Details[cites]?.cash_deposited_amount;
      let balance_cash = total_cash_amount - cash_deposited_amount + Cash_Details[cites]?.total_expenses || null;


      table_data.push({
        delivery_date: consolidate_col[cites]?.delivery_date,
        city: cites.split("@")[0],
        total_amount :  consolidate_amount_by_route[cites],
        total_amount_earlier : consolidate_amount_by_route_earlier[cites.split("@")[0]],
        total_cash_amount : total_cash_amount,
        total_coins_amount : consolidate_amount_of_coins[cites],
        total_expenses : Cash_Details[cites]?.total_expenses,
        coins_not_deposited : Cash_Details[cites]?.coins_not_deposited,
        coin_converted_to_cash : Cash_Details[cites]?.coin_converted_to_cash,
        cash_deposited_amount : cash_deposited_amount,
        balance_cash : balance_cash,
        cash_deposit_slip_url : Cash_Details[cites]?.cash_deposit_slip_url,
      });
    }

    const data = React.useMemo(() => table_data, []);

    const columns = React.useMemo(
      () => [
        {
          Header: "Delivery Date",
          accessor: "delivery_date"
        },
        {
          Header: "City",
          accessor: "city",
          Filter: SelectColumnFilter,
        },
        {
          Header: "Total amount earlier",
          accessor: "total_amount_earlier",
        },
        {
          Header: "Total amount",
          accessor: "total_amount",
        },
        {
          Header: "Total cash amount",
          accessor: "total_cash_amount",
        },
        {
          Header: "Total coins amount",
          accessor: "total_coins_amount",
        },
        {
          Header: "Total expenses",
          accessor: "total_expenses",
        },
        {
          Header: "Coin converted to cash",
          accessor: "coin_converted_to_cash",
        },
        {
          Header: "Coins not deposited",
          accessor: "coins_not_deposited",
        },
        
        {
          Header: "Cash deposited amount",
          accessor: "cash_deposited_amount",
        },
        {
          Header: "Balance cash",
          accessor: "balance_cash",
        },
        {
          Header: "Cash deposit slip",
          accessor: "cash_deposit_slip_url",
          Cell: ({ row }) => {
            // console.log(row.original.cash_deposit_slip_url);

            if (
              row.original.cash_deposit_slip_url !== null &&
              row.original.cash_deposit_slip_url !== undefined
            ) {
              return (
                <img
                  src={row.original.cash_deposit_slip_url}
                  onClick={() => handleShow2(row.original)}
                  style={{ width: "50px", height: "60px" }}
                />
              );
            } else {
              return (
                <Button onClick={() => handleShow2(row.original)}>
                  {" "}
                  Add Slip{" "}
                </Button>
              );
            }
          },
        },
        {
          Header: "Edit",
          Cell: ({ row }) => {
            if (tableEditStatus || userType == "Admin") {
              return (
                <Button onClick={() => handleShow(row.original)}> Edit </Button>
              );
            } else {
              return <Button variant="secondary"> Edit </Button>;
            }
          },
        },
      ],
      []
    );

    createCsv(data,columns)

    const defaultColumn = React.useMemo(
      () => ({
        Filter: () => null,
      }),
      []
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      setGlobalFilter,
      preGlobalFilteredRows,
    } = useTable({ columns, data, defaultColumn }, useFilters, useGlobalFilter);

    
    const { globalFilter } = state;

    const GlobalFilter = ({ filter, setFilter }) => {
      const [value, setValue] = useState(filter);
      const onChange = useAsyncDebounce((value) => {
        setFilter(value || undefined);
      }, 1000);
      return (
        <span>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="email"
              value={value || ""}
              placeholder="Search"
              onChange={(e) => {
                setValue(e.target.value);
                onChange(e.target.value);
              }}
            />
          </Form.Group>
        </span>
      );
    };

 
    
    return (
      <div>
        <Row xs="auto">

          {/* <Col>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="date"
                value={tableDateStart}
                onChange={(e) => setTableDateStart(e.target.value)}
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="date"
                value={tableDateEnd}
                onChange={(e) => setTableDateEnd(e.target.value)}
              />
            </Form.Group>
          </Col> */}

          <Col>
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          </Col>
          <Col>
            <Button onClick={syncDriverOrdersHistory}>Sync</Button>
          </Col>

          <Col>
            {tableEditStatus ? (
              <Button variant="success" onClick={handleCloseForToday}>
                Edit Status : Open
              </Button>
            ) : (
              <Button variant="danger">Edit Status : Closed</Button>
            )}
          </Col>

          <Col>
            <CSVLink
              className="btn btn-warning export_btn"
              filename={"cash_management.csv"}
              data={csv_data.current}
            >
              Export Data
            </CSVLink>{" "} 
          </Col>

          {/* <Col>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Button onClick={syncDriverOrdersHistory}>Sync</Button> {" "}
              {userType === "Admin" ? (
                <Button variant="danger" onClick={handleCloseForToday}>
                  Close Edit
                </Button>
              ) : (
                ""
              )}
            </Form.Group>
          </Col> */}
        </Row>

        <table
          {...getTableProps()}
          style={{ border: "solid 1px blue", width: "100%" }}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{
                      padding: "10px",
                      color: "black",
                      fontWeight: "bold",
                      border: "solid 1px gray",
                    }}
                  >
                    {column.render("Header")}
                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: "10px",
                          border: "solid 1px gray",
                          background: "yellow",
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  function Table2() {

    let consolidate_amount_by_route = {};
    let consolidate_amount_of_coins = {};
    let consolidate_col = {};

    for (let itm of tableData) {
      if (consolidate_amount_by_route[itm.city] === undefined) {
        consolidate_amount_by_route[itm.city] = 0;
        consolidate_col[itm.city] = itm;
        consolidate_amount_of_coins[itm.city] = 0;
      }

      if (TableDenomination !== null && TableDenomination !== undefined) {
        consolidate_amount_of_coins[itm.city] =
          consolidate_amount_of_coins[itm.city] +
          Number(TableDenomination[itm.route]?.currency_count?.coins || 0);
      }

      consolidate_amount_by_route[itm.city] =
        consolidate_amount_by_route[itm.city] + itm.amount_by_route;
    }

    let table_data = [];


    let total_total_amount = 0;
    let total_total_cash_amount  = 0
    let total_coins_not_deposited = 0
    let total_total_coins_amount  = 0
    let total_total_expenses    = 0
    let total_coin_converted_to_cash  = 0   
    let total_cash_deposited_amount   = 0
    let total_balance_cash    = 0



    for (let cites in consolidate_col) {

      let total_cash_amount =
        consolidate_amount_by_route[cites] - consolidate_amount_of_coins[cites];
      let cash_deposited_amount = CashDetails[cites]?.cash_deposited_amount;
      let balance_cash =  total_cash_amount -  cash_deposited_amount + CashDetails[cites]?.total_expenses || null;

      total_total_amount            +=    parseInt(consolidate_amount_by_route[cites] || 0 )
      total_total_cash_amount       +=    parseInt( total_cash_amount || 0 )
      total_coins_not_deposited     +=    parseInt( CashDetails[cites]?.coins_not_deposited || 0 )
      total_total_coins_amount      +=    parseInt( consolidate_amount_of_coins[cites] || 0 )
      total_total_expenses          +=    parseInt( CashDetails[cites]?.total_expenses || 0  )
      total_coin_converted_to_cash  +=    parseInt( CashDetails[cites]?.coin_converted_to_cash || 0 )
      total_cash_deposited_amount   +=    parseInt( cash_deposited_amount || 0 )
      total_balance_cash            +=    parseInt( balance_cash || 0 )
    }

    table_data.push({
        city                      :    "Total",
        total_amount              :    total_total_amount,
        total_cash_amount         :    total_total_cash_amount,
        total_coins_amount        :    total_total_coins_amount,
        total_expenses            :    total_total_expenses  ,
        coin_converted_to_cash    :    total_coin_converted_to_cash,
        coins_not_deposited       :    total_coins_not_deposited,
        cash_deposited_amount     :    total_cash_deposited_amount,
        balance_cash              :    total_balance_cash
    });


    const data = React.useMemo( () => table_data, [] )
  
    const columns = React.useMemo(
      () => [ 
        {
          Header: "City",
          accessor: "city",
          Filter: SelectColumnFilter,
        },
        {
          Header: "Total amount",
          accessor: "total_amount",
        },
        {
          Header: "Total cash amount",
          accessor: "total_cash_amount",
        },
        {
          Header: "Total coins amount",
          accessor: "total_coins_amount",
        },
        {
          Header: "Total expenses",
          accessor: "total_expenses",
        },
        {
          Header: "Coin converted to cash",
          accessor: "coin_converted_to_cash",
        },
        {
          Header: "Cash deposited amount",
          accessor: "cash_deposited_amount",
        },
        {
          Header: "Coins not deposited",
          accessor: "coins_not_deposited",
        },
        {
          Header: "Balance cash",
          accessor: "balance_cash",
        }
 
      ],
      []
    )
  
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable({ columns, data })
  
    return (
      <table {...getTableProps()} style={{ border: 'solid 1px blue' , width: "100%" }}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                  style={{
                    padding: "10px",
                    color: "black",
                    fontWeight: "bold",
                    border: "solid 1px gray",
                  }}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        padding: "10px",
                        border: "solid 1px gray",
                        background: "yellow",
                      }}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  async function callTableDataAndClosing() {
    
    setLoading(true)
    // ================================ Get Closing dates for particular date ===========================
    const url1 = `${service_url}/api/superzop/cashmanagement/getCashManagementClosing?date=${tableDate}`;
    try {
      let result = await axios.get(url1);
      if (result.data.length !== 0) {
        setTableEditStatus(false);
      } else {
        setTableEditStatus(true);
      }
    } catch (error) {
      console.log(error);
    }

    // ============================================= Amount By Route =================================================
    const url2 = `${service_url}/api/superzop/cashmanagement/getAmountByRoute?start_date=${tableDateStart}&end_date=${tableDateEnd}`;
    axios
      .get(url2)
      .then((result) => {
        setTableData(result.data);

        let data = result.data.map((item) => {
          return [
            item.route,
            item.route_no,
            item.delivery_date,
            item.cash_amount,
            item.city_depot,
            item.retailer_id,
            item.cash_deposit_slip,
            item.cash_deposited_date,
            item.cash_deposited_amount,
            item.name_of_user,
            item.name_of_the_bank_deposited,
            item.balance_cash,
            item.coin_converted_to_cash,
          ];
        });

        data.unshift([
          "route",
          "route no",
          "delivery date",
          "cash amount",
          "city depot",
          "retailer id",
          "cash deposit slip",
          "cash deposited date",
          "cash deposited amount",
          "name of user",
          "name of the bank deposited",
          "balance cash",
          "coin converted to cash",
        ]);

        setCsvData(data);
      })
      .catch((error) => {
        console.log(error);
      });
    
          // ============================================= Amount By Route =================================================
    const url21 = `${service_url}/api/superzop/cashmanagement/getAmountByRouteEarlierDays?start_date=${tableDateStart}`;
    axios
      .get(url21)
      .then((result) => {
        setTableDataEarlier(result.data);
        setLoading(false)
        console.log(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
    
    

    // ====================================== Cash Detail table for a date ================================================
    const url3 = `${service_url}/api/superzop/cashmanagement/getCashDetails?start_date=${tableDateStart}&end_date=${tableDateEnd}`;
    axios
      .get(url3)
      .then((result) => {

        setCashDetails2(result.data);

        let dataObj = {};
        result.data.map((itm) => {
          if (dataObj[itm.warehouse] === undefined) {
            dataObj[itm.warehouse] = itm;
          }
        });
        setCashDetails(dataObj);
      })
      .catch((error) => {
        console.log(error);
      });

    // ========================================== Fire base data for ==================================================
    let [y, m, d] =  tableDateStart.split("-");
    let [ys, ms, ds] =  tableDateStart.split("-");
    let [ye, me, de] =  tableDateEnd.split("-");
    
    [ys, ms, ds] = [Number(ys), Number(ms), Number(ds)];
    [ye, me, de] = [Number(ye), Number(me), Number(de)];

    let fireFilter = fire.database().ref("Cash_Denomination")
      // .child(y)
      // .child(Number(m))
      // .child(Number(d) + "-" + Number(m) + "-" + Number(y));

    let snapshot = await fireFilter.once("value");
    let velData = snapshot.val();

    // console.log("--------------------------------------");
    // console.log([ys, ms, ds]);
    // console.log([ye, me, de]);
    // console.log(velData);

    let FinalFairBaseValues = {};

    for (let st_ys = ys; st_ys <= ye; st_ys++ ){

      let i;
      let j;
      let ob_ls = Object.keys(velData[st_ys])
    

      if (st_ys == ys){
        i  = ms;
        j  = Number(ob_ls[ob_ls.length-1]);
      }else if (st_ys == ye){
        i  = Number(ob_ls[0]);
        j  = me;
      } else{
        i  = Number(ob_ls[0]);
        j  = Number(ob_ls[ob_ls.length-1]);

      }

      // console.log(i,"<%>",j);
      // console.log(st_ys);
      // console.log(Object.keys(velData[st_ys]));
      // console.log(velData[st_ys]);
      
      for(let p=i ; p<=j; p++){
    
        if (velData[st_ys]?.[p] !== undefined){
          // console.log("=======================================");
          // console.log(velData[st_ys]?.[p]);
          
            for (let item in  velData[st_ys]?.[p] ){
           
                let date_sp =  item.split("-")
                let [it_y, it_m, it_d] = [Number(date_sp[2]), Number(date_sp[1]), Number(date_sp[0])];              
                // console.log(   ys , it_y , ye , " - " ,  ms , it_m , me , ' - ' ,  ds , it_d , de );  
              
                const date = new Date(`${it_y}-${it_m}-${it_d}`);
                const start = new Date(`${ys}-${ms}-${ds}`);
                const end = new Date(`${ye}-${me}-${de}`);


                if (date >= start && date <= end) {
                  // console.log('✅ date is between the 2 dates');
                  let temp_obj = velData[st_ys]?.[p]?.[item]

                  for (let itm in temp_obj ){
                      // console.log( temp_obj[itm]);
                      if (FinalFairBaseValues[itm] === undefined ){
                          FinalFairBaseValues[itm] =  {
                                "amount": "0",
                                "cash_payment": "0",
                                "currency_count": {
                                    "_10": "0",
                                    "_100": "0",
                                    "_20": "0",
                                    "_200": "0",
                                    "_2000": "0",
                                    "_50": "0",
                                    "_500": "0",
                                    "adjustments": "0",
                                    "coins": "0"
                                }
                            }
                      }
                   
                      let new_obj = {
                        "amount": Number(FinalFairBaseValues[itm].amount) + Number(temp_obj[itm].amount),
                        "cash_payment": Number(FinalFairBaseValues[itm].cash_payment) + Number(temp_obj[itm].cash_payment),
                        "currency_count": {
                            "_10": Number(FinalFairBaseValues[itm].currency_count._10) + Number(temp_obj[itm].currency_count._10),
                            "_100": Number(FinalFairBaseValues[itm].currency_count._100) + Number(temp_obj[itm].currency_count._100),
                            "_20": Number(FinalFairBaseValues[itm].currency_count._20) + Number(temp_obj[itm].currency_count._20),
                            "_200": Number(FinalFairBaseValues[itm].currency_count._200) + Number(temp_obj[itm].currency_count._200),
                            "_2000": Number(FinalFairBaseValues[itm].currency_count._2000) + Number(temp_obj[itm].currency_count._2000),
                            "_50": Number(FinalFairBaseValues[itm].currency_count._50) + Number(temp_obj[itm].currency_count._50),
                            "_500": Number(FinalFairBaseValues[itm].currency_count_500) + Number(temp_obj[itm].currency_count_500),
                            "adjustments": Number(FinalFairBaseValues[itm].currency_count.adjustments) + Number(temp_obj[itm].currency_count.adjustments),
                            "coins": Number(FinalFairBaseValues[itm].currency_count.coins) + Number(temp_obj[itm].currency_count.coins)
                        }
                    }
                    FinalFairBaseValues[itm] = new_obj;
                  }

                } else {
                  // console.log('⛔️ date is not in the range');
                }
            }
          // console.log("=======================================");
        }
      }
    }
    // console.log("--------------------------------------");
    setTableDenomination(FinalFairBaseValues);
  }


  function createCsv(data,columns) {

    let final_csv = []
    for (let row of data){

      let one_row = []
      for (let col of columns){

        if (col.accessor === "actual_reason" && row[col.accessor] === undefined ){
          one_row.push(row["reason"])
          continue
        }

        one_row.push(row[col.accessor])
      }
      final_csv.push(one_row)
    }

    let one_row = []
    for (let col of columns){
      one_row.push(col.accessor)
    }
    final_csv.unshift(one_row)
    // setCsvData(final_csv)
    csv_data.current = final_csv;


  }



  useEffect(() => {
    callTableDataAndClosing();

    return () => {};
  }, [tableDateStart, tableDateEnd, Reload]);

  return (
    <>
      <NavBar />
      <br />
      <br />
      <br />
      <br />

      <div style={{ margin: 20 }}>
        <h3>Cash Management {Loading && ( <Spinner animation="border" role="status"> </Spinner>)} </h3>

        <Row xs="auto">
   
          <Col>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="date"
                value={tableDateStart}
                onChange={(e) => setTableDateStart(e.target.value)}
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="date"
                value={tableDateEnd}
                onChange={(e) => setTableDateEnd(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col></Col>
          <Col></Col>
          <Col></Col>
          <Col></Col>
       
        </Row>

        <Table1 />
        <br/>
        <Table2/>

      
      
      </div>

      {/* Edit model */}
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title> Edit Cash Management </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Delivery Date</Form.Label>
              <Form.Control
                type="Delivery date"
                value={createCashPayload.delivery_date}
                disabled
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>City </Form.Label>
              <Form.Control
                type="City"
                value={createCashPayload.city}
                disabled
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Total expenses</Form.Label>
              <Form.Control
                type="text"
                value={createCashPayload.total_expenses}
                onChange={(e) =>
                  setCreateCashPayload({
                    ...createCashPayload,
                    total_expenses: e.target.value,
                  })
                }
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Coin converted to cash</Form.Label>
              <Form.Control
                type="number"
                value={createCashPayload.coin_converted_to_cash}
                onChange={(e) =>
                  setCreateCashPayload({
                    ...createCashPayload,
                    coin_converted_to_cash: e.target.value,
                  })
                }
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Cash deposited amount</Form.Label>
              <Form.Control
                type="number"
                value={createCashPayload.cash_deposited_amount}
                onChange={(e) =>
                  setCreateCashPayload({
                    ...createCashPayload,
                    cash_deposited_amount: e.target.value,
                  })
                }
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Coins not deposited</Form.Label>
              <Form.Control
                type="number"
                value={createCashPayload.coins_not_deposited}
                onChange={(e) =>
                  setCreateCashPayload({
                    ...createCashPayload,
                    coins_not_deposited: e.target.value,
                  })
                }
              />
            </Form.Group>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridCity">
                <Form.Label>2000</Form.Label>
                <Form.Control
                  type="number"
                  value={createCashPayload.rs_2000}
                  onChange={(e) =>
                    setCreateCashPayload({
                      ...createCashPayload,
                      rs_2000: e.target.value,
                    })
                  }
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridCity">
                <Form.Label>500</Form.Label>
                <Form.Control
                  type="number"
                  value={createCashPayload.rs_500}
                  onChange={(e) =>
                    setCreateCashPayload({
                      ...createCashPayload,
                      rs_500: e.target.value,
                    })
                  }
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridCity">
                <Form.Label>200</Form.Label>
                <Form.Control
                  type="number"
                  value={createCashPayload.rs_200}
                  onChange={(e) =>
                    setCreateCashPayload({
                      ...createCashPayload,
                      rs_200: e.target.value,
                    })
                  }
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridCity">
                <Form.Label>100</Form.Label>
                <Form.Control
                  type="number"
                  value={createCashPayload.rs_100}
                  onChange={(e) =>
                    setCreateCashPayload({
                      ...createCashPayload,
                      rs_100: e.target.value,
                    })
                  }
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridCity">
                <Form.Label>50</Form.Label>
                <Form.Control
                  type="number"
                  value={createCashPayload.rs_50}
                  onChange={(e) =>
                    setCreateCashPayload({
                      ...createCashPayload,
                      rs_50: e.target.value,
                    })
                  }
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridCity">
                <Form.Label>20</Form.Label>
                <Form.Control
                  type="number"
                  value={createCashPayload.rs_20}
                  onChange={(e) =>
                    setCreateCashPayload({
                      ...createCashPayload,
                      rs_20: e.target.value,
                    })
                  }
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridCity">
                <Form.Label>10</Form.Label>
                <Form.Control
                  type="number"
                  value={createCashPayload.rs_10}
                  onChange={(e) =>
                    setCreateCashPayload({
                      ...createCashPayload,
                      rs_10: e.target.value,
                    })
                  }
                />
              </Form.Group>
            </Row>

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* image  model */}
      <Modal show={show2} onHide={handleClose2} size="lg">
        <Modal.Header closeButton>
          <Modal.Title> Cash deposit slip </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {ModelSlipImage ? (
              <Figure.Image
                width={1000}
                height={2000}
                alt=" "
                src={ModelSlipImage}
              />
            ) : (
              <h5> Upload Deposit Slip </h5>
            )}

            <br></br>

            <FileUploader
              accept="image/*"
              name="image-uploader-multiple"
              storageRef={fire
                .storage()
                .ref(`/disha-payments/Cash_Deposit_Slip`)}
              randomizeFilename
              onUploadStart={() =>
                setImageUploadState({
                  ...ImageUploadState,
                  isUploading: true,
                  progress: 0,
                })
              }
              onProgress={(prog) =>
                setImageUploadState({ ...ImageUploadState, progress: prog })
              }
              onUploadError={() => {
                toast(" Upload Error", {
                  type: toast.TYPE.ERROR,
                });
              }}
              onUploadSuccess={handelUploadImage}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {ImageUploadState.progress !== 0 && (
            <div>
              <Spinner animation="border" size="sm" /> Uploading Started
            </div>
          )}

          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );

}

export default CashManagement;
