import React, { useState, useEffect } from 'react'
import { Table, Form, Alert, Button,Modal,Dropdown,DropdownButton,InputGroup,Row,Col } from 'react-bootstrap'
import { toast } from 'react-toastify';
import Backdrop from '../backdrop/backdrop';
import fire from '../../Configs/firebase';

function DiscrepancyPopup({ getOrderHistory, getDB,...props }) {
  const [loading,setLoading] = useState(false);
  const [discrepancyType, setDiscrepancyType] = useState("SELECT");
  const [orderData, setOrderData] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [gstData, setGstData] = useState([]);

  toast.configure({
      autoClose: 4000,
      draggable: true,
      pauseOnHover: true,
      position: toast.POSITION.TOP_LEFT,
  });

  useEffect(() => {
    let itemList = [];
    let gstList = [];

    Object.keys(getOrderHistory).map((rkey, rindex) => {
      let routeOrders = getOrderHistory[rkey] || {};

      if(Number(rkey)!=9999 && Number(rkey)!=9998) {
        Object.keys(routeOrders).map((orderID,oindex) => {
          let total_amt = Number(routeOrders[orderID].mrp) || 0;
          let dealer_price = Number(routeOrders[orderID].dealer_price) || 0;
          let item_qty = Number(routeOrders[orderID].order_qty) || 0;
          let gst_charge = Number(routeOrders[orderID].gst_charge) || 0;
          let calc_total_amt = (dealer_price * item_qty) + gst_charge;

          if(Math.abs(calc_total_amt-total_amt) > 1 && dealer_price!=0 && !orderID.includes("R")) {
            calc_total_amt = Number(calc_total_amt).toFixed(2);

            let ele = {};
            ele['order_key'] = orderID;
            ele['route'] = rkey;
            ele['order_number'] = routeOrders[orderID].order_number;
            ele['item_id'] = routeOrders[orderID].item_id;
            ele['mrp'] = routeOrders[orderID].mrp;
            ele['calc_mrp'] = calc_total_amt;
            itemList.push(ele);
          }

          let gst_per = Number(routeOrders[orderID].gst_per) || 0;
          let calc_gst_charge = (dealer_price * item_qty) * (gst_per/100);

          if(Math.abs(calc_gst_charge-gst_charge) > 0.1 && !orderID.includes("R")) {
            calc_gst_charge = Number(calc_gst_charge).toFixed(3);

            let gstele = {};
            gstele['order_key'] = orderID;
            gstele['route'] = rkey;
            gstele['order_number'] = routeOrders[orderID].order_number;
            gstele['status'] = routeOrders[orderID].status;
            gstele['item_id'] = routeOrders[orderID].item_id;
            gstele['gst_charge'] = routeOrders[orderID].gst_charge;
            gstele['calc_gst_charge'] = calc_gst_charge;
            gstList.push(gstele);
          }
        })
      }
    });

    setGstData(gstList);
    setItemData(itemList);
  },[])

  const OrderValueDiscrepanciesTable = () => {
    return (
        <>
          <table className="table table-striped">
                <OrderValueDiscrepanciesTableHead />
                <OrderValueDiscrepanciesTableBody />
          </table>
        </>
    );
  }

  const OrderValueDiscrepanciesTableHead = () => {
    return (
      <thead>
        <tr>
          <th>Route</th>
          <th>Order Number</th>
          <th>Order Value</th>
          <th>Calculated Order Value</th>
        </tr>
      </thead>
    )
  }

  const OrderValueDiscrepanciesTableBody = () => {
    let orderAmtMap = {};
    Object.keys(getOrderHistory).map((rkey, rindex) => {
      let routeOrders = getOrderHistory[rkey] || {};

      if(Number(rkey)!=9999 && Number(rkey)!=9998) {
        Object.keys(routeOrders).map((orderID,oindex) => {
          let total_amt = Number(routeOrders[orderID].total_amt) || 0;
          let mrp = Number(routeOrders[orderID].mrp) || 0;
          let order_number = routeOrders[orderID].order_number || "";
          let order_disc = Number(routeOrders[orderID].order_disc) || 0;
          let cashback_redeemed = Number(routeOrders[orderID].cashback_redeemed) || 0;

          if(!orderAmtMap[order_number]) {
            orderAmtMap[order_number] = {};
            orderAmtMap[order_number].calc_total_amt = 0;
          }
          orderAmtMap[order_number].route = rkey;
          orderAmtMap[order_number].total_amt = total_amt + order_disc + cashback_redeemed;
          orderAmtMap[order_number].calc_total_amt += mrp;
        })
      }
    });

    let rows = Object.keys(orderAmtMap).map((okey, oindex) => {
      let total_amt = Number(orderAmtMap[okey].total_amt) || 0;
      let calc_total_amt = Number(orderAmtMap[okey].calc_total_amt) || 0;

      if(Math.abs(calc_total_amt-total_amt) > 1) {
        return (
          <tr key={okey}>
              <td>{orderAmtMap[okey].route}</td>
              <td>{okey}</td>
              <td>{total_amt}</td>
              <td>{calc_total_amt}</td>
          </tr>
        );
      }
    });

    return (<><tbody>{rows}</tbody></>);
  }

  const ItemValueDiscrepanciesTable = () => {
    return (
        <>
          <table className="table table-striped">
                <ItemValueDiscrepanciesTableHead />
                <ItemValueDiscrepanciesTableBody />
          </table>
        </>
    );
  }

  const ItemValueDiscrepanciesTableHead = () => {
    return (
      <thead>
        <tr>
          <th>Route</th>
          <th>Order Number</th>
          <th>Item ID</th>
          <th>Item Value</th>
          <th>Calculated Item Value</th>
          <th>Update</th>
        </tr>
      </thead>
    )
  }

  const ItemValueDiscrepanciesTableBody = () => {
    let rows = itemData.map((data) => {
      return (
        <tr key={data.order_key}>
            <td>{data.route}</td>
            <td>{data.order_number}</td>
            <td>{data.item_id}</td>
            <td>{data.mrp}</td>
            <td>{data.calc_mrp}</td>
            {Math.abs(Number(data.mrp)-Number(data.calc_mrp))>1?(<td><Button variant="warning" onClick={() => { updateItemDiscrepancy(data); }}>Update</Button></td>):(<td></td>)}
        </tr>
      );
    });

    return (<><tbody>{rows}</tbody></>);
  }

  const updateItemDiscrepancy = (itemObj) => {
    let { route, order_key, item_id, calc_mrp } = itemObj;

    fire.database().ref(`${getDB}/${route}/${order_key}`).update({"mrp":calc_mrp}).then(() => {
      toast("Successfully updated", { type: toast.TYPE.SUCCESS });
      setItemData(itemData.filter(item => item.order_key != order_key ));
    })
  }

  const GstDiscrepanciesTable = () => {
    return (
        <>
          <table className="table table-striped">
            <GstDiscrepanciesTableHead />
            <GstDiscrepanciesTableBody />
          </table>
        </>
    );
  }

  const GstDiscrepanciesTableHead = () => {
    return (
      <thead>
        <tr>
          <th>Route</th>
          <th>Order Number</th>
          <th>Status</th>
          <th>Item ID</th>
          <th>GST charge</th>
          <th>Calculated GST charge</th>
          <th>Update</th>
        </tr>
      </thead>
    )
  }

  const GstDiscrepanciesTableBody = () => {
    let rows = gstData.map((data) => {
      return (
        <tr key={data.order_key}>
            <td>{data.route}</td>
            <td>{data.order_number}</td>
            <td>{data.status}</td>
            <td>{data.item_id}</td>
            <td>{data.gst_charge}</td>
            <td>{data.calc_gst_charge}</td>
            {Math.abs(Number(data.gst_charge)-Number(data.calc_gst_charge))>1?(<td><Button variant="warning" onClick={() => { updateGstDiscrepancy(data); }}>Update</Button></td>):(<td></td>)}
        </tr>
      );
    });

    return (<><tbody>{rows}</tbody></>);
  }

  const updateGstDiscrepancy = (gstObj) => {
    let { route, order_key, item_id, calc_gst_charge } = gstObj;

    fire.database().ref(`${getDB}/${route}/${order_key}`).update({"gst_charge":calc_gst_charge}).then(() => {
      toast("Successfully updated", { type: toast.TYPE.SUCCESS });
      setGstData(gstData.filter(item => item.order_key != order_key ));
    })
  }

    return (<>
            <Modal
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Discrepancies
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row" style={{paddingTop:20,marginLeft: "auto",marginRight: "auto"}}>
                    <div className="col-md-3">
                      <InputGroup size="sm" style={{ marginLeft:10 }}>
                        <InputGroup.Prepend>
                          <InputGroup.Text> Type of Discrepancy </InputGroup.Text>
                        </InputGroup.Prepend>
                        <DropdownButton id="dropdown-basic-button" title={(discrepancyType!="SELECT")?discrepancyType:"SELECT"} size="sm" variant="secondary" >
                          <Dropdown.Item key="GST" name="GST" onClick={(e)=>{e.preventDefault();setDiscrepancyType("gst"); }}>GST</Dropdown.Item>
                          <Dropdown.Item key="Item Value" name="Item Value" onClick={(e)=>{e.preventDefault();setDiscrepancyType("item_value"); }}>Item Value</Dropdown.Item>
                          <Dropdown.Item key="Order Value" name="Order Value" onClick={(e)=>{e.preventDefault();setDiscrepancyType("order_value"); }}>Order Value</Dropdown.Item>
                        </DropdownButton>
                      </InputGroup>
                    </div>
                  </div>
                  <div className="row" style={{marginTop:20}}>
                    {discrepancyType=="gst"?<GstDiscrepanciesTable />:null}
                    {discrepancyType=="item_value"?<ItemValueDiscrepanciesTable />:null}
                    {discrepancyType=="order_value"?<OrderValueDiscrepanciesTable />:null}
                  </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
            {loading ? (<Backdrop parentLoadStatus={loading} />) : (null)}
        </>
    )
}

export default DiscrepancyPopup
