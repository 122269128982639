import React, { useState,useEffect, useRef, createRef } from 'react';
import {Modal,Button,Form,InputGroup,Dropdown,DropdownButton,Card,Accordion} from 'react-bootstrap';
import { validateIsBillingUser } from '../UserCredentials'

function MyVerticallyCenteredModal(props) {

const [getStatus,setStatus] = useState();
const [getPrimaryStatus,setPrimaryStatus] = useState("");
const [primaryStatusSelection,setPrimaryStatusSelection] = useState(false);
const [firstStatus,setFirstStatus] = useState(false);
const [secondStatus,setSecondStatus] = useState(false);
const [thirdStatus,setThirdStatus] = useState(false);



const handleMainSubmit = (event)=>{
  event.preventDefault();
  event.stopPropagation();
  console.log(props.order_status)
  if(getStatus) {
    props.callback(props.driver_id,props.order_id,{
      'item_status' : 'cancel',
      'item_primary_reason' : getPrimaryStatus,
      'item_reason' : getStatus,
      'status' : props.order_status.toLowerCase()===("shipped")?"Shipped-Modified":props.order_status

    })
    sessionStorage.setItem(`${props.order_id}`,`${props.order_id}`)
  } else {
    return;
  }
}

  const submit_ref = createRef()

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          Enter the reason for Item cancellation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleMainSubmit}>
          <Form.Group>
            <InputGroup>
              <Form.Control type="input" value={getPrimaryStatus} placeholder={"Select primary reason"} required readOnly/>
              <DropdownButton
                as={InputGroup.Append}
                variant="outline-primary"
                title="Edit"
                >
                {props.order_status.toLowerCase().includes("shipped")?(
                  <Dropdown.Item href="#" name="Bill Modified" onClick={(e)=>{e.preventDefault();setPrimaryStatus(e.target.name);setPrimaryStatusSelection(true);setStatus("");setFirstStatus(false);setSecondStatus(false);setThirdStatus(true)}}>Bill Modified</Dropdown.Item>
                ):(<>
                  {props.order_status.toLowerCase()!==("shipped") ? <><Dropdown.Item href="#" name="Returns" onClick={(e)=>{e.preventDefault();setPrimaryStatus(e.target.name);setPrimaryStatusSelection(true);setStatus("");setFirstStatus(true);setSecondStatus(false);setThirdStatus(false)}}>Returns</Dropdown.Item>
                  <Dropdown.Item href="#" name="Short" onClick={(e)=>{e.preventDefault();setPrimaryStatus(e.target.name);setPrimaryStatusSelection(true);setStatus("");setFirstStatus(false);setSecondStatus(true);setThirdStatus(false)}}>Short</Dropdown.Item></>:""}
                  {!validateIsBillingUser((JSON.parse(sessionStorage.getItem(`user`))[0].email))?(<Dropdown.Item href="#" name="Bill Modified" onClick={(e)=>{e.preventDefault();setPrimaryStatus(e.target.name);setPrimaryStatusSelection(true);setStatus("");setFirstStatus(false);setSecondStatus(false);setThirdStatus(true)}}>Bill Modified</Dropdown.Item>):null}
                </>)}
              </DropdownButton>
            </InputGroup>
            </Form.Group>

            {primaryStatusSelection?(
              <Form.Group>
            <InputGroup>
              <Form.Control type="input" value={getStatus} placeholder={"Select a secondary reason"} required readOnly/>
              <DropdownButton
                as={InputGroup.Append}
                variant="outline-primary"
                title="Edit"
                >
                {firstStatus?(<Dropdown.Item href="#" name="Quality Issue" onClick={(e)=>{e.preventDefault();setStatus(e.target.name)}}>Quality Issue</Dropdown.Item>):(null)}
                {firstStatus?(<Dropdown.Item href="#" name="Wrongly Ordered" onClick={(e)=>{e.preventDefault();setStatus(e.target.name)}}>Wrongly Ordered</Dropdown.Item>):(null)}
                {firstStatus?(<Dropdown.Item href="#" name="Rate Issue" onClick={(e)=>{e.preventDefault();setStatus(e.target.name)}}>Rate Issue</Dropdown.Item>):(null)}
                {firstStatus?(<Dropdown.Item href="#" name="Weight Issue" onClick={(e)=>{e.preventDefault();setStatus(e.target.name)}}>Weight Issue</Dropdown.Item>):(null)}
                {firstStatus?(<Dropdown.Item href="#" name="Cash Not Available" onClick={(e)=>{e.preventDefault();setStatus(e.target.name)}}>Cash Not Available</Dropdown.Item>):(null)}
                {secondStatus?(<Dropdown.Item href="#" name="Short Loaded" onClick={(e)=>{e.preventDefault();setStatus(e.target.name)}}>Short Loaded</Dropdown.Item>):(null)}
                {secondStatus?(<Dropdown.Item href="#" name="Other Short" onClick={(e)=>{e.preventDefault();setStatus(e.target.name)}}>Other Short</Dropdown.Item>):(null)}
                {secondStatus?(<Dropdown.Item href="#" name="Weight Issue" onClick={(e)=>{e.preventDefault();setStatus(e.target.name)}}>Weight Issue</Dropdown.Item>):(null)}
                {thirdStatus?(<Dropdown.Item href="#" name="Short Supply" onClick={(e)=>{e.preventDefault();setStatus(e.target.name)}}>Short Supply</Dropdown.Item>):(null)}
              </DropdownButton>
            </InputGroup>
            </Form.Group>
            ):(null)}

            <Form.Group>
              <Button type="submit" hidden ref={submit_ref}>Submit</Button>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button
                type="submit" onClick={(e)=>{e.preventDefault();submit_ref.current.click()}}>Submit</Button>
          <Button variant="light" onClick={()=>{setStatus();props.onHide()}}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }



  function App(props) {

    const [modalShow, setModalShow] = React.useState(props.ModalValue);

    return (
        <MyVerticallyCenteredModal
          show={props.ModalValue}
          onHide={() => {setModalShow(false);props.parentCallBack(false)}}
          driver_id = {props.driver_id}
          order_id = {props.order_id}
          order_status = {props.order_status}
          childfirebaseInsert = {props.childfirebaseInsert}
          callback = {props.callback}
        />
    );
  }

 export default App
