import React, { useState } from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

const MessageModal = ( {onOpen, onCloseFunction, onApproveSuccess, message , title} ) => {

  const handleClose = () => {
    onCloseFunction();
    onApproveSuccess(); 
  };

  return (
    <div>
      <Modal
        open={onOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {title || "Processing..."} 
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
           {message}
          </Typography>

          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleClose}
            sx={{ mt: 2 }}
          >
            OK
          </Button>

        </Box>
      </Modal>
    </div>
  );
};

export default MessageModal;