import React, { useState, useEffect, useRef } from 'react';
import { service_url } from "../../Configs/mysqlconfig";
import axios from "axios";
import { Breadcrumb, Navbar, Dropdown, DropdownButton, Alert, Table, Button, Form, Jumbotron, InputGroup, Accordion, Toast, Badge } from 'react-bootstrap';
import { InputGroupAddon, Input, InputGroupText, Card, CardHeader, CardTitle, CardText } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import NavBar from '../Navigation bar/Navbar'
import Backdrop from '../backdrop/backdrop'
import ReactToPrint from 'react-to-print';
import ReactSelect from 'react-select'
import Select from 'react-select';
import { toast } from 'react-toastify';
import { validateIsAdminUser,validateIsOperationUser } from '../UserCredentials'
import { Link } from 'react-router-dom'
import {support_portal_url} from '../../Configs/mysqlconfig';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faPrint } from "@fortawesome/free-solid-svg-icons";
import { Collapse, IconButton } from '@material-ui/core';
import SyncDeliveryOrdersModal from './syncdeliveryordersmodal.js'
import fire from '../../Configs/firebase'
const excel = require('exceljs');


function formatDate(date) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

function formatBillDate(date) {
  let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = '' + d.getFullYear();

  if (month.length < 2)
      month = '0' + month;
  if (day.length < 2)
      day = '0' + day;

  year = year.slice(-2);
  return [day, month, year].join('-');
}


const ProductSummary = () => {
  let userRoutes=sessionStorage.getItem("allowedRoutes")?JSON.parse(sessionStorage.getItem("allowedRoutes")):[];
  // console.log(userRoutes);
  let allowedRoutesSet = new Set();

  for(let i=0;i<userRoutes.length;i++){
    let route=`/${userRoutes[i]}`;
    if(!allowedRoutesSet.has(route)){
      allowedRoutesSet.add(route);
    }
  }
  // console.log(allowedRoutesSet);
  let today = new Date()
  let tomorrow =  new Date()
  tomorrow.setDate(today.getDate() + 1)

  const [loading,setLoading] = useState(true);
  const [exportData, setExportData] = useState(false);
  const [orderTypeText,setOrderTypeText] = useState("SuperZop");
  const [vehicleList,setVehicleList] = useState("");
  const [selectedVehicle,setSelectedVehicle] = useState("");
  const [deliveryTypeText,setDeliveryTypeText] = useState("");
  const [searchText, setSearchText] = useState("");
  const [syncFlag,setSyncFlag] = useState(false);
  const [totalOrderQuantity,setTotalOrderQuantity]=useState(0);
  const [totalUnFilerQty,setTotalUnFilerQty]=useState(0);
  const [deliveryDate,setDeliveryDate] = useState(tomorrow);
  const [varietyList, setVarietyList] = useState([]);
  const [selectedVarietyFilters,setSelectedVarietyFilters] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [dohData, setDohData] = useState({});
  const [productDataObj, setProductDataObj] = useState({});
  const [pickingFlag, setPickingFlag] = useState(false);
  const [isNoOrderDelivered, setIsNoOrderDelivered] = useState(true);

  const [cityList, setCityList] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");


  const [overallRouteSummaryDetails,setOverallRouteSummaryDetails] = useState({});
  const [searchOverallRouteSummaryDetails,setSearchOverallRouteSummaryDetails] = useState({});

  const dataToPrint = useRef()
  const overallDataToPrint = useRef()
  const varietyRef = useRef()

  toast.configure({
      autoClose: 4000,
      draggable: true,
      pauseOnHover: true,
      position: toast.POSITION.TOP_LEFT,
  });

  const convertCity = (cityVal) => {
    // cityVal=cityVal.toLowerCase();
    // if(cityVal=="pune"){
    //   return "Pune";
    // } else if(cityVal=="surat"){
    //   return "Surat";
    // } else if(cityVal=="nashik"){
    //   return "Nashik";
    // } else {
    //   return "Mumbai";
    // }
    return cityVal;
  }

  useEffect(() => {
    renderOverallRouteWiseProductData(deliveryDate);
  }, [orderTypeText,deliveryDate])

  useEffect(() => {
    if(exportData && deliveryTypeText){
      if(deliveryTypeText=="OverallProduct"){
        exportProductSummary();
      }else if(deliveryTypeText=="Route"){
        if(selectedVehicle){
          exportProductSummary();
        }else{
          setExportData(false);
          setDeliveryTypeText("");
          toast("Please select a route", { type: toast.TYPE.ERROR });
        }
      }
    }
  }, [exportData,deliveryDate])

  //generate variety filter dropdown
  useEffect(() => {
    let varietySet = new Set();

    let filteredData={};
    Object.keys(searchOverallRouteSummaryDetails).map((key,index)=>{
      let routeData = searchOverallRouteSummaryDetails[key] || [];

      for(let i=0;i<routeData.length;i++){
        let type=routeData[i]['Type'];
        if(!varietySet.has(type)){
          varietySet.add(type);
        }
      }
    })

    let uniqueVarietyList=[...varietySet];
    let finalVarietyList=[];
    for(let z=0;z<uniqueVarietyList.length;z++){
      let type=uniqueVarietyList[z];
      finalVarietyList.push({ value: type, label: type })
    }
    setVarietyList(finalVarietyList)
  },[searchOverallRouteSummaryDetails])

  useEffect(() => {
    if(selectedCity){
      setLoading(true);

      // city fetching
      let userCity = sessionStorage.getItem("userCity") || "";

      let deliveryItemObj = {};
      let total_quantity=0;

      let distinctVehicleDropdownList=[];
      Object.keys(dohData).map((rkey,rindex) => {
        let routeOrders = dohData[rkey] || {};
        let routeItemObj = {};
        let itemOrderedQtyObj = {};

        let isCityOrderPresent = false;

        Object.keys(routeOrders).map((okey,oindex) => {
          let city = routeOrders[okey].city || "";
          city = convertCity(city);

          if(city==selectedCity || selectedCity=="All"){
            isCityOrderPresent = true;

            let itemID = routeOrders[okey].item_id || "";
            let itemQty = routeOrders[okey].item_modified_qty?Number(routeOrders[okey].item_modified_qty):routeOrders[okey].order_qty?Number(routeOrders[okey].order_qty):0;
            let orderedQty = routeOrders[okey].order_qty?Number(routeOrders[okey].order_qty):0;

            if(!routeItemObj[itemID]){
              routeItemObj[itemID] = 0;
            }

            routeItemObj[itemID] += itemQty;

            if(!itemOrderedQtyObj[itemID]){
              itemOrderedQtyObj[itemID] = 0;
            }

            itemOrderedQtyObj[itemID] += orderedQty;
          }
        })

        if(isCityOrderPresent){
          distinctVehicleDropdownList.push({"value":rkey,"label":rkey});

          let routeProductList = [];

          Object.keys(routeItemObj).map((ikey,iindex) => {
            let itemID = ikey || "";
            let productQty = routeItemObj[itemID];
            let orderedQty = itemOrderedQtyObj[itemID];
            let ele={};
            ele["Item Id"] = itemID;


            if(productDataObj[itemID]){
              ele["Brand"] = productDataObj[itemID].brand || "";
              ele["Category"] = productDataObj[itemID].category || "";
              ele["Description"] = productDataObj[itemID].long_description || "";
              ele["Type"] = productDataObj[itemID].variety || "";
            }else{
              ele["Brand"] = "";
              ele["Category"] = "";
              ele["Description"] = "";
              ele["Type"] = "";
            }

            ele["Order Qty"] = productQty;
            ele["Original Order Qty"] = orderedQty;
            total_quantity += productQty;
            routeProductList.push(ele);
          })

          //sort by type, category, brand
          routeProductList.sort(function(a, b) {
            if (a.Type > b.Type) {
                return 1;
            } else if (a.Type < b.Type) {
                return -1;
            } else if (a.Category > b.Category) {
                return 1;
            } else if (a.Category < b.Category) {
                return -1;
            } else if (a.Brand > b.Brand) {
                return 1;
            } else if (a.Brand < b.Brand) {
                return -1;
            } else {
                return 0;
            }
          });

          deliveryItemObj[rkey] = routeProductList;
        }

      })

      setLoading(false);
      setTotalOrderQuantity(total_quantity);
      setTotalUnFilerQty(total_quantity)
      setOverallRouteSummaryDetails(deliveryItemObj);
      setSearchOverallRouteSummaryDetails(deliveryItemObj);
      setVehicleList(distinctVehicleDropdownList);
      setSelectedVehicle("");
    }
  },[selectedCity])

  const renderOverallRouteWiseProductData = (deliverydate) => {
    setLoading(true);
    deliverydate = new Date(deliverydate);
    let year = deliverydate.getFullYear();
    let month = deliverydate.getMonth() + 1;
    let date = deliverydate.getDate();
    let finaldate = `${date}-${month}-${year}`;

    Promise.all([
      fire.database().ref("Product_Master_New/A").once('value'),
      fire.database().ref(`Driver_Orders_History/${year}/${month}/${finaldate}`).once('value')
    ]).then(([ productsnap, dohsnapshot ]) => {

      let userCity = sessionStorage.getItem("userCity") || "";

      let productData = productsnap.val() || {};
      let getOrderHistory = dohsnapshot.val() || {};

      let deliveryItemObj = {};
      let total_quantity=0;

      let distinctVehicleDropdownList=[];
      let distinctCityDropdownList=[];
      distinctCityDropdownList.push({"value":"All","label":"All"});
      let citySet = new Set();

      Object.keys(getOrderHistory).map((rkey,rindex) => {
        let routeOrders = getOrderHistory[rkey] || {};
        let routeItemObj = {};
        let itemOrderedQtyObj = {};

        let isCityOrderPresent = false;

        Object.keys(routeOrders).map((okey,oindex) => {
          let city = routeOrders[okey].city || "";
          city = convertCity(city);
          let status = routeOrders[okey].status || "";

          if(!citySet.has(city)){
            citySet.add(city);
            distinctCityDropdownList.push({"value":city,"label":city});
          }

          if((!userCity || (userCity && userCity==city) || validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email))) && status!="Hold" ){
            isCityOrderPresent = true;

            let itemID = routeOrders[okey].item_id || "";
            let itemQty = routeOrders[okey].item_modified_qty?Number(routeOrders[okey].item_modified_qty):routeOrders[okey].order_qty?Number(routeOrders[okey].order_qty):0;
            let orderedQty = routeOrders[okey].order_qty?Number(routeOrders[okey].order_qty):0;

            if(!routeItemObj[itemID]){
              routeItemObj[itemID] = 0;
            }

            routeItemObj[itemID] += itemQty;

            if(!itemOrderedQtyObj[itemID]){
              itemOrderedQtyObj[itemID] = 0;
            }

            itemOrderedQtyObj[itemID] += orderedQty;
          }
        })

        if(isCityOrderPresent){
          distinctVehicleDropdownList.push({"value":rkey,"label":rkey});

          let routeProductList = [];

          Object.keys(routeItemObj).map((ikey,iindex) => {
            let itemID = ikey || "";
            let productQty = routeItemObj[itemID];
            let orderedQty = itemOrderedQtyObj[itemID];
            let ele={};
            ele["Item Id"] = itemID;


            if(productData[itemID]){
              ele["Brand"] = productData[itemID].brand || "";
              ele["Category"] = productData[itemID].category || "";
              ele["Description"] = productData[itemID].long_description || "";
              ele["Type"] = productData[itemID].variety || "";
            }else{
              ele["Brand"] = "";
              ele["Category"] = "";
              ele["Description"] = "";
              ele["Type"] = "";
            }

            ele["Order Qty"] = productQty;
            ele["Original Order Qty"] = orderedQty;
            total_quantity += productQty;
            routeProductList.push(ele);
          })

          //sort by type, category, brand
          routeProductList.sort(function(a, b) {
            if (a.Type > b.Type) {
                return 1;
            } else if (a.Type < b.Type) {
                return -1;
            } else if (a.Category > b.Category) {
                return 1;
            } else if (a.Category < b.Category) {
                return -1;
            } else if (a.Brand > b.Brand) {
                return 1;
            } else if (a.Brand < b.Brand) {
                return -1;
            } else {
                return 0;
            }
          });

          deliveryItemObj[rkey] = routeProductList;
        }

      })

      setLoading(false);
      setTotalOrderQuantity(total_quantity);
      setTotalUnFilerQty(total_quantity)
      setOverallRouteSummaryDetails(deliveryItemObj);
      setSearchOverallRouteSummaryDetails(deliveryItemObj);
      setVehicleList(distinctVehicleDropdownList);
      setCityList(distinctCityDropdownList);
      setDohData(getOrderHistory);
      setProductDataObj(productData);
      if(validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email))){
        setSelectedCity("All")
      }

    }).catch((error) => {
      alert(error)
      setLoading(false);
      setOverallRouteSummaryDetails({});
      setSearchOverallRouteSummaryDetails({});
      setVehicleList([]);
      setCityList([]);
      setTotalOrderQuantity(0);
      setProductDataObj({});
      if(validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email))){
        setSelectedCity("")
      }
    });
  }

  useEffect(()=>{
    console.log(selectedVarietyFilters);

    let originalOverallSummaryData=JSON.parse(JSON.stringify(searchOverallRouteSummaryDetails));
    let overallRouteObj={};

    if(selectedVehicle){
      let routeOrdersData = originalOverallSummaryData[selectedVehicle] || [];
      overallRouteObj[selectedVehicle]=routeOrdersData;
    }else{
      overallRouteObj=originalOverallSummaryData;
    }

    console.log(overallRouteObj);
    let checksearchtext=searchText.trim().toLowerCase();
    console.log(checksearchtext);

    if(selectedVarietyFilters.length>0){
      let varietySet=new Set();

      for(let i=0;i<selectedVarietyFilters.length;i++){
        let type=selectedVarietyFilters[i].value;
        if(!varietySet.has(type)){
          varietySet.add(type);
        }
      }

      console.log(varietySet);

      let filteredData={};
      let total_quantity=0;
      Object.keys(overallRouteObj).map((key,index)=>{
        let routeData = overallRouteObj[key] || [];
        filteredData[key]=[];

        for(let i=0;i<routeData.length;i++){
          let itemid=routeData[i]['Item Id']?(''+routeData[i]['Item Id']):"";
          let itemdesc=routeData[i]['Description']?routeData[i]['Description']:"";
          let Category=routeData[i]['Category']?routeData[i]['Category']:"";
          let brand=routeData[i]['Brand']?routeData[i]['Brand']:"";
          let type=routeData[i]['Type']?routeData[i]['Type']:"";
          let quantity=routeData[i]['Order Qty']?Number(routeData[i]['Order Qty']):0;

          Category=Category.trim().toLowerCase();
          itemdesc=itemdesc.trim().toLowerCase();
          brand=brand.trim().toLowerCase();
          type=type.trim().toLowerCase();

          let variety_type=routeData[i]['Type'];
          if(varietySet.has(variety_type) || (checksearchtext && (Category.includes(checksearchtext) || itemid.includes(checksearchtext) ||
           itemdesc.includes(checksearchtext) || brand.includes(checksearchtext) || type.includes(checksearchtext))) ){
            total_quantity=total_quantity+quantity;
            filteredData[key].push(routeData[i]);
          }
        }
      })

      setTotalOrderQuantity(total_quantity);
      setOverallRouteSummaryDetails(filteredData);
    }else{
      let filteredData={};
      let total_quantity=0;
      Object.keys(overallRouteObj).map((key,index)=>{
        let routeData = overallRouteObj[key] || [];
        filteredData[key]=[];

        for(let i=0;i<routeData.length;i++){
          let itemid=routeData[i]['Item Id']?(''+routeData[i]['Item Id']):"";
          let itemdesc=routeData[i]['Description']?routeData[i]['Description']:"";
          let Category=routeData[i]['Category']?routeData[i]['Category']:"";
          let brand=routeData[i]['Brand']?routeData[i]['Brand']:"";
          let type=routeData[i]['Type']?routeData[i]['Type']:"";
          let quantity=routeData[i]['Order Qty']?Number(routeData[i]['Order Qty']):0;

          Category=Category.trim().toLowerCase();
          itemdesc=itemdesc.trim().toLowerCase();
          brand=brand.trim().toLowerCase();
          type=type.trim().toLowerCase();


          if(!checksearchtext){
            total_quantity=total_quantity+quantity;
            filteredData[key].push(routeData[i]);
          }else if(checksearchtext && (Category.includes(checksearchtext) || itemid.includes(checksearchtext) || itemdesc.includes(checksearchtext) || brand.includes(checksearchtext) || type.includes(checksearchtext))){
            filteredData[key].push(routeData[i]);
            total_quantity=total_quantity+quantity;
          }


        }
      })

      setTotalOrderQuantity(total_quantity);
      setOverallRouteSummaryDetails(filteredData);
    }

  },[selectedVarietyFilters, selectedVehicle, searchText])

  const exportProductSummary = async () => {
    setLoading(true);

    let originalOverallSummaryData=JSON.parse(JSON.stringify(searchOverallRouteSummaryDetails));
    let overallRouteObj={};

    if(deliveryTypeText=="Route" && selectedVehicle){
      let routeOrdersData = originalOverallSummaryData[selectedVehicle] || [];
      overallRouteObj[selectedVehicle]=routeOrdersData;
    }else{
      overallRouteObj=originalOverallSummaryData;
    }


    let finalItemObj = {};
    let ordersPriorityObj = {};
    let orderItemQtyObj = {};
    let itemOrdersList = {};
    Object.keys(dohData).map((rkey,rindex) => {
      let routeOrders = dohData[rkey] || [];
      let routeOrdersPriorityObj = {};

      Object.keys(routeOrders).map((okey,oindex) => {
        let itemID = routeOrders[okey].item_id;
        let itemQty = routeOrders[okey].item_modified_qty?Number(routeOrders[okey].item_modified_qty):routeOrders[okey].order_qty?Number(routeOrders[okey].order_qty):0;
        let priority = routeOrders[okey].priority || "";
        let order_number = routeOrders[okey].order_number || "";

        routeOrdersPriorityObj[priority] = order_number;

        if(!orderItemQtyObj[order_number]){
          orderItemQtyObj[order_number] = {};
        }
        orderItemQtyObj[order_number][itemID] = itemQty;

        if(!itemOrdersList[itemID]){
          itemOrdersList[itemID] = [];
        }
        itemOrdersList[itemID].push(order_number);

        if(!finalItemObj[itemID]){
          finalItemObj[itemID] = 0;
        }
        finalItemObj[itemID] += itemQty;
      })

      let ordersList = Object.entries(routeOrdersPriorityObj);
      ordersList.sort(function(a, b) {
        let keyA = Number(a[0]),
          keyB = Number(b[0]);
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });

      ordersPriorityObj[rkey] = ordersList;
    })

    console.log("ordersPriorityObj");
    console.log(ordersPriorityObj);
    console.log(itemOrdersList);
    console.log(orderItemQtyObj);

    let deliveryProductSummary = [];
    Object.keys(finalItemObj).map((ikey,iindex) => {
      let itemID = ikey || "";
      let productQty = finalItemObj[itemID];
      let ele={};
      ele["Item Id"] = itemID;

      if(productDataObj[itemID]){
        ele["Brand"] = productDataObj[itemID].brand || "";
        ele["Category"] = productDataObj[itemID].category || "";
        ele["Description"] = productDataObj[itemID].long_description || "";
        ele["Type"] = productDataObj[itemID].variety || "";
      }else{
        ele["Brand"] = "";
        ele["Category"] = "";
        ele["Description"] = "";
        ele["Type"] = "";
      }

      ele["Order Qty"] = productQty;
      deliveryProductSummary.push(ele);
    })

    const workbook = new excel.Workbook();

    if(deliveryTypeText=="OverallProduct"){
      //product summary sheet
      const product_sheet = workbook.addWorksheet('Product Summary');
      product_sheet.columns = [
        { header: 'Item Id', key: 'Item Id', width: 20 },
        { header: 'Type', key: 'Type', width: 20 },
        { header: 'Category', key: 'Category', width: 20},
        { header: 'Brand', key: 'Brand', width: 50},
        { header: 'Item desc', key: 'Description', width: 50},
        { header: 'Total', key: 'Order Qty', width: 10}
      ];

      product_sheet.addRows(deliveryProductSummary);
    }


    Object.keys(overallRouteObj).map((rkey,rindex) => {
      let routeItemsList = overallRouteObj[rkey] || [];

      let routeOrdersPriorityList = ordersPriorityObj[rkey] || [];

      let route_str="Route_"+rkey;
      if(deliveryTypeText=="Route" && selectedVehicle){
        route_str="Route Summary";
      }

      const route_sheet = workbook.addWorksheet(route_str);
      route_sheet.columns = [
        { header: 'Item Id', key: 'Item Id', width: 20 },
        { header: 'Type', key: 'Type', width: 20 },
        { header: 'Category', key: 'Category', width: 20},
        { header: 'Brand', key: 'Brand', width: 50},
        { header: 'Item desc', key: 'Description', width: 50},
        { header: 'Total', key: 'Order Qty', width: 10}
      ];

      let rowOrderValues = [];
      let rowPriorityValues=[];
      rowOrderValues[1]  = 'Item Id';
      rowOrderValues[2] = 'Type';
      rowOrderValues[3] = 'Category';
      rowOrderValues[4] = 'Brand';
      rowOrderValues[5] = 'Item Desc';
      rowOrderValues[6] = 'Total';
      let collllCount=7;

      for(let i=0;i<routeOrdersPriorityList.length;i++){
        let column = route_sheet.getColumn(collllCount);
        column.width=20;
        rowOrderValues[collllCount] = routeOrdersPriorityList[i][1];
        rowPriorityValues[collllCount] = routeOrdersPriorityList[i][0];
        collllCount=collllCount+1;
      }

      route_sheet.getRow(1).values = rowOrderValues;
      route_sheet.addRow(rowPriorityValues);

      route_sheet.addRows(routeItemsList);

      for(let k=0;k<routeItemsList.length;k++){
        let itemID = routeItemsList[k]['Item Id'] || "";

        let ordersPresentList = itemOrdersList[itemID] || [];
        let colCount = 7;
        for(let i=0;i<routeOrdersPriorityList.length;i++){
          let orderNumber = routeOrdersPriorityList[i][1];
          if(ordersPresentList.includes(orderNumber)){
            let updaterow = route_sheet.getRow(k+3);
            let itemQty = 0;

            if(orderItemQtyObj){
              if(orderItemQtyObj[orderNumber]){
                if(orderItemQtyObj[orderNumber][itemID]){
                  updaterow.getCell(colCount).value = orderItemQtyObj[orderNumber][itemID];
                }
              }
            }

          }
          colCount=colCount+1;
        }
      }

    })

    let buf = await workbook.xlsx.writeBuffer();
    console.log(buf);

    let fileName = "ProductSummary.xlsx";
    if(deliveryTypeText=="Route" && selectedVehicle){
      fileName = `RouteSummary_${selectedVehicle}.xlsx`
    }

    return overalldownload(new Blob([buf], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}), fileName);

  }

  function overalldownload(blob: Blob, fileName) {
    const a: HTMLAnchorElement = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);

    const url: string = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = fileName;

    a.click();

    window.URL.revokeObjectURL(url);

    if (a && a.parentElement) {
        a.parentElement.removeChild(a);
    }
    setExportData(false);
    setDeliveryTypeText("");
    setLoading(false);
  }

  const ProductSummaryTableHeader = () => {
      return (
          <thead className="thead-light">
              <tr>
                  <th>Item ID</th>
                  <th>Type</th>
                  <th>Category</th>
                  <th>Brand</th>
                  <th>Description</th>
                  <th>Order Qty</th>
              </tr>
          </thead>
      );
  }

  const OverallRouteSummaryTableBody = (routeProductData) => {
    let routeProdData=routeProductData['routeProductData'];

    let rows = routeProdData.map((row, index) => {
            return (
                <tr key={index}>
                    <td>{row['Item Id']}</td>
                    <td>{row['Type']}</td>
                    <td>{row['Category']}</td>
                    <td>{row['Brand']}</td>
                    <td>{row['Description']}</td>
                    {(row['Order Qty']==row['Original Order Qty'])?(<td>{row['Order Qty']}</td>):(<td>{row['Order Qty']} <s>{row['Original Order Qty']}</s></td>)}
                </tr>
            );
        });

    return (<><tbody>{rows}</tbody></>);
  }

  const OverallRouteSummaryTable = (routeProductData) => {
    return (
        <>
          <table className="table table-striped">
                <ProductSummaryTableHeader />
                <OverallRouteSummaryTableBody routeProductData={routeProductData['routeProductData']} />
          </table>
        </>
    );
  }

  const OverallRouteSummaryPrintTable = () => {
    let rows = Object.keys(overallRouteSummaryDetails).map((rkey, index) => {
      let routeProductsData = overallRouteSummaryDetails[rkey] || [];

      if(routeProductsData.length>0){
        return (
          <div key={rkey} style={{ pageBreakBefore: "always" }}>
            <center><h4>Route - {rkey} ({formatBillDate(new Date(deliveryDate))})</h4></center>
            <OverallRouteSummaryTable routeProductData={overallRouteSummaryDetails[rkey]} />
          </div>
        );
      }
    });

    return (<><div ref={overallDataToPrint} style={{ marginTop:70}}>{rows}</div></>);
  }

  const printRouteBills = () => {

    let year=deliveryDate.getFullYear();
    let month=deliveryDate.getMonth()+1;
    let date=deliveryDate.getDate();

    console.log(varietyRef.current);

    let varietyList=[];

    if(varietyRef.current){
      varietyList = varietyRef.current.state.value || [];
    }

    if(selectedVehicle){

      let getDB=`Driver_Orders_History/${year}/${month}/${date}-${month}-${year}`;

      let routeOrdersObj = JSON.parse(JSON.stringify(dohData[selectedVehicle]))  || {};
      console.log(routeOrdersObj);
      let updatedDriverOrdersObj = {};
      updatedDriverOrdersObj[selectedVehicle]={};
      Object.keys(routeOrdersObj).map((okey,oindex) => {
        let ordersObj = routeOrdersObj[okey];
        delete ordersObj['agent_id'];
        delete ordersObj['asm'];
        delete ordersObj['cheque_date'];
        delete ordersObj['delivery_date'];
        delete ordersObj['final_order_amt'];
        delete ordersObj['created_by'];
        delete ordersObj['created_from'];
        delete ordersObj['distributor_id'];
        delete ordersObj['exp_delivery_date'];
        delete ordersObj['image_url'];
        delete ordersObj['itemModifiedUpdatedAt'];
        delete ordersObj['itemModifiedUpdatedBy'];
        delete ordersObj['itemDeliveredUpdatedAt'];
        delete ordersObj['itemDeliveredUpdatedBy'];
        delete ordersObj['long_description_hindi'];
        delete ordersObj['latitude'];
        delete ordersObj['longitude'];
        delete ordersObj['paid_date'];
        delete ordersObj['salesman_name'];
        delete ordersObj['se_phone_number'];
        delete ordersObj['signature_url'];
        delete ordersObj['saved_at'];
        delete ordersObj['updatedAt'];
        delete ordersObj['updatedBy'];
        delete ordersObj['updated_at_app'];
        delete ordersObj['updated_by_app'];

        let city = ordersObj.city || "";
        city = convertCity(city);

        if(!selectedCity || (selectedCity && (selectedCity==city || selectedCity=="All"))){
          updatedDriverOrdersObj[selectedVehicle][okey] = ordersObj;
        }
      })

      sessionStorage.setItem("routeIdToPrint",selectedVehicle);
      sessionStorage.setItem("dateToPrint",deliveryDate)
      sessionStorage.setItem("getDB",getDB);
      sessionStorage.setItem("varietyList",JSON.stringify(varietyList))
      localStorage.setItem("orderHistory",JSON.stringify(updatedDriverOrdersObj));
      window.open(`/orderBulkPrint`, "_blank");
    }else{
      let getDB=`Driver_Orders_History/${year}/${month}/${date}-${month}-${year}`;

      let driverOrdersObj = JSON.parse(JSON.stringify(dohData));
      console.log(driverOrdersObj);

      let updatedDriverOrdersObj = {};
      Object.keys(driverOrdersObj).map((rkey,rindex)=>{
        let routeOrdersObj = driverOrdersObj[rkey];

        updatedDriverOrdersObj[rkey]={};

        Object.keys(routeOrdersObj).map((okey,oindex) => {
          let ordersObj = routeOrdersObj[okey];
          delete ordersObj['agent_id'];
          delete ordersObj['asm'];
          delete ordersObj['cheque_date'];
          delete ordersObj['delivery_date'];
          delete ordersObj['final_order_amt'];
          delete ordersObj['created_by'];
          delete ordersObj['created_from'];
          delete ordersObj['distributor_id'];
          delete ordersObj['exp_delivery_date'];
          delete ordersObj['image_url'];
          delete ordersObj['itemModifiedUpdatedAt'];
          delete ordersObj['itemModifiedUpdatedBy'];
          delete ordersObj['itemDeliveredUpdatedAt'];
          delete ordersObj['itemDeliveredUpdatedBy'];
          delete ordersObj['long_description_hindi'];
          delete ordersObj['latitude'];
          delete ordersObj['longitude'];
          delete ordersObj['paid_date'];
          delete ordersObj['salesman_name'];
          delete ordersObj['se_phone_number'];
          delete ordersObj['signature_url'];
          delete ordersObj['saved_at'];
          delete ordersObj['updatedAt'];
          delete ordersObj['updatedBy'];
          delete ordersObj['updated_at_app'];
          delete ordersObj['updated_by_app'];

          let city = ordersObj.city || "";
          city = convertCity(city);

          if(!selectedCity || (selectedCity && (selectedCity==city || selectedCity=="All"))){
            updatedDriverOrdersObj[rkey][okey] = ordersObj;
          }
        })
      })

      sessionStorage.setItem("routeIdToPrint","All");
      sessionStorage.setItem("dateToPrint",deliveryDate)
      sessionStorage.setItem("getDB",getDB);
      sessionStorage.setItem("varietyList",JSON.stringify(varietyList))
      localStorage.setItem("orderHistory",JSON.stringify(updatedDriverOrdersObj));
      window.open(`/orderBulkPrint`, "_blank");
    }

  }

  const syncDeliveryOrdersList = () => {
    setSyncFlag(true);
  }

  const handleDeliveryDateChange = (selecteddate) => {
    setLoading(true);
    setDeliveryDate(new Date(selecteddate));
    setSelectedVehicle("");
    renderOverallRouteWiseProductData(selecteddate)
  }

  const CustomDateInput = ({ value, onClick }) => (
    <input
        value={value}
        onClick={onClick}
        style={{ border: "solid 1px black", height: "30px" }}
    />
  );

  const handleKeyPress = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    if(Object.keys(dohData).length>0){
      let deliveredFlag = true;

      Object.keys(dohData).map((rkey,rindex) => {
        let routeOrders = dohData[rkey] || {};

        Object.keys(routeOrders).map((okey,oindex) => {
          let status = routeOrders[okey].status || "";

          if(status.includes("Delivered")){
            deliveredFlag = false;
          }
        })
      })

      setIsNoOrderDelivered(deliveredFlag);
    }
  },[dohData])


  function handelRazorPayQRCode() {


    toast("It might take a long time ",{
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });


      const url = `${service_url}/api/superzop/razorpayQrCodeGen?deliverydate=${deliveryDate}`;
      axios
        .get(url)
        .then((result)=>{
          console.log(result);
          toast.success("success",{
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
        })
        .catch((err)=>{
          console.log(err);
          toast.error("failure", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
        });

      
  }
  



    return (
      <>
        <NavBar/>

    
        <div className="container col-sm-12" style={{paddingTop:100}}>
        <Card body className="text-center">
          <CardHeader> Product Summary </CardHeader>
          <div style={{ marginLeft: "auto", marginRight: "auto"  }}>
          <InputGroup size="sm" style = {{ margin : 5}}>
            <InputGroupAddon addonType="prepend">Delivery Date</InputGroupAddon>
                  <DatePicker
                      selected={deliveryDate}
                      onChange={(handleDeliveryDateChange)}
                      minDate={new Date("2017/01/01")}
                      aria-describedby="basic-addon1"
                      dateFormat="dd/MM/yyyy"
                      customInput={<CustomDateInput />}
                  />
          </InputGroup>



            {cityList.length>1 && validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email)) && <InputGroup size="sm" style={{ margin: "20px" }} >
                <InputGroupAddon addonType="prepend">WareHouse</InputGroupAddon>
                <div style={{width: '100px'}}><Select options={cityList} placeholder={selectedCity?selectedCity:"All"} onChange={(val)=>{ setSelectedCity(val.value); }}/></div>
            </InputGroup>}

            <InputGroup size="sm" style={{ margin: "20px" }} >
                {(vehicleList.length>0)?(
                <div style={{width: '100px'}}><Select options={vehicleList} key={`vehicle__${selectedVehicle}`} placeholder={selectedVehicle?selectedVehicle:""} onChange={(val)=>{ setSelectedVehicle(val.value); }}/></div>):null}
                <InputGroupAddon addonType="prepend">Route Summary</InputGroupAddon>
                <Button onClick={() =>{ setExportData(true);setDeliveryTypeText("Route"); }}>Export</Button>
                {(allowedRoutesSet.has(`/orderBulkPrint`) || validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email))) && <Button style={{float: "right", marginLeft:20}} size={"sm"} variant={"primary"} onClick={() => { printRouteBills() }}><FontAwesomeIcon size="2x" icon={faPrint} /></Button>}
            </InputGroup>
            <InputGroup size="sm" style={{ margin: "20px"}} >
              <InputGroupAddon addonType="prepend">Overall Product Summary</InputGroupAddon>
              <Button onClick={() =>{ setExportData(true);setDeliveryTypeText("OverallProduct");  }}>Export</Button>
            </InputGroup>

            <InputGroup size="sm" style={{ margin: "20px"}} >
              
              <Button onClick={() =>{ handelRazorPayQRCode() }}> Generate RazorPay QR Codes </Button>
            </InputGroup>

            <InputGroup size="sm" style={{ margin: "20px" }} >
              <InputGroupAddon addonType="prepend">Variety</InputGroupAddon>
              <div style={{width: '200px'}}><Select ref={varietyRef} options={varietyList} isMulti onChange={(val)=>{ setSelectedVarietyFilters(val || []); }}/></div>
            </InputGroup>
            <Form.Control type="search" placeholder="ID / Type / Category / Brand / Desc." value={searchText} onChange={(e)=>{ handleKeyPress(e); }}/>
          </div>
          </Card>
          <div style={{ padding:15}}>
          <div style={{float: "left"}}>
            <InputGroup size="sm">
              <InputGroup.Prepend>
              <InputGroup.Text>Total Order Qty </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control readOnly value={totalOrderQuantity}/>
            </InputGroup>
          </div>

          {(Object.keys(overallRouteSummaryDetails).length>0)?(<><div>
            <ReactToPrint
              trigger={() => <IconButton style={{float:"right"}}><FontAwesomeIcon size={"2x"} icon={faPrint} /></IconButton>}
              content={() => { return overallDataToPrint.current }}
              pageStyle={{margin:"50px",border:"2px"}}
            />
            <OverallRouteSummaryPrintTable />
          </div></>):""}

          </div>
        </div>
        {syncFlag==true?<SyncDeliveryOrdersModal
            show={syncFlag}
            onHide={() => setSyncFlag(false)}
        />:null}

        {loading ? (<Backdrop parentLoadStatus={loading} />) : (null)}
      </>
    )
}

export default ProductSummary
