

import React, { useState, useEffect } from 'react'
import { Dropdown, ProgressBar, Tabs, Form, InputGroup, Button, DropdownButton, Breadcrumb, Table, ButtonGroup, Container, Accordion, Card, Modal } from 'react-bootstrap'
import { useForm } from "react-hook-form";
import fire from '../../Configs/firebase'
import ExportDeliveryDetails from './ExportDeliveryDetails'
import { toast } from 'react-toastify';
import Switch from "react-switch";



function ExportConfirmPopup({showFlag,dateOfExport,endDelCurrentFlag,getOrderHistory,getAccessibleRoutes,...props}) {

    const [extraDriverDetails, setExtraDriverDetails] = useState("")
    const [dataDownload, setDataDownload] = useState(false)
    const [validated, setValidated] = useState(false);
    const [driverDbreturnDetails, setDriverDbReturnDetails] = useState({})
    const [driverDbReleaseDetails, setDriverDbReleaseDetails] = useState({})
    const [pushDBData, setPushDBData] = useState(false)
    const [endDeliveryFlag, setEndDeliveryFlag] = useState("")

    const modifiedDate = `${dateOfExport.getFullYear()}/${dateOfExport.getMonth() + 1}/${dateOfExport.getDate()}-${dateOfExport.getMonth() + 1}-${dateOfExport.getFullYear()}`

    const Driver_allocation = fire.database().ref(`Driver_Allocation_History/${modifiedDate}`)

    console.log(modifiedDate)
    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_CENTER,
    });

    const notify= (success) => {

        if(success)
        return toast(`Updated the DBRelease and DBReturn time for the date ${modifiedDate.split("/")[2]}`, { type: toast.TYPE.SUCCESS })

    }

    useEffect(() => {
        const driverDetails = []
        Driver_allocation.once("value",snapShot => {
            const driverRoute = snapShot.val();
            if(!driverRoute)
            return
            Object.keys(driverRoute).forEach(items=>{
              let routeKey = driverRoute[items].current_Route_Id;
              if(getAccessibleRoutes[routeKey])
                driverDetails.push({"driverNo":items,"vehicleID":driverRoute[items].current_Route_Id,"DBReturnTime":driverRoute[items].DB_Return_Time,"DBReleaseTime":driverRoute[items].DB_Release_Time})

            })
            driverDetails.sort((a,b)=>a.vehicleID-b.vehicleID)
            setExtraDriverDetails(driverDetails)
            console.log(driverDetails)
        })
    }, [])

    const handleSubmit = (event) => {

        if(endDelCurrentFlag) {
            event.preventDefault();
            setDataDownload(true);
            setTimeout(() => {
                props.onHide()
            }, 4000);
        }
        else {
            const form = event.currentTarget;
            if (form.checkValidity() === false || endDeliveryFlag == false) {
            event.preventDefault();
            event.stopPropagation();
            }
            else{
                event.preventDefault();
                console.log(driverDbreturnDetails);
                console.log(driverDbReleaseDetails);
                setPushDBData(true)
                Driver_allocation.once("value",snapShot => {
                    const driverRoute = snapShot.val();
                    if(!driverRoute)
                    return

                    Object.keys(driverRoute).forEach(items=>{
                      let routeKey = driverRoute[items].current_Route_Id;
                      if(getAccessibleRoutes[routeKey])
                        Driver_allocation.child(`${items}`).update({"endDeliveryFlag":endDeliveryFlag, "endDeliveryFlag_updatedAt":new Date().getTime()})
                    })
                })

                setDataDownload(true);
                setTimeout(() => {
                    props.onHide()
                }, 4000);
            }
        }

        setValidated(true);
    };
    const setNewDetails = () =>{


        Object.keys(driverDbreturnDetails).forEach(routeNos=>{
            if(Driver_allocation.child(routeNos))
            Driver_allocation.child(routeNos).update({"DB_Return_Time":driverDbreturnDetails[routeNos].dbReturn})
        })
        Object.keys(driverDbReleaseDetails).forEach(routeNos=>{
            if(Driver_allocation.child(routeNos))
            Driver_allocation.child(routeNos).update({"DB_Release_Time":driverDbReleaseDetails[routeNos].dbRelease})
        })
        // notify(true)

    setPushDBData(false)
    }
    useEffect(() => {
    if(pushDBData)
        setNewDetails()
    }, [pushDBData])

    return (
        <div>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Export Confirm
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div style={{color:"Red"}}>All fields are mandatory to proceed  to export</div>
                        <Table>
                            <thead>
                                <tr>
                                    <td>Vehicle ID</td>
                                    <td>DB Return time</td>
                                    <td>DB Release Time</td>
                                </tr>
                            </thead>
                            <tbody>
                                {extraDriverDetails && extraDriverDetails.map((driverDetails,index)=>(
                                    <tr>
                                        <td><Form.Control readOnly value={driverDetails.vehicleID}/></td>
                                        <td><Form.Control type="time" onChange={(e)=>{let newDriverDetails = [...extraDriverDetails]; newDriverDetails[index].DBReturnTime = e.target.value ; setDriverDbReturnDetails({...driverDbreturnDetails,[driverDetails.driverNo]:{"vehicleId":driverDetails.vehicleID,"dbReturn":e.target.value}}); setExtraDriverDetails(newDriverDetails)}} required value={extraDriverDetails[index].DBReturnTime}/></td>
                                        <td><Form.Control type="time" onChange={(e)=>{let newDriverDetails = [...extraDriverDetails]; newDriverDetails[index].DBReleaseTime = e.target.value ; setDriverDbReleaseDetails({...driverDbReleaseDetails,[driverDetails.driverNo]:{"vehicleID":driverDetails.vehicleID,"dbRelease":e.target.value}}); setExtraDriverDetails(newDriverDetails)}} required value={extraDriverDetails[index].DBReleaseTime}/></td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        {!endDelCurrentFlag &&
                            <label>
                                <span>End Delivery </span>
                                <Switch onChange={(val) => { setEndDeliveryFlag(val);console.log(val) }} checked={endDeliveryFlag?endDeliveryFlag:""} />
                            </label>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type={"submit"}>Proceed to Export</Button>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
                </Form>
            </Modal>
            {dataDownload ? <ExportDeliveryDetails  multipleDownload={(status) => setDataDownload(status)} downloadDate={`${dateOfExport.getDate()}-${dateOfExport.getMonth() + 1}-${dateOfExport.getFullYear()}`} getOrderHistory={getOrderHistory} /> : ""}
        </div>

    )
}

export default ExportConfirmPopup
