import React, { useState, useEffect } from 'react'
import { Table, Form, Alert, Button,Modal,InputGroup,Dropdown,DropdownButton } from 'react-bootstrap'
import { toast } from 'react-toastify';
import Select from 'react-select';
import UpdatedDate from './UpdatedDate'
import fire from '../../Configs/firebase'


function RecievedReturnProductsModal({driver_id,order_id,getOrderHistory,selectedReturnOrderStatus,getDB,receivedReturnedProductsModalOpen,...props}) {

    const [receivedReturnedProducts,setReceivedReturnedProducts]=useState("SELECT");

    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_LEFT,
    });

    const updateReceivedReturnProducts = () => {
      if(receivedReturnedProducts!="SELECT"){
        if(receivedReturnedProducts=="No"){
          toast("Status can't be updated. Contact Admin.", { type: toast.TYPE.ERROR });
          props.onHide();
        }else if(receivedReturnedProducts=="Yes"){
          //save status and close modal
          props.onHide();
          props.callback(driver_id,order_id);
        }
      }
    };

    return (<>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    Return Products
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Table>
                  <tbody>
                    <div className="row" style={{marginLeft:"auto",marginRight:"auto"}}>
                      <InputGroup size="sm">
                        <InputGroup.Prepend>
                          <InputGroup.Text> Received the Return Products? </InputGroup.Text>
                        </InputGroup.Prepend>
                        <DropdownButton id="dropdown-basic-button" title={(receivedReturnedProducts!="SELECT")?receivedReturnedProducts:"SELECT"} size="sm" variant="secondary" >
                          <Dropdown.Item key="Yes" name="Yes" onClick={(e)=>{e.preventDefault();setReceivedReturnedProducts(e.target.name); }}>Yes</Dropdown.Item>
                          <Dropdown.Item key="No" name="No" onClick={(e)=>{e.preventDefault();setReceivedReturnedProducts(e.target.name); }}>No</Dropdown.Item>
                        </DropdownButton>
                      </InputGroup>
                    </div>
                  </tbody>
                </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"success"} onClick={updateReceivedReturnProducts}>Submit</Button>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default RecievedReturnProductsModal
