import styled from 'styled-components';

export const FooterContainer = styled.div`
    position: fixed;
    width:100%;
    left: 0px;
    bottom: 0px;
    text-align: center;
    padding: 0.5rem 1rem;
    background-color: #fafafa;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-out;
    opacity:0.9;
`;
