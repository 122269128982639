import React, { useState } from "react";
import {
  Modal,
  Button,
  Dropdown,
  DropdownButton,
  Row,
  Col,
} from "react-bootstrap";
import fire from "../../Configs/firebase";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UpdateWarehouseModal = (props) => {
  const show = props.show;
  const setShow = props.setShow;
  const cityList = props.cityList;
  const routeId = props.selectedRouteId;
  const ordersArr = props.ordersArr;
  const routeDate = props.routeDate;
  const selectedWarehouse = props.selectedWarehouse;
  const refetchVehicleDetails = props.refetchVehicleDetails;

  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_RIGHT,
  });

  const [selectedCity, setSelectedCity] = useState("Select");

  function onSubmit() {
    if (!selectedCity) {
      return;
    }
    let year = routeDate.getFullYear();
    let month = routeDate.getMonth() + 1;
    let date = routeDate.getDate() + "-" + month + "-" + year;
    let dataToSave = {};
    ordersArr.forEach((item, index) => {
      dataToSave[`/${item}/city`] = selectedCity;
    });

    fire
      .database()
      .ref(
        "Driver_Orders_History/" +
          year +
          "/" +
          month +
          "/" +
          date +
          "/" +
          routeId
      )
      .update(dataToSave)
      .then(() => {
        console.log("successfully saved the data to firebase");
        toast("Successfully set new warehouse", { type: toast.TYPE.SUCCESS });
        setShow(false);
        refetchVehicleDetails();
      })
      .catch((err) => {
        console.log("Error, unable to save the data to firebase", err);
        toast("Error: unable to set the new warehouse", {
          type: toast.TYPE.ERROR,
        });
      });
  }

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
      }}
      id="status_details_modal"
      dialogClassName="status_details_modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Update warehouse for {routeId}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>Select warehouse: </Col>
          <Col>
            <DropdownButton
              id="dropdown-basic-button"
              title={selectedCity !== "Select" ? selectedCity : "Select"}
              size="sm"
              variant="secondary"
              onSelect={() => {}}
            >
              {cityList.map((item, index) => {
                // if (item === selectedWarehouse) {
                //   return undefined;
                // }

                return (
                  <Dropdown.Item
                    key={item}
                    name={item}
                    onClick={(e) => {
                      e.preventDefault();
                      setSelectedCity(item);
                    }}
                  >
                    {item}
                  </Dropdown.Item>
                );
              })}
            </DropdownButton>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setShow(false);
          }}
        >
          Close
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            onSubmit();
          }}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateWarehouseModal;
