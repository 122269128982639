import React, { useState, useEffect, useRef, useMemo } from "react";
import { service_url, token } from "../../Configs/mysqlconfig";
import NavBar from "../Navigation bar/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import fire from "../../Configs/firebase";
import Backdrop from "../backdrop/backdrop";
import { toast } from "react-toastify";
import moment from "moment";
import { InputGroupAddon } from "reactstrap";
import { Form, InputGroup, Button, Table, Link } from "react-bootstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import TransitReturnCreationModal from "./TransitReturnCreationModal";
import TransitReturnItemsModal from "./TransitReturnItemsModal";
import ExportToExcel from "../InventoryManagementExport/TransitStockReturnExport/ExportToExcel";
import { warehouseNamesTransit } from "../../Configs/mysqlconfig";

const GLOBAL_PARENT_WAREHOUSE = "Bhiwandi";

function formatDate(date) {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

const TransitStockReturn = (props) => {
  const warehouseOptions =  warehouseNamesTransit; //["All", "Vashi", "Virar", "Mira Road", "Wadala"];
  const [warehouseOptionsList, setWarehouseOptionsList] = useState([]);
  const sessionStorageWarehouse = sessionStorage.getItem("selectedWarehouse");
  const [userSelectedWarehouse, setUserSelectedWarehouse] = useState(null);
  const [warehouse, setWarehouse] = useState(
    sessionStorageWarehouse &&
      warehouseOptions.includes(sessionStorageWarehouse)
      ? { label: sessionStorageWarehouse, value: sessionStorageWarehouse }
      : ""
  );
  const [freshReturnCreationItems, setFreshReturnCreationItems] = useState({});
  const [selectedDataType, setSelectedDataType] = useState("transit");
  const [productMasterDataRaw, setProductMasterDataRaw] = useState({});
  const [productMasterDataRawFresh, setProductMasterDataRawFresh] = useState(
    {}
  );
  const [addTransitItemDropdownList, setAddTransitItemDropdownList] = useState(
    []
  );
  const [transitReturnExportData, setTransitReturnExportData] = useState([]);
  const [
    transitReturnExportLooseItemsData,
    setTransitReturnExportLooseItemsData,
  ] = useState([]);
  const [returnDate, setReturnDate] = useState(new Date());
  const [routeData, setRouteData] = useState({});
  const [transitDate, setTransitDate] = useState();
  const [transitID, setTransitID] = useState();
  const [itemsPopupShow, setItemsPopupShow] = useState(false);
  const [transitCreatePopupShow, setTransitCreatePopupShow] = useState(false);
  const [backDrop, setBackDrop] = useState(false);
  const [selectedReturnType, setSelectedReturnType] = useState("GT");
  const userCity = sessionStorage.getItem("userCity") || "";
  const userType = sessionStorage.getItem("userType") || "";

  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });

  useEffect(() => {
    setTransitDate(new Date());
    generateProductMasterData();
    fetchFreshReturnCreationItems();
    console.log("check userCity and stuff", userCity);

    let _warehouse_options_list = [];
    warehouseOptions.forEach((item, index) => {
      _warehouse_options_list.push({ label: item, value: item });
    });

    if (userCity && userCity !== "" && warehouseOptions.includes(userCity)) {
      setWarehouse({ label: userCity, value: userCity });
    }

    setWarehouseOptionsList(_warehouse_options_list);
  }, []);

  useEffect(() => {
    if (returnDate && warehouse && warehouse.value) {
      fetchStockTransferRecords();
    }
  }, [returnDate, warehouse]);

  useEffect(() => {
    if (returnDate) fetchTransitReturnDataForExport();
  }, [returnDate]);

  const refetchData = () => {
    fetchStockTransferRecords();
  };

  const fetchFreshReturnCreationItems = () => {
    const date = moment(new Date(returnDate)).format("YYYY-MM-DD");

    let url = `${service_url}/api/inventory-movements-export-all?start_date=${date}&end_date=${date}&category=Fresh&token=${token}`;
    fetch(url, {
      headers: { "x-access-token": sessionStorage.getItem("x-access-token") },
    })
      .then((res) => res.json())
      .then((result) => {
        // console.log('received data', result )
        const allFreshItems = result.data || [];
        let freshItemsObj = {};

        allFreshItems.forEach((item) => {
          if (item.category !== "FRESH") return;
          if (!freshItemsObj[item.warehouse]) {
            freshItemsObj[item.warehouse] = [];
          }

          item.item_modified_qty = item.shipped_qty;
          item.item_qty = item.grn_qty;
          item.returned_qty = item.customer_returns;
          item.stock_return_qty = item.inventory_after_shipped;

          freshItemsObj[item.warehouse].push(item);
        });

        setFreshReturnCreationItems(freshItemsObj);
      });
  };

  const fetchProductDataFromDB = () => {
    return new Promise((resolve, reject) => {
      let freshData = {};
      let product_base_ref = fire.database().ref("Product_Master_New/F");
      product_base_ref.once(
        "value",
        (snapshot) => {
          let freshData = snapshot.val();
          product_base_ref = fire.database().ref("Product_Master_New/A");
          product_base_ref.once(
            "value",
            (snapshot) => {
              let GTData = snapshot.val();
              resolve([GTData, freshData]);
            },
            (err) => {
              reject("error occured while fetching data from firebase");
            }
          );
        },
        (err) => {
          reject("error occured while fetching data from firebase");
        }
      );
    });
  };

  const fetchStockTransferRecords = () => {
    setBackDrop(true);
    let date = "";
    let url = "";

    date = formatDate(new Date(returnDate));
    url = `${service_url}/api/transit-stock-return?return_date=${date}&src=${warehouse.value}&destination=Bhiwandi&token=${token}`;

    console.log(url);

    fetch(url, {
      headers: { "x-access-token": sessionStorage.getItem("x-access-token") },
    })
      .then((res) => res.json())
      .then((result) => {
        let stockTransferData = result.data || [];
        console.log("check date", stockTransferData);

        let stockTransferObj = {};
        for (let stockTransfer of stockTransferData) {
          // if (
          //   userType !== "Admin" &&
          //   userType !== "Operation" &&
          //   userCity !== GLOBAL_PARENT_WAREHOUSE &&
          //   stockTransfer.destination !== userCity &&
          //   stockTransfer.src !== userCity
          // ) {
          //   //do nothing
          // } else {
          let transitId = stockTransfer.id;
          let route = stockTransfer.route;

          if (!stockTransferObj[transitId]) {
            stockTransferObj[transitId] = stockTransfer;
          } else {
            stockTransferObj[transitId].route += `, ${route}`;
          }
          // }
        }

        console.log(stockTransferObj);

        setBackDrop(false);
        setRouteData(stockTransferObj);
      })
      .catch((err) => {
        toast(`Error in fetching transit routes: ${err}`, {
          type: toast.TYPE.ERROR,
        });
      });
  };

  const fetchTransitReturnDataForExport = () => {
    const transit_id_list = Object.keys(routeData).join(",");
    const url = `${service_url}/api/planned-transit-return-items?&return_date=${moment(
      returnDate
    ).format("YYYY-MM-DD")}&token=${token}`;
    fetch(url, {
      headers: { "x-access-token": sessionStorage.getItem("x-access-token") },
    })
      .then((res) => res.json())
      .then((result) => {
        const transitData = result.data;
        const transitLooseData = result.bag_data;

        setTransitReturnExportData(transitData);
        setTransitReturnExportLooseItemsData(transitLooseData);
      })
      .catch((err) => {
        toast(`Error in fetching data for export: ${err}`, {
          type: toast.TYPE.ERROR,
        });
      });
  };

  const handleOnWarehouseSelectionChanged = (val) => {
    if (val) {
      sessionStorage.setItem("selectedWarehouse", val.value);
      setWarehouse(val);
    }
  };

  const excelHeaders = React.useMemo(
    () => [
      {
        label: "Item ID",
        value: "item_id",
      },
      {
        label: "Source",
        value: "src",
      },
      {
        label: "Item description",
        value: "item_disc",
      },
      {
        label: "Type",
        value: "variety",
      },
      {
        label: "Category",
        value: "category",
      },
      {
        label: "Inventory",
        value: "inventory",
      },

      {
        label: "Return Qty",
        value: "return_qty",
      },
      {
        label: "Received Qty",
        value: "received_qty",
      },
      {
        label: "Approved Qty",
        value: "approved_qty",
      },
    ],
    []
  );

  const excelHeadersLooseItems = React.useMemo(
    () => [
      {
        label: "Order No",
        value: "order_number",
      },
      {
        label: "Source",
        value: "src",
      },
      {
        label: "Bag Id",
        value: "bag_id",
      },
      {
        label: "Item Id",
        value: "item_id",
      },
      {
        label: "Item description",
        value: "item_disc",
      },
      {
        label: "Category",
        value: "category",
      },
      {
        label: "Inventory",
        value: "inventory",
      },

      {
        label: "Return Qty",
        value: "return_qty",
      },
      {
        label: "Received Qty",
        value: "received_qty",
      },
      {
        label: "Approved Qty",
        value: "approved_qty",
      },
    ],
    []
  );

  const isCreateReturnOptionDisabled = (
    user_type,
    user_city,
    selected_warehouse,
    return_date
  ) => {
    if (!selected_warehouse || !selected_warehouse.value) {
      return true;
    }
    if (
      !return_date ||
      moment(return_date).format("YYYY-MM-DD") !==
        moment(new Date()).format("YYYY-MM-DD")
    ) {
      return true;
    }
    const warehouseVal = selected_warehouse.value.toLowerCase();

    if (
      user_type.toLowerCase() === "admin" ||
      user_type.toLowerCase() === "systems"
    ) {
      return false;
    } else if (user_type.toLowerCase() === "operation" && user_city === "") {
      return true;
    } else if (userCity.toLowerCase() !== warehouseVal) {
      return true;
    }
  };

  const isSelectWarehouseOptionDisabled = (
    user_type,
    user_city,
    selected_warehouse
  ) => {
    if (
      user_type.toLowerCase() === "admin" ||
      user_city === "" ||
      user_city === "Bhiwandi"
    ) {
      return false;
    } else {
      return true;
    }
  };

  const generateProductMasterData = async () => {
    setBackDrop(true);
    const products = await fetchProductDataFromDB();
    generateTransitItemsList(products[0]);
    setProductMasterDataRaw(products[0]);
    setProductMasterDataRawFresh(products[1]);
    setBackDrop(false);
  };

  const generateTransitItemsList = (product_master) => {
    if (!product_master || Object.keys(product_master).length === 0) {
      return console.log("Function did not receive proper arguements");
    }
    const itemList = [];
    Object.keys(product_master).forEach((key, index) => {
      const product = product_master[key];
      const item_id = product.item_id;
      const item_disc = product.item_description1 || "";
      const brand = product.brand || "";

      const item = {
        value: item_id,
        label: `${item_id}-${brand}-${item_disc}`,
      };
      itemList.push(item);
    });

    setAddTransitItemDropdownList(itemList);
  };

  const deleteTransitRoute = (transit_id) => {
    console.log(transit_id);
    let url = `${service_url}/api/transit-stock-return?token=${token}&warehouse=${
      warehouse.value
    }&return_date=${moment(returnDate).format("YYYY-MM-DD")}`;

    let details = {
      transit_id: transit_id,
    };

    fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": sessionStorage.getItem("x-access-token"),
      },
      body: JSON.stringify(details),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success == 1) {
          toast(`${result.message}`, { type: toast.TYPE.SUCCESS });

          setTimeout(function () {
            window.location.reload();
          }, 2000);
        } else {
          toast(`${result.message}`, { type: toast.TYPE.ERROR });
        }
      })
      .catch((err) => {
        toast(`Error in deleting transit route: ${err}`, {
          type: toast.TYPE.ERROR,
        });
      });
  };

  const TransitRoutesTableHeader = () => {
    return (
      <thead className="thead-light">
        <tr>
          <th>Return Date</th>
          <th>Source</th>
          <th>Destination</th>
          <th>Type</th>
          <th>Items</th>
          <th>Delete</th>
        </tr>
      </thead>
    );
  };

  const TransitRoutesTableBody = () => {
    let rows = Object.keys(routeData).map((key, index) => {
      let row = routeData[key] || {};
      return (
        <tr
          style={{
            backgroundColor: row.is_received == -1 ? "#f79a97" : "#96f2a7",
          }}
          key={index}
        >
          <td>{formatDate(row.return_date)}</td>
          <td>{row.src}</td>
          <td>{row.destination}</td>
          <td>{row.type || ""}</td>
          <td>
            <Button
              onClick={() => {
                setTransitID(row.id);
                setItemsPopupShow(true);
                setSelectedReturnType(row.type);
                setUserSelectedWarehouse(row.src);
              }}
            >
              Items
            </Button>
          </td>
          <td>
            {userType.toLowerCase() === "admin" ||
            (userType.toLowerCase() === "systems" &&
              formatDate(row.return_date) == formatDate(new Date())) ? (
              !row.received_qty ? (
                <Button
                  variant="primary"
                  onClick={() => {
                    deleteTransitRoute(row.id);
                  }}
                >
                  Delete
                </Button>
              ) : null
            ) : null}
          </td>
        </tr>
      );
    });

    return (
      <>
        <tbody>{rows}</tbody>
      </>
    );
  };

  const handleReturnDateChange = (selecteddate) => {
    setReturnDate(new Date(selecteddate));
  };

  const CustomDateInput = ({ value, onClick }) => (
    <input
      value={value}
      onClick={onClick}
      style={{ border: "solid 1px black", height: "30px" }}
    />
  );

  return (
    <>
      <NavBar />

      <div className="container col-sm-12" style={{ paddingTop: 100 }}>
        <center>
          <h2 className="h2">Transit Stock Return</h2>
        </center>
        <Form inline>
          <InputGroup size="sm" style={{ margin: "20px" }}>
            <InputGroupAddon addonType="prepend">Return Date</InputGroupAddon>
            <DatePicker
              selected={returnDate}
              onChange={handleReturnDateChange}
              minDate={new Date("2017/01/01")}
              aria-describedby="basic-addon1"
              dateFormat="dd/MM/yyyy"
              customInput={<CustomDateInput />}
            />
          </InputGroup>
          <div style={{ width: "300px", marginTop: "-25px" }}>
            Src Warehouse
            <Select
              isDisabled={isSelectWarehouseOptionDisabled(
                userType,
                userCity,
                warehouse
              )}
              options={warehouseOptionsList}
              value={warehouse}
              onChange={(val) => {
                handleOnWarehouseSelectionChanged(val);
              }}
            />
          </div>
          {!isCreateReturnOptionDisabled(
            userType,
            userCity,
            warehouse,
            returnDate
          ) ? (
            <Button
              disabled={warehouse.value === "All"}
              size="sm"
              style={{ marginLeft: "300px" }}
              onClick={() => {
                setTransitCreatePopupShow(true);
              }}
            >
              Create Transit Return
            </Button>
          ) : null}
          <Button
            style={{ marginLeft: "10px" }}
            size="sm"
            variant="primary"
            onClick={() => {
              props.history.push("/stock-correction");
            }}
          >
            Stock correction
          </Button>{" "}
          <div style={{ marginLeft: "10px", marginRight: "10px" }}>
            <ExportToExcel
              sheet1={transitReturnExportData}
              sheet2={transitReturnExportLooseItemsData}
              filename={"transit_return_complete_summary"}
              sheet1Headers={excelHeaders}
              sheet2Headers={excelHeadersLooseItems}
              sheet1Name="Items"
              sheet2Name="Loose Items"
              buttonText="Export All"
            />
          </div>
        </Form>

        {Object.keys(routeData).length > 0 ? (
          <Table
            striped
            bordered
            hover
            style={{ marginTop: 10, textAlign: "center" }}
          >
            <TransitRoutesTableHeader />
            <TransitRoutesTableBody />
          </Table>
        ) : (
          <center>
            <div>No transit data</div>
          </center>
        )}

        {backDrop ? <Backdrop parentLoadStatus={backDrop} /> : null}

        {/* {transitCreatePopupShow == true ? (
          <TransitRouteCreationModal show={transitCreatePopupShow} />
        ) : null} */}

        {itemsPopupShow == true ? (
          <TransitReturnItemsModal
            show={itemsPopupShow}
            transitID={transitID}
            returnDate={returnDate}
            addTransitItemDropdownList={addTransitItemDropdownList}
            productMasterDataRaw={productMasterDataRaw}
            productMasterDataRawFresh={productMasterDataRawFresh}
            refetchData={refetchData}
            onHide={() => setItemsPopupShow(false)}
            warehouse={userSelectedWarehouse}
            type={selectedReturnType}
          />
        ) : null}

        {transitCreatePopupShow == true ? (
          <TransitReturnCreationModal
            freshReturnCreationItems={freshReturnCreationItems}
            warehouse={warehouse.value || ""}
            show={transitCreatePopupShow}
            returnDate={returnDate}
            transitID={transitID}
            addTransitItemDropdownList={addTransitItemDropdownList}
            productMasterDataRaw={productMasterDataRaw}
            productMasterDataRawFresh={productMasterDataRawFresh}
            onHide={() => setTransitCreatePopupShow(false)}
          />
        ) : null}
      </div>
    </>
  );
};

export default TransitStockReturn;
