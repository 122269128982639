import React, { useState, useEffect, useRef } from 'react'
import { Table, Form, Alert, Button,Modal } from 'react-bootstrap'
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import Select from 'react-select';
import fire from '../../Configs/firebase'

function formatDate(date) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

Date.prototype.timeNow = function () {
   return ((this.getHours() < 10)?"0":"") + this.getHours() +":"+ ((this.getMinutes() < 10)?"0":"") + this.getMinutes() +":"+ ((this.getSeconds() < 10)?"0":"") + this.getSeconds();
}

function UpdateVehicleOrderPriorityModal({mapOrdersData,updateOrder,route,routeDate,...props}) {
  console.log(mapOrdersData);
    const [vehiclePriorityObject, setVehiclePriorityObject] = useState({});

    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_LEFT,
    });

    const updateVehiclePriority = () => {
      console.log('updateVehiclePriority');
      console.log(vehiclePriorityObject);
      console.log(mapOrdersData);
      let orderNumberUpdatedKeysList=Object.keys(vehiclePriorityObject);
      console.log(orderNumberUpdatedKeysList);

      let emptyPriorityPresent=false;
      Object.keys(vehiclePriorityObject).map((key,value)=>{
        if(vehiclePriorityObject[key]==""){
          emptyPriorityPresent=true;
        }
      });

      if(emptyPriorityPresent==true){
        toast("Validation Error! Vehicle priority cannot be empty", { type: toast.TYPE.ERROR });
      }else{
        //validate priority
        let vehorderpriority=document.getElementsByClassName("vehorderpriority");
        let validateVehPriorityList=[];
        console.log(vehorderpriority);
        for(let v=0;v<vehorderpriority.length;v++){
          let order_number=vehorderpriority[v].name;
          let retailer_id="";
          if(order_number.length==13){
            retailer_id=order_number.split('/')[0];
          }else if(order_number.length==15){
            if(order_number.includes("A") || order_number.includes("B") || order_number.includes("R")){
              retailer_id=order_number.split('-')[0];
            }else if(order_number.includes("SC")){
              let retstr=order_number.split('/')[0];
              retailer_id = retstr.substr(retstr.length - 5);
            }
          }
          validateVehPriorityList.push({"order_number":vehorderpriority[v].name,"priority":vehorderpriority[v].value,"retailer_id":retailer_id});
        }
        console.log(validateVehPriorityList);

        let lookup = validateVehPriorityList.reduce((a, e) => {
          a[e.priority] = ++a[e.priority] || 0;
          return a;
        }, {});

        let duplicateVehPriorityList=validateVehPriorityList.filter(e => lookup[e.priority]);
        console.log(duplicateVehPriorityList);

        let isDuplicate=false;
        let duplicatePriority;

        for(let d=0;d<duplicateVehPriorityList.length;d++){
          let order_number=duplicateVehPriorityList[d]['order_number'];
          let priority=duplicateVehPriorityList[d]['priority'];
          let retailer_id=duplicateVehPriorityList[d]['retailer_id'];

          for(let k=0;k<duplicateVehPriorityList.length;k++){
            if(k==d){
              continue;
            }else{
              if(duplicateVehPriorityList[k]['priority']==priority){
                if(duplicateVehPriorityList[k]['retailer_id']!=retailer_id){
                  isDuplicate=true;
                  duplicatePriority=priority;
                }
              }
            }
          }
        }

        if(isDuplicate==true){
          toast(`Priority - ${duplicatePriority} -- can't be assigned to two different Retailers.`, { type: toast.TYPE.ERROR });
        }else{
          //update priority
          let year=routeDate.getFullYear();
          let month=routeDate.getMonth()+1;
          let date=routeDate.getDate()+'-'+month+'-'+year;
          let routeId=route.split('-')[1];
          let currtime=new Date().timeNow();
          let currdatetime = formatDate(new Date())+' '+currtime ;

          let driverordersele={};
          fire.database().ref('Driver_Orders_History/'+year+'/'+month+'/'+date+'/'+routeId+"/").once('value',snapshot=>{
              if(snapshot.hasChildren()){
                  let vehordersdata = snapshot.val();

                  for(let i=0;i<orderNumberUpdatedKeysList.length;i++){
                    let orderNumber=orderNumberUpdatedKeysList[i];
                    Object.keys(vehordersdata).map((key,index)=>{
                      if(vehordersdata[key]['order_number']==orderNumber){
                        driverordersele[`/${key}/priority`]=parseInt(vehiclePriorityObject[`${orderNumber}`]);
                        driverordersele[`/${key}/updated_priority_by`]=JSON.parse(sessionStorage.getItem('user'))[0]['email'];
                        driverordersele[`/${key}/updated_priority_at`]=currdatetime;
                      }
                    });
                  }
                  console.log(driverordersele);
                  if(Object.keys(driverordersele).length>0){
                    let driverOrdersHistoryRef=fire.database().ref('Driver_Orders_History/'+year+'/'+month+'/'+date+'/'+routeId);
                    driverOrdersHistoryRef.update(driverordersele).then(()=>{
                      sessionStorage.setItem('deliverydate',formatDate(new Date(routeDate)));
                      sessionStorage.setItem('vehicle',route);
                      toast("Successfully updated priority", { type: toast.TYPE.SUCCESS });
                      props.onHide();
                      setTimeout( function() {
                        window.location.reload();
                      }, 2000);
                    }).catch((error)=>{
                      toast("Error in updating priority"+error, { type: toast.TYPE.ERROR });
                      props.onHide();
                    })
                  }

              }
          });
        }
      }
    }

    const updatePriority = (e) => {
      let orderNumber=e.target.name;
      setVehiclePriorityObject({...vehiclePriorityObject,[orderNumber]:e.target.value})
    }

    return (<>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    Update Vehicle Priority of  Route {route}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Table>
                  <thead>
                    <tr>
                        <th>OrderNumber</th>
                        <th>Store Name</th>
                        <th>Area</th>
                        <th>Priority</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(mapOrdersData.length>0) ? mapOrdersData.map(mapOrders => (
                          <tr>
                              <td>{mapOrders.orderNumber}</td>
                              <td>{mapOrders.shop}</td>
                              <td>{mapOrders.area}</td>
                              <td><Form.Control type="number" className="vehorderpriority" defaultValue={mapOrders.priority} min="1" name={mapOrders.orderNumber} onChange={(e)=>{updatePriority(e);}}/></td>
                          </tr>
                      )) :null}
                  </tbody>
                </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"success"} onClick={updateVehiclePriority}>Submit</Button>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default UpdateVehicleOrderPriorityModal
