import React, { useState, useMemo, useEffect, useRef } from "react";
import fire from "../../Configs/firebase";
import axios from "axios";
import NavBar from "../Navigation bar/Navbar";
import {
  service_url,
  orderingApiUrl,
  support_portal_url,
  token
} from "../../Configs/mysqlconfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Dropdown,
  Table,
} from "react-bootstrap";
import Form from "react-bootstrap/Form";

import {
  useTable,
  useGlobalFilter,
  useFilters,
  useAsyncDebounce,
  useSortBy,
} from "react-table";
import { CSVLink } from "react-csv";

let today = new Date();
let dd = String(today.getDate()).padStart(2, "0");
let mm = String(today.getMonth() + 1).padStart(2, "0");
let m = String(today.getMonth() + 1);
let yyyy = today.getFullYear();
today = yyyy + "-" + mm + "-" + dd;

function Fresh() {
  const [tableDate, setTableDate] = useState(today);
  const [Dho, setDho] = useState({});
  const [RouteSelected, setRouteSelected] = useState();
  const [RetailerMasters, setRetailerMasters] = useState([]);
  const [DriverOrdersHistory, setDriverOrdersHistory] = useState([]);
  const [RouteT1, setRouteT1] = useState([]);
  const [SecRoute1, setSecRoute1] = useState("All");

  const [Day, setDay] = useState(dd);
  const [Month, setMonth] = useState(m);
  const [Year, setYear] = useState(yyyy);
  const [RouteList, setRouteList] = useState([]);
  const [Route, setRoute] = useState([]);
  const [TableData, setTableData] = useState([]);
  const [KFresh, setKFresh] = useState([]);
  const [ModelDate, setModelDate] = useState([])
  let csv_data = useRef("");

  const [ModalRoute, setModalRoute] = useState();


  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = async (route) => {
    // Retailer master

    // const url1 = `http://localhost:3000/api/superzop/others/freshStoreRegistrationDetails?date=2023-06-06&route=F001`;
    const url1 = `${support_portal_url}/freshStoreRegistrationDetails?date=${tableDate}&route=${route}`
    try {
      let result = await axios.get(url1);
      console.log(result.data);
      setModelDate(result.data)
    } catch (error) {
      console.log(error);
    }



    setModalRoute(route);
    setShow2(true);
  };

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 =  async (route) => {
    // Driver Orders History

    const url1 = `${service_url}/api/superzop/delivery/drivers_orders_history/freshOrderDetail?delivery_date=${tableDate}&route=${route}`;
    try {
      let result = await axios.get(url1);
      console.log(result.data);
      setModelDate(result.data)
    } catch (error) {
      console.log(error);
    }


    setModalRoute(route);
    setShow3(true);
  };

  // --------------------------------------------------------------

  useEffect(() => {
    loadedInventoryCumulative()
    OrderSummery();

    return () => {};
  }, [tableDate,SecRoute1]);

  useEffect(() => {
    getAllRoutes()

    return () => {};
  }, [tableDate]);

 
  async function OrderSummery(params) {

    let retailer = []
    let delivery = []
    const url1 = `${service_url}/api/superzop/delivery/drivers_orders_history/freshDeliverySummery?delivery_date=${tableDate}&route=${SecRoute1}`;
    try {
       let result = await axios.get(url1);
       delivery = result.data
       
    } catch (error) {
      console.log(error);
    }

    
      const url2 = `${support_portal_url}/freshOrderSummary?registration_date=${tableDate}`;
      try {
        retailer = await axios.get(url2);
        retailer = retailer.data

      } catch (error) {
        console.log(error);
      }
  
      let delivery_obj = {}


      for (let de of  delivery ){

        if (delivery_obj[de.driver_id] == undefined ){
          delivery_obj[de.driver_id] = de
        }

      }
      
      let final = []
      for(let re of retailer ){


        if ( (delivery_obj[re?.route]?.orders == 0 || delivery_obj[re?.route]?.orders == undefined) && re?.registered_retailers == 0){
          continue;
        }

        if (SecRoute1 !== 'All'){
          if (SecRoute1 == re?.route){
            let obj  = {
              ...re, ...delivery_obj[re?.route]
            }
            final.push(obj);

          }
        }else{

          let obj  = {
            ...re, ...delivery_obj[re?.route]
          }
          final.push(obj);

        }

      }

      setKFresh(final)
  }

  async function loadedInventoryCumulative(params) {

    const url1 = `${service_url}/api/loaded-inventory-cumulative?token=${token}&start_date=${tableDate}&end_date=${tableDate}&route=${SecRoute1}`;
    try {
       let result = await axios.get(url1);
       setTableData(result.data.data)
    } catch (error) {
      console.log(error);
    }

  }



  async function getAllRoutes(){


    const url1 = `${service_url}/api/getallroutes`;
    try {
       let result = await axios.get(url1);
       let data = result.data

        let arr = [];

        for (let da of data ){
          arr.push(da.route)
        }

        console.log(arr);
        setRouteT1(arr)
    } catch (error) {
      console.log(error);
    }
  }


  



  function createCsv(data, columns) {
    let final_csv = [];
    for (let row of data) {
      let one_row = [];

      for (let col of columns) {
        if (
          col.accessor === "actual_reason" &&
          row[col.accessor] === undefined
        ) {
          one_row.push(row["reason"]);
          continue;
        }

        one_row.push(row[col.accessor]);
      }
      final_csv.push(one_row);
    }

    let one_row = [];
    for (let col of columns) {
      one_row.push(col.Header);
    }
    final_csv.unshift(one_row);
    // csv_data is a useRef('') hook
    csv_data.current = final_csv;
  }

  function getTableOnSelect(td, SecRoute1) {
    if (SecRoute1 == "All") {
      let obj = {};

      for (let t of td) {
        if (obj[t.route] === undefined) {
          obj[t.route] = {
            brand: t.brand,
            route: t.route,
            crate_count: Number(t.crate_count),
            image_url: t.image_url,
            item_disc: t.item_disc,
            item_id: t.item_id,
            long_description: t.long_description,
            order_number: t.order_number,
            item_remaining: t.item_remaining,
            doh_item_delivered_qty: t.doh_item_delivered_qty,
            qty: t.qty,
            remaining_qty: t.remaining_qty,
          };
        } else {
          if (
            obj[t.route]["item_disc"] == null ||
            obj[t.route]["item_disc"] == ""
          ) {
            obj[t.route]["item_disc"] = t.item_disc;
          }

          obj[t.route]["item_remaining"] += t.item_remaining;
          obj[t.route]["doh_item_delivered_qty"] += t.doh_item_delivered_qty;
          obj[t.route]["qty"] += t.qty;
          obj[t.route]["crate_count"] += Number(t.crate_count);
        }
      }

      let tableD = [];
      for (let ob in obj) {
        tableD.push(obj[ob]);
      }

      return tableD;
    } else {
      let tableD = [];
      for (let t of td) {
        if (t.route == SecRoute1) {
          tableD.push(t);
        }
      }

      return tableD;
    }
  }

  function Table1() {
    // product summary


    const data = React.useMemo(() => TableData, []);

    const columns = React.useMemo(
      () => [
        {
          Header: "route",
          accessor: "route",
        },
        {
          Header: "Item Id",
          accessor: "item_id",
          Filter: SelectColumnFilter,
        },

        {
          Header: "Image preview",
          accessor: "image_url",
          Cell: ({ row }) => {
            return (
              <img width="40" height="50" src={`${row.original.image_url}`} />
            );
          },
        },
        {
          Header: "Item disc",
          accessor: "long_description",
          Filter: SelectColumnFilter,
        },
        {
          Header: "Brand",
          accessor: "brand",
          Filter: SelectColumnFilter,
        },

        // {
        //   Header: "city",
        //   accessor: "city",
        //   Filter: SelectColumnFilter,
        // },

        // {
        //   Header: "driver_name",
        //   accessor: "driver_name",
        //   Filter: SelectColumnFilter,
        // },
        {
          Header: "Total crate count",
          accessor: "total_crate_count",
        },
        {
          Header: "Total cases",
          accessor: "total_cases",
        },
        {
          Header: "Total qty",
          accessor: "total_qty",
        },
        {
          Header: "Total remaining qty",
          accessor: "total_remaining_qty",
        },
      ],
      []
    );

    createCsv(data, columns);

    function SelectColumnFilter({
      column: { filterValue, setFilter, preFilteredRows, id },
    }) {
      const options = useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
          options.add(row.values[id]);
        });
        return [...options.values()];
      }, [id, preFilteredRows]);

      return (
        <select
          value={filterValue}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
        >
          <option value="">All</option>
          {options.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </select>
      );
    }

    const defaultColumn = useMemo(
      () => ({
        Filter: () => null,
      }),
      []
    );

    const GlobalFilter = ({ filter, setFilter }) => {
      const [value, setValue] = useState(filter);
      const onChange = useAsyncDebounce((value) => {
        setFilter(value || undefined);
      }, 1000);
      return (
        <span>
          <input
            placeholder="scarch..."
            value={value || ""}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
          />
        </span>
      );
    };

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      setGlobalFilter,
      preGlobalFilteredRows,
    } = useTable({ columns, data, defaultColumn }, useFilters, useGlobalFilter);

    const { globalFilter } = state;

    return (
      <div>
        <Row>
          <Col>
            {" "}
            <h3>Product Summary</h3>{" "}
          </Col>
          <Col>
            <GlobalFilter
              filter={globalFilter}
              setFilter={setGlobalFilter}
            ></GlobalFilter>
          </Col>
          <Col>
            <CSVLink
              className="btn btn-warning export_btn"
              filename={"KhetikaFreshPortal.csv"}
              data={csv_data.current}
            >
              Export Data
            </CSVLink>{" "}
          </Col>

          <Col>
            <Dropdown
              onSelect={(evt, evtKey) => {
                console.log(evt);
                setSecRoute1(evt);
                // setUploadPayload({ ...uploadPayload, physical_cheque_collection: evt });
              }}
            >
              <Dropdown.Toggle variant="success">
                Route  {SecRoute1}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey="All">All</Dropdown.Item>
                {RouteT1 &&
                  RouteT1.map((itm) => (
                    <Dropdown.Item eventKey={itm}>{itm}</Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col></Col>
          <Col></Col>
          <Col></Col>
          <Col></Col>
        </Row>
        <br />

        <table
          {...getTableProps()}
          style={{ border: "solid 1px blue", width: "100%" }}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{
                      borderBottom: "solid 3px red",
                      background: "aliceblue",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {column.render("Header")}
                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: "10px",
                          border: "solid 1px gray",
                          background: "papayawhip",
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  function Table2() {
    // order summary
    const data = useMemo(() => KFresh, []);

    const columns = useMemo(
      () => [
        {
          Header: "Depo",
          accessor: "depo",
          Filter: SelectColumnFilter,
        },

        {
          Header: "Route",
          accessor: "route",
          Filter: SelectColumnFilter,
        },
        {
          Header: "Driver name",
          accessor: "driver_name",
          Filter: SelectColumnFilter,
        },
        {
          Header: "Area",
          accessor: "region",
          Filter: SelectColumnFilter,
        },
        {
          Header: "Store Registration",
          accessor: "registered_retailers",
          Filter: SelectColumnFilter,
          Cell: ({ row }) => {
            return (
              <Button
                variant="link"
                onClick={() => handleShow2(row.original.route)}
              >
                {" "}
                {row.original.registered_retailers}
              </Button>
            );
          },
        },
        {
          Header: "Order",
          accessor: "orders",
          Cell: ({ row }) => {
            return (
              <Button
                variant="link"
                onClick={() => handleShow3(row.original.route)}
              >
                {" "}
                {row.original.orders}
              </Button>
            );
          },
        },
        {
          Header: "order value",
          accessor: "order_value",
          Cell: ({ row }) => {
            return row.original?.order_value ? Number(row.original?.order_value)?.toFixed(2) : "";
          },
        },
      ],
      []
    );

    function SelectColumnFilter({
      column: { filterValue, setFilter, preFilteredRows, id },
    }) {
      const options = useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
          options.add(row.values[id]);
        });
        return [...options.values()];
      }, [id, preFilteredRows]);

      return (
        <select
          value={filterValue}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
        >
          <option value="">All</option>
          {options.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </select>
      );
    }

    const defaultColumn = useMemo(
      () => ({
        Filter: () => null,
      }),
      []
    );

    const GlobalFilter = ({ filter, setFilter }) => {
      const [value, setValue] = useState(filter);
      const onChange = useAsyncDebounce((value) => {
        setFilter(value || undefined);
      }, 1000);
      return (
        <span>
          <input
            placeholder="scarch..."
            value={value || ""}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
          />
        </span>
      );
    };

    let initialState = {
      sortBy: [
        {
          id: "depo",
          desc: true,
        },
        {
          id: "area",
          desc: true,
        },
      ],
    };

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      setGlobalFilter,
      preGlobalFilteredRows,
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        initialState,
      },
      useFilters,
      useGlobalFilter,
      useSortBy
    );

    const { globalFilter } = state;

    return (
      <div>
        <Row>
          <Col>
            <h3>Order Summary</h3>
          </Col>

          <Col>
            <GlobalFilter
              filter={globalFilter}
              setFilter={setGlobalFilter}
            ></GlobalFilter>
          </Col>
          <Col></Col>
          <Col></Col>
          <Col></Col>
          <Col></Col>
          <Col></Col>
          <Col></Col>
        </Row>
        <br />

        <table
          {...getTableProps()}
          style={{ border: "solid 1px blue", width: "100%" }}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{
                      borderBottom: "solid 3px red",
                      background: "aliceblue",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {column.render("Header")}
                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: "10px",
                          border: "solid 1px gray",
                          background: "papayawhip",
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div>
      <NavBar />
      <br />
      <br />
      <br />
      <div style={{ margin: 20 }}>
        <h2> Khetika Fresh Portal </h2>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="date"
                value={tableDate}
                onChange={(e) => setTableDate(e.target.value)}
                min={JSON.parse(sessionStorage.getItem('user'))[0].user_type == 'sz-admin' ?  new Date("2024/10/21") : null}
              />
            </Form.Group>
          </Col>
          <Col></Col>
          <Col></Col>
          <Col></Col>
          <Col></Col>
          <Col></Col>
        </Row>

        <Row>
          <Col>
            <Table1 />
          </Col>
        </Row>
        <br />
        <br />
        <br />
        <Row>
          <Col>
            <Table2 />
          </Col>
        </Row>

  
        <Modal show={show2} onHide={handleClose2} size="lg">
          <Modal.Header closeButton>
            <Modal.Title> Details {ModalRoute} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table>
              <thead>
                <tr>
                  <th>Route</th>
                  <th>Retailer id</th>
                  <th>Retailer name</th>
                  <th>Shop name</th>
                  <th>Phone</th>
                  <th>Last order number </th>
                </tr>
              </thead>
              <tbody>
                {ModelDate &&
                  ModelDate?.map((item, i) => {
 
                      return (
                        <>
                          <tr key={i}>
                            <td>{item.route} </td>
                            <td>{item.retailer_id} </td>
                            <td>{item.retailer_name} </td>
                            <td>{item.shop_name} </td>
                            <td>{item.phone} </td>
                            <td>{item.last_order_number} </td>
                          </tr>
                        </>
                      );
                    
                  })}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show3} onHide={handleClose3} size="lg">
          <Modal.Header closeButton>
            <Modal.Title> Details {ModalRoute} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table>
              <thead>
                <tr>

                  <th>order number</th>
                  <th>phone</th>
                  <th>shop name</th>
                  <th>retailer id</th>
                  <th>driver name</th>
                </tr>
              </thead>

              <tbody>
                {ModelDate &&
                  ModelDate?.map((item) => {
      
                      return (
                        <tr key={item.order_number}>
                          <td> {item.order_number} </td>
                          <td> {item.driver_phone_number} </td>
                          <td> {item.shop_name} </td>
                          <td> {item.retailer_id} </td>
                          <td> {item.driver_name} </td>
                        </tr>
                      );
                    
                  })}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose3}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>{" "}
    </div>
  );
}

export default Fresh;
