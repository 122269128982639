import React, { useState, useMemo, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NavBar from "../../Navigation bar/Navbar";
import { Container, Row, Col, Button, Modal, Form } from "react-bootstrap";

import {
  useTable,
  useGlobalFilter,
  useFilters,
  useAsyncDebounce,
} from "react-table";
import { CSVLink } from "react-csv";

let today = new Date();
let dd = String(today.getDate());
let mm = String(today.getMonth() + 1);
let yyyy = today.getFullYear();
today = yyyy + "-" + mm + "-" + dd;



function DetailedReport({TableData, amount_per_service ,additional_amount,max_km_for_fixed_amt  }) {
  const [show1, setShow1] = useState(false);
  let csv_data = useRef("");


  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => {
    toast(" showing tost massage please remove this on  handleShow1 fun");
    setShow1(true);
  };

  function filterData(data, amount_per_service ) {
    let obj = {};
    let data2 = [];
  
    for (let dt of data) {

      if(dt.vehicle_status == "absent" || dt.vehicle_status == "vehicle_breakdown" ){
        continue
    }


      if (obj[dt.driver_Phone_Number] == undefined) {
        obj[dt.driver_Phone_Number] = {
          transporter: dt.company_type,
          driver_Phone_Number: dt.driver_Phone_Number,
          actual_Driver_Name: dt.actual_Driver_Name,
          routes_serviced: 0,
          sum_kms: 0,
          additional_kms: 0,
          fixed_amt: 0,
          additional_amt: 0,
          Short_Cash_Amt: 0,
          stock_short_amt: 0,
          penalty_amt: 0,
          payable_amt: 0,
        };
      }
  
      let curr_obj = obj[dt.driver_Phone_Number];
      let sum_kms = curr_obj.sum_kms + Number(dt?.kms_travelled || 0);
      let Short_Cash_Amt =
        Number(curr_obj?.Short_Cash_Amt) + Number(dt?.adjustments || 0);
      let stock_short_amt =
        Number(curr_obj?.stock_short_amt) + Number(dt?.short_items || 0);
      let penalty_amt =
        Number(curr_obj?.penalty_amt) + Number(dt?.penalty_amt || 0);
      let payable_amt = "";
  
      let new_obj = {
        transporter: curr_obj.transporter,
        driver_Phone_Number: curr_obj.driver_Phone_Number,
        actual_Driver_Name: curr_obj.actual_Driver_Name,
        routes_serviced: Number(curr_obj?.routes_serviced || 0) + 1,
        sum_kms: sum_kms,
        Short_Cash_Amt: Short_Cash_Amt,
        stock_short_amt: stock_short_amt,
        penalty_amt: penalty_amt,
      };
      obj[dt.driver_Phone_Number] = new_obj;
    }
  
    console.log(obj);
    for (let ob in obj) {
      let current = obj[ob];
  
      let fixed_amt = Number(current?.routes_serviced) * amount_per_service;
      let additional_kms =
        current.sum_kms - max_km_for_fixed_amt > 0 ? current.sum_kms - max_km_for_fixed_amt : 0;
      let additional_amt = additional_kms > 0 ? Number(additional_kms) * additional_amount : 0;
      let payable_amt =
        fixed_amt +
        additional_amt -
        current.Short_Cash_Amt -
        current.stock_short_amt -
        current.penalty_amt;
  
      current["fixed_amt"] = fixed_amt;
      current["additional_kms"] = additional_kms;
      current["additional_amt"] = additional_amt;
      current["payable_amt"] = Number(payable_amt).toFixed(2);
  
      data2.push(current);
    }
  
    return data2;
  }

  function createCsv(data, columns) {
    let final_csv = [];
    for (let row of data) {
      let one_row = [];
      for (let col of columns) {
        if (
          col.accessor === "actual_reason" &&
          row[col.accessor] === undefined
        ) {
          one_row.push(row["reason"]);
          continue;
        }

        one_row.push(row[col.accessor]);
      }
      final_csv.push(one_row);
    }

    let one_row = [];
    for (let col of columns) {
      one_row.push(col.accessor);
    }
    final_csv.unshift(one_row);

    csv_data.current = final_csv;
  }

  let data2 = filterData(TableData, amount_per_service);
  const data = useMemo(() => data2, [TableData, amount_per_service]);

  const columns = useMemo(
    () => [
      {
        Header: "Transporter",
        accessor: "transporter",
      },
      {
        Header: "Driver Phone No",
        accessor: "driver_Phone_Number",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Driver",
        accessor: "actual_Driver_Name",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Routes Serviced",
        accessor: "routes_serviced",
      },
      {
        Header: "Additional Kms",
        accessor: "additional_kms",
      },
      {
        Header: "Fixed amt",
        accessor: "fixed_amt",
      },
      {
        Header: "Additional amt",
        accessor: "additional_amt",
      },
      {
        Header: "Short Cash Amt",
        accessor: "Short_Cash_Amt",
      },
      {
        Header: "stock short amt",
        accessor: "stock_short_amt",
      },
      {
        Header: "Penalty amt",
        accessor: "penalty_amt",
      },
      {
        Header: "Payable amt",
        accessor: "payable_amt",
      },
    ],
    []
  );

  createCsv(data, columns);

  function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    const options = useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    options.sort()
    
    return (
      <select
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  }

  const defaultColumn = useMemo(
    () => ({
      Filter: () => null,
    }),
    []
  );

  const GlobalFilter = ({ filter, setFilter }) => {
    const [value, setValue] = useState(filter);
    const onChange = useAsyncDebounce((value) => {
      setFilter(value || undefined);
    }, 1000);
    return (
      <span>
        <input
          placeholder="scarch..."
          value={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
        />
      </span>
    );
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    preGlobalFilteredRows,
  } = useTable({ columns, data, defaultColumn }, useFilters, useGlobalFilter);

  const { globalFilter } = state;

  return (
    <div>
      <Row>
        <Col>
          <GlobalFilter
            filter={globalFilter}
            setFilter={setGlobalFilter}
          ></GlobalFilter>
        </Col>
        <Col>
          <CSVLink
            className="btn btn-warning export_btn"
            filename={"Detailed_Report.csv"}
            data={csv_data.current}
          >
            Export Data
          </CSVLink>{" "}
        </Col>
        <Col>
          {/* <Button variant="primary" onClick={handleShow1}>
            Launch demo modal
          </Button> */}
        </Col>
        <Col>{/* <Button variant="warning">Primary</Button>{" "} */}</Col>
        <Col>{/* <Button variant="success">Primary</Button>{" "} */}</Col>
        <Col>{/* <Button variant="warning">Primary</Button>{" "} */}</Col>
        <Col>{/* <Button variant="info">Primary</Button>{" "} */}</Col>
      </Row>
      <br />

      <table
        {...getTableProps()}
        style={{ border: "solid 1px blue", width: "100%" }}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  style={{
                    borderBottom: "solid 3px red",
                    background: "aliceblue",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  {column.render("Header")}
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        padding: "10px",
                        border: "solid 1px gray",
                        background: "papayawhip",
                      }}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <Modal show={show1} onHide={handleClose1} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>name</Form.Label>
            <Form.Control type="test" onChange={(e) => {}} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose1}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DetailedReport;
