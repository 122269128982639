import React, { useEffect,useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import NavBar from '../Navigation bar/Navbar'
import fire from '../../Configs/firebase'
import Backdrop from '../backdrop/backdrop'
import { toast } from 'react-toastify';
import { InputGroupAddon, Input, InputGroupText, CardHeader, CardTitle, CardText } from 'reactstrap';
import {Tab,Row,Col,Nav,Form,Dropdown,InputGroup,Button,DropdownButton,Breadcrumb,Table,ButtonGroup,Container} from 'react-bootstrap';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { validateIsAdminUser } from '../UserCredentials'
import UpdatedDate from '../Orders/UpdatedDate'
import XLSX from 'xlsx'
import correct from "../../assests/images/correct.png";
import {Image} from "@mantine/core";
import Select from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faTimes,
  faUndo,
  faCircle,
  faTrash,
  faEdit,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";

function formatDate(date) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

const SuperKreditOrders = () => {
  let userRoutes=sessionStorage.getItem("allowedRoutes")?JSON.parse(sessionStorage.getItem("allowedRoutes")):[];

  let allowedRoutesSet = new Set();

  for(let i=0;i<userRoutes.length;i++){
    let route=`/${userRoutes[i]}`;
    if(!allowedRoutesSet.has(route)){
      allowedRoutesSet.add(route);
    }
  }

  let delivery_Date=sessionStorage.getItem("getDB") || "";
  let deliveryDateList = delivery_Date.split("/");
  let finalDeliveryDate = deliveryDateList[deliveryDateList.length - 1];
  let finalDeliveryDateList = finalDeliveryDate.split("-");

  let initialDate = `${finalDeliveryDateList[2]}-${finalDeliveryDateList[1]}-${finalDeliveryDateList[0]}`;

  const [deliveryDate,setDeliveryDate] = useState();
  const [ordersList, setOrdersList] = useState([]);
  const [searchOrdersList, setSearchOrdersList] = useState([]);
  const [internalRetailersCreditData,setInternalRetailersCreditData]=useState({});
  const [getCreditErrorCount, setCreditErrorCount] = useState(0);
  const [backDrop, setBackDrop] = useState(false);
  const [exportErrorMsg, setExportErrorMsg] = useState("");
  const [exportData, setExportData] = useState(false);
  const [statusText, setStatusText] = useState("");
  const [isOrderUpdateAllowed, setIsOrderUpdateAllowed] = useState(false);
  const [superkreditFlag, setSuperkreditFlag] = useState(false);
  const [isAllOrdersSuperkreditUpdated, setIsAllOrdersSuperkreditUpdated] = useState(false);

  toast.configure({
      autoClose: 4000,
      draggable: true,
      pauseOnHover: true,
      position: toast.POSITION.TOP_LEFT,
  });

  useEffect(() => {
    if(sessionStorage.getItem("superkreditDeliveryDate")){
      setDeliveryDate(new Date(sessionStorage.getItem("superkreditDeliveryDate")))
      sessionStorage.removeItem("superkreditDeliveryDate")
    }else{
      setDeliveryDate(new Date(initialDate));
    }
  },[])

  useEffect(() => {
      if (exportData)
          exportSuperkreditOrders()
  }, [exportData])

  useEffect(() => {
    if (statusText){
      filterSuperkreditOrders()
    }else{
      setOrdersList(searchOrdersList);
    }
  }, [statusText])

  const filterSuperkreditOrders = () => {
    let filteredOrdersList = [];

    for(let i=0;i<searchOrdersList.length;i++){
      let status = searchOrdersList[i].status || "";
      if(statusText==status || statusText=="All"){
        filteredOrdersList.push(searchOrdersList[i]);
      }
    }

    setOrdersList(filteredOrdersList);
  }


  const revisedAmt = (itemsInOrders, orderNo) => {

    let undeliveredItemAmt = 0
    let totalAmtPaid = 0
    let totalOrderValue
    Object.keys(itemsInOrders).forEach(orderNumber => {
      if (!(orderNumber.includes(`${orderNo.split("-")[0]}-${orderNo.split("-")[1]}`)))
        return;

      if((itemsInOrders[orderNumber].item_delivered_qty && itemsInOrders[orderNumber].mrp)||
        (itemsInOrders[orderNumber].item_modified_qty && itemsInOrders[orderNumber].mrp)) {
          if(itemsInOrders[orderNumber].status.toLowerCase().includes(`partially-delivered`) && itemsInOrders[orderNumber].item_delivered_qty) {
            undeliveredItemAmt += (Number(itemsInOrders[orderNumber].mrp) / Number(itemsInOrders[orderNumber].order_qty)) * (Number(itemsInOrders[orderNumber].order_qty) - Number(itemsInOrders[orderNumber].item_delivered_qty))
          }else if ((itemsInOrders[orderNumber].status.toLowerCase().includes(`shipped`) || itemsInOrders[orderNumber].status.toLowerCase().includes(`partially-delivered`)) && itemsInOrders[orderNumber].item_modified_qty) {
            undeliveredItemAmt += (Number(itemsInOrders[orderNumber].mrp) / Number(itemsInOrders[orderNumber].order_qty)) * (Number(itemsInOrders[orderNumber].order_qty) - Number(itemsInOrders[orderNumber].item_modified_qty))
          }
      }

      totalOrderValue = Number(itemsInOrders[orderNumber].total_amt);
      totalAmtPaid = itemsInOrders[orderNumber]["payment_details"]?itemsInOrders[orderNumber]["payment_details"]["total_amount_paid"]:0
    })

    if(!undeliveredItemAmt) {
      undeliveredItemAmt = 0
    }
    let orderDisc = itemsInOrders[orderNo].order_disc?Number(itemsInOrders[orderNo].order_disc):0;
    let revisedOrderDisc = itemsInOrders[orderNo].revised_order_disc?Number(itemsInOrders[orderNo].revised_order_disc):orderDisc;

    return (totalOrderValue - undeliveredItemAmt)
  }

  useEffect(() => {
    if(deliveryDate){
      fetchSuperkreditOrders();
    }
  }, [deliveryDate])

  const generateSuperkreditOrders = (internalCreditData,getOrderHistory,updatedSuperkreditOrdersObj,formattedDeliveryDate,year,month,date,finaldate) => {
    let orderStatusErrorMsg = "";
    let orderStatusCheckMsg = "";

    let internalSuperKreditObj={};
    Object.keys(getOrderHistory).forEach(routeKey => {
      if(routeKey!="9998"){
        Object.keys(getOrderHistory[routeKey]).forEach(orderKey => {
          let ordered_amt=getOrderHistory[routeKey][orderKey]['total_amt']?Number(getOrderHistory[routeKey][orderKey]['total_amt']):0;
          let delivered_amt=revisedAmt(getOrderHistory[routeKey], orderKey);
          let order_number=getOrderHistory[routeKey][orderKey]['order_number'];
          let credit_amt=getOrderHistory[routeKey][orderKey]['credit_amt']?Number(getOrderHistory[routeKey][orderKey]['credit_amt']):0;
          let order_status=getOrderHistory[routeKey][orderKey]['status'];

          if(order_status=="Cancelled"){
            delivered_amt=0;
          }

          let paymentDiff = credit_amt-delivered_amt;

          let paymentDets={};
          if(getOrderHistory[routeKey][orderKey]['order_payment_mode']=="Internal_Credit" && !order_number.includes('R') && (routeKey!="9999" && routeKey!="8888") && (order_status=="Cancelled" || order_status.includes("Delivered"))){
            if(!order_status.includes("Delivered") && order_status!="Cancelled") {
              orderStatusCheckMsg = `${order_number} has ${order_status} status`;
            }

            if('payment_details' in getOrderHistory[routeKey][orderKey]){
              paymentDets=getOrderHistory[routeKey][orderKey]['payment_details'];

              let otherPaymentModePaidAmount = 0;
              let internalCreditPaymentAmt = 0;
              let paymentModeStr = "";
              let chequeDetailsObj = {};
              Object.keys(paymentDets).map((pkey,pindex) => {
                if(pkey!="total_amt_paid" && pkey!="total_amount_paid"){
                  let mode=paymentDets[pkey]['payment_mode']?paymentDets[pkey]['payment_mode']:"";
                  paymentModeStr+=`${mode},`;

                  if(mode!="internalsuperkredit") {
                    otherPaymentModePaidAmount += paymentDets[pkey]['total_amount_paid']?Number(paymentDets[pkey]['total_amount_paid']):paymentDets[pkey]['total_amt_paid']?Number(paymentDets[pkey]['total_amt_paid']):0;
                  }else if(mode=="internalsuperkredit") {
                    internalCreditPaymentAmt = paymentDets[pkey]['total_amount_paid']?Number(paymentDets[pkey]['total_amount_paid']):paymentDets[pkey]['total_amt_paid']?Number(paymentDets[pkey]['total_amt_paid']):0;

                    chequeDetailsObj['cheque_number']=paymentDets[pkey]['cheque1_number']?paymentDets[pkey]['cheque1_number']:"";
                    chequeDetailsObj['cheque_date']=paymentDets[pkey]['cheque1_date']?paymentDets[pkey]['cheque1_date']:"";
                    chequeDetailsObj['cheque_amt']=paymentDets[pkey]['cheque1_amount']?paymentDets[pkey]['cheque1_amount']:"";

                    if(paymentDets[pkey]['cheque2_amount']) {
                      chequeDetailsObj['cheque_number2']=paymentDets[pkey]['cheque2_number']?paymentDets[pkey]['cheque2_number']:"";
                      chequeDetailsObj['cheque_date2']=paymentDets[pkey]['cheque2_date']?paymentDets[pkey]['cheque2_date']:"";
                      chequeDetailsObj['cheque_amt2']=paymentDets[pkey]['cheque2_amount']?paymentDets[pkey]['cheque2_amount']:"";
                    }
                  }
                }
              })

              if(paymentModeStr) {
                paymentModeStr = paymentModeStr.slice(0,-1);
              }

              Object.keys(paymentDets).map((pkey,pindex) => {
                if(pkey!="total_amt_paid" && pkey!="total_amount_paid"){

                  if(paymentModeStr.includes("internalsuperkredit")){
                    let kreditele={};
                    kreditele['delivery_date']=formattedDeliveryDate;
                    kreditele['cheque_number']=chequeDetailsObj['cheque_number'] || "";
                    kreditele['cheque_date']=chequeDetailsObj['cheque_date'] || "";
                    kreditele['cheque_amt']=chequeDetailsObj['cheque_amt'] || "";
                    //cheque_number2, cheque_date2, cheque_amt2
                    if(chequeDetailsObj['cheque_amt2']){
                      kreditele['cheque_number2']=chequeDetailsObj['cheque_number2'] || "";
                      kreditele['cheque_date2']=chequeDetailsObj['cheque_date2'] || "";
                      kreditele['cheque_amt2']=chequeDetailsObj['cheque_amt2'] || "";
                    }
                    kreditele['orderKey']=orderKey;
                    kreditele['phone']=getOrderHistory[routeKey][orderKey]['phone'];
                    kreditele['payment_diff']=(credit_amt-internalCreditPaymentAmt).toFixed(2);

                    kreditele['ordered_amt']=ordered_amt;
                    kreditele['delivered_amt']=internalCreditPaymentAmt.toFixed(2);
                    kreditele['actual_delivered_amt']=internalCreditPaymentAmt.toFixed(2);
                    kreditele['credit_amt']=credit_amt;
                    kreditele['status']=getOrderHistory[routeKey][orderKey]['status'];

                    kreditele['retailer_id']=getOrderHistory[routeKey][orderKey]['retailer_id'];
                    kreditele['order_number']=order_number;
                    kreditele['credit_type']="Internal";

                    kreditele['payment_mode']=paymentModeStr;
                    kreditele['route']=routeKey;
                    internalSuperKreditObj[order_number]=kreditele;
                  }else{
                    //other payment modes

                    let kreditele={};
                    kreditele['delivery_date']=formattedDeliveryDate;
                    kreditele['orderKey']=orderKey;
                    kreditele['phone']=getOrderHistory[routeKey][orderKey]['phone'];
                    delivered_amt=0;
                    kreditele['actual_delivered_amt']=delivered_amt.toFixed(2);

                    paymentDiff = credit_amt-delivered_amt;

                    kreditele['payment_diff']=paymentDiff.toFixed(2);
                    kreditele['ordered_amt']=ordered_amt;
                    kreditele['delivered_amt']=delivered_amt.toFixed(2);
                    kreditele['credit_amt']=credit_amt;
                    kreditele['status']=getOrderHistory[routeKey][orderKey]['status'];

                    kreditele['retailer_id']=getOrderHistory[routeKey][orderKey]['retailer_id'];
                    kreditele['order_number']=order_number;
                    kreditele['credit_type']="Internal";
                    kreditele['payment_mode']=paymentModeStr;
                    kreditele['case_type']="Other Payments";
                    kreditele['route']=routeKey;
                    internalSuperKreditObj[order_number]=kreditele;
                  }
                }
              })
            }else if(order_status=="Cancelled"){
              let kreditele={};
              kreditele['orderKey']=orderKey;
              kreditele['phone']=getOrderHistory[routeKey][orderKey]['phone'];
              kreditele['payment_diff']=paymentDiff.toFixed(2);

              kreditele['ordered_amt']=ordered_amt;
              kreditele['delivered_amt']=delivered_amt.toFixed(2);
              kreditele['credit_amt']=credit_amt;
              kreditele['status']=getOrderHistory[routeKey][orderKey]['status'];

              kreditele['retailer_id']=getOrderHistory[routeKey][orderKey]['retailer_id'];
              kreditele['order_number']=order_number;
              kreditele['credit_type']="Internal";
              kreditele['actual_delivered_amt']=delivered_amt.toFixed(2);
              kreditele['payment_mode']="";
              kreditele['route']=routeKey;
              internalSuperKreditObj[order_number]=kreditele;
            }

          }

        })
      }
    })

    console.log(internalSuperKreditObj);

    if(orderStatusCheckMsg) {
      setExportErrorMsg(orderStatusCheckMsg);
    }

    let updatedSuperkreditOrdersSet = new Set();
    let orderUpdatedSuperkreditObj = {};
    Object.keys(updatedSuperkreditOrdersObj).map((ukey,uindex) => {
      let order_number = updatedSuperkreditOrdersObj[ukey].order_number || "";
      let superkredit_flag = updatedSuperkreditOrdersObj[ukey].superkredit_flag==true?true:false;

      orderUpdatedSuperkreditObj[order_number] = Number(updatedSuperkreditOrdersObj[ukey].updated_available_credit) || 0;

      if(superkredit_flag){
        updatedSuperkreditOrdersSet.add(order_number);
      }
    })

    console.log(updatedSuperkreditOrdersSet);

    let finalInternalKreditOrdersList = [];
    let errorCount = 0;
    let isAllOrdersUpdated = true;

    Object.keys(internalSuperKreditObj).map((key,index) => {
      let retailerErrorMsg = "";
      let orderele = internalSuperKreditObj[key];
      let orderNumber = orderele.order_number;
      let retailer_id = orderele.retailer_id;
      let phone = orderele.phone;
      let orderstatus = orderele.status || "";
      let ordered_amt = Number(orderele.ordered_amt) || 0;

      if (orderstatus){
          if(orderstatus == "Ordered" || orderstatus == "Processed" || orderstatus.includes('Shipped')) {
            orderStatusErrorMsg = `Data can't be exported when order no.${orderNumber} | ${orderele.route} is in Ordered or Processed or Shipped state`;
          }
      }

      let updatedAvailableCredit = orderele.payment_diff?Number(orderele.payment_diff):0;
      updatedAvailableCredit=updatedAvailableCredit.toFixed(2);

      let retailerAvailableCredit=0;
      let retailerApprovedKredit=0;
      if(internalCreditData){
        if(internalCreditData[retailer_id]){
          if(internalCreditData[retailer_id]['approved_details']){
            retailerAvailableCredit = cleanNumber(internalCreditData[retailer_id]['approved_details'].available_credit);
            retailerApprovedKredit = cleanNumber(internalCreditData[retailer_id]['approved_details'].approved_credit);
          }else{
            retailerErrorMsg = `Not approved`;
          }
        }else{
          retailerErrorMsg = `Not registered`;
        }
      }else{
        retailerErrorMsg = `No credit info for all retailers`;
      }

      let finalRetailerAvailableCredit=retailerAvailableCredit+parseInt(updatedAvailableCredit);
      if(parseInt(finalRetailerAvailableCredit)>parseInt(retailerApprovedKredit) && !updatedSuperkreditOrdersSet.has(orderNumber)){
        let creditDiff = finalRetailerAvailableCredit - retailerApprovedKredit;
        if(retailerErrorMsg==""){
          retailerErrorMsg = `Exceeds approved credit by ${creditDiff.toFixed(2)}`
          orderele['new_available_credit'] = Number(retailerApprovedKredit) - parseInt(ordered_amt);
        }
      }
      finalRetailerAvailableCredit=finalRetailerAvailableCredit.toFixed(2);

      if(!phone){
        retailerErrorMsg = `Invalid phone number`;
      }

      orderele['available_credit'] = retailerAvailableCredit;

      if(orderUpdatedSuperkreditObj[orderNumber]){
        orderele['updated_available_credit'] = orderUpdatedSuperkreditObj[orderNumber];
      }else{
        orderele['updated_available_credit'] = finalRetailerAvailableCredit;
      }

      orderele['approved_credit'] = retailerApprovedKredit;
      orderele['error_msg'] = retailerErrorMsg;
      orderele['is_already_paid'] = updatedSuperkreditOrdersSet.has(orderNumber);
      orderele['finaldate'] = finaldate;

      if(!orderele['is_already_paid']) {
        isAllOrdersUpdated = false;
      }

      if(retailerErrorMsg){
        errorCount +=1;
      }

      finalInternalKreditOrdersList.push(orderele);
    })

    if(orderStatusErrorMsg!=""){
      setExportErrorMsg(orderStatusErrorMsg);
    }

    setIsAllOrdersSuperkreditUpdated(isAllOrdersUpdated);

    if(finalInternalKreditOrdersList.length>0){
      setBackDrop(false);
      setCreditErrorCount(errorCount);
      setOrdersList(finalInternalKreditOrdersList);
      setSearchOrdersList(finalInternalKreditOrdersList);
      setInternalRetailersCreditData(internalCreditData);
    }else{
      toast(`No Superkredit orders!`, { type: toast.TYPE.ERROR })
      setBackDrop(false);
      setCreditErrorCount(0);
      setOrdersList([]);
      setSearchOrdersList([]);
      setInternalRetailersCreditData(internalCreditData);
    }
  }


  const fetchSuperkreditOrders = () => {
    setBackDrop(true);

    let formattedDeliveryDate = formatDate(new Date(deliveryDate));
    let year = deliveryDate.getFullYear();
    let month = 1+deliveryDate.getMonth();
    let date = deliveryDate.getDate();
    let finaldate = `${date}-${month}-${year}`;

    if(formatDate(new Date(initialDate))==formattedDeliveryDate){
      Promise.all([
        fire.database().ref("Superzop_Kredit_Internal").once('value'),
        fire.database().ref(`SuperKredit_Updates/${year}/${month}/${finaldate}`).once('value'),
        fire.database().ref(`Driver_Allocation_History/${year}/${month}/${finaldate}`).orderByKey().limitToLast(1).once('value')
      ]).then(([ internalcreditsnap, superkreditorders_snap, kreditFlagSnap ]) => {

        let internalCreditData=internalcreditsnap.val() || {};
        let getOrderHistory = localStorage.getItem("orderHistory")?JSON.parse(localStorage.getItem("orderHistory")):{};
        let updatedSuperkreditOrdersObj = superkreditorders_snap.val() || {};
        let driverLastRoute = kreditFlagSnap.val() || {};
        let consolidatedFlag = false;

        Object.keys(driverLastRoute).forEach(item=>{
          if(driverLastRoute[item].hasOwnProperty("superkredit_flag")){
            setSuperkreditFlag(driverLastRoute[item].superkredit_flag)
          }

          if(driverLastRoute[item].hasOwnProperty("completed_conslidation")){
            consolidatedFlag = driverLastRoute[item].completed_conslidation;
          }
        })
        // if(!consolidatedFlag){
        //   toast(`Order consolidation not completed!`, { type: toast.TYPE.ERROR })
        //   setBackDrop(false);
        //   return;
        // }

        generateSuperkreditOrders(internalCreditData,getOrderHistory,updatedSuperkreditOrdersObj,formattedDeliveryDate,year,month,date,finaldate);
      })

    }else{
      Promise.all([
        fire.database().ref("Superzop_Kredit_Internal").once('value'),
        fire.database().ref(`SuperKredit_Updates/${year}/${month}/${finaldate}`).once('value'),
        fire.database().ref(`Driver_Orders_History/${year}/${month}/${finaldate}`).once('value'),
        fire.database().ref(`Driver_Allocation_History/${year}/${month}/${finaldate}`).orderByKey().limitToLast(1).once('value')
      ]).then(([ internalcreditsnap, superkreditorders_snap, dohsnapshot, kreditFlagSnap ]) => {

        let internalCreditData=internalcreditsnap.val() || {};
        let getOrderHistory = dohsnapshot.val() || {};
        let updatedSuperkreditOrdersObj = superkreditorders_snap.val() || {};
        let driverLastRoute = kreditFlagSnap.val() || {};
        let consolidatedFlag = false;

        Object.keys(driverLastRoute).forEach(item=>{
          if(driverLastRoute[item].hasOwnProperty("superkredit_flag")){
            setSuperkreditFlag(driverLastRoute[item].superkredit_flag)
          }

          if(driverLastRoute[item].hasOwnProperty("completed_conslidation")){
            consolidatedFlag = driverLastRoute[item].completed_conslidation;
          }
        })

        // if(!consolidatedFlag){
        //   toast(`Order consolidation not completed!`, { type: toast.TYPE.ERROR })
        //   setBackDrop(false);
        //   return;
        // }

        generateSuperkreditOrders(internalCreditData,getOrderHistory,updatedSuperkreditOrdersObj,formattedDeliveryDate,year,month,date,finaldate);
      })

    }

  }

  const cleanNumber = (num) => {
    return isNaN(Number(num))?0:Number(num);
  }

  const KreditOrdersTableHeader = () => {
    return (
        <thead className="thead-light">
            <tr>
              <th>OrderNumber</th>
              <th>Route</th>
              <th>Status</th>
              <th>Credit Type</th>
              <th>Credit Amt</th>
              <th>Order Amt</th>
              <th>Delivered Amt</th>
              <th>Credit Diff</th>
              <th>Phone</th>
              <th>Payment Mode</th>
              <th>Available Limit</th>
              <th>Updated Available Limit</th>
              <th>Approved Limit</th>
              {getCreditErrorCount>0 && <th>Error</th>}
              <th> X </th>
            </tr>
        </thead>
    );
  }

  const KreditOrdersTableBody = () => {
    let rowCount = 0;
    let rows = ordersList.map((ordersObj) => {
        return (
            <tr key={ordersObj.order_number} style={{backgroundColor:ordersObj.error_msg?"#ff3333":"white"}}>
                <td>{ordersObj.order_number}</td>
                <td>{ordersObj.route}</td>
                <td>{ordersObj.status}</td>
                <td>{ordersObj.credit_type}</td>
                <td>{ordersObj.credit_amt}</td>
                <td>{ordersObj.ordered_amt}</td>
                <td>{ordersObj.actual_delivered_amt}</td>
                <td>{ordersObj.payment_diff}</td>
                <td>{ordersObj.phone}</td>
                <td>{ordersObj.payment_mode}</td>
                <td>{Number(ordersObj.available_credit).toFixed(2)}</td>
                <td>{ordersObj.updated_available_credit}</td>
                <td>{ordersObj.approved_credit}</td>
                {getCreditErrorCount>0 && <td>{ordersObj.error_msg}</td>}
                {!ordersObj.is_already_paid && !ordersObj.error_msg && exportErrorMsg=="" && isOrderUpdateAllowed?(<td><Button onClick={()=>{ updateOrderSuperKreditValue(ordersObj, rowCount); }}>Update</Button></td>):(!ordersObj.is_already_paid?(validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email)) && ordersObj.new_available_credit?(<td><Button onClick={()=>{ updateApprovedSuperKreditLimit(ordersObj); }}>Update Available Credit</Button></td>):(<td></td>)):(<td><FontAwesomeIcon icon={faCheck} style={{"font-size": "30px","vertical-align": "middle",color: "green"}} /></td>))}
            </tr>
        );

        rowCount += 1;
    });

    return (<><tbody>{rows}</tbody></>);
  }

  const exportSuperkreditOrders = () => {
      const wb = XLSX.utils.book_new()

      let superkreditOrderSummary = [["OrderNumber", "Route", "Status", "Credit Type", "Credit Amt","Order Amt", "Delivered Amt", "Credit Diff","Phone","Available Limit","Updated Available Limit","Approved Limit","Error","Error Available Credit"]]

      ordersList.map((ordersObj) => {
        let superkreditOrder = [];
        superkreditOrder.push(ordersObj.order_number)
        superkreditOrder.push(ordersObj.route)
        superkreditOrder.push(ordersObj.status)
        superkreditOrder.push(ordersObj.credit_type)
        superkreditOrder.push(ordersObj.credit_amt)
        superkreditOrder.push(ordersObj.ordered_amt)
        superkreditOrder.push(ordersObj.delivered_amt)
        superkreditOrder.push(ordersObj.payment_diff)
        superkreditOrder.push(ordersObj.phone)
        superkreditOrder.push(ordersObj.available_credit)
        superkreditOrder.push(ordersObj.updated_available_credit)
        superkreditOrder.push(ordersObj.approved_credit)
        superkreditOrder.push(ordersObj.error_msg)
        superkreditOrder.push(ordersObj.new_available_credit)
        superkreditOrderSummary.push(superkreditOrder)
      });

      const superkreditExportDetails = XLSX.utils.aoa_to_sheet(superkreditOrderSummary)
      let wscols = [
          {wch:15},
          {wch:15},
          {wch:15},
          {wch:15},
          {wch:15},
          {wch:15},
          {wch:15},
          {wch:15},
          {wch:15},
          {wch:15},
          {wch:15},
          {wch:15},
          {wch:30},
          {wch:15}
      ];

      superkreditExportDetails['!cols'] = wscols;
      XLSX.utils.book_append_sheet(wb, superkreditExportDetails, `Superkredit Orders`)
      XLSX.writeFile(wb, `SuperKredit_Orders.xlsx`)
      setExportData(false)
  }

  const updateApprovedSuperKreditLimit = (orderDetails) => {
    console.log(orderDetails);

    let new_available_credit = Number(orderDetails.new_available_credit) || 0;
    let retailer_id = orderDetails.retailer_id;

    fire.database().ref(`Superzop_Kredit_Internal/${retailer_id}/approved_details`).update({"available_credit":new_available_credit}).then(() => {
      toast(`Successfully updated retailer available credit limit`, { type: toast.TYPE.SUCCESS })

      setTimeout(function(){
         sessionStorage.setItem("superkreditDeliveryDate",deliveryDate)
         window.location.reload();
      }, 3000);
    }).catch((err) => {
      toast(`Error while updating available credit : ${err}`, { type: toast.TYPE.ERROR })
    })
  }

  const updateOrderSuperKreditValue = (orderDetails, rowIndex) => {
    console.log(orderDetails);

    let kreditHistoryObj={};
    let phone=orderDetails.phone;
    let orderKey=orderDetails.orderKey;
    let retailer_id=orderDetails.retailer_id;
    let orderNumber=orderDetails.order_number;
    let orderStatus=orderDetails.status;
    let payment_mode=orderDetails.payment_mode;

    if(orderStatus!="Cancelled"){
      let deliveryyDate=orderDetails.delivery_date;
      console.log(deliveryyDate);
      let deliveryDateList=deliveryyDate.split("-");
      let formattedDate=`${deliveryDateList[2]}-${deliveryDateList[1]}-${deliveryDateList[0]}`;
      kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/delivered_on`]=formattedDate;
      kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/delivered_date`]=Date.parse(deliveryyDate);

      let repayable_date = new Date(deliveryyDate)
      repayable_date.setDate(repayable_date.getDate() + 12)
      kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/repayable_date`]=Date.parse(repayable_date);

      kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/status`]="Delivered";
      kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/delivered_value`]=parseFloat(orderDetails.delivered_amt);

      if(payment_mode.includes("internalsuperkredit")){
        kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/cheque_number`]=orderDetails.cheque_number;
        kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/cheque_date`]=orderDetails.cheque_date;
        kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/cheque_amount`]=orderDetails.cheque_amt;

        if(orderDetails.cheque_amt2){
          kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/cheque_amount2`]=orderDetails.cheque_amt2;
          kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/cheque_number2`]=orderDetails.cheque_number2;
          kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/cheque_date2`]=orderDetails.cheque_date2;
        }
      }

      if(orderDetails['case_type']=="Other Payments"){
        kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/repaid_status`]="C";
      }else{
        kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/repaid_status`]="N";
      }

    }else{
      kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/status`]="Cancelled";
      kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/repaid_status`]="C";
    }


    kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/updated_at`] = UpdatedDate()
    kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/updated_by`] = JSON.parse(sessionStorage.getItem("user"))[0].email || "";;



    let superkreditOrderPromisesList = [];
    superkreditOrderPromisesList.push(fire.database().ref("Kredit_History").update(kreditHistoryObj));

    let updatedAvailableCredit = Number(orderDetails.payment_diff);

    if(updatedAvailableCredit>0){
      superkreditOrderPromisesList.push(fire.database().ref(`Superzop_Kredit_Internal/${retailer_id}/approved_details`).update({"available_credit":updatedAvailableCredit}) );
    }

    let finaldate = orderDetails.finaldate;
    let year = finaldate.split("-")[2];
    let month = finaldate.split("-")[1];

    let superkreditUpdateObj = {};
    superkreditUpdateObj["available_credit"] = cleanNumber(orderDetails.available_credit);
    if(orderDetails.cheque_amt){
      superkreditUpdateObj["cheque_amt"] = Number(orderDetails.cheque_amt);
    }
    if(orderDetails.cheque_number){
      superkreditUpdateObj["cheque_number"] = orderDetails.cheque_number;
    }

    superkreditUpdateObj["delivered_amt"] = cleanNumber(orderDetails.delivered_amt);
    superkreditUpdateObj["payment_mode"] = orderDetails.payment_mode || "";
    superkreditUpdateObj["actual_delivered_amt"] = cleanNumber(orderDetails.actual_delivered_amt);
    superkreditUpdateObj["diff_amt"] = cleanNumber(orderDetails.payment_diff);
    superkreditUpdateObj["order_amt"] = cleanNumber(orderDetails.ordered_amt);
    superkreditUpdateObj["order_number"] =orderDetails.order_number;
    superkreditUpdateObj["phone"] = cleanNumber(orderDetails.phone);
    superkreditUpdateObj["retailer_id"] = cleanNumber(orderDetails.retailer_id);
    superkreditUpdateObj["updated_available_credit"] = cleanNumber(orderDetails.updated_available_credit);
    superkreditUpdateObj["superkredit_flag"] = true;
    superkreditUpdateObj["status"] = orderDetails.status || "";
    let new_available_credit = Number(orderDetails.new_available_credit) || 0;
    if(new_available_credit) {
      superkreditUpdateObj["error_available_credit"] = new_available_credit;
    }

    superkreditOrderPromisesList.push(fire.database().ref(`SuperKredit_Updates/${year}/${month}/${finaldate}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}`).update(superkreditUpdateObj));

    Promise.all(superkreditOrderPromisesList).then(()=>{
      toast(`Successfully updated superkredit`, { type: toast.TYPE.SUCCESS })

      let updatedOrdersList = [...ordersList];
      updatedOrdersList[rowIndex]['is_already_paid'] = true;
      setOrdersList(updatedOrdersList)
    }).catch((err)=>{
      toast(`Error in updating superkredit : ${err}`, { type: toast.TYPE.ERROR })
    })
  }

  const bulkUpdateSuperKreditValue = () => {

    let kreditHistoryObj={};
    let superkreditUpdateObj = {};
    let internalKreditRetailersObj={};
    let multipleRetailerOrderSuperkreditObj={};

    for(let i=0;i<ordersList.length;i++){
      let orderDetails = ordersList[i];

      let phone=orderDetails.phone;
      let orderKey=orderDetails.orderKey;
      let retailer_id=orderDetails.retailer_id;
      let orderNumber=orderDetails.order_number;
      let orderStatus=orderDetails.status;
      let payment_mode=orderDetails.payment_mode;

      if(!orderDetails.is_already_paid && !orderDetails.error_msg){
        if(orderStatus!="Cancelled"){
          let deliveryyDate=orderDetails.delivery_date;

          let deliveryDateList=deliveryyDate.split("-");
          let formattedDate=`${deliveryDateList[2]}-${deliveryDateList[1]}-${deliveryDateList[0]}`;
          kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/delivered_on`]=formattedDate;
          kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/delivered_date`]=Date.parse(deliveryyDate);

          let repayable_date = new Date(deliveryyDate)
          repayable_date.setDate(repayable_date.getDate() + 12)
          kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/repayable_date`]=Date.parse(repayable_date);

          kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/status`]="Delivered";
          kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/delivered_value`]=parseFloat(orderDetails.delivered_amt);

          if(payment_mode.includes("internalsuperkredit")){
            kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/cheque_number`]=orderDetails.cheque_number;
            kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/cheque_date`]=orderDetails.cheque_date;
            kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/cheque_amount`]=orderDetails.cheque_amt;

            if(orderDetails.cheque_amt2){
              kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/cheque_amount2`]=orderDetails.cheque_amt2;
              kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/cheque_number2`]=orderDetails.cheque_number2;
              kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/cheque_date2`]=orderDetails.cheque_date2;
            }
          }

          if(orderDetails['case_type']=="Other Payments"){
            kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/repaid_status`]="C";
          }else{
            kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/repaid_status`]="N";
          }

        }else{
          kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/status`]="Cancelled";
          kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/repaid_status`]="C";
        }


        kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/updated_at`] = UpdatedDate()
        kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/updated_by`] = JSON.parse(sessionStorage.getItem("user"))[0].email || "";;


        let updatedAvailableCredit = Number(orderDetails.payment_diff);

        if(!multipleRetailerOrderSuperkreditObj[retailer_id]){
          multipleRetailerOrderSuperkreditObj[retailer_id]=updatedAvailableCredit;
        }else{
          let prevRetailerOrderCredit=multipleRetailerOrderSuperkreditObj[retailer_id]?Number(multipleRetailerOrderSuperkreditObj[retailer_id]):0;
          updatedAvailableCredit=prevRetailerOrderCredit+updatedAvailableCredit;
          multipleRetailerOrderSuperkreditObj[retailer_id]=updatedAvailableCredit;
        }

        updatedAvailableCredit=updatedAvailableCredit.toFixed(2);

        let existingRetailerSuperkreditAmount = Number(orderDetails.available_credit) || 0;

        let finalRetailerAvailableCredit=existingRetailerSuperkreditAmount+Number(updatedAvailableCredit);

        if(finalRetailerAvailableCredit>0){
          finalRetailerAvailableCredit=finalRetailerAvailableCredit.toFixed(2);
          internalKreditRetailersObj[`/${retailer_id}/approved_details/available_credit`]=Number(finalRetailerAvailableCredit);
        }

        superkreditUpdateObj[`/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/available_credit`] = cleanNumber(orderDetails.available_credit);
        if(orderDetails.cheque_amt){
          superkreditUpdateObj[`/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/cheque_amt`] = Number(orderDetails.cheque_amt);
        }
        if(orderDetails.cheque_number){
          superkreditUpdateObj[`/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/cheque_number`] = orderDetails.cheque_number;
        }

        superkreditUpdateObj[`/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/delivered_amt`] = cleanNumber(orderDetails.delivered_amt);
        superkreditUpdateObj[`/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/payment_mode`] = orderDetails.payment_mode || "";
        superkreditUpdateObj[`/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/actual_delivered_amt`] = cleanNumber(orderDetails.actual_delivered_amt);
        superkreditUpdateObj[`/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/diff_amt`] = cleanNumber(orderDetails.payment_diff);
        superkreditUpdateObj[`/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/order_amt`] = cleanNumber(orderDetails.ordered_amt);
        superkreditUpdateObj[`/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/order_number`] =orderDetails.order_number;
        superkreditUpdateObj[`/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/phone`] = cleanNumber(orderDetails.phone);
        superkreditUpdateObj[`/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/retailer_id`] = cleanNumber(orderDetails.retailer_id);
        superkreditUpdateObj[`/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/updated_available_credit`] = cleanNumber(orderDetails.updated_available_credit);
        superkreditUpdateObj[`/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/superkredit_flag`] = true;
        superkreditUpdateObj[`/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/status`] = orderDetails.status || "";
        let new_available_credit = Number(orderDetails.new_available_credit) || 0;
        if(new_available_credit) {
          superkreditUpdateObj[`/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/error_available_credit`] = new_available_credit;
        }
      }
    }

    let finaldate = ordersList[0].finaldate;
    let year = finaldate.split("-")[2];
    let month = finaldate.split("-")[1];

    let superkreditOrderPromisesList = [];
    if(Object.keys(kreditHistoryObj).length>0){
      superkreditOrderPromisesList.push(fire.database().ref("Kredit_History").update(kreditHistoryObj));
      superkreditOrderPromisesList.push(fire.database().ref(`SuperKredit_Updates/${year}/${month}/${finaldate}`).update(superkreditUpdateObj));
    }

    if(Object.keys(internalKreditRetailersObj).length>0){
      superkreditOrderPromisesList.push(fire.database().ref("Superzop_Kredit_Internal").update(internalKreditRetailersObj));
    }

    if(superkreditOrderPromisesList.length>0){
      Promise.all(superkreditOrderPromisesList).then(()=>{

        let enddeliveryele={};
        fire.database().ref(`Driver_Allocation_History/${year}/${month}/${finaldate}`).once('value', (snapshot) =>{
          if(snapshot.hasChildren()){
            let driversData=snapshot.val();

            Object.keys(driversData).map((key,value)=>{
              enddeliveryele[`/${key}/superkredit_flag`]=true;
              enddeliveryele[`/${key}/superkredit_flag_updatedAt`]=new Date().getTime();
            });

            if(Object.keys(enddeliveryele).length>0){
              fire.database().ref(`Driver_Allocation_History/${year}/${month}/${finaldate}`).update(enddeliveryele).then(()=>{
                toast(`Successfully updated superkredit orders`, { type: toast.TYPE.SUCCESS })

                setTimeout(function(){
                   sessionStorage.setItem("superkreditDeliveryDate",deliveryDate)
                   window.location.reload();
                }, 3000);
              })
            }
          }else{
            toast(`Successfully updated superkredit orders`, { type: toast.TYPE.SUCCESS })

            setTimeout(function(){
               sessionStorage.setItem("superkreditDeliveryDate",deliveryDate)
               window.location.reload();
            }, 3000);
          }
        });
      }).catch((err)=>{
        setIsOrderUpdateAllowed(true);
        toast(`Error in updating superkredit orders : ${err}`, { type: toast.TYPE.ERROR })
      })
    }else{
      toast(`No superkredit orders to update`, { type: toast.TYPE.ERROR })
    }

  }

  const handleDeliveryDateChange = (selecteddate) => {
    setDeliveryDate(new Date(selecteddate));
  }

  const CustomDateInput = ({ value, onClick }) => (
    <input
        value={value}
        onClick={onClick}
        style={{ border: "solid 1px black", height: "30px" }}
    />
  );



    return (<>
      <NavBar/>
      <div className="container col-sm-12" style={{paddingTop:100}}>
        <center><h2 className="h2">SuperKredit Orders</h2></center>
        <Form inline style={{margin:"20px", width:'90%', marginLeft: "auto",marginRight: "auto", paddingTop:10}}>
            <InputGroup size="sm" style = {{ margin : 5}}>
              <InputGroupAddon addonType="prepend">Delivery Date</InputGroupAddon>
                    <DatePicker
                        selected={deliveryDate}
                        onChange={(handleDeliveryDateChange)}
                        minDate={new Date("2017/01/01")}
                        aria-describedby="basic-addon1"
                        dateFormat="dd/MM/yyyy"
                        customInput={<CustomDateInput />}
                    />
            </InputGroup>
        </Form>

        <InputGroup size="sm" style={{ margin: "20px", marginLeft: "auto",marginRight: "auto" }} >
          <InputGroupAddon addonType="prepend">Status</InputGroupAddon>
          <div style={{width: '200px'}}><Select options={[{"value":"All","label":"All"},{"value":"Delivered","label":"Delivered"},{"value":"Partially-Delivered","label":"Partially-Delivered"},{"value":"Cancelled","label":"Cancelled"}]} placeholder="All" onChange={(val)=>{ setStatusText(val.value); }}/></div>
        </InputGroup>

        {(allowedRoutesSet.has(`/superkredit-reports`) || validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email))) && <Button variant={"primary"} onClick={() => { window.open(`/superkredit-reports`, "_blank"); }}>Reports</Button>}
        {validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email)) && <Button style={{marginLeft:"15px"}} variant={"warning"} onClick={() => setExportData(true)}>Export</Button>}

        {exportErrorMsg!="" && <div style={{ display: "flex", backgroundColor: "#FF6347", padding: "10px", margin: "15px", borderRadius: "5px" }}>{exportErrorMsg}</div>}

        {(ordersList.length>0 && exportErrorMsg=="" && getCreditErrorCount==0)?(isAllOrdersSuperkreditUpdated?(<Button style={{marginLeft: "20px"}} disabled>All Done</Button>):(<Button style={{marginLeft: "20px"}} onClick={()=>{ bulkUpdateSuperKreditValue(); }}>Bulk Update</Button>)):null}

        {ordersList.length>0?(<table className="table table-striped" style={{marginTop: "10px", textAlign:"center"}}>
            <KreditOrdersTableHeader />
            <KreditOrdersTableBody />
        </table>):(<center><div>No superkredit orders</div></center>)}


        {backDrop?(<Backdrop parentLoadStatus={backDrop}/>):(null)}
      </div></>
    )
}

export default SuperKreditOrders
