import React, { useState, useEffect } from 'react';
import { Form, InputGroup } from 'react-bootstrap';

function ReturnOrdersSearch({filterOrders,searchOrders,originalOrdersList}) {

  const [searchText, setSearchText] = useState("")



  let filterResult = ""

  const searchOrdersListing = () => {
  
    filterResult = searchOrders.filter(obj => {

        const orderNo = obj.order_number;
        const retailerId = obj.retailer_id.toString() ;
        const shopName = obj.shop_name && obj.shop_name.trim().toLowerCase() ;
        const shopPhoneNo = obj.user_name ;

        setSearchText(searchText.trim().toLowerCase());
        return orderNo.match(searchText) || retailerId.match(searchText) || shopName.match(searchText)  || shopPhoneNo.match(searchText)
    })
    filterOrders(filterResult)

}
useEffect(() => {
    if (searchText!=="")
    searchOrdersListing()
}, [searchText])

  return (
    <div>
      {(searchOrders && searchOrders.length > 0 )? <InputGroup style={{ width: "40%",marginLeft: "auto", marginRight: "auto", marginTop:15 }}>
            <InputGroup.Prepend>
              <InputGroup.Text>Search</InputGroup.Text>
            </InputGroup.Prepend>
        <Form.Control id="searchProduct"  className="searchBox" placeholder="Order No./Retailer Id/Shop Name/Shop Phone No" value={searchText} onChange={(e) => { setSearchText(e.target.value); if (e.target.value === "") {setSearchText("");filterOrders(originalOrdersList) }}} />
        </InputGroup>:""}
    </div>
  )
}

export default ReturnOrdersSearch
