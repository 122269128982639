import React, { useState, useEffect, useRef } from 'react'
import { Table, Badge,Navbar,Form,Button,Dropdown,DropdownButton,InputGroup,Row,Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import szlogo from '../../assests/images/sz_logo.png';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import ReactToPrint from 'react-to-print';
import Backdrop from '../backdrop/backdrop';
import AddItemModal from '../OrdersListing/AddNewItemModal';
import { IconButton } from '@material-ui/core';
import NavBar from '../Navigation bar/Navbar'
import Select from 'react-select';
import { validateIsAdminUser,validateIsOperationUser } from '../UserCredentials'
import fire from '../../Configs/firebase';

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hour=''+d.getHours(),
        minute=''+d.getMinutes(),
        second=''+d.getSeconds();

    if (month.length < 2){
      month = '0' + month;
    }
    if (day.length < 2){
      day = '0' + day;
    }
    if (hour.length < 2){
      hour = '0' + hour;
    }
    if (minute.length < 2){
      minute = '0' + minute;
    }
    if (second.length < 2){
      second = '0' + second;
    }

    let currdate=[day, month, year].join('/');
    let time = hour + ":" + minute + ":" + second;
    let curdateTime = currdate+' '+time;

    return curdateTime;
}

function formatOrderDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear()

    if (month.length < 2){
      month = '0' + month;
    }
    if (day.length < 2){
      day = '0' + day;
    }

    let currdate=[year,month,day].join('-');

    return currdate;
}

Date.prototype.toShortFormat = function() {

    let monthNames =["Jan","Feb","Mar","Apr",
                      "May","Jun","Jul","Aug",
                      "Sep", "Oct","Nov","Dec"];

    let day = this.getDate();

    let monthIndex = this.getMonth();
    let monthName = monthNames[monthIndex];

    let year = ""+this.getFullYear();
    let lasttwoYearDigit = year.substr(year.length - 2);

    return `${day} ${monthName} ${lasttwoYearDigit}`;
}

const AddReturnOrAdjustmentOrder = () => {

    const [backDrop,setBackDrop] = useState(false);
    const [orderData,setOrderData] = useState([]);
    const [backupOrderData,setBackupOrderData] = useState([]);
    const [searchOrderData,setSearchOrderData] = useState([]);
    const [itemChecked,setItemChecked] = useState({});
    const [ordrStatus,setOrdrStatus]=useState("");

    const [initialOrderSelection,setInitialOrderSelection]=useState(true);

    //radio button state
    const [orderOption,setOrderOption]=useState("");
    const [orderSelectedItemSet,setOrderSelectedItemSet]=useState({});
    const [addedOrderChangedStatus,setAddedOrderChangedStatus]=useState("");
    const [reasonDropdownDisable,setReasonDropdownDisable]=useState(false);

    //add order state
    const [orderNumber,setOrderNumber] = useState("");
    const [returnOrderType,setReturnOrderType] = useState("SELECT");
    const [returnReason,setReturnReason]=useState("SELECT");
    const [customerReturnedItems,setCustomerReturnedItems]=useState("SELECT");
    const [returnItem,setReturnItem]=useState("SELECT");
    const [customerDeductedMoney,setCustomerDeductedMoney]=useState("SELECT");

    //schedule for delivery state
    let vehhroute=sessionStorage.getItem("routeIdToPrint")?sessionStorage.getItem("routeIdToPrint"):"SELECT";
    const [routeVehicleId,setRouteVehicleId] = useState(vehhroute);
    const [vehiclePriority,setVehiclePriority] = useState();
    let currdate=formatOrderDate(new Date());
    const [deliveryDate,setDeliveryDate] = useState(currdate);
    const [returnDate,setReturnDate]=useState(currdate);
    const [deliveryOrderNumber,setDeliveryOrderNumber] = useState("");
    const [deliveryOrderData,setDeliveryOrderData] = useState([]);
    const [backupDeliveryOrderData,setBackupDeliveryOrderData] = useState([]);

    //item input focus state
    const [itemFocusObj,setItemFocusObj] = useState({});

    //new states
    const [orderType,setOrderType] = useState("SELECT");
    const [actualOrderType,setActualOrderType] = useState("SELECT");
    const [validRouteList,setValidRouteList] = useState([]);
    const [customerAdjustedPayment,setCustomerAdjustedPayment]=useState("SELECT");

    const checkRef = useRef();

    useEffect(()=>{
      //valid routes list
      let currdate=new Date();
      let day=currdate.getDate();
      let month=currdate.getMonth()+1;
      let year=currdate.getFullYear();
      let date=day+"-"+month+'-'+year;

      let validRoute=[];

      fire.database().ref(`Driver_Orders_History/${year}/${month}/${date}/`).once('value',snapshot=>{
        if(snapshot.hasChildren()){
          let vehData = snapshot.val();
          Object.keys(vehData).map((key,value)=>{
            if(key!="9999"){
              validRoute.push(key);
            }
          });
          setValidRouteList(validRoute);
        }else{
          setValidRouteList([]);
        }
      });
    },[])

    useEffect(() => {
      if(routeVehicleId && deliveryDate){
        let currdate=new Date(deliveryDate);
        let day=currdate.getDate();
        let month=currdate.getMonth()+1;
        let year=currdate.getFullYear();
        let date=day+"-"+month+'-'+year;
        let maxVehiclePriority=-1;

        fire.database().ref(`Driver_Orders_History/${year}/${month}/${date}/${routeVehicleId}/`).once('value',snapshot=>{
          if(snapshot.hasChildren()){
            let vehData = snapshot.val();
            Object.keys(vehData).map((key,value)=>{
              let priority=Number(vehData[key]['priority']);
              if(priority>maxVehiclePriority){
                maxVehiclePriority=priority;
              }
            });
            let driverOrdersPriority=maxVehiclePriority+1;
            setVehiclePriority(driverOrdersPriority);
          }else{
            maxVehiclePriority=1;
            setVehiclePriority(maxVehiclePriority);
          }
        });

        let validRoute=[];

        fire.database().ref(`Driver_Orders_History/${year}/${month}/${date}/`).once('value',snapshot=>{
          if(snapshot.hasChildren()){
            let vehData = snapshot.val();
            Object.keys(vehData).map((key,value)=>{
              if(key!="9999"){
                validRoute.push(key);
              }
            });
            setValidRouteList(validRoute);
          }else{
            setValidRouteList([]);
          }
        });
      }
    },[deliveryDate,routeVehicleId])

    useEffect(()=>{
      if(returnItem!="SELECT"){
        if(returnItem=="Yes"){
          setCustomerDeductedMoney("SELECT");
          if(ordrStatus=="Delivered"){
            setReturnOrderType("Yes");
            setReasonDropdownDisable(true);
          }
        }else if(returnItem=="No"){
          setReturnOrderType("SELECT");
        }
      }
    },[returnItem])

    const viewRetailerOrders = () => {
        console.log('viewRetailerOrders');
        console.log(orderNumber);
        setBackDrop(true);
        setOrderData([]);
        setBackupOrderData([]);
        setSearchOrderData([]);
        let retailerOrdersArray=[];
        if(orderNumber.includes('/')==true){
          fetch(`http://support.superzop.com/api/ordernumbersearch?ordernumber=${orderNumber}`).then(res => {
              return res.json()
          }).then(data => {
             let orderdatalist=data.data;
             console.log(orderdatalist);
             if(orderdatalist=="No rows found"){
               setItemChecked({});
               setOrderData([]);
               setBackupOrderData([]);
               setSearchOrderData([]);
               setBackDrop(false);
               alert('Order number not found');
             }else{
               let orderstatus=orderdatalist["status"];
               let orderitemslist=orderdatalist["items"];

               console.log(orderitemslist);
               if(orderstatus.includes("Delivered") || orderstatus.includes("Processed") || orderstatus.includes("Shipped")){
                 for(let i=0;i<orderitemslist.length;i++){
                   let order_qty=orderitemslist[i]['order_qty'];
                   let item_delivered_qty=orderitemslist[i]['item_delivered_qty'];
                   order_qty=Number(order_qty);
                   item_delivered_qty=Number(item_delivered_qty);
                   let ele=orderitemslist[i];
                   ele['address1']=orderdatalist['address1'];
                   ele['address2']=orderdatalist['address2'];
                   ele['agent_id']=orderdatalist['agent_id'];
                   ele['area']=orderdatalist['area'];
                   ele['bagdetails']=orderdatalist['bagdetails']?orderdatalist['bagdetails']:"";
                   ele['card_amt']=orderdatalist['card_amt'];
                   ele['cash_amt']=orderdatalist['cash_amt'];

                   ele['cheque_date']=orderdatalist['cheque_date'];
                   ele['coupon_code']=orderdatalist['coupon_code'];
                   ele['coupon_code_item_level']=orderdatalist['coupon_code_item_level'];
                   ele['coupon_code_order_level']=orderdatalist['coupon_code_order_level'];
                   ele['delivery_date']=orderdatalist['delivery_date'];
                   ele['delivery_date_conv']=orderdatalist['delivery_date_conv'];
                   ele['distributor_id']=orderdatalist['distributor_id'];
                   ele['ean']=orderdatalist['ean'];
                   ele['exp_delivery_date']=orderdatalist['exp_delivery_date'];
                   ele['item_status']=orderdatalist['item_status'];
                   ele['market_price']=orderdatalist['market_price'];
                   ele['mrp']=orderdatalist['mrp'];
                   ele['mtd_exclude']=orderdatalist['mtd_exclude'];
                   ele['offer_price']=orderdatalist['offer_price'];
                   ele['offer_disc']=orderdatalist['offer_disc'];
                   ele['paid_date']=orderdatalist['paid_date'];
                   ele['price']=orderdatalist['price'];
                   ele['revised_order_amt']=orderdatalist['revised_order_amt'];
                   ele['scheme_amt']=orderdatalist['scheme_amt'];
                   ele['shipping_charges']=orderdatalist['shipping_charges'];
                   ele['update_status']=orderdatalist['update_status'];

                   ele['cashback_redeemed']=orderdatalist['cashback_redeemed'];
                   ele['city']=orderdatalist['city'];
                   ele['credit_amt']=orderdatalist['credit_amt'];
                   ele['final_order_amt']=orderdatalist['final_order_amt'];
                   ele['fssai_document_number']=orderdatalist['fssai_document_number'];
                   ele['address1']=orderdatalist['address1'];
                   ele['gst_number']=orderdatalist['gst_number'];
                   ele['net_order_amt']=orderdatalist['net_order_amt'];
                   ele['numbags']=orderdatalist['numbags'];
                   ele['order_amt']=orderdatalist['order_amt'];
                   ele['order_date']=formatOrderDate(orderdatalist['order_date']);
                   ele['order_disc']=orderdatalist['order_disc'];
                   ele['order_number']=orderdatalist['order_number'];
                   ele['order_payment_mode']=orderdatalist['order_payment_mode'];
                   ele['paytm_amt']=orderdatalist['paytm_amt'];
                   ele['phone']=orderdatalist['phone'];
                   ele['pincode']=orderdatalist['pincode'];
                   ele['processed_date']=orderdatalist['processed_date'];
                   ele['retailer_id']=orderdatalist['retailer_id'];
                   ele['shop_name']=orderdatalist['shop_name'];
                   ele['status']=orderdatalist['status'];
                   retailerOrdersArray.push(ele);
                 }
                 console.log(retailerOrdersArray);
                 setItemChecked({});
                 setBackupOrderData(retailerOrdersArray);
                 setSearchOrderData(retailerOrdersArray);
                 setOrderData(retailerOrdersArray);
                 setOrdrStatus(orderstatus);
                 setBackDrop(false);
                 if(orderstatus=="Delivered"){
                   setReturnOrderType("Yes");
                   setReasonDropdownDisable(true);
                 }else{
                   setReturnOrderType("SELECT");
                   setReasonDropdownDisable(false);
                 }
               }else{
                 setItemChecked({});
                 setOrderData([]);
                 setBackupOrderData([]);
                 setSearchOrderData([]);
                 setBackDrop(false);

                 alert(`Return Order can be only for Delivered Orders. ${orderNumber} - Status: ${orderstatus}.`)
               }

             }
          }).catch(err => {
            setItemChecked({});
            setOrderData([]);
            setBackupOrderData([]);
            setSearchOrderData([]);
            setBackDrop(false);
            alert(err);
          })
        }else{
          setItemChecked({});
          setOrderData([]);
          setBackupOrderData([]);
          setSearchOrderData([]);
          setBackDrop(false);
          alert('Please enter valid order number');
        }
    }

    const viewRetailerOrdersForDelivery = () => {
        console.log('viewRetailerOrdersForDelivery');
        console.log(deliveryOrderNumber);
        setBackDrop(true);
        let retailerOrdersArray=[];
        if(deliveryOrderNumber.includes('/') && (deliveryOrderNumber.includes('-R') || deliveryOrderNumber.includes('-C')) && !deliveryOrderNumber.includes('-RN')){
          let retailerid=deliveryOrderNumber.split("-")[0];
          let apiUrl=`http://support.superzop.com/api/fetchretailerdata?retailerid=${retailerid}`
          console.log(apiUrl);
          fetch(apiUrl)
          .then((response) => {
              return response.json();
          }).then((result) => {
              let retailerData=result['data'];
              console.log(retailerData);
              fire.database().ref('Orders_New/').once('value',snapshot=>{
                  if(snapshot.hasChildren()){
                      let ordersdata = snapshot.val();
                      console.log(ordersdata);
                      let searchOrdNum=deliveryOrderNumber;
                      let searchOrdNumList=searchOrdNum.split("/");
                      let newOrdNum=searchOrdNumList[0]+"-"+searchOrdNumList[1];
                      console.log(newOrdNum);
                      let finalOrdersList=[];

                      Object.keys(ordersdata).map((key,index)=>{
                        if(key.includes(newOrdNum)){
                          let order_qty=Number(ordersdata[key]['order_qty']);
                          if(order_qty>0){
                            let ele=ordersdata[key];
                            if(deliveryOrderNumber.includes('-R/')){
                              ele['net_order_amt']="0"
                              ele['cash_amt']="0"
                              ele['net_amt']="0"
                              ele['order_amt']="0"
                              ele['final_order_amt']="0"
                              ele['cashback_redeemed']="0";
                              ele['order_disc']="0";
                              ele['total_amt']="0";
                              ele['gst_charge']="0";
                              ele['transport_charge']="0";
                            }

                            if(retailerData){
                              ele['address1']=retailerData['address1']?retailerData['address1']:"";
                              ele['address2']=retailerData['address2']?retailerData['address2']:"";
                              ele['agent_id']=retailerData['agent_id']?retailerData['agent_id']:"";
                              ele['city']=retailerData['city']?retailerData['city']:"";
                              ele['gst_number']=retailerData['gst_number']?retailerData['gst_number']:"";
                              ele['phone']=retailerData['phone']?retailerData['phone']:"";
                              ele['pincode']=retailerData['pincode']?retailerData['pincode']:"";
                              ele['shop_name']=retailerData['shop_name']?retailerData['shop_name']:"";
                              ele['latitude']=retailerData['latitude']?retailerData['latitude']:"";
                              ele['longitude']=retailerData['longitude']?retailerData['longitude']:"";
                              ele['asm']=retailerData['asm']?retailerData['asm']:"";
                              ele['asmphone']=retailerData['asmphone']?retailerData['asmphone']:"";
                              ele['salesman_name']=retailerData['salesman_name']?retailerData['salesman_name']:"";
                              ele['se_phone_number']=retailerData['se_phone_number']?retailerData['se_phone_number']:"";
                            }

                            finalOrdersList.push(ele);
                          }
                        }
                      });
                      console.log(finalOrdersList);
                      setDeliveryOrderData(finalOrdersList);
                      let currdate=formatOrderDate(new Date());
                      console.log(currdate);
                      setDeliveryDate(currdate);
                      setBackDrop(false);

                      if(finalOrdersList.length==0){
                        alert("Order number not present");
                      }
                  }else{
                    setDeliveryOrderData([]);
                    setBackDrop(false);
                  }
              });
          }).catch((error) => {
            alert(error);
            setDeliveryOrderData([]);
            setBackDrop(false);
          });


        }else{
          setDeliveryOrderData([]);
          setBackDrop(false);
          alert('Only -R and -C orders are allowed');
        }
    }

    const DeliveryRetailerOrdersTableHeader = () => {
      return (
          <thead className="thead-light">
              <tr>
                  <th>Item Description</th>
                  <th>Item ID</th>
                  <th>Order Qty</th>
                  <th>Total Amount</th>
              </tr>
          </thead>
      );
    }

    const DeliveryRetailerOrdersTableBody = () => {
      let rows=[];

      if(deliveryOrderData.length>0){
        rows = deliveryOrderData.map((ord, index) => {
          return (
              <tr key={index}>
                  <td>{ord['item_disc']}</td>
                  <td>{ord['item_id']}</td>
                  <td>{ord['order_qty']}</td>
                  <td>{ord['total_amt']}</td>
              </tr>
          );
        });
      }else{
        rows=[];
      }

      return (<><tbody>{rows}</tbody></>);
    }

    const DeliveryRetailerOrdersSummaryTable = () => {
      let finalNetOrderAmount=0;
      let finalOrderAmount=0;
      let finalGstAmount=0;
      let finalTransportAmount=0;

      for(let k=0;k<deliveryOrderData.length;k++){
        let item_id=""+deliveryOrderData[k]['item_id'];
        let order_qty=Number(deliveryOrderData[k]['order_qty']);
        let total_amt=Number(deliveryOrderData[k]['total_amt']);
        let gst_charge=Number(deliveryOrderData[k]['gst_charge']);
        let transport_charge=Number(deliveryOrderData[k]['transport_charge']);
        finalNetOrderAmount=finalNetOrderAmount+total_amt;
        finalGstAmount=finalGstAmount+gst_charge;
        finalTransportAmount=finalTransportAmount+transport_charge;
      }
      finalOrderAmount=finalNetOrderAmount-(finalGstAmount+finalTransportAmount);

      return (
        <div className="table-wrapper" style={{marginTop:15,paddingBottom:40}}>
          <table className="table table-striped" style={{width:100,marginLeft:"auto",marginRight:"auto"}}>
            <tbody>
                <tr>
                    <td>OrderValue</td>
                    <td>{finalOrderAmount.toFixed(2)}</td>
                </tr>
                <tr>
                    <td>GST</td>
                    <td>{finalGstAmount.toFixed(2)}</td>
                </tr>
                <tr>
                    <td>Transport</td>
                    <td>{finalTransportAmount.toFixed(2)}</td>
                </tr>
                <tr>
                    <td>NetOrderValue</td>
                    <td>{finalNetOrderAmount.toFixed(2)}</td>
                </tr>
            </tbody>
        </table>
      </div>
      );
    }


    const RetailerOrdersSummaryTable = () => {
      let finalNetOrderAmount=0;
      let finalOrderAmount=0;
      let finalGstAmount=0;
      let finalTransportAmount=0;

      if(customerAdjustedPayment!="Yes" && actualOrderType!="R"){
        let bulkItemChecks = document.getElementsByClassName('bulkItemSelection');

        if(bulkItemChecks.length==0){
          for(let k=0;k<orderData.length;k++){
            let item_id=""+orderData[k]['item_id'];
            let order_qty=Number(orderData[k]['order_qty']);
            let total_amt=Number(orderData[k]['total_amt']);
            let gst_charge=Number(orderData[k]['gst_charge']);
            let transport_charge=Number(orderData[k]['transport_charge']);
            finalNetOrderAmount=finalNetOrderAmount+total_amt;
            finalGstAmount=finalGstAmount+gst_charge;
            finalTransportAmount=finalTransportAmount+transport_charge;
          }
          finalOrderAmount=finalNetOrderAmount-(finalGstAmount+finalTransportAmount);

        }else{
          let selectedItemSet=new Set();
          for (let i=0;i<bulkItemChecks.length;i++) {
            let itemId=bulkItemChecks[i].name;
            let isItemChecked=bulkItemChecks[i].checked;
            if(isItemChecked==true){
              selectedItemSet.add(itemId);
            }
          }
          for(let k=0;k<orderData.length;k++){
            let item_id=""+orderData[k]['item_id'];
            let order_qty=Number(orderData[k]['order_qty']);
            if(selectedItemSet.has(item_id) && order_qty>0){
              let total_amt=Number(orderData[k]['total_amt']);
              let gst_charge=Number(orderData[k]['gst_charge']);
              let transport_charge=Number(orderData[k]['transport_charge']);
              finalNetOrderAmount=finalNetOrderAmount+total_amt;
              finalGstAmount=finalGstAmount+gst_charge;
              finalTransportAmount=finalTransportAmount+transport_charge;
            }
          }
          finalOrderAmount=finalNetOrderAmount-(finalGstAmount+finalTransportAmount);
        }
      }

      return (
        <div className="table-wrapper" style={{marginTop:15,paddingBottom:40}}>
          <table className="table table-striped" style={{width:100,marginLeft:"auto",marginRight:"auto"}}>
            <tbody>
                <tr>
                    <td>OrderValue</td>
                    <td>{finalOrderAmount.toFixed(2)}</td>
                </tr>
                <tr>
                    <td>GST</td>
                    <td>{finalGstAmount.toFixed(2)}</td>
                </tr>
                <tr>
                    <td>Transport</td>
                    <td>{finalTransportAmount.toFixed(2)}</td>
                </tr>
                <tr>
                    <td>NetOrderValue</td>
                    <td>{finalNetOrderAmount.toFixed(2)}</td>
                </tr>
            </tbody>
        </table>
      </div>
      );
    }

    const RetailerOrdersTableHeader = () => {
      let isOrderChecked=initialOrderSelection==true?true:(checkRef.current?checkRef.current.checked:true);
      return (
          <thead className="thead-light">
              <tr>
                  <th><input type="checkbox" id="selectAllCheckbox" defaultChecked={isOrderChecked} ref={checkRef} onChange={() => {
                      let bulkItemChecks = document.getElementsByClassName('bulkItemSelection');
                      let itemObj={};
                      for (let i = 0; i < bulkItemChecks.length; i++) {
                        let itemId=bulkItemChecks[i].name;
                        if(checkRef.current.checked){
                          itemObj[`${itemId}`]=true;
                          bulkItemChecks[i].checked = true;
                        }else{
                          itemObj[`${itemId}`]=false;
                          bulkItemChecks[i].checked = false;
                        }
                      }
                      console.log(itemObj);
                      setInitialOrderSelection(false);
                      setItemChecked(itemObj);
                  }} /></th>
                  <th>Item Description</th>
                  <th>Item ID</th>
                  <th>Qty</th>
                  <th>Total Amount</th>
              </tr>
          </thead>
      );
    }

    const updateItemBasedOnQuantity = (rowNum,updatedQuantity,originalQuantity,itemID) => {
      updatedQuantity=Number(updatedQuantity);
      if(originalQuantity>0){
        let updatedOrderDataList = [...orderData];
        updatedOrderDataList[rowNum]['order_qty'] = updatedQuantity;
        updatedOrderDataList[rowNum]['total_amt'] = Math.round((backupOrderData[rowNum]['total_amt']*(updatedQuantity/originalQuantity) + Number.EPSILON) * 100) / 100;
        updatedOrderDataList[rowNum]['apmc_charge'] = Math.round((backupOrderData[rowNum]['total_amt']*(updatedQuantity/originalQuantity) + Number.EPSILON) * 100) / 100;
        updatedOrderDataList[rowNum]['gst_charge'] = Math.round((backupOrderData[rowNum]['gst_charge']*(updatedQuantity/originalQuantity) + Number.EPSILON) * 100) / 100;
        updatedOrderDataList[rowNum]['transport_charge'] = Math.round((backupOrderData[rowNum]['transport_charge']*(updatedQuantity/originalQuantity) + Number.EPSILON) * 100) / 100;
        setOrderData(updatedOrderDataList);
        setTimeout(() => {
          let bulkitemqty=document.getElementsByClassName("itemQty");
          for(let i=0;i<bulkitemqty.length;i++){
            let item_id=Number(bulkitemqty[i].name);
            if(itemID==item_id){
              bulkitemqty[i].focus();
            }
          }
         }, 0)
      }
    }

    const filterOrderData = (flag) => {
      if(flag=="Yes"){
        setOrderData(searchOrderData);
        setBackupOrderData(searchOrderData);
      }else if(flag=="No"){
        if(searchOrderData.length>0){
          let orderstatus=searchOrderData[0]['status'];
          if(orderstatus=="Partially-Delivered"){
            let finalOrdersData=[];
            for(let i=0;i<searchOrderData.length;i++){
              let order_qty=Number(searchOrderData[i]['order_qty']);
              let item_delivered_qty=Number(searchOrderData[i]['item_delivered_qty']);
              if(item_delivered_qty<order_qty){
                let ele=searchOrderData[i];
                finalOrdersData.push(ele);
              }
            }
            setOrderData(finalOrdersData);
            setBackupOrderData(finalOrdersData);
          }
        }
      }
    }

    const RetailerOrdersTableBody = () => {
      let rows=[];

      if(orderData.length>0){
        rows = orderData.map((ord, index) => {
          let itemID=ord.item_id;
          let order_qty=Number(ord.order_qty);
          let item_delivered_qty=Number(ord.item_delivered_qty);
          let item_qty=order_qty-item_delivered_qty;
          let isItemChecked=initialOrderSelection==true?true:(itemChecked[itemID]!==undefined?itemChecked[itemID]:true);
          let originalQuantity=0;
          if(backupOrderData.length>0){
            originalQuantity=backupOrderData[index]['order_qty'];
          }else{
            originalQuantity=order_qty;
          }

          return (
              <tr key={index}>
                  <td><input type="checkbox" name={ord.item_id} className="bulkItemSelection" defaultChecked={isItemChecked} onClick={(e) => {
                    setInitialOrderSelection(false);
                    setItemChecked({...itemChecked,[ord.item_id]: e.target.checked});
                  }} /></td>
                  <td>{ord['item_disc']}</td>
                  <td>{ord['item_id']}</td>
                  <td>
                    <Form.Control className="itemQty" name={ord.item_id} type="number" min="1" value={order_qty} onChange={(e) => { updateItemBasedOnQuantity(index,e.target.value,originalQuantity,ord.item_id);setItemFocusObj({[ord.item_id]: true}); }} />
                  </td>
                  <td>{((actualOrderType=="RN" && customerAdjustedPayment=='Yes') || actualOrderType=="R")?0:ord['total_amt']}</td>
              </tr>
          );
        });
      }else{
        rows=[];
      }

      return (<><tbody>{rows}</tbody></>);
    }

    const addReturnAdjustmentOrder = () => {
      console.log('addReturnAdjustmentOrder');
      console.log(returnOrderType);
      let bulkItemChecks = document.getElementsByClassName('bulkItemSelection');
      let selectedItemSet=new Set();
      for (let i=0;i<bulkItemChecks.length;i++) {
        let itemId=bulkItemChecks[i].name;
        let isItemChecked=bulkItemChecks[i].checked;
        if(isItemChecked==true){
          selectedItemSet.add(itemId);
        }
      }
      setOrderSelectedItemSet(selectedItemSet);
      console.log(selectedItemSet);
      console.log(selectedItemSet.size);

      if(selectedItemSet.size>0){
        if((customerDeductedMoney=="SELECT" && (returnItem=="Yes" || returnItem=="SELECT")) || customerReturnedItems=="No"){
          if(returnOrderType=="Customer made payment?"){
            alert("Please select if customer made payment");
          }else if(returnOrderType=="Yes"){
            //ReturnOrder
            let orderstatus=orderData[0]['status'];
            if(orderstatus!="Partially-Delivered" && orderstatus!="Delivered"){
              alert("Order status is not Partially-Delivered or Delivered");
            }else{
              let fbordersnewele={};
              let finalOrderList=JSON.parse(JSON.stringify(orderData));
              let currTimestamp=Date.parse(new Date());
              let finalOrderNumber="";

              for(let i=0;i<finalOrderList.length;i++){
                let item_id=""+finalOrderList[i]['item_id'];
                let order_qty=Number(finalOrderList[i]['order_qty']);
                if(selectedItemSet.has(item_id) && order_qty>0){
                  let ele=finalOrderList[i];
                  let order_number=finalOrderList[i]['order_number'];

                  let orderNumberList=order_number.split("/");
                  let newOrderNumber=orderNumberList[0]+"-R/"+orderNumberList[1];
                  finalOrderNumber=newOrderNumber;
                  ele['order_number']=newOrderNumber;
                  let fbkey=orderNumberList[0]+"-R-"+orderNumberList[1]+"-"+item_id;

                  ele['status']='Returned';
                  ele['net_order_amt']="0";
                  ele['order_date']=currTimestamp;
                  ele['exp_delivery_date']=currTimestamp;
                  ele['cash_amt']="0";
                  ele['total_amt']="0";
                  ele['net_amt']="0";
                  ele['order_amt']="0";
                  ele['final_order_amt']="0";
                  ele['gst_charge']="0";
                  ele['transport_charge']="0";

                  ele['cashback_redeemed']="0";
                  ele['order_disc']="0";
                  ele['reason']=returnReason;
                  let curdate = formatDate(new Date());
                  ele['ordered_at']=curdate;
                  ele['ordered_by']=JSON.parse(sessionStorage.getItem('user'))[0]['email'];
                  ele['customer_need_items']=returnItem!="SELECT"?returnItem:"";
                  ele['customer_deducted']=customerDeductedMoney!="SELECT"?customerDeductedMoney:"";
                  ele['customer_paid']=returnOrderType!="SELECT"?returnOrderType:"";
                  if(customerReturnedItems=="No"){
                    ele['customer_returned_items']="No";
                  }

                  delete ele['delivery_date'];
                  delete ele['delivery_date_conv'];
                  delete ele['item_delivered_qty'];
                  delete ele['processed_date'];
                  delete ele['revised_order_amt'];

                  delete ele['weight'];
                  delete ele['shop_name'];
                  delete ele['phone'];
                  delete ele['address1'];
                  delete ele['address2'];
                  delete ele['city'];
                  delete ele['pincode'];
                  delete ele['gst_number'];
                  delete ele['agent_id'];
                  delete ele['bagdetails'];
                  delete ele['numbags'];
                  fbordersnewele[`${fbkey}`]=ele;
                }
              }
              console.log('fbordersnewele');
              console.log(fbordersnewele);

              let driverOrdersList=JSON.parse(JSON.stringify(orderData));
              let driverordershistoryele={};

              let year,month,date;

              let currdate=new Date();
              let day=currdate.getDate();
              month=currdate.getMonth()+1;
              year=currdate.getFullYear();
              date=day+"-"+month+'-'+year;

              let retailerid=finalOrderNumber.split("-")[0];
              let apiUrl=`http://support.superzop.com/api/fetchretailerdata?retailerid=${retailerid}`
              console.log(apiUrl);
              fetch(apiUrl)
              .then((response) => {
                  return (response['status']==200)?response.json():[];
              }).then((result) => {
                  let retailerData=result['data'];
                  for(let z=0;z<driverOrdersList.length;z++){
                    let item_id=""+driverOrdersList[z]['item_id'];
                    let order_qty=Number(driverOrdersList[z]['order_qty']);
                    if(selectedItemSet.has(item_id) && order_qty>0){
                      let doele=driverOrdersList[z];
                      doele['priority']=1;

                      let order_number=driverOrdersList[z]['order_number'];
                      let orderNumberList=order_number.split("/");
                      let newOrderNumber=orderNumberList[0]+"-R/"+orderNumberList[1];

                      doele['order_number']=newOrderNumber;

                      doele['ordered_from']='web';
                      doele['ordered_by']=JSON.parse(sessionStorage.getItem('user'))[0]['email'];
                      doele['status']='Delivered';
                      doele['item_returned_qty']=""+driverOrdersList[z]['order_qty'];

                      doele['order_date']=formatOrderDate(currTimestamp);
                      doele['exp_delivery_date']=currTimestamp;


                      doele['order_amt']="0"
                      doele['final_order_amt']="0"
                      doele['net_order_amt']="0";
                      doele['cash_amt']="0";
                      doele['net_amt']="0";
                      doele['total_amt']="0";
                      doele['gst_charge']="0";
                      doele['transport_charge']="0";


                      doele['cashback_redeemed']="0";
                      doele['order_disc']="0";

                      doele['priority']=1;

                      doele['address1']=retailerData['address1']?retailerData['address1']:"";
                      doele['address2']=retailerData['address2']?retailerData['address2']:"";
                      doele['agent_id']=retailerData['agent_id']?retailerData['agent_id']:"";
                      doele['city']=retailerData['city']?retailerData['city']:"";
                      doele['gst_number']=retailerData['gst_number']?retailerData['gst_number']:"";
                      doele['phone']=retailerData['phone']?retailerData['phone']:"";
                      doele['pincode']=retailerData['pincode']?retailerData['pincode']:"";
                      doele['shop_name']=retailerData['shop_name']?retailerData['shop_name']:"";

                      doele['latitude']=retailerData['latitude']?retailerData['latitude']:"";
                      doele['longitude']=retailerData['longitude']?retailerData['longitude']:"";
                      doele['asm']=retailerData['asm']?retailerData['asm']:"";
                      doele['asmphone']=retailerData['asmphone']?retailerData['asmphone']:"";
                      doele['salesman_name']=retailerData['salesman_name']?retailerData['salesman_name']:"";
                      doele['se_phone_number']=retailerData['se_phone_number']?retailerData['se_phone_number']:"";

                      delete doele['delivery_date'];
                      delete doele['delivery_date_conv'];
                      delete doele['processed_date'];
                      delete doele['revised_order_amt'];

                      let ordnum=newOrderNumber;
                      //23451-R/3564656
                      let ordnumlist=ordnum.split("/");
                      let retid=ordnumlist[0];
                      let retlist=retid.split("-");
                      let dorefstr=retlist[0]+"_R-"+ordnumlist[1]+"-"+item_id;
                      // console.log(dorefstr);
                      let dohkey=`/${year}/${month}/${date}/9999/${dorefstr}`;

                      driverordershistoryele[`${dohkey}`]=doele;
                    }
                  }
                  console.log('driverordershistoryele');
                  console.log(driverordershistoryele);


                  let orrdersNewRef=fire.database().ref('Orders_New');
                  orrdersNewRef.update(fbordersnewele,function(err){
                    if(err){
                      alert("Error in placing order in Orders_New : "+err);
                    }else{
                      if(customerReturnedItems!="No"){
                        let driverOrdersRef = fire.database().ref('Driver_Orders_History');
                        driverOrdersRef.update(driverordershistoryele,function(error){
                          if (error) {
                            alert('Error in placing order in Driver_Orders_History : '+error);
                          }else{
                              getDeliveryOrdernumber(orderNumber);
                              alert(`Successfully placed the order. OrderNumber: ${finalOrderNumber}`);
                              setOrderOption("ScheduleForDelivery");
                              let status='Returned';
                              let deliveryOrdersFinalItemsList=[];
                              for(let i=0;i<orderData.length;i++){
                                let itemid=""+orderData[i]['item_id'];
                                let order_qty=Number(orderData[i]['order_qty']);
                                if(selectedItemSet.has(itemid) && order_qty>0){
                                  let itemele=orderData[i];
                                  itemele['status']=status;
                                  itemele['net_order_amt']="0"
                                  itemele['cash_amt']="0"
                                  itemele['net_amt']="0"
                                  itemele['order_amt']="0"
                                  itemele['final_order_amt']="0"
                                  itemele['cashback_redeemed']="0";
                                  itemele['order_disc']="0";
                                  itemele['total_amt']="0";
                                  itemele['gst_charge']="0";
                                  itemele['transport_charge']="0";

                                  deliveryOrdersFinalItemsList.push(itemele);
                                }
                              }

                              setDeliveryOrderData(deliveryOrdersFinalItemsList);
                          }
                        });
                      }else{
                        getDeliveryOrdernumber(orderNumber);
                        alert(`Successfully placed the order. OrderNumber: ${finalOrderNumber}`);
                        setOrderOption("ScheduleForDelivery");
                        let status='Returned';
                        let deliveryOrdersFinalItemsList=[];
                        for(let i=0;i<orderData.length;i++){
                          let itemid=""+orderData[i]['item_id'];
                          let order_qty=Number(orderData[i]['order_qty']);
                          if(selectedItemSet.has(itemid) && order_qty>0){
                            let itemele=orderData[i];
                            itemele['status']=status;
                            itemele['net_order_amt']="0"
                            itemele['cash_amt']="0"
                            itemele['net_amt']="0"
                            itemele['order_amt']="0"
                            itemele['final_order_amt']="0"
                            itemele['cashback_redeemed']="0";
                            itemele['order_disc']="0";
                            itemele['total_amt']="0";
                            itemele['gst_charge']="0";
                            itemele['transport_charge']="0";

                            deliveryOrdersFinalItemsList.push(itemele);
                          }
                        }

                        setDeliveryOrderData(deliveryOrdersFinalItemsList);
                      }
                    }
                  });
              }).catch((reterr)=>{
                alert("Error in fetching retailer data");
              })
            }
          }else if(returnOrderType=="No"){
            //AdjustmentOrder
            let orderstatus=orderData[0]['status'];
            if(orderstatus!="Partially-Delivered"){
              alert("Order status is not Partially-Delivered");
            }else if(customerReturnedItems=="No"){
              alert("Only -R Orders should be placed.");
            }else{
              //amount calculations
              let finalNetOrderAmount=0;
              let finalOrderAmount=0;
              let finalGstAmount=0;
              let finalTransportAmount=0;
              for(let k=0;k<orderData.length;k++){
                let item_id=""+orderData[k]['item_id'];
                if(selectedItemSet.has(item_id)){
                  let total_amt=Number(orderData[k]['total_amt']);
                  let gst_charge=Number(orderData[k]['gst_charge']);
                  let transport_charge=Number(orderData[k]['transport_charge']);
                  finalNetOrderAmount=finalNetOrderAmount+total_amt;
                  finalGstAmount=finalGstAmount+gst_charge;
                  finalTransportAmount=finalTransportAmount+transport_charge;
                }
              }
              finalOrderAmount=finalNetOrderAmount-(finalGstAmount+finalTransportAmount);


              let currTimestamp=+new Date();
              let fbordersnewele={};
              let finalOrderNumber="";
              let finalOrderList=JSON.parse(JSON.stringify(orderData));
              //get only items with item_delivered_qty < order_qty
              for(let i=0;i<finalOrderList.length;i++){
                let item_id=""+finalOrderList[i]['item_id'];
                let order_qty=Number(finalOrderList[i]['order_qty']);
                if(selectedItemSet.has(item_id) && order_qty>0){
                  let ele=finalOrderList[i];
                  let order_number=finalOrderList[i]['order_number'];

                  let orderNumberList=order_number.split("/");
                  let newOrderNumber=orderNumberList[0]+"-C/"+orderNumberList[1];
                  finalOrderNumber=newOrderNumber;
                  ele['order_number']=newOrderNumber;
                  let fbkey=orderNumberList[0]+"-C-"+orderNumberList[1]+"-"+item_id;

                  ele['status']='Ordered';
                  ele['net_order_amt']=""+finalNetOrderAmount;
                  ele['order_date']=currTimestamp;
                  ele['exp_delivery_date']=currTimestamp;
                  ele['cash_amt']=""+finalNetOrderAmount;
                  ele['net_amt']=""+finalNetOrderAmount;
                  ele['order_amt']=""+finalOrderAmount;
                  ele['final_order_amt']=""+finalOrderAmount;
                  ele['cashback_redeemed']="0";
                  ele['order_disc']="0";
                  ele['reason']=returnReason;
                  let curdate = formatDate(new Date());
                  ele['ordered_at']=curdate;
                  ele['ordered_by']=JSON.parse(sessionStorage.getItem('user'))[0]['email'];

                  delete ele['delivery_date'];
                  delete ele['delivery_date_conv'];
                  delete ele['item_delivered_qty'];
                  delete ele['processed_date'];
                  delete ele['revised_order_amt'];

                  delete ele['weight'];
                  delete ele['shop_name'];
                  delete ele['phone'];
                  delete ele['address1'];
                  delete ele['address2'];
                  delete ele['city'];
                  delete ele['pincode'];
                  delete ele['gst_number'];
                  delete ele['agent_id'];
                  delete ele['bagdetails'];
                  delete ele['numbags'];
                  fbordersnewele[`${fbkey}`]=ele;
                }
              }
              console.log('fbordersnewele');
              console.log(fbordersnewele);

              let orrdersNewRef=fire.database().ref('Orders_New');
              orrdersNewRef.update(fbordersnewele,function(err){
                if(err){
                  alert("Error in adding data to Orders_New : "+err);
                }else{
                  getDeliveryOrdernumber(orderNumber);
                  alert(`Successfully placed the order. OrderNumber: ${finalOrderNumber}`);

                  setOrderOption("ScheduleForDelivery");
                  let status='Ordered';
                  let deliveryOrdersFinalItemsList=[];
                  for(let i=0;i<orderData.length;i++){
                    let itemid=""+orderData[i]['item_id'];
                    let order_qty=Number(orderData[i]['order_qty']);
                    if(selectedItemSet.has(itemid) && order_qty>0){
                      let itemele=orderData[i];
                      itemele['status']=status;
                      deliveryOrdersFinalItemsList.push(itemele);
                    }
                  }

                  setDeliveryOrderData(deliveryOrdersFinalItemsList);
                }
              });
            }
          }
        }else{
          console.log('customerDeductedMoney');
          let orderstatus=orderData[0]['status'];
          if(orderstatus!="Partially-Delivered" && orderstatus!="Delivered"){
            alert("Order status is not Partially-Delivered or Delivered");
          }else if(customerReturnedItems=="No"){
            alert("Only -R Orders should be placed");
          }else{
            console.log(customerDeductedMoney);
            //amount calculations
            let finalNetOrderAmount=0;
            let finalOrderAmount=0;
            let finalGstAmount=0;
            let finalTransportAmount=0;
            for(let k=0;k<orderData.length;k++){
              let item_id=""+orderData[k]['item_id'];
              if(selectedItemSet.has(item_id)){
                let total_amt=Number(orderData[k]['total_amt']);
                let gst_charge=Number(orderData[k]['gst_charge']);
                let transport_charge=Number(orderData[k]['transport_charge']);
                finalNetOrderAmount=finalNetOrderAmount+total_amt;
                finalGstAmount=finalGstAmount+gst_charge;
                finalTransportAmount=finalTransportAmount+transport_charge;
              }
            }
            finalOrderAmount=finalNetOrderAmount-(finalGstAmount+finalTransportAmount);

            let orderID,retailerID,walletAmt;
            let today = new Date();
            let transactionDate=today.toShortFormat();

            let currTimestamp=new Date();
            let fbordersnewele={};
            let finalOrderList=JSON.parse(JSON.stringify(orderData));
            //get only items with item_delivered_qty < order_qty
            for(let i=0;i<finalOrderList.length;i++){
              let item_id=""+finalOrderList[i]['item_id'];
              let order_qty=Number(finalOrderList[i]['order_qty']);
              if(selectedItemSet.has(item_id) && order_qty>0){
                let ele=finalOrderList[i];
                let order_number=finalOrderList[i]['order_number'];

                let orderNumberList=order_number.split("/");
                let newOrderNumber=orderNumberList[0]+"-RN/"+orderNumberList[1];
                ele['order_number']=newOrderNumber;
                let fbkey=orderNumberList[0]+"-RN-"+orderNumberList[1]+"-"+item_id;

                if(returnItem=="No"){
                  ele['status']='Returned';
                }else{
                  ele['status']='Ordered';
                }


                ele['order_date']=currTimestamp;
                ele['exp_delivery_date']=currTimestamp;

                if(customerDeductedMoney=="Yes"){
                  ele['order_amt']="-"+finalOrderAmount.toFixed(2);
                  ele['final_order_amt']="-"+finalOrderAmount.toFixed(2);
                  ele['net_order_amt']="-"+finalNetOrderAmount.toFixed(2);
                  ele['net_amt']="-"+finalNetOrderAmount.toFixed(2);
                  ele['total_amt']="-"+finalOrderList[i]['total_amt'];
                  ele['cash_amt']="0";
                }else if(customerDeductedMoney=="No"){
                  ele['order_amt']="0"
                  ele['final_order_amt']="0"
                  ele['net_order_amt']="0";
                  ele['cash_amt']="0";
                  ele['net_amt']="0";
                  ele['total_amt']="0";
                }


                orderID=newOrderNumber;
                retailerID=finalOrderList[i]['retailer_id'];
                walletAmt=""+finalOrderAmount;

                ele['cashback_redeemed']="0";
                ele['order_disc']="0";
                ele['reason']=returnReason;
                let curdate = formatDate(new Date());
                ele['ordered_at']=curdate;
                ele['ordered_by']=JSON.parse(sessionStorage.getItem('user'))[0]['email'];
                ele['customer_need_items']=returnItem!="SELECT"?returnItem:"";
                ele['customer_deducted']=customerDeductedMoney!="SELECT"?customerDeductedMoney:"";
                ele['customer_paid']=returnOrderType!="SELECT"?returnOrderType:"";


                delete ele['delivery_date'];
                delete ele['delivery_date_conv'];
                delete ele['item_delivered_qty'];
                delete ele['processed_date'];
                delete ele['revised_order_amt'];

                delete ele['weight'];
                delete ele['shop_name'];
                delete ele['phone'];
                delete ele['address1'];
                delete ele['address2'];
                delete ele['city'];
                delete ele['pincode'];
                delete ele['gst_number'];
                delete ele['agent_id'];
                delete ele['bagdetails'];
                delete ele['numbags'];
                fbordersnewele[`${fbkey}`]=ele;
              }
            }
            console.log('fbordersnewele');
            console.log(fbordersnewele);

            let driverOrdersList=JSON.parse(JSON.stringify(orderData));
            let driverordershistoryele={};

            let year,month,date;

            if(validateIsAdminUser(JSON.parse(sessionStorage.getItem(`user`))[0].email)){
              let currdate=new Date(returnDate);
              let day=currdate.getDate();
              month=currdate.getMonth()+1;
              year=currdate.getFullYear();
              date=day+"-"+month+'-'+year;
            }else{
              let currdate=new Date();
              let day=currdate.getDate();
              month=currdate.getMonth()+1;
              year=currdate.getFullYear();
              date=day+"-"+month+'-'+year;
            }

            let retailerid=orderID.split("-")[0];
            let apiUrl=`http://support.superzop.com/api/fetchretailerdata?retailerid=${retailerid}`
            console.log(apiUrl);
            fetch(apiUrl)
            .then((response) => {
                return (response['status']==200)?response.json():[];
            }).then((result) => {
                let retailerData=result['data'];

                for(let z=0;z<driverOrdersList.length;z++){
                  let item_id=""+driverOrdersList[z]['item_id'];
                  let order_qty=Number(driverOrdersList[z]['order_qty']);
                  if(selectedItemSet.has(item_id) && order_qty>0){
                    let doele=driverOrdersList[z];
                    doele['priority']=1;

                    let order_number=driverOrdersList[z]['order_number'];

                    let orderNumberList=order_number.split("/");
                    let newOrderNumber=orderNumberList[0]+"-RN/"+orderNumberList[1];
                    let fbkey=orderNumberList[0]+"_RN-"+orderNumberList[1]+"-"+item_id;

                    doele['order_number']=newOrderNumber;

                    doele['ordered_from']='web';
                    doele['ordered_by']=JSON.parse(sessionStorage.getItem('user'))[0]['email'];
                    doele['status']='Delivered';
                    doele['item_returned_qty']=""+driverOrdersList[z]['order_qty'];

                    doele['order_date']=formatOrderDate(currTimestamp);
                    doele['exp_delivery_date']=currTimestamp;

                    if(customerDeductedMoney=="Yes"){
                      doele['order_amt']="-"+finalOrderAmount.toFixed(2);
                      doele['final_order_amt']="-"+finalOrderAmount.toFixed(2);
                      doele['net_order_amt']="-"+finalNetOrderAmount.toFixed(2);
                      doele['net_amt']="-"+finalNetOrderAmount.toFixed(2);
                      doele['total_amt']="-"+driverOrdersList[z]['total_amt'];
                      doele['cash_amt']="0";
                    }else if(customerDeductedMoney=="No"){
                      doele['order_amt']="0"
                      doele['final_order_amt']="0"
                      doele['net_order_amt']="0";
                      doele['cash_amt']="0";
                      doele['net_amt']="0";
                      doele['total_amt']="0";
                    }

                    doele['cashback_redeemed']="0";
                    doele['order_disc']="0";

                    doele['address1']=retailerData['address1']?retailerData['address1']:"";
                    doele['address2']=retailerData['address2']?retailerData['address2']:"";
                    doele['agent_id']=retailerData['agent_id']?retailerData['agent_id']:"";
                    doele['city']=retailerData['city']?retailerData['city']:"";
                    doele['gst_number']=retailerData['gst_number']?retailerData['gst_number']:"";
                    doele['phone']=retailerData['phone']?retailerData['phone']:"";
                    doele['pincode']=retailerData['pincode']?retailerData['pincode']:"";
                    doele['shop_name']=retailerData['shop_name']?retailerData['shop_name']:"";
                    doele['latitude']=retailerData['latitude']?retailerData['latitude']:"";
                    doele['longitude']=retailerData['longitude']?retailerData['longitude']:"";
                    doele['asm']=retailerData['asm']?retailerData['asm']:"";
                    doele['asmphone']=retailerData['asmphone']?retailerData['asmphone']:"";
                    doele['salesman_name']=retailerData['salesman_name']?retailerData['salesman_name']:"";
                    doele['se_phone_number']=retailerData['se_phone_number']?retailerData['se_phone_number']:"";

                    delete doele['delivery_date'];
                    delete doele['delivery_date_conv'];
                    delete doele['processed_date'];
                    delete doele['revised_order_amt'];

                    let dohkey=`/${year}/${month}/${date}/9999/${fbkey}`;

                    driverordershistoryele[`${dohkey}`]=doele;
                  }
                }
                console.log('driverordershistoryele');
                console.log(driverordershistoryele);

                //add in firebase
                let orrdersNewRef=fire.database().ref('Orders_New');
                orrdersNewRef.update(fbordersnewele,function(err){
                  if(err){
                    alert("Error in placing order in Orders_New : "+err);
                  }else{
                    let driverOrdersRef = fire.database().ref('Driver_Orders_History');
                    driverOrdersRef.update(driverordershistoryele,function(error){
                      if (error) {
                        alert('Error in placing order in Driver_Orders_History : '+error);
                      }else{
                        if(customerDeductedMoney=="No"){
                          let walletele={};
                          walletele['order_id']=orderID;
                          walletele['retailer_id']=""+retailerID;
                          walletele['transaction_date']=transactionDate;
                          walletele['wallet_amt']=walletAmt;
                          let walletMasterRef=fire.database().ref(`Wallet_Master/${retailerID}/`);
                          let walletRef = walletMasterRef.push();
                          walletRef.set(walletele,function(errr){
                            if(errr){
                              alert("Error in adding amount to Wallet_Master : "+errr);
                            }else{
                              setOrderData([]);
                              setBackupOrderData([]);
                              setSearchOrderData([]);
                              setItemChecked({});
                              setOrdrStatus("");
                              setInitialOrderSelection(true);
                              setOrderNumber("");
                              setReturnOrderType("SELECT");
                              setReturnReason("SELECT");
                              setReturnItem("SELECT");
                              setCustomerDeductedMoney("SELECT");
                              setReasonDropdownDisable(false);
                              let currdate=formatOrderDate(new Date());
                              setReturnDate(currdate);
                              alert(`Successfully placed the order. OrderNumber: ${orderID}`);
                            }
                          });

                        }else{
                          setOrderData([]);
                          setBackupOrderData([]);
                          setSearchOrderData([]);
                          setItemChecked({});
                          setOrdrStatus("");
                          setInitialOrderSelection(true);
                          setOrderNumber("");
                          setReturnOrderType("SELECT");
                          setReturnReason("SELECT");
                          setReturnItem("SELECT");
                          setCustomerDeductedMoney("SELECT");
                          setReasonDropdownDisable(false);
                          let currdate=formatOrderDate(new Date());
                          setReturnDate(currdate);
                          alert(`Successfully placed the order. OrderNumber: ${orderID}`);
                        }
                      }
                    });
                  }
                });
            }).catch((reterr)=>{
              alert("Error in fetching retailer data");
            })
          }
        }
      }else{
        alert("Please select items");
      }
    }

    const getDeliveryOrdernumber = (ordernumber) => {
      if(ordernumber && ordernumber.length>0){
        let ordernumberlist=ordernumber.split("/");

        if(actualOrderType=="R"){
          let ordnum=ordernumberlist[0]+"-R/"+ordernumberlist[1];
          setDeliveryOrderNumber(ordnum);
        }else if(actualOrderType=="C"){
          let ordnum=ordernumberlist[0]+"-C/"+ordernumberlist[1]
          setDeliveryOrderNumber(ordnum);
        }else{
          setDeliveryOrderNumber("");
        }
      }else{
        setDeliveryOrderNumber("");
      }
    }



    const placeDeliveryOrder = () => {
      console.log('placeDeliveryOrder');
      console.log(deliveryOrderNumber);

      if(routeVehicleId=="" || typeof(routeVehicleId)=="undefined"){
        alert("Please enter route");
      }else if(vehiclePriority=="" || typeof(vehiclePriority)=="undefined"){
        alert("Please enter order priority");
      }else if(deliveryOrderNumber.length==0){
        alert("Order Number is empty");
      }else if(!deliveryOrderNumber.includes("-C") && !deliveryOrderNumber.includes("-R")){
        alert("Only Order Number with -C or -R is allowed");
      }else if(deliveryOrderData.length>0){
          let orderstatus=deliveryOrderData[0]['status'];
          if(orderstatus=="Partially-Delivered" || orderstatus=="Delivered"){
            alert("Order status should not be Partially-Delivered or Delivered");
          }else{
            //amount calculations
            let finalNetOrderAmount=0;
            let finalOrderAmount=0;
            let finalGstAmount=0;
            let finalTransportAmount=0;
            for(let k=0;k<deliveryOrderData.length;k++){
              let item_id=""+deliveryOrderData[k]['item_id'];
              let total_amt=Number(deliveryOrderData[k]['total_amt']);
              let gst_charge=Number(deliveryOrderData[k]['gst_charge']);
              let transport_charge=Number(deliveryOrderData[k]['transport_charge']);
              finalNetOrderAmount=finalNetOrderAmount+total_amt;
              finalGstAmount=finalGstAmount+gst_charge;
              finalTransportAmount=finalTransportAmount+transport_charge;

            }
            finalOrderAmount=finalNetOrderAmount-(finalGstAmount+finalTransportAmount);
            let currTimestamp=Date.parse(new Date());

            let driverOrdersList=JSON.parse(JSON.stringify(deliveryOrderData));
            let driverordershistoryele={};

            let year,month,date;

            if(validateIsAdminUser(JSON.parse(sessionStorage.getItem(`user`))[0].email)){
              let currdate=new Date(deliveryDate);
              let day=currdate.getDate();
              month=currdate.getMonth()+1;
              year=currdate.getFullYear();
              date=day+"-"+month+'-'+year;
            }else{
              let currdate=new Date();
              let day=currdate.getDate();
              month=currdate.getMonth()+1;
              year=currdate.getFullYear();
              date=day+"-"+month+'-'+year;
            }


            //get retailer data
            let retailerid=deliveryOrderNumber.split("-")[0];
            let apiUrl=`http://support.superzop.com/api/fetchretailerdata?retailerid=${retailerid}`
            console.log(apiUrl);
            fetch(apiUrl)
            .then((response) => {
                return (response['status']==200)?response.json():[];
            }).then((result) => {
                let retailerData=result['data'];

                for(let z=0;z<driverOrdersList.length;z++){
                  let item_id=""+driverOrdersList[z]['item_id'];
                  let doele=driverOrdersList[z];
                  doele['priority']=vehiclePriority;

                  doele['order_number']=deliveryOrderNumber;

                  doele['ordered_from']='web';
                  doele['ordered_by']=JSON.parse(sessionStorage.getItem('user'))[0]['email'];
                  doele['status']=orderstatus;

                  if(deliveryOrderNumber.includes("R")){
                    doele['net_order_amt']="0"
                    doele['order_date']=formatOrderDate(currTimestamp);
                    doele['exp_delivery_date']=currTimestamp;
                    doele['cash_amt']="0"
                    doele['net_amt']="0"
                    doele['order_amt']="0"
                    doele['final_order_amt']="0"
                    doele['cashback_redeemed']="0";
                    doele['order_disc']="0";
                    doele['total_amt']="0";
                    doele['gst_charge']="0";
                    doele['transport_charge']="0";
                  }else{
                    doele['net_order_amt']=""+finalNetOrderAmount;
                    doele['order_date']=formatOrderDate(currTimestamp);
                    doele['exp_delivery_date']=currTimestamp;
                    doele['cash_amt']=""+finalNetOrderAmount;
                    doele['net_amt']=""+finalNetOrderAmount;
                    doele['order_amt']=""+finalOrderAmount;
                    doele['final_order_amt']=""+finalOrderAmount;
                    doele['cashback_redeemed']="0";
                    doele['order_disc']="0";
                    doele['total_amt']=""+finalNetOrderAmount;
                    doele['mrp']=""+driverOrdersList[z]['price'];
                  }

                  doele['address1']=retailerData['address1']?retailerData['address1']:"";
                  doele['address2']=retailerData['address2']?retailerData['address2']:"";
                  doele['agent_id']=retailerData['agent_id']?retailerData['agent_id']:"";
                  doele['city']=retailerData['city']?retailerData['city']:"";
                  doele['gst_number']=retailerData['gst_number']?retailerData['gst_number']:"";
                  doele['phone']=retailerData['phone']?retailerData['phone']:"";
                  doele['pincode']=retailerData['pincode']?retailerData['pincode']:"";
                  doele['shop_name']=retailerData['shop_name']?retailerData['shop_name']:"";
                  doele['latitude']=retailerData['latitude']?retailerData['latitude']:"";
                  doele['longitude']=retailerData['longitude']?retailerData['longitude']:"";
                  doele['asm']=retailerData['asm']?retailerData['asm']:"";
                  doele['asmphone']=retailerData['asmphone']?retailerData['asmphone']:"";
                  doele['salesman_name']=retailerData['salesman_name']?retailerData['salesman_name']:"";
                  doele['se_phone_number']=retailerData['se_phone_number']?retailerData['se_phone_number']:"";


                  delete doele['delivery_date'];
                  delete doele['delivery_date_conv'];
                  delete doele['item_delivered_qty'];
                  delete doele['processed_date'];
                  delete doele['revised_order_amt'];

                  let ordnum=deliveryOrderNumber;
                  //23451-R/3564656
                  let ordnumlist=ordnum.split("/");
                  let retid=ordnumlist[0];
                  let retlist=retid.split("-");
                  let dorefstr=retlist[0]+"_"+retlist[1]+"-"+ordnumlist[1]+"-"+item_id;
                  console.log(dorefstr);
                  let dohkey=`/${year}/${month}/${date}/${routeVehicleId}/${dorefstr}`;

                  driverordershistoryele[`${dohkey}`]=doele;
                }
                console.log('driverordershistoryele');
                console.log(driverordershistoryele);

                //add in firebase
                let driverOrdersRef = fire.database().ref('Driver_Orders_History');
                driverOrdersRef.update(driverordershistoryele,function(error){
                  if (error) {
                    alert('Error in adding data to Driver_Orders_History : '+error);
                  }else{
                    setDeliveryOrderNumber("");
                    setDeliveryOrderData([]);
                    let currdate=formatOrderDate(new Date());
                    setDeliveryDate(currdate);
                    alert(`Successfully Assigned Order: ${deliveryOrderNumber} to Route: ${routeVehicleId}`);
                  }
                });

            }).catch((reterr)=>{
              alert("Error in fetching retailer data");
            })
          }
      }
    }


    //latest code
    const placeShortOrder = () => {
      let bulkItemChecks = document.getElementsByClassName('bulkItemSelection');
      let selectedItemSet=new Set();
      for (let i=0;i<bulkItemChecks.length;i++) {
        let itemId=bulkItemChecks[i].name;
        let isItemChecked=bulkItemChecks[i].checked;
        if(isItemChecked==true){
          selectedItemSet.add(itemId);
        }
      }
      setOrderSelectedItemSet(selectedItemSet);
      console.log(selectedItemSet);
      console.log(selectedItemSet.size);

      if(selectedItemSet.size>0){
        let orderstatus=orderData[0]['status'];
        if(orderstatus!="Partially-Delivered"){
          alert("Order status is not Partially-Delivered");
        }else{
          //amount calculations
          let finalNetOrderAmount=0;
          let finalOrderAmount=0;
          let finalGstAmount=0;
          let finalTransportAmount=0;
          for(let k=0;k<orderData.length;k++){
            let item_id=""+orderData[k]['item_id'];
            if(selectedItemSet.has(item_id)){
              let total_amt=Number(orderData[k]['total_amt']);
              let gst_charge=Number(orderData[k]['gst_charge']);
              let transport_charge=Number(orderData[k]['transport_charge']);
              finalNetOrderAmount=finalNetOrderAmount+total_amt;
              finalGstAmount=finalGstAmount+gst_charge;
              finalTransportAmount=finalTransportAmount+transport_charge;
            }
          }
          finalOrderAmount=finalNetOrderAmount-(finalGstAmount+finalTransportAmount);


          let currTimestamp=Date.parse(new Date());
          let fbordersnewele={};
          let finalOrderNumber="";
          let finalOrderList=JSON.parse(JSON.stringify(orderData));
          //get only items with item_delivered_qty < order_qty
          for(let i=0;i<finalOrderList.length;i++){
            let item_id=""+finalOrderList[i]['item_id'];
            let order_qty=Number(finalOrderList[i]['order_qty']);
            if(selectedItemSet.has(item_id) && order_qty>0){
              let ele=finalOrderList[i];
              let order_number=finalOrderList[i]['order_number'];

              let orderNumberList=order_number.split("/");
              let newOrderNumber=orderNumberList[0]+"-C/"+orderNumberList[1];
              finalOrderNumber=newOrderNumber;
              ele['order_number']=newOrderNumber;
              let fbkey=orderNumberList[0]+"-C-"+orderNumberList[1]+"-"+item_id;

              ele['status']='Ordered';
              ele['net_order_amt']=""+finalNetOrderAmount;
              ele['order_date']=currTimestamp;
              ele['exp_delivery_date']=currTimestamp;
              ele['cash_amt']=""+finalNetOrderAmount;
              ele['net_amt']=""+finalNetOrderAmount;
              ele['order_amt']=""+finalOrderAmount;
              ele['final_order_amt']=""+finalOrderAmount;
              ele['cashback_redeemed']="0";
              ele['order_disc']="0";
              ele['reason']=returnReason;
              let curdate = formatDate(new Date());
              ele['ordered_at']=curdate;
              ele['ordered_by']=JSON.parse(sessionStorage.getItem('user'))[0]['email'];

              delete ele['delivery_date'];
              delete ele['delivery_date_conv'];
              delete ele['item_delivered_qty'];
              delete ele['processed_date'];
              delete ele['revised_order_amt'];

              delete ele['weight'];
              delete ele['shop_name'];
              delete ele['phone'];
              delete ele['address1'];
              delete ele['address2'];
              delete ele['city'];
              delete ele['pincode'];
              delete ele['gst_number'];
              delete ele['agent_id'];
              delete ele['bagdetails'];
              delete ele['numbags'];
              fbordersnewele[`${fbkey}`]=ele;
            }
          }
          console.log('fbordersnewele');
          console.log(fbordersnewele);

          let orrdersNewRef=fire.database().ref('Orders_New');
          orrdersNewRef.update(fbordersnewele,function(err){
            if(err){
              alert("Error in adding data to Orders_New : "+err);
            }else{
              getDeliveryOrdernumber(orderNumber);
              alert(`Successfully placed the order. OrderNumber: ${finalOrderNumber}`);

              setOrderOption("ScheduleForDelivery");
              let status='Ordered';
              let deliveryOrdersFinalItemsList=[];
              for(let i=0;i<orderData.length;i++){
                let itemid=""+orderData[i]['item_id'];
                let order_qty=Number(orderData[i]['order_qty']);
                if(selectedItemSet.has(itemid) && order_qty>0){
                  let itemele=orderData[i];
                  itemele['status']=status;
                  deliveryOrdersFinalItemsList.push(itemele);
                }
              }

              setDeliveryOrderData(deliveryOrdersFinalItemsList);
            }
          });
        }
      }else{
        alert("Please select items");
      }
    }

    const ReasonsDropdown = () => {
      console.log(actualOrderType);
      let reasonlist=[];
      let rows=[];

      if(actualOrderType!="SELECT"){
        if(actualOrderType=="C" || actualOrderType=="R"){
          reasonlist=["Shortage","UnDelivered","Cross Loading","Damaged","Quality Issue","Weight Issue","Packaging Issue"];
        }else if(actualOrderType=="RN"){
          reasonlist=["Cross Loading","Damaged","Quality Issue","Weight Issue","Packaging Issue"];
        }
        rows=reasonlist.map((reason,index)=>{
          return (<Dropdown.Item key={reason} name={reason} onClick={(e)=>{e.preventDefault();setReturnReason(e.target.name);}}>{reason}</Dropdown.Item>)
        });
      }else{
        rows=[];
      }
      return (<><DropdownButton id="dropdown-basic-button" title={(returnReason!="SELECT")?returnReason:"SELECT"} size="sm" variant="secondary" >{rows}</DropdownButton></>);
    }

    const VehicleDropdown = () => {
      let rows=[];

      if(validRouteList.length>0){
        rows=validRouteList.map((route,index)=>{
          if(route!="9999"){
            return (<Dropdown.Item key={route} name={route} onClick={(e)=>{e.preventDefault();setRouteVehicleId(e.target.name);}}>{route}</Dropdown.Item>)
          }
        });
      }else{
        rows=[];
      }

      return (<><DropdownButton id="dropdown-basic-button" title={(routeVehicleId!="SELECT")?routeVehicleId:"SELECT"} size="sm" variant="secondary" >{rows}</DropdownButton></>);
    }

    const placeReturnOrderForDelivery = () => {
      let bulkItemChecks = document.getElementsByClassName('bulkItemSelection');
      let selectedItemSet=new Set();
      for (let i=0;i<bulkItemChecks.length;i++) {
        let itemId=bulkItemChecks[i].name;
        let isItemChecked=bulkItemChecks[i].checked;
        if(isItemChecked==true){
          selectedItemSet.add(itemId);
        }
      }
      setOrderSelectedItemSet(selectedItemSet);
      console.log(selectedItemSet);
      console.log(selectedItemSet.size);

      if(selectedItemSet.size){
        let orderstatus=orderData[0]['status'];
        if(orderstatus!="Partially-Delivered" && orderstatus!="Delivered"){
          alert("Order status is not Partially-Delivered or Delivered");
        }else{
          let fbordersnewele={};
          let finalOrderList=JSON.parse(JSON.stringify(orderData));
          let currTimestamp=Date.parse(new Date());
          let finalOrderNumber="";

          for(let i=0;i<finalOrderList.length;i++){
            let item_id=""+finalOrderList[i]['item_id'];
            let order_qty=Number(finalOrderList[i]['order_qty']);
            if(selectedItemSet.has(item_id) && order_qty>0){
              let ele=finalOrderList[i];
              let order_number=finalOrderList[i]['order_number'];

              let orderNumberList=order_number.split("/");
              let newOrderNumber=orderNumberList[0]+"-R/"+orderNumberList[1];
              finalOrderNumber=newOrderNumber;
              ele['order_number']=newOrderNumber;
              let fbkey=orderNumberList[0]+"-R-"+orderNumberList[1]+"-"+item_id;

              ele['status']='Returned';
              ele['net_order_amt']="0";
              ele['order_date']=currTimestamp;
              ele['exp_delivery_date']=currTimestamp;
              ele['cash_amt']="0";
              ele['total_amt']="0";
              ele['net_amt']="0";
              ele['order_amt']="0";
              ele['final_order_amt']="0";
              ele['gst_charge']="0";
              ele['transport_charge']="0";

              ele['cashback_redeemed']="0";
              ele['order_disc']="0";
              ele['reason']=returnReason;
              let curdate = formatDate(new Date());
              ele['ordered_at']=curdate;
              ele['ordered_by']=JSON.parse(sessionStorage.getItem('user'))[0]['email'];
              ele['customer_need_items']=returnItem!="SELECT"?returnItem:"";
              ele['customer_deducted']=customerDeductedMoney!="SELECT"?customerDeductedMoney:"";
              ele['customer_paid']=returnOrderType!="SELECT"?returnOrderType:"";
              if(customerReturnedItems=="No"){
                ele['customer_returned_items']="No";
              }

              delete ele['delivery_date'];
              delete ele['delivery_date_conv'];
              delete ele['item_delivered_qty'];
              delete ele['processed_date'];
              delete ele['revised_order_amt'];

              delete ele['weight'];
              delete ele['shop_name'];
              delete ele['phone'];
              delete ele['address1'];
              delete ele['address2'];
              delete ele['city'];
              delete ele['pincode'];
              delete ele['gst_number'];
              delete ele['agent_id'];
              delete ele['bagdetails'];
              delete ele['numbags'];
              fbordersnewele[`${fbkey}`]=ele;
            }
          }
          console.log('fbordersnewele');
          console.log(fbordersnewele);

          let driverOrdersList=JSON.parse(JSON.stringify(orderData));
          let driverordershistoryele={};

          let year,month,date;

          let currdate=new Date();
          let day=currdate.getDate();
          month=currdate.getMonth()+1;
          year=currdate.getFullYear();
          date=day+"-"+month+'-'+year;

          let retailerid=finalOrderNumber.split("-")[0];
          let apiUrl=`http://support.superzop.com/api/fetchretailerdata?retailerid=${retailerid}`
          console.log(apiUrl);
          fetch(apiUrl)
          .then((response) => {
              return (response['status']==200)?response.json():[];
          }).then((result) => {
              let retailerData=result['data'];
              for(let z=0;z<driverOrdersList.length;z++){
                let item_id=""+driverOrdersList[z]['item_id'];
                let order_qty=Number(driverOrdersList[z]['order_qty']);
                if(selectedItemSet.has(item_id) && order_qty>0){
                  let doele=driverOrdersList[z];
                  doele['priority']=1;

                  let order_number=driverOrdersList[z]['order_number'];
                  let orderNumberList=order_number.split("/");
                  let newOrderNumber=orderNumberList[0]+"-R/"+orderNumberList[1];

                  doele['order_number']=newOrderNumber;

                  doele['ordered_from']='web';
                  doele['ordered_by']=JSON.parse(sessionStorage.getItem('user'))[0]['email'];
                  doele['status']='Delivered';
                  doele['item_returned_qty']=""+driverOrdersList[z]['order_qty'];

                  doele['order_date']=formatOrderDate(currTimestamp);
                  doele['exp_delivery_date']=currTimestamp;


                  doele['order_amt']="0"
                  doele['final_order_amt']="0"
                  doele['net_order_amt']="0";
                  doele['cash_amt']="0";
                  doele['net_amt']="0";
                  doele['total_amt']="0";
                  doele['gst_charge']="0";
                  doele['transport_charge']="0";


                  doele['cashback_redeemed']="0";
                  doele['order_disc']="0";

                  doele['priority']=1;

                  doele['address1']=retailerData['address1']?retailerData['address1']:"";
                  doele['address2']=retailerData['address2']?retailerData['address2']:"";
                  doele['agent_id']=retailerData['agent_id']?retailerData['agent_id']:"";
                  doele['city']=retailerData['city']?retailerData['city']:"";
                  doele['gst_number']=retailerData['gst_number']?retailerData['gst_number']:"";
                  doele['phone']=retailerData['phone']?retailerData['phone']:"";
                  doele['pincode']=retailerData['pincode']?retailerData['pincode']:"";
                  doele['shop_name']=retailerData['shop_name']?retailerData['shop_name']:"";

                  doele['latitude']=retailerData['latitude']?retailerData['latitude']:"";
                  doele['longitude']=retailerData['longitude']?retailerData['longitude']:"";
                  doele['asm']=retailerData['asm']?retailerData['asm']:"";
                  doele['asmphone']=retailerData['asmphone']?retailerData['asmphone']:"";
                  doele['salesman_name']=retailerData['salesman_name']?retailerData['salesman_name']:"";
                  doele['se_phone_number']=retailerData['se_phone_number']?retailerData['se_phone_number']:"";

                  delete doele['delivery_date'];
                  delete doele['delivery_date_conv'];
                  delete doele['processed_date'];
                  delete doele['revised_order_amt'];

                  let ordnum=newOrderNumber;
                  //23451-R/3564656
                  let ordnumlist=ordnum.split("/");
                  let retid=ordnumlist[0];
                  let retlist=retid.split("-");
                  let dorefstr=retlist[0]+"_R-"+ordnumlist[1]+"-"+item_id;
                  // console.log(dorefstr);
                  let dohkey=`/${year}/${month}/${date}/9999/${dorefstr}`;

                  driverordershistoryele[`${dohkey}`]=doele;
                }
              }
              console.log('driverordershistoryele');
              console.log(driverordershistoryele);


              let orrdersNewRef=fire.database().ref('Orders_New');
              orrdersNewRef.update(fbordersnewele,function(err){
                if(err){
                  alert("Error in placing order in Orders_New : "+err);
                }else{
                  if(customerReturnedItems!="No"){
                    let driverOrdersRef = fire.database().ref('Driver_Orders_History');
                    driverOrdersRef.update(driverordershistoryele,function(error){
                      if (error) {
                        alert('Error in placing order in Driver_Orders_History : '+error);
                      }else{
                          getDeliveryOrdernumber(orderNumber);
                          alert(`Successfully placed the order. OrderNumber: ${finalOrderNumber}`);
                          setOrderOption("ScheduleForDelivery");
                          let status='Returned';
                          let deliveryOrdersFinalItemsList=[];
                          for(let i=0;i<orderData.length;i++){
                            let itemid=""+orderData[i]['item_id'];
                            let order_qty=Number(orderData[i]['order_qty']);
                            if(selectedItemSet.has(itemid) && order_qty>0){
                              let itemele=orderData[i];
                              itemele['status']=status;
                              itemele['net_order_amt']="0"
                              itemele['cash_amt']="0"
                              itemele['net_amt']="0"
                              itemele['order_amt']="0"
                              itemele['final_order_amt']="0"
                              itemele['cashback_redeemed']="0";
                              itemele['order_disc']="0";
                              itemele['total_amt']="0";
                              itemele['gst_charge']="0";
                              itemele['transport_charge']="0";

                              deliveryOrdersFinalItemsList.push(itemele);
                            }
                          }

                          setDeliveryOrderData(deliveryOrdersFinalItemsList);
                      }
                    });
                  }else{
                    getDeliveryOrdernumber(orderNumber);
                    alert(`Successfully placed the order. OrderNumber: ${finalOrderNumber}`);
                    setOrderOption("ScheduleForDelivery");
                    let status='Returned';
                    let deliveryOrdersFinalItemsList=[];
                    for(let i=0;i<orderData.length;i++){
                      let itemid=""+orderData[i]['item_id'];
                      let order_qty=Number(orderData[i]['order_qty']);
                      if(selectedItemSet.has(itemid) && order_qty>0){
                        let itemele=orderData[i];
                        itemele['status']=status;
                        itemele['net_order_amt']="0"
                        itemele['cash_amt']="0"
                        itemele['net_amt']="0"
                        itemele['order_amt']="0"
                        itemele['final_order_amt']="0"
                        itemele['cashback_redeemed']="0";
                        itemele['order_disc']="0";
                        itemele['total_amt']="0";
                        itemele['gst_charge']="0";
                        itemele['transport_charge']="0";

                        deliveryOrdersFinalItemsList.push(itemele);
                      }
                    }

                    setDeliveryOrderData(deliveryOrdersFinalItemsList);
                  }
                }
              });
          }).catch((reterr)=>{
            alert("Error in fetching retailer data");
          })
        }
      }else{
        alert("Please select items");
      }
    }

    const placeReturnOrderForWarehouse = () => {
      let bulkItemChecks = document.getElementsByClassName('bulkItemSelection');
      let selectedItemSet=new Set();
      for (let i=0;i<bulkItemChecks.length;i++) {
        let itemId=bulkItemChecks[i].name;
        let isItemChecked=bulkItemChecks[i].checked;
        if(isItemChecked==true){
          selectedItemSet.add(itemId);
        }
      }
      setOrderSelectedItemSet(selectedItemSet);
      console.log(selectedItemSet);
      console.log(selectedItemSet.size);

      if(selectedItemSet.size){
        let orderstatus=orderData[0]['status'];
        if(orderstatus!="Partially-Delivered" && orderstatus!="Delivered"){
          alert("Order status is not Partially-Delivered or Delivered");
        }else if(customerAdjustedPayment=="No" && !validateIsAdminUser(JSON.parse(sessionStorage.getItem(`user`))[0].email)){
          alert("Please contact Admin to place the ReturnOrder.");
        }else{
          console.log(customerAdjustedPayment);
          //amount calculations
          let finalNetOrderAmount=0;
          let finalOrderAmount=0;
          let finalGstAmount=0;
          let finalTransportAmount=0;
          for(let k=0;k<orderData.length;k++){
            let item_id=""+orderData[k]['item_id'];
            if(selectedItemSet.has(item_id)){
              let total_amt=Number(orderData[k]['total_amt']);
              let gst_charge=Number(orderData[k]['gst_charge']);
              let transport_charge=Number(orderData[k]['transport_charge']);
              finalNetOrderAmount=finalNetOrderAmount+total_amt;
              finalGstAmount=finalGstAmount+gst_charge;
              finalTransportAmount=finalTransportAmount+transport_charge;
            }
          }
          finalOrderAmount=finalNetOrderAmount-(finalGstAmount+finalTransportAmount);

          let orderID,retailerID,walletAmt;
          let today = new Date();
          let transactionDate=today.toShortFormat();

          let currTimestamp=Date.parse(new Date());
          let fbordersnewele={};
          let finalOrderList=JSON.parse(JSON.stringify(orderData));
          //get only items with item_delivered_qty < order_qty
          for(let i=0;i<finalOrderList.length;i++){
            let item_id=""+finalOrderList[i]['item_id'];
            let order_qty=Number(finalOrderList[i]['order_qty']);
            if(selectedItemSet.has(item_id) && order_qty>0){
              let ele=finalOrderList[i];
              let order_number=finalOrderList[i]['order_number'];

              let orderNumberList=order_number.split("/");
              let newOrderNumber=orderNumberList[0]+"-RN/"+orderNumberList[1];
              ele['order_number']=newOrderNumber;
              let fbkey=orderNumberList[0]+"-RN-"+orderNumberList[1]+"-"+item_id;

              ele['status']='Returned';

              ele['order_date']=currTimestamp;
              ele['exp_delivery_date']=currTimestamp;

              if(customerAdjustedPayment=="No"){
                ele['order_amt']="-"+finalOrderAmount.toFixed(2);
                ele['final_order_amt']="-"+finalOrderAmount.toFixed(2);
                ele['net_order_amt']="-"+finalNetOrderAmount.toFixed(2);
                ele['net_amt']="-"+finalNetOrderAmount.toFixed(2);
                ele['total_amt']="-"+finalOrderList[i]['total_amt'];
                ele['cash_amt']="0";
              }else if(customerAdjustedPayment=="Yes"){
                ele['order_amt']="0"
                ele['final_order_amt']="0"
                ele['net_order_amt']="0";
                ele['cash_amt']="0";
                ele['net_amt']="0";
                ele['total_amt']="0";
              }


              orderID=newOrderNumber;
              retailerID=finalOrderList[i]['retailer_id'];
              walletAmt=""+finalOrderAmount;

              ele['cashback_redeemed']="0";
              ele['order_disc']="0";
              ele['reason']=returnReason;
              let curdate = formatDate(new Date());
              ele['ordered_at']=curdate;
              ele['ordered_by']=JSON.parse(sessionStorage.getItem('user'))[0]['email'];
              ele['customer_adjusted_payment']=customerAdjustedPayment!="SELECT"?customerAdjustedPayment:"";


              delete ele['delivery_date'];
              delete ele['delivery_date_conv'];
              delete ele['item_delivered_qty'];
              delete ele['processed_date'];
              delete ele['revised_order_amt'];

              delete ele['weight'];
              delete ele['shop_name'];
              delete ele['phone'];
              delete ele['address1'];
              delete ele['address2'];
              delete ele['city'];
              delete ele['pincode'];
              delete ele['gst_number'];
              delete ele['agent_id'];
              delete ele['bagdetails'];
              delete ele['numbags'];
              fbordersnewele[`${fbkey}`]=ele;
            }
          }
          console.log('fbordersnewele');
          console.log(fbordersnewele);

          let driverOrdersList=JSON.parse(JSON.stringify(orderData));
          let driverordershistoryele={};

          let year,month,date;

          if(validateIsAdminUser(JSON.parse(sessionStorage.getItem(`user`))[0].email)){
            let currdate=new Date(returnDate);
            let day=currdate.getDate();
            month=currdate.getMonth()+1;
            year=currdate.getFullYear();
            date=day+"-"+month+'-'+year;
          }else{
            let currdate=new Date();
            let day=currdate.getDate();
            month=currdate.getMonth()+1;
            year=currdate.getFullYear();
            date=day+"-"+month+'-'+year;
          }

          let retailerid=orderID.split("-")[0];
          let apiUrl=`http://support.superzop.com/api/fetchretailerdata?retailerid=${retailerid}`
          console.log(apiUrl);
          fetch(apiUrl)
          .then((response) => {
              return (response['status']==200)?response.json():[];
          }).then((result) => {
              let retailerData=result['data'];

              for(let z=0;z<driverOrdersList.length;z++){
                let item_id=""+driverOrdersList[z]['item_id'];
                let order_qty=Number(driverOrdersList[z]['order_qty']);
                if(selectedItemSet.has(item_id) && order_qty>0){
                  let doele=driverOrdersList[z];
                  doele['priority']=1;

                  let order_number=driverOrdersList[z]['order_number'];

                  let orderNumberList=order_number.split("/");
                  let newOrderNumber=orderNumberList[0]+"-RN/"+orderNumberList[1];
                  let fbkey=orderNumberList[0]+"_RN-"+orderNumberList[1]+"-"+item_id;

                  doele['order_number']=newOrderNumber;

                  doele['ordered_from']='web';
                  doele['ordered_by']=JSON.parse(sessionStorage.getItem('user'))[0]['email'];
                  doele['status']='Delivered';
                  doele['item_returned_qty']=""+driverOrdersList[z]['order_qty'];

                  doele['order_date']=formatOrderDate(currTimestamp);
                  doele['exp_delivery_date']=currTimestamp;

                  if(customerAdjustedPayment=="No"){
                    doele['order_amt']="-"+finalOrderAmount.toFixed(2);
                    doele['final_order_amt']="-"+finalOrderAmount.toFixed(2);
                    doele['net_order_amt']="-"+finalNetOrderAmount.toFixed(2);
                    doele['net_amt']="-"+finalNetOrderAmount.toFixed(2);
                    doele['total_amt']="-"+driverOrdersList[z]['total_amt'];
                    doele['cash_amt']="0";
                  }else if(customerAdjustedPayment=="Yes"){
                    doele['order_amt']="0"
                    doele['final_order_amt']="0"
                    doele['net_order_amt']="0";
                    doele['cash_amt']="0";
                    doele['net_amt']="0";
                    doele['total_amt']="0";
                  }

                  doele['cashback_redeemed']="0";
                  doele['order_disc']="0";

                  doele['address1']=retailerData['address1']?retailerData['address1']:"";
                  doele['address2']=retailerData['address2']?retailerData['address2']:"";
                  doele['agent_id']=retailerData['agent_id']?retailerData['agent_id']:"";
                  doele['city']=retailerData['city']?retailerData['city']:"";
                  doele['gst_number']=retailerData['gst_number']?retailerData['gst_number']:"";
                  doele['phone']=retailerData['phone']?retailerData['phone']:"";
                  doele['pincode']=retailerData['pincode']?retailerData['pincode']:"";
                  doele['shop_name']=retailerData['shop_name']?retailerData['shop_name']:"";
                  doele['latitude']=retailerData['latitude']?retailerData['latitude']:"";
                  doele['longitude']=retailerData['longitude']?retailerData['longitude']:"";
                  doele['asm']=retailerData['asm']?retailerData['asm']:"";
                  doele['asmphone']=retailerData['asmphone']?retailerData['asmphone']:"";
                  doele['salesman_name']=retailerData['salesman_name']?retailerData['salesman_name']:"";
                  doele['se_phone_number']=retailerData['se_phone_number']?retailerData['se_phone_number']:"";

                  delete doele['delivery_date'];
                  delete doele['delivery_date_conv'];
                  delete doele['processed_date'];
                  delete doele['revised_order_amt'];

                  let dohkey=`/${year}/${month}/${date}/9999/${fbkey}`;

                  driverordershistoryele[`${dohkey}`]=doele;
                }
              }
              console.log('driverordershistoryele');
              console.log(driverordershistoryele);

              //add in firebase
              let orrdersNewRef=fire.database().ref('Orders_New');
              orrdersNewRef.update(fbordersnewele,function(err){
                if(err){
                  alert("Error in placing order in Orders_New : "+err);
                }else{
                  let driverOrdersRef = fire.database().ref('Driver_Orders_History');
                  driverOrdersRef.update(driverordershistoryele,function(error){
                    if (error) {
                      alert('Error in placing order in Driver_Orders_History : '+error);
                    }else{
                      if(customerAdjustedPayment=="No"){
                        let walletele={};
                        walletele['order_id']=orderID;
                        walletele['retailer_id']=""+retailerID;
                        walletele['transaction_date']=transactionDate;
                        walletele['wallet_amt']=walletAmt;
                        let walletMasterRef=fire.database().ref(`Wallet_Master/${retailerID}/`);
                        let walletRef = walletMasterRef.push();
                        walletRef.set(walletele,function(errr){
                          if(errr){
                            alert("Error in adding amount to Wallet_Master : "+errr);
                          }else{
                            setOrderData([]);
                            setBackupOrderData([]);
                            setSearchOrderData([]);
                            setItemChecked({});
                            setOrdrStatus("");
                            setInitialOrderSelection(true);
                            setOrderNumber("");
                            setReturnOrderType("SELECT");
                            setReturnReason("SELECT");
                            setReturnItem("SELECT");
                            setCustomerAdjustedPayment("SELECT");
                            setOrderType("SELECT");
                            setActualOrderType("SELECT");
                            setReasonDropdownDisable(false);
                            let currdate=formatOrderDate(new Date());
                            setReturnDate(currdate);

                            alert(`Successfully placed the order. OrderNumber: ${orderID}`);
                          }
                        });

                      }else{
                        setOrderData([]);
                        setBackupOrderData([]);
                        setSearchOrderData([]);
                        setItemChecked({});
                        setOrdrStatus("");
                        setInitialOrderSelection(true);
                        setOrderNumber("");
                        setReturnOrderType("SELECT");
                        setReturnReason("SELECT");
                        setReturnItem("SELECT");
                        setOrderType("SELECT");
                        setActualOrderType("SELECT");
                        setCustomerAdjustedPayment("SELECT");
                        setReasonDropdownDisable(false);
                        let currdate=formatOrderDate(new Date());
                        setReturnDate(currdate);
                        alert(`Successfully placed the order. OrderNumber: ${orderID}`);
                      }
                    }
                  });
                }
              });
          }).catch((reterr)=>{
            alert("Error in fetching retailer data");
          })
        }
      }else{
        alert("Please select items");
      }
    }



    return (
      <>
      <NavBar/>
        <Navbar className="bg-light m-auto justify-content-center" style={{ paddingTop: 100,textAlign: "center"}}>
          <h3 style={{ marginLeft: "auto",marginRight: "auto" }}>Gate Pass / Customer Returns Orders</h3>
          <Button size="sm"  variant="warning" onClick={()=>{window.open("/viewreturnorders", "_blank")}}>Added Return Orders</Button>
          </Navbar>
          <Row style={{ display:"flex"  }}>
            <Col style={{ margin: "30px" }}>
            <InputGroup style={{ width:"300px",marginLeft: "auto",marginRight: "auto" }}>
              <InputGroup.Prepend>
              <InputGroup.Radio name="orderoption" checked={orderOption==="AddOrder"} onChange={(e) => { setOrderOption("AddOrder"); }}  />
              </InputGroup.Prepend>
              <InputGroup.Text > Add Order</InputGroup.Text>
            </InputGroup>
            </Col>
            <Col style={{ margin: "30px"}}>
            <InputGroup style={{width:"300px", marginLeft: "auto",marginRight: "auto" }}>
              <InputGroup.Prepend>
                <InputGroup.Radio name="orderoption" checked={orderOption==="ScheduleForDelivery"} onChange={(e) => { setOrderOption("ScheduleForDelivery"); getDeliveryOrdernumber(orderNumber); }} />
              </InputGroup.Prepend>
              <InputGroup.Text >Schedule For Delivery</InputGroup.Text>
            </InputGroup>
            </Col>
          </Row>
          {orderOption==="AddOrder"?(
            <div className="row" style={{paddingTop:20,width:"500px",marginLeft: "auto",marginRight: "auto"}}>
                <Form.Control style={{width:"400px", margin:"20"}} id="searchOrderNumber" className="searchBox" placeholder="Enter Parent Order Number" value={orderNumber} onChange={(e) => { setOrderNumber(e.target.value); }} />
                <Button size="sm" variant="warning" onClick={()=>{ viewRetailerOrders(); }}>Submit</Button>
            </div>
          ):null}

          {(orderOption==="AddOrder" && orderData.length>0)?(
            <div className="row" style={{paddingTop:20,marginLeft: "auto",marginRight: "auto"}}>
              <InputGroup size="sm" style={{marginLeft: "auto",marginRight: "auto",display:"flex" ,width:'25%' }}>
                <InputGroup.Prepend>
                  <InputGroup.Text> Type of Order </InputGroup.Text>
                </InputGroup.Prepend>
                <DropdownButton id="dropdown-basic-button" title={(orderType!="SELECT")?orderType:"SELECT"} size="sm" variant="secondary" >
                  <Dropdown.Item key="Short/UnDelivered (-C)" name="Short/UnDelivered (-C)" onClick={(e)=>{e.preventDefault();setOrderType(e.target.name);setActualOrderType("C");setReturnReason("SELECT");setCustomerReturnedItems("SELECT");setCustomerAdjustedPayment("SELECT"); }}>Short/UnDelivered (-C)</Dropdown.Item>
                  <Dropdown.Item key="Return Order For Delivery (-R )" name="Return Order For Delivery (-R )" onClick={(e)=>{e.preventDefault();setOrderType(e.target.name);setActualOrderType("R");setReturnReason("SELECT");setCustomerReturnedItems("SELECT");setCustomerAdjustedPayment("SELECT"); }}>Return Order For Delivery (-R )</Dropdown.Item>
                  <Dropdown.Item key="Returned To WareHouse (-RN)" name="Returned To WareHouse (-RN)" onClick={(e)=>{e.preventDefault();setOrderType(e.target.name);setActualOrderType("RN");setReturnReason("SELECT");setCustomerReturnedItems("SELECT");setCustomerAdjustedPayment("SELECT"); }}>Returned To WareHouse (-RN)</Dropdown.Item>
                </DropdownButton>
              </InputGroup>
            </div>
          ):null}

          {(orderOption==="AddOrder" && orderData.length>0 && actualOrderType=="R")?(
            <div className="row" style={{paddingTop:20,marginLeft: "auto",marginRight: "auto"}}>
              <InputGroup size="sm" style={{marginLeft: "auto",marginRight: "auto",display:"flex" ,width:'25%' }}>
                <InputGroup.Prepend>
                  <InputGroup.Text> Customer returned the items? </InputGroup.Text>
                </InputGroup.Prepend>
                <DropdownButton id="dropdown-basic-button" title={(customerReturnedItems!="SELECT")?customerReturnedItems:"SELECT"} size="sm" variant="secondary" >
                  <Dropdown.Item key="Yes" name="Yes" onClick={(e)=>{e.preventDefault();setCustomerReturnedItems(e.target.name); }}>Yes</Dropdown.Item>
                  <Dropdown.Item key="No" name="No" onClick={(e)=>{e.preventDefault();setCustomerReturnedItems(e.target.name); }}>No</Dropdown.Item>
                </DropdownButton>
              </InputGroup>
              <InputGroup size="sm" style={{marginLeft: "auto",marginRight: "auto",display:"flex" ,width:'35%' }}>
                <InputGroup.Prepend >
                  <InputGroup.Text> Reason </InputGroup.Text>
                </InputGroup.Prepend>
                <ReasonsDropdown />
              </InputGroup>
            </div>
          ):null}

          {(orderOption==="AddOrder" && orderData.length>0 && actualOrderType=="C")?(
            <div className="row" style={{paddingTop:20,marginLeft: "auto",marginRight: "auto"}}>
              <InputGroup size="sm" style={{marginLeft: "auto",marginRight: "auto",display:"flex" ,width:'35%' }}>
                <InputGroup.Prepend >
                  <InputGroup.Text> Reason </InputGroup.Text>
                </InputGroup.Prepend>
                <ReasonsDropdown />
              </InputGroup>
            </div>
          ):null}

          {(orderOption==="AddOrder" && actualOrderType=="RN" && orderData.length>0)?(
            <div className="row" style={{paddingTop:20,marginLeft: "auto",marginRight: "auto"}}>
              <InputGroup size="sm" style={{marginLeft: "auto",marginRight: "auto",display:"flex" ,width:'35%' }}>
                <InputGroup.Prepend>
                  <InputGroup.Text> Customer Adjusted the Payment?</InputGroup.Text>
                </InputGroup.Prepend>

                <DropdownButton id="dropdown-basic-button" title={(customerAdjustedPayment!="SELECT")?customerAdjustedPayment:"SELECT"} disabled={reasonDropdownDisable} size="sm" variant="secondary" >
                  <Dropdown.Item key="Yes" name="Yes" onClick={(e)=>{e.preventDefault();setCustomerAdjustedPayment(e.target.name);filterOrderData(e.target.name); }}>Yes</Dropdown.Item>
                  <Dropdown.Item key="No" name="No" onClick={(e)=>{e.preventDefault();setCustomerAdjustedPayment(e.target.name);filterOrderData(e.target.name); }}>No</Dropdown.Item>
                </DropdownButton>
                <InputGroup.Prepend >
                  <InputGroup.Text> Reason </InputGroup.Text>
                </InputGroup.Prepend>
                <ReasonsDropdown />
              </InputGroup>
            </div>):null}

          {(orderOption==="AddOrder" && orderData.length>0 && actualOrderType!="SELECT" && returnReason!="SELECT")?(<>
            <div className="table-wrapper" style={{marginTop:15,paddingBottom:40}}>
              <table className="table table-striped" style={{paddingLeft:50,paddingRight:50,textAlign:"center"}}>
                <RetailerOrdersTableHeader />
                <RetailerOrdersTableBody />
              </table>
            </div>

            <RetailerOrdersSummaryTable />
          </>):null}



          {(orderOption==="AddOrder" && orderData.length>0 && returnReason!="SELECT" && actualOrderType=="C")?(<>
            <Button style={{margin:"auto",display:"block"}} variant="warning" onClick={()=>{ placeShortOrder(); }}>Place Order</Button>
          </>):null}

          {(orderOption==="AddOrder" && orderData.length>0 && returnReason!="SELECT" && actualOrderType=="R" && customerReturnedItems!="SELECT")?(<>
            <Button style={{margin:"auto",display:"block"}} variant="warning" onClick={()=>{ placeReturnOrderForDelivery(); }}>Place Order</Button>
          </>):null}

          {(orderOption==="AddOrder" && orderData.length>0 && returnReason!="SELECT" && actualOrderType=="RN" && customerAdjustedPayment!="SELECT")?(<>
            {(validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email)))?(<InputGroup style={{paddingTop:20,margin:"auto",display:"flex",width:"300px",textAlign: "center"}}>
              <InputGroup.Prepend >
                <InputGroup.Text> Return Date</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control className="deliverydate" type="date" placeholder="Return Date" value={returnDate} onChange={(e) => { setReturnDate(formatOrderDate(e.target.value)); }} />
            </InputGroup>):null}
            <Button style={{margin:"auto",display:"block"}} variant="warning" onClick={()=>{ placeReturnOrderForWarehouse(); }}>Place Order</Button>
          </>):null}



            <div className="row" style={{paddingTop:20,width:"500px",marginLeft: "auto",marginRight: "auto"}}>
          {orderOption==="ScheduleForDelivery"?(
              <Form.Control style={{width:"400px", margin:"20"}}  id="searchOrderNumber" className="searchBox" placeholder="Enter Return Order Number" value={deliveryOrderNumber} onChange={(e) => { setDeliveryOrderNumber(e.target.value); }} />
            ):null}
            {(orderOption==="ScheduleForDelivery" && (deliveryOrderData.length==0 || deliveryOrderNumber==""))?(
              <Button size="sm" variant="warning" onClick={()=>{ viewRetailerOrdersForDelivery(); }}>Submit</Button>

              ):null}
          </div>

          {(orderOption==="ScheduleForDelivery" && deliveryOrderData.length>0 && deliveryOrderNumber.length>0)?(<>
            <div className="table-wrapper" style={{marginTop:15,paddingBottom:40}}>
              <table className="table table-striped" style={{paddingLeft:50,paddingRight:50,textAlign:"center"}}>
                <DeliveryRetailerOrdersTableHeader />
                <DeliveryRetailerOrdersTableBody />
              </table>
            </div>

            <DeliveryRetailerOrdersSummaryTable />

              <InputGroup size="sm"  style={{paddingTop:20,margin:"auto",display:"flex",width:"200px",textAlign: "center"}}>
                <InputGroup.Prepend >
                  <InputGroup.Text> Route </InputGroup.Text>
                </InputGroup.Prepend>
                <VehicleDropdown />
              </InputGroup>
              <InputGroup  style={{paddingTop:20,margin:"auto",display:"flex",width:"200px",textAlign: "center"}}>
                <InputGroup.Prepend >
                  <InputGroup.Text> Priority </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control className="priority" type="number" placeholder="Priority" value={vehiclePriority} onChange={(e) => { setVehiclePriority(e.target.value); }} />
              </InputGroup>
              {validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email))?(<InputGroup  style={{paddingTop:20,margin:"auto",display:"flex",width:"300px",textAlign: "center"}}>
                <InputGroup.Prepend >
                  <InputGroup.Text> Delivery Date</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control className="deliverydate" type="date" placeholder="Delivery Date" value={deliveryDate} onChange={(e) => { setDeliveryDate(formatOrderDate(e.target.value)); }} />
              </InputGroup>):null}
              <Button style={{margin:"auto",display:"block",padding:"20",marginTop:"20"}}  variant="warning" onClick={()=>{ placeDeliveryOrder(); }}>Assign to Route</Button>
          </>):null}

          {backDrop?(<Backdrop parentLoadStatus={backDrop}/>):(null)}
      </>
    )

}

export default AddReturnOrAdjustmentOrder
