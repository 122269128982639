import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import { Button, Select } from "@mantine/core";

import { StockDiscrepancyReportTable } from "./StockDiscrepancyReportTable";
import NavBar from "../../Navigation bar/Navbar";
import moment from "moment";
import Backdrop from "../../backdrop/backdrop";
import { toast } from "react-toastify";
import { fetchStockDiscrepancyReports } from "../../../services/InventoryMovements.services";
import ExportToExcel from "../Export/ExportToExcel";
import { warehouseNames } from "../../../Configs/mysqlconfig";

const Header = styled.div`
  display: flex;
  height: 18vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 1rem;
`;

const PageTitle = styled.div`
  font-weight: bolder;
  font-size: 2rem;
  text-align: center;
`;

const DateTitle = styled.div`
  text-align: center;
`;
const DateSelector = styled(DatePicker)`
  margin-left: 1rem;
  margin-right: 1rem;
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PageContainer = styled.div`
  width: 100%;
`;

const TableContainer = styled.div`
  width: 100%;
  padding: 1rem;
  flex: 1;
`;

const WarehouseSelectContainer = styled.div`
  margin: 0 1rem;
  align-self: flex-end;
`;

const FetchDataButton = styled(Button)`
  margin-top: 1rem;
`;

const ButtonStyle = styled(Button)`
  margin-right: 1rem;
`;

const ExportBtnStyles = styled.div`
  margin-top: 1rem;
`;

const mapWarehouseList = (names) => {
  return names.map(name => ({
    value: name,
    label: name
  }));
};

const warehouseList = mapWarehouseList(warehouseNames);

// const warehouseList = [
//   { value: "All", label: "All" },
//   { value: "Goa", label: "Goa" },
//   { value: "Vashi", label: "Vashi" },
//   { value: "Virar", label: "Virar" },
//   { value: "Wadala", label: "Wadala" },
//   { value: "Bhiwandi", label: "Bhiwandi" },
// ];



function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows

  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      if (row.values[id] && row.values[id] !== "") options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

function getFooterSum(data, field) {
  if (!data.rows || data.rows.length === 0 || !field) {
    return 0;
  }

  let footerSum = data.rows.reduce((sum, row) => {
    return Number(row.values[field]) + sum;
  }, 0);
  return footerSum.toFixed(1);
}

const StockDiscrepancyReport = ({ history }) => {
  const [tableData, setTableData] = useState([]);
  const [dateRangeStart, setDateRangeStart] = useState(new Date());
  const [dateRangeEnd, setDateRangeEnd] = useState(new Date());
  const [warehouse, setWarehouse] = useState(warehouseList[0].value);
  const [isLoading, setIsLoading] = useState(false);

  const greenColumnHeaderHighlight = "rgba(0,200,0,.6)";
  const redColumnHeaderHighlight = "rgba(200,0,0,.6)";

  const warehouseColumns = React.useMemo(
    () => [
      {
        Header: "Item Id",
        Footer: "Total :",
        label: "Item Id",
        accessor: "item_id",
        value: "item_id",
      },
      {
        Header: "Warehouse",
        label: "Warehouse",
        accessor: "warehouse",
        value: "warehouse",
      },
      {
        Header: "Item Description",
        label: "Item Description",
        accessor: "item_disc",
        value: "item_disc",
      },
      {
        Header: "Inventory",
        label: "Inventory",

        accessor: "inventory",
        value: "inventory",
      },
      {
        Header: "Stock Gain",
        label: "Stock Gain",
        accessor: "stock_gain",
        value: "stock_gain",
        filter: "equals",
      },
      {
        Header: "Stock Loss",
        label: "Stock Loss",
        accessor: "stock_loss",
        value: "stock_loss",
        filter: "equals",
      },
      {
        Header: "Shipped Qty",
        label: "Shipped Qty",

        accessor: "shipped_qty",
        value: "shipped_qty",
        filter: "equals",
      },
      {
        Header: "Received Qty",
        label: "Received Qty",
        accessor: "received_qty",
        value: "received_qty",
        filter: "equals",
      },
      {
        Header: "Variety",
        label: "Variety",
        accessor: "variety",
        value: "variety",
        filter: "equals",
      },
      {
        Header: "Bag Id",
        label: "Bag Id",
        accessor: "bag_id",
        value: "bag_id",
        filter: "equals",
      },

      {
        Header: "Approved At",
        label: "Approved At",
        accessor: "approved_at",
        value: "approved_at",
      },

      {
        Header: "Indicator",
        label: "Indicator",
        accessor: "indicator",
        value: "indicator",
      },
    ],
    []
  );
  const parentWarehouseColumns = React.useMemo(
    () => [
      {
        Header: "Item Id",
        label: "Item Id",
        Footer: "Total :",
        accessor: "item_id",
        value: "item_id",
      },
      {
        Header: "Route",
        label: "Route",
        accessor: "route",
        value: "route",
      },
      {
        Header: "Warehouse",
        label: "Warehouse",
        accessor: "warehouse",
        value: "warehouse",
      },
      {
        Header: "Item Description",
        label: "Item Description",
        accessor: "item_disc",
        value: "item_disc",
      },

      {
        Header: "Stock Gain",
        label: "Stock Gain",
        accessor: "stock_gain",
        value: "stock_gain",
        filter: "equals",
      },
      {
        Header: "Stock Loss",
        label: "Stock Loss",
        accessor: "stock_loss",
        value: "stock_loss",
        filter: "equals",
      },
      {
        Header: "Shipped Qty",
        label: "Shipped Qty",

        accessor: "shipped_qty",
        value: "shipped_qty",
        filter: "equals",
      },
      {
        Header: "Received Qty",
        label: "Received Qty",

        accessor: "received_qty",
        value: "received_qty",
        filter: "equals",
      },
      {
        Header: "Variety",
        label: "Variety",
        accessor: "variety",
        value: "variety",
        filter: "equals",
      },
      {
        Header: "Bag Id",
        label: "Bag Id",
        accessor: "bag_id",
        value: "bag_id",
        filter: "equals",
      },
      {
        Header: "Approved At",
        label: "Approved At",
        accessor: "approved_at",
        value: "approved_at",
      },

      {
        Header: "Indicator",
        label: "Indicator",
        accessor: "indicator",
        value: "indicator",
      },
    ],
    []
  );

  function getStockDiscrepancyTableData() {
    setIsLoading(true);
    fetchStockDiscrepancyReports(
      moment(dateRangeStart).format("YYYY-MM-DD"),
      moment(dateRangeEnd).format("YYYY-MM-DD"),
      warehouse
    )
      .then((result) => {
        const stockDiscrepancyData = result.data;

        const totalValues = {
          stock_loss: 0,
          stock_gain: 0,
          shipped_qty: 0,
          received_qty: 0,
          return_qty: 0,
        };

        stockDiscrepancyData.sort((a, b) => {
          console.log("check", a.approved_at, b.approved_at);

          let first =
            !a.approved_at || a.approved_at == "undefined"
              ? null
              : a.approved_at;
          let second =
            !b.approved_at || b.approved_at == "undefined"
              ? null
              : b.approved_at;

          if (first && !second) return -1;
          else if (!first && second) return 1;
          else if (!first && !second) return 0;
          first = moment(first, "YYYY-MM-DD HH:mm:ss");
          second = moment(second, "YYYY-MM-DD HH:mm:ss");

          return -first.diff(second, "seconds");
        });

        stockDiscrepancyData.forEach((item) => {
          item.inventory = (item.inventory || 0).toFixed(1);
          item.shipped_qty = Number(
            item.return_qty ?? item.shipped_qty ?? 0
          ).toFixed(1);
          item.stock_loss_gain = item.approved_qty ?? item.quantity;
          if (item.stock_loss_gain >= 0) item.stock_gain = item.stock_loss_gain;
          else if (item.stock_loss_gain < 0)
            item.stock_loss = item.stock_loss_gain;
          item.received_qty = Number(item.received_qty || 0).toFixed(1);
          totalValues.stock_gain += Number(item.stock_gain || 0);
          totalValues.stock_loss += Number(item.stock_loss || 0);
          totalValues.shipped_qty += Number(item.shipped_qty);
          totalValues.return_qty += Number(item.return_qty);
          totalValues.received_qty += Number(item.received_qty);
        });

        stockDiscrepancyData.push({
          item_id: "Total",
          stock_loss: totalValues.stock_loss,
          stock_gain: totalValues.stock_gain,
          shipped_qty: totalValues.shipped_qty,
          return_qty: totalValues.return_qty,
          received_qty: totalValues.received_qty,
        });

        setTableData(stockDiscrepancyData);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast(`Error : ${err}`, {
          type: toast.TYPE.ERROR,
        });
      });
  }

  useEffect(() => {
    if (warehouse && dateRangeStart && dateRangeEnd)
      getStockDiscrepancyTableData();
  }, [warehouse, dateRangeStart, dateRangeEnd]);

  return (
    <>
      <NavBar />
      <br />
      <br />
      <br />
      <PageContainer>
        <Header>
          <PageTitle> Stock Discrepancy Report</PageTitle>
          <DateContainer>
            <DateTitle>Start Date</DateTitle>
            <DateSelector
              selected={dateRangeStart}
              onChange={setDateRangeStart}
              maxDate={new Date()}
              // dateFormat="dd/MM/yyyy"
            />
          </DateContainer>
          <DateContainer>
            <DateTitle>End Date</DateTitle>
            <DateSelector
              selected={dateRangeEnd}
              onChange={setDateRangeEnd}
              minDate={new Date()}
              // dateFormat="dd/MM/yyyy"
            />
          </DateContainer>
          <WarehouseSelectContainer>
            <Select
              label="Warehouse"
              placeholder="Select"
              value={warehouse}
              data={warehouseList}
              onChange={setWarehouse}
            />
          </WarehouseSelectContainer>

          <ExportBtnStyles>
            <ExportToExcel
              sheet1={tableData}
              filename={"stock_discrepancy_report"}
              sheet1Headers={parentWarehouseColumns}
              sheet1Name={warehouse}
            />
          </ExportBtnStyles>
        </Header>

        <TableContainer>
          <StockDiscrepancyReportTable
            tableData={tableData}
            parentWarehouseColumns={
              warehouse === "Bhiwandi"
                ? parentWarehouseColumns
                : warehouseColumns
            }
            warehouse={warehouse}
            history={history}
          />
        </TableContainer>
      </PageContainer>
      {isLoading ? <Backdrop parentLoadStatus={isLoading} /> : null}
    </>
  );
};

export default StockDiscrepancyReport;
