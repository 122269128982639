import React, { useState, useEffect } from 'react'
import { Table, Form, Alert, Button,Modal } from 'react-bootstrap'
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import Backdrop from '../backdrop/backdrop';
import fire from '../../Configs/firebase';

function CashbackUpdatePopup({cashbackPopup,cashbackObj,cashbackEndFlag,...props}) {
  console.log(cashbackObj);
  const [loading,setLoading] = useState(false);
  const [retailerDataObj,setRetailerDataObj] = useState({});
  const [disableFlag,setDisableFlag] = useState(false);

  toast.configure({
      autoClose: 4000,
      draggable: true,
      pauseOnHover: true,
      position: toast.POSITION.TOP_LEFT,
  });

  useEffect(() => {
    setLoading(true);

    let retailerPhoneList=[];
    Object.keys(cashbackObj).map((orderID, index) => {
      let phone=cashbackObj[orderID].phone;
      retailerPhoneList.push(phone);
    });


    let firebaseDatabaseRef = fire.database().ref(`Retailer_Master_New`);

    let originalRetailerDataObj={};
    // fetch items parallely from firebase
    Promise.all(
      retailerPhoneList.map(phone => {
        console.log('send request '+phone);
        return firebaseDatabaseRef.child(phone).once('value')
        .then(snapshot => {
          console.log('got response '+phone);
          return snapshot;
        })
      })
    ).then((result) => {
      result.forEach(function(retailerResult) {
        let retailer_data = retailerResult.val();
        console.log(retailer_data);
        if(retailer_data){
          let ele={};
          ele['retailer_id']=retailer_data['retailer_id'];
          ele['wallet']=retailer_data['wallet'];
          let phone=retailer_data['phone'];
          ele['phone']=retailer_data['phone'];
          originalRetailerDataObj[phone]=ele;
        }
      });
      console.log("existing retailer wallet");
      console.log(originalRetailerDataObj);
      setRetailerDataObj(originalRetailerDataObj);
      setLoading(false);
    });
  },[])

  const updateCashbackValue = () => {
    console.log("updateCashbackValue");

    if(Object.keys(cashbackObj).length>0){
      setDisableFlag(true);

      console.log(cashbackObj);

      let newCashbackObj={};
      let orderNumberCashbackObj={};
      Object.keys(cashbackObj).map((orderID, index) => {
        let ele=cashbackObj[orderID];
        let orderNumber=cashbackObj[orderID].order_number;

        let delivered_amt=Number(cashbackObj[orderID].delivered_amt);

        let discount_value=cashbackObj[orderID].discount_value?Number(cashbackObj[orderID].discount_value):0;
        let discount_percent=cashbackObj[orderID].discount_percent?Number(cashbackObj[orderID].discount_percent):0;

        let orderDiscountAmount=0;
        if(discount_value){
          orderDiscountAmount=discount_value;
        }else if(discount_percent){
          orderDiscountAmount=(discount_percent/100)*delivered_amt;
        }

        if(!orderNumberCashbackObj[orderNumber]){
          orderNumberCashbackObj[orderNumber]=orderDiscountAmount;
        }else{
          let prevOrderCouponDiscount=orderNumberCashbackObj[orderNumber]?Number(orderNumberCashbackObj[orderNumber]):0;
          orderDiscountAmount=prevOrderCouponDiscount+orderDiscountAmount;
          orderNumberCashbackObj[orderNumber]=orderDiscountAmount;
        }

        ele['final_cashback_amount']=orderDiscountAmount;
        newCashbackObj[orderNumber]=ele;
      });
      console.log(newCashbackObj);

      let currentDate=new Date();
      let date=currentDate.getDate();
      let month=currentDate.getMonth()+1;
      let year=currentDate.getFullYear();
      let halfYear=year.toString().substr(-2);

      let formattedCurrDate=`${date}-${month}-${year}`;
      let monthNameList=["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
      let monthName=monthNameList[month-1];

      let timestamp=Date.now();

      let walletMasterObj={};
      let walletTransactionsObj={};
      let retailerMasterObj={};

      let multipleRetailerOrderCashbackObj={};

      let updatedOrderNumberStr="";
      let updatedOrderAmountStr="";

      Object.keys(newCashbackObj).map((orderID, index) => {
        let orderNumber=newCashbackObj[orderID].order_number;
        let retailer_id=newCashbackObj[orderID].retailer_id;
        let phone=newCashbackObj[orderID].phone;
        let delivered_amt=Number(newCashbackObj[orderID].delivered_amt);

        let orderKey=orderNumber.split("/")[0]+"-"+orderNumber.split("/")[1];

        let orderDiscountAmount=newCashbackObj[orderID].final_cashback_amount?Number(newCashbackObj[orderID].final_cashback_amount):0;

        walletTransactionsObj[`/${year}/${month}/${formattedCurrDate}/${orderKey}/order_number`]=orderNumber;
        walletTransactionsObj[`/${year}/${month}/${formattedCurrDate}/${orderKey}/amount`]=orderDiscountAmount.toFixed(2);
        walletTransactionsObj[`/${year}/${month}/${formattedCurrDate}/${orderKey}/timestamp`]=timestamp;

        walletMasterObj[`/${retailer_id}/${orderKey}/order_id`]=orderNumber;
        walletMasterObj[`/${retailer_id}/${orderKey}/retailer_id`]=retailer_id;
        walletMasterObj[`/${retailer_id}/${orderKey}/wallet_amt`]=orderDiscountAmount.toFixed(2);
        walletMasterObj[`/${retailer_id}/${orderKey}/transaction_date`]=`${date} ${monthName} ${halfYear}`;

        updatedOrderNumberStr=updatedOrderNumberStr+orderNumber+",";
        updatedOrderAmountStr=updatedOrderAmountStr+orderDiscountAmount+",";

        if(!multipleRetailerOrderCashbackObj[retailer_id]){
          multipleRetailerOrderCashbackObj[retailer_id]=orderDiscountAmount;
        }else{
          let prevRetailerOrderDiscount=multipleRetailerOrderCashbackObj[retailer_id]?Number(multipleRetailerOrderCashbackObj[retailer_id]):0;
          orderDiscountAmount=prevRetailerOrderDiscount+orderDiscountAmount;
          multipleRetailerOrderCashbackObj[retailer_id]=orderDiscountAmount;
        }

        orderDiscountAmount=orderDiscountAmount.toFixed(2);


        let existingRetailerWalletAmount=0;
        if(retailerDataObj){
          if(retailerDataObj[phone]){
            existingRetailerWalletAmount=retailerDataObj[phone].wallet?Number(retailerDataObj[phone].wallet):0;
          }
        }

        let finalRetailerWalletAmount=existingRetailerWalletAmount+Number(orderDiscountAmount);
        retailerMasterObj[`/${phone}/wallet`]=finalRetailerWalletAmount.toFixed(2);

      });

      console.log(walletTransactionsObj);
      console.log(walletMasterObj);
      console.log(retailerMasterObj);

      if(Object.keys(retailerMasterObj).length>0){
        fire.database().ref("Wallet_Master").update(walletMasterObj).then(()=>{
          fire.database().ref("Wallet_Transactions").update(walletTransactionsObj).then(() => {
            fire.database().ref("Retailer_Master_New").update(retailerMasterObj).then(() => {
              console.log("successfully processed cashback orders");
              updatedOrderNumberStr=updatedOrderNumberStr.slice(0,-1);
              updatedOrderAmountStr=updatedOrderAmountStr.slice(0,-1);
              props.onHide();
              props.callback(updatedOrderNumberStr,updatedOrderAmountStr);
            });
          });
        });
      }
    }else{
      toast("No cashback orders present.", { type: toast.TYPE.ERROR });
      props.onHide();
    }
  }

  const CashbackOrdersTableBody = () => {
    let rows = Object.keys(cashbackObj).map((orderID, index) => {
          return (
              <tr key={index}>
                  <td>{cashbackObj[orderID].order_number}</td>
                  <td>{cashbackObj[orderID].delivered_amt}</td>
                  <td>{cashbackObj[orderID].coupon_code}</td>
                  <td>{cashbackObj[orderID].discount_percent}</td>
                  <td>{cashbackObj[orderID].discount_value}</td>
              </tr>
          );
      });

      return (<><tbody>{rows}</tbody></>);
  }

    return (<>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Cashback Value Update
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Table>
                    <thead>
                      <tr>
                        <th>OrderNumber</th>
                        <th>Order Amount</th>
                        <th>Cashback Code</th>
                        <th>Cashback Percentage</th>
                        <th>Cashback Amount</th>
                      </tr>
                    </thead>
                    <CashbackOrdersTableBody />
                </Table>

                </Modal.Body>
                <Modal.Footer>
                    {cashbackEndFlag!=true?(<Button disabled={disableFlag} onClick={()=>{ updateCashbackValue(); }}>Process Cashback</Button>):null}
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
            {loading ? (<Backdrop parentLoadStatus={loading} />) : (null)}
        </>
    )
}

export default CashbackUpdatePopup
