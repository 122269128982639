import React, { useState, useEffect } from "react";
import { Form, Button, Modal, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import fire from "../../Configs/firebase";
import { service_url, token } from "../../Configs/mysqlconfig";
import moment from "moment";
import { toast } from "react-toastify";
import Select from "react-select";
import {
  redHighlightColor,
  greenHighlightColor,
  sortByTypeCategoryItemId,
} from "../Inventory/common/constants";

import Backdrop from "../backdrop/backdrop";
import { warehouseNamesTransit } from "../../Configs/mysqlconfig";

import "../../App.css";
const GLOBAL_PARENT_WAREHOUSE = "Bhiwandi";
const userCity = sessionStorage.getItem("userCity") || "";
const userType = sessionStorage.getItem("userType") || "";

const warehouseRetailerIds = {
  Goa: 9900089999,
  Wadala: 9900089996,
  Dhansar: 9900089997,
  MiraRoad: 9900089996,
  Bhiwandi: 9900089998,
};
const mapWarehouseList = (names) => {
  return names.map(name => ({
    value: name,
    label: name
  }));
};

const receivingWarehouseList = mapWarehouseList(warehouseNamesTransit);

// const receivingWarehouseList = [
//   { value: "Goa", label: "Goa" },
//   { value: "Vashi", label: "Vashi" },
//   { value: "Wadala", label: "Wadala" },
//   { value: "Mira Road", label: "Mira Road" },
//   { value: "Bhiwandi", label: "Bhiwandi" },
// ];

const DateSelector = styled(DatePicker)`
  margin-left: 1rem;
  margin-right: 1rem;
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WarehouseSelectContainer = styled.div`
  width: 15rem;
  margin: 0 1rem;
  align-self: flex-end;
`;

const CenterText = styled.div`
  text-align: center;
`;

function StockMovementCreationModal({ ...props }) {
  const [currentSelectedRoute, setCurrentSelectedRoute] = useState("");
  const [receivingDate, setReceivingDate] = useState();
  const [transitDate, setTransitDate] = useState();
  const [receivingWarehouse, setReceivingWarehouse] = useState(
    receivingWarehouseList[0]
  );

  const [productMasterDataRaw, setProductMasterDataRaw] = useState({});
  const [productMasterDataRawFresh, setProductMasterDataRawFresh] = useState(
    []
  );
  const [addTransitItemDropdownList, setAddTransitItemDropdownList] = useState(
    []
  );
  const [overallTransitSummaryDetails, setOverallTransitSummaryDetails] =
    useState([]);

  const [selectedItemId, setSelectedItemId] = useState("");
  const [backDrop, setBackDrop] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });

  const fetchProductDataFromDB = () => {
    return new Promise((resolve, reject) => {
      let freshData = {};
      let product_base_ref = fire.database().ref("Product_Master_New/F");
      product_base_ref.once(
        "value",
        (snapshot) => {
          let freshData = snapshot.val();
          product_base_ref = fire.database().ref("Product_Master_New/A");
          product_base_ref.once(
            "value",
            (snapshot) => {
              let GTData = snapshot.val();
              resolve([GTData, freshData]);
            },
            (err) => {
              reject("error occured while fetching data from firebase");
            }
          );
        },
        (err) => {
          reject("error occured while fetching data from firebase");
        }
      );
    });
  };

  const generateProductMasterData = async () => {
    const products = await fetchProductDataFromDB();
    // console.log("debug check", products);
    generateTransitItemsList(products[0]);
    setProductMasterDataRaw(products[0]);
    setProductMasterDataRawFresh(products[1]);
  };

  const generateTransitItemsList = (product_master) => {
    if (!product_master || Object.keys(product_master).length === 0) {
      return console.log("Function did not receive proper arguements");
    }
    const itemList = [];
    Object.keys(product_master).forEach((key, index) => {
      const product = product_master[key];

      const item_id = product.item_id;
      const long_description = product.long_description || "";
      const brand = product.brand || "";
      const active_status = product.active_status || "";

      if (!item_id || !long_description || !brand) return;
      const item = {
        value: item_id,
        label: `${item_id}-${brand}-${long_description}`,
      };
      active_status=='Y' && itemList.push(item);
    });

    setAddTransitItemDropdownList(itemList);
  };

  const init = () => {
    // setIsLoading(true);
    generateProductMasterData();
    setReceivingDate(new Date());
    setTransitDate(new Date());
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (receivingWarehouse.value === "Goa" || receivingWarehouse.value === "Bhiwandi") {
      generateTransitItemsList(productMasterDataRaw);
    } else {
      generateTransitItemsList(productMasterDataRawFresh);
    }
    setOverallTransitSummaryDetails([]);
    setSelectedItemId({});
  }, [receivingWarehouse]);

  const itemAlreadyInList = (item_id) => {
    const existingItems = overallTransitSummaryDetails;
    let itemAlreadyPresent = false;
    existingItems.forEach((item, index) => {
      if (Number(item_id) === Number(item.item_id)) {
        itemAlreadyPresent = true;
      }
    });

    return itemAlreadyPresent;
  };

  const removeItemFromSummaryDetails = (item_id, route, transit_id) => {
    return new Promise((resolve, reject) => {
      let url = `${service_url}/api/transit-route-item?token=${token}`;
      const data = { item_id, route, transit_id };
      // console.log("check data", data);

      fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": sessionStorage.getItem("x-access-token"),
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          toast(`Successfully removed item`, {
            type: toast.TYPE.SUCCESS,
          });

          resolve();
        })
        .catch((err) => {
          toast(`Unable to remove item`, {
            type: toast.TYPE.ERROR,
          });
          reject(err);
        });
    });
  };

  const addItemToSummaryDetails = (item_id) => {
    if (!item_id) {
      return console.log(" Error:  Missing arguements ");
    }

    if (itemAlreadyInList(item_id)) {
      toast(`This item is already present in the list`, {
        type: toast.TYPE.WARNING,
      });
      return;
    }
    const item = productMasterDataRaw[item_id];

    if (!item) {
      return console.log("Error : Unable to retrieve product info");
    }
    const category = item.category || "";
    const brand = item.brand || "";
    const variety = item.variety || "";
    const sub_category = item.sub_category;
    const long_disc = item.long_description || "";
    const item_disc = `${brand}-${long_disc}`;
    const {
      apmc_charge,
      gst_charge,
      dealer_price,
      transport_charge,
      total_amt,
      hsn_code,
      gst_per,
    } = item;

    const return_date = moment(receivingDate).format("YYYY-MM-DD");

    const itemToAdd = {
      item_id,
      category,
      variety,
      brand,
      sub_category,
      item_disc,
      apmc_charge,
      gst_charge,
      dealer_price,
      transport_charge,
      total_amt,
      hsn_code,
      gst_per,
      bgColor: greenHighlightColor,
      isNewItem: true,
    };
    console.log("ready to add data", itemToAdd);

    toast(`Successfully added new item`, {
      type: toast.TYPE.SUCCESS,
    });
    const existingRouteSummaryDetails = [...overallTransitSummaryDetails];
    existingRouteSummaryDetails.push(itemToAdd);
    setOverallTransitSummaryDetails(existingRouteSummaryDetails);
    setSelectedItemId({});
  };

  const updateApprovedItemsQty = () => {
    let itemsList = [];
    for (let transitItem of overallTransitSummaryDetails) {
      let item_id = transitItem.item_id;
      const shipped_qty = transitItem.qty_to_ship;

      if (!shipped_qty) {
        return toast(`Qty to Ship cannot be empty`, {
          type: toast.TYPE.ERROR,
        });
      }

      if (shipped_qty <= 0) {
        return toast(`Qty to Ship cannot be less than 1`, {
          type: toast.TYPE.ERROR,
        });
      }

      let item_disc = transitItem.item_disc || "";
      let category = transitItem.category || "";
      let sub_category = transitItem.sub_category || "";
      let brand = transitItem.brand || "";
      let variety = transitItem.variety || "";

      const {
        apmc_charge,
        gst_charge,
        dealer_price,
        transport_charge,
        total_amt,
        hsn_code,
        gst_per,
      } = transitItem;

      itemsList.push({
        item_id,
        shipped_qty,
        item_disc,
        category,
        brand,
        sub_category,
        variety,
        apmc_charge,
        gst_charge,
        dealer_price,
        transport_charge,
        total_amt,
        hsn_code,
        gst_per,
      });
    }

    let url = "";

    if (receivingWarehouse.value === "Goa" || receivingWarehouse.value === "Bhiwandi") {
      url = `${service_url}/api/transit-stock-movements?token=${token}`;
    } else {
      url = `${service_url}/api/transit-stock-transfer?token=${token}`;
      let formattedItems = {
        FRESH: {},
      };

      itemsList.forEach((item) => {
        item.order_qty = item.shipped_qty;
        formattedItems.FRESH[item.item_id] = item;
      });
      itemsList = formattedItems;
    }

    let data = {
      routeItemsData: itemsList,
      type: (receivingWarehouse.value != "Goa" &&  receivingWarehouse.value != "Bhiwandi") ? "Fresh" : "",
      source: receivingWarehouse.value != "Bhiwandi"?"Bhiwandi":"Goa",
      destination: receivingWarehouse.value,
      receiving_date: moment(receivingDate).format("YYYY-MM-DD"),
      delivery_date: moment(receivingDate).format("YYYY-MM-DD"),
      transit_date: moment(transitDate).format("YYYY-MM-DD"),
      retailer_id: warehouseRetailerIds[(receivingWarehouse.value).replace(/\s/g, '')].toString(),
      created_by: JSON.parse(sessionStorage.getItem(`user`))[0].email,
      created_at: new Date().toJSON().slice(0, 19).replace("T", " "),
    };

    console.log("check data", data);
    setIsLoading(true);

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": sessionStorage.getItem("x-access-token"),
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success == 1) {
          toast(`${result.message}`, { type: toast.TYPE.SUCCESS });
          setIsLoading(false);
          props.onHide();
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        } else {
          toast(`${result.message}`, { type: toast.TYPE.ERROR });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast(`Error in creating transit route records: ${err}`, {
          type: toast.TYPE.ERROR,
        });
      });
  };

  return (
    <div>
      <Modal
        {...props}
        id="transitReturnModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Create Stock Movement
          </Modal.Title>

          <DateContainer>
            <CenterText>Transit Date</CenterText>
            <DateSelector
              selected={transitDate}
              onChange={setTransitDate}
            />
          </DateContainer>
          <DateContainer>
            <CenterText>Receiving Date</CenterText>
            <DateSelector
              selected={receivingDate}
              onChange={setReceivingDate}
            />
          </DateContainer>

          <WarehouseSelectContainer>
            <CenterText>Target Warehouse</CenterText>
            <Select
              label="Warehouse"
              placeholder="Select"
              value={receivingWarehouse}
              options={receivingWarehouseList}
              onChange={setReceivingWarehouse}
            />
          </WarehouseSelectContainer>
        </Modal.Header>

        <Modal.Body>
          {overallTransitSummaryDetails &&
          overallTransitSummaryDetails.length > 0 ? (
            <>
              <div>
                <OverallTransitReturnSummaryTable
                  productMasterDataRaw={productMasterDataRaw}
                  overallTransitSummaryDetails={overallTransitSummaryDetails}
                  currentSelectedRoute={currentSelectedRoute}
                  removeItemFromSummaryDetails={removeItemFromSummaryDetails}
                  setOverallTransitSummaryDetails={
                    setOverallTransitSummaryDetails
                  }
                  init={init}
                  setBackDrop={setBackDrop}
                />
              </div>
            </>
          ) : (
            ""
          )}

          {userType === "Admin" || userCity !== GLOBAL_PARENT_WAREHOUSE ? (
            <>
              <Row style={{ width: "100%" }}>
                <Col style={{ width: "150%" }}>
                  <Select
                    options={addTransitItemDropdownList}
                    value={selectedItemId}
                    onChange={(val) => {
                      setSelectedItemId(val);
                    }}
                    isClearable={true}
                  />
                </Col>

                <Col style={{ width: "10%" }}>
                  <Button
                    onClick={() => {
                      if (selectedItemId && selectedItemId.value) {
                        addItemToSummaryDetails(selectedItemId.value);
                      } else {
                        toast(`Please select a valid item id from the list`, {
                          type: toast.TYPE.WARNING,
                        });
                      }
                    }}
                  >
                    Add item
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer>
          {userType === "Admin" || userCity !== "Bhiwandi" ? (
            <Button
              disabled={isLoading}
              onClick={() => {
                updateApprovedItemsQty();
              }}
            >
              Create
            </Button>
          ) : null}
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>

      {isLoading ? <Backdrop parentLoadStatus={isLoading} /> : null}
    </div>
  );
}

const TransitRoutesTableHeader = () => {
  return (
    <thead className="thead-light">
      <tr>
        <th>Item ID</th>
        <th>Item description || Type || Category</th>
        <th>Sub Category</th>
        <th>Brand</th>
        <th>Case Size</th>
        {userType === "Admin" || userCity !== GLOBAL_PARENT_WAREHOUSE ? (
          <th>Qty to Ship</th>
        ) : null}
      </tr>
    </thead>
  );
};

const TransitRoutesTableBody = ({
  routeProductData,
  overallTransitSummaryDetails,
  setOverallTransitSummaryDetails,
  currentSelectedRoute,
  removeItemFromSummaryDetails,
  transitID,
  init,
}) => {
  let itemsListData = routeProductData;

  let rows = itemsListData.map((row, index) => {
    return (
      <tr
        key={index}
        style={{ backgroundColor: row.bgColor ? row.bgColor : "" }}
      >
        <td>{row.item_id}</td>
        <td>{`${row.item_disc || ''} || ${row.variety || ''} || ${row.category || ""}`}</td>
        <td>{row.sub_category}</td>
        <td>{row.brand}</td>
        <td>{row.case_size}</td>
        <td>
          <Form.Control
            type="number"
            value={row.qty_to_ship}
            onBlur={(e) => {
              if (row.qty_to_ship === "") {
                e.target.value = "0";
              }
            }}
            onChange={(e) => {
              let _routeSumDetails = [...overallTransitSummaryDetails];
              _routeSumDetails[index].qty_to_ship = e.target.value;

              if (
                "stock_return_qty" in _routeSumDetails[index] &&
                _routeSumDetails[index].qty_to_ship !==
                  _routeSumDetails[index].stock_return_qty.toString()
              ) {
                _routeSumDetails[index].bgColor = redHighlightColor;
              } else if (
                "stock_return_qty" in _routeSumDetails[index] &&
                _routeSumDetails[index].qty_to_ship ===
                  _routeSumDetails[index].stock_return_qty.toString()
              ) {
                _routeSumDetails[index].bgColor = "white";
              }
              setOverallTransitSummaryDetails(_routeSumDetails);
            }}
          />
        </td>
      </tr>
    );
  });

  return (
    <>
      <tbody>{rows}</tbody>
    </>
  );
};

const TransitRoutesTable = ({
  routeProductData,
  overallTransitSummaryDetails,
  currentSelectedRoute,
  setOverallTransitSummaryDetails,
  removeItemFromSummaryDetails,
  transitID,
  init,
}) => {
  return (
    <>
      <table className="table table-striped">
        <TransitRoutesTableHeader />
        <TransitRoutesTableBody
          routeProductData={routeProductData}
          overallTransitSummaryDetails={overallTransitSummaryDetails}
          currentSelectedRoute={currentSelectedRoute}
          setOverallTransitSummaryDetails={setOverallTransitSummaryDetails}
          removeItemFromSummaryDetails={removeItemFromSummaryDetails}
          init={init}
          transitID={transitID}
        />
      </table>
    </>
  );
};

const OverallTransitReturnSummaryTable = (props) => {
  const productMasterDataRaw = props.productMasterDataRaw || {};
  const overallTransitSummaryDetails = props.overallTransitSummaryDetails;
  const overallLooseSummaryDetails = props.overallLooseSummaryDetails;
  const currentSelectedRoute = props.currentSelectedRoute;
  const removeItemFromSummaryDetails = props.removeItemFromSummaryDetails;
  const setOverallTransitSummaryDetails = props.setOverallTransitSummaryDetails;
  const setOverallLooseSummaryDetails = props.setOverallLooseSummaryDetails;
  const init = props.init;
  const setBackDrop = props.setBackDrop;

  setBackDrop(true);
  if (Object.keys(productMasterDataRaw).length === 0) return <></>;
  setBackDrop(false);
  let routeDataToShow = overallTransitSummaryDetails;
  overallTransitSummaryDetails.forEach((item, index) => {
    let data = item;
    data.item_disc = data.item_disc
      ? data.item_disc
      : productMasterDataRaw[item.item_id].long_description || "";
    data.variety = data.variety
      ? data.variety
      : productMasterDataRaw[item.item_id].variety || "";
    data.category = data.category
      ? data.category
      : productMasterDataRaw[item.item_id].category || "";
    data.case_size = data.case_size
      ? data.case_size
      : productMasterDataRaw[item.item_id].case_size || 1;
    overallTransitSummaryDetails[index] = data;
  });

  if (routeDataToShow.length > 0) {
    return (
      <div style={{ pageBreakBefore: "always" }}>
        <TransitRoutesTable
          routeProductData={routeDataToShow}
          currentSelectedRoute={currentSelectedRoute}
          overallTransitSummaryDetails={overallTransitSummaryDetails}
          removeItemFromSummaryDetails={removeItemFromSummaryDetails}
          setOverallTransitSummaryDetails={setOverallTransitSummaryDetails}
          init={init}
        />
      </div>
    );
  } else {
    return <></>;
  }
};

export default StockMovementCreationModal;
