import React, { useState, useEffect, useRef } from 'react'
import { Table, Form, Alert, Button,Modal, InputGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Select from 'react-select';
import Backdrop from '../backdrop/backdrop';
import fire from '../../Configs/firebase';
import { support_portal_url, orderingApiUrl } from "../../Configs/mysqlconfig";

function formatDate(date) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

function ShipModifyOrdersModal({dohData,deliveryDate,vehicleDropdownList,...props}) {
  const [loading, setLoading] = useState(false);
  const [routeOrderData, setRouteOrderData] = useState({});
  const [getAllocatedRoutes, setAllocatedRoutes] = useState({});
  const [getBagAllocatedRoutes, setBagAllocatedRoutes] = useState({});
  const [bagData, setBagData] = useState({});
  const [isShortOrderUpdated, setIsShortOrderUpdated] = useState(false);
  const [dataForHighValueUpdation,setHighValuesToUpdate] = useState([])

  console.log("vehicleDropdownList");
  console.log(vehicleDropdownList);

  let vehicleSet = new Set();
  for(let vehicle of vehicleDropdownList) {
    let veh = vehicle.value;
    if(!vehicleSet.has(veh)) {
      vehicleSet.add(veh);
    }
  }

  useEffect(()=>{
    if(vehicleDropdownList.length==0) {
      toast(`Please select route`, { type: toast.TYPE.ERROR });
      return;
    }
    setLoading(true);
    let formattedDeliveryDate = new Date(deliveryDate);
    console.log(formattedDeliveryDate);
    let year = formattedDeliveryDate.getFullYear();
    let month = formattedDeliveryDate.getMonth() + 1;
    let date = formattedDeliveryDate.getDate();

    let currDate = new Date();
    let curryear = currDate.getFullYear();
    let currmonth = currDate.getMonth() + 1;
    let curdate = currDate.getDate();
    let finalcurrdate = `${curdate}-${currmonth}-${curryear}`;

    Promise.all([
      fire.database().ref(`Picking_Orders_History/${curryear}/${currmonth}/${curdate}-${currmonth}-${curryear}`).once("value"),
      fire.database().ref(`Picking_Allocation_History/${curryear}/${currmonth}/${curdate}-${currmonth}-${curryear}`).once("value"),
      fire.database().ref(`Driver_Orders_Short_Orders/${year}/${month}/${date}-${month}-${year}`).once("value")
    ]).then(([pickingSnapshot, allocationSnapshot, shortOrdersSnapshot]) => {
      let pickingObj = pickingSnapshot.val() || {};
      let allocationObj = allocationSnapshot.val() || {};
      let shortOrdersObj = shortOrdersSnapshot.val() || {};
      let shortOrdersRouteList = Object.keys(shortOrdersObj) || [];
      let isShortRoutePresent = false;
      let presentShortRouteObj = {};

      const fbUpdatesForHighValue = []
      Object.keys(pickingObj).map((checkHighValueString)=> {

        if(checkHighValueString.includes("HIGH_VALUE"))
        {
          const route = checkHighValueString.split("-")[0];
          Object.keys(pickingObj[checkHighValueString]).map((highValues_itemId)=>
          {
  
            const itemKeysInDoh=dohData[route]
            Object.entries(itemKeysInDoh).map(([orderNumber,value])=>{
              const doh_itemId = orderNumber.split("-")[2]
              if(doh_itemId===highValues_itemId)
              {
                const packed_in_crate = pickingObj[checkHighValueString][highValues_itemId]?.packed_in_crate ? pickingObj[checkHighValueString][highValues_itemId].packed_in_crate : null
                const vlauesForDOH_highValue = {
                  route:route,
                  order_id : orderNumber,
                  packed_in_crate : packed_in_crate,
                  updation_key : `/${route}/${orderNumber}/crate_id/`
                }
                
                if(vlauesForDOH_highValue.packed_in_crate)
                {
                  fbUpdatesForHighValue.push(vlauesForDOH_highValue)
                }
                
              }
              
            })
          }
          )
        }
      })

      setHighValuesToUpdate(fbUpdatesForHighValue)

      for(let shortOrdersRoute of shortOrdersRouteList) {
        if(vehicleSet.has(shortOrdersRoute)) {
          isShortRoutePresent = true;
          presentShortRouteObj[shortOrdersRoute] = "Y";
        }
      }

      console.log(vehicleSet);
      console.log(isShortRoutePresent);
      Object.keys(allocationObj).map((key,index) => {
        let routeObj = allocationObj[key] || {};

        Object.keys(routeObj).map((rkey,rindex) => {
          let route = routeObj[rkey].picker_route;
          let bag_id_update = routeObj[rkey]["bag_id_update"]=="true";
          if(presentShortRouteObj[route]) {
            if(!bag_id_update) {
              presentShortRouteObj[route] = "N";
            }
          }

        })
      })

      let isShipModifyDone = true;
      Object.keys(presentShortRouteObj).map((key,index) => {
        if(presentShortRouteObj[key]=="N") {
          isShipModifyDone = false;
        }
      })

      if(isShortRoutePresent && isShipModifyDone) {
        let shortOrderData = {};
        Object.keys(shortOrdersObj).map((rkey,rindex) => {
          let routeObj = shortOrdersObj[rkey];
          let routeOrdersList = Object.values(routeObj) || [];

          shortOrderData[rkey] = routeOrdersList;
        })
        setIsShortOrderUpdated(isShortRoutePresent);
        setRouteOrderData(shortOrderData);
        setLoading(false);
        return;
      } else {
        let allocatedRouteObj = {};
        let bagRouteObj = {};
        let dbPickerObj = {};
        let bagPickerObj = {};
        let errorRoutes = "";
        let allocatedRoutesSet = new Set();
        Object.keys(allocationObj).map((key,index) => {
          let routeObj = allocationObj[key] || {};

          Object.keys(routeObj).map((rkey,rindex) => {
            let route = routeObj[rkey].picker_route;
            if(!allocatedRoutesSet.has(rkey) && rkey) {
              allocatedRoutesSet.add(rkey);
            }
            let picking_status = routeObj[rkey].status;
            let short_picking_update = routeObj[rkey].short_picking_update=="true"?true:false;
            let bag_id_update = routeObj[rkey].hasOwnProperty("bag_id_update");

            if(vehicleSet.has(route)) {
              if(picking_status!="ShortPicked" && picking_status!="Completed") {
                errorRoutes += `${rkey},`
              }

              if(picking_status=="ShortPicked" && !short_picking_update) {
                allocatedRouteObj[rkey] = "Y";

                if(!dbPickerObj[rkey]) {
                  dbPickerObj[rkey] = {};
                  dbPickerObj[rkey].route = rkey;
                  dbPickerObj[rkey].phone = key;
                }
              }

              if(rkey && rkey.includes("LOOSE")) {
                bagRouteObj[rkey] = "Y";
              }

              if(rkey) {
                if(!bagPickerObj[rkey]) {
                  bagPickerObj[rkey] = {};
                  bagPickerObj[rkey].route = rkey;
                  bagPickerObj[rkey].phone = key;
                }
              }

            }
          })
        })

        if(errorRoutes) {
          errorRoutes = errorRoutes.slice(0,-1);
          toast(`Ship modification is not allowed for status other than ShortPicked,Completed - ${errorRoutes}`, { type: toast.TYPE.ERROR });
          setLoading(false);
          return;
        }

        let notPickedItemQtyMap = {};
        let loosePackRouteMap = {};
        let orderBagIdMap = {};
        let bagIdError = "";
        let pickedStatusError = "";

        console.log(pickingObj);
        console.log("allocatedRouteObj");
        console.log(allocatedRouteObj);
        console.log("bagRouteObj");
        console.log(bagRouteObj);

        Object.keys(pickingObj).map((rkey,rindex) => {
          let routeObj = pickingObj[rkey] || {};
          // console.log('debug check', routeObj)

          if(allocatedRouteObj[rkey]=="Y") {
            Object.keys(routeObj).map((key,index) => {
              let plannedQty = routeObj[key]['modified_qty']?Number(routeObj[key]['modified_qty']):(routeObj[key]['order_qty']?Number(routeObj[key]['order_qty']):0)
              let fulfilledQty = Number(routeObj[key]['picked_qty']) || 0;
              let diffQty = plannedQty - fulfilledQty;
              let vehicle_no = routeObj[key]['vehicle_no'];

              if(Boolean(routeObj[key]['picked_status'])!=true) {
                pickedStatusError += `${key},`;
              }

              if(Boolean(routeObj[key]['picked_status'])==true && allocatedRoutesSet.has(rkey) && rkey) {
                if(!rkey.includes("LOOSE")) {
                  if(diffQty!=0) {
                    let productID = routeObj[key]['item_id'] || "";
                    let route = routeObj[key]['vehicle_no'] || "";
                    if(!notPickedItemQtyMap[route]){
                      notPickedItemQtyMap[route] = {};
                    }

                    if(!notPickedItemQtyMap[route][productID]){
                      notPickedItemQtyMap[route][productID] = 0;
                    }
                    notPickedItemQtyMap[route][productID] += diffQty;
                  }
                } else {
                  let route = routeObj[key]['vehicle_no'] || "";
                  let order_number = routeObj[key]['order_number'];

                  if(diffQty!=0) {
                    if(!loosePackRouteMap[route]) {
                      loosePackRouteMap[route] = {};
                    }

                    if(!loosePackRouteMap[route][key]) {
                      loosePackRouteMap[route][key] = {};
                    }
                    loosePackRouteMap[route][key].order_key = key;
                    loosePackRouteMap[route][key].order_number = order_number;
                    loosePackRouteMap[route][key].item_id = routeObj[key]['item_id'];
                    loosePackRouteMap[route][key].item_modified_qty = fulfilledQty;
                  }

                }

              }

            })
          }

          if(bagRouteObj[rkey]=="Y") {
            Object.keys(routeObj).map((key,index) => {
              let plannedQty = routeObj[key]['modified_qty']?Number(routeObj[key]['modified_qty']):(routeObj[key]['order_qty']?Number(routeObj[key]['order_qty']):0)
              let fulfilledQty = Number(routeObj[key]['picked_qty']) || 0;
              let diffQty = plannedQty - fulfilledQty;
              let vehicle_no = routeObj[key]['vehicle_no'];
              let route = routeObj[key]['vehicle_no'] || "";
              let order_number = routeObj[key]['order_number'];

              if(!routeObj[key]['packed_in_bag']) {
                bagIdError += `${order_number},`
              }

              if(Boolean(routeObj[key]['picked_status'])==true && allocatedRoutesSet.has(rkey) && rkey) {
                if(rkey.includes("LOOSE")) {
                  let bag_id = routeObj[key]['packed_in_bag'];

                  if(bag_id) {
                    let item_id = routeObj[key]['item_id'];
                    let order_keyy = `${order_number}-${item_id}`

                    if('split_bags' in routeObj[key]){

                       let bag_id_arr = []
                       Object.keys(routeObj[key]['split_bags']).forEach((split_bag_key) => {
                        bag_id_arr.push(`${split_bag_key}_${routeObj[key]['split_bags'][split_bag_key]}`)
                       })

                        bag_id = bag_id_arr.join(',')
                    }

                    orderBagIdMap[order_keyy] = bag_id;
                  }

                }
              }

            })
          }
        })

        if(bagIdError) {
          bagIdError = bagIdError.slice(0,-1);
          toast(`Bag id not present for orders - ${bagIdError}`, { type: toast.TYPE.ERROR });
          setLoading(false);
          return;
        }

        if(pickedStatusError) {
          pickedStatusError = pickedStatusError.slice(0,-1);
          toast(`Picked status not true for - ${pickedStatusError}`, { type: toast.TYPE.ERROR });
          setLoading(false);
          return;
        }

        console.log("allocatedRoutesSet");
        console.log(allocatedRoutesSet);

        let nonAllocatedRoutesSet = new Set();
        Object.keys(pickingObj).map((rkey,rindex) => {
          let routeObj = pickingObj[rkey] || {};

          Object.keys(routeObj).map((key,index) => {
            let vehicle_no = routeObj[key]['vehicle_no'];
            if(!allocatedRoutesSet.has(rkey) && rkey && vehicleSet.has(vehicle_no)) {
              if(!nonAllocatedRoutesSet.has(rkey)) {
                nonAllocatedRoutesSet.add(rkey);
              }
            }
          })
        })
        console.log("nonAllocatedRoutesSet");
        console.log(nonAllocatedRoutesSet);

        let nonAllocatedRoutesList = [...nonAllocatedRoutesSet];

        if(nonAllocatedRoutesList.length>0) {
          let nonAllocatedRoutesStr = "";

          for(let nonAllocatedRoute of nonAllocatedRoutesList) {
            nonAllocatedRoutesStr += `${nonAllocatedRoute},`
          }

          nonAllocatedRoutesStr = nonAllocatedRoutesStr.slice(0,-1);
          toast(`Non allocated routes are - ${nonAllocatedRoutesStr}`, { type: toast.TYPE.ERROR });
          setLoading(false);
          return;
        }



        console.log("notPickedItemQtyMap");
        console.log(notPickedItemQtyMap);

        generateShipModifyOrdersData(notPickedItemQtyMap, dbPickerObj, loosePackRouteMap, orderBagIdMap, bagPickerObj);
      }
    }).catch((err) => {
      setLoading(false);
      alert(err);
    })
  },[])

  const generateShipModifyOrdersData = (notPickedItemQtyMap, dbPickerObj, loosePackRouteMap, orderBagIdMap, bagPickerObj) => {

    console.log("loosePackRouteMap");
    console.log(loosePackRouteMap);

    Promise.all([
      fetch(`${orderingApiUrl}/offers?token=eyJhbGciOiJIUzI1NiIsInR5cCI6Ik`).then(response=>response.json()),
      fire.database().ref("Offers_Custom_Level").once('value'),
      fetch(`${orderingApiUrl}/retailer-group-details?token=eyJhbGciOiJIUzI1NiIsInR5cCI6Ik&group_id=prime`).then(response=>response.json())
    ]).then(([customerOffersData, customofferssnap, userData]) => {
      let customOffersObj=customofferssnap.hasChildren()?customofferssnap.val():{};
      let primeUserList = userData.data || [];
      let offersObj = {};

      if(customerOffersData.success==1) {
        let customOffers = customerOffersData['data'] || [];

        for(let customOffer of customOffers) {
          let coupon_code = customOffer.coupon_code;
          offersObj[coupon_code] = customOffer;
        }
      }

      let errorList = [];
      let routeShipModifyOrderDetailsObj = {};
      let driverOrdersHistoryObj = JSON.parse(JSON.stringify(dohData));

      Object.keys(notPickedItemQtyMap).map((routekey,routeindex) => {
        let routeNotPickedItemsObj = notPickedItemQtyMap[routekey] || {};

        // find shipped/processed orders for each item
        let shippedOrdersItemQtyMap = {};
        let primeShippedOrdersItemQtyMap = {};
        let routeOrders = dohData[routekey] || {};
        Object.keys(routeOrders).map((okey,oindex) => {
          let status = routeOrders[okey].status || "";
          let order_number = routeOrders[okey].order_number;
          let item_id = routeOrders[okey].item_id;
          let orderQty = Number(routeOrders[okey].order_qty) || 0;

          if((status.includes("Shipped") || status=="Processed") && !order_number.includes("R")){
            if(primeUserList.includes(String(routeOrders[okey].retailer_id))) {
              if(!primeShippedOrdersItemQtyMap[item_id]){
                primeShippedOrdersItemQtyMap[item_id] = {};
              }

              primeShippedOrdersItemQtyMap[item_id][order_number] = orderQty;
            } else {
              if(!shippedOrdersItemQtyMap[item_id]){
                shippedOrdersItemQtyMap[item_id] = {};
              }

              shippedOrdersItemQtyMap[item_id][order_number] = orderQty;
            }
          }
        })

        console.log("routekey : " + routekey);
        console.log(shippedOrdersItemQtyMap);
        console.log("primeShippedOrdersItemQtyMap");
        console.log(primeShippedOrdersItemQtyMap);

        // get orders list to modify
        Object.keys(routeNotPickedItemsObj).map((key,index) => {
          let productID = key;
          let leftoverProductQty = Number(routeNotPickedItemsObj[key]) || 0;
          console.log(`${productID} : leftoverProductQty - ${leftoverProductQty}`);

          if(leftoverProductQty>0){
            if(!shippedOrdersItemQtyMap[productID] && !primeShippedOrdersItemQtyMap[productID]){
              toast(`No shipped or processed order for route - ${routekey}, item ${productID}`, { type: toast.TYPE.ERROR });
              errorList.push(`No shipped or processed order for route - ${routekey}, item ${productID}`);
            }else{
              let itemShippedOrdersObj = shippedOrdersItemQtyMap[productID] || {};
              console.log(itemShippedOrdersObj);

              Object.keys(itemShippedOrdersObj).map((okey,oindex) => {
                let orderNumber = okey;
                let orderQty = Number(itemShippedOrdersObj[okey]) || 0;

                if(leftoverProductQty>=0){

                  let driverOrders = dohData[routekey] || {};

                  Object.keys(driverOrders).map((ordkey,ordindex) => {
                    if(driverOrders[ordkey].order_number!=orderNumber){
                      return;
                    }

                    if(Number(driverOrders[ordkey].item_id)==Number(productID)){
                      let itemModifiedQty = orderQty;
                      if(orderQty>=leftoverProductQty){
                        itemModifiedQty = orderQty-leftoverProductQty;
                        leftoverProductQty = 0;
                      } else {
                        // when order qty is less than leftover qty
                        itemModifiedQty = 0;
                        leftoverProductQty -= orderQty;
                      }

                      let itemele = driverOrders[ordkey];
                      itemele.item_modified_qty = itemModifiedQty;
                      driverOrdersHistoryObj[routekey][ordkey] = itemele;


                      let orderele = {};
                      orderele['order_number'] = orderNumber;
                      orderele['item_id'] = productID;
                      orderele['order_qty'] = orderQty;
                      orderele['item_modified_qty'] = itemModifiedQty;
                      orderele['coupon_code'] = driverOrders[ordkey].coupon_code || "";
                      orderele['total_amt'] = Number(driverOrders[ordkey].total_amt) || 0;
                      orderele['image_url'] = driverOrders[ordkey].image_url || "";
                      orderele['item_disc'] = driverOrders[ordkey].item_disc || "";
                      orderele['variety'] = driverOrders[ordkey].variety || "";

                      if(orderQty!=itemModifiedQty) {
                        if(!routeShipModifyOrderDetailsObj[routekey]) {
                          routeShipModifyOrderDetailsObj[routekey] = [];
                        }
                        routeShipModifyOrderDetailsObj[routekey].push(orderele);
                      }
                    }
                  })



                }
              })


            }

            // handle prime customers

            if(leftoverProductQty>0 && primeShippedOrdersItemQtyMap[productID]){
                let itemShippedOrdersObj = primeShippedOrdersItemQtyMap[productID];
                console.log(itemShippedOrdersObj);

                Object.keys(itemShippedOrdersObj).map((okey,oindex) => {
                  let orderNumber = okey;
                  let orderQty = Number(itemShippedOrdersObj[okey]) || 0;

                  if(leftoverProductQty>=0){

                    let driverOrders = dohData[routekey] || {};

                    Object.keys(driverOrders).map((ordkey,ordindex) => {
                      if(driverOrders[ordkey].order_number!=orderNumber){
                        return;
                      }

                      if(Number(driverOrders[ordkey].item_id)==Number(productID)){
                        let itemModifiedQty = orderQty;
                        if(orderQty>=leftoverProductQty){
                          itemModifiedQty = orderQty-leftoverProductQty;
                          leftoverProductQty = 0;
                        } else {
                          // when order qty is less than leftover qty
                          itemModifiedQty = 0;
                          leftoverProductQty -= orderQty;
                        }

                        let itemele = driverOrders[ordkey];
                        itemele.item_modified_qty = itemModifiedQty;
                        driverOrdersHistoryObj[routekey][ordkey] = itemele;


                        let orderele = {};
                        orderele['order_number'] = orderNumber;
                        orderele['item_id'] = productID;
                        orderele['order_qty'] = orderQty;
                        orderele['item_modified_qty'] = itemModifiedQty;
                        orderele['coupon_code'] = driverOrders[ordkey].coupon_code || "";
                        orderele['total_amt'] = Number(driverOrders[ordkey].total_amt) || 0;
                        orderele['image_url'] = driverOrders[ordkey].image_url || "";
                        orderele['item_disc'] = driverOrders[ordkey].item_disc || "";
                        orderele['variety'] = driverOrders[ordkey].variety || "";

                        if(orderQty!=itemModifiedQty) {
                          if(!routeShipModifyOrderDetailsObj[routekey]) {
                            routeShipModifyOrderDetailsObj[routekey] = [];
                          }
                          routeShipModifyOrderDetailsObj[routekey].push(orderele);
                        }
                      }
                    })



                  }
                })


            }

          }
        })

        console.log("routeShipModifyOrderDetailsObj");
        console.log(routeShipModifyOrderDetailsObj);

      })

      console.log("driverOrdersHistoryObj");
      console.log(driverOrdersHistoryObj);

      // handle loose pack orders
      Object.keys(loosePackRouteMap).map((rkey,rindex) => {
        let routeNotPickedItemsObj = loosePackRouteMap[rkey] || {};

        Object.keys(routeNotPickedItemsObj).map((okey,oindex) => {
          let orderNotPickedObj = routeNotPickedItemsObj[okey] || {};
          let order_key = orderNotPickedObj.order_key;
          let route = rkey;
          let order_number = orderNotPickedObj.order_number;
          let item_modified_qty = orderNotPickedObj.item_modified_qty;

          let driverOrders = driverOrdersHistoryObj[route] || {};
          let itemele = driverOrders[order_key];

          if(itemele) {
            itemele.item_modified_qty = item_modified_qty;
            driverOrdersHistoryObj[route][order_key] = itemele;

            let orderele = {};
            orderele['order_number'] = order_number;
            orderele['item_id'] = orderNotPickedObj.item_id;
            orderele['order_qty'] = Number(itemele.order_qty) || 0;
            orderele['item_modified_qty'] = item_modified_qty;
            orderele['coupon_code'] = itemele.coupon_code || "";
            orderele['total_amt'] = Number(itemele.total_amt) || 0;
            orderele['image_url'] = itemele.image_url || "";
            orderele['item_disc'] = itemele.item_disc || "";
            orderele['variety'] = itemele.variety || "";

            if(orderele['order_qty']!=item_modified_qty) {
              if(!routeShipModifyOrderDetailsObj[route]) {
                routeShipModifyOrderDetailsObj[route] = [];
              }
              routeShipModifyOrderDetailsObj[route].push(orderele);
            }
          } else {
            toast(`Order key : ${route}/${order_key} not present in doh`, { type: toast.TYPE.ERROR });
          }

        })
      })

      console.log("final driverOrdersHistoryObj");
      console.log(driverOrdersHistoryObj);

      console.log("-----hjgdshgfgejhdsggfsdjhsgfd------------")
      console.log(routeShipModifyOrderDetailsObj);

      let finalRouteShipModifyOrderDetailsObj = {};
      // find revised order disc and amt, offers handling
      Object.keys(routeShipModifyOrderDetailsObj).map((rkey,rindex) => {
        let routeModifiedOrders = routeShipModifyOrderDetailsObj[rkey] || [];

        for(let routeModifiedOrder of routeModifiedOrders){
          let orderNumber = routeModifiedOrder.order_number;
          let route = rkey;

          if(!finalRouteShipModifyOrderDetailsObj[route]){
            finalRouteShipModifyOrderDetailsObj[route] = [];
          }

          let revisedOrderAmtList = revisedAmt(driverOrdersHistoryObj[route], orderNumber);
          let revisedOrderAmt = (revisedOrderAmtList[0]).toFixed(2);
          let modifiedItemKeyList = revisedOrderAmtList[1];
          let order_id = revisedOrderAmtList[2];

          let originalOrderDisc = Number(driverOrdersHistoryObj[route][order_id].order_disc) || 0;
          let couponCodeValue = driverOrdersHistoryObj[route][order_id].coupon_code || "";
          let revisedOrderDisc = 0;

          let orderele = routeModifiedOrder;
          orderele['revised_order_amt'] = revisedOrderAmt;

          //discount offers
          if(originalOrderDisc>0){
            //discount offers
            revisedOrderDisc=revisedOrderDiscount(route, driverOrdersHistoryObj[route], order_id, revisedOrderAmt, couponCodeValue, offersObj, customOffersObj);
            orderele['order_disc'] = originalOrderDisc;
            orderele['revised_order_disc'] = revisedOrderDisc;
          }

          if(couponCodeValue){
            // freeproduct offers
            let freeProductOfferDiscrepanciesList = {};

            if(Object.keys(offersObj).length>0 || Object.keys(customOffersObj).length>0){
              let couponCodeValueList = couponCodeValue.split(",");

              //supports only 1 freeproduct offer
              for(let x=0;x<couponCodeValueList.length;x++){
                let singleCouponCode = couponCodeValueList[x] || "";
                let orderOfferObj = {};

                Object.keys(customOffersObj).map((customofferkey,customofferindex)=>{
                  let offer_coupon_code=customOffersObj[customofferkey].coupon_code;
                  let mode=customOffersObj[customofferkey].mode?customOffersObj[customofferkey].mode:"";
                  mode=mode.toLowerCase();

                  if(mode=="freeproduct" && singleCouponCode==offer_coupon_code){
                    orderOfferObj = customOffersObj[customofferkey] || {};
                  }
                });

                Object.keys(offersObj).map((offerkey,offerindex)=>{
                  let offer_coupon_code=offersObj[offerkey].coupon_code;
                  let mode=offersObj[offerkey].mode?offersObj[offerkey].mode:"";
                  mode=mode.toLowerCase();

                  if(mode=="freeproduct" && singleCouponCode==offer_coupon_code){
                    orderOfferObj = offersObj[offerkey] || {};
                  }
                });

                if(Object.keys(orderOfferObj).length>0){
                  // coupon present
                  let existingSugarQuantity;
                  let orderKey;
                  let numSugarBags;

                  let mov = orderOfferObj["minimum_order_value"];
                  let offer_category = orderOfferObj["category"];
                  let sub_category = orderOfferObj["sub_category"] || "";
                  let minimum_buy_product = orderOfferObj['minimum_buy_product'] || "";
                  let excluded_category = orderOfferObj["excluded_category"] || "";
                  let offer_excluded_products = orderOfferObj["excluded_products"] || {};
                  let minUnit = orderOfferObj["minimum_product_unit"]?orderOfferObj["minimum_product_unit"]:1;
                  let revisedAmtWithExcludedProductsList = revisedAmtForOffersWithExcludedProducts(driverOrdersHistoryObj[route], order_id,offer_excluded_products,offer_category,sub_category,minimum_buy_product,excluded_category);
                  let revisedOrderAmount = (revisedAmtWithExcludedProductsList[0]).toFixed(2);
                  revisedOrderAmount = Number(revisedOrderAmount);

                  let productCount = revisedAmtWithExcludedProductsList[1];
                  let productWeight = revisedAmtWithExcludedProductsList[2];

                  let minimum_category_unit = Number(orderOfferObj.minimum_category_unit) || 0;
                  let minimum_category_weight = Number(orderOfferObj.minimum_category_weight) || 0;
                  let minimum_category_value = Number(orderOfferObj.minimum_category_value) || 0;

                  let minimum_buy_product_unit = Number(orderOfferObj.minimum_buy_product_unit) || 0;
                  let minimum_buy_product_value = Number(orderOfferObj.minimum_buy_product_value) || 0;
                  let minimum_buy_product_weight = Number(orderOfferObj.minimum_buy_product_weight) || 0;
                  let offer_product_unit = Number(orderOfferObj["offer_product_unit"]) || 1;

                  let numFreeProductUnit = Math.floor(revisedOrderAmount / mov);

                  if(minimum_buy_product) {
                    if(minimum_buy_product_unit>0){
                      if(productCount>=minimum_buy_product_unit) {
                        numFreeProductUnit = Math.floor(productCount/minimum_buy_product_unit);
                      } else {
                        numFreeProductUnit = 0;
                      }
                    }else if(minimum_buy_product_weight>0) {
                      if(productWeight>=minimum_buy_product_weight) {
                        numFreeProductUnit = Math.floor(productWeight/minimum_buy_product_weight);
                      } else {
                        numFreeProductUnit = 0;
                      }

                    }else{
                      if(revisedOrderAmount>=minimum_buy_product_value) {
                        numFreeProductUnit = Math.floor(revisedOrderAmount/minimum_buy_product_value)
                      } else {
                        numFreeProductUnit = 0;
                      }

                    }
                  } else if(offer_category){
                    if(minimum_category_unit>0){
                      if(productCount>=minimum_category_unit) {
                        numFreeProductUnit = Math.floor(productCount/minimum_category_unit);
                      } else {
                        numFreeProductUnit = 0;
                      }
                    } else if(minimum_category_weight>0) {
                      if(productWeight>=minimum_category_weight) {
                        numFreeProductUnit = Math.floor(productWeight/minimum_category_weight);
                      } else {
                        numFreeProductUnit = 0;
                      }
                    } else{
                      if(revisedOrderAmount>=minimum_category_value) {
                        numFreeProductUnit = Math.floor(revisedOrderAmount/minimum_category_value);
                      } else {
                        numFreeProductUnit = 0;
                      }
                    }

                  }

                  let freeSugarItem = orderOfferObj["offer_product"] || orderOfferObj["item_id"];

                  numSugarBags = numFreeProductUnit*offer_product_unit;
                  if(orderOfferObj["maximum_product_unit"]!=null){
                    numSugarBags = Math.min(numSugarBags,Number(orderOfferObj["maximum_product_unit"]));
                  }
                  numSugarBags = Math.floor(numSugarBags);

                  numSugarBags =
                    numSugarBags >= minUnit
                      ? numSugarBags
                      : 0;
                    
                  if(freeSugarItem){
                    orderKey=`${order_id.split('-')[0]}-${order_id.split('-')[1]}-${freeSugarItem}`;

                    if(driverOrdersHistoryObj[route][orderKey]){
                      existingSugarQuantity = driverOrdersHistoryObj[route][orderKey]["item_modified_qty"]?Number(driverOrdersHistoryObj[route][orderKey]["item_modified_qty"]):(
                        driverOrdersHistoryObj[route][orderKey]["order_qty"]?Number(driverOrdersHistoryObj[route][orderKey]["order_qty"]):0
                      );

                      let itemModifiedQty = driverOrdersHistoryObj[route][orderKey]["item_modified_qty"]?Number(driverOrdersHistoryObj[route][orderKey]["item_modified_qty"]):0;
                      let orderQty = driverOrdersHistoryObj[route][orderKey]["order_qty"]?Number(driverOrdersHistoryObj[route][orderKey]["order_qty"]):0;

                      let offerele = {};
                      offerele.existingSugarQuantity = existingSugarQuantity;
                      offerele.orderKey = orderKey;
                      offerele.numSugarBags = numSugarBags;
                      offerele.singleCouponCode = singleCouponCode;
                      offerele.freeProduct = freeSugarItem;
                      offerele.item_deleted_qty = orderQty - itemModifiedQty;

                      if(freeProductOfferDiscrepanciesList[freeSugarItem]){
                        let prevOfferSameFreeItemQty = Number(freeProductOfferDiscrepanciesList[freeSugarItem].numSugarBags) || 0;
                        offerele.numSugarBags += prevOfferSameFreeItemQty;
                      }

                      freeProductOfferDiscrepanciesList[freeSugarItem] = offerele;
                    }

                  }
                }
              }

              console.log("freeProductOfferDiscrepanciesList");
              console.log(freeProductOfferDiscrepanciesList);

              if(Object.keys(freeProductOfferDiscrepanciesList).length>0){
                let freeProductList = [];
                Object.keys(freeProductOfferDiscrepanciesList).map((offer_key,offer_index) => {
                  let offerErr = freeProductOfferDiscrepanciesList[offer_key] || {};
                  if(offerErr.numSugarBags!=offerErr.existingSugarQuantity && offerErr.existingSugarQuantity!==undefined){
                    freeProductList.push(offerErr)
                  }
                })

                if(freeProductList.length>0){
                  orderele['freeproductlist'] = freeProductList;
                }
              }

              finalRouteShipModifyOrderDetailsObj[route].push(orderele);


            }else{
              finalRouteShipModifyOrderDetailsObj[route].push(orderele);
            }
            // freeproduct offer code ends

          }else{
            //normal order
            finalRouteShipModifyOrderDetailsObj[route].push(orderele);
          }
        }

      })

      console.log(finalRouteShipModifyOrderDetailsObj);
      setLoading(false);
      setRouteOrderData(finalRouteShipModifyOrderDetailsObj);
      setAllocatedRoutes(dbPickerObj);
      setBagAllocatedRoutes(bagPickerObj);
      setBagData(orderBagIdMap);
    }).catch((err) => {
      setLoading(false);
      alert(err);
    })
  }



  const modifyItemQtyInDoh = () => {
    console.log("modifyItemQtyInDoh");
    let fbBulkObj = {};
    let shortOrdersObj = {};

    const highValueDOH_Updation = () => {
      let todayDate = new Date(deliveryDate);
      let year = todayDate.getFullYear();
      let month = todayDate.getMonth() + 1; // JavaScript months are 0-indexed
      let date = todayDate.getDate();
      let finaldate = `${date}-${month}-${year}`;
  
      const updateHighValuePromiseList = dataForHighValueUpdation.map((updateObj) => {
     
        const updateData = {
          [updateObj.updation_key]: updateObj.packed_in_crate
        };
  
        return fire.database()
          .ref(`Driver_Orders_History/${year}/${month}/${finaldate}`)
          .update(updateData);
      });

  
      Promise.all(updateHighValuePromiseList)
        .then(() => {
          console.log("All high value updates completed successfully");
        })
        .catch((error) => {
          console.error("Error updating high value items:", error);
        });
    };
  
    highValueDOH_Updation();
  
    if(!isShortOrderUpdated) {
      Object.keys(routeOrderData).map((rkey,rindex) => {
        let route = rkey;
        let vehicleOrders = routeOrderData[rkey] || [];

        for(let vehicleOrder of vehicleOrders){
          let orderNumber = vehicleOrder.order_number;
          let dohRouteOrders = dohData[route] || {};
          Object.keys(dohRouteOrders).map((dokey,doindex) => {
            if(dohRouteOrders[dokey].order_number==orderNumber){
              fbBulkObj[`/${route}/${dokey}/status`] = "Shipped-Modified";
              fbBulkObj[`/${route}/${dokey}/revised_order_amt`] = "" + (Number(vehicleOrder.revised_order_amt) || 0);

              if(vehicleOrder.hasOwnProperty("revised_order_disc")){
                fbBulkObj[`/${route}/${dokey}/revised_order_disc`] = "" + (Number(vehicleOrder.revised_order_disc) || 0);
              }

              let modifiedItem = vehicleOrder.item_id;

              if(Number(dohRouteOrders[dokey].item_id)==Number(modifiedItem)){
                fbBulkObj[`/${route}/${dokey}/item_primary_reason`] = "Bill Modified";
                fbBulkObj[`/${route}/${dokey}/item_reason`] = "Short Supply";
                fbBulkObj[`/${route}/${dokey}/item_modified_qty`] = "" + (Number(vehicleOrder.item_modified_qty) || 0);
              }
            }
          })

          let orderKeyy = orderNumber.replaceAll("/","-");
          shortOrdersObj[`/${route}/${orderKeyy}-${vehicleOrder.item_id}`] = vehicleOrder;

          if(vehicleOrder.hasOwnProperty("freeproductlist")){
            let freeProducts = vehicleOrder.freeproductlist || [];

            for(let freeProduct of freeProducts){
              let orderKey = freeProduct.orderKey;
              fbBulkObj[`/${route}/${orderKey}/item_modified_qty`] = "" + (Number(freeProduct.numSugarBags) || 0);
              fbBulkObj[`/${route}/${orderKey}/item_deleted_qty`] = "" + (Number(freeProduct.item_deleted_qty) || 0);
            }
          }
        }
      })
    }


    console.log(fbBulkObj);

    //bagData
    Object.keys(dohData).map((rkey,rindex) => {
      let routeOrdersData = dohData[rkey];

      Object.keys(routeOrdersData).map((okey,oindex) => {
        let order_number = routeOrdersData[okey].order_number;
        let item_id = routeOrdersData[okey].item_id;
        let order_keyy = `${order_number}-${item_id}`

        if(bagData[order_keyy]) {
          fbBulkObj[`/${rkey}/${okey}/bag_id`] = bagData[order_keyy];
        }
      })
    })

    if(Object.keys(fbBulkObj).length>0 || Object.keys(getBagAllocatedRoutes).length>0){
      let todayDate = new Date(deliveryDate);
      let year = todayDate.getFullYear();
      let month = todayDate.getMonth() + 1;
      let date = todayDate.getDate();
      let finaldate = `${date}-${month}-${year}`;

      let promiseList = [];
      if(Object.keys(fbBulkObj).length>0) {
        promiseList.push(fire.database().ref(`Driver_Orders_History/${year}/${month}/${finaldate}`).update(fbBulkObj));
      }

      if(Object.keys(shortOrdersObj).length>0) {
        promiseList.push(fire.database().ref(`Driver_Orders_Short_Orders/${year}/${month}/${finaldate}`).update(shortOrdersObj))
      }

      let currDate = new Date();
      let curryear = currDate.getFullYear();
      let currmonth = currDate.getMonth() + 1;
      let curdate = currDate.getDate();
      let finalcurrdate = `${curdate}-${currmonth}-${curryear}`;

      let picking_allocation_obj = {};
      if(!isShortOrderUpdated) {
        Object.keys(getAllocatedRoutes).map((rkey,rindex) => {
          let route = getAllocatedRoutes[rkey].route;
          let phone = getAllocatedRoutes[rkey].phone;

          picking_allocation_obj[`/${phone}/${route}/short_picking_update`] = "true";
        })
      }


      Object.keys(getBagAllocatedRoutes).map((rkey,rindex) => {
        let route = getBagAllocatedRoutes[rkey].route;
        let phone = getBagAllocatedRoutes[rkey].phone;

        picking_allocation_obj[`/${phone}/${route}/bag_id_update`] = "true";
      })

      if(Object.keys(picking_allocation_obj).length > 0) {
        promiseList.push(fire.database().ref(`Picking_Allocation_History/${curryear}/${currmonth}/${finalcurrdate}`).update(picking_allocation_obj))
      }

      Promise.all(promiseList).then(() => {
        toast(`Successfully modified orders.`, { type: toast.TYPE.SUCCESS });
        window.location.reload();
      }).catch((err) => {
        toast(`Error while modifying orders : ${err}`, { type: toast.TYPE.ERROR });
      })
    }else{
      toast(`Nothing to update!`, { type: toast.TYPE.ERROR });
    }
  }

  const revisedOrderDiscount = (driver_id, itemsInOrders, orderNo, revisedOrderValue, couponCode, offersObj, customOffersObj) => {
    if(offersObj || customOffersObj){
      console.log(offersObj);
      console.log(customOffersObj);

      let cashbackOffersObj={};

      //custom offers
      Object.keys(customOffersObj).map((customofferkey,customofferindex)=>{
        let mode=customOffersObj[customofferkey].mode?customOffersObj[customofferkey].mode:"";
        mode=mode.toLowerCase();

        if(mode=="instant"){
          let coupon_code=customOffersObj[customofferkey].coupon_code;
          let discountValue = customOffersObj[customofferkey]["discount_value"];
          let discountPercent = customOffersObj[customofferkey]["discount_percent"];
          let ele={};
          ele["coupon_code"]=coupon_code;
          ele["discountValue"]=discountValue;
          ele["discountPercent"]=discountPercent;
          ele['excluded_products']=customOffersObj[customofferkey]["excluded_products"];
          ele['minimum_order_value']=customOffersObj[customofferkey]["minimum_order_value"];
          ele['category']=customOffersObj[customofferkey]["category"]?customOffersObj[customofferkey]["category"]:"";
          cashbackOffersObj[coupon_code]=ele;
        }
      });
      console.log("cashbackOffersObj");
      console.log(cashbackOffersObj);

      // category offers
      Object.keys(offersObj).map((offerkey,offerindex)=>{
        let mode=offersObj[offerkey].mode?offersObj[offerkey].mode:"";
        mode=mode.toLowerCase();

        if(mode=="cashback" || mode=="instant"){
          let coupon_code=offersObj[offerkey].coupon_code;
          let discountValue = offersObj[offerkey]["discount_value"];
          let discountPercent = offersObj[offerkey]["discount_percent"];
          let multiple = offersObj[offerkey].multiple == "Y" ? true : false;
          let minimum_category_value =
            Number(offersObj[offerkey]["minimum_category_value"]) || 0;
          let minimum_category_unit =
            Number(offersObj[offerkey]["minimum_category_unit"]) || 0;
          let minimum_category_weight =
            Number(offersObj[offerkey]["minimum_category_weight"]) || 0;
          let maximum_order_value = offersObj[offerkey]["maximum_order_value"]
            ? Number(offersObj[offerkey]["maximum_order_value"])
            : null;
          let maximum_discount_amount = offersObj[offerkey][
            "maximum_discount_amount"
          ]
            ? Number(offersObj[offerkey]["maximum_discount_amount"])
            : null;

          let minimum_buy_product =
            offersObj[offerkey]["minimum_buy_product"] || "";
          let minimum_buy_product_unit =
            Number(offersObj[offerkey]["minimum_buy_product_unit"]) || 0;
          let minimum_buy_product_value =
            Number(offersObj[offerkey]["minimum_buy_product_value"]) || 0;
          let minimum_buy_product_weight =
            Number(offersObj[offerkey]["minimum_buy_product_weight"]) || 0;

          let ele = {};
          ele["coupon_code"] = coupon_code;
          ele["discountValue"] = discountValue;
          ele["discountPercent"] = discountPercent;
          ele["excluded_products"] =
            offersObj[offerkey]["excluded_products"] || {};
          ele["excluded_category"] =
            offersObj[offerkey]["excluded_category"] || "";
          ele["minimum_order_value"] =
            offersObj[offerkey]["minimum_order_value"];
          ele["category"] = offersObj[offerkey]["category"] || "";
          ele["sub_category"] = offersObj[offerkey]["sub_category"] || "";
          ele["minimum_category_unit"] = minimum_category_unit;
          ele["minimum_category_value"] = minimum_category_value;
          ele["minimum_category_weight"] = minimum_category_weight;
          ele["minimum_buy_product"] = minimum_buy_product;
          ele["minimum_buy_product_unit"] = minimum_buy_product_unit;
          ele["minimum_buy_product_value"] = minimum_buy_product_value;
          ele["minimum_buy_product_weight"] = minimum_buy_product_weight;
          ele["multiple"] = multiple;
          cashbackOffersObj[coupon_code]=ele;
        }
      });

      console.log(cashbackOffersObj);


      let order_disc = Number(itemsInOrders[orderNo].order_disc) || 0;

      revisedOrderValue=Number(revisedOrderValue);


        let lastChar=couponCode.slice(-1);
        if(lastChar==","){
          couponCode=couponCode.slice(0,-1);
        }



        if(couponCode.includes(",")){
          //multiple coupons
          let couponCodeList=couponCode.split(",");
          let totalOrderDiscount=0;

          for(let i=0;i<couponCodeList.length;i++){
            let coupon_code=couponCodeList[i];
            if(cashbackOffersObj){
              if(cashbackOffersObj[coupon_code]){
                let category=cashbackOffersObj[coupon_code]['category']?cashbackOffersObj[coupon_code]['category']:"";
                let sub_category=cashbackOffersObj[coupon_code]['sub_category'] || "";
                let minimum_buy_product = cashbackOffersObj[coupon_code]['minimum_buy_product'] || "";
                let discount_value=cashbackOffersObj[coupon_code]['discountValue'];
                let discount_percent=cashbackOffersObj[coupon_code]['discountPercent'];
                let mov = cashbackOffersObj[coupon_code]["minimum_order_value"];
                let excludedProducts = cashbackOffersObj[coupon_code]["excluded_products"]?cashbackOffersObj[coupon_code]["excluded_products"]:{};
                let minimum_category_value =
                  Number(
                    cashbackOffersObj[coupon_code]["minimum_category_value"]
                  ) || 0;
                let minimum_category_unit =
                  Number(
                    cashbackOffersObj[coupon_code]["minimum_category_unit"]
                  ) || 0;
                let minimum_category_weight =
                  Number(
                    cashbackOffersObj[coupon_code]["minimum_category_weight"]
                  ) || 0;

                let minimum_buy_product_weight =
                  Number(
                    cashbackOffersObj[coupon_code]["minimum_buy_product_weight"]
                  ) || 0;
                let maximum_order_value = cashbackOffersObj[coupon_code][
                  "maximum_order_value"
                ]
                  ? Number(cashbackOffersObj[coupon_code]["maximum_order_value"])
                  : null;
                let maximum_discount_amount = cashbackOffersObj[coupon_code][
                  "maximum_discount_amount"
                ]
                  ? Number(
                      cashbackOffersObj[coupon_code]["maximum_discount_amount"]
                    )
                  : null;

                let minimum_buy_product_unit =
                  Number(
                    cashbackOffersObj[coupon_code]["minimum_buy_product_unit"]
                  ) || 0;
                let minimum_buy_product_value =
                  Number(
                    cashbackOffersObj[coupon_code]["minimum_buy_product_value"]
                  ) || 0;

                let excluded_category =
                  cashbackOffersObj[coupon_code]["excluded_category"] || "";
                let multiple =
                  cashbackOffersObj[coupon_code]["multiple"];


                let revisedAmtWithExcludedProductsList = revisedAmtForOffersWithExcludedProducts(itemsInOrders, orderNo, excludedProducts, category, sub_category, minimum_buy_product,
                  excluded_category);
                let revisedAmtWithExcludedProducts = (revisedAmtWithExcludedProductsList[0]).toFixed(2);
                revisedAmtWithExcludedProducts = Number(revisedAmtWithExcludedProducts);

                let productCount = revisedAmtWithExcludedProductsList[1];
                let productWeight = revisedAmtWithExcludedProductsList[2];

                let revisedOrderDisc=0;
                if (discount_value) {
                  if (multiple) {
                    if (minimum_buy_product) {
                      if (minimum_buy_product_unit > 0) {
                        discount_value *= Math.floor(
                          productCount / minimum_buy_product_unit
                        );
                      } else if (minimum_buy_product_weight > 0) {
                        discount_value *= Math.floor(
                          productWeight / minimum_buy_product_weight
                        );
                      } else {
                        discount_value *= Math.floor(
                          revisedAmtWithExcludedProducts /
                            minimum_buy_product_value
                        );
                      }
                    } else if (category) {
                      if (minimum_category_unit > 0) {
                        discount_value *= Math.floor(
                          productCount / minimum_category_unit
                        );
                      } else if (minimum_category_weight > 0) {
                        discount_value *= Math.floor(
                          productWeight / minimum_category_weight
                        );
                      } else {
                        discount_value *= Math.floor(
                          revisedAmtWithExcludedProducts / minimum_category_value
                        );
                      }
                    } else {
                      discount_value *= Math.floor(
                        revisedAmtWithExcludedProducts / mov
                      );
                    }

                    revisedOrderDisc = discount_value;
                  } else {
                    if(minimum_buy_product) {
                      if (minimum_buy_product_unit > 0) {
                        if (productCount >= minimum_buy_product_unit) {
                          revisedOrderDisc = discount_value;
                        } else {
                          revisedOrderDisc = 0;
                        }
                      } else if (minimum_buy_product_weight > 0) {
                        if (productWeight >= minimum_buy_product_weight) {
                          revisedOrderDisc = discount_value;
                        } else {
                          revisedOrderDisc = 0;
                        }
                      } else {
                        if (revisedAmtWithExcludedProducts >= minimum_buy_product_value) {
                          revisedOrderDisc = discount_value;
                        } else {
                          revisedOrderDisc = 0;
                        }
                      }

                    } else if(category) {
                      if (minimum_category_unit > 0) {
                        if (productCount >= minimum_category_unit) {
                          revisedOrderDisc = discount_value;
                        } else {
                          revisedOrderDisc = 0;
                        }
                      } else if (minimum_category_weight > 0) {
                        if (productWeight >= minimum_category_weight) {
                          revisedOrderDisc = discount_value;
                        } else {
                          revisedOrderDisc = 0;
                        }
                      } else {
                        if (revisedAmtWithExcludedProducts >= minimum_category_value) {
                          revisedOrderDisc = discount_value;
                        } else {
                          revisedOrderDisc = 0;
                        }
                      }

                      
                    } else if(revisedAmtWithExcludedProducts>=mov) {
                      revisedOrderDisc = discount_value;
                    } else {
                      revisedOrderDisc = 0;
                    }


                  }

                  
                } else if (discount_percent) {
                  if(minimum_buy_product) {
                    if (minimum_buy_product_unit > 0) {
                      if (productCount >= minimum_buy_product_unit) {
                        revisedOrderDisc =
                    (discount_percent / 100) * revisedAmtWithExcludedProducts;
                      } else {
                        revisedOrderDisc = 0;
                      }
                    } else if (minimum_buy_product_weight > 0) {
                      if (productWeight >= minimum_buy_product_weight) {
                        revisedOrderDisc =
                    (discount_percent / 100) * revisedAmtWithExcludedProducts;
                      } else {
                        revisedOrderDisc = 0;
                      }
                    } else {
                      if (revisedAmtWithExcludedProducts >= minimum_buy_product_value) {
                        revisedOrderDisc =
                    (discount_percent / 100) * revisedAmtWithExcludedProducts;
                      } else {
                        revisedOrderDisc = 0;
                      }
                    }

                  } else if(category) {
                    if (minimum_category_unit > 0) {
                      if (productCount >= minimum_category_unit) {
                        revisedOrderDisc =
                    (discount_percent / 100) * revisedAmtWithExcludedProducts;
                      } else {
                        revisedOrderDisc = 0;
                      }
                    } else if (minimum_category_weight > 0) {
                      if (productWeight >= minimum_category_weight) {
                        revisedOrderDisc =
                    (discount_percent / 100) * revisedAmtWithExcludedProducts;
                      } else {
                        revisedOrderDisc = 0;
                      }
                    } else {
                      if (revisedAmtWithExcludedProducts >= minimum_category_value) {
                        revisedOrderDisc =
                    (discount_percent / 100) * revisedAmtWithExcludedProducts;
                      } else {
                        revisedOrderDisc = 0;
                      }
                    }


                  } else if(revisedAmtWithExcludedProducts>=mov) {
                    revisedOrderDisc =
                    (discount_percent / 100) * revisedAmtWithExcludedProducts;
                  } else {
                    revisedOrderDisc = 0;
                  }
                  
                }
                totalOrderDiscount+=Number(revisedOrderDisc);
              }
            }
          }

          totalOrderDiscount=totalOrderDiscount.toFixed(2);

          return totalOrderDiscount;

        }else{
          //single coupon
          if(cashbackOffersObj){
            if(cashbackOffersObj[couponCode]){
              let category=cashbackOffersObj[couponCode]['category']?cashbackOffersObj[couponCode]['category']:"";
              let sub_category=cashbackOffersObj[couponCode]['sub_category'] || "";
              let minimum_buy_product = cashbackOffersObj[couponCode]['minimum_buy_product'] || "";
              let discount_value=cashbackOffersObj[couponCode]['discountValue'];
              let discount_percent=cashbackOffersObj[couponCode]['discountPercent'];
              let mov = cashbackOffersObj[couponCode]["minimum_order_value"];
              let excludedProducts = cashbackOffersObj[couponCode]["excluded_products"]?cashbackOffersObj[couponCode]["excluded_products"]:{};

              let minimum_category_value =
                Number(cashbackOffersObj[couponCode]["minimum_category_value"]) ||
                0;
              let minimum_category_unit =
                Number(cashbackOffersObj[couponCode]["minimum_category_unit"]) ||
                0;
              let minimum_category_weight =
                Number(
                  cashbackOffersObj[couponCode]["minimum_category_weight"]
                ) || 0;

              let minimum_buy_product_weight =
                Number(
                  cashbackOffersObj[couponCode]["minimum_buy_product_weight"]
                ) || 0;

              let maximum_order_value = cashbackOffersObj[couponCode][
                "maximum_order_value"
              ]
                ? Number(cashbackOffersObj[couponCode]["maximum_order_value"])
                : null;
              let maximum_discount_amount = cashbackOffersObj[couponCode][
                "maximum_discount_amount"
              ]
                ? Number(cashbackOffersObj[couponCode]["maximum_discount_amount"])
                : null;

              let minimum_buy_product_unit =
                Number(
                  cashbackOffersObj[couponCode]["minimum_buy_product_unit"]
                ) || 0;
              let minimum_buy_product_value =
                Number(
                  cashbackOffersObj[couponCode]["minimum_buy_product_value"]
                ) || 0;
              let excluded_category =
                cashbackOffersObj[couponCode]["excluded_category"] || "";
              let multiple =
                cashbackOffersObj[couponCode]["multiple"];

              let revisedAmtWithExcludedProductsList = revisedAmtForOffersWithExcludedProducts(itemsInOrders, orderNo, excludedProducts, category, sub_category, minimum_buy_product, excluded_category);
              let revisedAmtWithExcludedProducts = (revisedAmtWithExcludedProductsList[0]).toFixed(2);
              revisedAmtWithExcludedProducts = Number(revisedAmtWithExcludedProducts);

              let productCount = revisedAmtWithExcludedProductsList[1];
              let productWeight = revisedAmtWithExcludedProductsList[2];

              let revisedOrderDisc = 0;
              if (discount_value) {
                if (multiple) {
                  if (minimum_buy_product) {
                    if (minimum_buy_product_unit > 0) {
                      discount_value *= Math.floor(
                        productCount / minimum_buy_product_unit
                      );
                    } else if (minimum_buy_product_weight > 0) {
                      discount_value *= Math.floor(
                        productWeight / minimum_buy_product_weight
                      );
                    } else {
                      discount_value *= Math.floor(
                        revisedAmtWithExcludedProducts / minimum_buy_product_value
                      );
                    }
                  } else if (category) {
                    if (minimum_category_unit > 0) {
                      discount_value *= Math.floor(
                        productCount / minimum_category_unit
                      );
                    } else if (minimum_category_weight > 0) {
                      discount_value *= Math.floor(
                        productWeight / minimum_category_weight
                      );
                    } else {
                      discount_value *= Math.floor(
                        revisedAmtWithExcludedProducts / minimum_category_value
                      );
                    }
                  } else {
                    discount_value *= Math.floor(
                      revisedAmtWithExcludedProducts / mov
                    );
                  }

                  revisedOrderDisc = discount_value;
                } else {
                  if(minimum_buy_product) {
                    if (minimum_buy_product_unit > 0) {
                      if (productCount >= minimum_buy_product_unit) {
                        revisedOrderDisc = discount_value;
                      } else {
                        revisedOrderDisc = 0;
                      }
                    } else if (minimum_buy_product_weight > 0) {
                      if (productWeight >= minimum_buy_product_weight) {
                        revisedOrderDisc = discount_value;
                      } else {
                        revisedOrderDisc = 0;
                      }
                    } else {
                      if (revisedAmtWithExcludedProducts >= minimum_buy_product_value) {
                        revisedOrderDisc = discount_value;
                      } else {
                        revisedOrderDisc = 0;
                      }
                    }

                  } else if(category) {
                    if (minimum_category_unit > 0) {
                      if (productCount >= minimum_category_unit) {
                        revisedOrderDisc = discount_value;
                      } else {
                        revisedOrderDisc = 0;
                      }
                    } else if (minimum_category_weight > 0) {
                      if (productWeight >= minimum_category_weight) {
                        revisedOrderDisc = discount_value;
                      } else {
                        revisedOrderDisc = 0;
                      }
                    } else {
                      if (revisedAmtWithExcludedProducts >= minimum_category_value) {
                        revisedOrderDisc = discount_value;
                      } else {
                        revisedOrderDisc = 0;
                      }
                    }

                    
                  } else if(revisedAmtWithExcludedProducts>=mov) {
                    revisedOrderDisc = discount_value;
                  } else {
                    revisedOrderDisc = 0;
                  }


                }


                
              } else if (discount_percent) {
                if(minimum_buy_product) {
                  if (minimum_buy_product_unit > 0) {
                    if (productCount >= minimum_buy_product_unit) {
                      revisedOrderDisc =
                  (discount_percent / 100) * revisedAmtWithExcludedProducts;
                    } else {
                      revisedOrderDisc = 0;
                    }
                  } else if (minimum_buy_product_weight > 0) {
                    if (productWeight >= minimum_buy_product_weight) {
                      revisedOrderDisc =
                  (discount_percent / 100) * revisedAmtWithExcludedProducts;
                    } else {
                      revisedOrderDisc = 0;
                    }
                  } else {
                    if (revisedAmtWithExcludedProducts >= minimum_buy_product_value) {
                      revisedOrderDisc =
                  (discount_percent / 100) * revisedAmtWithExcludedProducts;
                    } else {
                      revisedOrderDisc = 0;
                    }
                  }

                } else if(category) {
                  if (minimum_category_unit > 0) {
                    if (productCount >= minimum_category_unit) {
                      revisedOrderDisc =
                  (discount_percent / 100) * revisedAmtWithExcludedProducts;
                    } else {
                      revisedOrderDisc = 0;
                    }
                  } else if (minimum_category_weight > 0) {
                    if (productWeight >= minimum_category_weight) {
                      revisedOrderDisc =
                  (discount_percent / 100) * revisedAmtWithExcludedProducts;
                    } else {
                      revisedOrderDisc = 0;
                    }
                  } else {
                    if (revisedAmtWithExcludedProducts >= minimum_category_value) {
                      revisedOrderDisc =
                  (discount_percent / 100) * revisedAmtWithExcludedProducts;
                    } else {
                      revisedOrderDisc = 0;
                    }
                  }

                  
                } else if(revisedAmtWithExcludedProducts>=mov) {
                  revisedOrderDisc =
                  (discount_percent / 100) * revisedAmtWithExcludedProducts;
                } else {
                  revisedOrderDisc = 0;
                }
                
              }


              revisedOrderDisc=revisedOrderDisc.toFixed(2);

              return revisedOrderDisc;

            }else{
              let revisedOrderDisc=0;
              revisedOrderDisc=revisedOrderDisc.toFixed(2);

              return revisedOrderDisc;
            }
          }

        }

    }else{
      return 0;
    }
  }

  const revisedAmtForOffersWithExcludedProducts = (itemsInOrders, orderNo, excludedProducts, offerCategory, offerSubCategory, minimumBuyProduct, excludedCategory) => {
    if (offerCategory) {
      offerCategory = offerCategory.toLowerCase();
    }

    if(offerSubCategory) {
      offerSubCategory = offerSubCategory.toLowerCase();
    }

    if (excludedCategory) {
      excludedCategory = excludedCategory.toLowerCase();
    }

    let totalOrderAmount = 0;
    let categoryProductCount=0;
    let categoryProductWeight=0;

    Object.keys(itemsInOrders).forEach((orderNumber) => {
      if (
        !orderNumber.includes(
          `${orderNo.split("-")[0]}-${orderNo.split("-")[1]}`
        )
      )
        return;

      let itemAmt = 0;
      let itemID = itemsInOrders[orderNumber].item_id;
      let itemDisc = itemsInOrders[orderNumber].item_disc;
      let itemQty = itemsInOrders[orderNumber].hasOwnProperty("item_delivered_qty")?Number(itemsInOrders[orderNumber].item_delivered_qty):(
                    itemsInOrders[orderNumber].hasOwnProperty("item_modified_qty")?Number(itemsInOrders[orderNumber].item_modified_qty):
                    Number(itemsInOrders[orderNumber].order_qty));

      let itemWeight = getWeightFromItemDescription(itemDisc,itemQty);
      let caseSize = Number(itemsInOrders[orderNumber].case_size) || 1;

      let category = itemsInOrders[orderNumber]["category"]
        ? itemsInOrders[orderNumber]["category"]
        : "";
      let sub_category = itemsInOrders[orderNumber]["sub_category"]
        ? itemsInOrders[orderNumber]["sub_category"]
        : "";
      let brand = itemsInOrders[orderNumber]["brand"]
        ? itemsInOrders[orderNumber]["brand"]
        : "";

      category = category.toLowerCase();
      sub_category = sub_category.toLowerCase();
      brand = brand.toLowerCase();

      if (
        excludedProducts[itemID] == undefined &&
        (!excludedCategory ||
          (excludedCategory &&
            !excludedCategory.includes(category) &&
            !excludedCategory.includes(brand))) &&
        (!offerCategory ||
          (offerCategory &&
            (category.includes(offerCategory) ||
              sub_category.includes(offerCategory) ||
              brand.includes(offerCategory)))) &&
        (!offerSubCategory ||
          (offerSubCategory &&
            (category.includes(offerSubCategory) ||
              sub_category.includes(offerSubCategory) ||
              brand.includes(offerSubCategory))))
      ) {
        if (
          (itemsInOrders[orderNumber].hasOwnProperty("item_delivered_qty") &&
            itemsInOrders[orderNumber].mrp) ||
          (itemsInOrders[orderNumber].hasOwnProperty("item_modified_qty") &&
            itemsInOrders[orderNumber].mrp)
        ) {
          if (
            itemsInOrders[orderNumber].hasOwnProperty("item_delivered_qty")
          ) {
            // console.log( itemsInOrders[orderNumber].item_id+" ItemDeliveredQty is present::: "+itemsInOrders[orderNumber].order_qty+" - DeliveredQty:: "+itemsInOrders[orderNumber].item_delivered_qty)
            itemAmt =
              (Number(itemsInOrders[orderNumber].mrp) /
                Number(itemsInOrders[orderNumber].order_qty)) *
              Number(itemsInOrders[orderNumber].item_delivered_qty);
          } else if (
            itemsInOrders[orderNumber].hasOwnProperty("item_modified_qty")
          ) {
            // console.log(itemsInOrders[orderNumber].item_id+" ItemModifiedQty is present::: "+itemsInOrders[orderNumber].order_qty+" - ModifiedQty:: "+itemsInOrders[orderNumber].item_modified_qty)
            itemAmt =
              (Number(itemsInOrders[orderNumber].mrp) /
                Number(itemsInOrders[orderNumber].order_qty)) *
              Number(itemsInOrders[orderNumber].item_modified_qty);
          } else {
            itemAmt = Number(itemsInOrders[orderNumber].mrp);
          }
        } else {
          itemAmt = Number(itemsInOrders[orderNumber].mrp);
        }
      }



      if(minimumBuyProduct) {
        if(minimumBuyProduct.includes(String(itemsInOrders[orderNumber].item_id))) {
          let prodQty = Number(itemsInOrders[orderNumber].order_qty);

          if(itemsInOrders[orderNumber].hasOwnProperty("item_modified_qty")) {
            prodQty = Number(itemsInOrders[orderNumber].item_modified_qty);
          }

          if(itemsInOrders[orderNumber].hasOwnProperty("item_delivered_qty")) {
            prodQty = Number(itemsInOrders[orderNumber].item_delivered_qty);
          }
          itemAmt =
            (Number(itemsInOrders[orderNumber].mrp) /
              Number(itemsInOrders[orderNumber].order_qty)) *
            Number(prodQty);

          totalOrderAmount += Number(itemAmt);
          categoryProductCount += (prodQty/caseSize);
          categoryProductWeight += itemWeight;
        }
      }else if(offerSubCategory && (category.includes(offerSubCategory) || sub_category.includes(offerSubCategory) || brand.includes(offerSubCategory))) {
        if (
          !excludedCategory ||
          (excludedCategory &&
            !excludedCategory.includes(category) &&
            !excludedCategory.includes(brand))
        ) {
          let prodQty = Number(itemsInOrders[orderNumber].order_qty);

          if (itemsInOrders[orderNumber].hasOwnProperty("item_modified_qty")) {
            prodQty = Number(itemsInOrders[orderNumber].item_modified_qty);
          }

          if (itemsInOrders[orderNumber].hasOwnProperty("item_delivered_qty")) {
            prodQty = Number(itemsInOrders[orderNumber].item_delivered_qty);
          }
          itemAmt =
            (Number(itemsInOrders[orderNumber].mrp) /
              Number(itemsInOrders[orderNumber].order_qty)) *
            Number(prodQty);

          if (!isNaN(itemAmt) && itemAmt) {
            totalOrderAmount += Number(itemAmt);
          }

          categoryProductCount += prodQty / caseSize;
          categoryProductWeight += itemWeight;
        }

      }else if(offerCategory && (category.includes(offerCategory) || sub_category.includes(offerCategory) || brand.includes(offerCategory))) {
        if (
          !excludedCategory ||
          (excludedCategory &&
            !excludedCategory.includes(category) &&
            !excludedCategory.includes(brand))
        ) {
          if (!isNaN(itemAmt) && itemAmt) {
            totalOrderAmount += Number(itemAmt);
          }

          let prodQty = Number(itemsInOrders[orderNumber].order_qty);

          if (itemsInOrders[orderNumber].hasOwnProperty("item_modified_qty")) {
            prodQty = Number(itemsInOrders[orderNumber].item_modified_qty);
          }

          if (itemsInOrders[orderNumber].hasOwnProperty("item_delivered_qty")) {
            prodQty = Number(itemsInOrders[orderNumber].item_delivered_qty);
          }

          categoryProductCount += prodQty / caseSize;
          categoryProductWeight += itemWeight;
        }
      }else if (!isNaN(itemAmt) && itemAmt && !offerCategory && !offerSubCategory) {
        totalOrderAmount += Number(itemAmt);
      }


    });

    return [totalOrderAmount,categoryProductCount,categoryProductWeight];
  }

  const getWeightFromItemDescription = (item_disc,orderQty) => {
      let itemWeight,orderWeight;
      if(item_disc!=undefined){
        let weightFromDesc = item_disc.substring(item_disc.lastIndexOf("-") + 1, item_disc.length);
        let itemWtArray = weightFromDesc.split(" ");
        itemWeight = itemWtArray[itemWtArray.length-2];

        if (weightFromDesc.includes("gm") || weightFromDesc.includes("ml")) {
            itemWeight = itemWeight / 1000;
        }
        if ((weightFromDesc.includes("1 pc") || weightFromDesc.includes("1 Pc"))) {
           if(item_disc.includes("gm")) {
               var itemWtExtract = item_disc.split("gm")
               let itemWtExtract2 = itemWtExtract[0].split("-")
               itemWeight = Number(itemWtExtract2[itemWtExtract2.length-1].replace(/[^0-9]/g,''))/1000;
            } else if (item_disc.includes("kg")){
               var itemWtExtract = item_disc.split("kg")
               itemWeight = Number(itemWtExtract[0].replace(/[^0-9]/g,''));
            }
        }
        if(itemWeight <=0){
            itemWeight = 1;
        }
        if(orderQty){
          orderWeight = itemWeight * Number(orderQty);
        }else{
          orderWeight = '';
        }
      }

      if(isNaN(orderWeight) || orderWeight==undefined){
        itemWeight=1;
        orderWeight=1;
      }

      return orderWeight
  }

  const revisedAmt = (itemsInOrders, orderNo) => {

    let undeliveredItemAmt = 0
    let totalAmtPaid = 0
    let totalOrderValue
    let modifiedItemKeyList = [];
    let orderID = ""
    Object.keys(itemsInOrders).forEach(orderNumber => {
      if (itemsInOrders[orderNumber].order_number!=orderNo)
        return;

      if((itemsInOrders[orderNumber].item_delivered_qty && itemsInOrders[orderNumber].mrp) ||
        (itemsInOrders[orderNumber].hasOwnProperty("item_modified_qty") && itemsInOrders[orderNumber].mrp)) {
          if(itemsInOrders[orderNumber].status.toLowerCase().includes(`partially-delivered`) && itemsInOrders[orderNumber].item_delivered_qty) {
            undeliveredItemAmt += (Number(itemsInOrders[orderNumber].mrp) / Number(itemsInOrders[orderNumber].order_qty)) * (Number(itemsInOrders[orderNumber].order_qty) - Number(itemsInOrders[orderNumber].item_delivered_qty))
          }
          else if ((itemsInOrders[orderNumber].status.toLowerCase().includes(`shipped`) || itemsInOrders[orderNumber].status=="Processed" || itemsInOrders[orderNumber].status.toLowerCase().includes(`partially-delivered`)) && itemsInOrders[orderNumber].hasOwnProperty("item_modified_qty")) {
            undeliveredItemAmt += (Number(itemsInOrders[orderNumber].mrp) / Number(itemsInOrders[orderNumber].order_qty)) * (Number(itemsInOrders[orderNumber].order_qty) - Number(itemsInOrders[orderNumber].item_modified_qty))
          }
      }

      orderID = orderNumber;

      if(itemsInOrders[orderNumber].hasOwnProperty("item_modified_qty")){
        modifiedItemKeyList.push({"item_key":orderNumber, "item_modified_qty":itemsInOrders[orderNumber].item_modified_qty});
      }else{
        modifiedItemKeyList.push({"item_key":orderNumber});
      }

      totalOrderValue = Number(itemsInOrders[orderNumber].total_amt);
      totalAmtPaid = itemsInOrders[orderNumber]["payment_details"]?itemsInOrders[orderNumber]["payment_details"]["total_amount_paid"]:0
    })

    if(!undeliveredItemAmt) {
      undeliveredItemAmt = 0
    }

    return [(totalOrderValue - undeliveredItemAmt), modifiedItemKeyList, orderID]
  }

  const RouteOrdersTableHeader = () => {
      return (
          <thead className="thead-light">
              <tr>
                  <th width="13%">Order Number</th>
                  <th>Total Order Amt</th>
                  <th>Item ID</th>
                  <th>Order Qty</th>
                  <th>Item Modified Qty</th>
                  <th>Coupon Code</th>
                  <th>Revised Order Amt</th>
                  <th>Revised Disc</th>
                  <th>Freeproduct</th>
              </tr>
          </thead>
      );
  }

  const RouteOrdersTableBody = (routeData) => {
    console.log(routeData);
    let finalRouteData=routeData['routeData'];

    let rows = finalRouteData.map((row, index) => {
        let freeproductlist = row['freeproductlist'] || [];
        let bgColor = "";
        let revisedOrderAmount = Number(row['revised_order_amt']) || 0;
        let orderDisc = Number(row['order_disc']) || 0;
        let revisedOrderDisc = Number(row['revised_order_disc']) || 0;
        let finalOrderValue = revisedOrderAmount + orderDisc - revisedOrderDisc;
        if(finalOrderValue<1000) {
          bgColor = "coral";
        }

            return (
                <tr key={index} style={{ backgroundColor: bgColor }}>
                    <td>{row['order_number']}</td>
                    <td>{row['total_amt']}</td>
                    <td>{row['item_id']}</td>
                    <td>{row['order_qty']}</td>
                    <td>{row['item_modified_qty']}</td>
                    <td>{row['coupon_code']}</td>
                    <td>{finalOrderValue}</td>
                    <td>{row['revised_order_disc']}</td>
                    <td>{freeproductlist && <RenderFreeProducts freeproductlist={freeproductlist} />}</td>
                </tr>
            );
        });

    return (<><tbody>{rows}</tbody></>);
  }

  const RenderFreeProducts = (freeproductlist) => {
    let freeProds = freeproductlist['freeproductlist'];

    let rows = freeProds.map((row, index) => {
            return (
                <ul key={index}>
                    <li>ID - {row['freeProduct']}</li>
                    <li>Order Qty - {row['existingSugarQuantity']}</li>
                    <li>Modified Qty - {row['numSugarBags']}</li>
                </ul>
            );
        });

    return (<><span>{rows}</span></>);
  }

  const RouteOrdersTable = (routeData) => {
    console.log(routeData);
    return (
        <>
          <table className="table table-striped" style={{ tableLayout: "fixed",
    wordWrap: "break-word" }}>
                <RouteOrdersTableHeader />
                <RouteOrdersTableBody routeData={routeData['routeData']} />
          </table>
        </>
    );
  }

  const RouteShipModifyOrdersTable = () => {
    let rows = Object.keys(routeOrderData).map((rkey, index) => {
      let routeData = routeOrderData[rkey] || [];

      if(routeData.length>0){
        return (
          <div key={rkey}>
            <center><h4>Route - {rkey}</h4></center>
            <RouteOrdersTable routeData={routeData} />
          </div>
        );
      }
    });

    return (<><div style={{ marginTop:70}}>{rows}</div></>);
  }

  return (<>
          <Modal
              {...props}
              size="xl"
              aria-labelledby="contained-modal-title-vcenter"
              centered
          >
              <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                    Ship Modify Orders
                  </Modal.Title>
              </Modal.Header>
              <Modal.Body>

              {Object.keys(routeOrderData).length>0 ? <RouteShipModifyOrdersTable /> : (<p>No orders present for modification</p>)}

              </Modal.Body>
              <Modal.Footer>
                  {((Object.keys(routeOrderData).length>0 && !isShortOrderUpdated) || Object.keys(bagData).length>0 || Object.keys(getBagAllocatedRoutes).length>0) && <Button onClick={()=>{ modifyItemQtyInDoh(); }}>Update</Button>}
                  <Button onClick={props.onHide}>Close</Button>
              </Modal.Footer>
          </Modal>
          {loading ? (<Backdrop parentLoadStatus={loading} />) : (null)}
      </>
  )
}

export default ShipModifyOrdersModal
