import React from "react";
import Barcode from "react-barcode";
import QRCode from "react-qr-code";

const getPickedLooseOrdersData = (_loosePackingOrdersData = []) => {
  const newLoosePackingOrdersData = [];

  _loosePackingOrdersData.forEach((item, index) => {
    if (item.picked_status === "true") {
      newLoosePackingOrdersData.push(item);
    }
  });

  return newLoosePackingOrdersData;
};

const LoosePackingPrintBody = (props) => {
  const { loosePackingOrdersData, printComponentRef } = props;
  return (
    <div
      ref={(element) => {
        printComponentRef.push(element);
      }}
    >
      {getPickedLooseOrdersData(loosePackingOrdersData).map((row, index) => {
        return (
          <div
            style={{
              pageBreakBefore: index > 0 ? "always" : "avoid",
              breakBefore: index > 0 ? "always" : "avoid",
            }}
          >
            {row.bag_items &&
              Object.keys(row.bag_items).map((bagId, bindex) => {
                return (
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "40px",
                      pageBreakBefore: bindex > 0 ? "always" : "avoid",
                      breakBefore: bindex > 0 ? "always" : "avoid",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "65px",
                        fontStyle: "italic",
                      }}
                    >
                      {row.shop_name}
                    </p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "1rem",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "left",
                      }}
                    >
                      <div style={{ marginRight: "70px" }}>
                        <QRCode
                          size={256}
                          value={`${row.order_number}-${bagId}`}
                        />
                      </div>

                      <div>
                        <div>
                          <span style={{ fontWeight: "bold" }}>
                            Retailer Id :
                          </span>{" "}
                          <span>{row.retailer_id}</span>
                        </div>
                        <div>
                          <span style={{ fontWeight: "bold" }}>Order No :</span>{" "}
                          <span>{row.order_number}</span>
                        </div>

                        <p style={{ display: "block" }}>
                          {" "}
                          <span style={{ fontWeight: "bold" }}>
                            Total Weight :
                          </span>{" "}
                          <span>
                            {" "}
                            {Number(row.weightbags).toFixed(2)} Kg(s)
                          </span>
                        </p>
                        <p style={{ display: "block" }}>
                          {" "}
                          <span style={{ fontWeight: "bold" }}>
                            Bag Id :
                          </span>{" "}
                          <span>{bagId}</span>
                        </p>
                        <p style={{ display: "block" }}>
                          {" "}
                          <span style={{ fontWeight: "bold" }}>
                            Bag Weight :
                          </span>{" "}
                          <span>
                            {Number(row.sub_bag_weights[bagId]).toFixed(2)}{" "}
                            Kg(s)
                          </span>
                        </p>
                      </div>
                    </div>

                    <table
                      style={{
                        border: "3px solid",
                        textAlign: "center",
                        margin: "auto",
                        width: "75%",
                        maxWidth: "75%",
                        marginBottom: "2.5rem",
                        marginTop: "1.5rem",
                        fontSize: "30px",
                      }}
                    >
                      <thead>
                        <tr style={{ border: "3px solid" }}>
                          <th style={{ border: "3px solid" }}>Items</th>
                          <th style={{ border: "3px solid" }}>Qty</th>
                        </tr>
                      </thead>
                      <tbody>
                        {row.bag_items[bagId].map((item, index) => {
                          if (item.picked_qty == 0) {
                            return "";
                          }

                          return (
                            <tr style={{ border: "3px solid" }}>
                              <td style={{ border: "3px solid" }}>{`${
                                item.item_disc || ""
                              }  `}</td>
                              <td>
                                <span style={{ fontWeight: "bold" }}>
                                  {`  ${item.picked_qty || ""}  `}
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>

                    <Barcode
                      value={`${row.order_number}-${bagId}`}
                      width={3}
                      height={180}
                      displayValue={true}
                      background="transparent"
                      lineColor="black"
                    />
                    <br />
                  </div>
                );
              })}
            <br />
          </div>
        );
      })}
    </div>
  );
};

export default LoosePackingPrintBody;
