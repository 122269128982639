

export const getPasswordCheck = (inpEmail, inpPass) => {
  let loggedInUser=JSON.parse(sessionStorage.getItem(`user`))[0];
  return loggedInUser.email===inpEmail && loggedInUser.password===inpPass;
};

export const validateUserEmail = (inpEmail) => {
  let loggedInUser=JSON.parse(sessionStorage.getItem(`user`))[0];
  return loggedInUser.email===inpEmail;
};

export const validateIsAdminUser = (inpEmail) => {
  let loggedInUser=JSON.parse(sessionStorage.getItem(`user`))[0];
  return loggedInUser.email===inpEmail && loggedInUser.user_type==="admin";
};


export const validateIsOperationUser = (inpEmail) => {
  let loggedInUser=JSON.parse(sessionStorage.getItem(`user`))[0];
  return loggedInUser.email===inpEmail && loggedInUser.user_type==="operation";
};

export const validateIsFinanceHeadUser = (inpEmail) => {
  let loggedInUser=JSON.parse(sessionStorage.getItem(`user`))[0];
  return loggedInUser.email===inpEmail && loggedInUser.user_type==="financeHead";
};

export const validateIsFinanceMISUser = (inpEmail) => {
  let loggedInUser=JSON.parse(sessionStorage.getItem(`user`))[0];
  return loggedInUser.email===inpEmail && loggedInUser.user_type==="financemis";
};

export const validateIsBillingUser = (inpEmail) => {
  let loggedInUser=JSON.parse(sessionStorage.getItem(`user`))[0];
  return loggedInUser.email===inpEmail && loggedInUser.user_type==="billing";
};

export const validateIsExportUser = (inpEmail) => {
  let loggedInUser=JSON.parse(sessionStorage.getItem(`user`))[0];
  return loggedInUser.email===inpEmail && (loggedInUser.user_type==="admin" || loggedInUser.user_type==="billing" || loggedInUser.user_type==="financeHead" || loggedInUser.user_type==="systems" || loggedInUser.user_type==="financemis" ) ;
};

export const validateIsPackingUser = (inpEmail) => {
  let loggedInUser=JSON.parse(sessionStorage.getItem(`user`))[0];
  return loggedInUser.email===inpEmail && loggedInUser.user_type==="packing";
};

export const validateIsFinanceUser = (inpEmail) => {
  let loggedInUser=JSON.parse(sessionStorage.getItem(`user`))[0];
  return loggedInUser.email===inpEmail && loggedInUser.user_type==="finance";
};

export const validateIsDistributorUser = (inpEmail) => {
  let loggedInUser=JSON.parse(sessionStorage.getItem(`user`))[0];
  return loggedInUser.email===inpEmail && loggedInUser.user_type==="distributor";
};

export const validateIsOtherOrdersMgmtUser = (inpEmail) => {
  let loggedInUser=JSON.parse(sessionStorage.getItem(`user`))[0];
  return loggedInUser.email===inpEmail && loggedInUser.user_type==="otherOrdersMgmt";
};

export const validateIsSystemsUser = (inpEmail) => {
  let loggedInUser=JSON.parse(sessionStorage.getItem(`user`))[0];
  return loggedInUser.email===inpEmail && (loggedInUser.user_type==="systems" || loggedInUser.user_type==="admin");
};

export const validateIsInventoryUser = (inpEmail) => {
  let loggedInUser=JSON.parse(sessionStorage.getItem(`user`))[0];
  return loggedInUser.email===inpEmail && (loggedInUser.user_type==="inventory" || loggedInUser.user_type=== "admin" || (loggedInUser.user_type).includes("finance") );
};

export const getUserType = (inpEmail) => {
  let loggedInUser=JSON.parse(sessionStorage.getItem(`user`))[0];
  return loggedInUser.user_type;
};
