import React, { useState, useMemo, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NavBar from "../../Navigation bar/Navbar";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Form,
  Dropdown,
} from "react-bootstrap";
import fire from "../../../Configs/firebase";

import {
  useTable,
  useGlobalFilter,
  useFilters,
  useAsyncDebounce,
  useSortBy,
} from "react-table";
import { CSVLink } from "react-csv";

function DetailedReportT3({ TableData, TableDate, callback }) {

  const [show1, setShow1] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  let csv_data = useRef("");
  const [RowValue, setRowValue] = useState({});
  const [Odometer, setOdometer] = useState({
    starting_odometer_reading: "",
    ending_odometer_reading: "",
  });

  const handleClose1 = () => setShow1(false);
  const handleShow1 = (row) => {
    let isStartingPresent 
    if (row.starting_odometer_reading ){
      isStartingPresent = true
    }else{
      isStartingPresent = false
    }
    setOdometer({
      starting_odometer_reading: row.starting_odometer_reading,
      ending_odometer_reading: row.ending_odometer_reading,
      date: row.date,
      driver_Phone_Number: row.driver_Phone_Number,
      isStartingPresent : isStartingPresent
    });
    setShow1(true);
  };

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (row) => {
    setRowValue({
      date: row.date,
      driver_Phone_Number: row.driver_Phone_Number,
    });
    setShow2(true);
  };

  function createCsv(data, columns) {
    let final_csv = [];
    for (let row of data) {
      let one_row = [];
      for (let col of columns) {
        if (
          col.accessor === "actual_reason" &&
          row[col.accessor] === undefined
        ) {
          one_row.push(row["reason"]);
          continue;
        }

        one_row.push(row[col.accessor]);
      }
      final_csv.push(one_row);
    }

    let one_row = [];
    for (let col of columns) {
      one_row.push(col.accessor);
    }
    final_csv.unshift(one_row);

    csv_data.current = final_csv;
  }

  async function SaveOdometerChanges() {
    if (!Odometer.starting_odometer_reading || !Odometer.ending_odometer_reading ) {

      return toast.error("Please add starting and ending odometer reading", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

    } else if ( (Number(Odometer.ending_odometer_reading) - Number(Odometer.starting_odometer_reading))  > 300  ) {
      return toast.error("Odometer reading can not be more than 300km", {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }

    let fireFilter = fire
      .database()
      .ref(
        `Driver_Allocation_History/${TableDate.yyyy}/${TableDate.mm}/${Odometer.date}/${Odometer.driver_Phone_Number}`
      );

    fireFilter
      .update({
        starting_odometer_reading: Odometer.starting_odometer_reading,
        ending_odometer_reading: Odometer.ending_odometer_reading,
      })
      .then(() => {
        setShow1(false);

        toast.success("odometer updated successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        callback("");
      })
      .catch((error) => {
        console.error("Data update failed:", error);
        toast.error("Error updating data ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  }

  async function SaveVehicleStatusChanges() {
    if (!selectedOption) {
      return toast.error("Please select option", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }

    let fireFilter = fire
      .database()
      .ref(
        `Driver_Allocation_History/${TableDate.yyyy}/${TableDate.mm}/${RowValue.date}/${RowValue.driver_Phone_Number}`
      );

    fireFilter
      .update({ vehicle_status: selectedOption })
      .then(() => {
        setShow2(false);

        toast.success("Successfully updated", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        callback("");
      })
      .catch((error) => {
        return toast.error("Some error happen", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  }

  const handleDropdownSelect = (eventKey, event) => {
    setSelectedOption(eventKey);
  };

  const data = useMemo(() => TableData, [TableData]);

  const columns = useMemo(
    () => [
      {
        Header: "Delivery Date",
        accessor: "date",
        getSortByToggleProps: () => ({
          handleSort: (event, sortOrder) => {
            console.log("Sorting by name", sortOrder);
          },
          isSorted: true,
        }),
      },
      {
        Header: "Transporter",
        accessor: "company_type",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Route",
        accessor: "current_Route_Id",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Driver",
        accessor: "actual_Driver_Name",
      },

      {
        Header: "Driver No",
        accessor: "driver_Phone_Number",
      },

      {
        Header: "Vehicle No",
        accessor: "vehicle_number",
        Filter: SelectColumnFilter,
      },
      {
        Header: "KM Start",
        accessor: "starting_odometer_reading",
        Cell: ({ row }) => {
          if (row.original.starting_odometer_reading) {
            return <> {row.original.starting_odometer_reading} </>;
          } else {
            return (
              <Button onClick={() => handleShow1(row.original)}>
                {" "}
                update{" "}
              </Button>
            );
          }
        },
      },
      {
        Header: "KM End",
        accessor: "ending_odometer_reading",
        Cell: ({ row }) => {
          if (row.original.ending_odometer_reading) {
            return <> {row.original.ending_odometer_reading} </>;
          } else {
            return (
              <Button onClick={() => handleShow1(row.original)}>
                {" "}
                update{" "}
              </Button>
            );
          }
        },
      },
      {
        Header: "KMs Travelled",
        accessor: "kms_travelled",
      },
      {
        Header: "Short Cash Amt",
        accessor: "adjustments",
      },
      {
        Header: "Stock Short Amt",
        accessor: "short_items",
      },
      {
        Header: "Penalty amt",
        accessor: "penalty_amt",
      },
      {
        Header: "Vehicle status",
        accessor: "vehicle_status",
        Cell: ({ row }) => {
          if (row.original.vehicle_status) {
            return <> {row.original.vehicle_status} </>;
          } else {
            return (
              <Button onClick={() => handleShow2(row.original)}>
                {" "}
                Update{" "}
              </Button>
            );
          }
        },
      },
    ],
    []
  );

  createCsv(data, columns);

  function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    const options = useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);
    
    options.sort()

    return (
      <select
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  }



  const defaultColumn = useMemo(
    () => ({
      Filter: () => null,
    }),
    []
  );

  const GlobalFilter = ({ filter, setFilter }) => {
    const [value, setValue] = useState(filter);
    const onChange = useAsyncDebounce((value) => {
      setFilter(value || undefined);
    }, 1000);
    return (
      <span>
        <input
          placeholder="search..."
          value={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
        />
      </span>
    );
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    preGlobalFilteredRows,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        sortBy: [{ id: "date", desc: true }],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  const { globalFilter } = state;

  return (
    <div>
      <Row>
        <Col>
          <GlobalFilter
            filter={globalFilter}
            setFilter={setGlobalFilter}
          ></GlobalFilter>
        </Col>

        <Col>
          <CSVLink
            className="btn btn-warning export_btn"
            filename={"Detailed_Report.csv"}
            data={csv_data.current}
          >
            Export Data
          </CSVLink>{" "}
        </Col>
        <Col></Col>
        <Col></Col>
        <Col></Col>
        <Col></Col>
        <Col></Col>
        <Col></Col>
        <Col></Col>
      </Row>
      <br />

      <table
        {...getTableProps()}
        style={{ border: "solid 1px blue", width: "100%" }}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{
                    borderBottom: "solid 3px red",
                    background: "aliceblue",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : " "}
                  </span>
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            console.log(row.cells[12].value);
            if (
              row.cells[12].value == "vehicle_breakdown" ||
              row.cells[12].value == "absent"
            ) {
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: "10px",
                          border: "solid 1px gray",
                          background: "#FF8080",
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            } else {
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: "10px",
                          border: "solid 1px gray",
                          background: "papayawhip",
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            }
          })}
        </tbody>
      </table>

      <Modal show={show1} onHide={handleClose1} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Update Odometer Reading </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group>
            <Form.Label> Km start (starting_odometer_reading) </Form.Label>

            {(Odometer.isStartingPresent == true ) ? (
              <>
                <Form.Control
                  type="number"
                  value={Odometer.starting_odometer_reading}
                  disabled
                />
              </>
            ) : (
              <>
                <Form.Control
                  type="number"
                  value={Odometer.starting_odometer_reading}
                  onChange={(e) =>
                    setOdometer({
                      ...Odometer,
                      starting_odometer_reading: e.target.value,
                    })
                  }
                />
              </>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>Km end (ending_odometer_reading) </Form.Label>
            <Form.Control
              type="number"
              value={Odometer.ending_odometer_reading}
              onChange={(e) =>
                setOdometer({
                  ...Odometer,
                  ending_odometer_reading: e.target.value,
                })
              }
            />
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={SaveOdometerChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show2} onHide={handleClose2} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Update Vehicle status </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group>
            <Dropdown onSelect={handleDropdownSelect}>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {selectedOption || "Select an option"}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item eventKey="absent">Absent</Dropdown.Item>
                <Dropdown.Item eventKey="vehicle_breakdown">
                  Vehicle Breakdown
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button variant="primary" onClick={SaveVehicleStatusChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DetailedReportT3;
