import React,{useEffect, useState} from 'react'
import NavBar from "../Navigation bar/Navbar";
import {
    Paper,
    Table,
    Typography,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Collapse,
    Box,
    TextField,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem
  } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import Backdrop from '../backdrop/backdrop'
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import UpdateModal from './updateModal';
import MessageModal from './messageModal';
import RefundModal from './refundModal';
import { service_url } from "../../Configs/mysqlconfig";
import { getEpayOrderNumber, epayApiForPartiallyDelivered, epayApiForDeliveredAndPartiallyDelivered } from './ePayAPIs'
import { // only these four users can see the update or approve button
  validateIsAdminUser, // this user can always update and only this user should be able to approve
  validateIsFinanceHeadUser, // can only update once
  validateIsFinanceUser, // can only update once
  validateIsFinanceMISUser // can only update once
} from "../UserCredentials"; // other users should not see these buttons

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 'auto',  // Push it to the right
    marginRight: theme.spacing(2),  // Add some right margin for spacing
    width: 'auto',
  }));
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '18ch',  // Adjust as needed
      '&:focus': {
        width: '24ch',  // Adjust as needed
      },
    },
  }));


function Home() {
// Get today's date as the default date
const currentDate = new Date();
const formattedDate = currentDate.toISOString().split('T')[0];

const [isApproveDisabled, setIsApproveDisabled] = useState(true);
const [shouldShowButtons, setShouldShowButtons] = useState(true);

const isAdminUser = validateIsAdminUser(JSON.parse(sessionStorage.getItem(`user`))[0].email);
const isFinanceHeadUser = validateIsFinanceHeadUser(JSON.parse(sessionStorage.getItem(`user`))[0].email);
const isFinanceUser = validateIsFinanceUser(JSON.parse(sessionStorage.getItem(`user`))[0].email);
const isFinanceMISUser = validateIsFinanceMISUser(JSON.parse(sessionStorage.getItem(`user`))[0].email);

// Determine button visibility based on user role
let otherRolesShowButton = isFinanceHeadUser || isFinanceUser || isFinanceMISUser;
let adminShowButton = isAdminUser

// otherRolesShowButton = true
useEffect(()=>{
if(adminShowButton){
  // Admin will always have access to both the buttons Approve and Update
  setIsApproveDisabled(false)
}
else if(otherRolesShowButton){
  // Approve button should always be disabled for this user
  // This user can only update once
  setIsApproveDisabled(true)
}
else{
  // Button visibilty is false for other users
  setShouldShowButtons(false)
}
},[])


const [backDrop, setBackDrop] = useState(true);
const [searchValue, setSearchValue] = useState('');
const[fetchData,setFetchData] = useState([])
const [selectedStartDate, setSelectedStartDate] = useState(formattedDate);
const [selectedEndDate, setSelectedEndDate] = useState(formattedDate);
const [goButton, setGoButton] = useState(true);
const [selectedOrderNumber, setOrderNumber] = useState('');
const [selectedUpdateType, setUpdateType] = useState('');

const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
const [previousAmountForUpdateModal,setPreviousAmountForUpdateModal] = useState('');
const [messageModalOpen, setMessageModalOpen] = useState(false);
const [messageModalMsg, setMessageModalMsg] = useState("");
const [messageModalTitle, setMessageModalTitle] = useState("Processing...");
const [epayAmout, setEpayAmount] = useState("")
const [deliveryType, setDeliveryType] = useState("")
// For  returned/replaced orders
const [isRefundModalOpen,setIsRefundModalOpen] = useState(false);
const [refundOrderNumber,setRefundOrderNumber] = useState('');
const [selectedDeliveryDate, setSelectedDeliveryDate] = useState('');
const [orderType, setOrderType] = useState('all');


const handleOrderTypeChange = (event) => {
  setOrderType(event.target.value);
};

// Filter API data based on search value
let filteredData = fetchData.filter(row =>
    Object.values(row).some(value =>
      (value !== null && value.toString().toLowerCase().includes(searchValue.toLowerCase()))
    )
  );

  if (orderType === 'all') {
    filteredData = filteredData.filter(row => {
      return !row.order_number.includes('R') && !row.order_number.includes('OR');
    });
  } else if (orderType === 'returned') {
    filteredData = filteredData.filter(row => {
      return row.order_number.includes('R') || row.order_number.includes('OR');
    });
  }
  


const fetchEpayData = async () => {

     setBackDrop(true); 
  try {
      const response = await fetch(`${service_url}/api/superzop/epay/epay-later?start_date=${selectedStartDate}&end_date=${selectedEndDate}`, 
      {
          method: 'GET'
      });
      const data = await response.json();
      const requiredData = data.data
      setFetchData(requiredData);
      setBackDrop(false);
      console.log("Rdata-->",requiredData);
  } catch (error) {
    setBackDrop(false);
      console.error('Error fetching data:', error);
  }
};

// Fetch data form API
useEffect(()=>{

    if(goButton===true)
    {
      
        fetchEpayData();
        setGoButton(false);
    }

    },[goButton])


// On click of Approve button
useEffect(() => {


    const epaySubmissionToEpayStatusApprove = async () => {

        const epay_order_number = await getEpayOrderNumber(selectedOrderNumber);
        if(epay_order_number===null){
          setMessageModalTitle("Error!")
          setMessageModalMsg(`Order not found in Epay details, for ${selectedOrderNumber}`)
          setMessageModalOpen(true)
          return
        }
      
        
        if (epay_order_number && deliveryType==="partially-delivered"){
       
        const resultForPartial = await epayApiForPartiallyDelivered(epay_order_number,epayAmout)

          if (!resultForPartial.success) {
            // If success is false, show the error message
            setMessageModalTitle("Error!");
            setMessageModalMsg(`${resultForPartial.message}  for ${selectedOrderNumber}`); // Show the specific error message
            setMessageModalOpen(true);
            return;
          }
        }
  
        const resultForDelivered = await epayApiForDeliveredAndPartiallyDelivered(selectedOrderNumber,epayAmout)
       
        if (!resultForDelivered.success) {
          // If success is false, show the error message
          setMessageModalTitle("Error!");
          setMessageModalMsg(`${resultForDelivered.message} for ${selectedOrderNumber} `); // Show the specific error message
          setMessageModalOpen(true);
          return;
        }
        
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "order_number": selectedOrderNumber,
            "submitted_to_epay": 1
          });

        const requestOptions = {
            method: "PUT",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
          };
          


        try {
            const response = await fetch(`${service_url}/api/superzop/epay/epay-submission`, 
            requestOptions);
            const data = await response.json();
            const requiredResponse = data.success
            if (requiredResponse===1)
            {
            setMessageModalTitle('Success')
            setMessageModalMsg(`Successfully Approved against Order Number ${selectedOrderNumber}`)
          }
          else{
            setMessageModalTitle('Error!')
            setMessageModalMsg("Updation was not successful check the API response at epay submission data to Sql-db. ")
          }
            
        } catch (error) {
            setMessageModalMsg(`Some Error Occured During Approval ${error}`)
            console.error('Error fetching data:', error);
        }
    };


    if (selectedUpdateType==="Approve") {
      epaySubmissionToEpayStatusApprove();
      setMessageModalOpen(true)
      setUpdateType("");
    }
  
  
  }, [selectedUpdateType]);


  const refreshPageData = async () => {
    await fetchEpayData(); // Refresh data after successful refund/approve
  };
  

const handleStartDateChange = (event) => {
    
    setSelectedStartDate(event.target.value);

  };


  const handleEndDateChange = (event) => {
    setSelectedEndDate(event.target.value)
  };


  const handleGoButtonClick=()=>{
    setGoButton(true)
  }



const handleRowButtonClick = (order_number, total_amount, delivery_type, selectedStatus, previousAmount) => {
  if (selectedStatus === "Approve") {
    setOrderNumber(order_number);
    setEpayAmount(parseFloat(total_amount))
    setUpdateType("Approve");
    const processDeliveryType = delivery_type.trim().toLowerCase();
    setDeliveryType(processDeliveryType)
  } else if (selectedStatus === "Update") {
    setOrderNumber(order_number);
    setIsUpdateModalOpen(true);
    if(previousAmount){
      setPreviousAmountForUpdateModal(previousAmount)
    }
    else{
      setPreviousAmountForUpdateModal('')
    }
  }
};

const handleRefundButtonClick = (ordNo,deliv_date) =>{
  
  setRefundOrderNumber(ordNo);
  setSelectedDeliveryDate(deliv_date)
  setIsRefundModalOpen(true);
}

  return (

    <div>
    <div style={{marginLeft:"8px",marginRight:"5px"}}>
    <NavBar/>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <div style={{ display: 'flex', marginTop:"8px", alignItems: 'center', justifyContent: 'center', height: '10vh' }}>
          <Typography variant="h4" gutterBottom>Epay Management</Typography>
    </div>
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        {/* Date Selection */}
        <FormControl variant="outlined" style={{ marginRight: '10px' }}>
          <TextField
            type="date"
            id="date"
            onChange={handleStartDateChange}
            value={selectedStartDate}
            label="Select Start Date"
          />
        </FormControl>


        <FormControl variant="outlined" style={{ marginRight: '10px' }}>
          <TextField
            type="date"
            id="date"
            inputProps={{
              max: new Date().toISOString().split("T")[0], 
            }}
            onChange={handleEndDateChange}
            value={selectedEndDate}
            label="Select End Date"
          />
        </FormControl>

        <FormControl variant="outlined" style={{ marginRight: '10px', minWidth: 200 }}>
        <InputLabel id="order-type-label">Order Type</InputLabel>
        <Select
          labelId="order-type-label"
          id="order-type-select"
          value={orderType}
          onChange={handleOrderTypeChange}
          label="Order Type"
        >
          <MenuItem value="all">All Orders</MenuItem>
          <MenuItem value="returned">Returned/Replaced Orders</MenuItem>
        </Select>
      </FormControl>
        <Button variant="contained" onClick={handleGoButtonClick} style={{ marginRight: '10px', backgroundColor:'#573CFA'}}>Go</Button>
       
        <Search sx={{float:'right'}}>
            <SearchIconWrapper>
                <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
                placeholder="Search By Order Number..."
                inputProps={{ 'aria-label': 'search' }}
                onChange={(e) => {
                setSearchValue(e.target.value);
                }}
            />
        </Search>
        
      
      </div> 


<TableContainer component={Paper}>

    <Table stickyHeader>

        <TableHead sx={{ fontWeight: 'bold', position: 'sticky', top: 0, bgcolor: 'background.paper' }}>

            <TableRow>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#573CFA', color: "#ffffff" }}>Delivery Date</TableCell>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#573CFA', color: "#ffffff" }}>Order Number</TableCell>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#573CFA', color: "#ffffff" }}>Warehouse</TableCell>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#573CFA', color: "#ffffff" }}>ASM Phone</TableCell>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#573CFA', color: "#ffffff" }}>Status</TableCell>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#573CFA', color: "#ffffff" }}>Total Amount</TableCell>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#573CFA', color: "#ffffff" }}>Shop Name</TableCell>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#573CFA', color: "#ffffff" }}>Driver ID</TableCell>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#573CFA', color: "#ffffff" }}>Submitted to Epay</TableCell>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#573CFA', color: "#ffffff" }}>Received Amount</TableCell>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#573CFA', color: "#ffffff" }}>Payment Received Date</TableCell>
               {orderType !== 'returned' && ( <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#573CFA', color: "#ffffff" }}>Approve / Update </TableCell>) }
                {orderType == 'returned' && (<TableCell sx={{ fontWeight: 'bold', backgroundColor: '#573CFA', color: "#ffffff" }}>Refund Action</TableCell>)}
            </TableRow>
    </TableHead>

    {filteredData.length!=0 ? <TableBody sx={{ fontWeight: 'bold', backgroundColor: '#ffffff', color: "#ffffff" }} >
            {filteredData.map((order, index) => (
                <TableRow key={index}>

                <TableCell>{order.delivery_date}</TableCell>
                <TableCell>{order.order_number}</TableCell>
                <TableCell>{order.warehouse}</TableCell>
                <TableCell>{order.asmphone}</TableCell>
                <TableCell>{order.status}</TableCell>
                <TableCell>{order.total_amount_paid}</TableCell>
                <TableCell>{order.shop_name}</TableCell>
                <TableCell>{order.driver_id}</TableCell>
                <TableCell>{( order.submitted_to_epay===null || order.submitted_to_epay < 1 ) ?  "Unapproved" : 'Approved'}</TableCell>
                <TableCell>{order.amount || ''}</TableCell>
                <TableCell>{order.payment_received_date || ''}</TableCell>
  { orderType !== 'returned' && shouldShowButtons && ( // other users except 4 users will not be able to see this button
  <TableCell>
    {order.payment_status == 1 ? (
       <Typography variant="body2">Payment Updated</Typography>
    ): (order.submitted_to_epay < 1 ? (
      <Button
        variant="contained"
        color={isApproveDisabled ? "inherit" : "primary"}
        onClick={() => handleRowButtonClick(order.order_number, order.total_amount_paid, order.status, "Approve", order.amount)}
        style={{
          fontSize: '0.7rem',
          padding: '4px 6px',
          backgroundColor: isApproveDisabled ? '#9e9e9e' : '#FF6701',
          color: '#ffffff',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.4)',
        }}
        disabled={isApproveDisabled} // Approve button will be enabled only for the Admin otherwise remains as disabled
      >
        Approve
      </Button>
    ) : (
      adminShowButton ? (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleRowButtonClick(order.order_number, order.total_amount_paid, order.status, "Update", order.amount)}
          style={{
            fontSize: '0.7rem',
            padding: '4px 6px',
            background: '#34A853',
            color: '#ffffff',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.4)',
          }} // this update button will be used by Admin who can always access this button
        >
          Update
        </Button>
      ) : (
        <Button
          variant="contained"
          color={order.amount ? "inherit" : "primary"}
          onClick={() => handleRowButtonClick(order.order_number, order.total_amount_paid, order.status, "Update", order.amount)}
          style={{
            fontSize: '0.7rem',
            padding: '4px 6px',
            backgroundColor: order.amount ? '#9e9e9e' : '#34A853',
            color: '#ffffff',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.4)',
          }}
          disabled={order.amount} // order.amount is NULL by default after one update it has values then disable button gets activated
        >
          Update
        </Button>
      )
    ))}
  </TableCell>

)}
{orderType === 'returned' && (
<TableCell>
  {order.order_number.includes('OR') ? (
    order.refund_status === 1 ? (
      <Typography variant="body2">Refund Processed</Typography>
    ) : (
      <Button 
        variant="outlined"  
        onClick={() => handleRefundButtonClick(order.order_number, order.delivery_date)}
      >
        Initiate Refund
      </Button>
    )
  ) : order.order_number.includes('R') ? (
    <Typography variant="body2">Not Applicable</Typography>
  ) : null}
</TableCell>
)}
                </TableRow>
            ))}
    </TableBody> :
    <TableBody>
        <TableRow>
          <TableCell colSpan={13}>No Data Found</TableCell>
        </TableRow>
      </TableBody>
      }

    </Table>
    
</TableContainer>

        <UpdateModal
        open={isUpdateModalOpen}
        handleClose={() => setIsUpdateModalOpen(false)}
        orderNumber={selectedOrderNumber}
        previousAmount = {previousAmountForUpdateModal}
        onUpdateSuccess = {refreshPageData}
        />

<   MessageModal
    onOpen={messageModalOpen}
    onCloseFunction={() => {setMessageModalOpen(false); setMessageModalMsg('');setMessageModalTitle('');}}
    message={messageModalMsg}
    title={messageModalTitle}
    onApproveSuccess = {refreshPageData}
    />

<RefundModal
open = {isRefundModalOpen}
handleClose ={() => setIsRefundModalOpen(false)}
orderNumber = {refundOrderNumber}
deliveryDate = {selectedDeliveryDate}
onRefundSuccess={refreshPageData}

/>
        {backDrop?(<Backdrop parentLoadStatus={backDrop}/>):(null)}
    </div>

</div>
  )
}

export default Home




