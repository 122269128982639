import React from 'react';
import { FooterContainer } from './wrapper';

const FooterComp = () => {
    return (
        <FooterContainer>
            &copy; Copyright 2016 - 2024 Retranz Infolabs Pvt. Ltd
        </FooterContainer>
    );
};

export default FooterComp;
