import React, { useEffect,useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import NavBar from '../Navigation bar/Navbar'
import fire from '../../Configs/firebase'
import Backdrop from '../backdrop/backdrop'
import { toast } from 'react-toastify';
import { InputGroupAddon, Input, InputGroupText, CardHeader, CardTitle, CardText } from 'reactstrap';
import {Tab,Row,Col,Nav,Form,Dropdown,InputGroup,Button,DropdownButton,Breadcrumb,Table,ButtonGroup,Container} from 'react-bootstrap';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { validateIsAdminUser } from '../UserCredentials'
import UpdatedDate from '../Orders/UpdatedDate'

function formatDate(date) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

const SuperKreditOrders = () => {

  const [deliveryDate,setDeliveryDate] = useState(new Date());
  const [ordersObj, setOrdersObj] = useState({});
  const [searchOrdersObj, setSearchOrdersObj] = useState({});
  const [backDrop, setBackDrop] = useState(false);
  const [isDiffAmtFilterChecked, setIsDiffAmtFilterChecked] = useState(true);

  toast.configure({
      autoClose: 4000,
      draggable: true,
      pauseOnHover: true,
      position: toast.POSITION.TOP_LEFT,
  });

  useEffect(() => {
    if(deliveryDate){
      fetchSuperkreditReports();
    }
  }, [deliveryDate])

  useEffect(() => {
    let filteredOrdersObj = {};
    Object.keys(searchOrdersObj).map((key,index)=>{
      let diffAmt = Number(searchOrdersObj[key].diff_amt) || 0;

      if(isDiffAmtFilterChecked){
        if(diffAmt>0){
          filteredOrdersObj[key] = searchOrdersObj[key];
        }
      }else{
        if(diffAmt<=0){
          filteredOrdersObj[key] = searchOrdersObj[key];
        }
      }
    })

    setOrdersObj(filteredOrdersObj);
  },[isDiffAmtFilterChecked])


  const fetchSuperkreditReports = () => {
    setBackDrop(true);

    let formattedDeliveryDate = formatDate(new Date(deliveryDate));
    let year = deliveryDate.getFullYear();
    let month = 1+deliveryDate.getMonth();
    let date = deliveryDate.getDate();
    let finaldate = `${date}-${month}-${year}`;

    fire.database().ref(`SuperKredit_Updates/${year}/${month}/${finaldate}`).once('value').then((snapshot) => {
      let updatedSuperkreditOrdersObj = snapshot.val() || {};
      console.log(updatedSuperkreditOrdersObj);

      let filteredOrdersObj = {};
      Object.keys(updatedSuperkreditOrdersObj).map((key,index)=>{
        let diffAmt = Number(updatedSuperkreditOrdersObj[key].diff_amt) || 0;

        if(diffAmt>0){
          filteredOrdersObj[key] = updatedSuperkreditOrdersObj[key];
        }
      })

      setBackDrop(false);
      setOrdersObj(filteredOrdersObj);
      setSearchOrdersObj(updatedSuperkreditOrdersObj);
    })
  }

  const KreditOrdersTableHeader = () => {
    return (
        <thead className="thead-light">
            <tr>
              <th>Order Number</th>
              <th>Status</th>
              <th>Retailer ID</th>
              <th>Available credit</th>
              <th>Updated available credit</th>
              <th>Ordered amt</th>
              <th>Actual Delivered Amt</th>
              <th>Diff Amt</th>
              <th>Payment Mode</th>
              <th>Cheque Amt</th>
              <th>Cheque Number</th>
              <th>Error Available Credit</th>
            </tr>
        </thead>
    );
  }

  const KreditOrdersTableBody = () => {
    let rows = Object.keys(ordersObj).map((key, index) => {
        return (
            <tr key={index}  style={{backgroundColor:!ordersObj[key].superkredit_flag?"#ff3333":"white"}}>
                <td>{ordersObj[key].order_number}</td>
                <td>{ordersObj[key].status}</td>
                <td>{ordersObj[key].retailer_id}</td>
                <td>{ordersObj[key].available_credit}</td>
                <td>{ordersObj[key].updated_available_credit}</td>
                <td>{ordersObj[key].order_amt}</td>
                <td>{ordersObj[key].actual_delivered_amt}</td>
                <td>{ordersObj[key].diff_amt}</td>
                <td>{ordersObj[key].payment_mode}</td>
                <td>{ordersObj[key].cheque_amt}</td>
                <td>{ordersObj[key].cheque_number}</td>
                <td>{ordersObj[key].error_available_credit}</td>
            </tr>
        );
    });

    return (<><tbody>{rows}</tbody></>);
  }

  const handleDeliveryDateChange = (selecteddate) => {
    setDeliveryDate(new Date(selecteddate));
  }

  const CustomDateInput = ({ value, onClick }) => (
    <input
        value={value}
        onClick={onClick}
        style={{ border: "solid 1px black", height: "30px" }}
    />
  );



    return (<>
      <NavBar/>
      <div className="container col-sm-12" style={{paddingTop:100}}>
        <center><h2 className="h2">SuperKredit Reports</h2></center>
        <Form inline style={{margin:30, width:'90%', marginLeft: "auto",marginRight: "auto", paddingTop:10}}>
            <InputGroup size="sm" style = {{ margin : 5}}>
              <InputGroupAddon addonType="prepend">Delivery Date</InputGroupAddon>
                    <DatePicker
                        selected={deliveryDate}
                        onChange={(handleDeliveryDateChange)}
                        minDate={new Date("2017/01/01")}
                        aria-describedby="basic-addon1"
                        dateFormat="dd/MM/yyyy"
                        customInput={<CustomDateInput />}
                    />
            </InputGroup>
        </Form>

        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <Input addon type="checkbox" defaultChecked={isDiffAmtFilterChecked} onChange={(e)=>{ setIsDiffAmtFilterChecked(e.target.checked) }} />
          </InputGroupText>
          Diff Amount Updated
        </InputGroupAddon>

        {Object.keys(ordersObj).length>0?(<table className="table table-striped" style={{textAlign:"center"}}>
            <KreditOrdersTableHeader />
            <KreditOrdersTableBody />
        </table>):(<center><div>No superkredit orders</div></center>)}


        {backDrop?(<Backdrop parentLoadStatus={backDrop}/>):(null)}
      </div></>
    )
}

export default SuperKreditOrders
