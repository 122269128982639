import React, { useState, useEffect } from 'react'
import { Table, Form, Alert, Button,Modal } from 'react-bootstrap'
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import Backdrop from '../backdrop/backdrop'
import {support_portal_url} from '../../Configs/mysqlconfig';

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

function SyncDeliveryOrdersModal({...props}) {
  console.log('SyncDeliveryOrdersModal');
  const [itemDetailsObject,setItemDetailsObject]=useState({});
  const [loading,setLoading] = useState(false);
  let currdate=formatDate(new Date());
  let currdateminusfourdays = new Date(currdate)
  currdateminusfourdays.setDate(currdateminusfourdays.getDate() -4);
  const [startDate,setStartDate] = useState(formatDate(currdateminusfourdays));
  const [endDate,setEndDate] = useState(currdate);
  const [absentOrders,setAbsentOrders] = useState("");


    const syncDeliveryOrders = () => {
      console.log('syncDeliveryOrders');
      setLoading(true);
      console.log(itemDetailsObject);
      let startdate=itemDetailsObject.startdate?itemDetailsObject.startdate:startDate;
      let enddate=itemDetailsObject.enddate?itemDetailsObject.enddate:endDate;
      let apiele={"startdate":startdate,"enddate":enddate};
      console.log(apiele);

      fetch(`${support_portal_url}/api/syncdeliveryorders`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': sessionStorage.getItem("apiToken")
        },
        body: JSON.stringify(apiele)
      }).then(res => {
          return res.json()
      }).then(data => {
        console.log(data);
        if(data["success"]==1){
          toast("Successfully synced DeliveryOrdersList", { type: toast.TYPE.SUCCESS });
          setLoading(false);

          setTimeout( function() {
            window.location.reload();
          }, 3000);
          props.onHide();
        }else{
          let errMessage=data['message']?data['message']:"";
          if(errMessage=="Missing Orders present"){
            let missingorders=data["missingorders"];
            console.log(missingorders);
            let missingordersstr="";
            let count=0;
            for(let mo in missingorders){
              count=count+1;
              missingordersstr=missingordersstr+missingorders[mo]+",";
              if(count%6==0){
                missingordersstr=missingordersstr+"\n";
              }
            }
            if(missingordersstr.length>0){
              missingordersstr=missingordersstr.slice(0,-1);
            }
            toast("Error in syncing DeliveryOrdersList:"+errMessage, { type: toast.TYPE.ERROR });
            setLoading(false);
            setAbsentOrders(missingordersstr);
          }else{
            toast("Error in syncing DeliveryOrdersList:"+errMessage, { type: toast.TYPE.ERROR });
            setLoading(false);
            props.onHide();
            if(data['status']==401 || data['status']==403){
              sessionStorage.clear();
              window.location.href="/"
            }
          }
        }
      }).catch(err => {
        console.log(err)
        toast("Error in syncing DeliveryOrdersList:"+err, { type: toast.TYPE.ERROR })
        props.onHide();
      })
    }

    return (<>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    Sync Delivery Orders
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {absentOrders.length>0?(<p>Missing Orders : <br/>{absentOrders}</p>):null}
                <Table>
                    <tbody>
                        <tr>
                            <td>Start Date</td>
                            <td><Form.Control type="date" defaultValue={startDate} onChange={(e)=>{ setItemDetailsObject({...itemDetailsObject,"startdate":(e.target.value)}); }}/></td>
                        </tr>
                        <tr>
                            <td>End Date</td>
                            <td><Form.Control type="date" defaultValue={endDate} onChange={(e)=>{ setItemDetailsObject({...itemDetailsObject,"enddate":(e.target.value)}); }}/></td>
                        </tr>
                    </tbody>
                </Table>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={()=>{syncDeliveryOrders()}}>Sync Delivery Orders</Button>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
            {loading ? (<Backdrop parentLoadStatus={loading} />) : (null)}
        </>
    )
}

export default SyncDeliveryOrdersModal
