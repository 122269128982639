import React, { useState, useEffect, useRef, useMemo } from "react";
import { useParams } from "react-router-dom";
import fire from "../../Configs/firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NavBar from "../Navigation bar/Navbar";
import { CSVLink } from "react-csv";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Form,
  Dropdown,
  Spinner
} from "react-bootstrap";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useAsyncDebounce,
} from "react-table";

let today = new Date();
let dd = String(today.getDate()).padStart(2, "0");
let mm = String(today.getMonth() + 1).padStart(2, "0");
let yyyy = today.getFullYear();
var today_date = yyyy + "-" + mm + "-" + dd;

function UnDeliveredOrders() {

  let { dateVal } = useParams();
  let start_date 

  if (dateVal === "" || dateVal === undefined) {
    start_date = today_date
  }else{
    start_date = dateVal
  }

  // const [CsvData, setCsvData] = useState()
  let CsvData = useRef("");
  const [TableDate, setTableDate] = useState(start_date)
  const [show1, setShow1] = useState(false);
  const [DriverOrdersHistory, setDriverOrdersHistory] = useState({});
  const [DriverAllocationHistory, setDriverAllocationHistory] = useState([]);
  const [SameOrderKey, setSameOrderKey] = useState();
  const [Reload, setReload] = useState(false)
  const [Loading, setLoading] = useState(false)
  const [ActionTaken, setActionTaken] = useState({
    row_id : '',
    value : ''
  })

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => {
    toast("showing tost massage please remove this on  handleShow1 fun");
    setShow1(true);
  };

  function fetchAllocationHistory(selected_date) {

    return new Promise((resolve, reject) => {

      let selectedDate = new Date(selected_date); //"2022-10-20"
      let year = selectedDate.getFullYear();
      let month = selectedDate.getMonth() + 1;
      let date = selectedDate.getDate() + "-" + month + "-" + year;


      fire
        .database()
        .ref(
          "/Driver_Allocation_History/" + year + "/" + month + "/" + date + "/"
        )
        .once("value", (snapshot) => {
          if (snapshot.hasChildren()) {
            const snap_data = snapshot.val();

            let dal_data = {};
            for (let key_id in snap_data ){
              dal_data[snap_data[key_id].current_Route_Id] = snap_data[key_id]
            }
 
            resolve(dal_data);

          } else {
            reject();
          }
        });
    });
  }

  function fetchOrdersHistory(selected_date) {

    return new Promise((resolve, reject) => {
   
      let selectedDate = new Date(selected_date); // "2022-10-20"
      let year = selectedDate.getFullYear();
      let month = selectedDate.getMonth() + 1;
      let date = selectedDate.getDate() + "-" + month + "-" + year;
  
      
      fire
        .database()
        .ref("Driver_Orders_History/" + year + "/" + month + "/" + date + "/")
        .once("value", (snapshot) => {

   

          if (snapshot.hasChildren()) {
            const snap_data = snapshot.val();
            let list_of_orders = {};
            let same_oder_key = {};
            for (let route_id in snap_data) {
              for (let oder_no in snap_data[route_id]) {
                
                let splitted_id = oder_no.split("-");
                let odr_key =
                  splitted_id[0] + "-" + splitted_id[1] + "@" + route_id;
                if (list_of_orders[odr_key] === undefined) {
                  list_of_orders[odr_key] = snap_data[route_id][oder_no];
                  same_oder_key[odr_key] = [];
                }

                same_oder_key[odr_key].push(oder_no);

              }
            }

            setSameOrderKey(same_oder_key);
            resolve(list_of_orders);
          } else {
            reject();
          }
        });
    });
  }

  function handleActualReasonChange(row, value) {

    console.log(row );
    console.log(value );
    // action_taken

    if ( ActionTaken.row_id !== row.id && row.action_taken == undefined ){
      toast.error("Pleases select action taken for order number " + row.order_number ,{
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        })
      return 
    }

    let [order_number_key, root] = row.id.split("@");
    let final_update_object = {};
    let final_take_action = {}
    for (let keys of SameOrderKey[row.id]) {
      final_update_object[keys + "/actual_reason"] = value;
      final_take_action[keys + "/action_taken"] = ActionTaken.value
    }
    console.log(final_update_object);

    let selectedDate = new Date(TableDate); // "2022-10-20"
    let year = selectedDate.getFullYear();
    let month = selectedDate.getMonth() + 1;
    let date = selectedDate.getDate() + "-" + month + "-" + year;

    fire
      .database()
      .ref(`Driver_Orders_History/${year}/${month}/${date}/${root}/`)
      .update(final_update_object);
      
    fire
    .database()
    .ref(`Driver_Orders_History/${year}/${month}/${date}/${root}/`)
    .update(final_take_action);
    
      toast("Success update")
      setReload(!Reload);

      
      
  }

  function createCsv(data,columns) {

    let final_csv = []
    for (let row of data){

      let one_row = []
      for (let col of columns){

        if (col.accessor === "actual_reason" && row[col.accessor] === undefined ){
          one_row.push(row["reason"])
          continue
        }

        one_row.push(row[col.accessor])
      }
      final_csv.push(one_row)
    }

    let one_row = []
    for (let col of columns){
      one_row.push(col.accessor)
    }
    final_csv.unshift(one_row)
    // setCsvData(final_csv)
    CsvData.current = final_csv;


  }



  useEffect(() => {
    setLoading(true)

    Promise.all([fetchAllocationHistory(TableDate), fetchOrdersHistory(TableDate)])
      .then((values) => {
        setDriverAllocationHistory(values[0]);
        setDriverOrdersHistory(values[1]);
        setLoading(false)

      })
      .catch((error) => {
        setLoading(false)
        toast('No data for data ' + TableDate)
        console.log(error);
      });

    return () => {};
  }, [Reload,TableDate]);

  function Table1() {
    let table_date = [];
    console.log(DriverOrdersHistory);
    Object.keys(DriverOrdersHistory).map((id) => {
      if (
        DriverOrdersHistory[id].status == "Pending" ||
        DriverOrdersHistory[id].status == "Cancelled"
      ) {
        table_date.push({
          id: id,
          area: DriverOrdersHistory[id].area,
          driver_id: DriverOrdersHistory[id].driver_id,
          actual_Driver_Name : DriverAllocationHistory[DriverOrdersHistory[id]?.driver_id]?.actual_Driver_Name,
          driver_Phone_Number : DriverAllocationHistory[DriverOrdersHistory[id]?.driver_id]?.driver_Phone_Number,
          order_amt: DriverOrdersHistory[id].order_amt,
          order_date: DriverOrdersHistory[id].order_date,
          order_number: DriverOrdersHistory[id].order_number,
          shop_name: DriverOrdersHistory[id].shop_name,
          status: DriverOrdersHistory[id].status,
          reason: DriverOrdersHistory[id]?.reason,
          actual_reason: DriverOrdersHistory[id].actual_reason,
          actualreason: DriverOrdersHistory[id].actual_reason || DriverOrdersHistory[id]?.reason,
          route_id: id.split("@")[1],
          company_type : DriverAllocationHistory[DriverOrdersHistory[id]?.driver_id]?.company_type,
          city : DriverOrdersHistory[id].city,
          action_taken : DriverOrdersHistory[id].action_taken,
        });
      }
    });


    const data = useMemo(() => table_date, []);

    const columns = useMemo(
      () => [
        {
          Header: "Status",
          accessor: "status",
          Filter: SelectColumnFilter,

        },
        {
          Header: "Warehouse",
          accessor: "city",
          Filter: SelectColumnFilter
        },
        {
          Header: "Route ID",
          accessor: "route_id",
        },
        {
          Header: "Transporter",
          accessor: "company_type",
          Filter: SelectColumnFilter,
        },
        {
          Header: "Driver Name",
          accessor: "actual_Driver_Name",
        },
        {
          Header: "Phone Number",
          accessor: "driver_Phone_Number",
        },
        {
          Header: "Shop Name",
          accessor: "shop_name",
        },
        {
          Header: "Area",
          accessor: "area",
          Filter: SelectColumnFilter,
        },
        {
          Header: "Order Number",
          accessor: "order_number",
        },
        {
          Header: "Order Date",
          accessor: "order_date",
        },
        {
          Header: "Order Amount",
          accessor: "order_amt",
        },
        {
          Header: "Action Taken",
          accessor: "action_taken",
          Cell: ({ row }) => {
            
            return (
              <div  >
                <select 
   
                  // value = { (row.original.id == ActionTaken.row_id) ?  ActionTaken.value : "Select"}
                  value = {  (row.original.id == ActionTaken.row_id) ?  ActionTaken.value : row.original.action_taken }
                  
                  onChange={ (e) => { setActionTaken({ row_id : row.original.id, value : e.target.value }) } }
                >
                  <option value=" ">Select</option>
                  <option value="Spoke to SE">Spoke to SE</option>
                  <option value="Spoke to DASM">Spoke to DASM</option>
                  <option value="Spoke to ZSM">Spoke to ZSM</option>
                </select>
              </div>
            );

          }
        },
        {
          Header: "Reason",
          accessor: "actualreason",
          Filter: SelectColumnFilter,
          Cell: ({ row }) => {
 
            if (row.original.status === "Pending") {

              return (
                <>
                  <select
                    value={row.original.actual_reason || row.original.reason  }
                    onChange={(e) =>
                      handleActualReasonChange(row.original, e.target.value)
                    }
                  >
                    {/* {row.original.actual_reason === undefined ? (
                      <option value="">
                        {row.original.reason ||
                          "Select Partially Delivered Reason"}
                      </option>
                    ) : (
                      <option value="">
                        Select Partially Delivered Reason
                      </option>
                    )} */}
                  

                    <option value="Cash not available">Cash not available</option>
                    <option value="Not Visited">Not Visited</option>
                    <option value="Shop Closed">Shop Closed</option>
                    <option value="Quality issue">Quality issue</option>
                    <option value="Short material loaded">
                      Short material loaded
                    </option>
                  </select>
                </>
              );
            } else if (row.original.status === "Cancelled") {
              return (
                <>
                  <select
                    value={row.original.actual_reason || row.original.reason }
                    onChange={(e) =>
                      handleActualReasonChange(row.original, e.target.value)
                    }
                  >
                    {/* {row.original.actual_reason === undefined ? (
                      <option value="">
                        {row.original.reason || "Select Cancellation reason"}
                      </option>
                    ) : (
                      <option value=""> Select Cancellation reason</option>
                    )} */}

              
                    <option value="Quality Issue">Quality Issue</option>
                    <option value="Wrongly Ordered">Wrongly Ordered</option>
                    <option value="Rate Issue">Rate Issue</option>
                    <option value="Weight Issue">Weight Issue</option>
                    <option value="Cash Not Available"> Cash Not Available </option>
                    <option value="Late Delivery">Late Delivery </option>
                    <option value="Shop closed">Shop closed</option>
                    <option value="Short material loaded">Short material loaded</option>


                  </select>
                </>
              );
            } else {
              return <></>;
            }
          },
        },
      
   
      ],
      []
    );
    createCsv(data,columns)

    function SelectColumnFilter({
      column: { filterValue, setFilter, preFilteredRows, id },
    }) {
      const options = useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
          options.add(row.values[id]);
        });
        return [...options.values()];
      }, [id, preFilteredRows]);

      return (
        <select
          value={filterValue}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
        >
          <option value="">All</option>
          {options.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </select>
      );
    }

    const defaultColumn = useMemo(
      () => ({
        Filter: () => null,
      }),
      []
    );

    const GlobalFilter = ({ filter, setFilter }) => {
      const [value, setValue] = useState(filter);
      const onChange = useAsyncDebounce((value) => {
        setFilter(value || undefined);
      }, 1000);
      return (
        <span>
          <Form.Control
            placeholder="search..."
            value={value || ""}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
          />
        </span>
      );
    };

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      setGlobalFilter,
      preGlobalFilteredRows,
    } = useTable({ columns, data, defaultColumn }, useFilters, useGlobalFilter);

    const { globalFilter } = state;

    return (
      <div>
        <Row>
            <GlobalFilter
              filter={globalFilter}
              setFilter={setGlobalFilter}
              style={{ magin:"10",  width: "50%" }}
            ></GlobalFilter>
          <Col>
            <CSVLink
              className="btn btn-warning export_btn"
              filename={"Panding_Cancelled_Orders.csv"}
              data={CsvData.current}
            >
              Export Data
            </CSVLink>{" "}
          </Col>
          <Col>
          </Col>
          <Col>
          </Col>
          <Col></Col>
          <Col></Col>
          <Col></Col>
          <Col></Col>
          <Col></Col>
        </Row>
        <br />

        <table
          {...getTableProps()}
          style={{ border: "solid 1px blue", width: "100%" }}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{
                      borderBottom: "solid 3px red",
                      background: "aliceblue",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {column.render("Header")}
                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    if (row.original.actual_reason !== undefined){
                      return (
                        <td
                          {...cell.getCellProps()}
                          style={{
                            padding: "10px",
                            border: "solid 1px gray",
                            background: "yellow",
                          }}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    }else{
                      return (
                        <td
                          {...cell.getCellProps()}
                          style={{
                            padding: "10px",
                            border: "solid 1px gray",
                            background: "papayawhip",
                          }}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    }
                
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  return (<>
      <NavBar />
      <br/>
      <br/>
      <br/>
      <br/>
    <div style={{ margin: 20 }}>


      <h2> All Pending / Cancelled Orders {Loading && ( <Spinner animation="border" role="status"> </Spinner>)}</h2>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

  <Row>
      <Col xs={3} >
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Control
            type="date"
            value={TableDate}
            onChange={(e) => setTableDate( e.target.value )}
          />
        </Form.Group>
        </Col>
    </Row>
       
    

      <Row>
        <Col>
          <Table1 />
        </Col>
      
      </Row>

      <Modal show={show1} onHide={handleClose1} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>name</Form.Label>
            <Form.Control type="test" onChange={(e) => {}} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose1}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
    </>);
}

export default UnDeliveredOrders;
