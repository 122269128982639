import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  createStyles,
  Table,
  Grid,
  Stack,
  Container,
  Title,
  Group,
  Text,
  Select,
  Button,
  Space,
  Center,
  TextInput,
  Loader,
  Tooltip,
  SimpleGrid,
} from "@mantine/core";
import styled from "styled-components";
import moment from "moment";
import { DatePicker } from "@mantine/dates";
import { forwardRef } from "react";
// import UpdateOrderStatusModal from "./modals/update-order-status.modal";
import OrderStatusBadge from "./components/order-status-badge";
// import ViewProductsModal from "./modals/view-products.modal";
import { IconEdit, IconPencil, IconQrcode } from "@tabler/icons-react";
import { useDebouncedValue } from "@mantine/hooks";
import { read, utils, writeFile, writeFileXLSX } from "xlsx";
import { toast, ToastContainer, Zoom } from "react-toastify";
import axios from "axios";
import { service_url } from "../../Configs/mysqlconfig";
import NavBar from "../Navigation bar/Navbar";

const Header = styled(Container)`
  height: 20vh;
  width: 100%;
  padding: 0.5rem;
`;

const StatsDisplayRow = styled.div`
  width: 100%;
  background-color: #e9f2ef;
  border-top: 0.1rem solid #495057;
  border-bottom: 0.1rem solid #495057;
`;

const SearchInput = styled(TextInput)`
  width: 22rem;
`;

const QRInput = styled(TextInput)`
  width: 20rem;
`;

const StyledTable = styled(Table)`
  thead tr th {
    text-align: center;
  }
  tbody tr td {
    text-align: center;
  }
`;

const useStyles = createStyles((theme) => ({
  header: {
    position: "sticky",
    zIndex: 1,
    top: 0,
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    transition: "box-shadow 150ms ease",

    "&::after": {
      content: '""',
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      borderBottom: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[3]
          : theme.colors.gray[2]
      }`,
    },
  },

  scrolled: {
    boxShadow: theme.shadows.sm,
  },
}));

const DistributorSelectItem = forwardRef(({ label, phone, ...others }, ref) => (
  <div ref={ref} {...others}>
    <Group noWrap>
      <div>
        <Text size="sm">{label}</Text>
        <Text size="xs" opacity={0.65}>
          {phone}
        </Text>
      </div>
    </Group>
  </div>
));

const StatusSelectItem = forwardRef(({ label, ...others }, ref) => (
  <div ref={ref} {...others}>
    <Group noWrap>
      <div>
        {label !== "All" ? <OrderStatusBadge status={label} /> : "All"}

        {/* <Text size="sm">{item}</Text>
          <Text size="xs" opacity={0.65}>
            {phone}
          </Text> */}
      </div>
    </Group>
  </div>
));

// const searchWorker = new Worker(
//   new URL("./web-workers/order-search.worker.js", import.meta.url)
// );

function BagCheckReport() {
  const [selectedOrderData, setSelectedOrderData] = useState({});
  const [updateStatusModalOpened, setUpdateStatusModalOpened] = useState(false);
  const [viewProductsModalOpened, setViewProductsModalOpened] = useState(false);
  const [ordersDataRaw, setOrdersDataRaw] = useState([]);
  const [ordersData, setOrdersData] = useState([]);
  const [ordersStats, setOrdersStats] = useState({});
  const [QRId, setQRId] = useState("");
  const [debouncedSearchTerm] = useDebouncedValue(QRId, 500);
  const [isSearchResultsLoading, setIsSearchResultsLoading] = useState(false);
  const [dataFetchedForQR, setDataFetchedForQR] = useState(null);

  const [currentStatus, setCurrentStatus] = useState("");
  const [grnDate, setGrnDate] = useState("");
  const [lastStockCountDate, setLastStockCountDate] = useState();

  const { classes, cx } = useStyles();
  const [scrolled, setScrolled] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dataFetchedForDate, setDataFetchedForDate] = useState(false);
  useEffect(() => {
    getRecordsByDate();
  }, []);

  //   const { user } = useContext(AuthenticationContext);

  const prepareDataForExport = (orders_data) => {
    if (!orders_data) return;

    return orders_data.map((item, index) => {
      let {
        qr_id,
        warehouse,
        route,
        item_id,
        picking,
        picking_user,        
        loading,
        loading_user,
        transit_transfer,
        transit_transfer_user,
        received_at_depot,
        received_at_depot_user,
        delivery_date,
        delivered_status,
        stock_check,
        stock_check_user,
        transit_return,
        transit_return_user,
        received_at_dc,
        dc_grn_user,
        customer_return,
        replacement_return,
        stock_conversion,
        stock_conversion_user,
        dump,
        dump_user,

      } = item;
      return {
        "QR ID": qr_id,
        "Warehouse/ Depot": warehouse,
        Route: route,
        "Item Id":item_id,
        "Picking Date": picking,
        "Picking User": picking_user,        
        "Loading Date": loading,
        "Loading User": loading_user,
        "Transit Transfer":transit_transfer,
        "Transit Transfer User":transit_transfer_user,
        "Received At Depot" : received_at_depot,
        "Received At Depot User" : received_at_depot_user,
        "Delivery Date": delivery_date,
        Delivered: delivered_status,
        "Stock Check": stock_check,
        "Stock Check User": stock_check_user,
        "Transit Return": transit_return,
        "Transit Return User": transit_return_user,
        "Received at DC": received_at_dc,
        "DC GRN User": dc_grn_user,
        "Customer Return": customer_return,
        "Replacement Return":replacement_return,
        "Stock Conversion": stock_conversion,
        "Stock Conversion User": stock_conversion_user,
        "Dump": dump,
        "Dump User": dump_user,
      };
    });
  };

  const exportFile = useCallback(async () => {
    const date = moment(selectedDate).format("YYYY-MM-DD");
    const url = `${service_url}/api/bag-tracking?delivery_date=${date}`;
  
    let toast_id = toast("Fetching data, Please wait", {
      type: toast.TYPE.SUCCESS,
      autoClose: false,
      position: "top-right",
    });
  
    try {
      const res = await axios.get(url);
      const orders = res.data?.data || [];
  
      if (orders.length === 0) {
        toast.update(toast_id, {
          render: "No data to export",
          type: toast.TYPE.WARNING,
          autoClose: 2000,
          closeButton: true,
        });
        return;
      }
  
      const ws = utils.json_to_sheet(prepareDataForExport(orders));
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Bag Check Report");
      writeFile(wb, "bag-check-report.xlsx");
  
      toast.update(toast_id, {
        render: "Report exported",
        type: toast.TYPE.SUCCESS,
        autoClose: 2000,
        closeButton: true,
      });
    } catch (err) {
      console.log(err);
      toast.update(toast_id, {
        render: "Unable to export report",
        type: toast.TYPE.ERROR,
        autoClose: 2000,
        closeButton: true,
      });
    }
  }, [selectedDate]);


  function getDistributors() {
    // DistributorsGET()
    //   .then((res) => {
    //     let distributorRes = res.data.data;
    //     let distributorData = [{ label: "All", value: "All", phone: "" }];
    //     distributorRes.forEach((item) => {
    //       const distributor = {
    //         label: `${item.id} ${item.name}`,
    //         value: item.id,
    //         phone: item.phone,
    //       };
    //       distributorData.push(distributor);
    //     });
    //     // console.log("check res", distributorData);
    //     setAllDistributors(distributorData);
    //   })
    //   .catch((err) => {
    //     setAllDistributors([]);
    //     console.log(err);
    //   });
  }

  const getGRNRecords = useCallback(
    function () {
      if (!QRId) {
        return toast("Please enter qr id", {
          type: toast.TYPE.ERROR,
        });
      }

      if (QRId && (QRId.length < 20 || QRId.length > 23)) {
        return toast("QR id length should be between 20 and 23 characters", {
          type: toast.TYPE.ERROR,
          closeButton: false
        });
      }
      const url = `${service_url}/api/bag-tracking?qr_id=${QRId}`;

      let toast_id = toast("Fetching data, Please wait", {
        type: toast.TYPE.SUCCESS,
        autoClose: false,
        position: "top-right",
      });

      setOrdersData([]);
      setDataFetchedForQR(null);
      setCurrentStatus("");
      setGrnDate("");
      setLastStockCountDate(null);

      axios
        .get(url)
        .then((res) => {
          const orders = res.data?.data;
          console.log("check orders", orders);

          let _grnDate = null;
          let _currentStatus = null;
          let _lastStockCountDate = null;

          if (orders.length > 0) {
            _grnDate = orders[0].grn_date;
            _currentStatus = orders[0].status;
            _lastStockCountDate = orders[0].stock_count_date;
          }

          setGrnDate(_grnDate);
          setCurrentStatus(_currentStatus);
          setLastStockCountDate(_lastStockCountDate);
          setOrdersData(orders);
          setOrdersDataRaw(orders);
          setDataFetchedForQR(QRId);
          setDataFetchedForDate(false);
          //   setOrdersStats({
          //     total_orders_amount: orders.total_order_amount,
          //     total_orders: orders.total_orders,
          //   });
          setQRId("");
          setIsSearchResultsLoading(false);
        })
        .catch((err) => {
          setOrdersData([]);
          setOrdersDataRaw([]);

          setOrdersStats({});
          setQRId("");
          setDataFetchedForQR(null);
          console.log(err);
          toast(`Error : Unable to fetch data, ${err}`, {
            type: toast.TYPE.ERROR,
          });
        })
        .finally(() => {
          toast.dismiss(toast_id);
        });
    },
    [QRId]
  );

  const getRecordsByDate = useCallback(
    function () {
      const formattedDate = moment(selectedDate).format('YYYY-MM-DD');
      const url = `${service_url}/api/bag-tracking?delivery_date=${formattedDate}`;

      let toast_id = toast("Fetching data, Please wait", {
        type: toast.TYPE.SUCCESS,
        autoClose: false,
        position: "top-right",
      });

      setOrdersData([]);
      setDataFetchedForDate(false);
      setCurrentStatus("");
      setGrnDate("");
      setLastStockCountDate(null);
      setIsSearchResultsLoading(true);
      axios
        .get(url)
        .then((res) => {
          const orders = res.data?.data;
          console.log("check orders", orders);

          let _grnDate = null;
          let _currentStatus = null;
          let _lastStockCountDate = null;

          if (orders.length > 0) {
            _grnDate = orders[0].grn_date;
            _currentStatus = orders[0].status;
            _lastStockCountDate = orders[0].stock_count_date;
          }

          setGrnDate(_grnDate);
          setCurrentStatus(_currentStatus);
          setLastStockCountDate(_lastStockCountDate);
          setOrdersData(orders);
          setOrdersDataRaw(orders);
          setDataFetchedForQR(QRId);
          setDataFetchedForDate(true);
          setIsSearchResultsLoading(false);
        })
        .catch((err) => {
          setOrdersData([]);
          setOrdersDataRaw([]);

          setOrdersStats({});
          setQRId("");
          setDataFetchedForQR(null);
          console.log(err);
          toast(`Error: Unable to fetch data, ${err}`, {
            type: toast.TYPE.ERROR,
          });
        })
        .finally(() => {
          toast.dismiss(toast_id);
        });
    },
    [selectedDate]
  );

  function getAvailableStatusSelections() {
    // const availableOrderStatus = orderStatusStatic.map((item, index) => {
    //   return {
    //     label: item,
    //     value: item,
    //   };
    // });
    // setAllStatuses(availableOrderStatus);
  }

  useEffect(() => {
    getDistributors();
    getAvailableStatusSelections();
  }, []);

  //   useEffect(() => {
  //     if (QRId) {
  //       searchWorker.postMessage({ data: ordersDataRaw, QRId });
  //     } else if (!QRId && ordersDataRaw.length !== ordersData.length) {
  //       setOrdersData(ordersDataRaw);
  //     }

  //     searchWorker.onmessage = (e) => {
  //       setIsSearchResultsLoading(false);
  //       setOrdersData(e.data);
  //     };
  //   }, [debouncedSearchTerm]);

  //   useEffect(() => {
  //     if (QRId && !isSearchResultsLoading) {
  //       //Show loading icon when searhTerm is entered
  //       setIsSearchResultsLoading(true);
  //     } else if (!QRId && isSearchResultsLoading) {
  //       setIsSearchResultsLoading(false);
  //     }
  //   }, [QRId]);

  //   useEffect(() => {
  //     if (!QRId) return;
  //     getGRNRecords();
  //   }, [QRId]);

  const excelTableColumns = useMemo(() => {
    return [
      {
        label: "Order number",
        value: "id",
      },
      {
        label: "Retailer Id",
        value: "retailer_id",
      },
      {
        label: "Order value",
        value: "order_amt",
      },
      {
        label: "Order date",
        value: "ordered_date",
      },
      {
        label: "Status",
        value: "status",
      },
    ];
  }, []);

  return (
    <>
    <NavBar/>
    <br />
          <br />
          <br />
          <span></span> {/* for logo, so don't remove the span */}
      <Stack direction="column">
        <Header fluid>
          <Title order={1} size="h2" align="center" mt="sm">
            Bag Check Report
          </Title>
          <Space h={"xs"} />
          {/* <Flex direction="row" gutter={"lg"} align={"flex-end"}> */}
          <SimpleGrid style={{ alignItems: "flex-end" }} cols={6}>
            <QRInput
              label="QR ID"
              placeholder="Enter QR id"
              onChange={(event) => {
                const val = event.currentTarget.value.trim();
                setQRId(val);
              }}
              value={QRId}
              size="sm"
              icon={<IconQrcode size={16} stroke={1.5} color="#000000" />}
            />

            <div>
              <Button
                color="blue"
                size="sm"
                onClick={() => {
                  getGRNRecords();
                }}
              >
                Fetch Records
              </Button>
            </div>

            <DatePicker
              placeholder="Pick date"
              label="Delivery Date"
              value={selectedDate}
              onChange={setSelectedDate}
              maxDate={new Date(new Date().setDate(new Date().getDate() + 2))}
            />

            <div>
              <Button
                color="blue"
                size="sm"
                onClick={() => {
                  getRecordsByDate();
                }}
              >
                Fetch Records by Date
              </Button>
            </div>


            <div>
              <Button
                color="blue"
                size="sm"
                onClick={() => {
                  exportFile();
                }}
              >
                Export Data
              </Button>
            </div>

            {/* <div>
              <DatePicker
                placeholder="Pick date"
                maxDate={new Date()}
                label="GRN Date"
                value={grnDate}
                onChange={setGrnDate}
              />
            </div> */}
            {/* <div>
                <OrdersExport
                  sheet1={ordersData}
                  filename={"order_management_export"}
                  sheet1Headers={excelTableColumns}
                  sheet1Name={"Orders"}
                />
              </div> */}
            {/* {user.userType !== "distributor" && (
              <div>
                <Select
                  label="Distributor"
                  placeholder="Select"
                  itemComponent={DistributorSelectItem}
                  data={allDistributors}
                  value={selectedDistributor}
                  onChange={setSelectedDistributor}
                  searchable
                  maxDropdownHeight={400}
                  // nothingFound="Nobody here"
                  filter={(value, item) =>
                    item.label
                      .toLowerCase()
                      .includes(value.toLowerCase().trim()) ||
                    item.phone
                      .toLowerCase()
                      .includes(value.toLowerCase().trim())
                  }
                />
              </div>
            )} */}
            {/* <div>
              <Select
                label="Status"
                placeholder="Select"
                itemComponent={StatusSelectItem}
                data={allStatuses}
                value={selectedStatus}
                onChange={setSelectedStatus}
                searchable
                maxDropdownHeight={400}
                // nothingFound="Nobody here"
                // filter={(value, item) =>
                //   item.label
                //     .toLowerCase()
                //     .includes(value.toLowerCase().trim()) ||
                //   item.phone.toLowerCase().includes(value.toLowerCase().trim())
                // }
              />
            </div> */}
            {/* <div>
              <Button
                color="yellow"
                size="sm"
                onClick={() => {
                  exportFile();
                }}
              >
                Export
              </Button>
            </div> */}
            {/* <div>
              <Button
                color="yellow"
                size="sm"
                onClick={() => {
                  syncDistributorProductMasterData();
                }}
              >
                Sync DistributorProductMaster
              </Button>
            </div> */}
          </SimpleGrid>
          {/* <Flex direction="row" gap={"lg"} style={{ marginTop: "1rem" }}>
            <SearchInput
              placeholder="Search"
              onChange={(event) => setQRId(event.currentTarget.value)}
              rightSection={isSearchResultsLoading ? <Loader size="xs" /> : ""}
              value={QRId}
              size="sm"
              icon={<IconSearch size={16} stroke={1.5} />}
              mb="sm"
            />
          </Flex> */}
        </Header>
        {dataFetchedForQR && <StatsDisplayRow>
          <Center>
            {dataFetchedForQR && (
              <>
                <Text
                  style={{ color: "#ed4e09 ", fontWeight: "600" }}
                  c="green"
                  fz="xs"
                  tt="uppercase"
                  fw="bold"
                >
                  {`QR: ${dataFetchedForQR} `}
                </Text>
                <Space w={".3rem"} />
                <Text c="dark" fz="md" tt="uppercase" fw="bold">
                  |
                </Text>
                <Space w={".3rem"} />
              </>
            )}

            <Text
              style={{ color: "green", fontWeight: "600" }}
              c="green"
              fz="xs"
              tt="uppercase"
              fw="bold"
            >
              {`Last stock count date: ${lastStockCountDate || "N/A"} `}
            </Text>
            <Space w={".3rem"} />
            <Text c="dark" fz="md" tt="uppercase" fw="bold">
              |
            </Text>
            <Space w={".3rem"} />
            <Text
              style={{ color: "#4e09ed", fontWeight: "600" }}
              c="yellow"
              fz="xs"
              tt="uppercase"
              fw="bold"
            >
              {`GRN Date: ${grnDate || "N/A"} `}
            </Text>
            <Space w={".3rem"} />
            <Text c="dark" fz="md" tt="uppercase" fw="bold">
              |
            </Text>
            <Space w={".3rem"} />
            <Text
              style={{ color: "#ed09d6", fontWeight: "600" }}
              c="cyan"
              fz="xs"
              tt="uppercase"
              fw="bold"
            >
              {`Current status: ${currentStatus || "N/A"}`}
            </Text>
          </Center>
        </StatsDisplayRow>}
        <StyledTable
          striped
          withBorder
          withColumnBorders
          sx={{ minWidth: 700 }}
        >
          <thead
            className={cx(classes.header, { [classes.scrolled]: scrolled })}
          >
            <tr>
              <th>QR ID</th>
              <th>Warehouse/ Depot</th>
              <th>Route</th>      
              <th>Item ID</th>        
              <th>Picking Date</th>
              <th>Picking User</th>              
              <th>Loading Date</th>
              <th>Loading User</th>
              <th>Received at Depot</th>
              <th>Received at Depot User</th>
              <th>Delivery Date</th>
              <th>Delivered</th>
              <th>Stock Check</th>
              <th>Stock Check User</th>
              <th>Received at DC</th>
              <th>DC GRN User</th>
            </tr>
          </thead>
          <tbody>
            {ordersData.map((row) => {
              return (
                <OrderRow
                  key={`${row.qr_id}/ ${row.delivery_date}`}
                  row={{ ...row }}
                />
              );
            })}
          </tbody>
        </StyledTable>
      </Stack>
      {/* <UpdateOrderStatusModal
        opened={updateStatusModalOpened}
        setOpened={setUpdateStatusModalOpened}
        data={selectedOrderData}
        refreshData={getGRNRecords}
      /> */}
      {/* <ViewProductsModal
        opened={viewProductsModalOpened}
        setOpened={setViewProductsModalOpened}
        data={selectedOrderData.ordersItems || []}
        refreshData={getGRNRecords}
      /> */}
    </>
  );
}

const OrderRow = React.memo(
  ({ row }) => {
    console.log("rerendering", row);
    return (
      <tr>
        <td>{row.qr_id}</td>
        <td>{row.warehouse}</td>
        <td>{row.route}</td>
        <td>{row.item_id}</td>
        <td>{row.picking}</td>
        <td>{row.picking_user}</td>        
        <td>{row.loading}</td>
        <td>{row.loading_user}</td>
        <td>{row.received_at_depot}</td>
        <td>{row.received_at_depot_user}</td>
        <td>{row.delivery_date}</td>
        <td>{row.delivered_status}</td>
        <td>{row.stock_check}</td>
        <td>{row.stock_check_user}</td>
        <td>{row.received_at_dc}</td>
        <td>{row.dc_grn_user}</td>
      </tr>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.row.qr_id === nextProps.row.qr_id &&
      prevProps.row.delivery_date === nextProps.row.delivery_date
    );
  }
);

export default BagCheckReport;
