import React, { useState, useMemo, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import NavBar from "../Navigation bar/Navbar";
import fire from "../../Configs/firebase";
import axios from "axios";
import { service_url } from "../../Configs/mysqlconfig";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Form,
  ButtonGroup,
  InputGroup,
  FormControl,
  Dropdown,
} from "react-bootstrap";
import TransporterSummaryT1 from "./Retailer/TransporterSummaryT1";
import DriverwiseSummaryT2 from "./Retailer/DriverwiseSummaryT2";
import DetailedReportT3 from "./Retailer/DetailedReportT3";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useAsyncDebounce,
} from "react-table";
import { ToastContainer, toast } from "react-toastify";

// let service_url = ''
let today = new Date();
let dd = String(today.getDate());
let mm = String(today.getMonth() + 1);
let yyyy = today.getFullYear();
today = yyyy + "-" + mm + "-" + dd;

function TransporterPaymentsRetailer() {
  const [TableDate, setTableDate] = useState({
    yyyy: yyyy,
    mm: mm,
    dd: dd,
  });
  {
    /* amount_per_service additional_amount max_km_for_fixed_amt  */
  }
  const [Reload, setReload] = useState();
  const [AmountPerService, setAmountPerService] = useState(2500);
  const [AdditionalAmount, setAdditionalAmount] = useState(12);
  const [MaxKmForFixedAmt, setMaxKmForFixedAmt] = useState(1200);
  const [DefaultValue, setDefaultValue] = useState(true);

  const [HidePrams, setHidePrams] = useState(false);
  const [show1, setShow1] = useState(false);
  const [TableNo, setTableNo] = useState(1);
  const [TableName, setTableName] = useState("Transporter Summary");
  const [TableData, setTableData] = useState([]);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => {
    toast(" showing tost massage please remove this on  handleShow1 fun");
    setShow1(true);
  };

  useEffect(() => {
    callAllApis();
    console.log("Reload >> ", Reload);
  }, [TableDate, Reload]);

  useEffect(() => {
    // for force reloading
    return () => {};
  }, [TableData]);

  async function callAllApis() {
    await get_transporter_payments_config();
    let cd = await CashDenomination();
    let dah = await DriverAllocationHistory();

    let tableList = [];
    for (let dt in dah) {
      let drivers = dah[dt];
      for (let dph in drivers) {
        let obj_dph = drivers[dph];
        let obj_cd = cd[dt]?.[drivers[dph]?.current_Route_Id];

        let short_items = 0;
        let short_items_obj = obj_cd?.short_items;

        for (let st in short_items_obj) {
          short_items += Number(short_items_obj[st].item_value);
        }

        let merge = {
          DB_Release_Time: obj_dph?.DB_Release_Time,
          DB_Return_Time: obj_dph?.DB_Return_Time,
          Helper_Boy_Name: obj_dph?.Helper_Boy_Name,
          Helper_Boy_Phone: obj_dph?.Helper_Boy_Phone,
          Vehicle_Dispatch_Time: obj_dph?.Vehicle_Dispatch_Time,
          actual_Driver_Name: obj_dph?.actual_Driver_Name,
          allocated_By: obj_dph?.allocated_By,
          allocation_Time: obj_dph?.allocation_Time,
          allocator_phone_number: obj_dph?.allocator_phone_number,
          city: obj_dph?.city,
          company_type: obj_dph?.company_type,
          company_name: obj_dph?.company_name,
          completed_conslidation: obj_dph?.completed_conslidation,
          completed_conslidation_updatedAt:
            obj_dph?.completed_conslidation_updatedAt,
          completed_delivery: obj_dph?.completed_delivery,
          completed_delivery_time: obj_dph?.completed_delivery_time,
          current_Route_Id: obj_dph?.current_Route_Id,
          date: dt,
          driver_Phone_Number: obj_dph?.driver_Phone_Number,
          ending_odometer_reading: obj_dph?.ending_odometer_reading,
          region: obj_dph?.region,
          starting_odometer_reading: obj_dph?.starting_odometer_reading,
          kms_travelled:
            Number(
              Number(obj_dph?.ending_odometer_reading) -
                Number(obj_dph?.starting_odometer_reading)
            ) || 0,
          vehicle_number: obj_dph?.vehicle_number,
          amount: obj_cd?.amount,
          cash_payment: obj_cd?.cash_payment,
          penalty_amt: obj_cd?.penalty_amt || 0,
          short_items: Number(short_items).toFixed(2),
          adjustments: obj_cd?.currency_count?.adjustments || 0,
          vehicle_status: obj_dph?.vehicle_status,
        };

        tableList.push(merge);
      }
    }

    setTableData(tableList);
  }

  async function CashDenomination() {
    let fireFilter = fire
      .database()
      .ref(`Cash_Denomination/${TableDate?.yyyy}/${TableDate?.mm}`);
    let snapshot = await fireFilter.once("value");
    let velData = snapshot.val();
    return velData;
  }

  async function DriverAllocationHistory() {
    let fireFilter = fire
      .database()
      .ref(`Driver_Allocation_History/${TableDate.yyyy}/${TableDate.mm}`);
    let snapshot = await fireFilter.once("value");
    let velData = snapshot.val();
    return velData;
  }

  async function get_transporter_payments_config() {
    const apiUrl = `${service_url}/api/superzop/transporter_payments/get-transporter-payments-config?date_1st_day=${
      TableDate.yyyy
    }-${TableDate.mm.padStart(2, "0")}-01 `;

    await axios
      .get(apiUrl)
      .then((response) => {
        if (response.data.length > 0) {
          let data = response.data[0];
          setAmountPerService(data.amount_per_service);
          setAdditionalAmount(data.additional_amount);
          setMaxKmForFixedAmt(data.max_km_for_fixed_amt);
          setDefaultValue(false);
        }
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      });
  }

  function handelDateChange(date) {
    let today = new Date(date);
    let dd = String(today.getDate());
    let mm = String(today.getMonth() + 1);
    let yyyy = today.getFullYear();

    setTableDate({
      yyyy: yyyy,
      mm: mm,
      dd: dd,
    });
  }

  function update_transporter_payments_config() {
    let formData = {
      date_1st_day: `${TableDate.yyyy}-${TableDate.mm.padStart(2, "0")}-01`,
      amount_per_service: AmountPerService,
      additional_amount: AdditionalAmount,
      max_km_for_fixed_amt: MaxKmForFixedAmt,
    };

    axios
      .put(
        `${service_url}/api/superzop/transporter_payments/update-transporter-payments-config`,
        formData
      )
      .then((response) => {
        // setMessage(response.data.message);
        toast.success("Transporter payments config updated", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((error) => {
        console.error("Error updating data ", error);
        toast.error("Error updating data ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  }

  const handleCallback = (data) => {
    setReload(!Reload);
  };

  const handleSelectTable = (eventKey) => {
    setTableNo(Number(eventKey));
    if (Number(eventKey) == 1) {
      setTableName("Transporter Summary");
    }
    if (Number(eventKey) == 2) {
      setTableName("Driverwise Summary");
    }
    if (Number(eventKey) == 3) {
      setTableName("Detailed Report");
    }
  };

  return (
    <>
      <div style={{ margin: 20 }}>
        <Row>
          <Col lg={2}>
            <Dropdown onSelect={handleSelectTable}>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {TableName}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item eventKey="1">Transporter Summary</Dropdown.Item>
                <Dropdown.Item eventKey="2">Driverwise Summary</Dropdown.Item>
                <Dropdown.Item eventKey="3">Detailed Report</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>

          <Col lg={3}>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon3"> Month </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="month"
                value={`${TableDate.yyyy}-${TableDate.mm.padStart(2, "0")}`}
                onChange={(e) => handelDateChange(e.target.value)}
              />
            </InputGroup>
          </Col>

          <Col lg={3}>
            {HidePrams ? (
              <Button onClick={() => setHidePrams(false)}>
                Hide Parameters{" "}
              </Button>
            ) : (
              <Button onClick={() => setHidePrams(true)}>
                Show Parameters
              </Button>
            )}
          </Col>
          <Col lg={2}></Col>
        </Row>

        <Row>
          {HidePrams && (
            <Col lg={3}>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon3">
                    Additional Amt
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="number"
                  value={AdditionalAmount}
                  onChange={(e) => setAdditionalAmount(e.target.value)}
                />
              </InputGroup>
            </Col>
          )}

          {HidePrams && (
            <Col lg={3}>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon3">
                    Amt Per Service
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="number"
                  value={AmountPerService}
                  onChange={(e) => setAmountPerService(e.target.value)}
                />
              </InputGroup>
            </Col>
          )}

          {HidePrams && (
            <Col lg={3}>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon3">Max Km</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="number"
                  value={MaxKmForFixedAmt}
                  onChange={(e) => setMaxKmForFixedAmt(e.target.value)}
                />
              </InputGroup>
            </Col>
          )}

          {HidePrams && (
            <Col lg={3}>
              <Button onClick={() => update_transporter_payments_config()}>
                {" "}
                update{" "}
              </Button>
            </Col>
          )}
        </Row>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <Row>
          <Col>
            {TableData && TableNo == 1 && (
              <TransporterSummaryT1
                TableData={TableData}
                amount_per_service={AmountPerService}
                additional_amount={AdditionalAmount}
                max_km_for_fixed_amt={MaxKmForFixedAmt}
              />
            )}
            {TableData && TableNo == 2 && (
              <DriverwiseSummaryT2
                TableData={TableData}
                amount_per_service={AmountPerService}
                additional_amount={AdditionalAmount}
                max_km_for_fixed_amt={MaxKmForFixedAmt}
              />
            )}
            {TableData && TableNo == 3 && (
              <DetailedReportT3
                TableData={TableData}
                amount_per_service={AmountPerService}
                additional_amount={AdditionalAmount}
                max_km_for_fixed_amt={MaxKmForFixedAmt}
                TableDate={TableDate}
                callback={handleCallback}
              />
            )}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default TransporterPaymentsRetailer;
