import { Checkbox } from "@mantine/core";
import React from "react";

export default function ShowDiscrepancyCheckbox(props) {
  const { showDiscrepancyItemsChecked, setShowDiscrepancyItemsChecked } = props;

  return (
    <div style={{ alignItems: "flex-start" }}>
      <Checkbox
        color="red"
        label="Show Only Discrepancy Items"
        checked={showDiscrepancyItemsChecked}
        onChange={(event) =>
          setShowDiscrepancyItemsChecked(event.currentTarget.checked)
        }
      />
      {/* <input
        checked={showDiscrepancyItemsChecked}
        onChange={(event) => {
          setShowDiscrepancyItemsChecked(event.currentTarget.checked);
        }}
        type="checkbox"
        id="discrepancyItemsCheckbox"
        name="discrepancyItemsCheckbox"
      />
      <label style={{ marginLeft: "20px" }} for="discrepancyItemsCheckbox">
        Show Only Discrepancy Items
      </label> */}
    </div>
  );
}
