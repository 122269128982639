import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Modal,
  Button,
  Table,
  Figure,
  Dropdown,
  DropdownButton,
  Row,
  Col,
  Tab,
} from "react-bootstrap";
import "./Orders.css";

import fire from "../../Configs/firebase";

const AVAILABLE_VECHILE_STATUS = {
  Planned: "Planned",
  Picked: "Picked",
  Loaded: "Loaded",
  Transferred: "Transferred",
  Assigned: "Assigned",
  Dispatched: "Dispatched",
  Delivering: "Delivering",
  "Deliveries Done": "Deliveries Done",
  Reported: "Reported",
  Unloading: "Unloading",
  "Completed Tally": "Completed Tally",
};

const ALL_STATUS_TIME = {
  Planned: "N/A",
  Picked: "N/A",
  Loaded: "N/A",
  Transferred: "N/A",
  Assigned: "N/A",
  Dispatched: "N/A",
  Delivering: "N/A",
  "Deliveries Done": "N/A",
  Reported: "N/A",
  Unloading: "N/A",
  "Completed Tally": "N/A",
};

const VehicleStatusModal = (props) => {
  const show = props.show;
  const setShow = props.setShow;
  const allocationHistoryRaw = props.allocationHistoryRaw;
  const ordersHistoryRaw = props.ordersHistoryRaw;
  const cityList = props.cityList;
  const date = props.date;

  const [selectedCityFilter, setSelectedCityFilter] = useState("All");
  const [selectedCompanyFilter, setSelectedCompanyFilter] = useState("All");
  const [cityFilterOptions, setCityFilterOptions] = useState([]);
  const [companyFilterOptions, setCompanyFilterOptions] = useState([]);
  const [showUnallocatedRoutes, setShowUnallocatedRoutes] = useState(false);
  const [pickingOrdersHistoryRaw, setPickingOrdersHistoryRaw] = useState({});
  const [routePickingHistory, setRoutePickingHistory] = useState({});

  const [tableData, setTableData] = useState([]);

  function fetchPickingOrdersHistory() {
    // console.log('running function',`Picking_Orders_History/${date}`)
    const picking_date = props.pickingDate;

    if (!picking_date) return;
    const date_split = picking_date.split("-");
    const year = date_split[2];
    const month = date_split[1];

    fire
      .database()
      .ref(`Picking_Orders_History/${year}/${month}/${picking_date}`)
      .once("value", (snapshot) => {
        const picking_data = snapshot.val();
        if (picking_data) {
          // console.log("check data", picking_data);
          setPickingOrdersHistoryRaw(picking_data);
        }
      });
  }

  function getPickingOrderHistoryForRoute(route) {
    let picking_data = null;
    Object.keys(pickingOrdersHistoryRaw).forEach((key) => {
      const key_split = key.split("-");
      if (key_split[0] === route && !picking_data) {
        picking_data = pickingOrdersHistoryRaw[key];
      }
    });

    return picking_data;
  }

  function generateCityFilterOptions(table_data) {
    let unique_options = ["All"];
    table_data.forEach((item, index) => {
      if (!item.city) {
        return;
      }
      if (!unique_options.includes(item.city)) {
        unique_options.push(item.city);
      }
    });
    return unique_options;
  }

  function generateCompanyFilterOptions(table_data) {
    let unique_options = ["All"];
    table_data.forEach((item, index) => {
      if (!item.transporter) {
        return;
      }
      if (!unique_options.includes(item.transporter)) {
        unique_options.push(item.transporter);
      }
    });
    return unique_options;
  }

  function populateTableData(allocation_history, orders_history, city_list) {
    if (!allocation_history || !city_list) {
      console.log("Error : Missing arguements");
    }

    // console.log("check orders history", orders_history);

    const table_data = [];
    Object.keys(allocation_history).forEach((key, index) => {
      const route_data = {};
      let route_no = allocation_history[key].current_Route_Id;
      let city = city_list[route_no.slice(0, 2)]?.city || "N/A";
      let region = city_list[route_no.slice(0, 2)]?.region || "N/A";
      route_data["city"] = city;
      route_data["region"] = region;
      route_data["route_no"] = route_no;
      route_data["driver_name"] = allocation_history[key].actual_Driver_Name;
      route_data["transporter"] = allocation_history[key].company_type;
      route_data["driver_no"] = allocation_history[key].driver_Phone_Number;
      table_data.push(route_data);
    });

    Object.keys(orders_history).forEach((key, index) => {
      if (
        !table_data.find((element) => {
          return element.route_no === key;
        })
      ) {
        const route_data = {};
        let route_no = key;
        let city = city_list[route_no.slice(0, 2)]?.city || "N/A";
        let region = city_list[route_no.slice(0, 2)]?.region || "N/A";
        route_data["city"] = city;
        route_data["region"] = region;
        route_data["route_no"] = route_no;
        table_data.push(route_data);
      }
    });

    table_data.sort(function (a, b) {
      var keyA = a.route_no,
        keyB = b.route_no;
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
    return table_data;
  }

  function getVehicleStatus(
    route_driver_allocation = {},
    route_orders_history = {},
    route_picking = {}
  ) {
    let status = "N/A";
    let time = "N/A";
    let _all_status_time = {...ALL_STATUS_TIME}

    const pickedItem =
      Object.keys(route_picking).length > 1
        ? route_picking[Object.keys(route_picking)[0]]
        : {};

   
      status = AVAILABLE_VECHILE_STATUS.Planned;
      time = getFirstPlanningStartTime(route_orders_history);
      _all_status_time.Planned = time;

    if ("picked_at" in pickedItem) {
      status = AVAILABLE_VECHILE_STATUS.Picked;
      time = pickedItem.picked_at;

      try {
        const formattedTime = moment(Number(time)).format("HH:mm - DD/MM/yyyy");
        time = formattedTime;
      } catch (err) {
        time = "N/A";
      }

      _all_status_time.Picked = time;
    }

    if ("loaded_at" in pickedItem) {
      status = AVAILABLE_VECHILE_STATUS.Loaded;
      time = pickedItem.loaded_at;

      try {
        const formattedTime = moment(Number(time)).format("HH:mm - DD/MM/yyyy");
        time = formattedTime;
      } catch (err) {
        time = "N/A";
      }

      _all_status_time.Loaded = time;
    }
    if ("allocation_Time" in route_driver_allocation) {
      status = AVAILABLE_VECHILE_STATUS.Assigned;
      time = moment(Number(route_driver_allocation.allocation_Time)).format(
        "HH:mm - DD/MM/yyyy"
      );
      _all_status_time.Assigned = time;
    }
    if ("Vehicle_Dispatch_Time" in route_driver_allocation) {
      status = AVAILABLE_VECHILE_STATUS.Dispatched;
      time = moment(
        Number(route_driver_allocation.Vehicle_Dispatch_Time)
      ).format("HH:mm - DD/MM/yyyy");
      _all_status_time.Dispatched = time;
    }

    if (isDriverDelivering(route_orders_history)) {
      status = AVAILABLE_VECHILE_STATUS.Delivering;
      time = getFirstDeliveringTime(route_orders_history);
      _all_status_time.Delivering = time;
    }

    if ("completed_delivery_time" in route_driver_allocation) {
      status = AVAILABLE_VECHILE_STATUS["Deliveries Done"];
      time = moment(
        Number(route_driver_allocation.completed_delivery_time)
      ).format("HH:mm - DD/MM/yyyy");
      _all_status_time["Deliveries Done"] = time;
    }
    if ("DB_Return_Time" in route_driver_allocation) {
      status = AVAILABLE_VECHILE_STATUS.Reported;
      time = moment(Number(route_driver_allocation.DB_Return_Time)).format(
        "HH:mm - DD/MM/yyyy"
      );
      _all_status_time.Reported = time;
    }
    if ("stock_checked_updatedAt" in route_driver_allocation) {
      status = AVAILABLE_VECHILE_STATUS.Unloading;
      time = moment(
        Number(route_driver_allocation.stock_checked_updatedAt)
      ).format("HH:mm - DD/MM/yyyy");
      _all_status_time.Unloading = time;
    }

    if (
      "completed_conslidation_updatedAt" in route_driver_allocation &&
      Number(route_driver_allocation["completed_conslidation_updatedAt"]) > 0
    ) {
      status = AVAILABLE_VECHILE_STATUS["Completed Tally"];
      time = moment(
        Number(route_driver_allocation.completed_conslidation_updatedAt)
      ).format("HH:mm - DD/MM/yyyy");
      _all_status_time["Completed Tally"] = time;
    }

    return { status, status_time: time, all_status_time: {..._all_status_time} };
  }

  const FilterRoutesCheckbox = () => {
    return (
      <>
        <input
          style={{ height: "15px", width: "15px" }}
          type="checkbox"
          id="routeFilter"
          name="routeFilter"
          value="routeFilter"
          checked={showUnallocatedRoutes}
          onChange={(event) => {
            setShowUnallocatedRoutes(event.target.checked);
          }}
        />
        <label
          style={{ marginLeft: "5px", fontWeight: "bold" }}
          htmlFor="routeFilter"
        >
          Also show unallocated routes
        </label>
      </>
    );
  };

  useEffect(() => {
    fetchPickingOrdersHistory();
    const table_data = populateTableData(
      allocationHistoryRaw,
      ordersHistoryRaw,
      cityList
    );
    const city_options = generateCityFilterOptions(table_data);
    const company_options = generateCompanyFilterOptions(table_data);
    setCityFilterOptions(city_options);
    setCompanyFilterOptions(company_options);
    setTableData(table_data);
  }, []);

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
      }}
      id="status_details_modal"
      dialogClassName="status_details_modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Vehicle Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FilterRoutesCheckbox />
        <Table striped bordered hover size="md">
          <thead>
            <tr>
              <th style={{ verticalAlign: "middle" }}>Route</th>
              <th style={{ verticalAlign: "middle" }}>Region</th>
              <th>
                Warehouse
                <DropdownButton
                  style={{ marginTop: "5px" }}
                  id="dropdown-basic-button"
                  title={
                    selectedCityFilter != "All" ? selectedCityFilter : "All"
                  }
                  size="sm"
                  variant="info"
                  onSelect={() => {
                    // setMapOrdersData([]);
                  }}
                >
                  {cityFilterOptions.map((item, index) => {
                    return (
                      <Dropdown.Item
                        key={item}
                        name={item}
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedCityFilter(e.target.name);
                        }}
                      >
                        {item}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
              </th>
              <th style={{ verticalAlign: "middle" }}>Driver</th>
              <th>
                Company
                <DropdownButton
                  style={{ marginTop: "5px" }}
                  id="dropdown-basic-button"
                  title={
                    selectedCompanyFilter != "All"
                      ? selectedCompanyFilter
                      : "All"
                  }
                  size="sm"
                  variant="info"
                  onSelect={() => {
                    // setMapOrdersData([]);
                  }}
                >
                  {companyFilterOptions.map((item, index) => {
                    return (
                      <Dropdown.Item
                        key={item}
                        name={item}
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedCompanyFilter(e.target.name);
                        }}
                      >
                        {item}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
              </th>
              <th style={{ verticalAlign: "middle" }}>Status</th>
              <th style={{ verticalAlign: "middle" }}>Time</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((item, index) => {
              //Check filters
              if (
                selectedCityFilter !== "All" &&
                item.city !== selectedCityFilter
              ) {
                return undefined;
              }

              if (
                selectedCompanyFilter !== "All" &&
                item.transporter !== selectedCompanyFilter
              ) {
                return undefined;
              }

              let driver_allocation_info = allocationHistoryRaw[item.driver_no];
              let picking_data =
                getPickingOrderHistoryForRoute(item.route_no) || {};
              let { status, status_time, all_status_time } = getVehicleStatus(
                driver_allocation_info,
                ordersHistoryRaw[item.route_no],
                picking_data
              );

              if (!driver_allocation_info && !showUnallocatedRoutes) {
                return undefined;
              }


              // console.log('check data',{  driver_no: item.driver_no, status, all_status_time} )

              return (
                <TableItemToRender
                  key={index}
                  item={item}
                  status={status}
                  status_time={status_time}
                  all_status_time={all_status_time}
                />
              );
            })}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setShow(false);
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const TableItemToRender = ({ item, status, status_time, all_status_time }) => {
  const [showTimeHistory, setShowTimeHistory] = useState(false);

  return (
    <tr
      style={{
        backgroundColor:
          status === AVAILABLE_VECHILE_STATUS["Completed Tally"]
            ? "#cdfecb "
            : "#fecbcb ",
      }}
    >
      <td>{item.route_no || "-"}</td>
      <td>{item.region || "-"}</td>
      <td>{item.city || "-"}</td>
      <td>
        {item.driver_no || ""} - {item.driver_name || ""}
      </td>
      <td>{item.transporter || "-"}</td>
      <td>{status || "-"}</td>
      <td>
        <span
          onClick={(event) => {
            setShowTimeHistory((oldVal) => {
              return !oldVal;
            });
          }}
          className="history_toggle"
        >
          Toggle history
        </span>
        {showTimeHistory ? (
          <table style={{ fontSize: "12px", width: "130px" }}>
            <tbody>
              <tr>
                <td>Planned</td>
                <td>{all_status_time.Planned}</td>
              </tr>
              <tr>
                <td>Picked</td>
                <td>{all_status_time.Picked}</td>
              </tr>
              <tr>
                <td>Loaded</td>
                <td>{all_status_time.Loaded}</td>
              </tr>

              <tr>
                <td>Transferred</td>
                <td>{all_status_time.Transferred}</td>
              </tr>
              <tr>
                <td>Assigned</td>
                <td>{all_status_time.Assigned}</td>
              </tr>
              <tr>
                <td>Dispatched</td>
                <td>{all_status_time.Dispatched}</td>
              </tr>
              <tr>
                <td>Delivering</td>
                <td>{all_status_time.Delivering}</td>
              </tr>
              <tr>
                <td>Deliveries Done</td>
                <td>{all_status_time["Deliveries Done"]}</td>
              </tr>
              <tr>
                {" "}
                <td>Reported</td>
                <td>{all_status_time.Reported}</td>{" "}
              </tr>
              <tr>
                <td>Unloading</td>
                <td>{all_status_time.Unloading}</td>
              </tr>
              <tr>
                <td>Completed Tally</td>
                <td>{all_status_time["Completed Tally"]}</td>
              </tr>
            </tbody>
          </table>
        ) : (
          status_time
        )}
      </td>
    </tr>
  );
};

function isDriverDelivering(orders) {
  for (let i = 0; i < Object.keys(orders).length; i++) {
    let order_key = Object.keys(orders)[i];
    if (
      orders[order_key].status !== "Shipped" &&
      orders[order_key].status !== "Shipped-Modified" &&
      orders[order_key].status !== "Processed" &&
      orders[order_key].status !== "Hold"
    ) {
      return true;
    }
  }
  return false;
}

function getFirstPlanningStartTime(orders_history) {
  let time = "23:59".concat(` - ${moment().format('DD/MM/yyyy')}`);

  Object.keys(orders_history).forEach((key) => {
    const order = orders_history[key];
    if (order.saved_at) {
      let formattedDateTime = moment(Number(order.saved_at)).format("HH:mm - DD/MM/yyyy");
      const formattedTime = formattedDateTime.split('-')[0].trim()
      time = formattedTime < time.split('-')[0].trim() ? formattedDateTime : time;
    }
  });

  return time;
}


function getFirstDeliveringTime(orders_history) {
  let time = "23:59".concat(` - ${moment().format('DD/MM/yyyy')}`);

  Object.keys(orders_history).forEach((key) => {
    const order = orders_history[key];
    if (order.updated_at_app) {
      let formattedDateTime = moment(Number(order.updated_at_app)).format("HH:mm - DD/MM/yyyy");
      const formattedTime = formattedDateTime.split('-')[0].trim()
      time = formattedTime < time.split('-')[0].trim() ? formattedDateTime : time;
    }
  });

  return time;
}

export default VehicleStatusModal;
