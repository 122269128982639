import React, { useState, useEffect, useRef } from "react";
import { Table, Form, Alert, Button, Modal } from "react-bootstrap";
import fire from "../../Configs/firebase";
import { toast } from "react-toastify";
import Select, { ActionMeta, OnChangeValue, StylesConfig } from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import {
  support_portal_url,
  service_url,
  token,
} from "../../Configs/mysqlconfig";
import Backdrop from "../backdrop/backdrop";
import moment from "moment";
import { warehouseNamesTransit } from "../../Configs/mysqlconfig";

const options = warehouseNamesTransit.map((name) => ({
  value: name,
  label: name,
}));

function formatDate(date) {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

function TransitRouteCreationModal({ ...props }) {
  const [deliveryDate, setDeliveryDate] = useState();
  const [transitDate, setTransitDate] = useState();
  const [receivingDate, setReceivingDate] = useState();
  const [warehouse, setWarehouse] = useState("");
  const [type, setType] = useState("GT");
  const [routeDropdownList, setRouteDropdownList] = useState([]);
  const [selectedRoutes, setSelectedRoutes] = useState([]);
  const [dohData, setDohData] = useState({});
  const [freshData, setFreshData] = useState({});
  const [backDrop, setBackDrop] = useState(false);
  const [isCreationAllowed, setIsCreationAllowed] = useState(false);

  const bulkRouteRef = useRef();

  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });

  const styles = {
    multiValue: (base, state) => {
      return state.data.isFixed ? { ...base, backgroundColor: "gray" } : base;
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed
        ? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 }
        : base;
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: "none" } : base;
    },
  };

  function getFreshData(_delivery_date) {
    let url = `${service_url}/api/loaded-inventory?delivery_date=${moment(
      _delivery_date
    ).format("YYYY-MM-DD")}&token=${token}`;

    fetch(url, {
      headers: { "x-access-token": sessionStorage.getItem("x-access-token") },
    })
      .then((res) => res.json())
      .then((result) => {
        let { data } = result || [];
        // console.log("check debug", data);

        const ordersByRoute = {};

        data.forEach((item, index) => {
          if (item.route in ordersByRoute) {
            ordersByRoute[item.route].push(item);
          } else {
            ordersByRoute[item.route] = [item];
          }
        });

        setFreshData(ordersByRoute);
      })
      .catch((err) => {
        // setIsLoading(false);
        toast(`Error in fetching items: ${err}`, { type: toast.TYPE.ERROR });
      });
  }

  function populateRouteDropDownList(_type, _freshData, _dohData) {
    let data = {};
    let routeList = [];

    if (_type === "GT") {
      data = _dohData;

      Object.keys(data).map((rkey, rindex) => {
        let routeOrders = data[rkey] || {};
        let isWarehousePresent = false;
        let isRecordIgnored = false;

        if (rkey.includes("F")) return;

        Object.keys(routeOrders).map((okey, oindex) => {
          let status = routeOrders[okey].status || "";
          let city = routeOrders[okey].city || "";
          city = convertCity(city);

          if (status == "Hold") {
            isRecordIgnored = true;
          }

          if (warehouse == city) {
            isWarehousePresent = true;
          }
        });

        if (!isRecordIgnored && isWarehousePresent) {
          routeList.push({ label: rkey, value: rkey, isFixed: true });
        }
      });
    } else if (_type === "Fresh") {
      data = _freshData;
      let includedRoutes = {};

      Object.keys(data).forEach((key, index) => {
        if (
          warehouse == "Vashi" &&
          key.includes("F0") &&
          !includedRoutes[key]
        ) {
          routeList.push({ label: key, value: key });
          includedRoutes[key] = key;
        } else if (
          warehouse == "Wadala" &&
          key.includes("F1") &&
          !includedRoutes[key]
        ) {
          routeList.push({ label: key, value: key });
          includedRoutes[key] = key;
        }
      });
    }

    console.log(routeList);
    setRouteDropdownList(routeList);
  }

  const checkIfTransferCanBeCreated = () => {
    // const date = moment(new Date(returnDate)).format("YYYY-MM-DD");

    if (!warehouse || !type) {
      setIsCreationAllowed(false);
      return;
    }

    let url = `${service_url}/api/transfer-received-updated?src=Bhiwandi&destination=${warehouse}&type=${type}&token=${token}`;

    fetch(url, {
      headers: { "x-access-token": sessionStorage.getItem("x-access-token") },
    })
      .then((res) => res.json())
      .then((result) => {
        const { success = 0, data = "N" } = result || "N";

        if (success && data === "Y") setIsCreationAllowed(true);
        else setIsCreationAllowed(false);
      });
  };

  useEffect(() => {
    let tomorrowDate = new Date();
    tomorrowDate.setDate(tomorrowDate.getDate() + 1);

    setTransitDate(new Date());
    setDeliveryDate(new Date(tomorrowDate));
    setReceivingDate(new Date(tomorrowDate));
  }, []);

  const convertCity = (cityVal) => {
    // cityVal = cityVal.toLowerCase();
    // if(cityVal=="pune"){
    //   return "Pune";
    // } else if(cityVal=="surat"){
    //   return "Surat";
    // } else if(cityVal=="nashik"){
    //   return "Nashik";
    // } else if(cityVal=="bhiwandi"){
    //   return "Bhiwandi";
    // } else if(cityVal=="wadala"){
    //   return "Wadala";
    // } else if(cityVal=="andheri"){
    //   return "Andheri";
    // } else {
    //   return "Mumbai";
    // }
    return cityVal;
  };

  useEffect(() => {
    if (!deliveryDate || !warehouse) {
      return;
    }

    console.log(dohData);
    console.log(warehouse);

    checkIfTransferCanBeCreated();
    populateRouteDropDownList(type, freshData, dohData);
  }, [warehouse, type]);

  useEffect(() => {
    console.log("running fetch dohdata1");
    if (!deliveryDate) {
      return;
    }

    console.log("running fetch dohdata2");

    let date = deliveryDate.getDate();
    let month = deliveryDate.getMonth() + 1;
    let year = deliveryDate.getFullYear();
    let routeList = [];

    setBackDrop(true);

    Promise.all([
      fire
        .database()
        .ref(`Driver_Orders_History/${year}/${month}/${date}-${month}-${year}`)
        .once("value"),
    ]).then(([snapshot]) => {
      let driverOrdersObj = snapshot.val() || {};

      Object.keys(driverOrdersObj).forEach((rkey, rindex) => {
        let routeOrders = driverOrdersObj[rkey] || {};
        let isVehicleOnHold = true;

        Object.keys(routeOrders).forEach((okey, oindex) => {
          let status = routeOrders[okey].status || "";

          if (status != "Hold") {
            isVehicleOnHold = false;
          }
        });

        if (!isVehicleOnHold) {
          routeList.push({ label: rkey, value: rkey });
        }
      });

      console.log("routeList");
      console.log(routeList);

      setBackDrop(false);
      // setRouteDropdownList(routeList);
      setDohData(driverOrdersObj);
      checkIfTransferCanBeCreated();
    });

    getFreshData(deliveryDate);
  }, [deliveryDate]);

  const createTransitRouteRecords = () => {
    let updateTransitRouteObj = {};
    updateTransitRouteObj["source"] = "Bhiwandi";
    updateTransitRouteObj["type"] = type;
    updateTransitRouteObj["destination"] = warehouse;
    updateTransitRouteObj["delivery_date"] = formatDate(new Date(deliveryDate));
    updateTransitRouteObj["transit_date"] = formatDate(new Date(transitDate));
    updateTransitRouteObj["receiving_date"] = formatDate(
      new Date(receivingDate)
    );

    updateTransitRouteObj["created_by"] = JSON.parse(
      sessionStorage.getItem(`user`)
    )[0].email;

    console.log(bulkRouteRef.current);

    let routeList = [];

    if (bulkRouteRef.current) {
      routeList = bulkRouteRef.current.state.value || [];
    }

    console.log(routeList);
    let selectedRoutesItemsObj = {};
    let selectedRouteList = [];

    if (type === "GT") {
      try {
        for (let i = 0; i < routeList.length; i++) {
          let routeVal = routeList[i].value;
          selectedRouteList.push(routeVal);
          let routeOrders = dohData[routeVal] || {};

          Object.keys(routeOrders).map((okey, oindex) => {
            if (routeOrders[okey].status === "Hold") {
              return;
            }

            if (routeOrders[okey].order_number.includes("OR")) {
              return;
            }

            let item_id = routeOrders[okey].item_id || "";
            let item_disc = routeOrders[okey].item_disc || "";
            let variety = routeOrders[okey].variety || "";
            let category = routeOrders[okey].category || "";
            let sub_category = routeOrders[okey].sub_category || "";
            let brand = routeOrders[okey].brand || "";

            let order_qty = Number(routeOrders[okey].order_qty) || 0;
            let item_modified_qty = 0;

            if (routeOrders[okey].item_modified_qty) {
              item_modified_qty = Number(routeOrders[okey].item_modified_qty);
            } else if (routeOrders[okey].order_qty) {
              item_modified_qty = Number(routeOrders[okey].order_qty);
            }

            let order_number = routeOrders[okey].order_number || "";
            const bag_id = routeOrders[okey].bag_id || "";

            if (item_modified_qty == 0) return;

            if (!bag_id && variety == "SMALL PACK") {
              throw new Error(
                `Bag Id is empty for ${order_number}, Cannot create stock transfer`
              );
            }

            if (!selectedRoutesItemsObj[routeVal]) {
              selectedRoutesItemsObj[routeVal] = {};
            }

            if (!selectedRoutesItemsObj[routeVal][okey]) {
              selectedRoutesItemsObj[routeVal][okey] = {};
            }
            selectedRoutesItemsObj[routeVal][okey].item_id = item_id;
            selectedRoutesItemsObj[routeVal][okey].item_disc = item_disc;
            selectedRoutesItemsObj[routeVal][okey].order_qty = order_qty;
            selectedRoutesItemsObj[routeVal][okey].item_modified_qty =
              item_modified_qty;
            selectedRoutesItemsObj[routeVal][okey].variety = variety;
            selectedRoutesItemsObj[routeVal][okey].category = category;
            selectedRoutesItemsObj[routeVal][okey].sub_category = sub_category;
            selectedRoutesItemsObj[routeVal][okey].brand = brand;
            selectedRoutesItemsObj[routeVal][okey].bag_id = bag_id;
            selectedRoutesItemsObj[routeVal][okey].order_number = order_number;
            selectedRoutesItemsObj[routeVal][okey].order_qty = order_qty;
            selectedRoutesItemsObj[routeVal][okey].item_modified_qty =
              item_modified_qty;
          });
        }
      } catch (err) {
        return toast(err.message, { type: toast.TYPE.ERROR });
      }
    } else if (type === "Fresh") {
      for (let i = 0; i < routeList.length; i++) {
        let routeVal = routeList[i].value;
        let routeOrders = freshData[routeVal];
        selectedRouteList.push(routeVal);

        routeOrders.forEach((item, index) => {
          const {
            item_id,
            long_description,
            brand,
            qty,
            remaining_qty,
            crate_count,
            warehouse,
          } = item;
          const order_number = "";

          if (!selectedRoutesItemsObj[routeVal]) {
            selectedRoutesItemsObj[routeVal] = {};
          }

          if (!selectedRoutesItemsObj[routeVal][item_id]) {
            selectedRoutesItemsObj[routeVal][item_id] = {};
          }

          selectedRoutesItemsObj[routeVal][item_id] = {
            item_id,
            item_disc: long_description,
            brand,
            order_qty: qty,
            order_number,
          };
        });
      }
    }

    updateTransitRouteObj["routeItemsData"] = selectedRoutesItemsObj;

    console.log("verify ", updateTransitRouteObj);
    if (!updateTransitRouteObj["destination"]) {
      return toast(`Please select child warehouse`, { type: toast.TYPE.ERROR });
    } else if (!updateTransitRouteObj["delivery_date"]) {
      return toast(`Please select delivery date`, { type: toast.TYPE.ERROR });
    } else if (!updateTransitRouteObj["transit_date"]) {
      return toast(`Please select transit date`, { type: toast.TYPE.ERROR });
    } else if (Object.keys(selectedRoutesItemsObj).length == 0) {
      return toast(`Please select routes`, { type: toast.TYPE.ERROR });
    } else {
      console.log(JSON.stringify(updateTransitRouteObj));

      console.log({ updateTransitRouteObj });
      let url = `${service_url}/api/transit-stock-transfer?token=${token}`;

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": sessionStorage.getItem("x-access-token"),
        },
        body: JSON.stringify(updateTransitRouteObj),
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.success == 1) {
            toast(`${result.message}`, { type: toast.TYPE.SUCCESS });

            setTimeout(function () {
              window.location.reload();
            }, 2000);
          } else {
            toast(`${result.message}`, { type: toast.TYPE.ERROR });
          }
        })
        .catch((err) => {
          toast(`Error in creating transit route records: ${err}`, {
            type: toast.TYPE.ERROR,
          });
        });
    }
  };

  const handleDeliveryDateChange = (selecteddate) => {
    setDeliveryDate(new Date(selecteddate));
  };

  const handleReceivingDateChange = (selecteddate) => {
    setReceivingDate(new Date(selecteddate));
  };

  const handleTransitDateChange = (selecteddate) => {
    setTransitDate(new Date(selecteddate));
  };

  const CustomDateInput = ({ value, onClick }) => (
    <input
      value={value}
      onClick={onClick}
      style={{ border: "solid 1px black", height: "30px" }}
    />
  );

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Create Transit Record
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <tr>
              <td>Transit Date</td>
              <td>
                <DatePicker
                  selected={transitDate}
                  // onChange={handleTransitDateChange}
                  maxDate={new Date()}
                  minDate={new Date()}
                  aria-describedby="basic-addon1"
                  dateFormat="dd/MM/yyyy"
                  customInput={<CustomDateInput />}
                />
              </td>
            </tr>
            <tr>
              <td>Delivery Date</td>
              <td>
                <DatePicker
                  selected={deliveryDate}
                  onChange={handleDeliveryDateChange}
                  minDate={new Date()}
                  maxDate={moment().add(3, "days").toDate()}
                  aria-describedby="basic-addon1"
                  dateFormat="dd/MM/yyyy"
                  customInput={<CustomDateInput />}
                />
              </td>
            </tr>
            <tr>
              <td>Receiving Date</td>
              <td>
                <DatePicker
                  selected={receivingDate}
                  onChange={handleReceivingDateChange}
                  minDate={new Date()}
                  maxDate={moment().add(2, "days").toDate()}
                  aria-describedby="basic-addon1"
                  dateFormat="dd/MM/yyyy"
                  customInput={<CustomDateInput />}
                />
              </td>
            </tr>
            <tr>
              <td>Warehouse</td>
              <td>
                <Select
                  // options={[
                  //   { value: "Vashi", label: "Vashi" },
                  //   { value: "Mira Road", label: "Mira Road" },
                  //   { value: "Wadala", label: "Wadala" },
                  // ]}
                  options = {options}
                  onChange={(val) => {
                    setWarehouse(val.value);
                  }}
                />
              </td>
            </tr>
            {/* <tr>
              <td>Type</td>
              <td>
                <Select
                  options={[
                    { value: "GT", label: "GT" },
                    { value: "Fresh", label: "Fresh" },
                  ]}
                  onChange={(val) => {
                    setType(val.value);
                  }}
                />
              </td>
            </tr> */}
            <tr>
              <td>Route</td>
              <td>
                <Select
                  key={Math.random()}
                  value={routeDropdownList}
                  styles={styles}
                  isClearable={false}
                  ref={bulkRouteRef}
                  options={routeDropdownList}
                  defaultValue={routeDropdownList}
                  onChange={(newValue, actionMeta) => {
                    if (actionMeta.removedValue.isFixed) {
                      return;
                    }
                    setRouteDropdownList(newValue);
                  }}
                  isMulti
                />
              </td>
            </tr>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant={"success"}
            onClick={() => {
              if (!isCreationAllowed) {
                toast(
                  "Error : Qty not entered for previous date, cannot create transfer",
                  {
                    type: toast.TYPE.WARNING,
                  }
                );
                return;
              }
              createTransitRouteRecords();
            }}
          >
            Submit
          </Button>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>

      {backDrop ? <Backdrop parentLoadStatus={backDrop} /> : null}
    </div>
  );
}

export default TransitRouteCreationModal;
