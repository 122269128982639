import { service_url,BASE_URL_EPAY,EPAY_TOKEN } from "../../Configs/mysqlconfig";

const toISOString = (date) => date.toISOString();
const date = new Date(Date.now());
const ISOdate = toISOString(date);


// API call to get epay_order_number
export const getEpayOrderNumber = async (selectedOrderNumber) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  try {
    const response = await fetch(
      `${service_url}/api/superzop/epay/epay-order-number?order_number=${selectedOrderNumber}`,
      requestOptions
    );
    const data = await response.json()

    let epay_order_number=null
    if(data.success===1){
        epay_order_number=data.epay_order_number
    }
    return epay_order_number;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};



// API call for Only Partial Delivery with the epay_order_number
export const epayApiForPartiallyDelivered = async (epay_order_number,epayAmout) => {
    const myHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `${EPAY_TOKEN}`
    };
  
    const raw = JSON.stringify({
      amount: parseFloat(epayAmout) * 100, // amount in paise conversion
      date: ISOdate, // current date time converted to string
      category: 'GROCERY', // by default
    });
  
    const requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };
  
    try {
      const response = await fetch(`${BASE_URL_EPAY}/transaction/v2/${epay_order_number}`, requestOptions);
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        const data = await response.json();

        if(data.marketplaceOrderId && data.status == "confirmed_update"){
            // orderNumber = data.marketplaceOrderId
            return { success: true, orderNumber: data.marketplaceOrderId };
        }else{
          if(data.code == "400"){
            return { success: false, message: data.message || 'Update not confirmed' };
          }else{
            return { success: false, message: data.message || 'Update not confirmed' };
          }
        }
       
      } else {
        const text = await response.text();
        console.error('Unexpected response:', text);
        return { success: false, message: `Unexpected response: ${text}` }; 
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      return { success: false, message: `Fetch error: ${error.message}` };
    }
  };


   // API call in case of partial and Delivered 
  export const epayApiForDeliveredAndPartiallyDelivered = async (selectedOrderNumber,epayAmout) => {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `${EPAY_TOKEN}`);

    const raw = JSON.stringify({
      shippingInfo: [
        {
          amount: parseFloat(epayAmout) * 100, // amount in paise conversion
          deliveredDate: ISOdate.toString(),
          invoiceNumber: selectedOrderNumber, 
        },
      ],
      marketplaceOrderId: selectedOrderNumber, // required field
    });

    const requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    const encodedNumber = encodeURIComponent(selectedOrderNumber);

    try {
      const response = await fetch(
        `${BASE_URL_EPAY}/transaction/v2/marketplaceorderid/${encodedNumber}/deliveryupdate`,
        requestOptions
      );
      const data = await response.json();
      if(data.status && data.status==="delivered"){
        return { success: true, amountInPaise: data.amount };
      }else{
        if(data.code == "400"){
          return { success: false, message: data.message || 'Update not confirmed' };
        }else{
          return { success: false, message: data.message || 'Update not confirmed' };
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

