import React from 'react'

const UpdatedDate=()=> {

    function checkTime(i) {
        if (i < 10) {
        i = "0" + i;
        }
        return i;
    }

    var today = new Date();
    var month =''+(today.getMonth()+1)
    var date=''+today.getDate()
    if (month.length < 2){
    month='0'+month;}
    if (date.length < 2){
    date ='0'+date;}
    var TodaysDate = today.getFullYear()+'-'+month+'-'+date;
    var h = today.getHours();
    var m = today.getMinutes();
    m = checkTime(m);
    var s = today.getSeconds()
    s= checkTime(s);
    var TodaysTime = h+":"+m+":"+s


    return TodaysDate+" "+TodaysTime
}

export default UpdatedDate
