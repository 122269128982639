import React, { useState,useEffect, useRef, createRef } from 'react';
import {Modal,Button,Form,InputGroup,Dropdown,DropdownButton,Card,Accordion,Toast} from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { IconButton } from '@material-ui/core';
import Backdrop from '../backdrop/backdrop';
import Select from 'react-select';
import { validateIsBillingUser, validateIsOperationUser, validateIsAdminUser } from '../UserCredentials'
import fire from '../../Configs/firebase';

function MyVerticallyCenteredModal(props) {
const [getStatus,setStatus] = useState();
const [getPrimaryStatus,setPrimaryStatus] = useState("");
const [primaryStatusSelection,setPrimaryStatusSelection] = useState(false);
const [firstStatus,setFirstStatus] = useState(false);
const [secondStatus,setSecondStatus] = useState(false);
const [thirdStatus,setThirdStatus] = useState(false);
const [showToast,setShowToast] = useState(false);
const [errorText,setErrorText]=useState("");
const [getBackDrop,setBackDrop]=useState(false);
const [oldItemBrand,setOldItemBrand]=useState("");
const [isCategoryRestricted, setIsCategoryRestricted]=useState(true);

const [getQuantity,setQuantity] = useState(props.item_qty);
const [quantityWeight, setQuantityWeight] = useState("");
const [productsObj, setProductsObj] = useState({});
const [replacementItemsArray, setReplacementItemsArray]=useState([]);
const [selectedReplacementItem, setSelectedReplacementItem] = useState("SELECT");
const [selectedReplacementItemObj, setSelectedReplacementItemObj] = useState({});
const [replaceRadioChecked,setReplaceRadioChecked] = useState(false);
const reasonlabel = useRef()
const reasonError = useRef()

let modifiedItemDelivered = "";
let grossWeight = "";

useEffect(() => {
  if(props.order_status){
    if(props.order_status.toLowerCase().includes("shipped") || props.order_status.toLowerCase().includes("delivered")){
      setBackDrop(true);
      let item_id=props.item_id;
      let finalReplacementProducts=[];

      fire.database().ref(`Product_Master_New/A`).once('value',snapshot=>{
        let productsList=snapshot.val();

        if(productsList){
          let currSubCategory=productsList[item_id]?productsList[item_id]['sub_category']:"";
          let currReplacementItem=productsList[item_id]?productsList[item_id]['replacement_items']:"";
          let currItemBrand=productsList[item_id]?productsList[item_id]['brand']:"";

          if(currReplacementItem){
            let replacementItemsList=currReplacementItem.split(",");
            for(let i=0;i<replacementItemsList.length;i++){
              let product_id=replacementItemsList[i];
              let prodele={};
              prodele['item_id']=product_id;
              prodele['long_description']=productsList[product_id]['long_description'];
              prodele['brand']=productsList[product_id]['brand'];
              prodele['dealer_price']=productsList[product_id]['dealer_price'];

              let dealer_price = productsList[product_id].dealer_price ? Number(productsList[product_id].dealer_price) : 0;
              let order_qty = props.item_qty ? Number(props.item_qty) : 0;
              let apmc_charge = productsList[product_id].apmc_charge ? Number(productsList[product_id].apmc_charge) : 0;
              let transport_charge = productsList[product_id].transport_charge ? Number(productsList[product_id].transport_charge) : 0;
              let gst_charge = productsList[product_id].gst_charge ? Number(productsList[product_id].gst_charge) : 0;

              let calcItemValue = (dealer_price * order_qty) + transport_charge + apmc_charge + gst_charge;

              prodele['mrp']=calcItemValue.toFixed(2);

              finalReplacementProducts.push(prodele);
            }
          }

          Object.keys(productsList).map((itemkey,itemvalue)=>{
            if(productsList[itemkey]['sub_category']==currSubCategory && itemkey!=item_id && productsList[itemkey]['active_status']=="Y"){
              let itemele={};
              itemele['item_id']=itemkey;
              itemele['long_description']=productsList[itemkey]['long_description'];
              itemele['brand']=productsList[itemkey]['brand'];
              itemele['dealer_price']=productsList[itemkey]['dealer_price'];

              let dealer_price = productsList[itemkey].dealer_price ? Number(productsList[itemkey].dealer_price) : 0;
              let order_qty = props.item_qty ? Number(props.item_qty) : 0;
              let apmc_charge = productsList[itemkey].apmc_charge ? Number(productsList[itemkey].apmc_charge) : 0;
              let transport_charge = productsList[itemkey].transport_charge ? Number(productsList[itemkey].transport_charge) : 0;
              let gst_charge = productsList[itemkey].gst_charge ? Number(productsList[itemkey].gst_charge) : 0;
              let calcItemValue = (dealer_price * order_qty) + transport_charge + apmc_charge + gst_charge;

              itemele['mrp']=calcItemValue.toFixed(2);

              finalReplacementProducts.push(itemele);
            }
          });
          console.log(finalReplacementProducts);

          setProductsObj(productsList);
          setOldItemBrand(currItemBrand);
          setReplacementItemsArray(finalReplacementProducts);
          setBackDrop(false);
        }
      });
    }
  }
},[]);

useEffect(() => {
  let item_id=props.item_id;
  let finalReplacementProducts=[];

  if(productsObj){
    let currSubCategory=productsObj[item_id]?productsObj[item_id]['sub_category']:"";
    let currReplacementItem=productsObj[item_id]?productsObj[item_id]['replacement_items']:"";
    let currItemBrand=productsObj[item_id]?productsObj[item_id]['brand']:"";

    if(currReplacementItem){
      let replacementItemsList=currReplacementItem.split(",");
      for(let i=0;i<replacementItemsList.length;i++){
        let product_id=replacementItemsList[i];
        let prodele={};
        prodele['item_id']=product_id;
        prodele['long_description']=productsObj[product_id]['long_description'];
        prodele['brand']=productsObj[product_id]['brand'];
        prodele['dealer_price']=productsObj[product_id]['dealer_price'];

        let dealer_price = productsObj[product_id].dealer_price ? Number(productsObj[product_id].dealer_price) : 0;
        let order_qty = props.item_qty ? Number(props.item_qty) : 0;
        let apmc_charge = productsObj[product_id].apmc_charge ? Number(productsObj[product_id].apmc_charge) : 0;
        let transport_charge = productsObj[product_id].transport_charge ? Number(productsObj[product_id].transport_charge) : 0;
        let gst_charge = productsObj[product_id].gst_charge ? Number(productsObj[product_id].gst_charge) : 0;

        let calcItemValue = (dealer_price * order_qty) + transport_charge + apmc_charge + gst_charge;

        prodele['mrp']=calcItemValue.toFixed(2);

        finalReplacementProducts.push(prodele);
      }
    }

    Object.keys(productsObj).map((itemkey,itemvalue)=>{
      if(((productsObj[itemkey]['sub_category']==currSubCategory && isCategoryRestricted) || !isCategoryRestricted) && itemkey!=item_id && productsObj[itemkey]['active_status']=="Y"){
        let itemele={};
        itemele['item_id']=itemkey;
        itemele['long_description']=productsObj[itemkey]['long_description'];
        itemele['brand']=productsObj[itemkey]['brand'];
        itemele['dealer_price']=productsObj[itemkey]['dealer_price'];

        let dealer_price = productsObj[itemkey].dealer_price ? Number(productsObj[itemkey].dealer_price) : 0;
        let order_qty = props.item_qty ? Number(props.item_qty) : 0;
        let apmc_charge = productsObj[itemkey].apmc_charge ? Number(productsObj[itemkey].apmc_charge) : 0;
        let transport_charge = productsObj[itemkey].transport_charge ? Number(productsObj[itemkey].transport_charge) : 0;
        let gst_charge = productsObj[itemkey].gst_charge ? Number(productsObj[itemkey].gst_charge) : 0;
        let calcItemValue = (dealer_price * order_qty) + transport_charge + apmc_charge + gst_charge;

        itemele['mrp']=calcItemValue.toFixed(2);

        finalReplacementProducts.push(itemele);
      }
    });
    console.log(isCategoryRestricted);
    console.log(finalReplacementProducts);

    setReplacementItemsArray(finalReplacementProducts);
  }
},[isCategoryRestricted])

const ReplacementItemsDropdown = () => {
  let rows=[];
  replacementItemsArray.map((replacementItem,index)=>{
    let text=replacementItem.item_id+" - "+replacementItem.long_description+" - "+replacementItem.brand;
    rows.push({"value":text,"label":text,"prodObj":replacementItem, "item_id":replacementItem.item_id })
  });

  let selectedReplaceItem = Object.keys(selectedReplacementItemObj).length>0?(selectedReplacementItemObj.item_id+" - "+selectedReplacementItemObj.long_description):"SELECT";

  return (<><Select defaultValue={{"value":selectedReplaceItem,"label":selectedReplaceItem}} options={rows} onChange={(val)=>{ console.log(val); setSelectedReplacementItem(val.item_id);setSelectedReplacementItemObj(val.prodObj); }}/></>);
}

const handleMainSubmit = (event)=>{
  event.preventDefault();
  event.stopPropagation();
  console.log("Quantity",props)

  if(selectedReplacementItem!="SELECT" || getPrimaryStatus=="Stock Discrepancy"){
    if(selectedReplacementItem=="SELECT"){
      setErrorText(`Please select replacement item.`);
      setShowToast(true);
      return;
    }

      let finalItemDescBrand=selectedReplacementItemObj.long_description+" - "+selectedReplacementItemObj.brand+" / "+oldItemBrand;

      let itemData={
        "item_id":Number(selectedReplacementItem),
        "item_disc":finalItemDescBrand,
        "ordered_item_id":Number(props.item_id),
        "ordered_item_disc":props.item_desc,
        "new_dealer_price":selectedReplacementItemObj.dealer_price,
        "new_mrp":selectedReplacementItemObj.mrp,
        'item_modified_qty': getQuantity,
        'item_primary_reason' : props.order_status.toLowerCase().includes("shipped")?"Bill Modified":getPrimaryStatus,
        'item_reason' : props.order_status.toLowerCase().includes("shipped")?"Short Supply":getStatus,
        "status" : props.order_status.toLowerCase()===("shipped")?"Shipped-Modified":props.order_status
      };

      if(!getStatus && props.order_status.includes("Delivered")){
        setErrorText(`Please select reasons.`);
        setShowToast(true);
        return;
      }else if(props.order_status.includes("Delivered")){
        itemData['item_delivered_qty']=getQuantity;
        itemData['replacement_after_delivery']="Yes";
        itemData['item_primary_reason']="Stock Discrepancy";
        itemData['item_reason']=getStatus?getStatus:"";
        delete itemData['item_modified_qty'];
      }

      if(Number(getQuantity)>Number(props.item_qty)){
        setErrorText(`Item Modified Qty should not be increased more than ordered Qty.`);
        setShowToast(true);
        return;
      } else if(Number(getQuantity) % Number(props.case_size) != 0) {
        setErrorText(`Item Modified Qty should be updated in multiples of case size.`);
        setShowToast(true);
        return;
      } else{
        props.callback(props.driver_id,props.order_id,props.item_qty,{},itemData);
      }
  }else{
    let item_modified_param =''
    let itemQuantityType=""
    if((props.order_status.toLowerCase().includes("shipped-modified") && getQuantity) || (getStatus && getQuantity)) {
      if(props.order_status.includes(`Shipped`)){
        item_modified_param = `item_modified_qty`
        itemQuantityType=`Modified`
      }else{
        item_modified_param = `item_delivered_qty`
        itemQuantityType=`Delivered`
      }

      if(Number(getQuantity)>Number(props.item_qty) && quantityWeight==""){
        setErrorText(`Item ${itemQuantityType} Qty should not be increased more than ordered Qty.`);
        setShowToast(true);
        return;
      }else if(quantityWeight!="" && parseInt(getQuantity)>parseInt(props.item_qty) && props.order_status.includes(`Shipped`)){
        setErrorText(`Item ${itemQuantityType} Qty should not be increased more than ordered Qty.`);
        setShowToast(true);
        return;
      }else if(Number(getQuantity) % Number(props.case_size) != 0) {
        setErrorText(`Item ${itemQuantityType} Qty should be updated in multiples of case size.`);
        setShowToast(true);
        return;
      }else if(props.item_modified_qty!=null && !props.order_status.includes(`Shipped`)){
        if(Number(getQuantity)>Number(props.item_modified_qty)){
          setErrorText(`Delivered qty cannot be more than Modified qty.`);
          setShowToast(true);
          return;
        } else{
          if(getPrimaryStatus=="Bill Modified"){
            props.callback(props.driver_id,props.order_id,props.item_qty,{
              [item_modified_param]: getQuantity,
              'item_modified_qty': getQuantity,
              'item_primary_reason' : props.order_status.toLowerCase().includes("shipped-modified")?"Bill Modified":getPrimaryStatus,
              'item_reason' : props.order_status.toLowerCase().includes("shipped-modified")?"Short Supply":getStatus,
              'status' : props.order_status.toLowerCase()===("shipped")?"Shipped-Modified":props.order_status
            },{})
          }else{
            props.callback(props.driver_id,props.order_id,props.item_qty,{
              [item_modified_param]: getQuantity,
              'item_primary_reason' : props.order_status.toLowerCase().includes("shipped-modified")?"Bill Modified":getPrimaryStatus,
              'item_reason' : props.order_status.toLowerCase().includes("shipped-modified")?"Short Supply":getStatus,
              'status' : props.order_status.toLowerCase()===("shipped")?"Shipped-Modified":props.order_status
            },{})
          }
        }
      }else{
        console.log(props.order_status)

        if(props.order_status.includes(`Shipped`)){
          props.callback(props.driver_id,props.order_id,props.item_qty,{
            [item_modified_param]: getQuantity,
            'item_primary_reason' : props.order_status.toLowerCase().includes("shipped-modified")?"Bill Modified":getPrimaryStatus,
            'item_reason' : props.order_status.toLowerCase().includes("shipped-modified")?"Short Supply":getStatus,
            'status' : props.order_status.toLowerCase()===("shipped")?"Shipped-Modified":props.order_status
          },{})
        }else{
          if(getPrimaryStatus=="Bill Modified"){
            props.callback(props.driver_id,props.order_id,props.item_qty,{
              [item_modified_param]: getQuantity,
              'item_modified_qty': getQuantity,
              'item_primary_reason' : props.order_status.toLowerCase().includes("shipped-modified")?"Bill Modified":getPrimaryStatus,
              'item_reason' : props.order_status.toLowerCase().includes("shipped-modified")?"Short Supply":getStatus,
              'status' : props.order_status.toLowerCase()===("shipped")?"Shipped-Modified":props.order_status
            },{})
          }else{
            props.callback(props.driver_id,props.order_id,props.item_qty,{
              [item_modified_param]: getQuantity,
              'item_primary_reason' : props.order_status.toLowerCase().includes("shipped-modified")?"Bill Modified":getPrimaryStatus,
              'item_reason' : props.order_status.toLowerCase().includes("shipped-modified")?"Short Supply":getStatus,
              'status' : props.order_status.toLowerCase()===("shipped")?"Shipped-Modified":props.order_status
            },{})
          }
        }
      }
    } else {
      setErrorText("Enter Quantity AND Select Reason.");
      setShowToast(true);
      return;
    }
  }
}

const calculateQuantity = (quantityWeight)=> {
  if(quantityWeight){

    let bagSize = props.item_desc.split(' ')
    if(bagSize[bagSize.length-1].includes("kg")) {
      bagSize=(bagSize[bagSize.length-2])
      quantityWeight = parseFloat(quantityWeight);
      grossWeight = quantityWeight/bagSize;
      modifiedItemDelivered = grossWeight.toFixed(2)+'';
      setQuantity(modifiedItemDelivered)
    }
  }
}

  const submit_ref = createRef()

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          {props.order_status.toLowerCase().includes("shipped")?"Enter Shipped Quantity or Shipped Weight":"Enter Delivered Quantity or Delivered Weight"}

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleMainSubmit}>
          {(!validateIsOperationUser((JSON.parse(sessionStorage.getItem(`user`))[0].email)) ||
            (validateIsOperationUser((JSON.parse(sessionStorage.getItem(`user`))[0].email)) && props.order_status.toLowerCase().includes("shipped"))) && <Form.Group>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Radio aria-label="radio-qty" name="group1" standalone defaultChecked/>
                <InputGroup.Text id="inputGroup-sizing-lg">{props.order_status.toLowerCase().includes("shipped")?"Shipped":"Delivered"} Item Quantity</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control type="number" onChange={(e)=>{setQuantity(e.target.value)}} value={getQuantity} required ref={reasonlabel}/>
              <label style={{color:'red',display:'none'}} ref={reasonError}>Enter the Quantity</label>
            </InputGroup>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Radio aria-label="radio-kg-qty" name="group1" standalone disable/>
                <InputGroup.Text id="inputGroup-sizing-lg">{props.order_status.toLowerCase().includes("shipped")?"Shipped":"Delivered"} Item Weight (in KG) </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control  onChange={(e)=>{let val = e.target.value; val = val.replace(/([^0-9.]+)/, "");e.target.value = val;setQuantityWeight(val);calculateQuantity(val)}} />
              <label style={{color:'red',display:'none'}} >Enter the Quantity</label>
            </InputGroup>
          </Form.Group>}

            <Form.Group>
              <Form.Label>Reason for partial delivery</Form.Label>
              <InputGroup>
                <Form.Control type="input" value={getPrimaryStatus} placeholder={"Select primary reason"} required readOnly/>
                <DropdownButton
                  as={InputGroup.Append}
                  variant="outline-primary"
                  title="Edit"
                  >
                  {!props.order_status.toLowerCase().includes("shipped") ? <><Dropdown.Item href="#" name="Returns" onClick={(e)=>{e.preventDefault();setPrimaryStatus(e.target.name);setPrimaryStatusSelection(true);setStatus("");setFirstStatus(true);setSecondStatus(false);setThirdStatus(false)}}>Returns</Dropdown.Item>
                  <Dropdown.Item href="#" name="Short" onClick={(e)=>{e.preventDefault();setPrimaryStatus(e.target.name);setPrimaryStatusSelection(true);setStatus("");setFirstStatus(false);setSecondStatus(true);setThirdStatus(false)}}>Short</Dropdown.Item></>:""}
                  {!validateIsBillingUser((JSON.parse(sessionStorage.getItem(`user`))[0].email))?(<Dropdown.Item href="#" name="Bill Modified" onClick={(e)=>{e.preventDefault();setPrimaryStatus(e.target.name);setPrimaryStatusSelection(true);setStatus("");setFirstStatus(false);setSecondStatus(false);setThirdStatus(true)}}>Bill Modified</Dropdown.Item>):null}
                  {((validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email)) ||
                    validateIsOperationUser((JSON.parse(sessionStorage.getItem(`user`))[0].email)) ) && props.order_status.toLowerCase().includes("delivered"))?(<Dropdown.Item href="#" name="Stock Discrepancy" onClick={(e)=>{e.preventDefault();setPrimaryStatus(e.target.name);setPrimaryStatusSelection(true);setStatus("");setFirstStatus(false);setSecondStatus(true);setThirdStatus(false)}}>Stock Discrepancy</Dropdown.Item>):null}
                </DropdownButton>
              </InputGroup>
            </Form.Group>

            {(primaryStatusSelection)?(
              <Form.Group>
                <InputGroup>
                  <Form.Control type="input" value={getStatus} placeholder={"Select a secondary reason"} required readOnly/>
                  <DropdownButton
                    as={InputGroup.Append}
                    variant="outline-primary"
                    title="Edit"
                    >
                    {(firstStatus && getPrimaryStatus!="Stock Discrepancy")?(<Dropdown.Item href="#" name="Quality Issue" onClick={(e)=>{e.preventDefault();setStatus(e.target.name)}}>Quality Issue</Dropdown.Item>):(null)}
                    {(firstStatus && getPrimaryStatus!="Stock Discrepancy")?(<Dropdown.Item href="#" name="Wrongly Ordered" onClick={(e)=>{e.preventDefault();setStatus(e.target.name)}}>Wrongly Ordered</Dropdown.Item>):(null)}
                    {(firstStatus && getPrimaryStatus!="Stock Discrepancy")?(<Dropdown.Item href="#" name="Rate Issue" onClick={(e)=>{e.preventDefault();setStatus(e.target.name)}}>Rate Issue</Dropdown.Item>):(null)}
                    {(firstStatus && getPrimaryStatus!="Stock Discrepancy")?(<Dropdown.Item href="#" name="Weight Issue" onClick={(e)=>{e.preventDefault();setStatus(e.target.name)}}>Weight Issue</Dropdown.Item>):(null)}
                    {(firstStatus && getPrimaryStatus!="Stock Discrepancy")?(<Dropdown.Item href="#" name="Cash Not Available" onClick={(e)=>{e.preventDefault();setStatus(e.target.name)}}>Cash Not Available</Dropdown.Item>):(null)}
                    {(secondStatus && getPrimaryStatus!="Stock Discrepancy")?(<Dropdown.Item href="#" name="Short Loaded" onClick={(e)=>{e.preventDefault();setStatus(e.target.name)}}>Short Loaded</Dropdown.Item>):(null)}
                    {(secondStatus && getPrimaryStatus!="Stock Discrepancy")?(<Dropdown.Item href="#" name="Other Short" onClick={(e)=>{e.preventDefault();setStatus(e.target.name)}}>Other Short</Dropdown.Item>):(null)}
                    {(secondStatus && getPrimaryStatus!="Stock Discrepancy")?(<Dropdown.Item href="#" name="Weight Issue" onClick={(e)=>{e.preventDefault();setStatus(e.target.name)}}>Weight Issue</Dropdown.Item>):(null)}
                    {(thirdStatus && getPrimaryStatus!="Stock Discrepancy")?(<Dropdown.Item href="#" name="Short Supply" onClick={(e)=>{e.preventDefault();setStatus(e.target.name)}}>Short Supply</Dropdown.Item>):(null)}
                    {(thirdStatus && getPrimaryStatus!="Stock Discrepancy")?(<Dropdown.Item href="#" name="Excess Weight" onClick={(e)=>{e.preventDefault();setStatus(e.target.name)}}>Excess Weight</Dropdown.Item>):(null)}
                    {((validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email)) ||
                      validateIsOperationUser((JSON.parse(sessionStorage.getItem(`user`))[0].email)) ) && props.order_status.toLowerCase().includes("delivered"))?(<Dropdown.Item href="#" name="Wrongly Loaded" onClick={(e)=>{e.preventDefault();setStatus(e.target.name)}}>Wrongly Loaded</Dropdown.Item>):null}
                    {((validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email)) ||
                      validateIsOperationUser((JSON.parse(sessionStorage.getItem(`user`))[0].email)) ) && props.order_status.toLowerCase().includes("delivered"))?(<Dropdown.Item href="#" name="Wrongly Delivered" onClick={(e)=>{e.preventDefault();setStatus(e.target.name)}}>Wrongly Delivered</Dropdown.Item>):null}
                  </DropdownButton>
                </InputGroup>
              </Form.Group>
            ):(null)}
            {/* <br />
            <DropDownListComponent id="primary-status-ddl" ref={(scope) => { setPrimaryStatusObj(scope); }} fields={primaryStatusField} dataSource={primaryStatus} placeholder='Select a Primary status' />
            <br />
            <DropDownListComponent id="status-ddl" ref={(scope) => { setStatusObj(scope); }} enabled={false} fields={statusField} dataSource={status} placeholder='Select a status'  change={onPrimaryStatusChange()}/>
            <br /> */}

            <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
            <Toast.Header>
              <strong className="mr-auto">Error</strong>
            </Toast.Header>
              <Toast.Body style={{color:'red', position: 'center'}}>{errorText}</Toast.Body>
            </Toast>

            {replacementItemsArray.length>0?(<><InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Checkbox aria-label="check-category" name="group23" standalone defaultChecked={isCategoryRestricted} onClick={(e)=>{setIsCategoryRestricted(e.target.checked)}} />
                <InputGroup.Text id="inputGroup-sizing-lg">Category Restriction </InputGroup.Text>
              </InputGroup.Prepend>
            </InputGroup>
            </>):null}

            {replacementItemsArray.length>0?(<><InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Radio aria-label="radio-kg-qty" name="group2" standalone onClick={(e)=>{setReplaceRadioChecked(e.target.checked)}} />
                <InputGroup.Text id="inputGroup-sizing-lg">Replace with a similar Brand </InputGroup.Text>
              </InputGroup.Prepend>
            </InputGroup>
            </>):null}

            {(replacementItemsArray.length>0 && replaceRadioChecked)?<ReplacementItemsDropdown />:null}

            <Form.Group>
              <Button type="submit" hidden ref={submit_ref}>Submit</Button>
            </Form.Group>
          </Form>
          {getBackDrop ? (<Backdrop parentLoadStatus={getBackDrop} />) : (null)}
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" onClick={(e)=>{e.preventDefault();submit_ref.current.click()}}>Submit</Button>
          <Button variant="light" onClick={()=>{setStatus();props.onHide()}}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }



  function App(props) {

    const [modalShow, setModalShow] = React.useState(props.ModalValue);

    return (
        <MyVerticallyCenteredModal
          show={props.ModalValue}
          onHide={() => {setModalShow(false);props.parentCallBack(false)}}
          driver_id = {props.driver_id}
          order_id = {props.order_id}
          order_status = {props.order_status}
          case_size = {props.case_size}
          item_qty = {props.item_qty}
          item_id = {props.item_id}
          ordered_item_id = {props.ordered_item_id}
          item_desc = {props.item_desc}
          ordered_item_desc = {props.ordered_item_desc}
          item_modified_qty = {props.item_modified_qty}
          childfirebaseInsert = {props.childfirebaseInsert}
          callback = {props.callback}
        />
    );
  }

 export default App
