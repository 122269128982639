import React, { useState, useEffect } from "react";
import { Modal, Button, Group } from "@mantine/core";
import { Table } from "react-bootstrap";

const DriverSummaryModal = (props) => {
  const opened = props.opened;
  const setOpened = props.setOpened;
  const orders = props.orders;
  const allocation = props.allocation;
  const routeNo = props.routeNo;

  const [tableData, setTableData] = useState([]);
  const [ordersByStatus, setOrdersByStatus] = useState([]);

  const populateTableData = () => {
    const tableD = [];

    if (!orders || !allocation) {
      setTableData([]);
      setOrdersByStatus([]);
      return;
    }

    const allocationHistory = allocation;
    // console.log(
    //   "DEBUG ready to run populate with data",
    //   allocationHistory,
    //   ordersHistory
    // );

    const visitedOrders = [];
    // console.log(
    //   "iterating for ,",
    //   allocationHistory[key].current_Route_Id
    // );
    let rowD = {};
    let route_no = routeNo;
    let city = "";
    let area = "";
    let vehicle_no = "";
    let transporter = "";
    let driver_name = "";
    let driver_no = "";
    let total_planned = 0;
    let status_delivered = 0;
    let status_shipped = 0;
    let status_pending = 0;
    let status_hold = 0;
    let status_cancelled = 0;
    let status_not_attempted = 0;
    let delivered_percentage = 0;

    driver_name = allocationHistory.actual_Driver_Name || "";
    driver_no = allocationHistory.driver_Phone_Number || "";
    transporter = allocationHistory.company_type || "";
    vehicle_no = allocationHistory.vehicle_number || "";

    const ordersBasedOnStatus = {
      pending_orders: [],
      cancelled_orders: [],
      hold_orders: [],
      not_attempted_orders: [],
      shipped_orders: [],
      delivered: [],
      total_planned: [],
    };
    Object.keys(orders).forEach((key) => {
      let orderId = key.split("-")[0] + "/" + key.split("-")[1];

      if (visitedOrders.includes(orderId)) {
        return;
      }
      visitedOrders.push(orderId);
      city = orders[key].city;
      area = orders[key].area;

      total_planned++;
      ordersBasedOnStatus["total_planned"].push(orders[key]);
      if (
        orders[key].status === "Delivered" ||
        orders[key].status === "Partially-Delivered"
      ) {
        status_delivered++;
        ordersBasedOnStatus["delivered"].push(orders[key]);
      }
      if (
        orders[key].status === "Pending" &&
        orders[key].reason !== "Not Visited"
      ) {
        status_pending++;
        ordersBasedOnStatus["pending_orders"].push(orders[key]);
      }
      if (orders[key].status === "Cancelled") {
        status_cancelled++;
        ordersBasedOnStatus["cancelled_orders"].push(orders[key]);
      }
      if (orders[key].status === "Hold") {
        status_hold++;
        ordersBasedOnStatus["hold_orders"].push(orders[key]);
      }
      if (
        orders[key].reason === "Not Visited" &&
        orders[key].status === "Pending"
      ) {
        status_not_attempted++;
        ordersBasedOnStatus["not_attempted_orders"].push(orders[key]);
      }
      if (
        orders[key].status === "Shipped" ||
        orders[key].status === "Shipped-Modified"
      ) {
        status_shipped++;
        ordersBasedOnStatus["shipped_orders"].push(orders[key]);
      }
    });
    delivered_percentage = getPercentage(status_delivered, total_planned);

    rowD = {
      route_no,
      city,
      area,
      vehicle_no,
      transporter,
      driver_name,
      driver_no,
      total_planned,
      delivered: status_delivered,
      shipped_orders: status_shipped,
      pending_orders: status_pending,
      cancelled_orders: status_cancelled,
      hold_orders: status_hold,
      not_attempted_orders: status_not_attempted,
      delivered_percentage,
    };
    tableD.push(rowD);

    // console.log("DEBUG final table", tableD);

    tableD.sort(function (a, b) {
      var keyA = a.route_no,
        keyB = b.route_no;
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });

    setTableData(tableD);
    setOrdersByStatus(ordersBasedOnStatus);
    // console.log("ordersBasedOnStatus", ordersBasedOnStatus);
  };

  const getPercentage = (numerator, denominator) => {
    if (denominator === 0) {
      return "N/A";
    }
    return ((numerator / denominator) * 100).toPrecision(3) + "%";
  };

  useEffect(() => {
    populateTableData();
  }, []);

  return (
    <Modal
      size="100%"
      opened={opened}
      onClose={() => setOpened(false)}
      title="Delivery Summary"
      style={{ fontSize: ".75rem" }}
    >
      {tableData.length !== 0 ? (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Route No</th>
              <th>City</th>
              <th>Area</th>
              <th>Vehicle No</th>
              <th>Driver Name</th>
              <th>Driver No</th>
              <th>Transporter</th>
              <th>Total planned</th>
              <th>Total delivered</th>
              <th>Shipped</th>
              <th>Pending</th>
              <th>Cancelled</th>
              <th>Hold</th>
              <th>Not visited</th>
              <th>Percentage</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.route_no}</td>
                  <td>{item.city}</td>
                  <td>{item.area}</td>
                  <td>{item.vehicle_no}</td>
                  <td>{item.driver_name}</td>
                  <td>{item.driver_no}</td>
                  <td>{item.transporter}</td>
                  <td>{item.total_planned}</td>
                  <td>{item.delivered}</td>
                  <td>{item.shipped_orders}</td>
                  <td>{item.pending_orders}</td>
                  <td>{item.cancelled_orders}</td>
                  <td>{item.hold_orders}</td>
                  <td>{item.not_attempted_orders}</td>
                  <td>{item.delivered_percentage}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <p>No data to show</p>
      )}
    </Modal>
  );
};

export default DriverSummaryModal;
