import React, { useEffect,useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import NavBar from '../Navigation bar/Navbar'
import fire from '../../Configs/firebase'
import Backdrop from '../backdrop/backdrop'
import XLSX from 'xlsx';

import { Link } from 'react-router-dom'
import { IconButton } from '@material-ui/core'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from '@fortawesome/free-solid-svg-icons';

import { InputGroupAddon, Input, InputGroupText, CardHeader, CardTitle, CardText } from 'reactstrap';
import {Tab,Row,Col,Nav,Form,Dropdown,ProgressBar,Tabs,Image,InputGroup,Button,DropdownButton,Breadcrumb,Table,ButtonGroup,Container, Accordion,Card, Navbar} from 'react-bootstrap';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

import ShowParentOrderItemModal from './itemmodal.js'
import ShowReturnOrderItemModal from './returnitemmodal.js'
import { validateIsAdminUser } from '../UserCredentials'

function formatDate(date) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

function formatDateTime(datetimeval) {

    let datetimestr = datetimeval.split(' ');
    let datestr = datetimestr[0];
    let timestr = datetimestr[1];
    let timestring=""

    let datestrsplit = datestr.split('-');
    let datestring = datestrsplit[2] + '/' + datestrsplit[1] + '/' + datestrsplit[0];
    if(timestr){
    let timestrsplit = timestr.split(':');
    timestring = ' ' + timestrsplit[0] + ':' + timestrsplit[1];
    }
    return datestring + timestring;
}

String.prototype.padLeft = function (length, character) {
    return new Array(length - this.length + 1).join(character || ' ') + this;
};

Date.prototype.toFormattedString = function () {
    return [String(this.getDate()).padLeft(2, '0'),
            String(this.getMonth()+1).padLeft(2, '0'),
            String(this.getFullYear())].join("/");
};

function formatOrderedBy(orderedBy){
  if(orderedBy){
    let orderedByDotCount=orderedBy.split(".").length - 1;

    if(orderedByDotCount==1){
      let username=orderedBy.split("@")[0];
      username=username.replace(/(^\w|\s\w)/g, m => m.toUpperCase());
      return username;
    }else if(orderedByDotCount==2){
      let usernameSeparator=orderedBy.split("@")[0];
      let usernamelist=usernameSeparator.split(".");
      let firstname=usernamelist[0];
      firstname=firstname.replace(/(^\w|\s\w)/g, m => m.toUpperCase());
      return firstname;
    }
  }else{
    return null;
  }
}


const DeliverySchedules = () => {

  let userRoutes=sessionStorage.getItem("allowedRoutes")?JSON.parse(sessionStorage.getItem("allowedRoutes")):[];
  console.log(userRoutes);
  let allowedRoutesSet = new Set();

  for(let i=0;i<userRoutes.length;i++){
    let route=`/${userRoutes[i]}`;
    if(!allowedRoutesSet.has(route)){
      allowedRoutesSet.add(route);
    }
  }
  console.log(allowedRoutesSet);

  const [ordersList, setOrdersList] = useState([]);
  const [searchOrdersList, setSearchOrdersList] = useState([]);
  const [exportData,setExportData]=useState(false);
  let selecteddate=new Date();
  selecteddate.setDate(selecteddate.getDate()-1);
  const [startDate, setStartDate] = useState(new Date(selecteddate));
  const [endDate, setEndDate] = useState(new Date());

  const [showOrderItems,setShowOrderItems] = useState(false);
  const [parentOrderNumber,setParentOrderNumber] = useState("");
  const [showReturnOrderItems,setShowReturnOrderItems] = useState(false);
  const [returnOrderNumber,setReturnOrderNumber] = useState("");
  const [deliveryDate,setDeliveryDate] = useState("");

  const [backDrop, setBackDrop] = useState(false);

  useEffect(() => {
    fetchScheduledDeliveryOrders();
  },[])

  const fetchScheduledDeliveryOrders = () => {
    setBackDrop(true);

    let apiurlstr=`http://services2.superzop.com:3000/api/superzop/delivery/ordersbasedondeliverydate?start_date=${formatDate(new Date(startDate))}&end_date=${formatDate(new Date(endDate))}&order_type=returns&token=${sessionStorage.getItem("apiToken")}`;
    console.log(apiurlstr);
    fetch(apiurlstr, {
      headers: {  'x-access-token' : sessionStorage.getItem('x-access-token') }
    })
    .then((response) => {
      return (response['status']==200)?response.json():[];
    })
    .then((result) => {
        let tempOrdersList=result['orders'];
        let finalOrdersList=[];
        for(let i=0;i<tempOrdersList.length;i++){
          let ele=tempOrdersList[i];
          let order_number=tempOrdersList[i]['order_number'];
          let parentordernumber=order_number.split("-")[0]+"/"+order_number.split("/")[1];
          ele['parent_order_number']=parentordernumber;
          finalOrdersList.push(ele);
        }
        setBackDrop(false);
        setOrdersList(finalOrdersList)
        setSearchOrdersList(finalOrdersList)
    })
    .catch((error) => {
      setBackDrop(false);
      setOrdersList([]);
      setSearchOrdersList([]);
    });
  }


  useEffect(() => {
    if(startDate && endDate){
      fetchScheduledDeliveryOrders();
    }
  }, [startDate,endDate])

  const ScheduledDeliveryOrderTableHeader = () => {
    return (
        <thead className="thead-light">
            <tr>
                <th>Parent Order No.</th>
                <th>Order No.</th>
                <th>Retailer Id</th>
                <th>Order Value</th>
                <th>Shop Name</th>
                <th>Shop Phone No.</th>
                <th>Order Date</th>
                <th>Status</th>
                <th>Reason</th>
                <th>OrderedBy</th>
                <th>Delivery Date</th>
                <th>Print</th>
            </tr>
        </thead>
    );
  }

  const ScheduledDeliveryOrderTableBody = () => {
    console.log(ordersList);
    let rows=[];
    rows = ordersList.map((row, index) => {
        return (
            <tr key={index}>
                <td><Button onClick={()=>{setShowOrderItems(true);setParentOrderNumber(row.parent_order_number);}}>{row.parent_order_number}</Button></td>
                <td><Button onClick={()=>{setShowReturnOrderItems(true);setReturnOrderNumber(row.order_number);setDeliveryDate(row.delivery_date);}}>{row.order_number}</Button></td>
                <td>{row.retailer_id}</td>
                <td>{row.total_amt}</td>
                <td>{row.shop_name}</td>
                <td>{row.user_name}</td>
                <td>{row.order_date_conv}</td>
                <td>{row.status}</td>
                <td>{row.reason}</td>
                <td>{row.ordered_by}</td>
                <td>{row.delivery_date}</td>
                <td>{(allowedRoutesSet.has(`/orderdetails`) || validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email))) && <Link onClick={() => {sessionStorage.setItem("orderIdToPrint", row.order_number);window.open(`/orderdetails`, "_blank");}}><IconButton><FontAwesomeIcon size="1x" icon={faPrint} /></IconButton></Link>}</td>
            </tr>
        );
    });

    return (<><tbody>{rows}</tbody>
      <ShowParentOrderItemModal
          show={showOrderItems}
          onHide={() => setShowOrderItems(false)}
          parentOrderNumber={parentOrderNumber}
          showOrderItems = {showOrderItems}
      />
      <ShowReturnOrderItemModal
          show={showReturnOrderItems}
          onHide={() => setShowReturnOrderItems(false)}
          returnOrderNumber={returnOrderNumber}
          deliveryDate={deliveryDate}
          showReturnOrderItems = {showReturnOrderItems}
      /></>);
  }

  const handleStartDateChange = (selecteddate) => {
    setStartDate(new Date(selecteddate));
  }

  const handleEndDateChange = (selecteddate) => {
    setEndDate(new Date(selecteddate));
  }

  const CustomDateInput = ({ value, onClick }) => (
    <input
        value={value}
        onClick={onClick}
        style={{ border: "solid 1px black", height: "30px" }}
    />
  );



    return (<>
      <NavBar/>
      <div className="container col-sm-12" style={{paddingTop:100}}>
        <center><h2 className="h2">Orders List</h2></center>
        <Form inline style={{margin:30, width:'90%', marginLeft: "auto",marginRight: "auto", paddingTop:10}}>
            <InputGroup size="sm" style = {{ margin : 5}}>
              <InputGroupAddon addonType="prepend">Start Date</InputGroupAddon>
                    <DatePicker
                        selected={startDate}
                        onChange={(handleStartDateChange)}
                        minDate={new Date("2017/01/01")}
                        maxDate={new Date()}
                        aria-describedby="basic-addon1"
                        dateFormat="dd/MM/yyyy"
                        customInput={<CustomDateInput />}
                    />
            </InputGroup>
            <InputGroup size="sm" style = {{ margin : 5}}>
              <InputGroupAddon addonType="prepend">End Date</InputGroupAddon>
                    <DatePicker
                        selected={endDate}
                        onChange={(handleEndDateChange)}
                        minDate={new Date("2017/01/01")}
                        maxDate={new Date()}
                        aria-describedby="basic-addon1"
                        dateFormat="dd/MM/yyyy"
                        customInput={<CustomDateInput />}
                    />
            </InputGroup>
        </Form>

        <table className="table table-striped">
            <ScheduledDeliveryOrderTableHeader />
            {ordersList.length>0?(<ScheduledDeliveryOrderTableBody />):(<center><div>No scheduled deliveries found.</div></center>)}
        </table>


        {backDrop?(<Backdrop parentLoadStatus={backDrop}/>):(null)}
      </div></>
    )
}

export default DeliverySchedules
