import React, {useState,useEffect} from 'react'
import { IconButton } from '@material-ui/core';
import Backdrop from '../backdrop/backdrop'
import { Breadcrumb, Tab,Form, Row, Col, Nav, Button,InputGroup,Badge } from 'react-bootstrap';



function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

const ReturnOrdersForm = (props) => {

  const [ordersListStartDate, setOrdersListStartDate] = useState(formatDate(new Date())+'T00:00')
  const [ordersListEndDate, setOrdersListEndDate] = useState(formatDate(new Date())+'T23:59')
  const [backDrop, setBackDrop] = useState(false)



  // initialState = {
  //   orderliststartdate: formatDate(new Date())+'T00:00',
  //   orderlistenddate: formatDate(new Date())+'T23:59',
  //   orderliststatus:'Ordered',
  //   ordertype:'All'
  // }
  

  const submitForm = () => {
    props.handleSubmit({ordersListStartDate,ordersListEndDate})
  }





  return (
    <>
      <form className="form-horizontal">
        <div className="row" style={{width: "50%",marginLeft: "auto", marginRight: "auto", marginTop:10}}>
          <div className="col">
            <label htmlFor="orderliststartdate" className="control-label">StartDate</label>
            <input type="datetime-local" className="form-control" name="orderliststartdate" id="orderliststartdate" value={ordersListStartDate} onChange={(e)=>{setOrdersListStartDate(e.target.value)}} />
          </div>
          <div className="col">
            <label htmlFor="orderlistenddate" className="control-label">EndDate</label>
            <input type="datetime-local" className="form-control" name="orderlistenddate" id="orderlistenddate" value={ordersListEndDate} onChange={(e)=>{setOrdersListEndDate(e.target.value)}} />
          </div>
        </div>
        <div className="row" style={{width: "50%",marginLeft: "auto", marginRight: "auto", marginTop:10}}>
          <div className="col">
            <input type="button" style={{marginLeft: "auto", marginRight: "auto"}} className="btn btn-primary" value="View Orders" onClick={submitForm} />
          </div>
        </div>

      </form>
    </>
    );
}

export default ReturnOrdersForm
