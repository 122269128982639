import React, { useState, useEffect, useRef } from "react";
import fire from "../../Configs/firebase";
import {
  Modal,
  Button,
  Form,
  InputGroup,
  Dropdown,
  DropdownButton,
  Table,
  Tab,
  Tabs,
  Jumbotron,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import ReactToPrint from "react-to-print";
import { IconButton } from "@material-ui/core";
import {
  NumberInput,
  Button as MantineButton,
  Flex,
  SimpleGrid,
  Text,
  Center,
} from "@mantine/core";
import Switch from "react-switch";
import { toast } from "react-toastify";
import {
  service_url,
  support_portal_url,
  token,
} from "../../Configs/mysqlconfig";
import {
  validateIsOperationUser,
  validateIsBillingUser,
  validateIsAdminUser,
  validateIsFinanceUser,
} from "../UserCredentials";
import "./ItemModal.css";
import { InputGroupAddon } from "reactstrap";

function MyVerticallyCenteredModal(props) {
  console.log(props);

  const [getCurrentSelected, setCurrentSelected] = useState("delivered");
  const [driverName, setDriverName] = useState("");
  const [driverPhn, setDriverPhn] = useState("");
  const [completedDelivery, setCompletedDelivery] = useState("");
  const [consolidatedFlag, setConsolidatedFlag] = useState(false);
  const [consolidationPopupOpen, setConsolidationPopupOpen] = useState(false);
  const [consolidationSwitchChecked, setConsolidationSwitchChecked] =
    useState(false);

  const [dispatchedVehicleFlag, setDispatchedVehicleFlag] = useState(false);
  const [dispatchedVehiclePopupOpen, setDispatchedVehiclePopupOpen] =
    useState(false);
  const [dispatchedVehicleSwitchChecked, setDispatchedVehicleSwitchChecked] =
    useState(false);

  const [completedDeliveryFlag, setCompletedDeliveryFlag] = useState(false);
  const [stockCheckedFlag, setStockCheckedFlag] = useState(true);
  const [stockCheckedPopupOpen, setStockCheckedPopupOpen] = useState(false);
  const [stockCheckedSwitchChecked, setStockCheckedSwitchChecked] =
    useState(false);

  const [vehicleNotReturned, setVehicleNotReturned] = useState(false);
  const [isFreshOrder, setIsFreshOrder] = useState(false);
  const [stockReturnImage, setStockReturnImage] = useState(null);
  const [fullViewShow, setFullViewShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [stockReturnImages, setStockReturnImages] = useState([]);

  let driver_id = props.selectedDriver;
  const freshTotalLoadedCrateCount = props.freshTotalLoadedCrateCount;
  const returnCrateInput = useRef();

  const printAllStatusRef = useRef();

  useEffect(() => {
    console.log("DETAILS MODAL");
    console.log(props.allRouteOrdersData);

    let allRouteOrdersData = props.allRouteOrdersData;
    let currRouteOrdersData = allRouteOrdersData[driver_id];
    console.log(currRouteOrdersData);

    let isAllOrdersConsolidated = true;
    let isAllOrdersDispatched = true;
    Object.keys(currRouteOrdersData).map((key, index) => {
      let status = currRouteOrdersData[key].status;
      //Processed or Shipped or Shipped-Modified
      if (status == "Processed" || status.includes("Shipped")) {
        isAllOrdersConsolidated = false;
      }

      if (status != "Hold" && !status.includes("Shipped")) {
        isAllOrdersDispatched = false;
      }
    });
    setConsolidatedFlag(isAllOrdersConsolidated);
    setDispatchedVehicleFlag(isAllOrdersDispatched);

    if (driver_id.startsWith("F")) setIsFreshOrder(true);
  }, []);

  useEffect(() => {
    let driverAllocationPath =
      props.selectDate.getFullYear() +
      "/" +
      (props.selectDate.getMonth() + 1) +
      "/" +
      (props.selectDate.getDate() +
        "-" +
        (props.selectDate.getMonth() + 1) +
        "-" +
        props.selectDate.getFullYear());

    fire
      .database()
      .ref("Driver_Allocation_History/" + driverAllocationPath)
      .once("value", (snapShot) => {
        console.log(snapShot.val());

        if (snapShot.val()) {
          const driverInfo = snapShot.val();
          Object.keys(driverInfo).forEach((driverPhn) => {
            if (
              driverInfo[driverPhn].current_Route_Id === props.selectedDriver
            ) {
              setConsolidationSwitchChecked(
                driverInfo[driverPhn].completed_conslidation
              );
              setDispatchedVehicleSwitchChecked(
                driverInfo[driverPhn].dispatched_vehicle
              );
              setStockCheckedSwitchChecked(driverInfo[driverPhn].stock_checked);
              setCompletedDeliveryFlag(
                driverInfo[driverPhn].completed_delivery
              );
              setVehicleNotReturned(driverInfo[driverPhn].vehicle_not_returned);
              setStockCheckedFlag(
                driverInfo[driverPhn]?.stock_checked || false
              );
            }
          });
        }
      });
  }, []);

  useEffect (() =>{
    RenderUndeliveredImage();
  },[]);
  const fetchDriverDetails = () => {
    fire
      .database()
      .ref(
        "Driver_Allocation_History/" +
          props.selectDate.getFullYear() +
          "/" +
          (props.selectDate.getMonth() + 1) +
          "/" +
          (props.selectDate.getDate() +
            "-" +
            (props.selectDate.getMonth() + 1) +
            "-" +
            props.selectDate.getFullYear())
      )
      .once("value", (snapShot) => {
        console.log(snapShot.val());

        if (snapShot.val()) {
          const driverInfo = snapShot.val();
          console.log(Object.keys(driverInfo));
          Object.keys(driverInfo).forEach((driverPhn) => {
            if (
              driverInfo[driverPhn].current_Route_Id === props.selectedDriver
            ) {
              setDriverName(driverInfo[driverPhn].actual_Driver_Name);
              setDriverPhn(driverInfo[driverPhn].driver_Phone_Number);
              setCompletedDelivery(driverInfo[driverPhn].completed_delivery);
              setVehicleNotReturned(driverInfo[driverPhn].vehicle_not_returned);
            }
          });
        }
      });
  };

  const submitReturnCrateCount = (val) => {
    if (val != 0 && !val) return;
    const url = `${service_url}/api/loaded-inventory?token=${token}`;
    const body = {
      isReturnCrate: "Y",
      delivery_date: formatDateAPI(props.selectDate),
      itemsList: [{ route: driver_id, return_crate_count: val }],
    };
    fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((res) => {
        if (!res.success) {
          throw Error;
        }
        toast("Successfully submitted return crate count", {
          type: toast.TYPE.SUCCESS,
        });
      })
      .catch((err) => {
        toast("Error occured while submitting return crate count", {
          type: toast.TYPE.ERROR,
        });
        console.log(err);
      });
  };

  useEffect(() => {
    fetchDriverDetails();
  }, []);

  function RenderItemsTable(itemType) {
    let finalItemDetails = [];
    if (itemType == "Delivered") {
      finalItemDetails = props.itemDetails["delivered"];
    } else if (itemType == "Short") {
      finalItemDetails = props.itemDetails["short"];
    } else if (itemType == "notLoaded") {
      finalItemDetails = props.itemDetails["notLoaded"];
    } else if (itemType == "Undelivered") {
      finalItemDetails = props.itemDetails["undelivered"];
    } else if (itemType == "loaded") {
      finalItemDetails = props.itemDetails["loaded"];
    } else if (itemType == "replacements") {
      finalItemDetails = props.itemDetails["replacements"];
    }

    let orderVarietyMap = props.getOrderVarietyMap || {};

    if (finalItemDetails) {
      if (finalItemDetails.length == 0)
        return [<p>No Items under this category</p>];
      let temp_table = [];

      finalItemDetails.forEach((item) => {
        let order_list = item.order_list || [];
        let bagID = "";
        for (let i = 0; i < order_list.length; i++) {
          let orderNo = order_list[i];
          if (orderVarietyMap[orderNo]) {
            let orderBagID = orderVarietyMap[orderNo].bagid || "";
            if (orderBagID) {
              bagID += `${orderNo} : ${orderBagID},`;
            }
          }
        }

        if (bagID) {
          bagID = bagID.slice(0, -1);
        }

        if (itemType === "replacements") {
          temp_table.push(
            <tr>
              <td>{item.original_item_id}</td>
              <td>{item.item_id}</td>
              <td>{Number(item.order_qty) || 0}</td>
              <td>{Number(item.item_modified_qty) || 0}</td>
            </tr>
          );
        } else {
          temp_table.push(
            <tr>
              <td>{item.item_id}</td>
              <td>{item.item_disc}</td>
              <td>{Number(item.order_qty) || 0}</td>
              {itemType === "loaded" ? (
                !isFreshOrder ? (
                  <td>₹ {Number(item.mrp) || 0}</td>
                ) : null
              ) : (
                <td>₹ {Number(item.mrp) || 0}</td>
              )}
              <td>{item.variety}</td>
              <td>{item.variety == "SMALL PACK" ? bagID || "" : ""}</td>
            </tr>
          );
        }
      });

      if (itemType === "replacements") {
        return [
          <Table striped bordered hover>
            <thead>
              <tr className="bg-dark text-white">
                <th>Original Item</th>
                <th>Replaced Item</th>
                <th>Order Qty</th>
                <th>Shipped Qty</th>
              </tr>
            </thead>
            <tbody>{temp_table}</tbody>
          </Table>,
        ];
      } else {
        return [
          <Table striped bordered hover>
            <thead>
              <tr className="bg-dark text-white">
                <th>Item ID</th>
                <th>Description</th>
                <th>Quantity</th>
                {itemType === "loaded" ? (
                  !isFreshOrder ? (
                    <th>Value</th>
                  ) : null
                ) : (
                  <th>Value</th>
                )}
                <th>Variety</th>
                <th>Bag ID</th>
              </tr>
            </thead>
            <tbody>{temp_table}</tbody>
          </Table>,
        ];
      }
    } else {
      return [<p>No Items under this category</p>];
    }
  }

  function RenderVarietyBreakdown() {
    let finalItemDetails = props.itemDetails["undelivered"] || [];

    let varietyItemMap = { Bulk: 0, "High Value": 0, "Small Pack": 0, FMCG: 0 };
    finalItemDetails.forEach((item) => {
      let variety = item.variety || "";
      let mrp = Number(item.mrp) || 0;

      if (
        variety == "GONI" ||
        variety == "GONI SUGAR" ||
        variety == "BAG OF PACKS" ||
        variety == "BOX" ||
        variety == "BULK"
      ) {
        varietyItemMap["Bulk"] += mrp;
      } else if (variety == "HIGH VALUE LOOSE" || variety == "CRATE LINES") {
        varietyItemMap["High Value"] += mrp;
      } else if (variety == "SMALL PACK") {
        varietyItemMap["Small Pack"] += mrp;
      } else if (variety == "FMCG") {
        varietyItemMap["FMCG"] += mrp;
      }
    });

    console.log(varietyItemMap);
    let rows = Object.keys(varietyItemMap).map((key, index) => {
      return (
        <tr key={index}>
          <th>{key}</th>
          <td>{varietyItemMap[key].toFixed(2)}</td>
        </tr>
      );
    });

    return (
      <>
        <Table striped bordered hover>
          <tbody>{rows}</tbody>
        </Table>
      </>
    );
  }

  async function RenderUndeliveredImage() {
    try {
      let driverAllocationPath =
        props.selectDate.getFullYear() + "/" +
        (props.selectDate.getMonth() + 1) + "/" +
        (props.selectDate.getDate() + "-" + 
        (props.selectDate.getMonth() + 1) + "-" + 
        props.selectDate.getFullYear());
  
      let snapshot = await fire.database().ref("Driver_Allocation_History/" + driverAllocationPath).once("value");
  
      console.log(snapshot.val());
  
      if (snapshot.val()) {
        const driverInfo = snapshot.val();
        const allImages = [];
  
        for (let driverPhn of Object.keys(driverInfo)) {
          let routeKey = driverInfo[driverPhn].current_Route_Id;
  
          if (
            driverInfo[driverPhn].current_Route_Id === props.selectedDriver && 
            routeKey !== "8888" && 
            routeKey !== "9999" && 
            props.getAccessibleRoutes[routeKey]
          ) {
            let driverSnapshot = await fire.database().ref(`Driver_Allocation_History/${driverAllocationPath}/${driverPhn}`).once('value');
            let driverData = driverSnapshot.val();
  
            if (driverData) {
              // let stockReturnImage = driverData.stock_return_image;
              // console.log("Stock Return Image:", stockReturnImage);
              const imageNodes = ['stock_return_bag_image', 'stock_return_loose_pack__image', 'stock_return_high_value_image', 'stock_return_fmcg_image'];
              imageNodes.forEach(node => {
                if (driverData[node]) {
                  allImages.push({ type: node, url: driverData[node] });
                }
              });
              // setStockReturnImages(allImages)
            }
          }
        }
        setStockReturnImages(allImages)
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  function Summary() {
    let total = {
      delivered: { total_mrp: 0, total_qty: 0 },
      undelivered: { total_mrp: 0, total_qty: 0 },
      notLoaded: { total_mrp: 0, total_qty: 0 },
      short: { total_mrp: 0, total_qty: 0 },
      loaded: { total_mrp: 0, total_qty: 0 },
    };

    props.itemDetails["delivered"].forEach((item) => {
      total["delivered"]["total_mrp"] += Number(item.mrp) || 0;
      total["delivered"]["total_qty"] += Number(item.order_qty) || 0;
    });

    props.itemDetails["undelivered"].forEach((item) => {
      total["undelivered"]["total_mrp"] += Number(item.mrp) || 0;
      total["undelivered"]["total_qty"] += Number(item.order_qty) || 0;
    });

    props.itemDetails["notLoaded"].forEach((item) => {
      console.log("DEBUG check", item);

      total["notLoaded"]["total_mrp"] += Number(item.mrp) || 0;
      total["notLoaded"]["total_qty"] += Number(item.order_qty) || 0;
    });

    props.itemDetails["loaded"].forEach((item) => {
      total["loaded"]["total_mrp"] += Number(item.mrp) || 0;
      total["loaded"]["total_qty"] += Number(item.order_qty) || 0;
    });

    props.itemDetails["short"].forEach((item) => {
      total["short"]["total_mrp"] += Number(item.mrp) || 0;
      total["short"]["total_qty"] += Number(item.order_qty) || 0;
    });

    return [
      <>
        <Table striped bordered hover>
          <thead>
            <tr className="bg-dark text-white">
              <th>Status</th>
              <th>Total Value</th>
              <th>Total Quantity</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Delivered</td>
              <td>₹ {total.delivered.total_mrp.toFixed(2)}</td>
              <td>{total.delivered.total_qty.toFixed(0)}</td>
            </tr>

            <tr>
              <td>Undelivered</td>
              <td>₹ {total.undelivered.total_mrp.toFixed(2)}</td>
              <td>{total.undelivered.total_qty.toFixed(0)}</td>
            </tr>

            {!isFreshOrder ? (
              <tr>
                <td>Short</td>
                <td>₹ {total.short.total_mrp.toFixed(2)}</td>
                <td>{total.short.total_qty.toFixed(0)}</td>
              </tr>
            ) : null}

            {!isFreshOrder ? (
              <tr>
                <td>Not Loaded</td>
                <td>₹ {total.notLoaded.total_mrp.toFixed(2)}</td>
                <td>{total.notLoaded.total_qty.toFixed(0)}</td>
              </tr>
            ) : null}

            <tr>
              <td>Loaded</td>
              <td>₹ {total.loaded.total_mrp.toFixed(2)}</td>
              <td>{total.loaded.total_qty.toFixed(0)}</td>
            </tr>
          </tbody>
        </Table>
        {freshTotalLoadedCrateCount && (
          <SimpleGrid cols={2} justify={"center"} align={"center"}>
            <div
              style={{
                paddingTop: "40px",
              }}
            >
              <Center>
                <Text weight={700}>
                  Total loaded creates : {freshTotalLoadedCrateCount}
                </Text>
              </Center>
            </div>

            <SimpleGrid
              align={"center"}
              justify={"center"}
              cols={2}
              spacing={1}
              style={{
                paddingTop: "15px",
                paddingLeft: "50px",
                paddingBottom: "50px",
              }}
            >
              <div>
                <NumberInput
                  placeholder="Enter count"
                  label="Return crate count"
                  hideControls
                  ref={returnCrateInput}
                />
              </div>
              <div>
                <MantineButton
                  color="green"
                  variant="light"
                  style={{ marginTop: "28px" }}
                  onClick={() => {
                    console.log(returnCrateInput.current.value);
                    submitReturnCrateCount(returnCrateInput.current.value);
                  }}
                >
                  Submit
                </MantineButton>
              </div>
            </SimpleGrid>
          </SimpleGrid>
        )}
      </>,
    ];
  }

  const formatDate = (selecteddate) => {
    let dt = new Date(selecteddate);
    let date = dt.getDate();
    let month = dt.getMonth() + 1;
    let year = dt.getFullYear();
    return `${date}-${month}-${year}`;
  };

  const formatDateAPI = (selecteddate) => {
    let dt = new Date(selecteddate);
    let date = dt.getDate();
    let month = dt.getMonth() + 1;
    let year = dt.getFullYear();
    return `${year}-${month}-${date}`;
  };

  const generatePrintData = () => {
    return (
      <div style={{ margin: "50px" }} ref={printAllStatusRef}>
        <Jumbotron>
          <div style={{ textAlign: "center" }}>DELIVERY DETAILS</div>
          <Table bordered>
            <tr>
              <td>Delivery Boy</td>
              <td>{driverName}</td>
            </tr>
            <tr>
              <td>Delivery Boy Phone</td>
              <td>{driverPhn}</td>
            </tr>
            <tr>
              <td>Route ID</td>
              <td>{props.selectedDriver}</td>
            </tr>
            <tr>
              <td>Delivery Date</td>
              <td>{formatDate(new Date(props.selectDate))}</td>
            </tr>
          </Table>
        </Jumbotron>
        <h2 style={{ textAlign: "center" }}>Item Summary</h2>
        {generateSummary()}
        {/* props.itemDetails['delivered'].length!=0 && <h2 style={{textAlign:"center"}}>Delivered</h2> */}
        {/* props.itemDetails['delivered'].length!=0 && generatePrintDataDelivered() */}
        {props.itemDetails["undelivered"].length != 0 && (
          <h2 style={{ textAlign: "center" }}>Undelivered</h2>
        )}
        {props.itemDetails["undelivered"].length != 0 &&
          generatePrintDataUndelivered()}
        {/* props.itemDetails['short'].length!=0 && <h2 style={{textAlign:"center"}}>Short</h2> */}
        {/* props.itemDetails['short'].length!=0 && generatePrintDataShort() */}
        {/* props.itemDetails['notLoaded'].length!=0 && <h2 style={{textAlign:"center"}}>Not Loaded</h2> */}
        {/* props.itemDetails['notLoaded'].length!=0 && generatePrintDataNotLoaded() */}
        {props.itemDetails["undelivered"].length != 0 &&
          generateSmallPackTable()}
        {props.itemDetails["undelivered"].length != 0 && (
          <h2 style={{ textAlign: "center", paddingTop: "5 px" }}>Short</h2>
        )}
        {props.itemDetails["undelivered"].length != 0 &&
          generateUndeliveredShortItemsTable()}

        <Jumbotron>
          <Table bordered>
            <tr>
              <td>DB Signature</td>
              <td width="50%"></td>
            </tr>
            <tr>
              <td>Cashier Signature</td>
              <td width="50%"></td>
            </tr>
          </Table>
        </Jumbotron>
      </div>
    );
  };

  const generatePrintDataDelivered = () => {
    if (props.itemDetails["delivered"].length == 0)
      return [<p>No Items under this category</p>];
    let temp_table = [];

    props.itemDetails["delivered"].forEach((item) => {
      temp_table.push(
        <tr>
          <td>{item.item_id}</td>
          <td>{item.item_disc}</td>
          <td>{item.order_qty}</td>
          <td>{item.mrp}</td>
        </tr>
      );
    });

    return [
      <Table bordered>
        <thead>
          <tr>
            <th>Item ID</th>
            <th>Description</th>
            <th>Quantity</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>{temp_table}</tbody>
      </Table>,
    ];
  };

  const generateUndeliveredShortItemsTable = () => {
    if (props.itemDetails["short"].length == 0) {
      return;
    }

    let itemShortMap = {};
    itemShortMap["Short Loaded"] = [];
    itemShortMap["Weight Issue"] = [];
    itemShortMap["Other Short"] = [];

    props.itemDetails["short"].forEach((item) => {
      let item_primary_reason = item.item_primary_reason || "";
      let item_reason = item.item_reason || "";

      if (
        item_primary_reason == "Short" &&
        (item_reason == "Short Loaded" ||
          item_reason == "Weight Issue" ||
          item_reason == "Other Short")
      ) {
        itemShortMap[item_reason].push(
          <tr>
            <td>{item_reason}</td>
            <td>{item.status}</td>
            <td>{item.item_id}</td>
            <td>{item.item_disc}</td>
            <td>{item.variety}</td>
            <td>{Number(item.order_qty) || 0}</td>
            <td>{item.mrp}</td>
          </tr>
        );
      }
    });

    let rows = Object.keys(itemShortMap).map((key, index) => {
      let productsData = itemShortMap[key] || [];

      if (productsData.length > 0) {
        return (
          <div key={key}>
            <center>
              <h4>{key}</h4>
            </center>
            <Table bordered>
              <thead>
                <tr>
                  <th>Short Type</th>
                  <th>Status</th>
                  <th>Item Id</th>
                  <th>Description</th>
                  <th>Variety</th>
                  <th>Qty</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>{productsData}</tbody>
            </Table>
          </div>
        );
      }
    });

    return (
      <>
        <div>{rows}</div>
      </>
    );
  };

  const generateSmallPackTable = () => {
    let driverOrders = props.allRouteOrdersData || {};
    let routeValue = props.selectedDriver || "";
    let vehicleOrders = driverOrders[routeValue] || [];
    let orderVarietyMap = props.getOrderVarietyMap || {};

    let smallPackOrders = [];
    Object.keys(vehicleOrders).map((key, index) => {
      let variety = vehicleOrders[key].variety || "";
      let itemPlannedQty = vehicleOrders[key].item_modified_qty
        ? Number(vehicleOrders[key].item_modified_qty)
        : Number(vehicleOrders[key].order_qty);
      let itemDeliveredQty = Number(vehicleOrders[key].item_delivered_qty);
      let itemQty = itemPlannedQty - itemDeliveredQty;
      if (
        variety == "SMALL PACK" &&
        vehicleOrders[key].status.toLowerCase().includes(`partially`) &&
        itemQty > 0
      ) {
        let orderNo = vehicleOrders[key].order_number || "";
        let bagID = "";
        if (orderVarietyMap[orderNo]) {
          bagID = orderVarietyMap[orderNo].bagid || "";
        }

        smallPackOrders.push(
          <tr>
            <td>{vehicleOrders[key].status}</td>
            <td>{orderNo}</td>
            <td>{vehicleOrders[key].item_id}</td>
            <td>{vehicleOrders[key].item_disc}</td>
            <td>{itemQty}</td>
            <td>{bagID || ""}</td>
          </tr>
        );
      }
      if (
        variety == "SMALL PACK" &&
        vehicleOrders[key].status.toLowerCase().includes(`cancel`)
      ) {
        let orderNo = vehicleOrders[key].order_number || "";
        let bagID = "";
        if (orderVarietyMap[orderNo]) {
          bagID = orderVarietyMap[orderNo].bagid || "";
        }

        smallPackOrders.push(
          <tr>
            <td>{vehicleOrders[key].status}</td>
            <td>{orderNo}</td>
            <td>{vehicleOrders[key].item_id}</td>
            <td>{vehicleOrders[key].item_disc}</td>
            <td>{itemPlannedQty}</td>
            <td>{bagID || ""}</td>
          </tr>
        );
      }
      if (
        variety == "SMALL PACK" &&
        vehicleOrders[key].status.toLowerCase().includes(`pending`)
      ) {
        let orderNo = vehicleOrders[key].order_number || "";
        let bagID = "";
        if (orderVarietyMap[orderNo]) {
          bagID = orderVarietyMap[orderNo].bagid || "";
        }

        smallPackOrders.push(
          <tr>
            <td>{vehicleOrders[key].status}</td>
            <td>{orderNo}</td>
            <td>{vehicleOrders[key].item_id}</td>
            <td>{vehicleOrders[key].item_disc}</td>
            <td>{itemPlannedQty}</td>
            <td>{bagID || ""}</td>
          </tr>
        );
      }
    });
    if (smallPackOrders.length > 0) {
      return [
        <div>
          <h2 style={{ textAlign: "center" }}>SMALL PACK</h2>
          <Table bordered>
            <thead>
              <tr>
                <th>Status</th>
                <th>Order Number</th>
                <th>Item ID</th>
                <th>Item Description</th>
                <th>Qty</th>
                <th>Bag Id</th>
              </tr>
            </thead>
            <tbody>{smallPackOrders}</tbody>
          </Table>
        </div>,
      ];
    }
  };

  const generatePrintDataUndelivered = () => {
    if (props.itemDetails["undelivered"].length == 0)
      return [<p>No Items under this category</p>];
    let temp_table = [];
    let orderVarietyMap = props.getOrderVarietyMap || {};

    let varietyTableMap = {};
    varietyTableMap["Small Pack"] = [];
    varietyTableMap["High Value"] = [];
    varietyTableMap["Bulk"] = [];
    varietyTableMap["FMCG"] = [];

    props.itemDetails["undelivered"].forEach((item) => {
      let order_list = item.order_list || [];
      let bagID = "";
      for (let i = 0; i < order_list.length; i++) {
        let orderNo = order_list[i];
        if (orderVarietyMap[orderNo]) {
          let orderBagID = orderVarietyMap[orderNo].bagid || "";
          if (orderBagID) {
            bagID += `${orderNo} : ${orderBagID},`;
          }
        }
      }

      if (bagID) {
        bagID = bagID.slice(0, -1);
      }

      let variety = item.variety || "";

      if (variety == "SMALL PACK") {
        // varietyTableMap["Small Pack"].push(<tr>
        //   <td>{item.item_id}</td>
        //   <td>{item.item_disc}</td>
        //   <td>{item.variety}</td>
        //   <td>{Number(item.order_qty) || 0}</td>
        //   <td>{item.mrp}</td>
        //   <td>{bagID || ""}</td>
        // </tr>)
      } else if (variety == "FMCG") {
        varietyTableMap["FMCG"].push(
          <tr>
            <td>{item.item_id}</td>
            <td>{item.item_disc}</td>
            <td>{item.variety}</td>
            <td>{Number(item.order_qty) || 0}</td>
            <td>{item.mrp}</td>
            <td>{bagID || ""}</td>
          </tr>
        );
      } else if (variety == "HIGH VALUE LOOSE" || variety == "CRATE LINES") {
        varietyTableMap["High Value"].push(
          <tr>
            <td>{item.item_id}</td>
            <td>{item.item_disc}</td>
            <td>{item.variety}</td>
            <td>{Number(item.order_qty) || 0}</td>
            <td>{item.mrp}</td>
          </tr>
        );
      } else {
        varietyTableMap["Bulk"].push(
          <tr>
            <td>{item.item_id}</td>
            <td>{item.item_disc}</td>
            <td>{item.variety}</td>
            <td>{Number(item.order_qty) || 0}</td>
            <td>{item.mrp}</td>
          </tr>
        );
      }
    });

    let rows = Object.keys(varietyTableMap).map((key, index) => {
      let productsData = varietyTableMap[key] || [];

      if (productsData.length > 0) {
        return (
          <div key={key}>
            <center>
              <h4>{key}</h4>
            </center>
            <Table bordered>
              <thead>
                <tr>
                  <th>Item ID</th>
                  <th>Description</th>
                  <th>Variety</th>
                  <th>Quantity</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>{productsData}</tbody>
            </Table>
          </div>
        );
      }
    });

    return (
      <>
        <div>{rows}</div>
      </>
    );
  };
  const generatePrintDataShort = () => {
    if (props.itemDetails["short"].length == 0)
      return [<p>No Items under this category</p>];
    let temp_table = [];

    props.itemDetails["short"].forEach((item) => {
      temp_table.push(
        <tr>
          <td>{item.item_id}</td>
          <td>{item.item_disc}</td>
          <td>{Number(item.order_qty) || 0}</td>
          <td>{item.mrp}</td>
        </tr>
      );
    });

    return [
      <Table bordered>
        <thead>
          <tr>
            <th>Item ID</th>
            <th>Description</th>
            <th>Quantity</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>{temp_table}</tbody>
      </Table>,
    ];
  };

  const generatePrintDataNotLoaded = () => {
    if (props.itemDetails["notLoaded"].length == 0)
      return [<p>No Items under this category</p>];
    let temp_table = [];

    props.itemDetails["notLoaded"].forEach((item) => {
      temp_table.push(
        <tr>
          <td>{item.item_id}</td>
          <td>{item.item_disc}</td>
          <td>{Number(item.order_qty) || 0}</td>
          <td>{item.mrp}</td>
        </tr>
      );
    });

    return [
      <Table bordered>
        <thead>
          <tr>
            <th>Item ID</th>
            <th>Description</th>
            <th>Quantity</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>{temp_table}</tbody>
      </Table>,
    ];
  };

  const generateSummary = () => {
    let total = {
      delivered: { total_mrp: 0, total_qty: 0 },
      undelivered: { total_mrp: 0, total_qty: 0 },
      notLoaded: { total_mrp: 0, total_qty: 0 },
      short: { total_mrp: 0, total_qty: 0 },
    };
    props.itemDetails["delivered"].forEach((item) => {
      total["delivered"]["total_mrp"] += Number(item.mrp) || 0;
      total["delivered"]["total_qty"] += Number(item.order_qty) || 0;
    });

    props.itemDetails["undelivered"].forEach((item) => {
      total["undelivered"]["total_mrp"] += Number(item.mrp) || 0;
      total["undelivered"]["total_qty"] += Number(item.order_qty) || 0;
    });

    props.itemDetails["notLoaded"].forEach((item) => {
      total["notLoaded"]["total_mrp"] += Number(item.mrp) || 0;
      total["notLoaded"]["total_qty"] += Number(item.order_qty) || 0;
    });

    props.itemDetails["short"].forEach((item) => {
      total["short"]["total_mrp"] += Number(item.mrp) || 0;
      total["short"]["total_qty"] += Number(item.order_qty) || 0;
    });

    return [
      <div>
        <Table bordered>
          <thead>
            <tr>
              <th>Status</th>
              <th>Total Value</th>
              <th>Total Quantity</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Delivered</td>
              <td>{total.delivered.total_mrp.toFixed(2)}</td>
              <td>{total.delivered.total_qty.toFixed(0)}</td>
            </tr>

            <tr>
              <td>Undelivered</td>
              <td>{total.undelivered.total_mrp.toFixed(2)}</td>
              <td>{total.undelivered.total_qty.toFixed(0)}</td>
            </tr>

            <tr>
              <td>Short</td>
              <td>₹ {total.short.total_mrp.toFixed(2)}</td>
              <td>{total.short.total_qty.toFixed(0)}</td>
            </tr>

            <tr>
              <td>Not Loaded</td>
              <td>₹ {total.notLoaded.total_mrp.toFixed(2)}</td>
              <td>{total.notLoaded.total_qty.toFixed(0)}</td>
            </tr>
          </tbody>
        </Table>
      </div>,
    ];
  };

  const DispatchedVehicleModalPopup = () => {
    let text = `Do you want change as 'Dispatched Vehicle'? You won't be able to edit Delivery Orders for the Route-${props.selectedDriver}.`;
    return (
      <Modal
        {...props}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Dispatched Vehicle
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>{text}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              updateDispatchedVehicleFlag();
              props.onHide();
            }}
          >
            OK
          </Button>
          <Button
            onClick={() => {
              setDispatchedVehiclePopupOpen(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const StockCheckedModalPopup = () => {
    let text = `Do you want change as 'Stock Checked'? You won't be able to edit Delivery Orders for the Route-${props.selectedDriver}.`;
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Stock Checked
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>{text}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              updateStockCheckedFlag();
              props.onHide();
            }}
          >
            OK
          </Button>
          <Button
            onClick={() => {
              setStockCheckedPopupOpen(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ConsolidationModalPopup = () => {
    let text = `Do you want change as 'Completed Tally'? You won't be able to edit Delivery Orders for the Route-${props.selectedDriver}.`;
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Consolidation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>{text}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              updateConsolidationFlag();
              props.onHide();
            }}
          >
            OK
          </Button>
          <Button
            onClick={() => {
              setConsolidationPopupOpen(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const revisedAmt = (itemsInOrders, orderNo) => {
    let undeliveredItemAmt = 0;
    let totalAmtPaid = 0;
    let totalOrderValue;
    Object.keys(itemsInOrders).forEach((orderNumber) => {
      if (
        !orderNumber.includes(
          `${orderNo.split("-")[0]}-${orderNo.split("-")[1]}`
        )
      )
        return;

      if (
        (itemsInOrders[orderNumber].item_delivered_qty &&
          itemsInOrders[orderNumber].mrp) ||
        (itemsInOrders[orderNumber].item_modified_qty &&
          itemsInOrders[orderNumber].mrp)
      ) {
        if (
          itemsInOrders[orderNumber].status
            .toLowerCase()
            .includes(`partially-delivered`) &&
          itemsInOrders[orderNumber].item_delivered_qty
        ) {
          // console.log( itemsInOrders[orderNumber].item_id+" ItemDeliveredQty is present::: "+itemsInOrders[orderNumber].order_qty+" - DeliveredQty:: "+itemsInOrders[orderNumber].item_delivered_qty)
          undeliveredItemAmt +=
            (Number(itemsInOrders[orderNumber].mrp) /
              Number(itemsInOrders[orderNumber].order_qty)) *
            (Number(itemsInOrders[orderNumber].order_qty) -
              Number(itemsInOrders[orderNumber].item_delivered_qty));
        } else if (
          (itemsInOrders[orderNumber].status
            .toLowerCase()
            .includes(`shipped`) ||
            itemsInOrders[orderNumber].status
              .toLowerCase()
              .includes(`partially-delivered`)) &&
          itemsInOrders[orderNumber].item_modified_qty
        ) {
          // console.log(itemsInOrders[orderNumber].item_id+" ItemModifiedQty is present::: "+itemsInOrders[orderNumber].order_qty+" - ModifiedQty:: "+itemsInOrders[orderNumber].item_modified_qty)
          undeliveredItemAmt +=
            (Number(itemsInOrders[orderNumber].mrp) /
              Number(itemsInOrders[orderNumber].order_qty)) *
            (Number(itemsInOrders[orderNumber].order_qty) -
              Number(itemsInOrders[orderNumber].item_modified_qty));
        }
      }

      totalOrderValue = Number(itemsInOrders[orderNumber].total_amt);
      totalAmtPaid = itemsInOrders[orderNumber]["payment_details"]
        ? itemsInOrders[orderNumber]["payment_details"]["total_amount_paid"]
        : 0;
    });
    console.log("UndeliveredAmt:: " + undeliveredItemAmt);
    console.log("TotalOrderValue:: " + totalOrderValue);
    if (!undeliveredItemAmt) {
      undeliveredItemAmt = 0;
    }
    let orderDisc = itemsInOrders[orderNo].order_disc
      ? Number(itemsInOrders[orderNo].order_disc)
      : 0;
    let revisedOrderDisc = itemsInOrders[orderNo].revised_order_disc
      ? Number(itemsInOrders[orderNo].revised_order_disc)
      : orderDisc;

    return totalOrderValue - undeliveredItemAmt;
  };

  const updateConsolidationFlag = () => {
    let driverAllocationPath =
      props.selectDate.getFullYear() +
      "/" +
      (props.selectDate.getMonth() + 1) +
      "/" +
      (props.selectDate.getDate() +
        "-" +
        (props.selectDate.getMonth() + 1) +
        "-" +
        props.selectDate.getFullYear());

    let orderValueCheckerObj = {};
    let issueInExport = false;

    let driver_id = props.selectedDriver;
    let allRouteOrdersData = props.allRouteOrdersData;
    let currRouteOrdersData = allRouteOrdersData[driver_id];
    console.log(currRouteOrdersData);

    let isAllOrdersConsolidated = true;
    Object.keys(currRouteOrdersData).map((key, index) => {
      let status = currRouteOrdersData[key].status;
      //Processed or Shipped or Shipped-Modified
      if (status == "Processed" || status.includes("Shipped")) {
        isAllOrdersConsolidated = false;
      }

      let ordered_amt = currRouteOrdersData[key]["total_amt"]
        ? Number(currRouteOrdersData[key]["total_amt"])
        : 0;
      let delivered_amt = revisedAmt(currRouteOrdersData, key);
      let order_number = currRouteOrdersData[key]["order_number"];
      let credit_amt = currRouteOrdersData[key]["credit_amt"]
        ? Number(currRouteOrdersData[key]["credit_amt"])
        : 0;

      let itemID = currRouteOrdersData[key]["item_id"];
      let mrp = currRouteOrdersData[key]["mrp"]
        ? Number(currRouteOrdersData[key]["mrp"])
        : 0;
      let order_disc = currRouteOrdersData[key]["order_disc"]
        ? Number(currRouteOrdersData[key]["order_disc"])
        : 0;
      let cashback_redeemed = currRouteOrdersData[key]["cashback_redeemed"]
        ? Number(currRouteOrdersData[key]["cashback_redeemed"])
        : 0;

      let srevisedOrderAmt = currRouteOrdersData[key].revised_order_amt
        ? Number(currRouteOrdersData[key].revised_order_amt)
        : Number(currRouteOrdersData[key].total_amt);
      let sorderDisc = currRouteOrdersData[key].order_disc
        ? Number(currRouteOrdersData[key].order_disc)
        : 0;
      let srevisedOrderDisc = currRouteOrdersData[key].revised_order_disc
        ? Number(currRouteOrdersData[key].revised_order_disc)
        : sorderDisc;
      let srevisedOrderAmtWithDisc = (
        srevisedOrderAmt +
        sorderDisc -
        srevisedOrderDisc
      ).toFixed(2);

      let orderele = {};
      orderele["order_number"] = order_number;
      orderele["route"] = driver_id;
      orderele["calc_order_amount"] = mrp;
      if (orderValueCheckerObj[order_number]) {
        let existingAmt = orderValueCheckerObj[order_number][
          "calc_order_amount"
        ]
          ? Number(orderValueCheckerObj[order_number]["calc_order_amount"])
          : 0;
        orderele["calc_order_amount"] = existingAmt + mrp;
      }
      orderele["order_disc"] = order_disc;
      orderele["cashback_redeemed"] = cashback_redeemed;
      orderele["ordered_amt"] = ordered_amt;
      orderele["status"] = status;
      orderValueCheckerObj[order_number] = orderele;

      let finalPaidAmt = 0;
      if (currRouteOrdersData[key]["payment_details"]) {
        finalPaidAmt = currRouteOrdersData[key]["payment_details"][
          "total_amount_paid"
        ]
          ? Number(
              currRouteOrdersData[key]["payment_details"]["total_amount_paid"]
            )
          : 0;
      }
      let paidAmtDiff = srevisedOrderAmtWithDisc - finalPaidAmt;

      if (
        (paidAmtDiff > 10 || paidAmtDiff < -10) &&
        currRouteOrdersData[key].status.toLowerCase().includes("delivered")
      ) {
        toast(
          `Discrepancy in the payments. Can't export order ${order_number} | ${driver_id}`,
          { type: toast.TYPE.ERROR }
        );
        issueInExport = true;
        return;
      }

      // if (currRouteOrdersData[key].hasOwnProperty("payment_details") && currRouteOrdersData[key]['status'].includes("Delivered")){
      //   let paymentAmountDiff=Number(delivered_amt) - currRouteOrdersData[key]["payment_details"]["total_amount_paid"];
      //   if(Number(delivered_amt) - currRouteOrdersData[key]["payment_details"]["total_amount_paid"] > 100) {
      //     toast(`Discrepancy in the payments. Can't export order ${order_number} | ${driver_id}`, { type: toast.TYPE.ERROR })
      //     issueInExport = true;
      //     return;
      //   }
      // }
    });

    console.log(orderValueCheckerObj);

    Object.keys(orderValueCheckerObj).map((key, index) => {
      let order_disc = orderValueCheckerObj[key].order_disc;
      let cashback_redeemed = orderValueCheckerObj[key].cashback_redeemed;
      let order_number = orderValueCheckerObj[key].order_number;
      let sumTotalAmt = orderValueCheckerObj[key].calc_order_amount;
      let net_order_amt = orderValueCheckerObj[key].ordered_amt;
      let route = orderValueCheckerObj[key].route;
      let status = orderValueCheckerObj[key].status
        ? orderValueCheckerObj[key].status
        : "";

      let calcOrderValue = sumTotalAmt - order_disc - cashback_redeemed;

      let orderValueDiff = calcOrderValue - net_order_amt;
      if (
        net_order_amt != calcOrderValue &&
        (!order_number.includes("R") ||
          (status.includes("Delivered") && order_number.includes("R"))) &&
        orderValueCheckerObj[key].route != "9999" &&
        (orderValueDiff > 10 || orderValueDiff < -10)
      ) {
        toast(
          `${route} : ${order_number} has Order Value discrepancy with difference of ${orderValueDiff.toFixed(
            2
          )}`,
          { type: toast.TYPE.ERROR }
        );
        issueInExport = true;
        return;
      }
    });

    if (issueInExport == true) {
      return;
    }

    fire
      .database()
      .ref("Driver_Allocation_History/" + driverAllocationPath)
      .once("value", (snapShot) => {
        console.log(snapShot.val());

        if (snapShot.val()) {
          const driverInfo = snapShot.val();
          let isEndingReadingPresent = false;

          Object.keys(driverInfo).forEach((driverPhn) => {
            let routeKey = driverInfo[driverPhn].current_Route_Id;

            if (
              driverInfo[driverPhn].current_Route_Id === props.selectedDriver &&
              routeKey != "8888" &&
              routeKey != "9999" &&
              props.getAccessibleRoutes[routeKey] &&
              driverInfo[driverPhn].hasOwnProperty("ending_odometer_reading")
            ) {
              fire
                .database()
                .ref(
                  `Driver_Allocation_History/${driverAllocationPath}/${driverPhn}`
                )
                .update({
                  completed_conslidation: true,
                  completed_conslidation_updatedAt: new Date().getTime(),
                });
              setConsolidationSwitchChecked(true);
            }

            if (
              driverInfo[driverPhn].hasOwnProperty("ending_odometer_reading")
            ) {
              isEndingReadingPresent = true;
            }
          });

          if (!isEndingReadingPresent) {
            toast(`Ending vehicle reading not present`, {
              type: toast.TYPE.ERROR,
            });
            return;
          }

          toast(`Updated the order consolidation flag status`, {
            type: toast.TYPE.SUCCESS,
          });
          sessionStorage.setItem("vehicleReloaded", props.selectedDriver);
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        }
      });
  };

  const markVehicleNotReturned = (isVehicleNotReturned) => {
    if (isVehicleNotReturned) {
      let driverAllocationPath =
        props.selectDate.getFullYear() +
        "/" +
        (props.selectDate.getMonth() + 1) +
        "/" +
        (props.selectDate.getDate() +
          "-" +
          (props.selectDate.getMonth() + 1) +
          "-" +
          props.selectDate.getFullYear());

      fire
        .database()
        .ref("Driver_Allocation_History/" + driverAllocationPath)
        .once("value", (snapShot) => {
          console.log(snapShot.val());

          if (snapShot.val()) {
            const driverInfo = snapShot.val();
            Object.keys(driverInfo).forEach((driverPhn) => {
              let routeKey = driverInfo[driverPhn].current_Route_Id;
              if (
                driverInfo[driverPhn].current_Route_Id ===
                  props.selectedDriver &&
                routeKey != "8888" &&
                routeKey != "9999" &&
                props.getAccessibleRoutes[routeKey]
              ) {
                fire
                  .database()
                  .ref(
                    `Driver_Allocation_History/${driverAllocationPath}/${driverPhn}`
                  )
                  .update({ vehicle_not_returned: true });
                setVehicleNotReturned(true);
              }
            });

            toast(`Updated the dispatched vehicle flag status`, {
              type: toast.TYPE.SUCCESS,
            });
          }
        });
    }
  };

  const updateDispatchedVehicleFlag = () => {
    let driverAllocationPath =
      props.selectDate.getFullYear() +
      "/" +
      (props.selectDate.getMonth() + 1) +
      "/" +
      (props.selectDate.getDate() +
        "-" +
        (props.selectDate.getMonth() + 1) +
        "-" +
        props.selectDate.getFullYear());

    fire
      .database()
      .ref("Driver_Allocation_History/" + driverAllocationPath)
      .once("value", (snapShot) => {
        console.log(snapShot.val());

        if (snapShot.val()) {
          const driverInfo = snapShot.val();
          Object.keys(driverInfo).forEach((driverPhn) => {
            let routeKey = driverInfo[driverPhn].current_Route_Id;
            if (
              driverInfo[driverPhn].current_Route_Id === props.selectedDriver &&
              routeKey != "8888" &&
              routeKey != "9999" &&
              props.getAccessibleRoutes[routeKey]
            ) {
              fire
                .database()
                .ref(
                  `Driver_Allocation_History/${driverAllocationPath}/${driverPhn}`
                )
                .update({
                  dispatched_vehicle: true,
                  dispatched_vehicle_updatedAt: new Date().getTime(),
                });
              setDispatchedVehicleSwitchChecked(true);
            }
          });

          toast(`Updated the dispatched vehicle flag status`, {
            type: toast.TYPE.SUCCESS,
          });
          sessionStorage.setItem("vehicleReloaded", props.selectedDriver);
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        }
      });
  };

  const updateStockCheckedFlag = () => {
    let driverAllocationPath =
      props.selectDate.getFullYear() +
      "/" +
      (props.selectDate.getMonth() + 1) +
      "/" +
      (props.selectDate.getDate() +
        "-" +
        (props.selectDate.getMonth() + 1) +
        "-" +
        props.selectDate.getFullYear());

    fire
      .database()
      .ref("Driver_Allocation_History/" + driverAllocationPath)
      .once("value", (snapShot) => {
        console.log(snapShot.val());

        if (snapShot.val()) {
          const driverInfo = snapShot.val();
          Object.keys(driverInfo).forEach((driverPhn) => {
            let routeKey = driverInfo[driverPhn].current_Route_Id;
            if (
              driverInfo[driverPhn].current_Route_Id === props.selectedDriver &&
              routeKey != "8888" &&
              routeKey != "9999" &&
              props.getAccessibleRoutes[routeKey]
            ) {
              fire
                .database()
                .ref(
                  `Driver_Allocation_History/${driverAllocationPath}/${driverPhn}`
                )
                .update({
                  stock_checked: true,
                  stock_checked_updatedAt: new Date().getTime().toString(),
                });
              setStockCheckedSwitchChecked(true);
            }
          });

          toast(`Updated the stock checked flag status`, {
            type: toast.TYPE.SUCCESS,
          });
          sessionStorage.setItem("vehicleReloaded", props.selectedDriver);
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        }
      });
  };

  return (
    <>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Item Details Summary
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.selectedDriver != "8888" &&
            props.selectedDriver != "9999" &&
            (validateIsAdminUser(
              JSON.parse(sessionStorage.getItem(`user`))[0].email
            ) ||
              validateIsOperationUser(
                JSON.parse(sessionStorage.getItem(`user`))[0].email
              )) && (
              <Table size="sm" style={{ width: 250 }} borderless>
                <tr>
                  <td>Dispatched Vehicle?</td>
                  <td>
                    <Switch
                      onChange={(val) => {
                        if (
                          !validateIsAdminUser(
                            JSON.parse(sessionStorage.getItem(`user`))[0].email
                          )
                        ) {
                          return toast(
                            "Only admin user can modify dispatched status",
                            { type: toast.TYPE.ERROR }
                          );
                        }
                        setDispatchedVehiclePopupOpen(val);
                      }}
                      checked={dispatchedVehicleSwitchChecked}
                    />
                  </td>
                </tr>
              </Table>
            )}

          {(validateIsAdminUser(
            JSON.parse(sessionStorage.getItem(`user`))[0].email
          ) ||
            validateIsBillingUser(
              JSON.parse(sessionStorage.getItem(`user`))[0].email
            )) && (
            <Table size="sm" style={{ width: 250 }} borderless>
              <tr>
                <td>Completed Deliveries?</td>
                <td>
                  <Switch
                    onChange={(val) => {
                      setCompletedDelivery(val);
                    }}
                    disabled={true}
                    checked={completedDelivery}
                  />
                </td>
              </tr>
            </Table>
          )}

          {(validateIsAdminUser(
            JSON.parse(sessionStorage.getItem(`user`))[0].email
          ) ||
            (completedDeliveryFlag &&
              props.selectedDriver != "8888" &&
              props.selectedDriver != "9999" &&
              (validateIsAdminUser(
                JSON.parse(sessionStorage.getItem(`user`))[0].email
              ) ||
                validateIsOperationUser(
                  JSON.parse(sessionStorage.getItem(`user`))[0].email
                )))) && (
            <Table size="sm" style={{ width: 250 }} borderless>
              <tr>
                <td>Stock Checked?</td>
                <td>
                  <Switch
                    onChange={(val) => {
                      if (
                        !validateIsAdminUser(
                          JSON.parse(sessionStorage.getItem(`user`))[0].email
                        )
                      ) {
                        return toast(
                          "Only admin user can modify stock checked status",
                          { type: toast.TYPE.ERROR }
                        );
                      }
                      setStockCheckedPopupOpen(val);
                    }}
                    checked={stockCheckedSwitchChecked}
                  />
                </td>
              </tr>
            </Table>
          )}

          {(validateIsAdminUser(
            JSON.parse(sessionStorage.getItem(`user`))[0].email
          ) ||
            (consolidatedFlag &&
              props.selectedDriver != "8888" &&
              props.selectedDriver != "9999" &&
              validateIsBillingUser(
                JSON.parse(sessionStorage.getItem(`user`))[0].email
              ))) && (
            <Table size="sm" style={{ width: 250 }} borderless>
              <tr>
                <td>Completed Tally?</td>
                <td>
                  <Switch
                    onChange={(val) => {
                      if (
                        !validateIsAdminUser(
                          JSON.parse(sessionStorage.getItem(`user`))[0].email
                        )
                      ) {
                        if (!completedDeliveryFlag) {
                          return toast(
                            "Cannot mark completed tally without delivery complete flag. ",
                            { type: toast.TYPE.ERROR }
                          );
                        } else if (!stockCheckedFlag) {
                          return toast(
                            "Cannot mark completed tally without stock checked flag. ",
                            { type: toast.TYPE.ERROR }
                          );
                        }
                      }

                      setConsolidationPopupOpen(val);
                    }}
                    checked={consolidationSwitchChecked}
                  />
                </td>
              </tr>
            </Table>
          )}

          {props.selectedDriver != "8888" &&
            props.selectedDriver != "9999" &&
            validateIsAdminUser(
              JSON.parse(sessionStorage.getItem(`user`))[0].email
            ) && (
              <Table size="sm" style={{ width: 250 }} borderless>
                <tr>
                  <td>Vehicle Not Returned?</td>
                  <td>
                    <Switch
                      onChange={(val) => {
                        setVehicleNotReturned(val);
                        markVehicleNotReturned(val);
                      }}
                      checked={vehicleNotReturned}
                    />
                  </td>
                </tr>
              </Table>
            )}

          {completedDelivery && (
            <ReactToPrint
              trigger={() => (
                <IconButton style={{ float: "right" }}>
                  <FontAwesomeIcon size="lg" icon={faPrint} />
                </IconButton>
              )}
              content={() => {
                return printAllStatusRef.current;
              }}
              onAfterPrint={() => {
                props.parentAlert("Print Successful", "success");
              }}
              onPrintError={() => {
                props.parentAlert("Print Unsuccessful", "error");
              }}
              onBeforePrint={() => {
                props.parentAlert("Print Started", "info");
              }}
            />
          )}

          <Tabs
            defaultActiveKey={getCurrentSelected}
            id="uncontrolled-tab-example"
            onSelect={(e) => {
              setCurrentSelected(e);
            }}
          >
            <Tab eventKey="loaded" title="Loaded">
              {RenderItemsTable("loaded")}
              <div style={{ display: "none" }}>{generatePrintData()}</div>
            </Tab>

            {!isFreshOrder ? (
              <Tab eventKey="notLoaded" title="Not Loaded">
                {RenderItemsTable("notLoaded")}
                <div style={{ display: "none" }}>{generatePrintData()}</div>
              </Tab>
            ) : null}

            {!isFreshOrder ? (
              <Tab eventKey="replacements" title="Replacements">
                {RenderItemsTable("replacements")}
                <div style={{ display: "none" }}>{generatePrintData()}</div>
              </Tab>
            ) : null}

            <Tab eventKey="delivered" title="Delivered">
              {RenderItemsTable("Delivered")}
              <div style={{ display: "none" }}>{generatePrintData()}</div>
            </Tab>
            <Tab eventKey="undelivered" title="Undelivered">
              {RenderItemsTable("Undelivered")}
              {RenderVarietyBreakdown()}
              {/* {RenderUndeliveredImage()} */}
              {stockReturnImages.length > 0 && (
                <tr>
                  <td colSpan="2">
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {stockReturnImages.map((imageNode, index) => (
                        <div key={index} style={{ margin: '10px', textAlign: 'center' }}>
                          <img
                            src={imageNode.url}
                            alt={`Stock Return ${index}`}
                            style={{ maxWidth: '100px', cursor: 'pointer' }}
                            onClick={() => {
                              setSelectedImage(imageNode.url);
                              setFullViewShow(true);
                            }}
                          />
                          <div>{imageNode.type.replace('stock_return_', '').toUpperCase()}</div>
                        </div>
                      ))}
                    </div>
                  </td>
                </tr>
              )}
              <div style={{ display: "none" }}>{generatePrintData()}</div>
            </Tab>
            {!isFreshOrder ? (
              <Tab eventKey="short" title="Short">
                {RenderItemsTable("Short")}
                <div style={{ display: "none" }}>{generatePrintData()}</div>
              </Tab>
            ) : null}

            <Tab eventKey="summary" title="Summary">
              {Summary()}
              <div style={{ display: "none" }}>{generatePrintData()}</div>
            </Tab>
          </Tabs>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>

      {selectedImage && (
        <FullViewImageModal
          show={fullViewShow}
          onHide={() => setFullViewShow(false)}
          imageUrl={selectedImage}
        />
      )}
      {consolidationPopupOpen == true ? <ConsolidationModalPopup /> : null}
      {dispatchedVehiclePopupOpen == true ? (
        <DispatchedVehicleModalPopup />
      ) : null}
      {stockCheckedPopupOpen == true ? <StockCheckedModalPopup /> : null}
    </>
  );
}

function App(props) {
  const [modalShow, setModalShow] = React.useState(false);
  let showModalStatus = props.getItemDetailsStatus;
  useEffect(() => {
    setModalShow(showModalStatus);
  }, [showModalStatus]);
  return (
    <MyVerticallyCenteredModal
      show={modalShow}
      onHide={() => {
        props.setItemDetailsStatus(false);
        setModalShow(false);
      }}
      itemDetails={props.getItemDetails}
      parentAlert={props.generateAlert}
      selectDate={props.getSelectedDate}
      selectedDriver={props.getSelectedDriver}
      allRouteOrdersData={props.allRouteOrdersData}
      getOrderVarietyMap={props.getOrderVarietyMap}
      getAccessibleRoutes={props.getAccessibleRoutes}
      freshTotalLoadedCrateCount={props.freshTotalLoadedCrateCount}
    />
  );
}

function FullViewImageModal({ show, onHide, imageUrl }) {
  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Full View Image</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <img src={imageUrl} alt="Full View" style={{ maxWidth: '100%' }} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default App;
