import React, { useState, useEffect, useRef } from 'react'
import { Table, Form, Alert, Badge } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faTimes, faPlusCircle, faCircle, faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import szlogo from '../../assests/images/sz_logo.png';
import superprimelogo from '../../assests/images/superprimelogo.png';
import footer from '../../assests/images/footer.png';
import { support_portal_url, orderingApiUrl } from "../../Configs/mysqlconfig";
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import ReactToPrint from 'react-to-print';
import Backdrop from '../backdrop/backdrop';
import { toast } from 'react-toastify';
import database from '../../Configs/firebase'


function formatDate(selecteddate){
    let dt=new Date(selecteddate);
    let monthNames =["Jan","Feb","Mar","Apr",
                        "May","Jun","Jul","Aug",
                        "Sep", "Oct","Nov","Dec"];

      let day = dt.getDate();

      let monthIndex = dt.getMonth();
      let monthName = monthNames[monthIndex];

      let year = dt.getFullYear().toString().substr(2,2);

      //temp solution to pre date invoice dates
      let formattedDate = `${day}-${monthName}-${year}`;
      return formattedDate;
  }


function formatGstNumber(gst_number){
  return `GSTIN : ${gst_number}`;
}

function formatFssai(fssai){
  return `FSSAI : ${fssai}`;
}

function getRetailerIdFromOrderNumber(orderNumber) {
  if(!orderNumber) {
    return "";
  }

  if(orderNumber.includes("SC")) {
    let retailerStr = orderNumber.split("/")[0];
    return retailerStr.substr(retailerStr.length - 5);;
  }else if(orderNumber.includes("-")) {
    return orderNumber.split("-")[0];
  }else{
    return orderNumber.split("/")[0];
  }
}

const OrdersDetails = (props) => {

    const [itemDetails, setItemDetails] = useState()
    const [updateditemDetails, setUpdatedItemDetails] = useState()
    const [orderdetails, setOrderdetails] = useState("")
    const [getBackDrop, setBackDrop] = useState(false)
    const [totalOrderQty, setTotalOrderQty] = useState("")
    const [amtExclGst, setAmtExclGst] = useState("")
    const [CGSTAmt, setCGSTAmt] = useState("")
    const [SGSTAmt, setSGSTAmt] = useState("")
    const [phoneText,setPhoneText] = useState("")
    const [amtInclGst, setAmtInclGst] = useState("")
    const [itemAmountErrorMessage,setItemAmountErrorMessage] = useState([]);
    const [orderAmountErrorMessage,setOrderAmountErrorMessage] = useState([]);
    const [getPrimeUser, setPrimeUser] = useState(false);


    const dataToPrint = useRef()
    const itemWeightRef = useRef([])
    const params = new URLSearchParams(props.location.search);
    const params_order_no = params.get('order_number');


    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_CENTER,
    });


    let invoiceDate = new Date()
    let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    invoiceDate = `${invoiceDate.getDate()<10?`0${invoiceDate.getDate()}`:invoiceDate.getDate()}-${monthNames[invoiceDate.getMonth()]}-${invoiceDate.getFullYear().toString().substr(2,2)}`

    useEffect(() => {

       if (!sessionStorage.getItem("orderIdToPrint") && ! params_order_no)
            return;
        const orderNumberSelected = sessionStorage.getItem("orderIdToPrint")?sessionStorage.getItem("orderIdToPrint"):params_order_no;
        setBackDrop(true)
        let retailerID = getRetailerIdFromOrderNumber(orderNumberSelected);

        Promise.all([
          fetch(`${support_portal_url}/api/ordersdetails?ordernumber=${orderNumberSelected}&token=${sessionStorage.getItem("apiToken")}`).then(res => res.json()),
          fetch(`${orderingApiUrl}/is-prime-user?token=eyJhbGciOiJIUzI1NiIsInR5cCI6Ik&retailer_id=${retailerID}`).then(response=>response.json()),
        ]).then(([data, userData]) => {
          let isPrimeUser = userData.data=="Y"?true:false;

          if(data['success']==1){
            console.log(data.data);
            setBackDrop(false); setItemDetails(data.data.items); setOrderdetails(data.data); setPrimeUser(isPrimeUser);
            let orderdata=data.data;
            let itemdata=data.data.items;
            console.log(orderdata);
            console.log("orderdata");

            // Conditions for discrepancy.
            //  if total_amt != (dealer_price * order_qty) + transport_charge + apmc_charge + gst_charge.
            //  if net_order_amt != sum(total_amt) - order_disc - cashback_redeemed.

            //check for discrepancies
            let net_order_amt=orderdata['net_order_amt']?Number(orderdata['net_order_amt']):0;
            let order_disc=orderdata['order_disc']?Number(orderdata['order_disc']):0;
            let cashback_redeemed=orderdata['cashback_redeemed']?Number(orderdata['cashback_redeemed']):0;
            let sumTotalAmt=0;

            let orderAmountErrors=[];
            let itemAmountErrors=[];

            for(let i=0;i<itemdata.length;i++){
              let item_disc=itemdata[i]['item_disc'];
              let total_amt=itemdata[i]['total_amt']?Number(itemdata[i]['total_amt']):0;
              let dealer_price=itemdata[i]['dealer_price']?Number(itemdata[i]['dealer_price']):0;
              let order_qty=itemdata[i]['order_qty']?Number(itemdata[i]['order_qty']):0;
              let transport_charge=itemdata[i]['transport_charge']?Number(itemdata[i]['transport_charge']):0;
              let apmc_charge=itemdata[i]['apmc_charge']?Number(itemdata[i]['apmc_charge']):0;
              let gst_charge=itemdata[i]['gst_charge']?Number(itemdata[i]['gst_charge']):0;

              let calcItemValue=(dealer_price * order_qty) + transport_charge + apmc_charge + gst_charge;
              calcItemValue=calcItemValue.toFixed(2);
              calcItemValue=Number(calcItemValue);

              let itemValueDiff=calcItemValue-total_amt;
              if(calcItemValue!=total_amt && (itemValueDiff>10 || itemValueDiff<-10)){
                itemAmountErrors.push(`${item_disc} has Item Value discrepancy with difference of ${itemValueDiff.toFixed(2)}`)
              }

              sumTotalAmt=sumTotalAmt+total_amt;
            }

            let calcOrderValue=sumTotalAmt-order_disc-cashback_redeemed;
            calcOrderValue=calcOrderValue.toFixed(2);
            calcOrderValue=Number(calcOrderValue);

            let orderValueDiff=calcOrderValue-net_order_amt;
            if(net_order_amt!=calcOrderValue && (orderValueDiff>10 || orderValueDiff<-10)){
              orderAmountErrors.push(`Order value discrepancy with difference of ${orderValueDiff.toFixed(2)}`);
            }


            //process bill
            let phonestr=""+orderdata.phone;
            if(orderdata.secondary_number && orderdata.secondary_number!="null"){
              phonestr+=","+orderdata.secondary_number;
            }

            setItemAmountErrorMessage(itemAmountErrors);
            setOrderAmountErrorMessage(orderAmountErrors);
            setPhoneText(phonestr);
          }else{
            let errMessage=data['message']?data['message']:"";
            alert(errMessage);
            if(data['status']==401 || data['status']==403){
              sessionStorage.clear();
              window.location.href="/"
            }
          }

        }).catch(err => alert(err))

    }, [])


    useEffect(() => {
        if (itemDetails) {
            //getting the sum values of items in an order
            let sumOfItems = 0; let sumAmtInclGst = 0; let sumGSTAmt = 0;
            var result = [];
            var itemIds = [];
            itemDetails.forEach(item => {
                if (!itemIds.includes(item.item_id)) {
                  result.push(item);
                  itemIds.push(item.item_id);
                  sumAmtInclGst += Number(item.total_amt)
                  sumGSTAmt += Number(isNumber(item.gst_charge))
                  sumOfItems++
                }
            })
            setUpdatedItemDetails(result)
            setTotalOrderQty(parseInt(sumOfItems))
            setAmtExclGst((sumAmtInclGst - sumGSTAmt))
            setCGSTAmt(sumGSTAmt)
            setSGSTAmt(sumGSTAmt/2)
            setAmtInclGst(sumAmtInclGst.toFixed(2))
        }
    }, [itemDetails])

    const getItemsWeight = (indItemWeight, itemDesc, quantityOrdered) => {

        if (!indItemWeight)
            return 1
        let itemWeight = Number(indItemWeight.replace(/\D/g, ''));
        if (indItemWeight.includes("gm") || indItemWeight.includes("ml")) {
            itemWeight = itemWeight / 1000;
        }

        if ((indItemWeight.includes("1 pc") || indItemWeight.includes("1 Pc")) && itemDesc.includes("gm")) {
            let itemWtExtract = itemDesc.split("gm")
            let itemWtExtract2 = itemWtExtract[0].split("-")
            itemWeight = parseFloat(itemWtExtract2[itemWtExtract2.length-1].replace(/[^0-9]/g,''))/1000;
        }
        if(itemWeight <=0){
            itemWeight = 1;
        }
        indItemWeight = itemWeight * Number(quantityOrdered)

        return `${indItemWeight.toFixed(2)}`
    }

    const isAlreadyPaid = (payment_mode) => {
        return payment_mode && (payment_mode.includes('Credit') || payment_mode.includes('RazorPay'))
    }

    const isNumber = (item) => {
        return isNaN(Number(item))?"0":Number(item).toFixed(2)
    }

    const convertCity = (cityVal) => {
    //   cityVal=cityVal.toLowerCase();
    //   if(cityVal=="pune"){
    //     return "Pune";
    //   } else if(cityVal=="surat"){
    //     return "Surat";
    //   } else if(cityVal=="nashik"){
    //     return "Nashik";
    //   } else {
    //     return "Mumbai";
    //   }
    return cityVal;
    }

    let isMHGSTNumber = true;

    if(orderdetails.gst_number){
      if(!orderdetails.gst_number.startsWith('27')){
        isMHGSTNumber = false
      }
    }


    return (
        <>
        
            <div style={{ textAlign: "center", width: "1000px", margin: "auto", border: "2px solid black" }}>
                <ReactToPrint
                    trigger={() => <a href="#" style={{ 'float': 'right' }} ><FontAwesomeIcon size="3x" icon={faPrint} /></a>}
                    content={() => { return dataToPrint.current }}
                    pageStyle={{margin:"50px",border:"2px"}}

                />

                {itemAmountErrorMessage.map((itemError, key) => {
                    return (
                        <div style={{ display: "flex", backgroundColor: "#FF6347", padding: "10px", margin: "15px", borderRadius: "5px" }}>{itemError}</div>
                    )
                })}

                {orderAmountErrorMessage.map((orderError, key) => {
                    return (
                        <div style={{ display: "flex", backgroundColor: "#FF6347", padding: "10px", margin: "15px", borderRadius: "5px" }}>{orderError}</div>
                    )
                })}

                <div ref={dataToPrint}>
                    <Table><tr>
                        {orderdetails.city!="Ali" && <td><img src={szlogo} alt={"superzopLogo"} style={{  width: "250px", height: "70px" }} /></td>}
                        {getPrimeUser && <td><img src={superprimelogo} alt={"superprimeLogo"} style={{  width: "150px", height: "80px" }} /></td>}
                        {!((orderdetails.city) && (orderdetails.city).includes('Goa'))?(
                          <td style={{ width: "400px", textAlign: "left" }}><b>Retranz Infolabs Private Limited</b><br />
                          Godown D1, Indian Corporation Compound,<br />Diva - Anjur Village, Bhiwandi – 421302<br />
                  Phone : 9901044177<br />
                  Email : support@superzop.com<br />
                  GSTIN : 27AAHCR2573F1ZA <br />
                  FSSAI : 11522998000504</td>
                        ):(<td style={{ width: "400px", textAlign: "left" }}><b>Retranz Infolabs Private Limited</b><br />
                        3/7693, Cartybon Factory,<br /> Behind Residency Bunglow, Murida Fatorda, Margao, Goa - 403602<br />
                Phone : 9901044177<br />
                Email : support@superzop.com<br />
                GSTIN : 30AAHCR2573F1ZN <br />
                FSSAI : 11520030000203</td>)}
                    </tr>
                    </Table>
                    <div><div style={{ borderTop: "2px solid black", borderBottom: "2px solid black", textAlign: "center" }}>
                        <b style={{ fontSize: "20px" }}>RETAILER TAX INVOICE</b>
                    </div>
                        <Table>
                            <tr>
                                <td style={{ textAlign: "left" }}><div>
                                    <b>{orderdetails.shop_name}</b><br />
                                    {orderdetails.address1}<br />
                                    {orderdetails.address2}<br />
                                    {orderdetails.area}<br />
                                    {convertCity(orderdetails.city || "")}-{orderdetails.pincode}<br />
                                    ✆{phoneText}<br />
                                    {orderdetails.gst_number?formatGstNumber(orderdetails.gst_number):null}<br />
                                    {orderdetails.fssai_document_number?formatFssai(orderdetails.fssai_document_number):null}<br />

                                </div></td>
                                <td style={{ width: "400px", textAlign: "left" }}><div><Table borderless style={{ cellSpacing: "0px" }}>
                                    <tr style={{ cellSpacing: "0px", cellPadding: "0px" }}>
                                        <td style={{ padding: "0px" }}>Retailer ID</td>
                                        <td style={{ textAlign: "right", padding: "0px", }}>{orderdetails.retailer_id}</td>
                                    </tr>
                                    <tr style={{ cellSpacing: "0px", cellPadding: "0px" }}>
                                        <td style={{ padding: "0px" }}>Invoice Number</td>
                                        <td style={{ textAlign: "right", padding: "0px", }}>{orderdetails.order_number}</td>
                                    </tr>
                                    <tr style={{ cellSpacing: "0px" }}>
                                        <td style={{ padding: "0px" }}>Invoice Date</td>
                                        <td style={{ textAlign: "right", padding: "0px", }}>{orderdetails.processed_date?formatDate(orderdetails.processed_date):formatDate(invoiceDate)}</td>
                                    </tr>
                                    <tr style={{ cellSpacing: "0px" }}>
                                        <td style={{ padding: "0px" }}>Order Date</td>
                                        <td style={{ textAlign: "right", padding: "0px", }}>{orderdetails.order_date?formatDate(orderdetails.order_date):""}</td>
                                    </tr>
                                    <tr style={{ cellSpacing: "0px" }}>
                                        <td style={{ padding: "0px" }}>No of Items</td>
                                        <td style={{ textAlign: "right", padding: "0px", }}>{totalOrderQty}</td>
                                    </tr>
                                    {orderdetails.numbags!=0 && <tr style={{ cellSpacing: "0px" }}>
                                        <td style={{ padding: "0px" }}>No of Loose Bags</td>
                                        <td style={{ textAlign: "right", padding: "0px", }}>{orderdetails.numbags!=0?orderdetails.numbags:""}</td>
                                    </tr>}
                                    {orderdetails.bagdetails && orderdetails.bagdetails!='null' && <tr style={{ cellSpacing: "0px" }}>
                                        <td style={{ padding: "0px" }}>Loose Bag Id</td>
                                        <td style={{ textAlign: "right", padding: "0px", }}>{orderdetails.bagdetails && orderdetails.bagdetails!='null'?orderdetails.bagdetails:""}</td>
                                    </tr>}
                                    <tr style={{ cellSpacing: "0px" }}>
                                        <td style={{ padding: "0px" }}>Sales Agent</td>
                                        <td style={{ textAlign: "right", padding: "0px", }}>{orderdetails.agent_id}</td>
                                    </tr>
                                </Table>
                                </div>
                                </td>
                            </tr>
                        </Table>
                    </div>
                    <Table>
                        <thead style={{ borderTop: "2px solid black", borderBottom: "2px solid black" }}>
                            <tr>
                                <td style={{paddingRight:"10px"}}><b>Sl No</b></td>
                                <td style={{paddingRight:"10px"}}><b>Item description</b></td>
                                <td style={{paddingRight:"10px"}}><b>HSN Code</b></td>
                                <td style={{paddingRight:"10px"}}><b>Qty/No</b></td>
                                <td style={{paddingRight:"10px"}}><b>Total Weight</b></td>
                                <td style={{paddingRight:"10px"}}><b>Dealer Price (per pack)</b></td>
                                <td style={{paddingRight:"10px"}}><b>Amount (Rs.)</b></td>
                                {!isMHGSTNumber && <td style={{paddingRight:"10px"}}><b>IGST Amount</b></td>}
                                {isMHGSTNumber && <td style={{paddingRight:"10px"}}><b>CGST Amount</b></td>}
                                {isMHGSTNumber && <td style={{paddingRight:"10px"}}><b>SGST Amount</b></td>}
                                <td style={{paddingRight:"10px"}}><b>Total Amount</b></td>
                            </tr>
                        </thead>
                        <tbody style={{padding:"0"}}>
                            {(updateditemDetails) ? Object.keys(updateditemDetails).map((items, index) => (
                                <tr >
                                    <td style={{padding:"2px",border:"none"}}>{index + 1}</td>
                                    <td style={{ width: "500px", padding: "2px", textAlign: "left", border: "none", fontSize: "16px" }}>{updateditemDetails[items].item_disc}</td>
                                    <td style={{padding:"2px",border:"none"}}>{updateditemDetails[items].hsn_code}</td>
                                    <td style={{padding:"2px",border:"none"}}>{updateditemDetails[items].order_qty}</td>
                                    <td style={{padding:"2px",border:"none"}} ref={(value => itemWeightRef.current[index] = value)}> {getItemsWeight(updateditemDetails[items].weight,updateditemDetails[items].item_disc, updateditemDetails[items].order_qty)}</td>
                                    <td style={{ padding: "2px", border: "none" }}>{isNumber((Number(updateditemDetails[items].total_amt) -  Number(updateditemDetails[items].gst_charge))/Number(updateditemDetails[items].order_qty))}</td>
                                    <td style={{ padding: "2px", border: "none" }}>{isNumber(Number(updateditemDetails[items].total_amt) - Number(updateditemDetails[items].gst_charge))}</td>
                                    {!isMHGSTNumber && <td style={{ padding: "2px", border: "none" }}>{isNumber(updateditemDetails[items].gst_charge)}</td>}
                                    {isMHGSTNumber && <td style={{ padding: "2px", border: "none" }}>{isNumber(updateditemDetails[items].gst_charge/2)}</td>}
                                    {isMHGSTNumber && <td style={{ padding: "2px", border: "none" }}>{isNumber(updateditemDetails[items].gst_charge/2)}</td>}
                                    <td style={{ padding: "2px", border: "none" }}>{isNumber(updateditemDetails[items].total_amt)}</td>
                                </tr>
                            )) : ""}
                        </tbody>
                    </Table>
                    <div style={{ borderTop: "1px solid black" }}>
                        <Table ><tr>
                            <td style={{ textAlign: "left" }}><div>
                                <br /><br /><br />
                                <div style={{textAlign:"center"}}><i>I/We hereby certify that the goods mentioned in the invoice are warranted
                                to be of the same nature<br />
                                Certified that the particulars given above are true and correct</i><br /><br />
                                <b><i>Goods Once Sold Will Not Be Taken Back.</i></b><br />
                                Subject to Mumbai Jurisdiction<br />
                                <i><b>E&OE</b></i></div><br />
                                <div style={{textAlign:"center"}}>{(orderdetails["order_payment_mode"]=='Credit')?<h1><Badge pill variant="primary">Paid - SuperKredit</Badge></h1>:(orderdetails["order_payment_mode"]=='RazorPay')?<h1><Badge pill variant="primary">Paid - RazorPay</Badge></h1>:<h1><Badge pill variant="primary">Cash Only</Badge></h1>}</div>
                            </div></td>
                            <td style={{ width: "400px", textAlign: "left", cellSpacing: "0px" }}><div>
                                <Table bordered style={{ border: "2px solid black" }}>
                                    <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black" }}>Amount (Exl GST)</td>
                                        <td style={{ border: "2px solid black" }}>{isNumber(amtExclGst)}</td>
                                    </tr>
                                    {!isMHGSTNumber&& <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black" }}>IGST Amount</td>
                                        <td style={{ border: "2px solid black" }}>{isNumber(CGSTAmt)}</td>
                                    </tr>}
                                    {isMHGSTNumber&& <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black" }}>CGST Amount</td>
                                        <td style={{ border: "2px solid black" }}>{isNumber(SGSTAmt)}</td>
                                    </tr>}
                                    {isMHGSTNumber&& <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black" }}>SGST Amount</td>
                                        <td style={{ border: "2px solid black" }}>{isNumber(SGSTAmt)}</td>
                                    </tr>}
                                    <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black" }}><b>Total Amount</b></td>
                                        <td style={{ border: "2px solid black" }}>{amtInclGst}</td>
                                    </tr>
                                    <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black" }}>Discount/Coupon</td>
                                        <td style={{ border: "2px solid black" }}>{orderdetails["order_disc"]}</td>
                                    </tr>
                                    <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black" }}>SZ Wallet Paid</td>
                                        <td style={{ border: "2px solid black" }}>{orderdetails["cashback_redeemed"]}</td>
                                    </tr>
                                    <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black" }}>SuperKredit/RazorPay</td>
                                        <td style={{ border: "2px solid black" }}>{(isAlreadyPaid(orderdetails["order_payment_mode"]) && !(orderdetails["order_payment_mode"]=="Rupifi Credits" || orderdetails["order_payment_mode"]=="Finaleap Credits"))?orderdetails['net_order_amt']:0}</td>
                                    </tr>
                                    <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black" }}>Rupifi/Finaleap</td>
                                        <td style={{ border: "2px solid black" }}>{(orderdetails["order_payment_mode"]=="Rupifi Credits" || orderdetails["order_payment_mode"]=="Finaleap Credits")?orderdetails['net_order_amt']:0}</td>
                                    </tr>
                                    <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black" }}><b>Net Amount Payable</b></td>
                                        <td style={{ border: "2px solid black" }}><b>{isAlreadyPaid(orderdetails["order_payment_mode"])?0:Math.round((Number(amtInclGst) - Number(Number(orderdetails["order_disc"]) + Number(orderdetails["cashback_redeemed"])))).toFixed(2)}</b></td>
                                    </tr>

                                </Table><br /><br />
                                For Retranz Infolabs Private Limited<br /><br />
                                <i>(Authorized Signatory)</i>
                            </div></td>
                        </tr></Table>
                        <Table>
                          <tr>
                            <td><img src={footer} alt={"Khetika"} style={{  width: "100%", height: "100px" }} /></td>
                          </tr>
                        </Table>
                    </div>
                </div>
            </div>
            {getBackDrop ? (<Backdrop parentLoadStatus={getBackDrop} />) : (null)}
        </>
    )
}

export default OrdersDetails
