import { useState, useEffect } from 'react'
import database from '../../Configs/firebase'
import XLSX from 'xlsx'
import saveAs from 'file-saver'
// import {Blob} from 'Blob'
import { FormRow } from 'react-bootstrap/Form'
import { object } from 'prop-types'
import { validateIsOperationUser, validateIsBillingUser, validateIsAdminUser,validateIsFinanceUser } from '../UserCredentials'


const DataExport = ({ downloadDate, getOrderHistory, multipleDownload, ...props }) => {
  const fetchDetails = JSON.parse(JSON.stringify(getOrderHistory))
  console.log(fetchDetails);

    const [DownloadConfirm, setDownloadConfirm] = useState(false)
    const [orderSummary, setOrderSummary] = useState("")
    const [totalOrderQty, setTotalOrderQty] = useState("")
    const [quantityArrayCollection, setQuantityArrayCollection] = useState("")
    const [totalAmtOrders, setTotalAmtOrders] = useState("")
    const [totalValOrders, setTotalValOrders] = useState("")
    const [totalPaymentPerOrder, setTotalPaymentPerOrder] = useState("")
    const [totalPaymentDetails, setTotalPaymentDetails] = useState("")
    const [itemList, setItemList]= useState([])
    const [itemSummary, setItemSummary]= useState([])
    const [vehicleData, setVehicleData] = useState("")
    const [vehicleRouteInfo, setVehicleRouteInfo] = useState("")
    const [looselyAllocated, setLooselyAllocated] = useState("")
    const [deliveryBoyName, setDeliveryBoyName] = useState("")
    const [vehicleLoaded, setVehicleLoaded] = useState("")
    const [notReturnedVehicleList, setNotReturnedVehicleList] = useState([])
    const [cashDenominationObj, setCashDenominationObj] = useState({});


    const dataBaseDetails = database.database().ref(`Driver_Orders_History/${downloadDate.split("-")[2]}/${downloadDate.split("-")[1]}/${downloadDate}`)
    const driverDetailsDb = database.database().ref(`Driver_Allocation_History/${downloadDate.split("-")[2]}/${downloadDate.split("-")[1]}/${downloadDate}`)
    const cashDenominationsDb = database.database().ref(`Cash_Denomination/${downloadDate.split("-")[2]}/${downloadDate.split("-")[1]}/${downloadDate}`)

    const convertCity = (cityVal) => {
    //   cityVal=cityVal.toLowerCase();
    //   if(cityVal=="pune"){
    //     return "Pune";
    //   } else if(cityVal=="surat"){
    //     return "Surat";
    //   } else if(cityVal=="nashik"){
    //     return "Nashik";
    //   } else {
    //     return "Mumbai";
    //   }
    return cityVal;
    }

    const fetchOrdersDrivers = () => {
        const itemSummary = {};
        itemSummary['Delivered'] = {};
        itemSummary['UnDelivered'] = {};
        itemSummary['Short'] = {};
        itemSummary['NotLoaded'] = {};
        itemSummary['Returns'] = {};
        itemSummary['DeliveredPayment'] = {};
        itemSummary['UnDeliveredPayment'] = {};
        itemSummary['ShortPayment'] = {};
        itemSummary['NotLoadedPayment'] = {};
        itemSummary['ReturnsPayment'] = {};

        const orderUniqueArray = [];
        const indVehicleDetails = []
        let summedUpAmount ={}
        const itemList = [];
        let looseAllocated = [];
        let vehicleLoaded =[];
        let deliveryBoyName =[];

        let count = 0;
        let quantityArray = []
        let totalAmtOrdesArray = []
        let totalOrdersValArray = []
        let totalPaymentArray = []

        let updatedVehicleRouteInfo = {};

        vehicleRouteInfo.forEach(leadDeliveryDetails=>{
          let route = leadDeliveryDetails.route;
          updatedVehicleRouteInfo[route] = leadDeliveryDetails;
        })

        console.log(updatedVehicleRouteInfo);

        let orderBillTotalAmtMap = {};

        Object.keys(fetchDetails).forEach(vehicleId => {

            const indVehicleObject = {}
            const ordersArray = []
            let totalOrderQuant = 0;
            let totalAmtPerOrders = 0;
            let totalValuePerOrders = 0;
            let totalPaymentOrdered = 0;

            if(updatedVehicleRouteInfo[vehicleId]){
              let leadDeliveryDetails = updatedVehicleRouteInfo[vehicleId] || {};
              if(leadDeliveryDetails.route===vehicleId && leadDeliveryDetails.looseAllocatedBy!=undefined && leadDeliveryDetails.vehicleLoadedBy!=undefined){
                  looseAllocated.push(leadDeliveryDetails.looseAllocatedBy)
                  vehicleLoaded.push(leadDeliveryDetails.vehicleLoadedBy)
                  deliveryBoyName.push(leadDeliveryDetails.name)
              }
            }

            Object.keys(fetchDetails[vehicleId]).forEach(ordersNo => {
                let originalOrderNumber = fetchDetails[vehicleId][ordersNo].order_number || ""
                let originalOrderItemAmt = Number(fetchDetails[vehicleId][ordersNo].mrp) || 0
                let itemOrderedQty = Number(fetchDetails[vehicleId][ordersNo].order_qty) || 0
                let itemDeliveredQty = fetchDetails[vehicleId][ordersNo].item_delivered_qty?Number(fetchDetails[vehicleId][ordersNo].item_delivered_qty):(
                  fetchDetails[vehicleId][ordersNo].item_modified_qty?Number(fetchDetails[vehicleId][ordersNo].item_modified_qty):(
                    fetchDetails[vehicleId][ordersNo].order_qty?Number(fetchDetails[vehicleId][ordersNo].order_qty):0
                  )
                )

                if(!orderBillTotalAmtMap[originalOrderNumber]){
                  orderBillTotalAmtMap[originalOrderNumber] = 0;
                }

                orderBillTotalAmtMap[originalOrderNumber] += originalOrderItemAmt * (itemDeliveredQty/itemOrderedQty);

                let fetchObjectDetails = {}
                fetchObjectDetails["date"] = downloadDate
                // fetchObjectDetails["Vehicle_number"] = vehicleId
                fetchObjectDetails["order_number"] = fetchDetails[vehicleId][ordersNo].order_number || ""
                fetchObjectDetails["item_disc"] = fetchDetails[vehicleId][ordersNo].item_disc || ""
                fetchObjectDetails["item_id"] = fetchDetails[vehicleId][ordersNo].item_id || ""
                fetchObjectDetails["variety"] = fetchDetails[vehicleId][ordersNo].variety || ""
                fetchObjectDetails["status"] = fetchDetails[vehicleId][ordersNo].status || ""
                fetchObjectDetails["gst_charge"] = fetchDetails[vehicleId][ordersNo].gst_charge || ""
                fetchObjectDetails["gst_number"] = fetchDetails[vehicleId][ordersNo].gst_number || ""
                fetchObjectDetails["reason"] = fetchObjectDetails["status"].indexOf('Delivered')!=-1?"":fetchDetails[vehicleId][ordersNo].actual_reason?fetchDetails[vehicleId][ordersNo].actual_reason:fetchDetails[vehicleId][ordersNo].reason || ""
                fetchObjectDetails["action_taken"] = fetchObjectDetails["status"].indexOf('Delivered')!=-1?"":fetchDetails[vehicleId][ordersNo].action_taken || ""
                if(fetchDetails[vehicleId][ordersNo].item_primary_reason && (fetchObjectDetails["status"] == 'Partially-Delivered' || fetchObjectDetails["status"] =='Shipped-Modified') ){
                     fetchObjectDetails["item_status"]=fetchDetails[vehicleId][ordersNo].item_primary_reason
                     fetchObjectDetails["item_reason"] = fetchDetails[vehicleId][ordersNo].item_reason || ""
                } else {
                    fetchObjectDetails["item_status"]=fetchObjectDetails["status"].indexOf('Delivered')!=-1?'Delivered':fetchObjectDetails["status"]
                    fetchObjectDetails["item_reason"] = ""
                }

                fetchObjectDetails["order_qty"] = Number(fetchDetails[vehicleId][ordersNo].order_qty) || 0
                totalOrderQuant = totalOrderQuant + Number(fetchDetails[vehicleId][ordersNo].order_qty)
                if (String(fetchObjectDetails["status"]).includes("Delivered")){
                    fetchObjectDetails["item_returned_qty"] = fetchDetails[vehicleId][ordersNo].item_returned_qty?Number(fetchDetails[vehicleId][ordersNo].item_returned_qty):""
                }

                if(vehicleId != 9999 && fetchObjectDetails["status"] != 'Hold') {
                    fetchObjectDetails["item_modified_qty"] = fetchDetails[vehicleId][ordersNo].item_modified_qty?Number(fetchDetails[vehicleId][ordersNo].item_modified_qty):Number(fetchDetails[vehicleId][ordersNo].order_qty)
                    if (fetchDetails[vehicleId][ordersNo].item_delivered_qty && fetchObjectDetails["status"] == 'Partially-Delivered') {
                        fetchObjectDetails["item_delivered_qty"] = fetchDetails[vehicleId][ordersNo].item_delivered_qty;
                    } else if (String(fetchObjectDetails["status"]).includes("Delivered")){
                        fetchObjectDetails["item_delivered_qty"] = fetchObjectDetails["order_qty"]
                    }
                }
                fetchObjectDetails["order_value"] = Number(fetchDetails[vehicleId][ordersNo].mrp) || 0
                totalValuePerOrders = totalValuePerOrders + Number(fetchDetails[vehicleId][ordersNo].mrp)
                fetchObjectDetails["order_date"] = fetchDetails[vehicleId][ordersNo].order_date || ""
                fetchObjectDetails["total_amount_paid"] = 0
                if (fetchDetails[vehicleId][ordersNo].hasOwnProperty("payment_details") && String(fetchObjectDetails["status"]).includes("Delivered")) {

                    if (fetchDetails[vehicleId][ordersNo].payment_details.hasOwnProperty("payment_mode")) {
                        fetchObjectDetails = setPaymentDetails(fetchDetails[vehicleId][ordersNo].payment_details,fetchObjectDetails);
                    } else {
                        const paymentList = fetchDetails[vehicleId][ordersNo].payment_details;
                        Object.keys(paymentList).forEach(key => {
                            if(key!="total_amount_paid")
                                fetchObjectDetails = setPaymentDetails(paymentList[key],fetchObjectDetails);
                        });

                    }
                    totalPaymentOrdered = totalPaymentOrdered + totalPaymentPerOrder
                    // console.log(totalPaymentOrdered)

                    if (fetchDetails[vehicleId][ordersNo].payment_details.hasOwnProperty("total_amount_paid"))
                        fetchObjectDetails["total_amount_paid"] = Number(fetchDetails[vehicleId][ordersNo].payment_details.total_amount_paid) || 0
                }
                if(fetchDetails[vehicleId][ordersNo].order_payment_mode=="RazorPay") {
                  fetchObjectDetails["payment_mode"] = "Prepaid";
                }
                fetchObjectDetails["phone"] = fetchDetails[vehicleId][ordersNo].phone || ""
                fetchObjectDetails["priority"] = fetchDetails[vehicleId][ordersNo].priority || ""
                fetchObjectDetails["retailer_id"] = fetchDetails[vehicleId][ordersNo].retailer_id || ""
                fetchObjectDetails["shop_name"] = fetchDetails[vehicleId][ordersNo].shop_name || ""
                fetchObjectDetails["total_amt"] = Number(fetchDetails[vehicleId][ordersNo].total_amt) || 0
                totalAmtPerOrders = totalAmtPerOrders +Number(fetchDetails[vehicleId][ordersNo].total_amt)

                //new fields
                let itemDeliveredUpdatedBy=fetchDetails[vehicleId][ordersNo].itemDeliveredUpdatedBy;
                if(itemDeliveredUpdatedBy){
                  itemDeliveredUpdatedBy = itemDeliveredUpdatedBy.replace("@superzop.com", "");
                }

                let itemModifiedUpdatedBy=fetchDetails[vehicleId][ordersNo].itemModifiedUpdatedBy;
                if(itemModifiedUpdatedBy){
                  itemModifiedUpdatedBy = itemModifiedUpdatedBy.replace("@superzop.com", "");
                }

                let updatedBy=fetchDetails[vehicleId][ordersNo].updatedBy;
                if(updatedBy){
                  updatedBy = updatedBy.replace("@superzop.com", "");
                }

                if(ordersNo.includes('OR')){
                  fetchObjectDetails["payment_returns_amount"] =  fetchDetails[vehicleId][ordersNo].total_amt
                  fetchObjectDetails["payment_mode"] = "return";
                }

                fetchObjectDetails["itemDeliveredUpdatedAt"] = fetchDetails[vehicleId][ordersNo].itemDeliveredUpdatedAt || ""
                fetchObjectDetails["itemDeliveredUpdatedBy"] = itemDeliveredUpdatedBy || ""
                fetchObjectDetails["itemModifiedUpdatedAt"] = fetchDetails[vehicleId][ordersNo].itemModifiedUpdatedAt || ""
                fetchObjectDetails["itemModifiedUpdatedBy"] = itemModifiedUpdatedBy || ""
                fetchObjectDetails["updatedAt"] = fetchDetails[vehicleId][ordersNo].updatedAt || ""
                fetchObjectDetails["updatedBy"] = updatedBy || ""
                fetchObjectDetails["ordered_item_id"] = fetchDetails[vehicleId][ordersNo].ordered_item_id || ""
                fetchObjectDetails["ordered_item_disc"] = fetchDetails[vehicleId][ordersNo].ordered_item_disc || ""
                fetchObjectDetails["new_dealer_price"] = fetchDetails[vehicleId][ordersNo].new_dealer_price || ""
                fetchObjectDetails["area"] = fetchDetails[vehicleId][ordersNo].area || ""
                fetchObjectDetails["gst_per"] = fetchDetails[vehicleId][ordersNo].gst_per || ""
                fetchObjectDetails["asm"] = fetchDetails[vehicleId][ordersNo].asm || ""
                fetchObjectDetails["payment_rupifi"] = fetchDetails[vehicleId][ordersNo].rupifi_amount || ""
                fetchObjectDetails["payment_finaleap"] = fetchDetails[vehicleId][ordersNo].finaleap_amount || ""
                fetchObjectDetails["salesman_name"] = fetchDetails[vehicleId][ordersNo].salesman_name || ""
                fetchObjectDetails["company_type"] = updatedVehicleRouteInfo[vehicleId]?updatedVehicleRouteInfo[vehicleId].company_type:""
                let city = fetchDetails[vehicleId][ordersNo].city || ""
                fetchObjectDetails["city"] = convertCity(city)

                fetchObjectDetails["deliveredValue"] = Number(fetchDetails[vehicleId][ordersNo].mrp) * (itemDeliveredQty/itemOrderedQty);

                ordersArray.push(fetchObjectDetails)

                ordersArray.sort((a,b) => (a.priority > b.priority) ? 1 : ((b.priority > a.priority) ? -1 : 0));
                //order_level summary
                orderUniqueArray.push(fetchDetails[vehicleId][ordersNo].order_number)

                //ItemSummary
                const itemDetails = {"item_id": fetchObjectDetails["item_id"],"item_disc": fetchObjectDetails["item_disc"]};
                if(itemList.findIndex(i => i.item_id === itemDetails.item_id)==-1) {
                    itemList.push({"item_id": fetchObjectDetails["item_id"],"item_disc": fetchObjectDetails["item_disc"].replace(" -","-")});
                    itemSummary['Delivered'] [fetchObjectDetails["item_id"]] = 0;
                    itemSummary['UnDelivered'] [fetchObjectDetails["item_id"]] = 0;
                    itemSummary['Short'] [fetchObjectDetails["item_id"]] = 0;
                    itemSummary['NotLoaded'] [fetchObjectDetails["item_id"]] = 0;
                    itemSummary['Returns'] [fetchObjectDetails["item_id"]] = 0;
                    itemSummary['ReturnsPayment'] [fetchObjectDetails["item_id"]] = 0;
                    itemSummary['DeliveredPayment'] [fetchObjectDetails["item_id"]] = 0;
                    itemSummary['UnDeliveredPayment'] [fetchObjectDetails["item_id"]] = 0;
                    itemSummary['ShortPayment'] [fetchObjectDetails["item_id"]] = 0;
                    itemSummary['NotLoadedPayment'] [fetchObjectDetails["item_id"]] = 0;
                }


                if(fetchObjectDetails["status"] == "Delivered") {
                    if(vehicleId == 9999) {
                        // console.log("Item Returned:: "+fetchObjectDetails["item_returned_qty"])
                        itemSummary['Returns'] [fetchObjectDetails["item_id"]]+=fetchObjectDetails["item_returned_qty"]?fetchObjectDetails["item_returned_qty"]:fetchObjectDetails["order_qty"];
                        // console.log("Returns"+itemSummary['Returns'] [fetchObjectDetails["item_id"]])
                    } else {
                        itemSummary['Delivered'] [fetchObjectDetails["item_id"]]+=Number(fetchObjectDetails["order_qty"]);
                        itemSummary['DeliveredPayment'] [fetchObjectDetails["item_id"]]+=Number(fetchObjectDetails["order_value"]);
                        itemSummary['Returns'] [fetchObjectDetails["item_id"]]+=fetchObjectDetails["item_returned_qty"]?fetchObjectDetails["item_returned_qty"]:0
                        // console.log("Returns"+itemSummary['Returns'] [fetchObjectDetails["item_id"]])
                    }
                } else if (fetchObjectDetails["status"] == "Partially-Delivered" || fetchObjectDetails["status"] == "Shipped-Modified") {
                    const orderedQty = Number(fetchObjectDetails["order_qty"]);
                    const orderedAmt = Number(fetchObjectDetails["order_value"]);
                    let unDeliveredQty = orderedQty;
                    let unDeliveredAmt = orderedAmt;
                    let deliveredQty = 0;
                    let deliveredAmt = 0;
                    if(fetchDetails[vehicleId][ordersNo].item_modified_qty || fetchDetails[vehicleId][ordersNo].item_delivered_qty) {
                        deliveredQty = fetchDetails[vehicleId][ordersNo].item_modified_qty ? Number(fetchObjectDetails["item_modified_qty"]) : Number(fetchObjectDetails["item_delivered_qty"]);
                        deliveredAmt = Number(orderedAmt * (deliveredQty/orderedQty).toFixed(2));
                        unDeliveredQty = orderedQty - deliveredQty;
                        unDeliveredAmt = orderedAmt - deliveredAmt;
                    }
                    if(fetchObjectDetails["item_status"] == "Returns"){
                        itemSummary['UnDelivered'] [fetchObjectDetails["item_id"]]+=unDeliveredQty;
                        itemSummary['UnDeliveredPayment'] [fetchObjectDetails["item_id"]]+=unDeliveredAmt;
                        itemSummary['Delivered'] [fetchObjectDetails["item_id"]]+=deliveredQty;
                        itemSummary['DeliveredPayment'] [fetchObjectDetails["item_id"]]+=deliveredAmt;
                    } else if(fetchObjectDetails["item_status"] == "Short"){
                        itemSummary['Short'] [fetchObjectDetails["item_id"]]+=unDeliveredQty;
                        itemSummary['ShortPayment'] [fetchObjectDetails["item_id"]]+=unDeliveredAmt;
                        itemSummary['Delivered'] [fetchObjectDetails["item_id"]]+=deliveredQty;
                        itemSummary['DeliveredPayment'] [fetchObjectDetails["item_id"]]+=deliveredAmt;
                    } else if(fetchObjectDetails["item_status"] == "Bill Modified"){
                        itemSummary['NotLoaded'] [fetchObjectDetails["item_id"]]+=unDeliveredQty;
                        itemSummary['NotLoadedPayment'] [fetchObjectDetails["item_id"]]+=unDeliveredAmt;
                        itemSummary['Delivered'] [fetchObjectDetails["item_id"]]+=deliveredQty;
                        itemSummary['DeliveredPayment'] [fetchObjectDetails["item_id"]]+=deliveredAmt;
                    }
                    itemSummary['Returns'] [fetchObjectDetails["item_id"]]+=fetchObjectDetails["item_returned_qty"]?Number(fetchObjectDetails["item_returned_qty"]):0
                } else if (fetchObjectDetails["status"] == "Hold") {
                    itemSummary['NotLoaded'] [fetchObjectDetails["item_id"]]+=Number(fetchObjectDetails["order_qty"]);
                    itemSummary['NotLoadedPayment'] [fetchObjectDetails["item_id"]]+=Number(fetchObjectDetails["order_value"]);
                } else {
                    itemSummary['UnDelivered'] [fetchObjectDetails["item_id"]]+=Number(fetchObjectDetails["order_qty"]);
                    itemSummary['UnDeliveredPayment'] [fetchObjectDetails["item_id"]]+=Number(fetchObjectDetails["order_value"]);
                }
            })
            setItemList(itemList)
            setItemSummary(itemSummary)
            // console.log("ItemList length::"+itemList.length);
            quantityArray.push(totalOrderQuant)
            totalAmtOrdesArray.push(totalAmtPerOrders)
            totalOrdersValArray.push(totalValuePerOrders)
            totalPaymentArray.push(totalPaymentOrdered)
            indVehicleObject["vehicle_id"] = vehicleId;
            indVehicleObject["vehicle_Data"] = ordersArray;
            indVehicleDetails.push(indVehicleObject)
            count++
        })
        // console.log(quantityArray)
        setQuantityArrayCollection(quantityArray)
        setTotalAmtOrders(totalAmtOrdesArray)
        setTotalValOrders(totalOrdersValArray)
        setTotalPaymentDetails(totalPaymentArray)
        setLooselyAllocated(looseAllocated)
        setVehicleLoaded(vehicleLoaded)
        setDeliveryBoyName(deliveryBoyName)



            let uniqueOrderSet = new Set(orderUniqueArray);
            let orderAddedInSummaryMap = {};

            const orderWiseList = [];
            let orderAdd = 0;
            let totalOrderVal = 0;
            let totalOrderAmt = 0;




            Object.keys(fetchDetails).forEach(vehicleId => {



              let leadDeliveryDetails = {};
              if(updatedVehicleRouteInfo[vehicleId]){
                leadDeliveryDetails = updatedVehicleRouteInfo[vehicleId] || {};
              }

              console.log(leadDeliveryDetails);

                Object.keys(fetchDetails[vehicleId]).forEach(ordersNo => {

                    let finalOrderNumber = fetchDetails[vehicleId][ordersNo].order_number || "";

                    if (uniqueOrderSet.has(fetchDetails[vehicleId][ordersNo].order_number) && !orderAddedInSummaryMap[finalOrderNumber]) {
                        orderAddedInSummaryMap[finalOrderNumber] = true;

                        let Payment_mode = ""
                        let Total_amt_paid = 0

                        let payment_cash_amount, payment_cheque1_amount, payment_cheque2_amount, payment_card_amount, payment_paytm_amount, payment_returns_amount;
                        let payment_razorpay_amount, payment_pending_payment_amount, payment_adjustments;
                        let payment_rupifi, payment_finaleap;
                        let payment_cheque1_number, payment_cheque2_number;
                        let city = fetchDetails[vehicleId][ordersNo].city || "";
                        let cashback_redeemed = Number(fetchDetails[vehicleId][ordersNo].cashback_redeemed) || 0;
                        let finalOrderDiscount = fetchDetails[vehicleId][ordersNo].revised_order_disc?Number(fetchDetails[vehicleId][ordersNo].revised_order_disc):fetchDetails[vehicleId][ordersNo].order_disc?Number(fetchDetails[vehicleId][ordersNo].order_disc):0;
                        let paymentDetails = {}
                        if(fetchDetails[vehicleId][ordersNo].payment_details){
                            if (fetchDetails[vehicleId][ordersNo].payment_details && fetchDetails[vehicleId][ordersNo].payment_details.hasOwnProperty("payment_mode")) {
                                paymentDetails = setPaymentDetails(fetchDetails[vehicleId][ordersNo].payment_details,paymentDetails);
                            } else {
                                const paymentList = fetchDetails[vehicleId][ordersNo].payment_details;
                                Object.keys(paymentList).forEach(key => {
                                    if(key!="total_amount_paid"){
                                      paymentDetails = setPaymentDetails(paymentList[key],paymentDetails);
                                    }
                                });

                            }
                            // console.log(paymentDetails);

                            Payment_mode = paymentDetails.payment_mode;



                            payment_cash_amount = cleanNumbers(paymentDetails.cash_amount)
                            payment_cheque1_amount = cleanNumbers(paymentDetails.cheque1_amount)
                            payment_cheque2_amount = cleanNumbers(paymentDetails.cheque2_amount)
                            payment_cheque1_number = paymentDetails.cheque1_number || ""
                            payment_cheque2_number = paymentDetails.cheque2_number || ""
                            payment_card_amount = cleanNumbers(paymentDetails.card_amount)
                            payment_paytm_amount = cleanNumbers(paymentDetails.paytm_amount)
                            payment_razorpay_amount = cleanNumbers(paymentDetails.razorpay_amount)
                            payment_pending_payment_amount = cleanNumbers(paymentDetails.pending_amount)
                            payment_adjustments = cleanNumbers(paymentDetails.adjustments)
                            payment_rupifi = cleanNumbers(paymentDetails.rupifi_amount)
                            payment_finaleap = cleanNumbers(paymentDetails.finaleap_amount)

                            if (fetchDetails[vehicleId][ordersNo].payment_details.hasOwnProperty("total_amount_paid"))
                                Total_amt_paid = Number(fetchDetails[vehicleId][ordersNo].payment_details.total_amount_paid) || 0
                        }

                        // console.log('debug check order no', ordersNo,  fetchDetails[vehicleId][ordersNo] )

                        if(ordersNo.includes('OR')){
                          Payment_mode = 'return'
                          payment_returns_amount =  fetchDetails[vehicleId][ordersNo].order_amt
                        }

                        if(fetchDetails[vehicleId][ordersNo].order_payment_mode=="RazorPay") {
                          Payment_mode = "Prepaid"
                        }

                        let origOrderNumber = fetchDetails[vehicleId][ordersNo].order_number || ""
                        let discountValue = fetchDetails[vehicleId][ordersNo].revised_order_disc?Number(fetchDetails[vehicleId][ordersNo].revised_order_disc):
                                            fetchDetails[vehicleId][ordersNo].order_disc?Number(fetchDetails[vehicleId][ordersNo].order_disc):0;
                        let totalOrderValueWithDiscount = Number(fetchDetails[vehicleId][ordersNo].total_amt) - discountValue;

                        let updatedBy=fetchDetails[vehicleId][ordersNo].updatedBy;
                        if(updatedBy){
                          updatedBy = updatedBy.replace("@superzop.com", "");
                        }

                        orderWiseList.push([downloadDate,
                            vehicleId,
                            leadDeliveryDetails.name,
                            fetchDetails[vehicleId][ordersNo].retailer_id,
                            fetchDetails[vehicleId][ordersNo].order_number,
                            fetchDetails[vehicleId][ordersNo].status,
                            fetchDetails[vehicleId][ordersNo].reason,
                            fetchDetails[vehicleId][ordersNo].action_taken,
                            fetchDetails[vehicleId][ordersNo].order_date,
                            Payment_mode,
                            Number(fetchDetails[vehicleId][ordersNo].total_amt),
                            Total_amt_paid,
                            fetchDetails[vehicleId][ordersNo].priority,
                            fetchDetails[vehicleId][ordersNo].gst_number,
                            payment_cash_amount,
                            payment_cheque1_amount,
                            payment_cheque2_amount,
                            payment_card_amount,
                            payment_paytm_amount,
                            payment_razorpay_amount,
                            payment_returns_amount,
                            payment_pending_payment_amount,
                            payment_adjustments,
                            fetchDetails[vehicleId][ordersNo].asm,
                            fetchDetails[vehicleId][ordersNo].salesman_name,
                            leadDeliveryDetails.company_type,
                            convertCity(city),
                            cashback_redeemed>0?cashback_redeemed:"",
                            finalOrderDiscount>0?finalOrderDiscount:"",
                            payment_cheque1_number,
                            payment_cheque2_number,
                            orderBillTotalAmtMap[origOrderNumber].toFixed(2),
                            totalOrderValueWithDiscount,
                            fetchDetails[vehicleId][ordersNo].latitude,
                            fetchDetails[vehicleId][ordersNo].longitude,
                            payment_rupifi,
                            payment_finaleap,
                            updatedBy || "",
                            fetchDetails[vehicleId][ordersNo].updatedAt || ""
                        ])
                        totalOrderVal= totalOrderVal + Number(fetchDetails[vehicleId][ordersNo].total_amt);
                        totalOrderAmt = totalOrderAmt + Number(Total_amt_paid);

                    }
                    orderAdd=orderAdd+Number(fetchDetails[vehicleId][ordersNo].order_qty);

                })
                orderWiseList.sort((a,b) => (a.priority > b.priority) ? 1 : ((b.priority > a.priority) ? -1 : 0));


            })
            console.log("orderWiseList");
            console.log(orderWiseList);
            setTotalOrderQty(orderAdd)
            if (orderWiseList) {
                setOrderSummary(orderWiseList)
            }
            console.log(orderSummary)
            console.log(indVehicleDetails);

            setDownloadConfirm(true)
            multipleDownload(false)

            exportFile(indVehicleDetails,orderWiseList,orderAdd,totalOrderVal,totalOrderAmt,orderBillTotalAmtMap);
    }

    const cleanNumbers = (num) => {
      return num?Number(num):"";
    }

    const fetchDriverDetails = () => {
        const leadDeliveryNameDetails = []
        const vehicleDataArray = []
        let vehicleNotReturnedList = []

        Promise.all([
          driverDetailsDb.once("value"),
          cashDenominationsDb.once("value")
        ]).then(([vehicleList, cashSnapshot]) => {
          let cashObj = cashSnapshot.val() || {};
          setCashDenominationObj(cashObj);
          if(vehicleList){

              const vehcileDetails = vehicleList.val()
              console.log(vehcileDetails)
              if(vehcileDetails) {
                  Object.keys(vehcileDetails).forEach(details=>{
                    if(vehcileDetails[details].vehicle_not_returned==true){
                      vehicleNotReturnedList.push(vehcileDetails[details].current_Route_Id);
                    }

                      leadDeliveryNameDetails.push({"name":vehcileDetails[details].actual_Driver_Name,"route":vehcileDetails[details].current_Route_Id,"vehicleLoadedBy":vehcileDetails[details].Vehicle_Loaded_By
                      ,"looseAllocatedBy":vehcileDetails[details].Loose_Allocated_By,"company_type":vehcileDetails[details].company_type})

                      vehicleDataArray.push([vehcileDetails[details].current_Route_Id,vehcileDetails[details].Driver_Name,vehcileDetails[details].Driver_Phone,
                      vehcileDetails[details].actual_Driver_Name,vehcileDetails[details].driver_Phone_Number,vehcileDetails[details].Helper_Boy_Name,
                      vehcileDetails[details].Helper_Boy_Phone,vehcileDetails[details].Helper_2,
                      vehcileDetails[details].Helper_2_Phone,vehcileDetails[details].Vehicle_Loaded_By,vehcileDetails[details].Loose_Allocated_By,
                      vehcileDetails[details].Vehicle_Dispatch_Time,vehcileDetails[details].DB_Return_Time,vehcileDetails[details].DB_Release_Time,vehcileDetails[details].vehicle_number,vehcileDetails[details].company_type])
                  })
              }

          }

          setNotReturnedVehicleList(vehicleNotReturnedList);

          vehicleDataArray.sort((a,b)=>{return a[0]-b[0]});
          setVehicleData(vehicleDataArray);
          setVehicleRouteInfo(leadDeliveryNameDetails)

        })
    }

    useEffect(() => {
        fetchDriverDetails()
    }, [])

    useEffect(() => {
      if(vehicleRouteInfo){
        fetchOrdersDrivers()
      }

    }, [vehicleRouteInfo])

    const exportFile = (downloadList,orderDetailsFromSession,totalOrderQuantity,totalOrderValue,totalAmount,orderBillTotalAmtMap) => {
      console.log('debug check',downloadList);

        let orderDetailsSummary = [["Delivery Date", "Vehicle No.","Lead Delivery Name", "Store ID", "Order No.", "Status", "Reason","Action Taken","Order Date", "Payment Mode", "Total Order Value", "Total Amount Paid","Priority", "GST Number",
        "payment_cash_amount", "payment_cheque1_amount", "payment_cheque2_amount", "payment_card_amount", "payment_paytm_amount", "payment_razorpay_amount", "payment_returns_amount" , "payment_pending_payment_amount", "payment_adjustments", "ASM", "SE Name", "Transporter", "City","payment_wallet","Order Discount", "payment_cheque1_number", "payment_cheque2_number", "Total Bill Amt", "Total Order Value with Discount",'Latitude','Longitude', "payment_rupifi", "payment_finaleap", "updatedBy", "updatedAt"]]

        const wb = XLSX.utils.book_new()
        orderDetailsFromSession.forEach(orders => {
            orderDetailsSummary.push(orders)
        })
        orderDetailsSummary.push(["SUMMARY"])
        orderDetailsSummary.push(["Total_ordered_quantity",totalOrderQuantity])
        orderDetailsSummary.push(["Total_ordered_value",totalOrderValue])
        orderDetailsSummary.push(["Total_amount",totalAmount])
        // console.log(orderDetailsSummary)
        const orderSummaryData = XLSX.utils.aoa_to_sheet(orderDetailsSummary)
        XLSX.utils.book_append_sheet(wb, orderSummaryData, `Order Summary`)

        //OrderDetails

        let orderDetailsRecords = [["Delivery Date","Vehicle No.","Loose Allocated By","Vehicle Loaded By", "Delivery Boy", "Order No.","Area", "Status","Reason","Variety", "Item Details", "Item Id",  "Item Undelivered Reason","Item Status","Delivered Qty", "Modified Qty", "Returned Qty","Order Qty", "Order Value", "Order Date",
        "Payment Mode", "payment_cash_amount", "payment_cheque1_amount", "payment_cheque1_number", "payment_cheque1_date", "payment_cheque_image", "payment_cheque2_amount", "payment_cheque2_number", "payment_cheque2_date",
        "payment_card_amount", "payment_card_transactionid", "payment_paytm_amount", "payment_razorpay_amount", "payment_pending_payment_amount", "payment_adjustments",
        "Phone", "Priority", "GST Charges", "Retailer Id", "Shop Name", "Total Amount", "Total Amount Paid", "itemDeliveredUpdatedAt","itemDeliveredUpdatedBy","itemModifiedUpdatedAt","itemModifiedUpdatedBy","updatedAt","updatedBy","Ordered Item","Ordered Item Description","Updated Dealer Price","ASM","SE Name","Transporter","City","Total Bill Amt", "Delivered Value", "GST Perc", "payment_rupifi", "payment_finaleap"]]

        downloadList.forEach((indVehicle,vehicleWiseIndex) => {

            // console.log(looselyAllocated[vehicleWiseIndex])
            // console.log(vehicleLoaded[vehicleWiseIndex])

            indVehicle.vehicle_Data.forEach((ordersDetails,index) => {

                // console.log('debug check', ordersDetails)
                const { date, order_number, status,reason, variety, item_disc, item_id, item_reason, item_status,item_modified_qty, item_delivered_qty, item_returned_qty,order_qty, order_value,
                    order_date, phone, priority,gst_charge, retailer_id, shop_name, total_amt, total_amount_paid, rupifi_amount, finaleap_amount } = ordersDetails
                const { payment_mode, cash_amount, cheque1_amount, cheque1_number, cheque1_date,cheque_image, cheque2_amount, cheque2_number, cheque2_date,
                    card_amount, card_transactionid, paytm_amount, razorpay_amount, pending_payment_amount, adjustments, itemDeliveredUpdatedAt,itemDeliveredUpdatedBy,itemModifiedUpdatedAt,itemModifiedUpdatedBy,updatedAt,updatedBy,ordered_item_id,ordered_item_disc,new_dealer_price, area, asm, salesman_name, company_type, city, deliveredValue, gst_per } = ordersDetails


                let orderDetailsArray = [date, indVehicle.vehicle_id,looselyAllocated[vehicleWiseIndex],vehicleLoaded[vehicleWiseIndex],deliveryBoyName[vehicleWiseIndex], order_number, area, status,reason, variety, item_disc, item_id,
                    item_reason, item_status, item_delivered_qty,item_modified_qty, item_returned_qty,order_qty, order_value, order_date, payment_mode,
                    cash_amount, cheque1_amount, cheque1_number, cheque1_date,cheque_image, cheque2_amount, cheque2_number, cheque2_date,
                    card_amount, card_transactionid, paytm_amount, razorpay_amount, pending_payment_amount, adjustments,
                    phone, priority,gst_charge, retailer_id, shop_name, total_amt, total_amount_paid, itemDeliveredUpdatedAt,itemDeliveredUpdatedBy,itemModifiedUpdatedAt,itemModifiedUpdatedBy,updatedAt,updatedBy,ordered_item_id,ordered_item_disc,new_dealer_price, asm, salesman_name, company_type, convertCity(city), orderBillTotalAmtMap[order_number].toFixed(2), deliveredValue, gst_per, rupifi_amount, finaleap_amount ]


                    orderDetailsRecords.push(orderDetailsArray)
            })
        })
        const wsIndvData = XLSX.utils.aoa_to_sheet(orderDetailsRecords)
        XLSX.utils.book_append_sheet(wb, wsIndvData, `Order Details`)



        //Vehiclewise
        downloadList.forEach((indVehicle,index) => {

            let exportRecords = [["Delivery Date", "Order No.", "Status","Reason","Variety", "Item Details", "Item Id",  "Item Undelivered Reason","Item Status", "Delivered Qty", "Modified Qty", "Returned Qty", "Order Qty", "Order Value", "Order Date",
                "Payment Mode", "payment_cash_amount", "payment_cheque1_amount", "payment_cheque1_number", "payment_cheque1_date","payment_cheque_image", "payment_cheque2_amount", "payment_cheque2_number", "payment_cheque2_date",
                "payment_card_amount", "payment_card_transactionid", "payment_paytm_amount", "payment_razorpay_amount","payment_returns", "payment_pending_payment_amount", "payment_adjustments",
                "Phone", "Priority", "Retailer Id", "Shop Name", "Total Amount", "Total Amount Paid", "Total Bill Amt"]]


              
            indVehicle.vehicle_Data.forEach((ordersDetails,index) => {
              // console.log('debug check', ordersDetails)
                const { date, order_number, status,reason,variety, item_disc, item_id, item_reason, item_status, item_delivered_qty,item_modified_qty, item_returned_qty, order_qty, order_value,
                    order_date, phone, priority, retailer_id, shop_name, total_amt, total_amount_paid } = ordersDetails
                const { payment_mode, cash_amount, cheque1_amount, cheque1_number, cheque1_date,cheque_image, cheque2_amount, cheque2_number, cheque2_date,
                    card_amount, card_transactionid, paytm_amount, razorpay_amount, payment_returns_amount, pending_payment_amount, adjustments } = ordersDetails

                let orderDetailsArray = [date, order_number, status,reason,variety, item_disc, item_id,
                    item_reason, item_status, item_delivered_qty,item_modified_qty, item_returned_qty, order_qty, order_value, order_date, payment_mode,
                    cash_amount, cheque1_amount, cheque1_number, cheque1_date,cheque_image, cheque2_amount, cheque2_number, cheque2_date,
                    card_amount, card_transactionid, paytm_amount, razorpay_amount, payment_returns_amount, pending_payment_amount, adjustments,
                    phone, priority, retailer_id, shop_name, total_amt, total_amount_paid, orderBillTotalAmtMap[order_number].toFixed(2) ]

                exportRecords.push(orderDetailsArray)
            })
            exportRecords.push(["SUMMARY"])
            exportRecords.push(["Total_Payment",totalPaymentDetails[index]])
            exportRecords.push(["Total_Ordered_Quantity",quantityArrayCollection[index]])
            exportRecords.push(["Total_Ordered_Amount",totalAmtOrders[index]])
            exportRecords.push(["Total_Ordered_Value",totalValOrders[index]])
            const wsIndvData = XLSX.utils.aoa_to_sheet(exportRecords)
            XLSX.utils.book_append_sheet(wb, wsIndvData, `Vehicle ${indVehicle.vehicle_id}`)


        })


        //Item Summary
        let deliveryStatuses = ["Delivered","UnDelivered","Short","NotLoaded","Returns"];
        deliveryStatuses.forEach(deliveryStatus => {
            let summaryRecords = [["ItemId","Item Description","Quantity","Amount"]]
            // console.log("ItemList::: "+itemList.length+itemList)
            // console.log(itemSummary[deliveryStatus])
            itemList.forEach(item => {
                if(itemSummary[deliveryStatus][item.item_id]>0)
                    summaryRecords.push([item.item_id,item.item_disc,itemSummary[deliveryStatus][item.item_id],itemSummary[deliveryStatus+"Payment"][item.item_id]]);
            })
            const summaryData = XLSX.utils.aoa_to_sheet(summaryRecords)
            XLSX.utils.book_append_sheet(wb, summaryData, `Item Summary - `+deliveryStatus)
        })

        //Vehicle Details
        const vehicleInfo = [["Current Route Id","Lead Delivery Name","Lead Delivery Phone","Driver Name","Driver Phone","Helper Boy Name",
    "Helper Boy Phone","Helper2 Name","Helper2 Phone","Vehicle_Loaded_By","Loose_Allocated_By","Vehicle_Dispatch_Time","DB_Return_Time","DB_Release_Time","Vehicle Number","Transporter Name"]]
        if(vehicleData) {
            vehicleData.forEach(items=>{
                vehicleInfo.push(items)
            })
        }
        const vehicleDetailsDatewise = XLSX.utils.aoa_to_sheet(vehicleInfo)
        XLSX.utils.book_append_sheet(wb, vehicleDetailsDatewise, `VehicleDetails`)

        //notReturnedVehicleList
        const notReturnedRoutesInfo = [["Route"]];
        for(let k=0;k<notReturnedVehicleList.length;k++){
          notReturnedRoutesInfo.push([notReturnedVehicleList[k]]);
        }
        const notReturnedVehicleSheet = XLSX.utils.aoa_to_sheet(notReturnedRoutesInfo)
        XLSX.utils.book_append_sheet(wb, notReturnedVehicleSheet, `NotReturnedVehicles`)

        const cashDenominationInfo = [];
        let cashHeader = [];
        cashHeader.push("Denomination")
        let cashRoutes = Object.keys(cashDenominationObj) || [];

        for(let c=0;c<cashRoutes.length;c++){
          let cashRoute = cashRoutes[c];
          cashHeader.push(`Count ${cashRoute}`);
          cashHeader.push(`Total ${cashRoute}`);
        }
        cashDenominationInfo.push(cashHeader);
        let cashDenominationValueList = [2000,500,200,100,50,20,10,"adjustments","coins"];
        for(let k=0;k<cashDenominationValueList.length;k++){
          let cashBody = [];
          let denominatonValue = cashDenominationValueList[k];
          denominatonValue=='adjustments'?cashBody.push("Cash Short"):cashBody.push(denominatonValue);
          let cashTotalCount = 0;
          let cashTotalValue = 0;
          for(let c=0;c<cashRoutes.length;c++){
            let cashRoute = cashRoutes[c];
            let isCashDenomPresent = false;
            if(cashDenominationObj){
              if(cashDenominationObj[cashRoute]){
                if(cashDenominationObj[cashRoute]['currency_count']){
                  isCashDenomPresent = true;
                  let currDenomKey = "";
                  if(denominatonValue!="adjustments" && denominatonValue!="coins"){
                    currDenomKey = `_${denominatonValue}`;
                  }else{
                    currDenomKey = `${denominatonValue}`;
                  }

                  let currCashCount = Number(cashDenominationObj[cashRoute]['currency_count'][currDenomKey]) || 0;
                  cashBody.push(currCashCount);
                  cashTotalCount +=currCashCount;

                  let totalDenomValue = (denominatonValue!="adjustments" && denominatonValue!="coins")?(currCashCount*denominatonValue):currCashCount;
                  cashBody.push(totalDenomValue);
                  cashTotalValue +=totalDenomValue;
                }
              }
            }

            if(!isCashDenomPresent){
              cashBody.push(0);
              cashBody.push(0);
            }
          }
          cashDenominationInfo.push(cashBody);
        }



        const cashDenominationSheet = XLSX.utils.aoa_to_sheet(cashDenominationInfo)
        XLSX.utils.book_append_sheet(wb, cashDenominationSheet, `Cash Denomination`)

        if(validateIsOperationUser((JSON.parse(sessionStorage.getItem(`user`))[0].email))){
          XLSX.writeFile(wb, `dispatch_report_${downloadDate}.xlsx`)
        }else{
          XLSX.writeFile(wb, `delivery_report_${downloadDate}.xlsx`)
        }

    }


    function setPaymentDetails(payment_details,fetchObjectDetails){

        let totalPaymentMade = 0;

        if(fetchObjectDetails["payment_mode"]) {
            fetchObjectDetails["payment_mode"] += ", "+payment_details.payment_mode
        } else {
            fetchObjectDetails["payment_mode"] = payment_details.payment_mode
        }
        if (payment_details.hasOwnProperty("cash_amount")){
            fetchObjectDetails["cash_amount"] = payment_details.cash_amount || "";
            totalPaymentMade = totalPaymentMade +Number(fetchObjectDetails["cash_amount"])}
        if (payment_details.hasOwnProperty("cheque1_amount")){
            fetchObjectDetails["cheque1_amount"] = payment_details.cheque1_amount || ""
            totalPaymentMade = totalPaymentMade +Number(fetchObjectDetails["cheque1_amount"])}
        if (payment_details.hasOwnProperty("cheque1_number"))
            fetchObjectDetails["cheque1_number"] = payment_details.cheque1_number || ""
        if (payment_details.hasOwnProperty("cheque1_date"))
            fetchObjectDetails["cheque1_date"] = payment_details.cheque1_date || ""
        if (payment_details.hasOwnProperty("cheque_image"))
            fetchObjectDetails["cheque_image"] = payment_details.cheque_image || ""
        if (payment_details.hasOwnProperty("cheque2_amount")){
            fetchObjectDetails["cheque2_amount"] = payment_details.cheque2_amount || ""
            totalPaymentMade = totalPaymentMade +Number(fetchObjectDetails["cheque2_amount"])}
        if (payment_details.hasOwnProperty("cheque2_number"))
            fetchObjectDetails["cheque2_number"] =  payment_details.cheque2_number || ""
        if (payment_details.hasOwnProperty("cheque2_date"))
            fetchObjectDetails["cheque2_date"] = payment_details.cheque2_date || ""
        if (payment_details.hasOwnProperty("card_amount")){
            fetchObjectDetails["card_amount"] = payment_details.card_amount || ""
            totalPaymentMade = totalPaymentMade +Number(fetchObjectDetails["card_amount"])}
        if (payment_details.hasOwnProperty("card_transactionid"))
            fetchObjectDetails["card_transactionid"] = payment_details.card_transactionid || ""
        if (payment_details.hasOwnProperty("paytm_amount")){
            fetchObjectDetails["paytm_amount"] = payment_details.paytm_amount || ""
            totalPaymentMade = totalPaymentMade +Number(fetchObjectDetails["paytm_amount"])}
        if (payment_details.hasOwnProperty("razorpay_amount")){
            fetchObjectDetails["razorpay_amount"] = payment_details.razorpay_amount || ""
            totalPaymentMade = totalPaymentMade +Number(fetchObjectDetails["razorpay_amount"])}
        if (payment_details.hasOwnProperty("pending_amount")){
          fetchObjectDetails["pending_payment_amount"] = payment_details.pending_amount || ""
          fetchObjectDetails["pending_amount"] = payment_details.pending_amount || ""
        }

        if (payment_details.hasOwnProperty("rupifi_amount")){
            fetchObjectDetails["rupifi_amount"] = payment_details.rupifi_amount || ""
            totalPaymentMade = totalPaymentMade +Number(fetchObjectDetails["rupifi_amount"])
        }

        if (payment_details.hasOwnProperty("finaleap_amount")){
            fetchObjectDetails["finaleap_amount"] = payment_details.finaleap_amount || ""
            totalPaymentMade = totalPaymentMade +Number(fetchObjectDetails["finaleap_amount"])
        }

        if (payment_details.hasOwnProperty("adjustment_amount"))
            fetchObjectDetails["adjustments"] = payment_details.adjustment_amount || ""

            setTotalPaymentPerOrder(totalPaymentMade)
        return fetchObjectDetails;
    }




    return null
}


export default DataExport
