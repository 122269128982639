import React, { useState, useEffect,useRef } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Dropdown, Navbar, Jumbotron, InputGroup, Button, DropdownButton,ButtonGroup, Table } from 'react-bootstrap'
import { InputGroupAddon } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import XLSX from 'xlsx'
import Backdrop from '../../backdrop/backdrop'

function OrderSummaryPage() {

    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [orderDetails, setOrderDetails] = useState("")
    const [orderStatus, setOrderStatus] = useState("")
    const [exportData, setExportData] = useState(false)
    const [getBackDrop,setBackDrop] = useState(false)
    const [orderSearch, setOrderSearch] = useState(false)
    const [sortValue, setSortValue] = useState("")

    const sortingListRef = useRef();

    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_CENTER,
    });

    const notify= (unavailability) => {

        if(unavailability)
        return toast("No Details Available for selected date range or with order status", { type: toast.TYPE.ERROR })

    }


    useEffect(() => {

        setBackDrop(true)
        const startDateConversion = `${startDate.getFullYear()}-${startDate.getMonth() + 1 < 10 ? "0" + (startDate.getMonth() + 1) : startDate.getMonth() + 1}-${startDate.getDate() < 0 ? "0" + startDate.getDate() : startDate.getDate()}`
        const endDateConversion = `${endDate.getFullYear()}-${endDate.getMonth() + 1 < 10 ? "0" + (endDate.getMonth() + 1) : endDate.getMonth() + 1}-${endDate.getDate() < 0 ? "0" + endDate.getDate() : endDate.getDate()}`
        fetch(`http://services2.superzop.com:3000/api/superzop/delivery/ordersbasedondeliverydate?start_date=${startDateConversion}&end_date=${endDateConversion}`,{ headers: { 'x-access-token' : sessionStorage.getItem('x-access-token') } }).then(res => {
        return res.json()
        }).then(data => { console.log(data.orders);setOrderDetails(data.orders);setBackDrop(false);if(!data.orders.length>0){notify(true)} }).catch(err => console.log(err))

    }, [])



    const handleDateRangeChange = () => {

        if(startDate>endDate){
            
            setBackDrop(false)
            setOrderDetails("")
            return toast("Please enter a valid date range", { type: toast.TYPE.ERROR })

        }
        setOrderDetails("")
        const startDateConversion = `${startDate.getFullYear()}-${startDate.getMonth() + 1 < 10 ? "0" + (startDate.getMonth() + 1) : startDate.getMonth() + 1}-${startDate.getDate() < 0 ? "0" + startDate.getDate() : startDate.getDate()}`
        const endDateConversion = `${endDate.getFullYear()}-${endDate.getMonth() + 1 < 10 ? "0" + (endDate.getMonth() + 1) : endDate.getMonth() + 1}-${endDate.getDate() < 0 ? "0" + endDate.getDate() : endDate.getDate()}`
        if(!orderStatus || orderStatus==="reset"){

            fetch(`http://services2.superzop.com:3000/api/superzop/delivery/ordersbasedondeliverydate?start_date=${startDateConversion}&end_date=${endDateConversion}`,{ headers: { 'x-access-token' : sessionStorage.getItem('x-access-token') } }).then(res => {
                return res.json()
                }).then(data => { console.log(data.orders);setOrderDetails(data.orders);setBackDrop(false);if(!data.orders.length>0){notify(true)} }).catch(err => console.log(err))
                return
        }
        fetch(`http://services2.superzop.com:3000/api/superzop/delivery/ordersbasedondeliverydate?start_date=${startDateConversion}&end_date=${endDateConversion}&status=${orderStatus ? orderStatus : "Delivered"}`,{ headers: { 'x-access-token' : sessionStorage.getItem('x-access-token') } }).then(res => {
        return res.json()
        }).then(data => { console.log(data.orders);setOrderDetails(data.orders);setBackDrop(false);if(!data.orders.length>0){notify(true)} }).catch(err => console.log(err))
    }



    useEffect(() => {
        if (orderSearch || orderStatus)
            handleDateRangeChange()
    }, [orderSearch, orderStatus])


    const exportOrderSummary = () => {

        const wb = XLSX.utils.book_new()

        setBackDrop(true)
        let orderDetailsSummary = [["Order Number", "Driver Id", "Status", "Total Amount", "Total Amount Paid","Retailer ID","Reason","Payment Mode","Priority","Delivery Date"]]
        orderDetails.map(item=>{
            item["total_amt"] = Number(item["total_amt"]);
            item["mrp"] = Number(item["mrp"]);
            if(!item["status"].includes("Delivered")) {
                item["total_amount_paid"] = 0;
            } else {
                item["total_amount_paid"] = Number(item["total_amount_paid"]);
            }
            orderDetailsSummary.push([item.order_number,item.driver_id,item.status,item.total_amt,item.total_amount_paid,item.retailer_id,item.reason,item.payment_mode,item.priority,item.delivery_date])
        })
        const orderSummaryData = XLSX.utils.aoa_to_sheet(orderDetailsSummary)
        XLSX.utils.book_append_sheet(wb, orderSummaryData, `Order Summary`)
        XLSX.writeFile(wb, `Order_Summary.xlsx`)
        setExportData(false)
        setBackDrop(false)


    }

    useEffect(() => {
    if(exportData)
    exportOrderSummary()
    }, [exportData])


    useEffect(() => {
    if(sortValue){
    console.log(sortingListRef.current.value)
    }
    }, [sortValue])


    return (
        <div>
            <Navbar className="bg-light mr-auto justify-content-between" >
                <Form inline>
                <InputGroup size="sm" style={{ marginLeft: 10, marginRight: 20 }}>
                    <InputGroupAddon addonType="prepend">Select Start Date</InputGroupAddon>
                    <DatePicker
                        selected={startDate}
                        onChange={(val) => { setStartDate(val) }}
                        minDate={new Date("2017/01/01")}
                        maxDate={new Date()}
                        aria-describedby="basic-addon1"
                        dateFormat="dd/MM/yyyy"

                    />
                </InputGroup>
                <InputGroup size="sm" style={{ marginLeft: 10, marginRight: 20 }}>
                    <InputGroupAddon addonType="prepend">Select End Date</InputGroupAddon>
                    <DatePicker
                        selected={endDate}
                        onChange={(val) => { setEndDate(val) }}
                        minDate={new Date("2017/01/01")}
                        maxDate={new Date()}
                        aria-describedby="basic-addon1"
                        dateFormat="dd/MM/yyyy"

                    />
                </InputGroup>
                <Button size="sm" onClick={() => {setOrderSearch(true);setBackDrop(true)}}>Search Orders</Button>
                <InputGroup size="sm" style={{ marginLeft: 150, marginRight: 100 }}>
                    <InputGroupAddon addonType="prepend">Select Order Status</InputGroupAddon>
                    <DropdownButton size="sm"
                        as={ButtonGroup}
                        key={"right"}
                        drop={"down"}
                        variant={"outline-primary"}
                        title={orderStatus?orderStatus:"All"}
                    >
                        <Dropdown.Item eventKey="1" name={'Delivered'} onClick={(e) => { setOrderStatus(e.target.name) }} >Delivered</Dropdown.Item>
                        <Dropdown.Item eventKey="2" name={'Partially-Delivered'} onClick={(e) => { setOrderStatus(e.target.name) }} >Partial Delivered</Dropdown.Item>
                        <Dropdown.Item eventKey="3" name={'Cancelled'} onClick={(e) => { setOrderStatus(e.target.name) }} >Cancelled</Dropdown.Item>
                        <Dropdown.Item eventKey="4" name={'shop closed'} onClick={(e) => { setOrderStatus(e.target.name) }} >Shop Closed</Dropdown.Item>
                        <Dropdown.Item eventKey="5" name={'not visited'} onClick={(e) => { setOrderStatus(e.target.name) }} >Not Visited</Dropdown.Item>
                        <Dropdown.Item eventKey="6" name={'Hold'} onClick={(e) => { setOrderStatus(e.target.name) }} >Hold</Dropdown.Item>
                        <Dropdown.Item eventKey="7" name={'processed'} onClick={(e) => { setOrderStatus(e.target.name) }} >Processed</Dropdown.Item>
                        <Dropdown.Item eventKey="8" name={'Pending'} onClick={(e) => { setOrderStatus(e.target.name) }} >Pending</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item eventKey="9" name={'reset'} onClick={(e) => { setOrderStatus(e.target.name) }} >Reset</Dropdown.Item>
                    </DropdownButton>
            </InputGroup>
            <InputGroup size="sm" style={{ marginLeft: 30, marginRight: 50 }}>
                    <Button size="sm" onClick={()=>setExportData(true)}>Export</Button>
                </InputGroup>
            </Form>
            </Navbar>
            {orderDetails.length>0 && orderDetails.length<1000 &&
            <Jumbotron>
                <Table>
                    <thead>
                        <tr>
                            <td value={"Order Number"} ref = {sortingListRef} onClick={(e)=>setSortValue(e.currentTarget)}>Order Number</td>
                            <td value={"Driver Id"} ref = {sortingListRef} onClick={(e)=>setSortValue(e.currentTarget)}>Driver Id</td>
                            <td value={"Status"} ref = {sortingListRef} onClick={(e)=>setSortValue(e.currentTarget)}>Status</td>
                            <td value={"Total Amount"} ref = {sortingListRef} onClick={(e)=>setSortValue(e.currentTarget)}>Total Amount</td>
                            <td value={"Total Amount Paid"} ref = {sortingListRef} onClick={(e)=>setSortValue(e.currentTarget)}>Total Amount Paid</td>
                            <td value={"Retailer ID"} ref = {sortingListRef} onClick={(e)=>setSortValue(e.currentTarget)}>Retailer ID</td>
                            <td value={"Reason"} ref = {sortingListRef} onClick={(e)=>setSortValue(e.currentTarget)}>Reason</td>
                            <td value={"Payment Mode"} ref = {sortingListRef} onClick={(e)=>setSortValue(e.currentTarget)}>Payment Mode</td>
                            <td value={"Priority"} ref = {sortingListRef} onClick={(e)=>setSortValue(e.currentTarget)}>Priority</td>
                            <td value={"Delivery Date"} ref = {sortingListRef} onClick={(e)=>setSortValue(e.currentTarget)}>Delivery Date</td>
                        </tr>
                    </thead>
                    <tbody>
                {orderDetails.length>0 && orderDetails.length<1000 && orderDetails.map(items=>(
                    <tr>
                        <td><center>{items.order_number}</center></td>
                        <td><center>{items.driver_id}</center></td>
                        <td><center>{items.status}</center></td>
                        <td><center>{items.total_amt}</center></td>
                        <td><center>{items.total_amount_paid}</center></td>
                        <td><center>{items.retailer_id}</center></td>
                        <td><center>{items.reason?items.reason:""}</center></td>
                        <td><center>{items.payment_mode}</center></td>
                        <td><center>{items.priority}</center></td>
                        <td><center>{items.delivery_date}</center></td>
                    </tr>
                ))}
                </tbody>
                </Table>
            </Jumbotron>
            }
            {getBackDrop?(<Backdrop parentLoadStatus={getBackDrop}/>):(null)}
        </div>
    )
}

export default OrderSummaryPage
