import React, { useMemo } from "react";
import { useTable, useGlobalFilter, useFilters } from "react-table";
import styled from "styled-components";

const TableStyles = styled.table`
  font-size: 0.9rem;
  border-spacing: 0;
  border: 1px solid black;
  width: 96vw;
  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }

  th,
  td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid black;
    border-right: 1px solid black;

    :last-child {
      border-right: 0;
    }
  }
  tfoot {
    tr:first-child {
      td {
        border-top: 2px solid red;
      }
    }
    font-weight: bolder;

    td {
      color: #0010ff;
    }
  }
`;

const SearchContainer = styled.div`
  margin-bottom: 20px;
  width: 31%;
`;

const Search = styled.input`
  width: 100%;
`;

const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <SearchContainer>
      <Search
        value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}
        placeholder="Search"
      />{" "}
    </SearchContainer>
  );
};

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows

  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      if (row.values[id] && row.values[id] !== "") options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

export const ItemInventoryTable = ({
  tableData: data,
  warehouse,
  parentWarehouseColumns,
  childWarehouseColumns,
  goaWarehouseColumns,
  allWarehouseColumns,
}) => {
  let columns = useMemo(() => {
    if (warehouse === "Bhiwandi") {
      return parentWarehouseColumns;
    } else if (warehouse === "All") {
      return allWarehouseColumns;
    } else if (warehouse === "Goa") {
      return goaWarehouseColumns;
    } else {
      return childWarehouseColumns;
    }
  }, [warehouse]);

  //!Due to an issue with the react-table library, Select filter does not work without this dummmy function.
  //!Also this is being passed to useTable
  const defaultColumn = React.useMemo(
    () => ({
      Filter: () => null,
    }),
    []
  );

  // Use the state and functions returned from useTable to build your UI

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    preGlobalFilteredRows,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      autoResetFilters: true,
      autoResetHiddenColumns: true,
      autoResetPage: true,
    },
    useFilters,
    useGlobalFilter
  );

  const { globalFilter } = state;

  // Render the UI for your table
  return (
    <>
      <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />

      {/* //Apply the table props */}
      <TableStyles style={{ width: "100%" }} {...getTableProps()}>
        <thead>
          {/* // Loop over the header rows */}
          {headerGroups.map((headerGroup) => (
            // Apply the header row props
            <tr {...headerGroup.getHeaderGroupProps()}>
              {/* // Loop over the headers in each row */}
              {headerGroup.headers.map((column) => (
                // Apply the header cell props
                <th
                  style={{ backgroundColor: column.bgColor || "white" }}
                  {...column.getHeaderProps()}
                >
                  {/* // Render the header */}

                  {column.render("Header")}

                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {/*  Apply the table body props  */}
        <tbody {...getTableBodyProps()}>
          {/* // Loop over the table rows */}
          {rows.map((row, i) => {
            // Prepare the row for display
            prepareRow(row);

            return (
              // Apply the row props
              <tr {...row.getRowProps()}>
                {/* // Loop over  the rows cells */}
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")} </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          {footerGroups.map((group) => (
            <tr {...group.getFooterGroupProps()}>
              {group.headers.map((column) => (
                <td {...column.getFooterProps()}>{column.render("Footer")}</td>
              ))}
            </tr>
          ))}
        </tfoot>
      </TableStyles>
    </>
  );
};
