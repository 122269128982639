import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from '@mui/material';
import { service_url } from "../../Configs/mysqlconfig";
import MessageModal from './messageModal'

const OrderModal = ({ open, handleClose, orderNumber, previousAmount,onUpdateSuccess }) => {
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [messageModalTitle, setMessageModalTitle] = useState("Processing...");
  const [messageModalMsg, setMessageModalMsg] = useState("");
  const [amount, setAmount] = useState(previousAmount);
  const [date, setDate] = useState('');
  const [submit, setSubmit] = useState(false);

  useEffect(() => {
    setAmount(previousAmount);
  }, [previousAmount]);

  useEffect(()=>
  {
    const epayAmountAndPaymentReceivedUpdate = async () => {
        
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "order_number": orderNumber,
            "amount": amount,
            "payment_received_date": date
          });

        const requestOptions = {
            method: "PUT",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
          };


        try {
            const response = await fetch(`${service_url}/api/superzop/epay/epay-payment-details`, 
            requestOptions);
            const data = await response.json();
            const requiredResponse = data.success
            console.log(requiredResponse===1)
            if (requiredResponse===1)
            {
            setMessageModalTitle('Success')
            setMessageModalMsg("Successfully updated the value")
            // onUpdateSuccess();
          }
          else{
            setMessageModalTitle('Error!')
            setMessageModalMsg("Updation was not successful check the API response")
          }
        } catch (error) {
            console.error('Error fetching data:', error);
            setMessageModalTitle('Error!')
            setMessageModalMsg(`Runtime error occured ${error}`)
            setMessageModalOpen(true)
        }
    };

          if (submit) {

          if(!date || date===''){
            setMessageModalTitle('Error!')
            setMessageModalMsg("Please enter date")
            setMessageModalOpen(true)
            return
            }

          if(!amount || amount===''){
            setMessageModalTitle('Error!')
            setMessageModalMsg("Please enter amount")
            setMessageModalOpen(true)
            return
            }

              epayAmountAndPaymentReceivedUpdate();
              setSubmit(false)
              setMessageModalOpen(true)
            }


  },[submit])

  const handleSubmit = () => {
    setSubmit(true);
    handleClose();
  };  

  return (
    <>
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Order Details</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Order Number"
          type="text"
          fullWidth
          value={orderNumber}
          disabled
          InputProps={{
            readOnly: true,
          }}
          variant="outlined"
        />

        {/* {previousAmount && <TextField
          autoFocus
          margin="dense"
          label="Previous Amount"
          type="text"
          fullWidth
          value={previousAmount}
          disabled
          InputProps={{
            readOnly: true,
          }}
          variant="outlined"
        />} */}

        <TextField
          margin="dense"
          label="Enter the amount"
          type="number"
          fullWidth
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          variant="outlined"
          required
          error={!amount}
          helperText={!amount && '* Amount is a required field'}
        />
        <TextField
          margin="dense"
          label="Select Payment Received Date"
          type="date"
          fullWidth
          value={date}
          onChange={(e) => setDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            max: new Date().toISOString().split("T")[0], 
          }}
          variant="outlined"
          required
          error={!date}
          helperText={!date && '* Date is a required field'}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>

    <MessageModal
    onOpen={messageModalOpen}
    onCloseFunction={() => {setMessageModalOpen(false); setMessageModalMsg('');setMessageModalTitle('');}}
    message={messageModalMsg}
    title={messageModalTitle}
    onApproveSuccess = {onUpdateSuccess}
    />
    </>

  );
};

export default OrderModal;