import React, { useState, useRef, useEffect } from "react";
import fire from "../../Configs/firebase";
import {
  Map,
  GoogleApiWrapper,
  Polyline,
  Marker,
  InfoWindow,
  Circle,
} from "google-maps-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import Geocode from "react-geocode";
import FooterComponent from "../Footer/footer";

let wareHouseLatLng = { lat: 19.070279, lng: 73.005516 };
let wareHouseLatLongDict = {
  surat: { lat: 21.1966092, lng: 72.8545132 },
  pune: { lat: 18.5247663, lng: 73.7929267 },
  nashik: { lat: 19.9910547, lng: 73.5163678 },
  bhiwandi: { lat: 19.2818569, lng: 73.0264525 },
  wadala: { lat: 19.0139451, lng: 72.8524243 },
  virar: { lat: 19.4796228, lng: 72.896112 },
  vashi: { lat: 19.0617039, lng: 72.9734505 },
  andheri: { lat: 19.23531669919817, lng: 73.03272326848142 },
};
Geocode.setApiKey("AIzaSyBhWjIBx_EM-hyCJlg2zbfoYpyaxmPHmKs");

const AllVehicleMapOrders = ({ mapOrdersData, routeDate, ...props }) => {
  const selectedWarehouse = props.selectedWarehouse || "";

  const [mapCenter, setMapCenter] = useState(
    wareHouseLatLongDict[selectedWarehouse.toLowerCase()] || wareHouseLatLng
  );
  const [mapZoom, setMapZoom] = useState(10);
  const [activeMarker, setActiveMarker] = useState({});
  const [isInfoWindowOrderOpened, setIsInfoWindowOrderOpened] = useState(false);
  const [mapOrderDetails, setMapOrderDetails] = useState({});
  const [vehicleChecked, setVehicleChecked] = useState({});
  const [mapMarkers, setMapMarkers] = useState([]);
  const [initialMapMarkers, setInitialMapMarkers] = useState(true);
  const [colors, setColors] = useState([
    "aqua",
    "blue",
    "brown",
    "coral",
    "fuchsia",
    "gray",
    "green",
    "lime",
    "maroon",
    "mustard",
    "navy",
    "olive",
    "orange",
    "peach",
    "pink",
    "purple",
    "red",
    "silver",
    "teal",
    "yellow",
  ]);

  const checkRef = useRef();

  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });

  const generateCheckedRouteMarkers = () => {
    let markers = [];
    // let colors = ['aqua', 'blue', 'brown', 'coral', 'fuchsia', 'gray', 'green', 'lime', 'maroon','mustard', 'navy', 'olive', 'orange','peach','pink', 'purple', 'red', 'silver', 'teal', 'yellow'];
    console.log(vehicleChecked);
    let vehicleSelection = document.getElementsByClassName(
      "bulkVehicleSelection"
    );
    console.log(vehicleSelection);
    for (let i = 0; i < mapOrdersData.length; i++) {
      let route = mapOrdersData[i]["route"];
      let orderdetails = mapOrdersData[i]["orderdetails"];
      let vehicleChecked = vehicleSelection[i].checked;
      console.log(vehicleChecked);
      for (let j = 0; j < orderdetails.length; j++) {
        let latitude = orderdetails[j]["latitude"];
        let longitude = orderdetails[j]["longitude"];

        if (vehicleChecked == true) {
          markers.push(
            <Marker
              key={orderdetails[j]["order_number"]}
              icon={pinSymbol(colors[i % 19])}
              position={{ lat: Number(latitude), lng: Number(longitude) }}
              label={`${orderdetails[j]["retailer_id"]}`}
              orderInfo={orderdetails[j]}
              onClick={markerInfoShow}
            ></Marker>
          );
        } else {
          // markers.push(<Marker key={orderdetails[j]['order_number']}
          // map={null}
          // icon={pinSymbol(colors[i%14])}
          // position={{lat:Number(latitude),lng:Number(longitude)}}
          // label={`${orderdetails[j]['retailer_id']}`} orderInfo={orderdetails[j]} onClick={markerInfoShow}>
          // </Marker>)
        }
      }
    }
    // setMapMarkers(markers);
    return markers;
  };

  const generateShopMarkers = () => {
    let markers = [];
    // let colors = ['aqua', 'blue', 'brown', 'coral', 'fuchsia', 'gray', 'green', 'lime', 'maroon','mustard', 'navy', 'olive', 'orange','peach','pink', 'purple', 'red', 'silver', 'teal', 'yellow'];

    for (let i = 0; i < mapOrdersData.length; i++) {
      let route = mapOrdersData[i]["route"];
      let orderdetails = mapOrdersData[i]["orderdetails"];
      for (let j = 0; j < orderdetails.length; j++) {
        let latitude = orderdetails[j]["latitude"];
        let longitude = orderdetails[j]["longitude"];
        markers.push(
          <Marker
            key={orderdetails[j]["order_number"]}
            icon={pinSymbol(colors[i % 19])}
            position={{ lat: Number(latitude), lng: Number(longitude) }}
            label={`${orderdetails[j]["retailer_id"]}`}
            orderInfo={orderdetails[j]}
            onClick={markerInfoShow}
          ></Marker>
        );
      }
    }
    // setMapMarkers(markers);
    return markers;
  };

  const pinSymbol = (color) => {
    return {
      path: "M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z",
      fillColor: color,
      fillOpacity: 1,
      strokeColor: "#000",
      strokeWeight: 2,
      scale: 1,
    };
  };

  const MapVehiclesTableHeader = () => {
    let isVehicleChecked =
      initialMapMarkers == true ? true : checkRef.current.checked;
    return (
      <thead className="thead-light">
        <tr>
          <th>Route</th>
          <th>
            <input
              type="checkbox"
              id="selectAllCheckbox"
              defaultChecked={isVehicleChecked}
              ref={checkRef}
              onChange={() => {
                let bulkOrdersChecks = document.getElementsByClassName(
                  "bulkVehicleSelection"
                );
                for (let i = 0; i < bulkOrdersChecks.length; i++) {
                  if (checkRef.current.checked) {
                    bulkOrdersChecks[i].checked = true;
                  } else {
                    bulkOrdersChecks[i].checked = false;
                  }
                }
                setInitialMapMarkers(false);
                updateCheckedVehicles(checkRef.current.checked);
              }}
            />
          </th>
        </tr>
      </thead>
    );
  };

  const updateCheckedVehicles = (isVehChecked) => {
    console.log("updateCheckedVehicles");
    console.log(isVehChecked);

    let vehObj = {};
    mapOrdersData.map((shp, index) => {
      let route = shp["route"];
      vehObj[`${route}`] = isVehChecked;
    });
    // console.log(vehObj);
    setVehicleChecked(vehObj);
  };

  const MapVehiclesTableBody = () => {
    // let colors = ['aqua', 'blue', 'brown', 'coral', 'fuchsia', 'gray', 'green', 'lime', 'maroon','mustard', 'navy', 'olive', 'orange','peach','pink', 'purple', 'red', 'silver', 'teal', 'yellow'];
    let rows = [];
    // console.log(vehicleChecked);

    if (mapOrdersData.length > 0) {
      rows = mapOrdersData.map((shp, index) => {
        let current_order_color = colors[index % 19].toLowerCase();
        let route = shp["route"];
        // console.log(current_order_color);
        let isVehicleChecked =
          initialMapMarkers == true
            ? true
            : vehicleChecked[route] !== undefined
            ? vehicleChecked[route]
            : true;
        // console.log(isVehicleChecked);

        return (
          <tr key={index}>
            <td>
              <FontAwesomeIcon
                icon={faCircle}
                style={{
                  fontSize: "10px",
                  verticalAlign: "middle",
                  color: current_order_color,
                }}
              />
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                }}
              >
                {" "}
                {shp.route}{" "}
              </a>
            </td>
            <td>
              <input
                type="checkbox"
                name={shp.route}
                className="bulkVehicleSelection"
                defaultChecked={isVehicleChecked}
                onClick={(e) => {
                  console.log(e.target.checked);
                  console.log(shp.route);
                  setInitialMapMarkers(false);
                  setVehicleChecked({
                    ...vehicleChecked,
                    [shp.route]: e.target.checked,
                  });
                }}
              />
            </td>
          </tr>
        );
      });
    } else {
      rows = [];
    }

    return (
      <>
        <tbody>{rows}</tbody>
      </>
    );
  };

  const markerInfoShow = (props, marker, e) => {
    setMapOrderDetails(marker.orderInfo);
    setActiveMarker(marker);
    setIsInfoWindowOrderOpened(true);
  };

  const handleOrderMarkerClose = () => {
    setMapOrderDetails({});
    setActiveMarker({});
    setIsInfoWindowOrderOpened(false);
  };

  return (
    <>
      {mapOrdersData.length === 0 ? (
        "No data available to show"
      ) : (
        <div className="row" style={{ marginTop: 10, marginRight: 0 }}>
          <div className="col-md-9" style={{ marginLeft: 10 }}>
            <Map
              google={props.google}
              zoom={mapZoom}
              initialCenter={mapCenter}
              center={mapCenter}
              style={{ width: "100%", height: "80vh" }}
            >
              {mapOrdersData.length > 0
                ? initialMapMarkers == true
                  ? generateShopMarkers()
                  : generateCheckedRouteMarkers()
                : null}
              <InfoWindow
                marker={activeMarker}
                visible={isInfoWindowOrderOpened}
                onCloseClick={handleOrderMarkerClose}
              >
                {mapOrderDetails ? (
                  <div>
                    <h6>OrderNumber : {mapOrderDetails.order_number}</h6>
                    <h6>Shop : {mapOrderDetails.shop_name}</h6>
                    <h6>Status : {mapOrderDetails.status}</h6>
                    <h6>
                      Vehicle : {mapOrderDetails.route} [
                      {mapOrderDetails.priority}]
                    </h6>
                  </div>
                ) : (
                  <div>
                    <h6>Loading</h6>
                  </div>
                )}
              </InfoWindow>
            </Map>
          </div>
          <div className="col-md-2" style={{ marginLeft: 10 }}>
            <div className="table-wrapper" style={{ paddingBottom: 60 }}>
              <table className="table table-striped">
                <MapVehiclesTableHeader />
                <MapVehiclesTableBody />
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyBhWjIBx_EM-hyCJlg2zbfoYpyaxmPHmKs",
})(AllVehicleMapOrders);
