import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useTable, useGlobalFilter, useFilters } from "react-table";
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";
import FooterComponent from "../Footer/footer";
import fire from "../../Configs/firebase";
import NavBar from "../Navigation bar/Navbar";
import { Row, Col, InputGroup, Button } from "react-bootstrap";
import { InputGroupAddon } from "reactstrap";
import StatusDetailsModal from "./StatusDetailsModal";
import UnDeliveredOrdersModal from "./UndeliveredOrdersModal";
import ExportToExcel from "./ExportToExcel";
import { findIfDriverVisitedLocationVicinity } from "./utils";
import VehicleStatusModal from "./VehicleStatusModal";
import { Link } from "react-router-dom";

const Styles = styled.div`
  padding: 1rem;
  width: 100vw;

  table {
    border-spacing: 0;
    border: 1px solid black;
    width: 96vw;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }

  .summary_header {
    font-size: 1.5rem;
  }
  .loading_text {
    font-size: 1.2rem;
  }
  .export_btn {
    margin-left: 20px;
  }
  .search_container {
    margin-bottom: 20px;
  }
  .search_bar {
    width: 31%;
  }
`;

const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <div className="search_container">
      <input
        className="search_bar"
        value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}
        placeholder="Search"
      />{" "}
    </div>
  );
};

//Main component
const DeliverySummary = (props) => {
  const [tableData, setTableData] = useState([]);
  const [vehicleLocRaw, setVehicleLocRaw] = useState({});
  const [summaryHeaderDate, setSummaryHeaderDate] = useState("");
  const [summaryDate, setSummaryDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showStatusDetailsModal, setShowStatusDetailsModal] = useState(false);
  const [showUndeliveredOrdersModal, setShowUndeliveredOrdersModal] =
    useState(false);
  const [showVehicleStatusModal, setShowVehicleStatusModal] = useState(false);
  const [statusDetailsModalRouteId, setStatusDetailsModalRouteId] =
    useState("");
  const [statusDetailsModalStatus, setStatusDetailsModalStatus] = useState("");
  const [statusDetailsModalLocData, setStatusDetailsModalLocData] =
    useState("");
  const [ordersByStatus, setOrdersByStatus] = useState({});
  const [ordersHistoryRaw, setOrdersHistoryRaw] = useState({});
  const [allocationHistoryRaw, setAllocationHistoryRaw] = useState({});
  const [cityListRaw, setCityListRaw] = useState({});

  const [statusDetailsModalData, setStatusDetailsModalData] = useState({});
  const [
    showStatusDetailsModalProximityCol,
    setShowStatusDetailsModalProximityCol,
  ] = useState(false);
  const [allPendingCancelledOrders, setAllPendingCancelledOrders] = useState(
    []
  );
  const isAdminUser = sessionStorage.getItem("originalUserType") === "admin";

  const getInitTotalCount = () => {
    return {
      route_no: "TOTAL ",
      total_planned: 0,
      delivered: 0,
      shipped_orders: 0,
      pending_orders: 0,
      cancelled_orders: 0,
      hold_orders: 0,
      not_attempted_orders: 0,
      not_visited_system_orders: 0,
      row_bg_color: "yellow",
      delivered_percentage: 0,
    };
  };

  const getTimeFromTimeStamp = (timestamp) => {
    if (!timestamp) {
      return "";
    }
    let date = new Date(Number(timestamp));
    return `${date.getHours()} :${date.getMinutes()} `;
  };
  const tableTotalStats = useRef(getInitTotalCount());

  function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    tableTotalStats.current = getInitTotalCount();

    // Calculate the options for filtering
    // using the preFilteredRows

    const options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        if (row.values[id] && row.values[id] !== "")
          options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
      <select
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  }

  const fetchOrdersHistory = (selectedDate) => {
    return new Promise((resolve, reject) => {
      let year = selectedDate.getFullYear();
      let month = selectedDate.getMonth() + 1;
      let date = selectedDate.getDate() + "-" + month + "-" + year;
      // console.log("DEBUG date", date);

      fire
        .database()
        .ref("Driver_Orders_History/" + year + "/" + month + "/" + date + "/")
        .once("value", (snapshot) => {
          if (snapshot.hasChildren()) {
            const snap_data = snapshot.val();
            // console.log("DEBUG setting orders history state", snap_data);
            resolve(snap_data);
          } else {
            reject();
          }
        });
    });
  };

  const fetchAllocationHistory = (selectedDate) => {
    return new Promise((resolve, reject) => {
      let year = selectedDate.getFullYear();
      let month = selectedDate.getMonth() + 1;
      let date = selectedDate.getDate() + "-" + month + "-" + year;

      fire
        .database()
        .ref(
          "Driver_Allocation_History/" + year + "/" + month + "/" + date + "/"
        )
        .once("value", (snapshot) => {
          if (snapshot.hasChildren()) {
            const snap_data = snapshot.val();
            // console.log("DEBUG setting allocation history state", snap_data);
            resolve(snap_data);
          } else {
            reject();
          }
        });
    });
  };

  const fetchVehicleLocation = (selectedDate) => {
    return new Promise((resolve, reject) => {
      let year = selectedDate.getFullYear();
      let month = selectedDate.getMonth() + 1;
      let date = selectedDate.getDate() + "-" + month + "-" + year;

      fire
        .database()
        .ref("Vehicle_Location/" + year + "/" + month + "/" + date + "/")
        .once("value", (snapshot) => {
          if (snapshot.hasChildren()) {
            const snap_data = snapshot.val();
            // console.log("DEBUG setting allocation history state", snap_data);
            resolve(snap_data);
          } else {
            resolve({});
          }
        });
    });
  };

  const fetchCityList = () => {
    return new Promise((resolve, reject) => {
      fire
        .database()
        .ref("Region_List")
        .once("value")
        .then((snapshot) => {
          const data = snapshot.val();
          // console.log("setting selected city to : ", cities[0].value);
          resolve(data);
        })
        .catch((err) => {
          console.log(
            "An error occured while attempting to fetch the city list",
            err
          );
        });
    });
  };

  /**
   *
   * @param {Number} numerator
   * @param {Number} denominator
   *
   * @return {String}
   * Returns the percentage, if denominator is 0 will function return N/A, else will return percentage with precision 3 along with % symbol
   */
  const getPercentage = (numerator, denominator) => {
    if (denominator === 0) {
      return "N/A";
    }
    return ((numerator / denominator) * 100).toPrecision(3) + "%";
  };

  const revisedAmt = (itemsInOrders, orderNo) => {

    let undeliveredItemAmt = 0
    let totalOrderValue
    Object.keys(itemsInOrders).forEach(orderNumber => {
      if (!(orderNumber.includes(`${orderNo.split("-")[0]}-${orderNo.split("-")[1]}`)))
        return;

      if((itemsInOrders[orderNumber].item_delivered_qty && itemsInOrders[orderNumber].mrp)||
        (itemsInOrders[orderNumber].item_modified_qty && itemsInOrders[orderNumber].mrp)) {
          if(itemsInOrders[orderNumber].status.toLowerCase().includes(`partially-delivered`) && itemsInOrders[orderNumber].item_delivered_qty) {
            // console.log( itemsInOrders[orderNumber].item_id+" ItemDeliveredQty is present::: "+itemsInOrders[orderNumber].order_qty+" - DeliveredQty:: "+itemsInOrders[orderNumber].item_delivered_qty)
            undeliveredItemAmt += (Number(itemsInOrders[orderNumber].mrp) / Number(itemsInOrders[orderNumber].order_qty)) * (Number(itemsInOrders[orderNumber].order_qty) - Number(itemsInOrders[orderNumber].item_delivered_qty))
          }
          else if ((itemsInOrders[orderNumber].status.toLowerCase().includes(`shipped`) || itemsInOrders[orderNumber].status.toLowerCase().includes(`partially-delivered`)) && itemsInOrders[orderNumber].item_modified_qty) {
            // console.log(itemsInOrders[orderNumber].item_id+" ItemModifiedQty is present::: "+itemsInOrders[orderNumber].order_qty+" - ModifiedQty:: "+itemsInOrders[orderNumber].item_modified_qty)
            undeliveredItemAmt += (Number(itemsInOrders[orderNumber].mrp) / Number(itemsInOrders[orderNumber].order_qty)) * (Number(itemsInOrders[orderNumber].order_qty) - Number(itemsInOrders[orderNumber].item_modified_qty))
          }
      }

      totalOrderValue = Number(itemsInOrders[orderNumber].total_amt);
    })

    if(!undeliveredItemAmt) {
      undeliveredItemAmt = 0
    }

    return (totalOrderValue - undeliveredItemAmt)
  }

  const populateTableData = (selectedDate) => {
    setIsLoading(true);
    const tableD = [];
    const ordersBasedOnStatus = {};

    Promise.all([
      fetchAllocationHistory(selectedDate),
      fetchOrdersHistory(selectedDate),
      fetchCityList(),
      fetchVehicleLocation(selectedDate),
    ])
      .then((values) => {
        const allocationHistory = values[0];
        const ordersHistory = values[1];
        const city_list = values[2];
        const locationList = values[3];
        let pending_cancelled_orders = [];

        // console.log(
        //   "DEBUG ready to run populate with data",
        //   allocationHistory,
        //   ordersHistory
        // );
        Object.keys(allocationHistory).forEach((key) => {
          const visitedOrders = [];
          // console.log(
          //   "iterating for ,",
          //   allocationHistory[key].current_Route_Id
          // );
          let rowD = {};
          let route_no = allocationHistory[key].current_Route_Id;
          let city = "";
          let region = "";
          let area = "";
          let vehicle_no = "";
          let transporter = "";
          let driver_name = "";
          let driver_no = "";
          let total_planned = 0;
          let status_delivered = 0;
          let status_shipped = 0;
          let status_pending = 0;
          let status_hold = 0;
          let status_cancelled = 0;
          let status_not_attempted = 0;
          let status_not_visited_system = 0;
          let delivered_percentage = 0;
          let driver_assigned_time = getTimeFromTimeStamp(
            allocationHistory[key].allocation_Time
          );
          let driver_reported_time =
            allocationHistory[key].DB_Return_Time || "";
          let driver_unloading_time =
            allocationHistory[key].DB_Release_Time || "";
          if (ordersHistory[route_no]) {
            //if data is present in ordersHistory table
            driver_name = allocationHistory[key].actual_Driver_Name || "";
            driver_no = allocationHistory[key].driver_Phone_Number || "";
            transporter = allocationHistory[key].company_type || "";
            vehicle_no = allocationHistory[key].vehicle_number || "";

            let orders = ordersHistory[route_no];

            ordersBasedOnStatus[route_no] = {
              pending_orders: [],
              cancelled_orders: [],
              hold_orders: [],
              not_attempted_orders: [],
              not_visited_system_orders: [],
              shipped_orders: [],
              delivered: [],
              total_planned: [],
            };

            Object.keys(orders).forEach((key) => {
              let orderId = key.split("-")[0] + "/" + key.split("-")[1];

              if (visitedOrders.includes(orderId)) {
                return;
              }

              visitedOrders.push(orderId);
              city = city_list[route_no.slice(0, 2)]?.city || "N/A";
              region = city_list[route_no.slice(0, 2)]?.region || "N/A";
              area = orders[key].area;

              let order_disc = Number(orders[key].order_disc) || 0;
              let revised_order_disc = Number(orders[key].revised_order_disc) || 0;
              orders[key].delivered_amt = revisedAmt(ordersHistory[route_no],key) + order_disc - revised_order_disc;

              total_planned++;
              ordersBasedOnStatus[route_no]["total_planned"].push(orders[key]);
              if (
                orders[key].status === "Delivered" ||
                orders[key].status === "Partially-Delivered"
              ) {
                status_delivered++;
                ordersBasedOnStatus[route_no]["delivered"].push(orders[key]);
              }

              if (
                orders[key].status === "Pending" &&
                orders[key].reason !== "Not Visited"
              ) {
                status_pending++;
                ordersBasedOnStatus[route_no]["pending_orders"].push(
                  orders[key]
                );

                let driver_visited_vicinity =
                  findIfDriverVisitedLocationVicinity(
                    [orders[key]],
                    locationList[driver_no] || {}
                  )[0];

                if (!driver_visited_vicinity.visited_proximity) {
                  status_not_visited_system++;
                  ordersBasedOnStatus[route_no][
                    "not_visited_system_orders"
                  ].push(orders[key]);
                }
                pending_cancelled_orders.push({
                  status: "Pending",
                  route_no,
                  driver_name,
                  driver_no,
                  transporter,
                  ...driver_visited_vicinity,
                });
              }
              if (orders[key].status === "Cancelled") {
                status_cancelled++;
                ordersBasedOnStatus[route_no]["cancelled_orders"].push(
                  orders[key]
                );

                let driver_visited_vicinity =
                  findIfDriverVisitedLocationVicinity(
                    [orders[key]],
                    locationList[driver_no] || {}
                  )[0];

                if (!driver_visited_vicinity.visited_proximity) {
                  status_not_visited_system++;
                  ordersBasedOnStatus[route_no][
                    "not_visited_system_orders"
                  ].push(orders[key]);
                }

                pending_cancelled_orders.push({
                  status: "Cancelled",
                  route_no,
                  driver_name,
                  driver_no,
                  transporter,
                  ...driver_visited_vicinity,
                });
              }
              if (orders[key].status === "Hold") {
                status_hold++;
                ordersBasedOnStatus[route_no]["hold_orders"].push(orders[key]);
              }
              if (
                orders[key].reason === "Not Visited" &&
                orders[key].status === "Pending"
              ) {
                status_not_attempted++;
                ordersBasedOnStatus[route_no]["not_attempted_orders"].push(
                  orders[key]
                );
              }
              if (
                orders[key].status === "Shipped" ||
                orders[key].status === "Shipped-Modified"
              ) {
                status_shipped++;
                ordersBasedOnStatus[route_no]["shipped_orders"].push(
                  orders[key]
                );
              }
            });
            delivered_percentage = getPercentage(
              status_delivered,
              total_planned
            );

            rowD = {
              route_no,
              city,
              region,
              area,
              vehicle_no,
              transporter,
              driver_name,
              driver_no,
              driver_assigned_time,
              driver_reported_time,
              driver_unloading_time,
              total_planned,
              delivered: status_delivered,
              shipped_orders: status_shipped,
              pending_orders: status_pending,
              cancelled_orders: status_cancelled,
              hold_orders: status_hold,
              not_attempted_orders: status_not_attempted,
              not_visited_system_orders: status_not_visited_system,
              delivered_percentage,
            };
            tableD.push(rowD);
          }
          // console.log("DEBUG final table", tableD);
        });

        tableD.sort(function (a, b) {
          var keyA = a.route_no,
            keyB = b.route_no;
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });

        setTableData(tableD);
        setAllPendingCancelledOrders(pending_cancelled_orders);
        setVehicleLocRaw(locationList);
        setOrdersHistoryRaw(ordersHistory);
        setAllocationHistoryRaw(allocationHistory);
        setCityListRaw(city_list);
        setOrdersByStatus(ordersBasedOnStatus);
        // console.log("ordersBasedOnStatus", ordersBasedOnStatus);
      })
      .catch((err) => {
        console.log(
          "Error occured while fetching data or no data to fetch",
          err
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getFormattedDate = (selectedDate) => {
    let year = selectedDate.getFullYear();
    let month = selectedDate.getMonth() + 1;
    let date = selectedDate.getDate() + "-" + month + "-" + year;
    return date;
  };

  const statusCountClicked = (
    event,
    route_no,
    status,
    formattedStatus,
    driver_phno
  ) => {
    if (status === "pending_orders" || status === "cancelled_orders") {
      setShowStatusDetailsModalProximityCol(true);
    } else {
      setShowStatusDetailsModalProximityCol(false);
    }
    setShowStatusDetailsModal(true);
    setStatusDetailsModalRouteId(route_no);
    setStatusDetailsModalStatus(formattedStatus);
    setStatusDetailsModalData(ordersByStatus[route_no][status] || []);
    setStatusDetailsModalLocData(vehicleLocRaw[driver_phno]);
  };

  useEffect(() => {
    let selectedDate;
    if (!sessionStorage.getItem("selectedDate")) {
      selectedDate = new Date();
    } else {
      selectedDate = new Date(sessionStorage.getItem("selectedDate"));
    }
    let date = getFormattedDate(selectedDate);
    setSummaryHeaderDate(date);
    setSummaryDate(selectedDate);
    populateTableData(selectedDate);
  }, []);

  useEffect(() => {
    if (summaryDate) {
      setTableData([]);
      tableTotalStats.current = getInitTotalCount();
      const selectedDate = summaryDate;
      let date = getFormattedDate(selectedDate);
      setSummaryHeaderDate(date);
      populateTableData(selectedDate);
    }
  }, [summaryDate]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Route No",
        accessor: "route_no",
      },
      {
        Header: "WareHouse",
        accessor: "city",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Area",
        accessor: "area",
      },
      {
        Header: "Vehicle No",
        accessor: "vehicle_no",
      },
      {
        Header: "Driver Name",
        accessor: "driver_name",
      },
      {
        Header: "Driver No",
        accessor: "driver_no",
      },
      {
        Header: "Transporter",
        accessor: "transporter",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Total planned",
        accessor: "total_planned",
      },
      {
        Header: "Total delivered",
        accessor: "delivered",
      },
      {
        Header: "Shipped",
        accessor: "shipped_orders",
      },
      {
        Header: "Pending",
        accessor: "pending_orders",
      },
      {
        Header: "Cancelled",
        accessor: "cancelled_orders",
      },
      {
        Header: "Hold",
        accessor: "hold_orders",
      },
      {
        Header: "Not visited",
        accessor: "not_attempted_orders",
      },
      {
        Header: "Pending/ Cancelled, Not visited (system)",
        accessor: "not_visited_system_orders",
      },
      {
        Header: "Percentage",
        accessor: "delivered_percentage",
      },
    ],
    []
  );

  const HeadersForCSV1 = React.useMemo(
    () => [
      {
        label: "Route No",
        value: "route_no",
      },
      {
        label: "Area",
        value: "area",
      },
      {
        label: "Vehicle No",
        value: "vehicle_no",
      },
      {
        label: "Driver Name",
        value: "driver_name",
      },
      {
        label: "Driver No",
        value: "driver_no",
      },
      {
        label: "Assigned Time",
        value: "driver_assigned_time",
      },
      {
        label: "Reported Time",
        value: "driver_reported_time",
      },
      {
        label: "Unloading Time",
        value: "driver_unloading_time",
      },
      {
        label: "Transporter",
        value: "transporter",
      },
      {
        label: "Total delivery planned",
        value: "total_planned",
      },
      {
        label: "Total delivered",
        value: "delivered",
      },
      {
        label: "Pending",
        value: "pending_orders",
      },
      {
        label: "Cancelled",
        value: "cancelled_orders",
      },
      {
        label: "Hold",
        value: "hold_orders",
      },
      {
        label: "Not visited",
        value: "not_attempted_orders",
      },
      {
        label: "Percentage",
        value: "delivered_percentage",
      },
    ],
    []
  );
  const HeadersForCSV2 = React.useMemo(
    () => [
      {
        label: "Status",
      },
      {
        label: "Route Id",
      },
      {
        label: "Driver Name",
      },
      {
        label: "Driver No",
      },
      {
        label: "Transporter",
      },
      {
        label: "Store",
      },
      {
        label: "Area",
      },
      {
        label: "Order no",
      },
      {
        label: "Order date",
      },
      {
        label: "Order Amount",
      },
      {
        label: "Driver visited retailer",
      },
    ],
    []
  );

  return (
    <>
      <NavBar />
      {showVehicleStatusModal ? (
        <VehicleStatusModal
          show={showVehicleStatusModal}
          setShow={setShowVehicleStatusModal}
          data={tableData}
          allocationHistoryRaw={allocationHistoryRaw}
          ordersHistoryRaw={ordersHistoryRaw}
          cityList={cityListRaw}
        />
      ) : (
        ""
      )}
      {showUndeliveredOrdersModal ? (
        <UnDeliveredOrdersModal
          show={showUndeliveredOrdersModal}
          setShow={setShowUndeliveredOrdersModal}
          allPendingCancelledOrders={allPendingCancelledOrders}
        />
      ) : (
        ""
      )}
      {showStatusDetailsModal ? (
        <StatusDetailsModal
          show={showStatusDetailsModal}
          showDriverProximityCol={showStatusDetailsModalProximityCol}
          setShow={setShowStatusDetailsModal}
          data={statusDetailsModalData}
          vecLocData={statusDetailsModalLocData}
          routeId={statusDetailsModalRouteId}
          status={statusDetailsModalStatus}
        />
      ) : (
        ""
      )}
      <br />
      <br />
      <br />
      <br />
      {isAdminUser ? (
        <InputGroup size="sm" style={{ marginLeft: 20 }}>
          <InputGroupAddon addonType="prepend">Delivery Date</InputGroupAddon>
          <DatePicker
            dateFormat={"dd/MM/yyyy"}
            selected={summaryDate}
            onChange={setSummaryDate}
            minDate={new Date("2017/01/01")}
          />
        </InputGroup>
      ) : (
        ""
      )}
      {!isLoading ? (
        <>
  
          <Styles>
            <Row>
              <Col>
                <p style={{ fontWeight: "bold" }} className="summary_header">
                  Showing delivery summary <br /> for : {summaryHeaderDate}
                </p>
              </Col>
              <Col>
          
              <Link to = {"un-delivered-orders"} >
                <Button>
                    Show Pending/ Cancelled orders
                </Button>
              </Link>

              {/* 
                {/* <Button
                  variant="danger"
                  onClick={() => { setShowUndeliveredOrdersModal(true);   }} >
                  Show Pending/ Cancelled orders
                </Button> 
              */}

              
            
        
              </Col>
              <Col>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setShowVehicleStatusModal(true);
                  }}
                >
                  Show vehicle status
                </Button>
              </Col>
              {isAdminUser ? (
                <Col style={{ textAlign: "end", marginRight: "30px" }}>
                  {" "}
                  <ExportToExcel
                    sheet1={tableData}
                    sheet2={allPendingCancelledOrders}
                    filename={"delivery_summary"}
                    sheet1Headers={HeadersForCSV1}
                    sheet2Headers={HeadersForCSV2}
                    sheet1Name={"delivery summary"}
                    sheet2Name={"undelivered orders"}
                  />
                  {/* <CSVLink
                    className="btn btn-warning export_btn"
                    filename={"deliver_summary.csv"}
                    headers={HeadersForCSV}
                    data={tableData}
                  >
                    Export data
                  </CSVLink> */}
                </Col>
              ) : (
                ""
              )}
            </Row>

            <Table
              columns={columns}
              data={tableData}
              tableTotalStats={tableTotalStats}
              statusCountClicked={statusCountClicked}
              getPercentage={getPercentage}
            />
          </Styles>
        </>
      ) : (
        <Styles>
          <p className="loading_text">Loading ...</p>
        </Styles>
      )}{" "}
      <br />
      <br />
      <br />
      <FooterComponent />
    </>
  );
};

const Table = ({
  columns,
  data,
  tableTotalStats,
  getPercentage,
  statusCountClicked,
}) => {
  //!Due to an issue with the react-table library, Select filter does not work without this dummmy function.
  //!Also this is being passed to useTable
  const defaultColumn = React.useMemo(
    () => ({
      Filter: () => null,
    }),
    []
  );

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    preGlobalFilteredRows,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      autoResetFilters: true,
      autoResetHiddenColumns: true,
      autoResetPage: true,
    },
    useFilters,
    useGlobalFilter
  );

  const { globalFilter } = state;

  // Render the UI for your table
  return (
    <>
      <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />

      {/* //Apply the table props */}
      <table style={{ fontSize: ".85rem" }} {...getTableProps()}>
        <thead>
          {/* // Loop over the header rows */}
          {headerGroups.map((headerGroup) => (
            // Apply the header row props
            <tr {...headerGroup.getHeaderGroupProps()}>
              {/* // Loop over the headers in each row */}
              {headerGroup.headers.map((column) => (
                // Apply the header cell props
                <th {...column.getHeaderProps()}>
                  {/* // Render the header */}
                  {column.render("Header")}
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {/*  Apply the table body props  */}
        <tbody {...getTableBodyProps()}>
          {/* // Loop over the table rows */}
          {rows.map((row, i) => {
            // Prepare the row for display
            prepareRow(row);
            tableTotalStats.current.total_planned += row.values.total_planned;

            tableTotalStats.current.delivered += row.values.delivered;

            tableTotalStats.current.pending_orders += row.values.pending_orders;

            tableTotalStats.current.cancelled_orders +=
              row.values.cancelled_orders;

            tableTotalStats.current.hold_orders += row.values.hold_orders;

            tableTotalStats.current.not_attempted_orders +=
              row.values.not_attempted_orders;

            tableTotalStats.current.not_visited_system_orders +=
              row.values.not_visited_system_orders;

            tableTotalStats.current.shipped_orders += row.values.shipped_orders;

            return (
              // Apply the row props
              <tr
                style={{
                  backgroundColor: row.original.row_bg_color || "white",
                }}
                {...row.getRowProps()}
              >
                {/* // Loop over the rows cells */}
                {row.cells.map((cell) => {
                  if (
                    cell.column.id === "shipped_orders" ||
                    cell.column.id === "cancelled_orders" ||
                    cell.column.id === "pending_orders" ||
                    cell.column.id === "hold_orders" ||
                    cell.column.id === "not_attempted_orders" ||
                    cell.column.id === "not_visited_system_orders" ||
                    cell.column.id === "delivered" ||
                    cell.column.id === "total_planned"
                  ) {
                    return (
                      <td
                        aria-describedby="status_count_tooltip"
                        {...cell.getCellProps()}
                      >
                        <div hidden role="tooltip" id="status_count_tooltip">
                          Click to view the order list
                        </div>
                        <div
                          className="status_count"
                          onClick={(e) => {
                            statusCountClicked(
                              e,
                              cell.row.values.route_no,
                              cell.column.id,
                              cell.column.Header,
                              cell.row.values.driver_no
                            );
                          }}
                          style={{ color: "blue", fontWeight: "bold" }}
                        >
                          {cell.render("Cell")}{" "}
                        </div>
                        {/* // Render the cell contents */}
                      </td>
                    );
                  }
                  // Apply the cell props
                  return (
                    <td {...cell.getCellProps()}>
                      {/* // Render the cell contents */}
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
          <tr style={{ backgroundColor: "yellow" }}>
            <td>TOTAL </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td>{tableTotalStats.current.total_planned}</td>
            <td>{tableTotalStats.current.delivered}</td>
            <td>{tableTotalStats.current.shipped_orders}</td>
            <td>{tableTotalStats.current.pending_orders}</td>
            <td>{tableTotalStats.current.cancelled_orders}</td>
            <td>{tableTotalStats.current.hold_orders}</td>
            <td>{tableTotalStats.current.not_attempted_orders}</td>
            <td>{tableTotalStats.current.not_visited_system_orders}</td>
            <td>
              {getPercentage(
                tableTotalStats.current.delivered,
                tableTotalStats.current.total_planned
              )}
            </td>
          </tr>
          <tr style={{ backgroundColor: "yellow" }}>
            <td>PERCENT </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td>
              {getPercentage(
                tableTotalStats.current.total_planned,
                tableTotalStats.current.total_planned
              )}
            </td>
            <td>
              {getPercentage(
                tableTotalStats.current.delivered,
                tableTotalStats.current.total_planned
              )}
            </td>
            <td>
              {getPercentage(
                tableTotalStats.current.shipped_orders,
                tableTotalStats.current.total_planned
              )}
            </td>
            <td>
              {getPercentage(
                tableTotalStats.current.pending_orders,
                tableTotalStats.current.total_planned
              )}
            </td>
            <td>
              {getPercentage(
                tableTotalStats.current.cancelled_orders,
                tableTotalStats.current.total_planned
              )}
            </td>
            <td>
              {getPercentage(
                tableTotalStats.current.hold_orders,
                tableTotalStats.current.total_planned
              )}
            </td>
            <td>
              {getPercentage(
                tableTotalStats.current.not_attempted_orders,
                tableTotalStats.current.total_planned
              )}
            </td>
            <td>
              {getPercentage(
                tableTotalStats.current.not_visited_system_orders,
                tableTotalStats.current.total_planned
              )}
            </td>
            <td>{tableTotalStats.current.delivered_percentage}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default DeliverySummary;
