import React, { useState, useEffect, useRef } from 'react'
import { Table, Form, Alert, Button,Modal, InputGroup } from 'react-bootstrap';

function AllErrorsModal({ allErrorStatus, ...props}) {
  console.log(allErrorStatus);

  return (<>
          <Modal
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
          >
              <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                    Errors
                  </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ol type="1">
                  {allErrorStatus.map((errorStatus) => {
                    return (<li>{errorStatus}</li>)
                  })}
                </ol>

              </Modal.Body>
              <Modal.Footer>
                  <Button onClick={props.onHide}>Close</Button>
              </Modal.Footer>
          </Modal>
      </>
  )
}

export default AllErrorsModal
