import React, { useState, useEffect, useRef, forwardRef } from "react";
import {
  Table,
  Form,
  Alert,
  Button,
  Modal as BootstapModal,
  Row,
  Col,
} from "react-bootstrap";

import { service_url, token } from "../../Configs/mysqlconfig";
import moment from "moment";
import { toast } from "react-toastify";
import Select from "react-select";
import {
  Select as MantineSelect,
  Modal,
  Textarea,
  Avatar,
  Group,
  Text,
  NumberInput,
  Checkbox,
} from "@mantine/core";
import Backdrop from "../backdrop/backdrop";
import AddNewProductItem from "./AddNewProductItem";
const GLOBAL_PARENT_WAREHOUSE = "Bhiwandi";
const userCity = sessionStorage.getItem("userCity") || "";
const userType = sessionStorage.getItem("userType") || "";

const getStockStatusTextForQuantity = (quantity) => {
  if (!quantity) {
    return;
  }

  if (quantity > 0) {
    return (
      <p style={{ fontWeight: "bold", color: "green", display: "inline" }}>
        {" "}
        (Gain){" "}
      </p>
    );
  } else {
    return (
      <p style={{ fontWeight: "bold", color: "red", display: "inline" }}>
        {" "}
        (Loss){" "}
      </p>
    );
  }
};

function StockCorrectionApproveModal({ warehouse, ...props }) {
  const [selectedItemId, setSelectedItemId] = useState("");
  const [backDrop, setBackDrop] = useState(true);
  const [inventoryMap, setInventoryMap] = useState({});
  const userLoginType = JSON.parse(sessionStorage.getItem("user"))[0].user_type;

  const addTransitItemDropdownList = props.addTransitItemDropdownList;
  const stockDate = props.stockDate;
  const productMasterDataRaw = props.productMasterDataRaw;
  const isModalOpen = props.show;
  const setIsModalOpen = props.onHide;

  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });

  useEffect(() => {
    setBackDrop(true);
    let stock_date = moment(stockDate).format("YYYY-MM-DD");
    // let url = `${service_url}/api/inventory?inventory_date=${moment(
    //   returnDate
    // ).format("YYYY-MM-DD")}&warehouse=${warehouse}&token=${token}`;

    let url = `${service_url}/api/stock-correction?stock_date=${stock_date}&warehouse=${warehouse}&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MTU0OWIwMTIwMWUyZjMzZWE3NmFkZjYiLCJlbWFpbCI6InNtdHdpbmtsZTQ1MkBnbWFpbC5jb20iLCJpYXQiOjE2MzI5MzQ2NTgsImV4cCI6MTYzMjkzODI1OH0._oHr3REme2pjDDdRliArAeVG_HuimbdM5suTw8HI7ut`;

    fetch(url, {
      headers: { "x-access-token": sessionStorage.getItem("x-access-token") },
    })
      .then((res) => res.json())
      .then((result) => {
        const results = result.data || [];

        let inventoryList = results.map((item, index) => {
          if (item.approved_qty || item.approved_qty === 0) {
            item["isApproved"] = true;
          } else {
            item["isApproved"] = false;
          }
          return item;
        }).filter(item => !item.isApproved);

        setBackDrop(false);
        setInventoryMap(inventoryList);
      })
      .catch((err) => {
        setBackDrop(false);
        toast(`Error in fetching stock correction data : ${err}`, {
          type: toast.TYPE.ERROR,
        });
      });
  }, []);

  const approveStockCorrection = () => {
    let itemsList = [];
    inventoryMap.forEach((item, index) => {
      if (!item.isApproved && item.approved_stock) {
        let itemToSave = {};

        if (item.stock_status === "stock_gain") {
          itemToSave.approved_qty = Number(item.approved_stock) * +1;
        } else if (item.stock_status === "stock_loss") {
          itemToSave.approved_qty = Number(item.approved_stock) * -1;
        } else {
          throw new Error(
            "Please select a stock gain/ loss for all the enabled rows"
          );
        }

        let updatedInventory = Number(item.inventory_after_shipped) + Number(itemToSave.approved_qty);
        itemToSave.inventory = updatedInventory.toFixed(2); //item.quantity;
        itemToSave.item_id = item.item_id;
        itemToSave.remarks = item.remarks || "";
        itemToSave.id = item.id;
        itemsList.push(itemToSave);
      }
    });

    // new Date().toJSON().slice(0, 19).replace("T", " ")
    let dataToSend = {
      stock_date: moment(stockDate).format("YYYY-MM-DD"),
      approved_at: moment().format("YYYY-MM-DD HH:mm:ss"),
      warehouse: warehouse,
      itemsList,
    };

    let url = `${service_url}/api/approve-stock-correction?token=${token}&isAuto=Y`;

    console.log("check response", dataToSend);

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": sessionStorage.getItem("x-access-token"),
      },
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success == 1) {
          toast(`${result.message}`, { type: toast.TYPE.SUCCESS });
          // props.onHide();
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        } else {
          toast(`${result.message}`, { type: toast.TYPE.ERROR });
        }
      })
      .catch((err) => {
        toast(`Error in creating transit route records: ${err}`, {
          type: toast.TYPE.ERROR,
        });
      });
  };

  return (
    <div>
      <Modal opened={isModalOpen} size="90%" onClose={setIsModalOpen}>
        <header>
          <h3 id="contained-modal-title-vcenter">Approve Stock Correction</h3>
        </header>
        <body>
          {inventoryMap && inventoryMap.length > 0 ? (
            <>
              <div>
                {/* <OverallTransitReturnSummaryTable
                  productMasterDataRaw={productMasterDataRaw}
                  setBackDrop={setBackDrop}
                /> */}

                <TransitRoutesTable
                  inventoryMap={inventoryMap}
                  setInventoryMap={setInventoryMap}
                />
              </div>
            </>
          ) : (
            ""
          )}

          {/* {userType === "Admin" || userCity !== GLOBAL_PARENT_WAREHOUSE ? (
            <AddNewProductItem
              addTransitItemDropdownList={addTransitItemDropdownList}
              selectedItemId={selectedItemId}
              setSelectedItemId={setSelectedItemId}
              addItemToSummaryDetails={addItemToSummaryDetails}
            />
          ) : (
            // <>
            //   <Row style={{ width: "100%" }}>
            //     <Col style={{ width: "150%" }}>
            //       <Select
            //         options={addTransitItemDropdownList}
            //         value={selectedItemId}
            //         onChange={(val) => {
            //           console.log("selected value", val);
            //           setSelectedItemId(val);
            //         }}
            //         isClearable={true}
            //       />
            //     </Col>

            //     <Col style={{ width: "10%" }}>
            //       <Button
            //         onClick={() => {
            //           if (selectedItemId && selectedItemId.value) {
            //             addItemToSummaryDetails(selectedItemId.value);
            //           } else {
            //             toast(`Please select a valid item id from the list`, {
            //               type: toast.TYPE.WARNING,
            //             });
            //           }
            //         }}
            //       >
            //         Add item
            //       </Button>
            //     </Col>
            //   </Row>
            // </>
            ""
          )} */}
        </body>
        <BootstapModal.Footer>
          {userLoginType === "admin" || userLoginType === "financeHead" || userLoginType.toLowerCase() === 'dcmanager' ? (
            <Button
              onClick={() => {
                approveStockCorrection();
              }}
            >
              Approve
            </Button>
          ) : null}

          <Button onClick={props.onHide}>Close</Button>
        </BootstapModal.Footer>
      </Modal>

      {backDrop ? <Backdrop parentLoadStatus={backDrop} /> : null}
    </div>
  );
}

const StockSelectItemUI = forwardRef(
  ({ image, label, value, description, ...others }, ref) => (
    <div ref={ref} {...others}>
      {/* {console.log("check value", value)} */}
      <Group noWrap>
        <Avatar src={image} />

        <div>
          <Text
            color={
              value === "stock_gain"
                ? "green"
                : value === "stock_loss"
                ? "red"
                : "black"
            }
            size="sm"
          >
            {label}
          </Text>
          {/* <Text size="xs" color="dimmed">
            {description}
          </Text> */}
        </div>
      </Group>
    </div>
  )
);

const TransitRoutesTableHeader = () => {
  return (
    <thead className="thead-light">
      <tr>
        <th>Item ID</th>
        <th>Item description</th>
        {/* <th>Current Inventory</th>
        <th>Stock Loss/ Gain</th> */}
        {/* <th>Remarks</th> */}
        <th>Gain/Loss qty</th>
        {/* <th>Approved Gain/Loss</th> */}
        <th>Approved Gain/Loss</th>
      </tr>
    </thead>
  );
};

const TransitRoutesTableBody = ({ inventoryMap, setInventoryMap }) => {
  let itemsListData = inventoryMap;

  let rows = itemsListData.map((row, index) => {
    // let gainQty = Number(row.inventory_after_shipped) + Number(row.approved_qty ? row.approved_qty : row.quantity);
    let gainQty = row.quantity; //Math.round(Math.abs(row.quantity) - row.inventory_after_shipped);
    row.approved_stock= row.approved_stock ? row.approved_stock : Math.abs(row.quantity) ;//Math.round(Math.abs(row.quantity) - row.inventory_after_shipped);
    row.stock_status = row.quantity > 0 ? "stock_gain" : "stock_loss";
    
    return (
      <tr
        key={index}
        style={{ backgroundColor: row.bgColor ? row.bgColor : "" }}
      >
        <td style={{ fontWeight: "bold" }}>{row.item_id}</td>
        <td style={{ fontWeight: "bold" }}>{row.item_disc}</td>

        {/* <td>
          <MantineSelect
            defaultValue="stock_gain"
            disabled={true}
            data={[
              { value: "stock_loss", label: "Stock Loss" },
              { value: "stock_gain", label: "Stock Gain" },
            ]}
          />
        </td> */}
        {/* <td>
          <Form.Control
            type="text"
            disabled
            value={row.remarks || ""}
            onChange={(e) => {
              // console.log("check debug", e.target.value);
              let _inventoryMap = [...inventoryMap];
              _inventoryMap[index].remarks = e.target.value;
              setInventoryMap(_inventoryMap);
            }}
          />
        </td> */}
        <td>
          {/* {Math.abs(row.quantity)} {getStockStatusTextForQuantity(row.quantity)} */}
          {Math.abs(Number(gainQty))} {getStockStatusTextForQuantity(gainQty)}
        </td>

        {/* <td>
          <MantineSelect
            disabled={row.isApproved}
            value={row.stock_status}
            itemComponent={StockSelectItemUI}
            onChange={(value) => {
              let _inventoryMap = [...inventoryMap];
              _inventoryMap[index].stock_status = value;
              setInventoryMap(_inventoryMap);
            }}
            data={[
              {
                value: "stock_gain",
                label: "Stock Gain",
                image: "./increase.png",
              },
              {
                value: "stock_loss",
                label: "Stock Loss",
                image: "./decrease.png",
              },
            ]}
          />
        </td> */}

        <td>
          {!row.isApproved ? (
            <Form.Control
              type="number"
              value={Math.abs(Number(row.approved_stock))}
              onChange={(e) => {
                let _inventoryMap = [...inventoryMap];
                _inventoryMap[index].approved_stock = e.target.value;
                setInventoryMap(_inventoryMap);
              }}
            />
          ) : (
            <div style={{ color: "green", fontWeight: "bold" }}>
              Approved : {row.approved_qty}
            </div>
          )}
        </td>
      </tr>
    );
  });

  return (
    <>
      <tbody>{rows}</tbody>
    </>
  );
};

const TransitRoutesTable = ({ inventoryMap, setInventoryMap }) => {
  return (
    <>
      <table style={{ fontSize: "13px" }} className="table table-striped">
        <TransitRoutesTableHeader />
        <TransitRoutesTableBody
          inventoryMap={inventoryMap}
          setInventoryMap={setInventoryMap}
        />
      </table>
    </>
  );
};

export default StockCorrectionApproveModal;
