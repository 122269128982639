import React, { useEffect, useMemo, useState } from "react";
import NavBar from "../Navigation bar/Navbar";
import { Row, Col, InputGroup, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import styled from "styled-components";
import { useTable, useGlobalFilter, useFilters } from "react-table";
import { service_url, token } from "../../Configs/mysqlconfig";
import Select from "react-select";
import { InputGroupAddon } from "reactstrap";
import Backdrop from "../backdrop/backdrop";
import moment from "moment";
import InventoryTransactionsModal from "./InventoryTransactionsModal";
import ExportToExcel from "../InventoryManagementExport/ExportToExcel";

const Styles = styled.div`
  padding: 1rem;
  width: 100vw;

  table {
    border-spacing: 0;
    border: 1px solid black;
    width: 96vw;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }

  .summary_header {
    font-size: 1.5rem;
  }
  .loading_text {
    font-size: 1.2rem;
  }
  .export_btn {
    margin-left: 20px;
  }
  .search_container {
    margin-bottom: 20px;
  }
  .search_bar {
    width: 31%;
  }
`;

const Header = styled.div`
  display: flex;
  height: 18vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 1rem;
`;

const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <div className="search_container">
      <input
        className="search_bar"
        value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}
        placeholder="Search"
      />{" "}
    </div>
  );
};

const Inventory = (props) => {
  const warehouseOptionsList = useMemo(() => {
    return [
      { value: "Nashik", label: "Nashik" },
      { value: "Vashi", label: "Vashi" },
      { value: "Mira Road", label: "Mira Road" },
      { value: "Wadala", label: "Wadala" },
    ];
  }, []);

  const sessionStorageWarehouse = sessionStorage.getItem("selectedWarehouse");
  const [warehouse, setWarehouse] = useState(
    sessionStorageWarehouse
      ? { label: sessionStorageWarehouse, value: sessionStorageWarehouse }
      : ""
  );
  const [warehouseDataRaw, setWarehouseDataRaw] = useState([]);
  const [warehouseTableData, setWarehouseTableData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showTransactionsModal, setShowTransactionsModal] = useState();
  const [itemId, setItemId] = useState("");
  const [itemDescription, setItemDescription] = useState("");
  const [backDrop, setBackDrop] = useState(true);

  function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    const options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        if (row.values[id] && row.values[id] !== "")
          options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
      <select
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  }
  const fetchDataFromAPI = (_warehouse, _date) => {
    return new Promise((resolve, reject) => {
      if (_warehouse && Object.keys(_warehouse).length >= 0) {
        _warehouse = _warehouse.value;
        let url = `${service_url}/api/inventory?warehouse=${_warehouse}&inventory_date=${_date}&token=${token}`;
        fetch(url)
          .then((res) => res.json())
          .then((result) => {
            console.log("check result", result.data);
            setWarehouseDataRaw(result.data);
            resolve(result.data);
          });
      } else {
        reject();
      }
    });
  };

  const handleReturnDateChange = (selecteddate) => {
    setSelectedDate(selecteddate);
  };

  const handleOnWarehouseSelectionChanged = (val) => {
    if (val) {
      sessionStorage.setItem("selectedWarehouse", val.value);
      setWarehouse(val);
    }
  };

  const CustomDateInput = ({ value, onClick }) => (
    <input
      value={value}
      onClick={onClick}
      style={{ border: "solid 1px black", height: "30px" }}
    />
  );
  const generateTableData = (wareHouseData) => {
    if (!wareHouseData) {
      return console.log("Function did not receive required arguements");
    }

    wareHouseData.forEach((item, index) => {
      wareHouseData[index].item_disc = `${item.item_disc}-${item.brand}`;
    });

    setWarehouseTableData(wareHouseData);
  };

  const init = (selectedWarehouse, _selectedDate) => {
    setBackDrop(true);
    let date = moment(_selectedDate).format("YYYY-MM-DD");

    if (selectedWarehouse !== "none") {
      fetchDataFromAPI(selectedWarehouse, date)
        .then((data) => {
          generateTableData(data);
          setBackDrop(false);
        })
        .catch((err) => {
          console.log("Error ", err);
          setBackDrop(false);
        });
    }
  };

  useEffect(() => {
    init(warehouse, selectedDate);
  }, [warehouse, selectedDate]);

  const excelHeaders = React.useMemo(
    () => [
      {
        label: "Item ID",
        value: "item_id",
      },
      {
        label: "Description",
        value: "item_disc",
      },
      {
        label: "Variety",
        value: "variety",
      },
      {
        label: "Category",
        value: "category",
      },
      {
        label: "Sub Category",
        value: "sub_category",
      },

      {
        label: "Last Day Closing Inventory",
        value: "last_day_closing_inventory",
      },
      {
        label: "Transit Received Qty",
        value: "received_qty",
      },
      {
        label: "Shipped Qty",
        value: "shipped_qty",
      },
      {
        label: "Delivered Qty",
        value: "delivered_qty",
      },
      {
        label: "Returned Qty",
        value: "returned_qty",
      },
      {
        label: "Transit Returned Qty",
        value: "transit_returned_qty",
      },
      {
        label: "Stock Correction",
        value: "stock_correction",
      },
      {
        label: "Resultant Inventory",
        value: "resultant_inventory",
      },
    ],
    []
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Item ID",
        accessor: "item_id",
      },
      {
        Header: "Description",
        accessor: "item_disc",
      },
      {
        Header: "Variety",
        accessor: "variety",
      },
      {
        Header: "Category",
        accessor: "category",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Sub Category",
        accessor: "sub_category",
        Filter: SelectColumnFilter,
        filter: "equals",
      },

      {
        Header: "Last Day Closing Inventory",
        accessor: "last_day_closing_inventory",
      },
      {
        Header: "Transit Received Qty",
        accessor: "received_qty",
      },
      {
        Header: "Shipped Qty",
        accessor: "shipped_qty",
      },
      {
        Header: "Delivered Qty",
        accessor: "delivered_qty",
      },
      {
        Header: "Returned Qty",
        accessor: "returned_qty",
      },
      {
        Header: "Transit Returned Qty",
        accessor: "transit_returned_qty",
      },
      {
        Header: "Stock Correction",
        accessor: "stock_correction",
      },
      {
        Header: "Resultant Inventory",
        accessor: "resultant_inventory",
      },
    ],
    []
  );

  return (
    <>
      <NavBar />
      <div
        className="inventory_container"
        style={{
          width: "100%",
          marginLeft: "auto",
          marginRight: "auto",
          paddingTop: "100px",
        }}
      >
        <div
          className="inventory_header"
          style={{
            height: "20vh",
            paddingLeft: "1vw",
            paddingRight: "1vw",
          }}
        >
          <div>
            {" "}
            <h2
              style={{
                fontWeight: "bolder",
                fontSize: "30px",
                paddingBottom: "2%",
              }}
            >
              Warehouse Inventory
            </h2>
          </div>
          <Row>
            <Col xs={3}>
              <InputGroup size="sm" style={{ paddingTop: "6%" }}>
                <InputGroupAddon addonType="prepend">Date</InputGroupAddon>
                <DatePicker
                  style={{ width: "500px" }}
                  selected={selectedDate}
                  onChange={handleReturnDateChange}
                  minDate={new Date("2017/01/01")}
                  aria-describedby="basic-addon1"
                  dateFormat="dd/MM/yyyy"
                  customInput={<CustomDateInput />}
                />
              </InputGroup>
            </Col>

            <Col xs={3} style={{ width: "5rem" }}>
              {" "}
              <div>
                Select WareHouse{" "}
                <Select
                  options={warehouseOptionsList}
                  value={warehouse}
                  onChange={(val) => {
                    handleOnWarehouseSelectionChanged(val);
                  }}
                />
              </div>
            </Col>

            <Col style={{ alignSelf: "flex-end" }}>
              <Button
                size="sm"
                style={{ marginRight: "10px", marginBottom: "10px" }}
                variant="primary"
                onClick={() => {
                  props.history.push("/inventory-movements");
                }}
              >
                Inventory movements
              </Button>{" "}
              <Button
                size="sm"
                style={{ marginRight: "10px", marginBottom: "10px" }}
                variant="primary"
                onClick={() => {
                  props.history.push("/transit-stock-transfer");
                }}
              >
                Transit stock transfer
              </Button>{" "}
              <Button
                style={{ marginRight: "10px", marginBottom: "10px" }}
                size="sm"
                variant="primary"
                onClick={() => {
                  props.history.push("/transit-stock-return");
                }}
              >
                Transit stock return
              </Button>{" "}
              <div
                style={{
                  marginRight: "10px",
                  marginBottom: "10px",
                  display: "inline",
                }}
              >
                <ExportToExcel
                  sheet1={warehouseTableData}
                  filename={"inventory_summary"}
                  sheet1Headers={excelHeaders}
                  sheet1Name={warehouse.value}
                />
              </div>
            </Col>
          </Row>
        </div>
        <div
          className="inventory_body"
          style={{
            width: "100%",
          }}
        >
          <Styles>
            <Table
              columns={columns}
              data={warehouseTableData}
              history={props.history}
              setShowTransactionsModal={setShowTransactionsModal}
              warehouse={warehouse}
              setItemId={setItemId}
              setItemDescription={setItemDescription}
              date={selectedDate}
            />
          </Styles>
        </div>
        {showTransactionsModal ? (
          <InventoryTransactionsModal
            show={showTransactionsModal}
            onHide={() => setShowTransactionsModal(false)}
            warehouse={warehouse.value || ""}
            item_id={itemId}
            item_description={itemDescription}
            transaction_date={moment(selectedDate).format("YYYY-MM-DD")}
          />
        ) : null}
        {backDrop ? <Backdrop parentLoadStatus={backDrop} /> : null}
      </div>
    </>
  );
};

const Table = ({
  columns,
  data,
  setShowTransactionsModal,
  warehouse,
  setItemId,
  setItemDescription,
  history,
  date,
}) => {
  //!Due to an issue with the react-table library, Select filter does not work without this dummmy function.
  //!Also this is being passed to useTable
  const defaultColumn = React.useMemo(
    () => ({
      Filter: () => null,
    }),
    []
  );

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    preGlobalFilteredRows,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      autoResetFilters: true,
      autoResetHiddenColumns: true,
      autoResetPage: true,
    },
    useFilters,
    useGlobalFilter
  );

  const { globalFilter } = state;

  // Render the UI for your table
  return (
    <>
      <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />

      {/* //Apply the table props */}
      <table style={{ fontSize: ".85rem" }} {...getTableProps()}>
        <thead>
          {/* // Loop over the header rows */}
          {headerGroups.map((headerGroup) => (
            // Apply the header row props
            <tr {...headerGroup.getHeaderGroupProps()}>
              {/* // Loop over the headers in each row */}
              {headerGroup.headers.map((column) => (
                // Apply the header cell props
                <th {...column.getHeaderProps()}>
                  {/* // Render the header */}

                  {column.render("Header")}

                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </th>
              ))}
              <th>Inventory Transactions</th>
            </tr>
          ))}
        </thead>
        {/*  Apply the table body props  */}
        <tbody {...getTableBodyProps()}>
          {/* // Loop over the table rows */}
          {rows.map((row, i) => {
            // Prepare the row for display
            prepareRow(row);

            return (
              // Apply the row props
              <tr {...row.getRowProps()}>
                {/* // Loop over  the rows cells */}
                {row.cells.map((cell) => {
                  if (cell.column.id === "item_id") {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  }

                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")} </td>
                  );
                })}
                <td>
                  {" "}
                  <Button
                    variant="primary"
                    onClick={() => {
                      setItemId(row.original.item_id);
                      setItemDescription(row.original.item_disc);
                      setShowTransactionsModal(true);
                    }}
                  >
                    View
                  </Button>{" "}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default Inventory;
