import styled from "styled-components";
import bg_img from "../../assests/images/home_background.jpeg";
import sz_logo from "../../assests/images/login_header_logo.png";
export const LoginContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(${bg_img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;
export const LoginHeader = styled.header`
  padding: 3rem;
  margin-top: -12rem;
  margin-bottom: 1rem;

  span {
    padding: 6rem 7rem;
    border-radius: 0.5rem;
    positon: relative;
    background-image: url(${sz_logo});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
`;
export const UserDetailsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  padding: 2rem 5rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  background-color: #fff;
  opacity: 0.95;
  box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.5);

  input {
    width: 100%;
    outline: none;
    margin: 0.6rem auto;
    padding: 0.6rem 1rem;
    font-size: 1rem;
    background-color: #fff;
    border-radius: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.5);
    box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.2);

    &:hover {
      box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.5);
    }
  }

  span {
    width: 100%;
    font-size: 0.9rem;
    color: #fff;
    text-align: center;
    background-color: red;
  }

  button {
    position: relative;
    left: 0rem;
    margin: 0.2rem;
    outline: none;
    padding: 0.5rem 2rem;
    font-size: 1.3rem;
    border-radius: 0.5rem;
    // background-color: #96fb96;
    border: 1px solid rgba(0, 0, 0, 0.4);
    box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.1);

    &:hover {
      color: #ffffff;
      cursor: pointer;
      box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.5);
    }
  }
`;
