import React, { useState, useEffect, useRef } from 'react'
import { Table, Form, Alert, Badge } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faTimes, faPlusCircle, faCircle, faTrash, faEdit, faCloudShowersHeavy } from "@fortawesome/free-solid-svg-icons";
import szlogo from '../../assests/images/sz_logo.png';
import footer from '../../assests/images/footer.png';
import khetika from '../../assests/images/khetika.png';
import khetika_qr from '../../assests/images/khetika_qr.png';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import ReactToPrint from 'react-to-print';
import Backdrop from '../backdrop/backdrop';
import AddItemModal from '../OrdersListing/AddNewItemModal';
import { Collapse, IconButton } from '@material-ui/core';
import superprimelogo from '../../assests/images/superprimelogo.png';
import priorityshipping from '../../assests/images/priority_shipping.png';
import { support_portal_url, orderingApiUrl } from "../../Configs/mysqlconfig";

function formatDate(selecteddate){
  let dt=new Date(selecteddate);
  let monthNames =["Jan","Feb","Mar","Apr",
                      "May","Jun","Jul","Aug",
                      "Sep", "Oct","Nov","Dec"];

    let day = dt.getDate();

    let monthIndex = dt.getMonth();
    let monthName = monthNames[monthIndex];

    let year = dt.getFullYear().toString().substr(2,2);

    return `${day}-${monthName}-${year}`;
}

function formatPhone(phone,secondary_number){
  let phonestr=""+phone;
  if(secondary_number && secondary_number!="null"){
    phonestr+=","+secondary_number;
  }
  return phonestr;
}

function formatGstNumber(gst_number){
  return `GSTIN : ${gst_number}`;
}

function formatFssai(fssai){
  return `FSSAI : ${fssai}`;
}

function getRetailerIdFromOrderNumber(orderNumber) {
  if(!orderNumber) {
    return "";
  }

  if(orderNumber.includes("SC")) {
    let retailerStr = orderNumber.split("/")[0];
    return retailerStr.substr(retailerStr.length - 5);;
  }else if(orderNumber.includes("-")) {
    return orderNumber.split("-")[0];
  }else{
    return orderNumber.split("/")[0];
  }
}

const BulkOrdersPrint = () => {
    const [bulkOrderDetails,setBulkOrderDetails] = useState([])
    const [backupBulkOrderDetails,setBackupBulkOrderDetails] = useState([])
    const [numPrint,setNumPrint] = useState(1)
    const [vehicleOptions,setVehicleOptions] = useState([])
    const [fillerText, setFillerText] = useState("Loading...");
    const [itemAmountErrorMessage,setItemAmountErrorMessage] = useState([]);
    const [orderAmountErrorMessage,setOrderAmountErrorMessage] = useState([]);

    let getBackDrop=false;


    const dataToPrint = useRef()

    let invoiceDate = new Date()
    let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    invoiceDate = `${invoiceDate.getDate()<10?`0${invoiceDate.getDate()}`:invoiceDate.getDate()}-${monthNames[invoiceDate.getMonth()]}-${invoiceDate.getFullYear().toString().substr(2,2)}`

    useEffect(() => {
        if (!sessionStorage.getItem("bulkOrderIdToPrint"))
            return;
        const orderNumberSelected = sessionStorage.getItem("bulkOrderIdToPrint");
        console.log(orderNumberSelected);
        getBackDrop=true;
        let details;
        let queryParameters = new URLSearchParams(window.location.search);

        if(queryParameters.get("startDate") && queryParameters.get("endDate")){
          let startDate=queryParameters.get("startDate");
          let endDate=queryParameters.get("endDate");
          details = {
              'orders': orderNumberSelected,
              'startDate':startDate,
              'endDate':endDate
          };

          if(queryParameters.get("orderType")) {
            details['orderType'] = queryParameters.get("orderType");
          }
        }else{
          details = {
              'orders': orderNumberSelected
          };
        }
        console.log(details);


        let formBody = Object.keys(details).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(details[key])).join('&');
        console.log(formBody)

        Promise.all([
          fetch(`${support_portal_url}/api/multipleorderdetails`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
              'x-access-token': sessionStorage.getItem("apiToken")
            },
            body: formBody
          }).then(res => res.json()),
          fetch(`${orderingApiUrl}/retailer-group-details?token=eyJhbGciOiJIUzI1NiIsInR5cCI6Ik&group_id=prime`).then(response=>response.json()),
        ]).then(([data, userData]) => {
          let primeUserList = userData.data || [];
          if(data['success']==1){

            let apiOrderData=data['data'];

            let orderAmountErrors=[];
            let itemAmountErrors=[];

            for(let j=0;j<apiOrderData.length;j++){
              let orderdata=apiOrderData[j]['orderdetails'];
              let itemdata=orderdata['items'];

              // Conditions for discrepancy.
              //  if total_amt != (dealer_price * order_qty) + transport_charge + apmc_charge + gst_charge.
              //  if net_order_amt != sum(total_amt) - order_disc - cashback_redeemed.

              //check for discrepancies
              let order_number=orderdata['order_number'];
              let net_order_amt=orderdata['net_order_amt']?Number(orderdata['net_order_amt']):0;
              let order_disc=orderdata['order_disc']?Number(orderdata['order_disc']):0;
              let cashback_redeemed=orderdata['cashback_redeemed']?Number(orderdata['cashback_redeemed']):0;
              let sumTotalAmt=0;

              for(let i=0;i<itemdata.length;i++){
                let item_disc=itemdata[i]['item_disc'];
                let total_amt=itemdata[i]['total_amt']?Number(itemdata[i]['total_amt']):0;
                let dealer_price=itemdata[i]['dealer_price']?Number(itemdata[i]['dealer_price']):0;
                let order_qty=itemdata[i]['order_qty']?Number(itemdata[i]['order_qty']):0;
                let transport_charge=itemdata[i]['transport_charge']?Number(itemdata[i]['transport_charge']):0;
                let apmc_charge=itemdata[i]['apmc_charge']?Number(itemdata[i]['apmc_charge']):0;
                let gst_charge=itemdata[i]['gst_charge']?Number(itemdata[i]['gst_charge']):0;

                let calcItemValue=(dealer_price * order_qty) + transport_charge + apmc_charge + gst_charge;
                calcItemValue=calcItemValue.toFixed(2);
                calcItemValue=Number(calcItemValue);

                let itemValueDiff=calcItemValue-total_amt;
                if(calcItemValue!=total_amt && (itemValueDiff>10 || itemValueDiff<-10)){
                  itemAmountErrors.push(`${order_number} : ${item_disc} has Item Value discrepancy with difference of ${itemValueDiff.toFixed(2)}`)
                }

                sumTotalAmt=sumTotalAmt+total_amt;
              }

              let calcOrderValue=sumTotalAmt-order_disc-cashback_redeemed;
              calcOrderValue=calcOrderValue.toFixed(2);
              calcOrderValue=Number(calcOrderValue);

              let orderValueDiff=calcOrderValue-net_order_amt;
              if(net_order_amt!=calcOrderValue && (orderValueDiff>10 || orderValueDiff<-10)){
                orderAmountErrors.push(`Order number : ${order_number} has order value discrepancy with difference of ${orderValueDiff.toFixed(2)}`);
              }
            }

              setItemAmountErrorMessage(itemAmountErrors);
              setOrderAmountErrorMessage(orderAmountErrors);

              data['data'] && filterUniqueItems(data['data'], primeUserList);
              console.log(data);
          }else{
            let errMessage=data['message']?data['message']:"";
            alert(errMessage);
            if(data['status']==401 || data['status']==403){
              sessionStorage.clear();
              window.location.href="/"
            }else{
              setBulkOrderDetails([]);
              setBackupBulkOrderDetails([]);
            }
          }
        }).catch(err => {
          setBulkOrderDetails([]);
          setBackupBulkOrderDetails([]);
          alert(err)
        })

        sessionStorage.removeItem("bulkPrintConfirm")
        sessionStorage.removeItem("allCheckBoxSelected")


    }, [])

    const filterUniqueItems = (orderItems, primeUserList) => {
        var orderNumbers = [];
        orderItems.forEach(function (orderDetails, index) {
            if (!orderNumbers.includes(orderDetails["orderdetails"]["order_number"])) {
                let itemList = orderDetails["orderdetails"]["items"];
                var result = [];
                var itemIds = [];
                orderNumbers.push(orderDetails["orderdetails"]["order_number"])
                itemList.forEach(item => {
                    if (!itemIds.includes(item.item_id)) {
                    result.push(item);
                    itemIds.push(item.item_id);
                    }
                })
            } else {
                setFillerText("Duplicate Order Number")
                getBackDrop=true;
                return;
            }
            orderItems[index]["orderdetails"].is_prime_user = primeUserList.includes(String(orderDetails["orderdetails"]["retailer_id"]));
            orderItems[index]["orderdetails"]["items"] = result;
        })
        setBulkOrderDetails(orderItems)
        setBackupBulkOrderDetails(orderItems)
        getBackDrop=false;
    };

    useEffect(() => {
      console.log('numPrint');
      console.log(numPrint);
      //bulkOrderDetails
      let modifiedbulkOrderDetails=[];

      for(let j=0;j<backupBulkOrderDetails.length;j++){
        let ele=backupBulkOrderDetails[j];
        for(let i=0;i<numPrint;i++){
          modifiedbulkOrderDetails.push(ele);
        }
      }
      console.log(modifiedbulkOrderDetails);
      setBulkOrderDetails(modifiedbulkOrderDetails);

    }, [numPrint])


    const getItemsWeight = (indItemWeight, itemDesc, quantityOrdered) => {

        if (!indItemWeight)
            return 1
        let itemWeight = Number(indItemWeight.replace(/\D/g, ''));
        if (indItemWeight.includes("gm") || indItemWeight.includes("ml")) {
            itemWeight = itemWeight / 1000;
        }

        if ((indItemWeight.includes("1 pc") || indItemWeight.includes("1 Pc")) && itemDesc.includes("gm")) {
            let itemWtExtract = itemDesc.split("gm")
            let itemWtExtract2 = itemWtExtract[0].split("-")
            itemWeight = parseFloat(itemWtExtract2[itemWtExtract2.length-1].replace(/[^0-9]/g,''))/1000;
        }
        if(itemWeight <=0){
            itemWeight = 1;
        }
        indItemWeight = itemWeight * Number(quantityOrdered)

        return `${indItemWeight.toFixed(2)}`
    }

    const getTotalItems = (itemDetails) =>{

        let sumOfItems = 0
        itemDetails.forEach(item => {

            sumOfItems++

        })
        return parseInt(sumOfItems)
    }

    const getTotalInclGST = (itemDetails) =>{

        let sumAmtInclGst = 0
        itemDetails.forEach(item => {

            sumAmtInclGst+= Number(item.total_amt)


        })
        return sumAmtInclGst.toFixed(2)
    }
    const getTotalExclGST = (itemDetails) =>{

        let sumGSTAmt = 0; let sumAmtInclGst = 0;
        itemDetails.forEach(item => {

            sumAmtInclGst += Number(isNumber(item.total_amt))
            sumGSTAmt += Number(isNumber(item.gst_charge))


        })
        return ((sumAmtInclGst - sumGSTAmt).toFixed(2))
    }
    const getTotalCGST = (itemDetails) =>{

        let sumCGSTAmt = 0
        itemDetails.forEach(item => {

            sumCGSTAmt += Number(isNumber(item.gst_charge))

        })
        return sumCGSTAmt.toFixed(2)
    }
    const getTotalSGST = (itemDetails) =>{

        let sumSGSTAmt = 0
        itemDetails.forEach(item => {

            sumSGSTAmt += isNumber(item.gst_charge) / 2


        })
        return sumSGSTAmt.toFixed(2)
    }

    const isAlreadyPaid = (payment_mode) => {
        return payment_mode && (payment_mode.includes('Credit') || payment_mode.includes('RazorPay'))
    }

    const isNumber = (item) => {
        return isNaN(Number(item))?"0":Number(item).toFixed(2)
    }

    const convertCity = (cityVal, retailerid) => {
      // cityVal=cityVal.toLowerCase();
      // if(cityVal=="pune"){
      //   return "Pune";
      // } else if(cityVal=="surat"){
      //   return "Surat";
      // } else if(cityVal=="nashik"){
      //   return "Nashik";
      // } else {
      //   if(Number(retailerid)>0) {
      //     return "Mumbai";
      //   } else {
      //     return cityVal;
      //   }
      // }
      return cityVal;
    }

    const getSavedAmount = (updateditemList) => {
      let savedAmt = 0;

      if(updateditemList) {
        for(let updateditem of updateditemList) {
          let total_amt = Number(updateditem.total_amt) || 0;
          let mrp = Number(updateditem.mrp) || 0;
          let order_qty = Number(updateditem.order_qty) || 0;

          savedAmt += ((mrp * order_qty) - total_amt);
        }
      }

      return savedAmt.toFixed(2);
    }

    const getApartmentBlock = (apartment,block) => {
      let result = "";
      apartment = apartment || "";
      result += apartment;

      if(block) {
        result = result + ", "
        result += block;
      }

      return result;
    }

    console.log('bulkOrderDetails');
    console.log(bulkOrderDetails);
    let newItem=false;
    let printConfirm=false;
    let disablePrintIcon=true;

    const bulkOrderPrintUI = () => {
            return(<>

              {itemAmountErrorMessage.map((itemError, key) => {
                  return (
                      <div style={{ display: "flex", backgroundColor: "#FF6347", padding: "10px", margin: "15px", borderRadius: "5px" }}>{itemError}</div>
                  )
              })}

              {orderAmountErrorMessage.map((orderError, key) => {
                  return (
                      <div style={{ display: "flex", backgroundColor: "#FF6347", padding: "10px", margin: "15px", borderRadius: "5px" }}>{orderError}</div>
                  )
              })}

            <div ref={dataToPrint}>
            {bulkOrderDetails ? bulkOrderDetails.map((bulkOrderDetail, index) => (

              <div style={{pageBreakBefore: "always"}}>
              <div style={{ textAlign: "center", width: "1000px", margin: "auto", border: "2px solid black" }}>

              <div>
                  <Table><tr>
                      {bulkOrderDetail['orderdetails'].city!="Ali" && bulkOrderDetail['orderdetails'].retailer_id>0 && <td><img src={szlogo} alt={"superzopLogo"} style={{ width: "250px", height: "70px" }} /></td>}
                      {(Number(bulkOrderDetail['orderdetails'].customer_id)>0 || bulkOrderDetail['orderdetails'].retailer_id==0) && <td><img src={khetika} alt={"KhetikaLogo"} style={{  width: "300px", height: "100px" }} /></td>}
                      {bulkOrderDetail['orderdetails'].is_prime_user && <td><img src={superprimelogo} alt={"superprimeLogo"} style={{  width: "150px", height: "70px" }} /></td>}
                      {bulkOrderDetail['orderdetails'].is_priority && <td><img src={priorityshipping} alt={"prioritylogo"} style={{  width: "150px", height: "70px" }} /></td>}
                      {bulkOrderDetail['orderdetails'].retailer_id>0?!(bulkOrderDetail['orderdetails'].city && (bulkOrderDetail['orderdetails'].city).includes('Goa'))?(
                        <td style={{ width: "400px", textAlign: "left" }}><b>Retranz Infolabs Private Limited</b><br />
                        Godown D1, Indian Corporation Compound,<br />Diva - Anjur Village, Bhiwandi – 421302<br />
                Phone : 9901044177<br />
                Email : support@superzop.com<br />
                GSTIN : 27AAHCR2573F1ZA <br />
                FSSAI : 11522998000504</td>
                      ):(<td style={{ width: "400px", textAlign: "left" }}><b>Retranz Infolabs Private Limited</b><br />
                      3/7693, Cartybon Factory,<br /> Behind Residency Bunglow, Murida Fatorda, Margao, Goa - 403602<br />
              Phone : 9901044177<br />
              Email : support@superzop.com<br />
              GSTIN : 30AAHCR2573F1ZN <br />
              FSSAI : 11520030000203</td>):(<td style={{ width: "400px", textAlign: "left" }}><b>Retranz Infolabs Private Limited</b><br />
              Godown D1, Indian Corporation Compound,<br />Diva - Anjur Village, Bhiwandi – 421302<br />
      Phone : 8010173173<br />
      Email : support@superzop.com<br />
      GSTIN : 27AAHCR2573F1ZA <br />
      FSSAI : 11522998000504</td>)}
                  </tr>
                  </Table>
                  <div><div style={{ borderTop: "2px solid black", borderBottom: "2px solid black" }}>
                      <b style={{ fontSize: "20px" }}>RETAILER TAX INVOICE</b>
                  </div>
                      <Table>
                          <tr>
                              <td style={{ textAlign: "left" }}><div>
                                  <b>{bulkOrderDetail['orderdetails'].retailer_id>0?bulkOrderDetail['orderdetails'].shop_name:bulkOrderDetail['orderdetails'].name}</b><br />
                                  {bulkOrderDetail['orderdetails'].retailer_id>0?bulkOrderDetail['orderdetails'].address1:getApartmentBlock(bulkOrderDetail['orderdetails'].apartment,bulkOrderDetail['orderdetails'].block)}<br />
                                  {bulkOrderDetail['orderdetails'].retailer_id>0?bulkOrderDetail['orderdetails'].address2:bulkOrderDetail['orderdetails'].address}<br />
                                  {bulkOrderDetail['orderdetails'].area}<br />
                                  {convertCity(bulkOrderDetail['orderdetails'].city || "", bulkOrderDetail['orderdetails'].retailer_id)}-{bulkOrderDetail['orderdetails'].pincode}<br />
                                  ✆{formatPhone(bulkOrderDetail['orderdetails'].phone,bulkOrderDetail['orderdetails'].secondary_number)}<br />
                                  {bulkOrderDetail['orderdetails'].gst_number?formatGstNumber(bulkOrderDetail['orderdetails'].gst_number):null}<br />
                                  {bulkOrderDetail['orderdetails'].fssai_document_number?formatFssai(bulkOrderDetail['orderdetails'].fssai_document_number):null}<br />
                              </div></td>
                              <td style={{ width: "400px", textAlign: "left" }}><div><Table borderless style={{ cellSpacing: "0px" }}>
                                  <tr style={{ cellSpacing: "0px", cellPadding: "0px" }}>
                                      <td style={{ padding: "0px" }}>Retailer ID</td>
                                      <td style={{ textAlign: "right", padding: "0px", }}>{bulkOrderDetail['orderdetails'].retailer_id || bulkOrderDetail['orderdetails'].customer_id}</td>
                                  </tr>
                                  <tr style={{ cellSpacing: "0px", cellPadding: "0px" }}>
                                      <td style={{ padding: "0px" }}>Invoice Number</td>
                                      <td style={{ textAlign: "right", padding: "0px", }}>{bulkOrderDetail['orderdetails'].order_number}</td>
                                  </tr>
                                  <tr style={{ cellSpacing: "0px" }}>
                                      <td style={{ padding: "0px" }}>Invoice Date</td>
                                      <td style={{ textAlign: "right", padding: "0px", }}>{bulkOrderDetail['orderdetails'].processed_date?formatDate(bulkOrderDetail['orderdetails'].processed_date):formatDate(invoiceDate)}</td>
                                  </tr>
                                  <tr style={{ cellSpacing: "0px" }}>
                                      <td style={{ padding: "0px" }}>Order Date</td>
                                      <td style={{ textAlign: "right", padding: "0px", }}>{formatDate(bulkOrderDetail['orderdetails'].order_date)}</td>
                                  </tr>
                                  <tr style={{ cellSpacing: "0px" }}>
                                      <td style={{ padding: "0px" }}>No of Items</td>
                                      <td style={{ textAlign: "right", padding: "0px", }}>{getTotalItems(bulkOrderDetail['orderdetails']['items'])}</td>
                                  </tr>
                                  {bulkOrderDetail['orderdetails'].numbags!=0 && <tr style={{ cellSpacing: "0px" }}>
                                      <td style={{ padding: "0px" }}>No of Loose Bags</td>
                                      <td style={{ textAlign: "right", padding: "0px", }}>{bulkOrderDetail['orderdetails'].numbags!=0?bulkOrderDetail['orderdetails'].numbags:""}</td>
                                  </tr>}
                                  {bulkOrderDetail['orderdetails'].bagdetails && bulkOrderDetail['orderdetails'].bagdetails!='null' && <tr style={{ cellSpacing: "0px" }}>
                                      <td style={{ padding: "0px" }}>Loose Bag Id</td>
                                      <td style={{ textAlign: "right", padding: "0px", }}>{bulkOrderDetail['orderdetails'].bagdetails?bulkOrderDetail['orderdetails'].bagdetails:""}</td>
                                  </tr>}
                                  <tr style={{ cellSpacing: "0px" }}>
                                      <td style={{ padding: "0px" }}>Sales Agent</td>
                                      <td style={{ textAlign: "right", padding: "0px", }}>{bulkOrderDetail['orderdetails'].agent_id}</td>
                                  </tr>
                              </Table>
                              </div>
                              </td>
                          </tr>
                      </Table>
                  </div>
                  <Table>
                        <thead style={{ borderTop: "2px solid black", borderBottom: "2px solid black" }}>
                            <tr>
                                <td style={{paddingRight:"10px"}}><b>Sl No</b></td>
                                <td style={{paddingRight:"10px"}}><b>Item description</b></td>
                                <td style={{paddingRight:"10px"}}><b>HSN Code</b></td>
                                <td style={{paddingRight:"10px"}}><b>Qty/No</b></td>
                                {Number(bulkOrderDetail['orderdetails'].retailer_id)==0 && <td style={{paddingRight:"10px"}}><b>Mrp</b></td>}
                                <td style={{paddingRight:"10px"}}><b>Total Weight</b></td>
                                <td style={{paddingRight:"10px"}}><b>Dealer Price (per pack)</b></td>
                                <td style={{paddingRight:"10px"}}><b>Amount (Rs.)</b></td>
                                {bulkOrderDetail['orderdetails'].city=="Surat" && <td style={{paddingRight:"10px"}}><b>IGST Amount</b></td>}
                                {bulkOrderDetail['orderdetails'].city!="Surat" && <td style={{paddingRight:"10px"}}><b>CGST Amount</b></td>}
                                {bulkOrderDetail['orderdetails'].city!="Surat" && <td style={{paddingRight:"10px"}}><b>SGST Amount</b></td>}
                                <td style={{paddingRight:"10px"}}><b>Total Amount</b></td>
                            </tr>
                        </thead>
                      <tbody>
                        {bulkOrderDetail['orderdetails']['items'] ? Object.keys(bulkOrderDetail['orderdetails']['items']).map((product, index) => (
                            <tr key={index}>
                                <td style={{padding:"2px",border:"none"}}>{index + 1}</td>
                                <td style={{width:"400px",padding:"2px",textAlign:"left",border:"none",fontSize:"16px"}}>{bulkOrderDetail['orderdetails']['items'][product].item_disc}</td>
                                <td style={{padding:"2px",border:"none"}}>{bulkOrderDetail['orderdetails']['items'][product].hsn_code}</td>
                                <td style={{padding:"2px",border:"none"}}>{bulkOrderDetail['orderdetails']['items'][product].order_qty}</td>
                                {Number(bulkOrderDetail['orderdetails'].retailer_id)==0 && <td style={{padding:"2px",border:"none"}}>{Number(bulkOrderDetail['orderdetails']['items'][product].mrp) || 0}</td>}
                                <td style={{padding:"2px",border:"none"}}>{getItemsWeight(bulkOrderDetail['orderdetails']['items'][product].weight, bulkOrderDetail['orderdetails']['items'][product].item_disc,bulkOrderDetail['orderdetails']['items'][product].order_qty)}</td>
                                <td style={{ padding: "2px", border: "none" }}>{isNumber((Number(bulkOrderDetail['orderdetails']['items'][product].total_amt) -  Number(bulkOrderDetail['orderdetails']['items'][product].gst_charge))/Number(bulkOrderDetail['orderdetails']['items'][product].order_qty))}</td>
                                <td style={{ padding: "2px", border: "none" }}>{isNumber(Number(bulkOrderDetail['orderdetails']['items'][product].total_amt) - Number(bulkOrderDetail['orderdetails']['items'][product].gst_charge))}</td>
                                {bulkOrderDetail['orderdetails'].city=="Surat" && <td style={{ padding: "2px", border: "none" }}>{isNumber(Number(bulkOrderDetail['orderdetails']['items'][product].gst_charge))}</td>}
                                {bulkOrderDetail['orderdetails'].city!="Surat" && <td style={{ padding: "2px", border: "none" }}>{isNumber(Number(bulkOrderDetail['orderdetails']['items'][product].gst_charge)/2)}</td>}
                                {bulkOrderDetail['orderdetails'].city!="Surat" && <td style={{ padding: "2px", border: "none" }}>{isNumber(Number(bulkOrderDetail['orderdetails']['items'][product].gst_charge)/2)}</td>}
                                <td style={{padding:"2px",border:"none"}}>{isNumber(Number(bulkOrderDetail['orderdetails']['items'][product].total_amt))}</td>
                            </tr>
                        )) : ""}
                      </tbody>
                  </Table>
                  <div style={{ borderTop: "1px solid black" }}>
                      <Table ><tr>
                          <td style={{ textAlign: "center" }}><div>
                              <br /><br /><br />
                              <i>I/We hereby certify that the goods mentioned in the invoice are warranted
                              to be of the same nature<br/>
                              Certified that the particulars given above are true and correct</i><br/><br/>
                              <b><i>Goods Once Sold Will Not Be Taken Back.</i></b><br/>
                              Subject to Mumbai Jurisdiction<br/>
                              <i><b>E&OE</b></i>
                              <div><b>You have saved Rs {getSavedAmount(bulkOrderDetail['orderdetails']['items'])} by ordering on Khetika</b></div>
                              <br/><br/><br/>
                              <i><b>*For any queries please contact support@superzop.com or 8010173173</b></i>
                              <div style={{textAlign:"center"}}>{Number(bulkOrderDetail['orderdetails']["retailer_id"])>0 ? (bulkOrderDetail['orderdetails']["order_payment_mode"]=='Credit'?<h1><Badge pill variant="primary">Paid - SuperKredit</Badge></h1>:bulkOrderDetail['orderdetails']["order_payment_mode"]=='RazorPay'?<h1><Badge pill variant="primary">Paid - RazorPay</Badge></h1>:<h1><Badge pill variant="primary">Cash Only</Badge></h1>) : (
                                bulkOrderDetail['orderdetails']["order_payment_mode"]=='Credit'?<h1><Badge pill variant="primary">Paid - SuperKredit</Badge></h1>:bulkOrderDetail['orderdetails']["order_payment_mode"]=='RazorPay'?<h1><Badge pill variant="primary">Paid - RazorPay</Badge></h1>:<img src={khetika_qr} alt={"KhetikaQR"} style={{  width: "200px", height: "300px" }} />
                              )}</div>
              </div></td>
                          <td style={{ width: "400px", textAlign: "left", cellSpacing: "0px" }}><div>
                              <Table bordered style={{ border: "2px solid black" }}>
                                  <tr style={{ border: "2px solid black" }}>
                                      <td style={{ border: "2px solid black" }}>Amount (Exl GST)</td>
                                      <td style={{ border: "2px solid black" }}>{getTotalExclGST(bulkOrderDetail['orderdetails']['items'])}</td>
                                  </tr>
                                  {bulkOrderDetail['orderdetails'].city=="Surat" && <tr style={{ border: "2px solid black" }}>
                                      <td style={{ border: "2px solid black" }}>IGST Amount</td>
                                      <td style={{ border: "2px solid black" }}>{getTotalCGST(bulkOrderDetail['orderdetails']['items'])}</td>
                                  </tr>}
                                  {bulkOrderDetail['orderdetails'].city!="Surat" && <tr style={{ border: "2px solid black" }}>
                                      <td style={{ border: "2px solid black" }}>CGST Amount</td>
                                      <td style={{ border: "2px solid black" }}>{getTotalSGST(bulkOrderDetail['orderdetails']['items'])}</td>
                                  </tr>}
                                  {bulkOrderDetail['orderdetails'].city!="Surat" && <tr style={{ border: "2px solid black" }}>
                                      <td style={{ border: "2px solid black" }}>SGST Amount</td>
                                      <td style={{ border: "2px solid black" }}>{getTotalSGST(bulkOrderDetail['orderdetails']['items'])}</td>
                                  </tr>}
                                  <tr style={{ border: "2px solid black" }}>
                                      <td style={{ border: "2px solid black" }}><b>Total Amount</b></td>
                                      <td style={{ border: "2px solid black" }}>{getTotalInclGST(bulkOrderDetail['orderdetails']['items'])}</td>
                                  </tr>
                                  <tr style={{ border: "2px solid black" }}>
                                      <td style={{ border: "2px solid black" }}>Discount/Coupon</td>
                                      <td style={{ border: "2px solid black" }}>{bulkOrderDetail['orderdetails']["order_disc"]}</td>
                                  </tr>
                                  <tr style={{ border: "2px solid black" }}>
                                      <td style={{ border: "2px solid black" }}>SZ Wallet Paid</td>
                                      <td style={{ border: "2px solid black" }}>{bulkOrderDetail['orderdetails']["cashback_redeemed"]}</td>
                                  </tr>
                                  <tr style={{ border: "2px solid black" }}>
                                      <td style={{ border: "2px solid black" }}>SuperKredit/RazorPay</td>
                                      <td style={{ border: "2px solid black" }}>{(isAlreadyPaid(bulkOrderDetail['orderdetails']["order_payment_mode"]) && !(bulkOrderDetail['orderdetails']["order_payment_mode"]=="Rupifi Credits" || bulkOrderDetail['orderdetails']["order_payment_mode"]=="Finaleap Credits"))?bulkOrderDetail['orderdetails']['net_order_amt']:0}</td>
                                  </tr>
                                  <tr style={{ border: "2px solid black" }}>
                                      <td style={{ border: "2px solid black" }}>Rupifi/Finaleap</td>
                                      <td style={{ border: "2px solid black" }}>{(bulkOrderDetail['orderdetails']["order_payment_mode"]=="Rupifi Credits" || bulkOrderDetail['orderdetails']["order_payment_mode"]=="Finaleap Credits")?bulkOrderDetail['orderdetails']['net_order_amt']:0}</td>
                                  </tr>
                                  <tr style={{ border: "2px solid black" }}>
                                      <td style={{ border: "2px solid black" }}><b>Net Amount Payable</b></td>
                                      <td style={{ border: "2px solid black" }}><b>{isAlreadyPaid(bulkOrderDetail['orderdetails']["order_payment_mode"])?0:Math.round((Number(getTotalInclGST(bulkOrderDetail['orderdetails']['items']))-Number(Number(bulkOrderDetail['orderdetails']["order_disc"])+Number(bulkOrderDetail['orderdetails']["cashback_redeemed"])+Number(bulkOrderDetail['orderdetails']["card_amt"]))).toFixed(2))}</b></td>
                                  </tr>

                              </Table><br/><br/>
                              For Retranz Infolabs Private Limited<br/><br/>
                              <i>(Authorized Signatory)</i>
                          </div></td>
                      </tr></Table>
                      <Table>
                        <tr>
                          <td><img src={footer} alt={"Khetika"} style={{  width: "100%", height: "120px" }} /></td>
                        </tr>
                      </Table>
                  </div>
              </div>
            </div>
          </div>)) : ""}
                {getBackDrop ? (<Backdrop parentLoadStatus={getBackDrop} />) : (null)}
                {newItem && <AddItemModal
                    show={newItem}
                    onHide={() => newItem=false }
                />}
          </div></>);

    }

    if(bulkOrderDetails.length!=0){
      return (<><div>
          <div style={{float:"right"}}>
            <label>Print Quantity</label>
            <select className="form-control" onChange={(e)=>{setNumPrint(e.target.value)}}>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </select>
          </div>
          <ReactToPrint
              trigger={() => <IconButton style={{float:"right"}}><FontAwesomeIcon size="3x" icon={faPrint} /></IconButton>}
              content={() => { return dataToPrint.current }}
              pageStyle={{margin:"50px",border:"2px"}}
          />{bulkOrderPrintUI()}</div></>)
    }else{
      return(<div>{fillerText}</div>)
    }
}

export default BulkOrdersPrint
