import React, { useState, useEffect, useRef } from 'react'
import { Table, Form, Alert, Button, Modal } from 'react-bootstrap'
import database from '../../Configs/firebase'
import { toast } from 'react-toastify';
import Select from 'react-select';

function BulkStatusUpdateConfirmModal({ updateDetails, onHide, ...props }) {
  const [selectedOrders,setSelectedOrders] = useState("");
  const [newStatus,setNewStatus] = useState("Processed");
  console.log('BulkStatusUpdateConfirmModal');
  console.log(updateDetails);
    console.log(props);
    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_LEFT,
    });

    useEffect(() => {
      let count=0;
      let bulkOrderssChecks=document.getElementsByClassName('bulkOrdersCheck');
      let bulkOrderNumbersString="";
      for(let i=0;i<bulkOrderssChecks.length;i++){
        if(bulkOrderssChecks[i].checked){
          count=count+1;
          bulkOrderNumbersString=bulkOrderNumbersString+updateDetails[i].order_number+",";
          if(count%6==0){
            bulkOrderNumbersString=bulkOrderNumbersString+"\n";
          }
        }
      }
      bulkOrderNumbersString = bulkOrderNumbersString.slice(0, -1);
      setSelectedOrders(bulkOrderNumbersString)
    }, [])

    const updateBulkStatusForOrders = async () => {
      let finalStatus="Processed";
      if(newStatus!=undefined && newStatus!=""){
        finalStatus=newStatus;
      }else{
        finalStatus="Processed";
      }
      await props.updateBulkProcessed(finalStatus);
      onHide();

    }


    return (
        <div>
            <Modal
                {...props}
                size="lg"
                onHide={onHide}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Update Status
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        The following orders will be updated :<br/> {selectedOrders}
                    </div>
                    <Table>
                        <tbody>
                          <tr>
                              <td>Status</td>
                              <td><Select defaultValue={{"value":"Processed","label":"Processed"}} options={[{"value":"Processed","label":"Processed"},{"value":"Cancelled","label":"Cancelled"}]} onChange={(val)=>{ setNewStatus(val.value); }}/></td>
                          </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"success"} onClick={updateBulkStatusForOrders}>OK</Button>
                    <Button onClick={onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default BulkStatusUpdateConfirmModal
