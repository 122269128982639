import React, { useState, useEffect } from 'react';
import { Form, InputGroup} from 'react-bootstrap';

function OrderslistSearch({filterOrders,searchOrders,originalOrdersList,countSearchFilter,weightCountSelectedFilter,toatlOrderValueSelected}) {

  const [searchText, setSearchText] = useState("")




  let filterResult = ""

  const searchOrdersListing = () => {

    let weightCountFilter = 0
    let totalOrderValueFilter = 0
    filterResult = searchOrders.filter(obj => {

        const orderNo = obj.order_number;
        const retailerId = obj.retailer_id.toString() ;
        const shopName = obj.shop_name && obj.shop_name.trim().toLowerCase() ;
        const shopPhoneNo = obj.user_name ;
      
        setSearchText(searchText.trim().toLowerCase());
        return orderNo.match(searchText) || retailerId.match(searchText) || shopName.match(searchText)  || shopPhoneNo.match(searchText)
    })
    countSearchFilter(Object.keys(filterResult).length)
    Object.keys(filterResult).forEach(items=>{
      weightCountFilter += filterResult[items].weight? filterResult[items].weight:0
      totalOrderValueFilter += filterResult[items].net_order_amt? parseFloat(filterResult[items].net_order_amt):0
    })
    weightCountSelectedFilter(weightCountFilter)
    toatlOrderValueSelected(totalOrderValueFilter)
    filterOrders(filterResult)

}
useEffect(() => {
    if (searchText!=="")
    searchOrdersListing()
}, [searchText])

  return (
    <div>
      {searchOrders!=="undefined" && <InputGroup size="sm" style={{ width: "500px",padding:"10px",borderRadius:"5px"}}>
            <InputGroup.Prepend>
              <InputGroup.Text>Search</InputGroup.Text>
            </InputGroup.Prepend>
        <Form.Control id="searchProduct"  className="searchBox" placeholder="Order No./Retailer Id/Shop Name/Shop Phone No" value={searchText} onChange={(e) => { setSearchText(e.target.value); if (e.target.value === "") {setSearchText("");filterOrders(originalOrdersList); countSearchFilter(0);weightCountSelectedFilter(0);toatlOrderValueSelected(0)}}} />
        </InputGroup>}
    </div>
  )
}

export default OrderslistSearch
