import React, { useEffect,useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import NavBar from '../Navigation bar/Navbar'
import fire from '../../Configs/firebase'
import Backdrop from '../backdrop/backdrop'
import { toast } from 'react-toastify';
import { InputGroupAddon, Input, InputGroupText, CardHeader, CardTitle, CardText } from 'reactstrap';
import {Tab,Row,Col,Nav,Form,Dropdown,InputGroup,Button,DropdownButton,Breadcrumb,Table,ButtonGroup,Container} from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { validateIsAdminUser } from '../UserCredentials'
import UpdatedDate from '../Orders/UpdatedDate'

function formatDate(date) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

const StockChecker = () => {

  const [ordersObj, setOrdersObj] = useState({});
  const [backDrop, setBackDrop] = useState(false);
  const [selectedCity, setSelectedCity] = useState("All");

  toast.configure({
      autoClose: 4000,
      draggable: true,
      pauseOnHover: true,
      position: toast.POSITION.TOP_LEFT,
  });

  useEffect(() => {
    fetchTransitShippedOrders("All");
  }, [])

  useEffect(() => {
    fetchTransitShippedOrders(selectedCity);
  },[selectedCity])


  const fetchTransitShippedOrders = (cityValue) => {
    setBackDrop(true);

    let ordersHistory=JSON.parse(localStorage.getItem("orderHistory")) || {};
    console.log(ordersHistory);

    let transitOrdersObj = {};
    let itemQtyMap = {};
    Object.keys(ordersHistory).map((rkey,rindex) => {
      let route = "" + rkey;
      if(route.startsWith("98") || route.startsWith("99")){
        let routeOrders = ordersHistory[rkey] || {};

        Object.keys(routeOrders).map((okey,oindex) => {
          let city = routeOrders[okey].city || "";
          let itemID = routeOrders[okey].item_id || "";
          let status = routeOrders[okey].status || "";

          if((cityValue=="All" || cityValue==city) && status.includes("Shipped")){
            if(!itemQtyMap[itemID]){
              itemQtyMap[itemID] = {};
              itemQtyMap[itemID]['shipped_qty'] = 0;
              itemQtyMap[itemID]['delivered_qty'] = 0;
              itemQtyMap[itemID]['returned_qty'] = 0;
            }

            let existingShippedQty = itemQtyMap[itemID]['shipped_qty'] || 0;
            let currShippedQty = Number(routeOrders[okey].item_modified_qty) || 0;
            itemQtyMap[itemID]['shipped_qty'] = existingShippedQty + currShippedQty;

            let existingDeliveredQty = itemQtyMap[itemID]['delivered_qty'] || 0;
            let currDeliveredQty = Number(routeOrders[okey].item_delivered_qty) || 0;
            itemQtyMap[itemID]['delivered_qty'] = existingDeliveredQty + currDeliveredQty;

            itemQtyMap[itemID]['returned_qty'] = itemQtyMap[itemID]['shipped_qty'] - itemQtyMap[itemID]['delivered_qty'];


            let itemele = {};
            itemele['order_date'] = routeOrders[okey].order_date || "";
            itemele['item_id'] = itemID;
            itemele['item_disc'] = routeOrders[okey].item_disc || "";
            itemele['category'] = routeOrders[okey].category || "";
            itemele['sub_category'] = routeOrders[okey].sub_category || "";
            itemele['shipped_qty'] = itemQtyMap[itemID]['shipped_qty'];
            itemele['delivered_qty'] = itemQtyMap[itemID]['delivered_qty'];
            itemele['returned_qty'] = itemQtyMap[itemID]['returned_qty'];
            itemele['inventory'] = "";
            transitOrdersObj[itemID] = itemele;
          }
        })
      }
    })

    setBackDrop(false);
    setOrdersObj(transitOrdersObj);
  }

  const TransitShippedOrdersTableHeader = () => {
    return (
        <thead className="thead-light">
            <tr>
              <th>Date</th>
              <th>Category</th>
              <th>Sub Category</th>
              <th>Item ID</th>
              <th>Item Description</th>
              <th>Shipped Qty</th>
              <th>Delivered Qty</th>
              <th>Returned Qty</th>
              <th>Resultant Inventory</th>
            </tr>
        </thead>
    );
  }

  const TransitShippedOrdersTableBody = () => {
    let rows = Object.keys(ordersObj).map((key, index) => {
        return (
            <tr key={index}>
                <td>{ordersObj[key].order_date}</td>
                <td>{ordersObj[key].category}</td>
                <td>{ordersObj[key].sub_category}</td>
                <td>{ordersObj[key].item_id}</td>
                <td>{ordersObj[key].item_disc}</td>
                <td>{ordersObj[key].shipped_qty}</td>
                <td>{ordersObj[key].delivered_qty}</td>
                <td>{ordersObj[key].returned_qty}</td>
                <td>{ordersObj[key].inventory}</td>
            </tr>
        );
    });

    return (<><tbody>{rows}</tbody></>);
  }



    return (<>
      <NavBar/>
      <div className="container col-sm-12" style={{paddingTop:100}}>
        <center><h2 className="h2">Stock Checker</h2></center>

        <InputGroup size="sm" style={{ margin: "20px" }} >
          <InputGroupAddon addonType="prepend">City</InputGroupAddon>
          <div style={{width: '200px'}}><Select options={[{"value":"All","label":"All"},{"value":"Nashik","label":"Nashik"},{"value":"Surat","label":"Surat"}]} placeholder={selectedCity?selectedCity:"All"} onChange={(val)=>{ setSelectedCity(val.value); }}/></div>
        </InputGroup>

        {Object.keys(ordersObj).length>0?(<table className="table table-striped" style={{textAlign:"center"}}>
            <TransitShippedOrdersTableHeader />
            <TransitShippedOrdersTableBody />
        </table>):(<center><div>No shipped orders</div></center>)}


        {backDrop?(<Backdrop parentLoadStatus={backDrop}/>):(null)}
      </div></>
    )
}

export default StockChecker
