import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { Table, Form, Alert, Button, Modal, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { Collapse, IconButton } from "@material-ui/core";
import ReactToPrint from "react-to-print";
import moment from "moment";
import { service_url, token } from "../../Configs/mysqlconfig";
import { toast } from "react-toastify";
import Select from "react-select";
import {
  redHighlightColor,
  greenHighlightColor,
  orangeHighlightColor,
  sortByTypeCategoryItemId,
} from "../Inventory/common/constants";

import Backdrop from "../backdrop/backdrop";
import ExportToExcel from "../InventoryManagementExport/ExportToExcel";
import ShowDiscrepancyCheckbox from "../Inventory/common/ShowDiscrepancyCheckbox";
const GLOBAL_PARENT_WAREHOUSE = "Bhiwandi";
const userCity = sessionStorage.getItem("userCity") || "";
const userType = sessionStorage.getItem("userType") || "";

function DiscrepancyItemsModal({ transitID, ...props }) {
  const [routeDropDownValues, setRouteDropDownValues] = useState([]);
  const [currentSelectedRoute, setCurrentSelectedRoute] = useState("");
  const [overallRouteSummaryDetailsCopy, setOverallRouteSummaryDetailsCopy] =
    useState({});
  const [overallRouteSummaryDetails, setOverallRouteSummaryDetails] = useState(
    {}
  );
  const [isApprovedQtyFieldsShown, setIsApprovedQtyFieldsShown] =
    useState(false);
  const [isApprovedQtyFieldsEditable, setIsApprovedQtyFieldsEditable] =
    useState(false);
  const [selectedItemId, setSelectedItemId] = useState("");
  const [backDrop, setBackDrop] = useState(false);
  const [showDiscrepancyItemsChecked, setShowDiscrepancyItemsChecked] =
    useState(false);

  const addTransitItemDropdownList = props.addTransitItemDropdownList;
  const productMasterDataRaw = props.productMasterDataRaw;
  const refetchData = props.refetchData;
  const warehouse = props.warehouse;
  const overallDataToPrint = useRef();

  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });

  const setApprovedFieldsStatus = (_route, _overallRouteSummaryDetails) => {
    let _isApprovedFieldsShown = false;
    let _isApprovedFieldsEditable = true;

    for (let routeItems of _overallRouteSummaryDetails[_route]) {
      if (routeItems.received_qty && routeItems.received_qty > 0) {
        _isApprovedFieldsShown = true;
      }
      if (routeItems.approved_qty && routeItems.approved_qty > 0) {
        _isApprovedFieldsEditable = false;
      }
    }

    setIsApprovedQtyFieldsShown(_isApprovedFieldsShown);
    // if (!_isApprovedFieldsEditable && userType === "Admin") {
    //   _isApprovedFieldsEditable = true;
    // }
    setIsApprovedQtyFieldsEditable(_isApprovedFieldsEditable);
  };

  const getApprovedFieldsStatus = (_route, _overallRouteSummaryDetails) => {
    let _isApprovedFieldsShown = false;
    let _isApprovedFieldsEditable = true;

    for (let routeItems of _overallRouteSummaryDetails[_route]) {
      if (routeItems.received_qty && routeItems.received_qty > 0) {
        _isApprovedFieldsShown = true;
      }
      if (routeItems.approved_qty && routeItems.approved_qty > 0) {
        _isApprovedFieldsEditable = false;
      }
    }

    // if (!_isApprovedFieldsEditable && userType === "Admin") {
    //   _isApprovedFieldsEditable = true;
    // }

    return [_isApprovedFieldsShown, _isApprovedFieldsEditable];
  };

  const isBhiwandiUser = () => {
    if (
      (userType === "Operation" && userCity === "") ||
      (userType === "Operation" && userCity === "Bhiwandi") ||
      userCity === "Bhiwandi"
    ) {
      return true;
    }
    return false;
  };

  const itemAlreadyInList = (item_id) => {
    const existingItems = overallRouteSummaryDetails[currentSelectedRoute];
    let itemAlreadyPresent = false;
    existingItems.forEach((item, index) => {
      if (Number(item_id) === Number(item.item_id)) {
        itemAlreadyPresent = true;
      }
    });

    return itemAlreadyPresent;
  };

  const init = () => {
    setBackDrop(true);

    let url = `${service_url}/api/planned-transit-items?transit_id=${transitID}&token=${token}`;

    fetch(url, {
      headers: { "x-access-token": sessionStorage.getItem("x-access-token") },
    })
      .then((res) => res.json())
      .then((result) => {
        let itemsData = result.data || [];
        let _overallRouteSummDetails = {};
        if (userType === "Admin" || isBhiwandiUser()) {
          _overallRouteSummDetails["All"] = [];
        }
        let _routeDropdownValues = [];

        for (let routeItems of itemsData) {
          // console.log("iterating...", routeItems);
          let route = routeItems.route || "";

          if (
            routeItems.received_qty &&
            routeItems.item_modified_qty &&
            routeItems.received_qty !== routeItems.item_modified_qty
          ) {
            routeItems.showApprovedQtyField = true;
          } else {
            routeItems.showApprovedQtyField = false;
          }

          if (!_overallRouteSummDetails[route]) {
            _overallRouteSummDetails[route] = [];
          }

          _overallRouteSummDetails[route].push(routeItems);

          if (userType === "Admin" || isBhiwandiUser()) {
            _overallRouteSummDetails["All"].push(routeItems);
          }
        }

        Object.keys(_overallRouteSummDetails).forEach((key, index) => {
          _overallRouteSummDetails[key] = sortByTypeCategoryItemId(
            _overallRouteSummDetails[key]
          );
        });

        _routeDropdownValues = Object.keys(_overallRouteSummDetails) || [];
        setBackDrop(false);
        setOverallRouteSummaryDetails(_overallRouteSummDetails);
        setOverallRouteSummaryDetailsCopy(
          JSON.parse(JSON.stringify(_overallRouteSummDetails))
        );
        setRouteDropDownValues(_routeDropdownValues);

        setCurrentSelectedRoute(
          currentSelectedRoute || _routeDropdownValues[0] || ""
        );

        if (userType === "Admin" || isBhiwandiUser()) {
          setCurrentSelectedRoute("All");
        }
      })
      .catch((err) => {
        toast(`Error in fetching items: ${err}`, { type: toast.TYPE.ERROR });
      });
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (currentSelectedRoute && overallRouteSummaryDetailsCopy) {
      setApprovedFieldsStatus(
        currentSelectedRoute,
        overallRouteSummaryDetailsCopy
      );
    }
  }, [currentSelectedRoute, overallRouteSummaryDetailsCopy]);

  // const resetInputValuesOnRouteChange = () => {
  //   const initialData = overallRouteSummaryDetailsRaw;
  //   setOverallRouteSummaryDetails({ ...initialData });
  //   setOverallRouteSummaryDetailsCopy({ ...initialData });
  // };

  const addItemToSummaryDetails = (item_id, route) => {
    if (!item_id || !route) {
      return console.log(" Error:  Missing arguements ");
    }

    if (itemAlreadyInList(item_id)) {
      toast(`This item is already present in the list`, {
        type: toast.TYPE.WARNING,
      });
      return;
    }
    const item = productMasterDataRaw[item_id];

    if (!item) {
      return console.log("Error : Unable to retrieve product info");
    }
    const category = item.category || "";
    const brand = item.brand || "";
    const variety = item.variety || "";
    const sub_category = item.sub_category;
    const item_disc = item.description || "";
    const approved_qty = 1;

    const itemToAdd = {
      transit_id: transitID,
      item_id,
      category,
      brand,
      sub_category,
      item_disc,
      route,
      warehouse: userCity || "",
      approved_qty,
      bgColor: greenHighlightColor,
      delivery_date:
        props.dateType === "transit"
          ? moment(props.transit_date).format("YYYY-MM-DD")
          : "",
      transit_date:
        props.dateType === "delivery"
          ? moment(props.delivery_date).format("YYYY-MM-DD")
          : "",
    };
    console.log("ready to add data", itemToAdd);

    toast(`Successfully added new item`, {
      type: toast.TYPE.SUCCESS,
    });
    const existingRouteSummaryDetails = { ...overallRouteSummaryDetails };
    // const existingRouteItemsList = [...routeItemsList];
    // existingRouteItemsList.push(itemToAdd);
    existingRouteSummaryDetails[route].push(itemToAdd);
    setOverallRouteSummaryDetails(existingRouteSummaryDetails);
    setSelectedItemId({});

    // submitNewItemToAPI(itemToAdd)
    //   .then((res) => {})
    //   .catch((err) => {
    //     toast(`Unable to add new item`, {
    //       type: toast.TYPE.ERROR,
    //     });
    //     console.log("Error occured while trying to add new item", err);
    //   });
  };

  const removeItemFromSummaryDetails = (item_id, route, transit_id) => {
    return new Promise((resolve, reject) => {
      let url = `${service_url}/api/transit-route-item?token=${token}`;
      const data = { item_id, route, transit_id };
      console.log("check data", data);

      fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": sessionStorage.getItem("x-access-token"),
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          toast(`Successfully removed item`, {
            type: toast.TYPE.SUCCESS,
          });

          resolve();
        })
        .catch((err) => {
          toast(`Unable to remove item`, {
            type: toast.TYPE.ERROR,
          });
          reject(err);
        });
    });
  };

  const updateItemsQty = () => {
    console.log(transitID);
    let itemsList = [];
    for (let routeItem of overallRouteSummaryDetails[currentSelectedRoute]) {
      let dataToPush = {};
      let item_id = routeItem.item_id;
      let received_qty = routeItem.received_qty;

      if (!received_qty) {
        return toast(`Please fill the quantity for all rows`, {
          type: toast.TYPE.WARNING,
        });
      } else if (received_qty < 0) {
        return toast(`Received quantity cannot be negative`, {
          type: toast.TYPE.ERROR,
        });
      }

      dataToPush = { item_id: item_id, received_qty: received_qty };
      if (isApprovedQtyFieldsShown && isApprovedQtyFieldsEditable) {
        if (routeItem.showApprovedQtyField && !routeItem.approved_qty) {
          return toast(`Please fill the quantity for all rows`, {
            type: toast.TYPE.WARNING,
          });
        } else if (
          routeItem.showApprovedQtyField &&
          routeItem.approved_qty < 0
        ) {
          return toast(`Approved quantity cannot be negative`, {
            type: toast.TYPE.ERROR,
          });
        } else if (routeItem.showApprovedQtyField) {
          dataToPush["approved_qty"] = routeItem.approved_qty;
          itemsList.push(dataToPush);
        }
      } else {
        itemsList.push(dataToPush);
      }
    }

    let url = `${service_url}/api/approved-item-qty?token=${token}`;

    let data = {
      updatedItems: itemsList,
      transit_id: transitID,
      route_id: currentSelectedRoute,
      warehouse: warehouse || "",
      delivery_date:
        props.dateType === "transit"
          ? moment(props.transit_date).format("YYYY-MM-DD")
          : "",
      transit_date:
        props.dateType === "delivery"
          ? moment(props.delivery_date).format("YYYY-MM-DD")
          : "",
    };
    if (isApprovedQtyFieldsShown && isApprovedQtyFieldsEditable) {
      data["approved_by"] = JSON.parse(sessionStorage.getItem(`user`))[0].email;
      data["approved_at"] = new Date().toJSON().slice(0, 19).replace("T", " ");
    } else {
      data["received_by"] = JSON.parse(sessionStorage.getItem(`user`))[0].email;
      data["received_at"] = new Date().toJSON().slice(0, 19).replace("T", " ");
    }

    console.log(data);
    fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": sessionStorage.getItem("x-access-token"),
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success == 1) {
          toast(`${result.message}`, { type: toast.TYPE.SUCCESS });
          refetchData();
          props.onHide();
        } else {
          toast(`${result.message}`, { type: toast.TYPE.ERROR });
        }
      })
      .catch((err) => {
        toast(`Error in creating transit route records: ${err}`, {
          type: toast.TYPE.ERROR,
        });
      });
  };

  const excelHeaders = React.useMemo(
    () => [
      {
        label: "Item ID",
        value: "item_id",
      },
      {
        label: "Type",
        value: "variety",
      },
      {
        label: "Category",
        value: "category",
      },
      {
        label: "Brand",
        value: "brand",
      },
      {
        label: "Sub Category",
        value: "sub_category",
      },

      {
        label: "Description",
        value: "item_disc",
      },
      {
        label: "Shipped Qty",
        value: "item_modified_qty",
      },
      {
        label: "Received Qty",
        value: "received_qty",
      },
      {
        label: "Approved Qty",
        value: "approved_qty",
      },
    ],
    []
  );

  return (
    <div>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Transit Items List
          </Modal.Title>
          <label style={{ marginLeft: "40px", margin: "7px" }}>Route:</label>
          <select
            style={{ marginLeft: "5px", marginTop: "7px" }}
            value={currentSelectedRoute}
            onChange={(event) => {
              setCurrentSelectedRoute(event.currentTarget.value);
            }}
          >
            {routeDropDownValues.map((item) => {
              return (
                <option key={item} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </Modal.Header>

        <Modal.Body>
          {overallRouteSummaryDetails[currentSelectedRoute] &&
          overallRouteSummaryDetails[currentSelectedRoute].length > 0 ? (
            <>
              <div>
                <div
                  className="clearFix"
                  style={{
                    float: "right",
                    marginBottom: "20px",
                    marginLeft: "30px",
                  }}
                >
                  <ExportToExcel
                    sheet1={overallRouteSummaryDetails[currentSelectedRoute]}
                    filename={"transit_stock_transfer_summary"}
                    sheet1Headers={excelHeaders}
                    sheet1Name={warehouse}
                  />
                </div>
                <div
                  className="clearFix"
                  style={{ float: "right", marginBottom: "20px" }}
                >
                  <ShowDiscrepancyCheckbox
                    showDiscrepancyItemsChecked={showDiscrepancyItemsChecked}
                    setShowDiscrepancyItemsChecked={
                      setShowDiscrepancyItemsChecked
                    }
                  />
                </div>

                <RenderTransitRouteTable
                  overallRouteSummaryDetails={overallRouteSummaryDetails}
                  routeDropDownValues={routeDropDownValues}
                  currentSelectedRoute={currentSelectedRoute}
                  removeItemFromSummaryDetails={removeItemFromSummaryDetails}
                  setOverallRouteSummaryDetails={setOverallRouteSummaryDetails}
                  getApprovedFieldsStatus={getApprovedFieldsStatus}
                  init={init}
                  isApprovedQtyFieldsShown={isApprovedQtyFieldsShown}
                  isApprovedQtyFieldsEditable={isApprovedQtyFieldsEditable}
                  showDiscrepancyItemsChecked={showDiscrepancyItemsChecked}
                />
              </div>
            </>
          ) : (
            ""
          )}

          {!isUpdateBtnDisabled(
            userType,
            userCity,
            isApprovedQtyFieldsShown,
            isApprovedQtyFieldsEditable
          ) && !isApprovedQtyFieldsShown ? (
            <>
              <Row style={{ width: "100%" }}>
                <Col style={{ width: "150%" }}>
                  <Select
                    options={addTransitItemDropdownList}
                    value={selectedItemId}
                    onChange={(val) => {
                      console.log("selected value", val);
                      setSelectedItemId(val);
                    }}
                    isClearable={true}
                  />
                </Col>

                <Col style={{ width: "10%" }}>
                  <Button
                    onClick={() => {
                      if (selectedItemId && selectedItemId.value) {
                        addItemToSummaryDetails(
                          selectedItemId.value,
                          currentSelectedRoute
                        );
                      } else {
                        toast(`Please select a valid item id from the list`, {
                          type: toast.TYPE.WARNING,
                        });
                      }
                    }}
                  >
                    Add item
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer>
          {!isUpdateBtnDisabled(
            userType,
            userCity,
            isApprovedQtyFieldsShown,
            isApprovedQtyFieldsEditable
          ) && currentSelectedRoute !== "All" ? (
            <Button
              onClick={() => {
                updateItemsQty();
              }}
            >
              Update
            </Button>
          ) : null}

          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>

      {backDrop ? <Backdrop parentLoadStatus={backDrop} /> : null}
    </div>
  );
}

const submitNewItemToAPI = (data) => {
  return new Promise((resolve, reject) => {
    if (!data) {
      console.log("Did not receive required arguements");
      reject();
      return;
    }

    let url = `${service_url}/api/transit-route-item?token=${token}`;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": sessionStorage.getItem("x-access-token"),
      },
      body: JSON.stringify(data),
    }).then((res) => {
      console.log("check response", res.body);
      resolve();
    });
  });
};

const isUpdateBtnDisabled = (
  user_type,
  user_city,
  is_approved_qty_fields_shown,
  is_approved_qty_fields_editable
) => {
  if (user_type === "Admin") {
    return false;
  } else if (is_approved_qty_fields_shown && !is_approved_qty_fields_editable) {
    return true;
  } else if (!is_approved_qty_fields_shown) {
    //Received Qty Field
    if (user_city === GLOBAL_PARENT_WAREHOUSE) {
      return true;
    } else if (user_type === "Operation" && user_city === "") {
      return true;
    } else {
      return false;
    }
  } else if (is_approved_qty_fields_shown && is_approved_qty_fields_editable) {
    if (
      user_city === GLOBAL_PARENT_WAREHOUSE ||
      (user_type === "Operation" && user_city === "")
    ) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};

const TransitRoutesTableHeader = (props) => {
  const isApprovedQtyFieldsShown = props.isApprovedQtyFieldsShown;

  return (
    <thead className="thead-light">
      <tr>
        <th>Item ID</th>
        <th>Bag ID</th>
        <th>Type</th>
        <th>Category</th>
        <th>Brand</th>
        <th>Sub Category</th>
        <th>Description</th>
        <th>Shipped Qty</th>
        <th>Received Qty</th>
        {isApprovedQtyFieldsShown ? <th>Approved Qty</th> : null}
        {/* <th>Delete</th> */}
      </tr>
    </thead>
  );
};

const ReceivedQtyTableCell = (props) => {
  const {
    isApprovedQtyFieldsShown,
    overallRouteSummaryDetails,
    setOverallRouteSummaryDetails,
    currentRoute,
    currentSelectedRoute,
    row,
    index,
  } = props;

  if (currentSelectedRoute === "All") {
    if (!row.received_qty) {
      return <td>Not Entered</td>;
    }
    return <td>{row.received_qty}</td>;
  }

  return (
    <td>
      <fieldset disabled={isApprovedQtyFieldsShown}>
        <Form.Control
          type="number"
          defaultValue={row.received_qty}
          onChange={(e) => {
            let val = e.target.value;
            let _routeSumDetails = {
              ...overallRouteSummaryDetails,
            };
            _routeSumDetails[currentRoute][index].received_qty = val;
            // if (
            //   row.id &&
            //   _routeSumDetails[currentRoute][index].received_qty !==
            //     row.item_modified_qty
            // ) {
            //   _routeSumDetails[currentRoute][index].bgColor = redHighlightColor;
            // } else if (
            //   row.id &&
            //   _routeSumDetails[currentRoute][index].received_qty ===
            //     row.item_modified_qty
            // ) {
            //   _routeSumDetails[currentRoute][index].bgColor = "white";
            // }
            setOverallRouteSummaryDetails(_routeSumDetails);
          }}
        />
      </fieldset>
    </td>
  );
};

const ApporvedQtyTableCell = (props) => {
  const {
    isApprovedQtyFieldsShown,
    overallRouteSummaryDetails,
    isApprovedQtyFieldsEditable,
    setOverallRouteSummaryDetails,
    currentRoute,
    currentSelectedRoute,
    row,
    index,
  } = props;

  if (currentSelectedRoute === "All") {
    if (
      isApprovedQtyFieldsShown &&
      isApprovedQtyFieldsEditable &&
      !row.approved_qty
    ) {
      return <td>Not Entered</td>;
    }
    return <td>{row.approved_qty}</td>;
  }

  return (
    <>
      {isApprovedQtyFieldsShown && row.showApprovedQtyField ? (
        <td>
          <fieldset disabled={!isApprovedQtyFieldsEditable}>
            <Form.Control
              type="number"
              defaultValue={row.approved_qty}
              onChange={(e) => {
                let _routeSumDetails = {
                  ...overallRouteSummaryDetails,
                };
                _routeSumDetails[currentRoute][index].approved_qty =
                  e.target.value;

                setOverallRouteSummaryDetails(_routeSumDetails);
              }}
            />
          </fieldset>
        </td>
      ) : null}
    </>
  );
};

const checkForApprovedQtyDiscrepancy = (
  row,
  _isAppQtyFieldsEditable,
  _isAppQtyFieldsShown
) => {
  if (_isAppQtyFieldsShown && !_isAppQtyFieldsEditable) {
    if (row.showApprovedQtyField && row.approved_qty !== row.received_qty) {
      return true;
    }
  }
  return false;
};

const getColorBasedOnRowData = (
  row,
  _isAppQtyFieldsEditable,
  _isAppQtyFieldsShown
) => {
  //Approved Qty
  if (!_isAppQtyFieldsEditable && _isAppQtyFieldsShown) {
    if (row.showApprovedQtyField && row.received_qty !== row.approved_qty) {
      return orangeHighlightColor;
    }
  }
  //Received Qty
  if (_isAppQtyFieldsShown && _isAppQtyFieldsEditable) {
    if (
      row.received_qty &&
      row.item_modified_qty &&
      row.received_qty === row.item_modified_qty
    ) {
      return greenHighlightColor;
    } else if (
      row.received_qty &&
      row.item_modified_qty &&
      row.received_qty !== row.item_modified_qty
    ) {
      return redHighlightColor;
    }
  }

  return "white";
};

const TransitRoutesTableBody = ({
  routeProductData,
  overallRouteSummaryDetails,
  setOverallRouteSummaryDetails,
  currentSelectedRoute,
  currentRoute,
  removeItemFromSummaryDetails,
  transitID,
  init,
  isApprovedQtyFieldsShown,
  isApprovedQtyFieldsEditable,
  showDiscrepancyItemsChecked,
}) => {
  let itemsListData = routeProductData;

  // console.log("check current selected route", currentSelectedRoute);
  let rows = itemsListData.map((row, index) => {
    row.bgColor = getColorBasedOnRowData(
      row,
      isApprovedQtyFieldsEditable,
      isApprovedQtyFieldsShown
    );

    if (currentSelectedRoute === "All") {
      row.bgColor = "white";
    }

    if (
      showDiscrepancyItemsChecked &&
      !checkForApprovedQtyDiscrepancy(
        row,
        isApprovedQtyFieldsEditable,
        isApprovedQtyFieldsShown
      )
    ) {
      return null;
    }

    return (
      <tr
        key={index}
        style={{ backgroundColor: row.bgColor ? row.bgColor : "" }}
      >
        <td>{row.item_id}</td>
        <td> </td>
        <td>{row.variety}</td>
        <td>{row.category}</td>
        <td>{row.brand}</td>
        <td>{row.sub_category}</td>
        <td>{row.item_disc}</td>
        <td>{row.item_modified_qty}</td>

        <ReceivedQtyTableCell
          isApprovedQtyFieldsShown={isApprovedQtyFieldsShown}
          overallRouteSummaryDetails={overallRouteSummaryDetails}
          setOverallRouteSummaryDetails={setOverallRouteSummaryDetails}
          currentRoute={currentRoute}
          currentSelectedRoute={currentSelectedRoute}
          row={row}
          index={index}
        />

        {/* <fieldset disabled={isApprovedQtyFieldsShown}>
            <td>
              <Form.Control
                type="number"
                defaultValue={row.received_qty}
                onChange={(e) => {
                  let val = e.target.value;
                  let _routeSumDetails = {
                    ...overallRouteSummaryDetails,
                  };
                  _routeSumDetails[currentRoute][index].received_qty = val;
                  if (
                    row.id &&
                    _routeSumDetails[currentRoute][index].received_qty !==
                      row.item_modified_qty
                  ) {
                    _routeSumDetails[currentRoute][index].bgColor = redHighlightColor;
                  } else if (
                    row.id &&
                    _routeSumDetails[currentRoute][index].received_qty ===
                      row.item_modified_qty
                  ) {
                    _routeSumDetails[currentRoute][index].bgColor = "white";
                  }
                  setOverallRouteSummaryDetails(_routeSumDetails);
                }}
              />
            </td>
          </fieldset> */}

        <ApporvedQtyTableCell
          isApprovedQtyFieldsShown={isApprovedQtyFieldsShown}
          overallRouteSummaryDetails={overallRouteSummaryDetails}
          isApprovedQtyFieldsEditable={isApprovedQtyFieldsEditable}
          setOverallRouteSummaryDetails={setOverallRouteSummaryDetails}
          currentRoute={currentRoute}
          currentSelectedRoute={currentSelectedRoute}
          row={row}
          index={index}
        />

        {/* <>
            {isApprovedQtyFieldsShown && row.showApprovedQtyField ? (
              <td>
                <fieldset disabled={!isApprovedQtyFieldsEditable}>
                  <Form.Control
                    type="number"
                    defaultValue={row.approved_qty}
                    onChange={(e) => {
                      let _routeSumDetails = {
                        ...overallRouteSummaryDetails,
                      };
                      _routeSumDetails[currentRoute][index].approved_qty =
                        e.target.value;
  
                      setOverallRouteSummaryDetails(_routeSumDetails);
                    }}
                  />
                </fieldset>
              </td>
            ) : null}
          </> */}

        {/* <td>
            {" "}
            <Button
              variant="danger"
              onClick={() => {
                removeItemFromSummaryDetails(
                  row.item_id,
                  currentRoute,
                  transitID
                ).then((res) => {
                  init();
                });
              }}
            >
              Delete
            </Button>
          </td> */}
      </tr>
    );
  });

  return (
    <>
      <tbody>{rows}</tbody>
    </>
  );
};

const RenderTransitRouteTable = (props) => {
  const overallRouteSummaryDetails = props.overallRouteSummaryDetails;
  const currentSelectedRoute = props.currentSelectedRoute;
  const removeItemFromSummaryDetails = props.removeItemFromSummaryDetails;
  const setOverallRouteSummaryDetails = props.setOverallRouteSummaryDetails;
  const init = props.init;
  const routeDropDownValues = props.routeDropDownValues;
  const isApprovedQtyFieldsShown = props.isApprovedQtyFieldsShown;
  const isApprovedQtyFieldsEditable = props.isApprovedQtyFieldsEditable;
  const getApprovedFieldsStatus = props.getApprovedFieldsStatus;
  const showDiscrepancyItemsChecked = props.showDiscrepancyItemsChecked;
  console.log("rerender");

  if (currentSelectedRoute !== "All") {
    let routeDataToShow =
      currentSelectedRoute && overallRouteSummaryDetails[currentSelectedRoute]
        ? overallRouteSummaryDetails[currentSelectedRoute]
        : [];

    if (routeDataToShow.length > 0) {
      return (
        <div key={currentSelectedRoute} style={{ pageBreakBefore: "always" }}>
          <center>
            <h4>Route - {currentSelectedRoute}</h4>
          </center>
          <TransitRoutesTable
            routeProductData={routeDataToShow}
            routeDropDownValues={routeDropDownValues}
            currentSelectedRoute={currentSelectedRoute}
            currentRoute={currentSelectedRoute}
            overallRouteSummaryDetails={overallRouteSummaryDetails}
            removeItemFromSummaryDetails={removeItemFromSummaryDetails}
            setOverallRouteSummaryDetails={setOverallRouteSummaryDetails}
            init={init}
            isApprovedQtyFieldsShown={isApprovedQtyFieldsShown}
            isApprovedQtyFieldsEditable={isApprovedQtyFieldsEditable}
            showDiscrepancyItemsChecked={showDiscrepancyItemsChecked}
          />
        </div>
      );
    } else {
      return <></>;
    }
  } else {
    return routeDropDownValues.map((item, index) => {
      if (item === "All") {
        return null;
      }

      let routeDataToShow = item ? overallRouteSummaryDetails[item] : [];

      // routeDataToShow.sort(function (a, b) {
      //   if (a.type < b.type) {
      //     return -1;
      //   }
      //   if (a.type > b.type) {
      //     return 1;
      //   }
      //   return 0;
      // });

      if (routeDataToShow.length > 0) {
        const [isAppQtyFieldsShown, isAppQtyFieldsEditable] =
          getApprovedFieldsStatus(item, overallRouteSummaryDetails);

        return (
          <div key={item} style={{ pageBreakBefore: "always" }}>
            <center>
              <h4>Route - {item}</h4>
            </center>
            <TransitRoutesTable
              routeProductData={routeDataToShow}
              routeDropDownValues={routeDropDownValues}
              currentRoute={item}
              currentSelectedRoute={currentSelectedRoute}
              overallRouteSummaryDetails={overallRouteSummaryDetails}
              removeItemFromSummaryDetails={removeItemFromSummaryDetails}
              setOverallRouteSummaryDetails={setOverallRouteSummaryDetails}
              init={init}
              isApprovedQtyFieldsShown={isAppQtyFieldsShown}
              isApprovedQtyFieldsEditable={isAppQtyFieldsEditable}
              showDiscrepancyItemsChecked={showDiscrepancyItemsChecked}
            />
          </div>
        );
      } else {
        return <></>;
      }
    });
  }
};

const TransitRoutesTable = ({
  routeProductData,
  overallRouteSummaryDetails,
  currentSelectedRoute,
  currentRoute,
  routeDropDownValues,
  setOverallRouteSummaryDetails,
  removeItemFromSummaryDetails,
  transitID,
  init,
  isApprovedQtyFieldsShown,
  isApprovedQtyFieldsEditable,
  showDiscrepancyItemsChecked,
}) => {
  return (
    <Table striped bordered hover style={{ marginBottom: "30px" }}>
      <TransitRoutesTableHeader
        isApprovedQtyFieldsShown={isApprovedQtyFieldsShown}
      />
      <TransitRoutesTableBody
        routeProductData={routeProductData}
        overallRouteSummaryDetails={overallRouteSummaryDetails}
        currentRoute={currentRoute}
        currentSelectedRoute={currentSelectedRoute}
        setOverallRouteSummaryDetails={setOverallRouteSummaryDetails}
        removeItemFromSummaryDetails={removeItemFromSummaryDetails}
        init={init}
        transitID={transitID}
        isApprovedQtyFieldsEditable={isApprovedQtyFieldsEditable}
        isApprovedQtyFieldsShown={isApprovedQtyFieldsShown}
        showDiscrepancyItemsChecked={showDiscrepancyItemsChecked}
      />
    </Table>
  );
};

export default DiscrepancyItemsModal;
