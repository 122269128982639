import React, {
  useState,
  useEffect,
  useRef,
  memo,
  useCallback,
  forwardRef,
} from "react";
import {
  Table,
  Form,
  Alert,
  Button,
  Modal as BootStrapModal,
  Row,
  Col,
} from "react-bootstrap";
import styled from "styled-components";

import { service_url, token } from "../../Configs/mysqlconfig";
import moment from "moment";
import { toast } from "react-toastify";
import { Select } from "react-select";
import {
  Select as MantineSelect,
  Modal,
  Textarea,
  Avatar,
  Group,
  Text,
  NumberInput,
  Checkbox,
} from "@mantine/core";
import Backdrop from "../backdrop/backdrop";
import AddNewProductItem from "./AddNewProductItem";
const GLOBAL_PARENT_WAREHOUSE = "Bhiwandi";
const userCity = sessionStorage.getItem("userCity") || "";
const userType = sessionStorage.getItem("userType") || "";

const targetWarehouseList = [
  { value: "Nashik", label: "Nashik" },
  { value: "Vashi", label: "Vashi" },
  { value: "Virar", label: "Virar" },
  { value: "Mira Road", label: "Mira Road" },
  { value: "Wadala", label: "Wadala" },
  { value: "Goa", label: "Goa" },
  { value: "Bhiwandi", label: "Bhiwandi" },
];

const WarehouseSelectContainer = styled.div`
  width: 15rem;
  margin: 0 1rem;
  margin-bottom: 1.5rem;
  align-self: flex-end;
`;

const CenterText = styled.div`
  text-align: center;
`;

function StockCorrectionCreationModal({ warehouse, ...props }) {
  const [selectedItemId, setSelectedItemId] = useState("");
  const [targetWarehouse, setTargetWarehouse] = useState(warehouse);
  const [backDrop, setBackDrop] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [inventoryMapRaw, setInventoryMapRaw] = useState([]);
  const [inventoryMap, setInventoryMap] = useState([]);
  const defaultStockStatus = "";

  const addTransitItemDropdownList = props.addTransitItemDropdownList;
  const stockDate = props.stockDate;
  const productMasterDataRaw = props.productMasterDataRaw;
  const isModalOpen = props.show;
  const setIsModalOpen = props.onHide;

  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });

  function searchItem(search_term, data) {
    if (!search_term || !data) {
      return console.log("Missing required arguements");
    }
    let filteredData = {};

    search_term = search_term.toLowerCase();

    filteredData = data.filter((item) => {
      if (
        item.item_id.toLowerCase().includes(search_term) ||
        (item.item_disc && item.item_disc.toLowerCase().includes(search_term))
      ) {
        return true;
      }
      return false;
    });

    return filteredData;
  }

  const init = () => {
    setBackDrop(true);

    let url = `${service_url}/api/inventory-movements?start_date=${moment(
      stockDate
    ).format("YYYY-MM-DD")}&end_date=${moment(
      stockDate
    ).format("YYYY-MM-DD")}&warehouse=${targetWarehouse}&token=${token}`;

    // let url = `${service_url}/api/inventory?warehouse=Andheri&inventory_date=2022-09-09&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MTU0OWIwMTIwMWUyZjMzZWE3NmFkZjYiLCJlbWFpbCI6InNtdHdpbmtsZTQ1MkBnbWFpbC5jb20iLCJpYXQiOjE2MzI5MzQ2NTgsImV4cCI6MTYzMjkzODI1OH0._oHr3REme2pjDDdRliArAeVG_HuimbdM5suTw8HI7ut`;

    fetch(url, {
      headers: { "x-access-token": sessionStorage.getItem("x-access-token") },
    })
      .then((res) => res.json())
      .then((result) => {
        let results = result.data || [];
        let inventoryList = [];

        results.forEach((item, index) => {
          item.resultant_inventory = Number(item.resultant_inventory).toFixed(2);
          item.inventory_after_shipped = Number(item.inventory_after_shipped).toFixed(2);
          item.stock_status = defaultStockStatus;
          item.new_stock = 0;
          item.remarks = "";
          item.isEditable = false;
          inventoryList.push(item);
        });

        setBackDrop(false);
        setInventoryMapRaw(inventoryList);
        setInventoryMap(inventoryList);
      })
      .catch((err) => {
        setBackDrop(false);
        toast(`Error in fetching stock correction data : ${err}`, {
          type: toast.TYPE.ERROR,
        });
      });
  };

  useEffect(() => {
    init();
  }, [targetWarehouse]);

  useEffect(() => {
    if (searchTerm === "" || !searchTerm) {
      setInventoryMap([...inventoryMapRaw]);
    } else {
      let search_results = searchItem(searchTerm, inventoryMapRaw);
      setInventoryMap(search_results);
    }
  }, [searchTerm]);

  const updateInventoryState = useCallback((key, value, index) => {
    setInventoryMap((prevState) => {
      let _inventoryMap = [...prevState];
      console.log(value)
      _inventoryMap[index][key] = value;
      return _inventoryMap;
    });

    if (key === "isEditable" && value === false) {
      resetRowFields(index);
    }
  }, []);

  const resetRowFields = useCallback((index) => {
    setInventoryMap((prevState) => {
      let _inventoryMap = [...prevState];
      _inventoryMap[index].stock_status = defaultStockStatus;
      _inventoryMap[index].new_stock = 0;
      _inventoryMap[index].remarks = "";

      return _inventoryMap;
    });
  }, []);

  const itemAlreadyInList = (item_id) => {
    const existingItems = inventoryMapRaw;
    let itemAlreadyPresent = false;
    existingItems.forEach((item, index) => {
      if (Number(item_id) === Number(item.item_id)) {
        itemAlreadyPresent = true;
      }
    });

    return itemAlreadyPresent;
  };

  const addItemToSummaryDetails = (item_id) => {
    if (!item_id) {
      return toast(`Please select item`, {
        type: toast.TYPE.WARNING,
      });
    }

    if (itemAlreadyInList(item_id)) {
      toast(`This item is already present in the list`, {
        type: toast.TYPE.WARNING,
      });
      return;
    }

    const item = productMasterDataRaw[item_id];

    if (!item) {
      return toast(`Unable to retrieve product info`, {
        type: toast.TYPE.WARNING,
      });
    }

    const category = item.category || "";
    const brand = item.brand || "";
    const variety = item.variety || "";
    const sub_category = item.sub_category;
    const item_disc = item.long_description || "";
    const stock_date = moment(stockDate).format("YYYY-MM-DD");
    const stock_status = defaultStockStatus;
    const remarks = "";
    const new_stock = 0;

    const itemToAdd = {
      item_id,
      category,
      variety,
      brand,
      targetWarehouse,
      sub_category,
      item_disc,
      stock_date,
      stock_status,
      remarks,
      new_stock,
      bgColor: "aquamarine",
    };
    // console.log("ready to add data", itemToAdd);

    const _inventoryMap = [...inventoryMap];
    _inventoryMap.push(itemToAdd);
    setInventoryMap(_inventoryMap);
    setSelectedItemId("");
  };

  const createStockCorrection = () => {
    let itemsList = [];

    try {
      inventoryMap.forEach((item, index) => {
        if (item.isEditable) {
          if (item.new_stock === "" || item.remarks.trim() === "") {
            throw new Error("Please enter data for all the enabled fields");
          }

          let itemToSave = {};

          if (item.stock_status === "stock_gain") {
            itemToSave.quantity = item.new_stock * 1;
            console.log(itemToSave.quantity)
          } else if (item.stock_status === "stock_loss") {
            itemToSave.quantity = item.new_stock * -1;
          } else {
            throw new Error(
              "Please select a stock gain/ loss for all the enabled rows"
            );
          }
          itemToSave.item_id = item.item_id;
          itemToSave.inventory_after_shipped = item.inventory_after_shipped;
          itemToSave.inventory = item.resultant_inventory;
          itemToSave.remarks = item.remarks || "";
          itemsList.push(itemToSave);
        }
      });
    } catch (err) {
      return toast(`${err.message}`, {
        type: toast.TYPE.WARNING,
      });
    }

    let dataToSend = {
      stock_date: moment(new Date()).format("YYYY-MM-DD"),
      warehouse: targetWarehouse,
      itemsList,
    };

    let url = `${service_url}/api/stock-correction?token=${token}`;

    console.log("check response", dataToSend);
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": sessionStorage.getItem("x-access-token"),
      },
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success == 1) {
          toast(`${result.message}`, { type: toast.TYPE.SUCCESS });
          setIsModalOpen();
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        } else {
          toast(`${result.message}`, { type: toast.TYPE.ERROR });
        }
      })
      .catch((err) => {
        toast(`Error in creating transit route records: ${err}`, {
          type: toast.TYPE.ERROR,
        });
      });
  };

  return (
    <div>
      <Modal opened={isModalOpen} size="98%" onClose={setIsModalOpen}>
        <h3 style={{ marginBottom: "20px" }} id="contained-modal-title-vcenter">
          Create Stock Correction
        </h3>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "20px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "35%" }} className="search-bar">
            <input
              style={{ width: "100%" }}
              type="text"
              placeholder="Search Item Id/ Description"
              onChange={(event) => {
                setSearchTerm(event.target.value);
              }}
            />
          </div>
          <WarehouseSelectContainer>
            <MantineSelect
              label="Warehouse"
              placeholder="Select"
              value={targetWarehouse}
              data={targetWarehouseList}
              onChange={setTargetWarehouse}
            />
          </WarehouseSelectContainer>
        </div>

        <div>
          {inventoryMap && inventoryMap.length > 0 ? (
            <>
              <div>
                <>
                  <table className="table table-striped">
                    <TransitRoutesTableHeader />
                    <TransitRoutesTableBody
                      inventoryMap={inventoryMap}
                      updateInventoryState={updateInventoryState}
                    />
                  </table>
                </>
              </div>
            </>
          ) : (
            ""
          )}

          {userType === "Admin" || userCity !== GLOBAL_PARENT_WAREHOUSE ? (
            <AddNewProductItem
              addTransitItemDropdownList={addTransitItemDropdownList}
              selectedItemId={selectedItemId}
              setSelectedItemId={setSelectedItemId}
              addItemToSummaryDetails={addItemToSummaryDetails}
            />
          ) : (
            ""
          )}
        </div>
        <BootStrapModal.Footer>
          {userType === "Admin" || userCity !== "Bhiwandi" ? (
            <Button
              style={{ float: "right" }}
              onClick={() => {
                createStockCorrection();
              }}
            >
              Create
            </Button>
          ) : null}

          <Button style={{ float: "right" }} onClick={props.onHide}>
            Close
          </Button>
        </BootStrapModal.Footer>
      </Modal>

      {backDrop ? <Backdrop parentLoadStatus={backDrop} /> : null}
    </div>
  );
}

const TransitRoutesTableHeader = () => {
  return (
    <thead className="thead-light">
      <tr>
        <th>Edit Row</th>
        <th>Item ID</th>
        <th>Item description</th>
        <th>Inventory After Shipped</th>
        <th>Stock Loss/ Gain</th>
        <th>Stock Correction</th>
        <th>Remarks</th>
      </tr>
    </thead>
  );
};

const TransitRoutesTableBody = ({ inventoryMap, updateInventoryState }) => {
  return (
    <>
      <tbody>
        {inventoryMap.map((row, index) => {
          return (
            <StockCorrectionTableRow
              key={index}
              index={index}
              row={{ ...row }}
              updateInventoryState={updateInventoryState}
            />
          );
        })}
      </tbody>
    </>
  );
};

const StockSelectItemUI = forwardRef(
  ({ image, label, value, description, ...others }, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <Avatar src={image} />

        <div>
          <Text
            color={
              value === "stock_gain"
                ? "green"
                : value === "stock_loss"
                ? "red"
                : "black"
            }
            size="sm"
          >
            {label}
          </Text>
        </div>
      </Group>
    </div>
  )
);

const StockCorrectionTableRow = memo(
  (props) => {
    let { updateInventoryState, index, row } = props;
    let uiString = "";
    let uiStringSuffix = "";
    let uiStringColor = "black";

    if (
      "new_stock" in row &&
      row.new_stock &&
      row.new_stock !== "" &&
      row.quantity !== row.new_stock
    ) {
      if (row.stock_status === "stock_loss") {
        uiString = "- ";
        uiStringColor = "red";
        uiStringSuffix = "Loss";
      } else if (row.stock_status === "stock_gain") {
        uiString = "+ ";
        uiStringColor = "green";
        uiStringSuffix = "Gain";
      }
      uiString += row.new_stock || 0;
      row["stockInfoTipUI"] = uiString;
      row["stockInfoTipUIColor"] = uiStringColor;
      row["stockInfoTipUISuffix"] = uiStringSuffix;
    }

    return (
      <tr
        key={index}
        style={{ backgroundColor: row.bgColor ? row.bgColor : "" }}
      >
        <td style={{ textAlign: "center" }}>
          <Checkbox
            value={row.isEditable}
            onChange={(event) => {
              updateInventoryState(
                "isEditable",
                event.currentTarget.checked,
                index
              );
            }}
          />
        </td>
        <td style={{ fontWeight: "bold" }}>{row.item_id}</td>
        <td style={{ fontWeight: "bold" }}>{`${row.item_disc || ""} - ${
          row.brand
        }`}</td>
        <td>{row.inventory_after_shipped}</td>
        <td>

          <MantineSelect
            value={row.stock_status}
            disabled={!row.isEditable}
            itemComponent={StockSelectItemUI}
            onChange={(val) => {
              updateInventoryState("stock_status", val, index);
            }}
            data={[
              {
                value: "stock_gain",
                label: "Stock Gain",
                image: "./increase.png",
              },
              {
                value: "stock_loss",
                label: "Stock Loss",
                image: "./decrease.png",
              },
            ]}
          />
        </td>
        <td>
          <div>
            <NumberInput
              disabled={!row.isEditable}
              value={row.new_stock}
              precision={2}
              onChange={(value) => {
                if (!value && value !== 0) {
                  updateInventoryState("new_stock", "", index);
                } else {
                  updateInventoryState("new_stock", value, index);
                }
              }}
              hideControls
              min={0}
            />

            {row.stockInfoTipUI ? (
              <>
                <span
                  style={{
                    display: "inline-block",
                    marginTop: "5px",
                    color: row.stockInfoTipUIColor,
                  }}
                >
                  {row.stockInfoTipUI || ""}
                </span>

                <span
                  style={{
                    display: "inline-block",
                    marginTop: "5px",
                    color: row.stockInfoTipUIColor,
                    marginLeft: "3px",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  {row.stockInfoTipUISuffix}{" "}
                </span>
              </>
            ) : (
              ""
            )}
          </div>
        </td>
        <td>
          <Textarea
            placeholder="Remarks"
            value={row.remarks}
            disabled={!row.isEditable}
            onChange={(e) => {
              updateInventoryState("remarks", e.target.value, index);
            }}
          />
        </td>
      </tr>
    );
  },
  (prevProps, nextProps) => {
    return JSON.stringify(prevProps.row) === JSON.stringify(nextProps.row);
  }
);

const TransitRoutesTable = ({ inventoryMap, updateInventoryState }) => {
  let inventory_data = [...inventoryMap];
  return <></>;
};

export default StockCorrectionCreationModal;
