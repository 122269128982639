import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function IdleTimeOutModal({showModal, ...props}) {
    console.log(showModal);
    sessionStorage.setItem("modalOpenAt",Date.now());
    const [showPopup,setShowPopup] = useState(showModal);
    const handlePopupClose = () => {
      props.callback();
    }

    return (
        <Modal show={showPopup} onHide={handlePopupClose}>
            <Modal.Header closeButton>
            <Modal.Title>You Have Been Idle!</Modal.Title>
            </Modal.Header>
            <Modal.Body>You session is going to end. Click OK to continue your session</Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handlePopupClose}>
                OK
            </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default IdleTimeOutModal
