import React from "react";
import ReactExport from "react-data-export";
import { Button } from "react-bootstrap";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const userType = sessionStorage.getItem("userType") || "";

class ExportToExcel extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    this.sheet1 = this.props.sheet1;
    this.sheet1Headers = this.props.sheet1Headers;

    this.filename = this.props.filename;
    this.sheet1Name = this.props.sheet1Name;

    return (
      <>
        {userType === "Admin" ? (
          <ExcelFile
            element={<Button variant="warning">Export</Button>}
            filename={this.filename}
          >
            <ExcelSheet data={this.sheet1} name={this.sheet1Name}>
              {Object.keys(this.sheet1Headers).map((key, index) => {
                return (
                  <ExcelColumn
                    key={index}
                    label={this.sheet1Headers[key].label}
                    value={this.sheet1Headers[key].value}
                  />
                );
              })}
            </ExcelSheet>
          </ExcelFile>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default ExportToExcel;
