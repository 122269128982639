import React, { useState,useEffect, useRef, createRef } from 'react';
import fire from '../../Configs/firebase'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Modal,Button,Form,InputGroup,Dropdown,DropdownButton,Card,Accordion} from 'react-bootstrap'
import { InputGroupAddon, Input, InputGroupText, CardHeader, CardTitle, CardText } from 'reactstrap';

function MyVerticallyCenteredModal(props) {

const [getStatus,setStatus] = useState();
const [diffDateSelection, setDiffDateSelection] = useState(false)
const [diffDate, setDiffDate] = useState(new Date())


const handleMainSubmit = (event)=>{
  event.preventDefault();
  event.stopPropagation();
  if(getStatus) {
    if(diffDateSelection){
      props.callback(props.driver_id,props.order_id,{
        'status' : 'Pending',
        'reason' : getStatus,
        'customer_selected_date' : diffDateSelection ? `${diffDate.getDate()}-${diffDate.getMonth()+1}-${diffDate.getFullYear()}` :null
      })
    }else{
      props.callback(props.driver_id,props.order_id,{
        'status' : 'Pending',
        'reason' : getStatus
      })
    }

  } else {
    return;
  }
}

  const submit_ref = createRef()

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Describe the reason for Pending
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleMainSubmit}>
          <Form.Group>
            <InputGroup>
              <Form.Control type="input" value={getStatus} placeholder={"Select an option from the dropdown"} required readOnly/>
              <DropdownButton
                as={InputGroup.Append}
                variant="outline-primary"
                title="Edit"
                >
                <Dropdown.Item href="#" name="Not Visited" onClick={(e)=>{e.preventDefault();setStatus(e.target.name);setDiffDateSelection(false);}}>Not Visited</Dropdown.Item>
                {/* <Dropdown.Item href="#" name="Shop Closed" onClick={(e)=>{e.preventDefault();setStatus(e.target.name);setDiffDateSelection(false);}}>Shop Closed</Dropdown.Item>
                <Dropdown.Item href="#" name="Customer wants on different day" onClick={(e)=>{e.preventDefault();setStatus(e.target.name);setDiffDateSelection(true)}}>Customer wants on different day</Dropdown.Item> */}
              </DropdownButton>
            </InputGroup>
            {diffDateSelection && <InputGroup size="sm" style={{ marginLeft: 10, marginRight: 100 , marginTop:20}}>
            <InputGroupAddon addonType="prepend">Enter Customer Selected Date</InputGroupAddon>
            <DatePicker
              selected={diffDate}
              onChange={(val) => { setDiffDate(val) }}
              minDate={new Date("2017/01/01")}
              aria-describedby="basic-addon1"
              dateFormat="dd/MM/yyyy"
            />

          </InputGroup>}
            </Form.Group>
            <Form.Group>
              <Button type="submit" hidden ref={submit_ref}>Submit</Button>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button
                type="submit" onClick={(e)=>{e.preventDefault();submit_ref.current.click()}}>Submit</Button>
          <Button variant="light" onClick={()=>{setStatus();props.onHide()}}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }



  function App(props) {

    const [modalShow, setModalShow] = React.useState(props.ModalValue);

    return (
        <MyVerticallyCenteredModal
          show={props.ModalValue}
          onHide={() => {setModalShow(false);props.parentCallBack(false)}}
          driver_id = {props.driver_id}
          order_id = {props.order_id}
          childfirebaseInsert = {props.childfirebaseInsert}
          callback = {props.callback}
        />
    );
  }

 export default App
