import React, { useState, useMemo, useEffect, forwardRef } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { service_url } from "../../Configs/mysqlconfig";
import NavBar from "../Navigation bar/Navbar";
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";
import moment from "moment";
import ReactSelect from "react-select";
import fire from "../../Configs/firebase";
import "react-datepicker/dist/react-datepicker.css";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Form,
  InputGroup,
  FormControl,
  Dropdown,
} from "react-bootstrap";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useAsyncDebounce,
} from "react-table";
import axios from "axios";

function Main() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]); 


  // Function to format date to YYYY-MM-DD
const formatDate = (date) => {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

  useEffect(() => {

    const today = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(today.getDate() - 7);

    // Format dates to YYYY-MM-DD
    const startDate = formatDate(sevenDaysAgo);
    const endDate = formatDate(today);
    fire
      .database()
      .ref("Finaleap_Transaction")
      .once("value", (snapshot) => {
        const Finaleap_Transaction = snapshot.val();

        let dataArray = [];
        for (let retailer in Finaleap_Transaction) {
          if (retailer == "creditsBalance") continue; //skip this entry-- wriong data in db
          for (let order_id in Finaleap_Transaction[retailer]) {
            let obj = {
              retailer_id: retailer,
              order_id: order_id,
              ...Finaleap_Transaction[retailer][order_id],
            };
            dataArray.push(obj);
          }
        }

        

        const filteredData = dataArray.filter(item => {
          if (!item.orderDate) return false;
        
          const [day, month, yearTime] = item.orderDate.split('-');
          const [year, time] = yearTime.split(' ');
          const formattedOrderDate = `${year}-${month}-${day}`;
        
          return formattedOrderDate >= startDate && formattedOrderDate <= endDate;
        });
      
        setData(dataArray);
        setLoading(false);
      });
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Retailer id",
        accessor: "retailer_id",
        Filter: ColumnFilter,
      },
      {
        Header: "Order ID",
        accessor: "orderId", 
      },
      {
        Header: "Order Date",
        accessor: "orderDate", 
        Filter: ColumnFilter,
       
  
      },
      {
        Header: "Order Status",
        accessor: "status", 
      },
      {
        Header: "Settlement Amount",
        accessor: "settelmentamount",
      },
      {
        Header: "Credits Balance",
        accessor: "creditsBalance",
      },
      {
        Header: "Credits Used",
        accessor: "creditsUsed",
      },
      {
        Header: "Credits Status",
        accessor: "creditsStatus",
      },
      {
        Header: "Loan ID",
        accessor: "loanId",
      },
      {
        Header: "Transaction Date",
        accessor: "transactionDate",
       
        
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useGlobalFilter
  );

  const { globalFilter } = state;

  const handleFilterChange = useAsyncDebounce((value) => {
  
    setGlobalFilter(value || undefined);
  }, 10);



  const uniqueRetailerIds = useMemo(() => {
    return [...new Set(data.map((item) => item.retailer_id))];
  }, [data]);

  const uniqueOrders = useMemo(() => {
    return [...new Set(data.map((item) => item.orderId))];
  }, [data]);

  useEffect(() => {
    // Update filtered data whenever global filter changes
    setFilteredData(
      data.filter((row) =>
        Object.values(row).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(globalFilter?.toLowerCase())
        )
      )
    );
  }, [globalFilter, data]);

  const dataToExport = filteredData.length > 0 ? filteredData : data;

  return (
    <>
      <NavBar />
      <br />
      <br />
      <br />
      <br />

      <div style={{ margin: 20 }}>
        <h2>FinaleapTransaction</h2>
        <Row>
          <Col>
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Search..."
                value={globalFilter || ""}
                onChange={(e) => {
                  handleFilterChange(e.target.value);
                }}
              />
            </InputGroup>
          </Col>
          <Col>
            <Dropdown>
              <Dropdown.Toggle variant="secondary" id="dropdown-retailer">
                Select Retailer
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setGlobalFilter("")}>
                  All Retailers
                </Dropdown.Item>
                {uniqueRetailerIds.map((retailerId) => (
                  <Dropdown.Item
                    key={retailerId}
                    onClick={() => setGlobalFilter(retailerId)}
                  >
                    {retailerId}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>

          <Col>
            <Dropdown>
              <Dropdown.Toggle variant="secondary" id="dropdown-retailer">
                Select Order Id
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setGlobalFilter("")}>
                  All Order Id
                </Dropdown.Item>
                {uniqueOrders.map((orderId) => (
                  <Dropdown.Item
                    key={orderId}
                    onClick={() => setGlobalFilter(orderId)}
                  >
                    {orderId}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>

          <Col>
            <DateFilter setGlobalFilter={setGlobalFilter} />
          </Col>
          <Col></Col>
          <Col></Col>

          <Col className="text-right">
            <CSVLink data={dataToExport} filename={"Finaleap_Transaction.csv"}>
              <Button variant="warning">Export CSV</Button>
            </CSVLink>
          </Col>
        </Row>
        <Row>
          <Col>
            <table
              {...getTableProps()}
              className="table"
              style={{ border: "solid 1px blue", width: "100%" }}
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    style={{
                      borderBottom: "solid 3px red",
                      background: "aliceblue",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                        {/* <div>{column?.canFilter ? column.render('Filter') : null}</div> */}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      style={{
                        padding: "10px",
                        border: "solid 1px gray",
                        background: "papayawhip",
                      }}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {loading && <div>Loading...</div>}
          </Col>
        </Row>
      </div>

      <ToastContainer />
    </>
  );
}

// Custom filter UI component
function ColumnFilter({ column }) {
  const { filterValue, setFilter } = column;

  return (
    <div>
      <input
        value={filterValue || ""}
        onChange={(e) => setFilter(e.target.value || undefined)}
        placeholder={`Filter ${column.Header}`}
      />
    </div>
  );
}

function DateFilter({ setGlobalFilter }) {
  const [date, setDate] = useState(null);

  const handleDateChange = (date) => {
    const formattedDate = moment(date).format("DD-MM-yyyy");
    setDate(date);
    setGlobalFilter(formattedDate);
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    // <button className="form-control" onClick={onClick} ref={ref}>
    //   Select Order Date
    // </button>

    <Dropdown>
      <Dropdown.Toggle
        className="form-control"
        onClick={onClick}
        ref={ref}
        variant="secondary"
        id="dropdown-retailer"
      >
        Select Order date
      </Dropdown.Toggle>
    </Dropdown>
  ));

  return (
    <div className="datepicker-container">
      <DatePicker
        selected={date}
        onChange={handleDateChange}
        dateFormat="dd-MM-yyyy"
        placeholderText="Select Order Date"
        className="form-control"
        dropdownMode="select"
        customInput={<ExampleCustomInput />}
      />
    </div>
  );
}

export default Main;
