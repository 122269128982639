import React, { Component,useState,useRef } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom'
import Backdrop from '../backdrop/backdrop'
import { IconButton } from '@material-ui/core'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from 'react-select'
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { InputGroupAddon, Input, InputGroupText, CardHeader, CardTitle, CardText } from 'reactstrap';
import { toast } from 'react-toastify';
import ShowParentOrderItemModal from './itemmodal.js'
import ShowReturnOrderItemModal from './returnitemmodal.js'
import { Tab, Row, Col, Form, Dropdown, ProgressBar, Tabs, Image, InputGroup, Button, DropdownButton, Breadcrumb, Table, ButtonGroup, Container, Accordion, Card, Navbar } from 'react-bootstrap'
import { validateIsAdminUser,validateIsOperationUser } from '../UserCredentials'

toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
});


function formatDateTime(datetimeval) {

    let datetimestr = datetimeval.split(' ');
    let datestr = datetimestr[0];
    let timestr = datetimestr[1];
    let timestring=""

    let datestrsplit = datestr.split('-');
    let datestring = datestrsplit[2] + '/' + datestrsplit[1] + '/' + datestrsplit[0];
    if(timestr){
    let timestrsplit = timestr.split(':');
    timestring = ' ' + timestrsplit[0] + ':' + timestrsplit[1];
    }
    return datestring + timestring;
}

String.prototype.padLeft = function (length, character) {
    return new Array(length - this.length + 1).join(character || ' ') + this;
};

Date.prototype.toFormattedString = function () {
    return [String(this.getDate()).padLeft(2, '0'),
            String(this.getMonth()+1).padLeft(2, '0'),
            String(this.getFullYear())].join("/");
};

function formatOrderedBy(orderedBy){
  if(orderedBy){
    let orderedByDotCount=orderedBy.split(".").length - 1;

    if(orderedByDotCount==1){
      let username=orderedBy.split("@")[0];
      username=username.replace(/(^\w|\s\w)/g, m => m.toUpperCase());
      return username;
    }else if(orderedByDotCount==2){
      let usernameSeparator=orderedBy.split("@")[0];
      let usernamelist=usernameSeparator.split(".");
      let firstname=usernamelist[0];
      firstname=firstname.replace(/(^\w|\s\w)/g, m => m.toUpperCase());
      return firstname;
    }
  }else{
    return null;
  }
}


const ReturnOrdrListTableHeader = () => {
    return (
        <thead className="thead-light">
            <tr>
                <th>Parent Order No.</th>
                <th>Order No.</th>
                <th>Retailer Id</th>
                <th>Order Value</th>
                <th>Shop Name</th>
                <th>Shop Phone No.</th>
                <th>Order Date</th>
                <th>Status</th>
                <th>Reason</th>
                <th>OrderedBy</th>
                <th>Delivery Date</th>
                <th>Print</th>
            </tr>
        </thead>
    );
}


const ReturnOrdrListTableBody = props => {
  let userRoutes=sessionStorage.getItem("allowedRoutes")?JSON.parse(sessionStorage.getItem("allowedRoutes")):[];
  console.log(userRoutes);
  let allowedRoutesSet = new Set();

  for(let i=0;i<userRoutes.length;i++){
    let route=`/${userRoutes[i]}`;
    if(!allowedRoutesSet.has(route)){
      allowedRoutesSet.add(route);
    }
  }
  console.log(allowedRoutesSet);
  
    let rows;
    const [showOrderItems,setShowOrderItems] = useState(false);
    const [parentOrderNumber,setParentOrderNumber] = useState("");
    const [showReturnOrderItems,setShowReturnOrderItems] = useState(false);
    const [returnOrderNumber,setReturnOrderNumber] = useState("");

    if (props.orderListData) {
      console.log(props.orderListData);
        rows = props.orderListData.map((row, index) => {
            return (
                <tr key={index}>
                    <td><Button onClick={()=>{setShowOrderItems(true);setParentOrderNumber(row.parent_order_number);}}>{row.parent_order_number}</Button></td>
                    <td><Button onClick={()=>{setShowReturnOrderItems(true);setReturnOrderNumber(row.order_number);}}>{row.order_number}</Button></td>
                    <td>{row.retailer_id}</td>
                    <td>{row.net_order_amt}</td>
                    <td>{row.shop_name}</td>
                    <td>{row.user_name}</td>
                    <td>{formatDateTime(row.order_date_conv)}</td>
                    <td>{row.status}</td>
                    <td>{row.reason}</td>
                    <td>{row.ordered_by?formatOrderedBy(row.ordered_by):null}</td>
                    <td>{row.delivery_date?(new Date(row.delivery_date).toFormattedString()):""}</td>
                    <td>{(allowedRoutesSet.has(`/orderdetails`) || validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email))) && <Link onClick={() => {sessionStorage.setItem("orderIdToPrint", row.order_number);window.open(`/orderdetails`, "_blank");}}><IconButton><FontAwesomeIcon size="1x" icon={faPrint} /></IconButton></Link>}</td>
                </tr>
            );
        });
    } else {
        rows = [];
    }
    return (<><tbody>{rows}</tbody>
      <ShowParentOrderItemModal
          show={showOrderItems}
          onHide={() => setShowOrderItems(false)}
          parentOrderNumber={parentOrderNumber}
          showOrderItems = {showOrderItems}
      />
      <ShowReturnOrderItemModal
          show={showReturnOrderItems}
          onHide={() => setShowReturnOrderItems(false)}
          returnOrderNumber={returnOrderNumber}
          showReturnOrderItems = {showReturnOrderItems}
      />
      </>);
}

const ReturnOrderListTable = (props) => {

    const { orderListData } = props;

    return (
        <table className="table table-striped">
            <ReturnOrdrListTableHeader />
            <ReturnOrdrListTableBody orderListData={orderListData}  />
        </table>
    );
}

export default ReturnOrderListTable
