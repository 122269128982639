import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useMemo,
  memo,
  useCallback,
} from "react";
import {
  Table,
  Form,
  Alert,
  Button,
  Modal as BootstapModal,
  Row,
  Col,
} from "react-bootstrap";

import { service_url, token } from "../../Configs/mysqlconfig";
import moment from "moment";
import { toast } from "react-toastify";
import Select from "react-select";
import {
  Select as MantineSelect,
  Modal,
  Textarea,
  Avatar,
  Group,
  Text,
  NumberInput,
  Checkbox,
  Switch,
} from "@mantine/core";
import Backdrop from "../backdrop/backdrop";
import AddNewProductItem from "./AddNewProductItem";
const GLOBAL_PARENT_WAREHOUSE = "Bhiwandi";
const userCity = sessionStorage.getItem("userCity") || "";
const userType = sessionStorage.getItem("userType") || "";

function StockDiscrepancyApproveModal({
  warehouse,
  ignoreReturnCreationToggle,
  ...props
}) {
  const [selectedItemId, setSelectedItemId] = useState("");
  const [backDrop, setBackDrop] = useState(true);
  const [inventoryMap, setInventoryMap] = useState([]);
  const userLoginType = JSON.parse(sessionStorage.getItem("user"))[0].user_type;

  const addTransitItemDropdownList = props.addTransitItemDropdownList;
  const stockDate = props.stockDate;
  const productMasterDataRaw = props.productMasterDataRaw;
  const isModalOpen = props.show;
  const setIsModalOpen = props.onHide;

  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });

  useEffect(() => {
    setBackDrop(true);
    let stock_date = moment(stockDate).format("YYYY-MM-DD");
    // let url = `${service_url}/api/inventory?inventory_date=${moment(
    //   returnDate
    // ).format("YYYY-MM-DD")}&warehouse=${warehouse}&token=${token}`;

    let url = `${service_url}/api/stock-correction?stock_date=${stock_date}&isAuto=Y&warehouse=${warehouse}&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MTU0OWIwMTIwMWUyZjMzZWE3NmFkZjYiLCJlbWFpbCI6InNtdHdpbmtsZTQ1MkBnbWFpbC5jb20iLCJpYXQiOjE2MzI5MzQ2NTgsImV4cCI6MTYzMjkzODI1OH0._oHr3REme2pjDDdRliArAeVG_HuimbdM5suTw8HI7ut`;

    fetch(url, {
      headers: { "x-access-token": sessionStorage.getItem("x-access-token") },
    })
      .then((res) => res.json())
      .then((result) => {
        const results = result.data || [];

        let inventoryList = results.map((item, index) => {
          if (item.approved_flag && item.approved_flag == "Y") {
            item["isApprovedToggleDisabled"] = true;
          } else {
            item["isApprovedToggleDisabled"] = false;
            item["approved_stock"] = item.return_qty
              ? Number(item.return_qty)
              : Number(item.shipped_qty);
          }
          item["isApproved"] = true;
          return item;
        });

        if (ignoreReturnCreationToggle) {
          inventoryList = inventoryList.filter((item) => {
            if (!item.indicator) return true;
            return !item.indicator.includes("return_creation");
          });
        }
        setInventoryMap(inventoryList);
        setBackDrop(false);
      })
      .catch((err) => {
        setBackDrop(false);
        toast(`Error in fetching stock correction data : ${err}`, {
          type: toast.TYPE.ERROR,
        });
      });
  }, []);

  const approveStockCorrection = () => {
    let itemsList = [];

    for (let item of inventoryMap) {
      if (!item.isApprovedToggleDisabled) {
        if (!item.isApproved && item.approved_stock == item.received_qty) {
          toast(
            `Approved qty cannot be same as the received qty for rejection case`,
            {
              type: toast.TYPE.ERROR,
            }
          );
          return;
        }
      }
    }

    inventoryMap.forEach((item, index) => {
      if (!item.isApprovedToggleDisabled) {
        let itemToSave = {};

        if (!item.isApproved) {
          itemToSave.approved_qty = Number(item.approved_stock);
          itemToSave.case = "rejection";
        } else if (item.isApproved) {
          itemToSave.approved_qty = Number(item.quantity);
        } else {
          throw new Error(
            "Please select a stock gain/ loss for all the enabled rows"
          );
        }

        itemToSave.quantity = item.quantity;
        itemToSave.item_id = item.item_id;
        itemToSave.remarks = item.remarks || "";
        itemToSave.id = item.id;
        itemToSave = {
          ...itemToSave,
          indicator: item.indicator,
          received_qty: item.received_qty,
          old_warehouse: item.old_warehouse,
          shipped_qty: item.shipped_qty,
          transit_id: item.transit_id,
          route: item.route,
          bag_id: item.bag_id,
          return_qty: item.return_qty,
          shipped_qty: item.shipped_qty,
          received_qty: item.received_qty,
        };
        itemsList.push(itemToSave);
      }
    });

    // new Date().toJSON().slice(0, 19).replace("T", " ")
    let dataToSend = {
      stock_date: moment(stockDate).format("YYYY-MM-DD"),
      approved_at: moment().format("YYYY-MM-DD HH:mm:ss"),
      warehouse: warehouse,
      itemsList,
      isAuto: "Y",
    };

    let url = `${service_url}/api/approve-stock-correction?isAuto=Y&token=${token}`;

    console.log("check response", dataToSend);
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": sessionStorage.getItem("x-access-token"),
      },
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success == 1) {
          toast(`${result.message}`, { type: toast.TYPE.SUCCESS });
          // props.onHide();
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        } else {
          toast(`${result.message}`, { type: toast.TYPE.ERROR });
        }
      })
      .catch((err) => {
        toast(`Error in creating transit route records: ${err}`, {
          type: toast.TYPE.ERROR,
        });
      });
  };

  return (
    <div>
      <Modal opened={isModalOpen} size="100%" onClose={setIsModalOpen}>
        <header>
          <h3 id="contained-modal-title-vcenter">Approve Stock Discrepancy</h3>
        </header>
        <body>
          {inventoryMap && inventoryMap.length > 0 ? (
            <>
              <div>
                <TransitRoutesTable
                  inventoryMap={inventoryMap}
                  setInventoryMap={setInventoryMap}
                  warehouse={warehouse}
                />
              </div>
            </>
          ) : (
            ""
          )}
        </body>
        <BootstapModal.Footer>
          {userLoginType === "admin" || userLoginType === "financeHead" || userLoginType === "operation" ? (
            <Button
              onClick={() => {
                approveStockCorrection();
              }}
            >
              Approve
            </Button>
          ) : null}

          <Button onClick={props.onHide}>Close</Button>
        </BootstapModal.Footer>
      </Modal>

      {backDrop ? <Backdrop parentLoadStatus={backDrop} /> : null}
    </div>
  );
}

const StockSelectItemUI = forwardRef(
  ({ image, label, value, description, ...others }, ref) => (
    <div ref={ref} {...others}>
      {/* {console.log("check value", value)} */}
      <Group noWrap>
        <Avatar src={image} />

        <div>
          <Text
            color={
              value === "stock_gain"
                ? "green"
                : value === "stock_loss"
                ? "red"
                : "black"
            }
            size="sm"
          >
            {label}
          </Text>
          {/* <Text size="xs" color="dimmed">
            {description}
          </Text> */}
        </div>
      </Group>
    </div>
  )
);

const TransitRoutesTableHeader = ({ warehouse }) => {
  console.log("debug ", warehouse);
  return (
    <thead className="thead-light">
      <tr>
        <th>Item ID</th>
        <th>Item description</th>
        {warehouse !== "Bhiwandi" && <th>Inventory</th>}
        <th>Stock Loss/ gain</th>
        <th>Shipped Qty</th>
        <th>Received qty</th>
        <th>Status</th>
        <th>Approval Status</th>
        <th></th>
        <th>Remarks</th>
      </tr>
    </thead>
  );
};

const TransitRoutesTableBody = ({
  inventoryMap,
  modifyInventoryData,
  warehouse,
}) => {
  return (
    <>
      <tbody>
        {inventoryMap.map((row, index) => {
          row.stock_status = row.quantity > 0 ? "stock_gain" : "stock_loss";
          return (
            <TransitRoutesTableRow
              key={row.id}
              row={{ ...row }}
              index={index}
              modifyInventoryData={modifyInventoryData}
              warehouse={warehouse}
            />
          );
        })}
      </tbody>
    </>
  );
};

const TransitRoutesTableRow = ({
  row,
  index,
  modifyInventoryData,
  warehouse,
}) => {
  // const [approvedToggleUI, setApprovedToggleUI] = useState(false);
  const stock_loss_gain = row.approved_qty ?? row.quantity;
  return (
    <tr key={index} style={{ backgroundColor: row.bgColor ? row.bgColor : "" }}>
      <td style={{ fontWeight: "bold" }}>{row.item_id}</td>
      <td style={{ fontWeight: "bold" }}>{row.item_disc}</td>
      {warehouse !== "Bhiwandi" && (
        <td style={{ fontWeight: "bold" }}>{row.inventory}</td>
      )}

      <td style={{ fontWeight: "bold" }}>{stock_loss_gain}</td>

      <td style={{ fontWeight: "bold" }}>
        {row.return_qty || row.shipped_qty}
      </td>

      <td style={{ fontWeight: "bold" }}>{row.received_qty}</td>
      {row.isApprovedToggleDisabled ? (
        <td style={{ color: "green", fontWeight: "bold" }}>Approved</td>
      ) : (
        <td style={{ color: "red", fontWeight: "bold" }}>Pending approval</td>
      )}

      <td>
        <fieldset disabled={row.isApprovedToggleDisabled}>
          <Switch
            size="md"
            offLabel="No"
            onLabel="Yes"
            color="green"
            checked={row.isApproved}
            onChange={(event) => {
              const rowData = JSON.parse(JSON.stringify(row));
              rowData.isApproved = event.currentTarget.checked;
              modifyInventoryData(rowData, index);
            }}
          />
        </fieldset>
      </td>

      <td>
        {!row.isApproved && (
          <NumberInput
            value={row.approved_stock}
            placeholder="Enter quantity"
            label="Quantity"
            disabled={row.isApprovedToggleDisabled}
            withAsterisk
            onChange={(val) => {
              if (val || val == 0) {
                const rowData = JSON.parse(JSON.stringify(row));
                rowData.approved_stock = val;
                modifyInventoryData(rowData, index);
              }
            }}
            hideControls
          />
        )}
      </td>

      <td>
        <Textarea
          placeholder="Remarks"
          onChange={(event) => {
            const rowData = { ...row };
            rowData.remarks = event.currentTarget.value;
            modifyInventoryData(rowData, index);
          }}
        />
      </td>
    </tr>
  );
};

const TransitRoutesTable = ({ inventoryMap, setInventoryMap, warehouse }) => {
  const modifyInventoryData = useCallback(
    (row_data, index) => {
      const _inventoryMapCopy = [...inventoryMap];
      _inventoryMapCopy[index] = { ...row_data };
      setInventoryMap(_inventoryMapCopy);
    },
    [inventoryMap]
  );
  return (
    <>
      <table style={{ fontSize: "13px" }} className="table table-striped">
        <TransitRoutesTableHeader warehouse={warehouse} />
        <TransitRoutesTableBody
          inventoryMap={inventoryMap}
          modifyInventoryData={modifyInventoryData}
          warehouse={warehouse}
        />
      </table>
    </>
  );
};

export default StockDiscrepancyApproveModal;
