import React, { Component, useState, useRef } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom'
import Backdrop from '../backdrop/backdrop'
import { IconButton } from '@material-ui/core'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint, faCube, faCubes } from '@fortawesome/free-solid-svg-icons';
import { InputGroupAddon, Input, InputGroupText, CardHeader, CardTitle, CardText } from 'reactstrap';
import { Tab, Row, Col, Form, Dropdown, ProgressBar, Tabs, Image, InputGroup, Button, DropdownButton, Breadcrumb, Table, ButtonGroup, Container, Accordion, Card, Navbar } from 'react-bootstrap'
import UpdateRetailersModal from './UpdateRetailers';
import OrderItemsListingModal from './OrderItemsListingModal';
import { validateIsAdminUser,validateIsSystemsUser } from '../UserCredentials'

let bulkPrintOrdersList = [];

function formatDateTime(datetimeval) {

    let datetimestr = datetimeval.split(' ');
    let datestr = datetimestr[0];
    let timestr = datetimestr[1];
    let timestring = ""

    let datestrsplit = datestr.split('-');
    let datestring = datestrsplit[2] + '/' + datestrsplit[1] + '/' + datestrsplit[0];
    if (timestr) {
        let timestrsplit = timestr.split(':');
        timestring = ' ' + timestrsplit[0] + ':' + timestrsplit[1];
    }
    return datestring + timestring;
}



const OrdrListTableHeader = (props) => {
    const [allOptionsChecked, setAllOptionsChecked] = useState(false)
    const checkRef = useRef();
    sessionStorage.setItem(`allCheckBoxSelected`, allOptionsChecked ? allOptionsChecked : false)

    return (
        <thead className="thead-light">
            <tr>
                <th><input type="checkbox" id="selectAllCheckbox" ref={checkRef} onChange={() => {
                    let bulkOrdersChecks = document.getElementsByClassName('bulkOrdersCheck');
                    if(allOptionsChecked)
                    setAllOptionsChecked(false)
                    else
                    setAllOptionsChecked(true)
                    for (let i = 0; i < bulkOrdersChecks.length; i++) {
                        if (checkRef.current.checked) {
                            bulkOrdersChecks[i].checked = true;
                        } else {
                            bulkOrdersChecks[i].checked = false;
                        }
                    }
                }} /></th>
                <th>OrderNumber</th>
                <th>Retailer Id</th>
                {props.isNewRetailerOrder==true && <th>Latitude</th>}
                {props.isNewRetailerOrder==true && <th>Longitude</th>}
                <th>OrderValue</th>
                <th>ShopName</th>
                <th>Weight(Kg)</th>
                <th>OrderDate</th>
                <th>Status</th>
                <th>Payment Mode</th>
                <th>Update Retailers</th>
                {(validateIsSystemsUser((JSON.parse(sessionStorage.getItem(`user`))[0].email)) || validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email))) && <th>Print Orders</th>}
                <th>Products</th>
            </tr>
        </thead>
    );

}

const OrdrListTableBody = props => {
  let userRoutes=sessionStorage.getItem("allowedRoutes")?JSON.parse(sessionStorage.getItem("allowedRoutes")):[];
  console.log(userRoutes);
  let allowedRoutesSet = new Set();

  for(let i=0;i<userRoutes.length;i++){
    let route=`/${userRoutes[i]}`;
    if(!allowedRoutesSet.has(route)){
      allowedRoutesSet.add(route);
    }
  }
  console.log(allowedRoutesSet);

    const checkBodyRef = useRef()

    const [updateRetailers, setUpdateRetailers] = useState(false)
    const [retailerDetails, setRetailerDetails] = useState("")
    const [orderNumberChecked, setOrderNumberChecked] = useState({})
    const [itemModalPopupShow, setItemModalPopupShow] = useState(false);
    const [itemDetails,setItemDetails] = useState([]);
    const [orderAmt,setOrderAmt] = useState(0);
    const [orderNum,setOrderNum] = useState("");
    let [orderValueOnCheck, setOrderValueOnCheck] = useState(0)
    let [orderCountOnCheck, setOrderCountOnCheck] = useState(0)
    let [orderWeightOnCheck, setOrderWeightOnCheck] = useState(0)



    let orderSelecetionCounter = 0
    //check if table is empty
    let bulkOrderNumbers = '';
    let rows;


    if (props.orderListData) {

        rows = props.orderListData.map((row, index) => {
          let smallPackItemsList=[];
          let otherItemsList=[];

          let itemsStr=row.items_list;
          let itemsList=itemsStr.split(",");

          let varietyStr=row.variety_list;
          let varietyList=varietyStr.split(",");

          let weightStr=row.weight_list;
          let weightList=weightStr.split(",");

          let quantityStr=row.quantity_list;
          let quantityList=quantityStr.split(",");

          let itemAmtStr=row.item_amt_list;
          let itemAmtList=[];
          if(itemAmtStr){
            itemAmtList=itemAmtStr.split(",");
          }else{
            for(let i=0;i<itemsList.length;i++){
              itemAmtList.push(0);
            }
          }


          for(let i=0;i<itemsList.length;i++){
            let ele={};
            ele['item_disc']=itemsList[i];
            ele['weight']=weightList[i];
            ele['quantity']=quantityList[i];
            ele['variety']=varietyList[i];
            ele['item_amt']=itemAmtList[i];

            if(varietyList[i]=="SMALL PACK"){
              smallPackItemsList.push(ele);
            }else{
              otherItemsList.push(ele);
            }
          }

          let finalItemsList=smallPackItemsList.concat(otherItemsList);

          let trowBgColor = "table-light";

          if(props.getCutoffOrderObj['order_number']==row.order_number){
            trowBgColor = "table-warning";
          }else{
            trowBgColor = "table-light";
          }

            return (
                <tr style={{verticalAlign: "middle", display: "tableCell"}} class={trowBgColor} key={index}>

                    <td><input type="checkbox" name={row.orderid} id="bulkOrdersVerify" className="bulkOrdersCheck" ref={checkBodyRef} onClick={(val) => {
                        setOrderNumberChecked({ ...orderNumberChecked, [row.order_number]: true })

                        // document.getElementById(`bulkOrdersVerify`).setAttribute("checked","false")


                        if (document.getElementsByClassName("bulkOrdersCheck")[index].checked) {

                            setOrderValueOnCheck(orderValueOnCheck + parseFloat(row.net_order_amt))
                            setOrderCountOnCheck(orderCountOnCheck + 1)
                            setOrderWeightOnCheck(row.weight && orderWeightOnCheck + parseFloat(row.weight))
                        }
                        else {

                            setOrderValueOnCheck(orderValueOnCheck - parseFloat(row.net_order_amt))
                            setOrderCountOnCheck(orderCountOnCheck - 1)
                            setOrderWeightOnCheck(row.weight && orderWeightOnCheck - parseFloat(row.weight))

                        }



                    }} /></td>
                    <td>{row.order_number}</td>
                    <td>{row.retailer_id}</td>
                    {props.isNewRetailerOrder==true && <td>{row.latitude}</td>}
                    {props.isNewRetailerOrder==true && <td>{row.longitude}</td>}
                    <td>{row.net_order_amt}</td>
                    <td>{row.shop_name}</td>
                    <td>{row.weight && (row.weight).toFixed(2)}</td>
                    <td>{formatDateTime(row.order_date_conv)}</td>
                    <td>{row.status}</td>
                    <td>{row.order_payment_mode}</td>
                    <td><Button onClick={() => { setUpdateRetailers(true); setRetailerDetails(row) }}>Update</Button></td>
                    {(validateIsSystemsUser((JSON.parse(sessionStorage.getItem(`user`))[0].email)) || validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email))) && row.order_number.length===15 && !row.order_number.includes("S") && !row.order_number.includes("A") && !row.order_number.includes("B") && !row.order_number.includes("C") && !row.order_number.includes("R")?<td><Link to="#" onClick={() => {sessionStorage.setItem("orderIdToPrint", row.order_number);window.open(`/khetikaInvoice`, "_blank");}}><IconButton><FontAwesomeIcon size="1x" icon={faPrint} /></IconButton></Link></td>
                    :(validateIsSystemsUser((JSON.parse(sessionStorage.getItem(`user`))[0].email)) || validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email)))?(<td><Link to="#" onClick={() => {sessionStorage.setItem("orderIdToPrint", row.order_number);window.open(`/orderdetails`, "_blank");}}><IconButton><FontAwesomeIcon size="1x" icon={faPrint} /></IconButton></Link></td>):(<td></td>)}
                    <td>
                        <Link onClick={() => { setItemModalPopupShow(true);setItemDetails(finalItemsList);setOrderNum(row.order_number);setOrderAmt(row.net_order_amt); }}>
                            <IconButton>
                              {smallPackItemsList.length>0?<FontAwesomeIcon size="1x" icon={faCubes} />:null}
                              {otherItemsList.length>0?<FontAwesomeIcon size="1x" icon={faCube} />:null}
                            </IconButton>
                        </Link>
                    </td>
                </tr>
            );
        });

        props.totalOrderWtOnCheck(orderWeightOnCheck)
        props.totalOrderCountOnCheck(orderCountOnCheck)
        props.totalOrderValueOnCheck(orderValueOnCheck)
        props.orderSelectionCounter(orderSelecetionCounter)
        sessionStorage.setItem(`bulkPrintConfirm`, JSON.stringify(orderNumberChecked))
    }
    else if (props.filteredDataDisplay !== "") {
        rows = props.filteredDataDisplay.map((row, index) => {

          let smallPackItemsList=[];
          let otherItemsList=[];

          let itemsStr=row.items_list;
          let itemsList=itemsStr.split(",");

          let varietyStr=row.variety_list;
          let varietyList=varietyStr.split(",");

          let weightStr=row.weight_list;
          let weightList=weightStr.split(",");

          let quantityStr=row.quantity_list;
          let quantityList=quantityStr.split(",");

          let itemAmtStr=row.item_amt_list;
          let itemAmtList=itemAmtStr.split(",");



          for(let i=0;i<itemsList.length;i++){
            let ele={};
            ele['item_disc']=itemsList[i];
            ele['weight']=weightList[i];
            ele['quantity']=quantityList[i];
            ele['variety']=varietyList[i];
            ele['item_amt']=itemAmtList[i];

            if(varietyList[i]=="SMALL PACK"){
              smallPackItemsList.push(ele);
            }else{
              otherItemsList.push(ele);
            }
          }

          let finalItemsList=smallPackItemsList.concat(otherItemsList);

          let trowBgColor = "table-light";

          if(props.getCutoffOrderObj['order_number']==row.order_number){
            trowBgColor = "table-warning";
          }else{
            trowBgColor = "table-light";
          }

            return (
                <tr class={trowBgColor} key={index}>
                    <td><input type="checkbox" name={row.orderid} checked className="bulkOrdersCheck" ref={checkBodyRef} onClick={(val) => {
                        setOrderNumberChecked({ ...orderNumberChecked, [row.order_number]: true })
                    }} /></td>
                    <td>{row.order_number}</td>
                    <td>{row.retailer_id}</td>
                    {props.isNewRetailerOrder==true && <td>{row.latitude}</td>}
                    {props.isNewRetailerOrder==true && <td>{row.longitude}</td>}
                    <td>{row.net_order_amt}</td>
                    <td>{row.shop_name}</td>
                    <td>{row.weight && (row.weight).toFixed(2)}</td>
                    <td>{formatDateTime(row.order_date_conv)}</td>
                    <td>{row.status}</td>
                    <td>{row.order_payment_mode}</td>
                    <td><Button onClick={() => { setUpdateRetailers(true); setRetailerDetails(row) }}>Update</Button></td>
                    <td>{(validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email))) && <Button onClick={() => {sessionStorage.setItem("orderIdToPrint", row.order_number);window.open(`/orderdetails`, "_blank");}}><IconButton><FontAwesomeIcon size="1x" icon={faPrint} /></IconButton></Button>}</td>
                    <td>
                        <Link onClick={() => { setItemModalPopupShow(true);setItemDetails(finalItemsList);setOrderNum(row.order_number);setOrderAmt(row.net_order_amt); }}>
                            <IconButton>
                              {smallPackItemsList.length>0?<FontAwesomeIcon size="1x" icon={faCubes} />:null}
                              {otherItemsList.length>0?<FontAwesomeIcon size="1x" icon={faCube} />:null}
                            </IconButton>
                        </Link>
                    </td>
                </tr>
            );
        });
        props.orderSelectionCounter(orderSelecetionCounter)
        sessionStorage.setItem(`bulkPrintConfirm`, JSON.stringify(orderNumberChecked))
    }
    else {
        rows = [];
    }
    return (<><tbody>{rows}</tbody>
        {updateRetailers && <UpdateRetailersModal
            show={updateRetailers}
            onHide={() => setUpdateRetailers(false)}
            updateDetails={retailerDetails}
        />}
        {itemModalPopupShow && <OrderItemsListingModal
            show={itemModalPopupShow}
            onHide={() => setItemModalPopupShow(false)}
            itemDetails={itemDetails}
            orderNum={orderNum}
            orderAmt={orderAmt}
        />}
    </>);
}

const OrderListTable = (props) => {

    const { orderListData, isNewRetailerOrder, getCutoffOrderObj } = props;

    return (
        <>
            <table className="table table-striped">
                <OrdrListTableHeader isNewRetailerOrder={isNewRetailerOrder} />
                <OrdrListTableBody orderListData={orderListData} orderSelectionCounter={props.orderSelectionCounter} totalOrderValueOnCheck={props.totalOrderValueOnCheck} totalOrderCountOnCheck={props.totalOrderCountOnCheck} totalOrderWtOnCheck={props.totalOrderWtOnCheck} isNewRetailerOrder={isNewRetailerOrder} getCutoffOrderObj={getCutoffOrderObj} />
            </table>
        </>
    );
}

export default OrderListTable
