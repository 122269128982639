import React, { useState, useEffect, useRef } from "react";
import {
  Breadcrumb,
  Navbar,
  Dropdown,
  DropdownButton,
  Alert,
  Table,
  Button,
  Form,
  Jumbotron,
  InputGroup,
  Accordion,
  Toast,
  Badge,
  Modal
} from "react-bootstrap"; 
import Spinner from 'react-bootstrap/Spinner';
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";

import NavBar from "../Navigation bar/Navbar";
import { InputGroupAddon, Input } from "reactstrap";
import DatePicker from "react-datepicker";
import fire from "../../Configs/firebase";
import { support_portal_url,warehouseNames } from "../../Configs/mysqlconfig";
import Select from "react-select";

import ReactToPrint from "react-to-print";
import Barcode from "react-barcode";
import QRCode from "react-qr-code";

import XLSX from "xlsx";

import HighValueCrateItemsModal from "./highValueCrateItemsModal";
// import HighValuePrintBody from "./highValuePrint"



const warehouseOptionsList = warehouseNames.map((warehouse)=>({ label: warehouse, value: warehouse }))



function formatDate(date) {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}


export default function Home() {

  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isDataNotFound, setIsDataNotFound] = useState(false);

  let today = new Date();
  let tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [deliveryPlanDate, setDeliveryPlanDate] = useState(today);

  const [warehouse, setWarehouse] = useState({label:"All",value:"All"})

  const [route, setRoute] = useState([{label:"All",value:"All"}]);
  const [routeOptionsList, setRouteOptionsList] = useState([{label:"All",value:"All"}]);
  
  const [allRouteWiseData,setAllRouteWiseData] = useState({})
  const [filteredRouteWiseData,setFilteredRouteWiseData] = useState({})

  const [routeAndCrateWiseData, setRouteAndCrateWiseData] = useState({})

  const [exportData, setExportData] = useState(false);

  const handleRouteFiltering = (selectedRoute) => {

    if (selectedRoute.value && selectedRoute.value !== "All") {

      // if warehouse value is present then check if that has that warehouse is present for that route
      if (warehouse.value && warehouse.value !== "All") {

          const listOfValues = allRouteWiseData[selectedRoute.value]

          if (listOfValues.length > 0 && listOfValues[0]?.city === warehouse.value) {
            setFilteredRouteWiseData({
              [selectedRoute.value]: listOfValues
            });
          }
          else
          {
            setFilteredRouteWiseData({});
          }
  
      }
        else{
          // if warehouse is not present then make key of that route and get that respective key value
          setFilteredRouteWiseData({
            [selectedRoute.value]: allRouteWiseData[selectedRoute.value]
          });
        }
    
    } else {
      let allSelectedWareHouseObjects={...allRouteWiseData}

      if (warehouse.value && warehouse.value !== "All") {
       allSelectedWareHouseObjects = Object.entries(allRouteWiseData).reduce((acc, [key, listOfValues]) => {
          if (listOfValues.length > 0 && listOfValues[0]?.city === warehouse.value) {
            acc[key] = listOfValues;
          }
          return acc;
        }, {});
      }
        setFilteredRouteWiseData(allSelectedWareHouseObjects);
    }

  };


  const handleWareHouseFiltering = (selectedWareHouse) => {

    if (selectedWareHouse.value && selectedWareHouse.value !== "All") {
      const allSelectedWareHouseObjects = Object.entries(allRouteWiseData).reduce((acc, [key, listOfValues]) => {
        if (listOfValues.length > 0 && listOfValues[0]?.city === selectedWareHouse.value) {
          acc[key] = listOfValues;
        }
        return acc;
      }, {});

        // check if there is route value 
        // if route pressent then check if that warehouse has that route
          if (route.value && route.value !== "All") 
            {
          route.value in allSelectedWareHouseObjects ? setFilteredRouteWiseData({[route.value]: allSelectedWareHouseObjects[route.value]}) : setFilteredRouteWiseData({});
            }
          else
            {
            setFilteredRouteWiseData(allSelectedWareHouseObjects);
            }

    } else {
      
      setFilteredRouteWiseData(allRouteWiseData);
    }
  };



//   function exportFilteredHighValue() {
//     // Create a new workbook
//     const wb = XLSX.utils.book_new();

//     // Iterate over each key in the filteredRouteWiseData
//     for (const [key, data] of Object.entries(filteredRouteWiseData)) {
//         // Create a worksheet for the current key with its associated data
//         const ws = XLSX.utils.json_to_sheet(data);
        
//         // Append the worksheet to the workbook
//         XLSX.utils.book_append_sheet(wb, ws, key);
//     }

//     // Export the workbook
//     XLSX.writeFile(wb, 'HighValuePacks_RouteWiseData.xlsx');
// }

function exportFilteredHighValue() {
  // Create a new workbook
  const wb = XLSX.utils.book_new();

  // Create an array to hold all the data
  let allData = [];

  // Iterate over each key in the filteredRouteWiseData
  for (const [route, data] of Object.entries(filteredRouteWiseData)) {
      // Add the route information to each row
      const routeData = data.map(item => ({ Route: route, ...item }));
      allData = allData.concat(routeData);
  }

  // Create a single worksheet with all the data
  const ws = XLSX.utils.json_to_sheet(allData);
  
  // Append the worksheet to the workbook
  XLSX.utils.book_append_sheet(wb, ws, 'All Routes');

  // Export the workbook
  XLSX.writeFile(wb, 'HighValuePacks_AllRoutesData.xlsx');
}

  const getNextCrateId = (day, month, prevBagId) => {
    // month-1 because we want to start from 0 and month value here will start from 1
    const monthChar = String.fromCharCode("A".charCodeAt(0) + (month-1));
    // day-1 because day starts with 1
    const dayChar = String.fromCharCode("A".charCodeAt(0) + ((day-1) % 20));

    let nextBagIdNumber;
    if (prevBagId) {
      const prevBagIdNumber = prevBagId.substring(2, 4);
      nextBagIdNumber = Number(prevBagIdNumber) + 1;
    } else {
      nextBagIdNumber = "01";
    }

    return `${monthChar}${dayChar}${nextBagIdNumber
      .toString()
      .padStart(2, "0")}`;
  };



const storeCrateIdsInFb = async (day, month, year, deliveryDate) => {
  const baseRef = fire.database().ref(`Picking_Orders_History/${year}/${month}/${deliveryDate}/`);

  try {
    const snapshot = await baseRef.once("value");
    const pickingOrdersHistory = snapshot.val() || {};

    let lastAssignedBagId = null;
    let bagIdsSet = new Set();

    for (const [checkHighValueString, ordersByCategory] of Object.entries(pickingOrdersHistory)) {
      if (checkHighValueString.includes("HIGH_VALUE")) {
        for (const item of Object.values(ordersByCategory)) {
          if (item.crate_id) {
            bagIdsSet.add(item.crate_id.toUpperCase());
          }
        }
      }
    }

    if (bagIdsSet.size > 0) {
      // Convert Set to Array, sort in descending order, and get the first element
      lastAssignedBagId = Array.from(bagIdsSet).sort((a, b) => b.localeCompare(a))[0];
    }


    const updatePromises = [];

    for (const [key, value] of Object.entries(pickingOrdersHistory)) {
      if (key.includes("HIGH_VALUE")) {
        let count = 1
        const getCrateId = getNextCrateId(day, month, lastAssignedBagId)

        for (const highValueKey of Object.keys(value)) {
          const allHighvalueInThatRouteLength = Object.keys(value).length
          const highValueRef = baseRef.child(`${key}/${highValueKey}`);
          const crateIdSnapshot = await highValueRef.child('crate_id').once('value');

          if (!crateIdSnapshot.exists()) {
            const fbUpdateOrderData = {
              crate_id: getCrateId //+`-${count}-${allHighvalueInThatRouteLength}`
            };

            // update all values according to the latest crate id for all if one of the crate id value id missing
            for (const highValueKey_2 of Object.keys(value)) {
              const highValueRef_2 = baseRef.child(`${key}/${highValueKey_2}`);
              updatePromises.push(highValueRef_2.update(fbUpdateOrderData)); // update the same value for all the child nodes of that parent
            }
          
           bagIdsSet.add(getCrateId)
           lastAssignedBagId = Array.from(bagIdsSet).sort((a, b) => b.localeCompare(a))[0];
           count+=1
          }

        }
      }
    }

    await Promise.all(updatePromises);
    console.log("All updates completed successfully");
  } catch (error) {
    console.error("Error in storeCrateIdsInFb:", error);
    throw error; // Re-throw for caller to handle if needed
  }
};


useEffect(()=>{
  fetchHighValueOrders(new Date())
},[])

useEffect(() => {
  if (exportData) 
  {
    exportFilteredHighValue();
    setExportData(false)
  }
}, [exportData]);

// Making filteredRouteWiseData form Firebase
const fetchHighValueOrders = async (selecteddate) => {

    setIsPageLoading(true)
    setIsDataNotFound(false)
    

    let year = new Date(selecteddate).getFullYear();
    let month = new Date(selecteddate).getMonth() + 1;
    let date = new Date(selecteddate).getDate();
    let deliveryDate = `${date}-${month}-${year}`;

   // await storeCrateIdsInFb(date, month, year, deliveryDate)
      
    // const picking_allocation_by_route = {};
    
    Promise.all([
      fire
        .database()
        .ref(`Picking_Orders_History/${year}/${month}/${deliveryDate}/`)
        .once("value"),
      fire
        .database()
        .ref(`Picking_Allocation_History/${year}/${month}/${deliveryDate}/`)
        .once("value"),
    ])
      .then(([PoHSnapshot, PaHSnapshot]) => {

        const pickingOrdersHistory = PoHSnapshot.val() || {};
        const pickingAllocationHistory = PaHSnapshot.val() || {};

        const routeWiseHighValues = {}

        let listOfHighValueObjects = []

        // route keys are being made from picking Order History Firebase only
        // then route keys are being mappend with picking allocation history firebase
        const routesList = [{label:"All",value:"All"}]

        // making routeWiseHighValues from pickingOrdersHistory
        Object.keys(pickingOrdersHistory).map((checkHighValueString)=> {

          if(checkHighValueString.includes("HIGH_VALUE"))
          {
            const route = checkHighValueString.split("-")[0];

            routesList.push({label:route,value:route})

            Object.keys(pickingOrdersHistory[checkHighValueString]).map((highValues)=>
            {
            listOfHighValueObjects.push(pickingOrdersHistory[checkHighValueString][highValues])
            }
            )

            routeWiseHighValues[route] = listOfHighValueObjects

            listOfHighValueObjects=[]

          }

        })

       

        // making routeWiseHighValues from pickingAllocationHistory
        Object.keys(pickingAllocationHistory).map((phone_key)=> {
  
          Object.keys(pickingAllocationHistory[phone_key]).map((checkHighValueString)=> {
          if(checkHighValueString.includes("HIGH_VALUE"))
          {
            const route = checkHighValueString.split("-")[0];

            const objectValue = pickingAllocationHistory[phone_key][checkHighValueString]

           if(route in routeWiseHighValues)
            {
                routeWiseHighValues[route] = routeWiseHighValues[route].map(object => ({
                  ...object,
                  allocated_by: object?.allocated_by ? object.allocated_by || "" : "",
                  bag_id_update : objectValue?.bag_id_update ? objectValue.bag_id_update || "" : "",
                  num_of_crate : objectValue?.num_of_crate ? objectValue.num_of_crate || "0" : "0",
                  

                }));

          }
        }
          })

        })
      


        const route_crateWiseData = {};

        Object.entries(routeWiseHighValues).forEach(([route, routeObjectsList]) => {
          routeObjectsList.forEach((crateObj) => {

            if(crateObj?.packed_in_crate && crateObj?.picked_qty && Number(crateObj.picked_qty)>0)
            {
            // Extract the crate ID string from the object
            const crateId = crateObj.packed_in_crate;

            // Split the crateId string by commas into an array of individual crate IDs
            const crateIdsSplit = crateId.split(",");

            // Iterate over each crate ID
            crateIdsSplit.forEach((ids) => {
              // Get the crate number (second part after splitting by '-')
              const crateNumber = ids.split("-")[1];

              // Initialize route_crateWiseData[route] if it doesn't exist
              if (!route_crateWiseData[route]) {
                route_crateWiseData[route] = {};
              }

              // Initialize route_crateWiseData[route][crateNumber] if it doesn't exist
              if (!route_crateWiseData[route][crateNumber]) {
                route_crateWiseData[route][crateNumber] = [];
              }

              // Push the individual crate ID (ids) into the corresponding crate group
              route_crateWiseData[route][crateNumber].push(crateObj);
            });

          }

          });
        });

        setRouteAndCrateWiseData(route_crateWiseData)

        setFilteredRouteWiseData(routeWiseHighValues)
        setAllRouteWiseData(routeWiseHighValues)

        setRoute({label:"All",value:"All"})
        setRouteOptionsList(routesList)

        if(Object.keys(routeWiseHighValues).length<=0)
        {
          setIsDataNotFound(true)
        }

        setIsPageLoading(false)
       
        }
      )
      .catch((error) => {
        alert(error);
      });
    
};



  const handleDeliveryPlanDateChange = (selecteddate) => {
    setDeliveryPlanDate(new Date(selecteddate));
    fetchHighValueOrders(new Date(selecteddate));
    setRouteOptionsList([]);
    setRoute({});
  };

  const CustomDateInput = ({ value, onClick }) => (
    <input
      value={value}
      onClick={onClick}
      style={{ border: "solid 1px black", height: "30px" }}
    />
  );



  const HighValueTableHeader = (props) => {
    return (
      <thead className="thead-light">
        <tr>
          <th>Sr No</th>
  
          <th>Route</th>
          <th>Area</th>
          <th>Crate-ID</th>
          <th>No of Crates</th>
          <th>Total weight of Bags</th>
          {/* <th>Short Items</th> */}
          <th>Packed By</th>
          <th>Print</th>
        </tr>
      </thead>
    );
  };



  const HighValueTableBody = ({ filteredRouteWiseData }) => {
    const [rows, setRows] = useState([]);
    const [showCrateDetailsModal, setShowCrateDetailsModal] = useState(false);
    const [crateDetails, setCrateDetails] = useState([]);
    const [route, setRoute] = useState("");
    const [calculatedTotalWeight, setCalculatedTotalWeight] = useState(0);
  
    const printRefs = useRef({});
  
    useEffect(() => {
      if (filteredRouteWiseData) {
        setRows(
          Object.entries(filteredRouteWiseData).map(([key, listOfValues], index) => {
            const total_weight = calculateTotalWeight(listOfValues);
            setCalculatedTotalWeight(total_weight);
  
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{key}</td>
                <td>{listOfValues[0].city}</td>
                <td>{listOfValues[0].crate_id}</td>
                <td>
                  {listOfValues && (
                    <Button
                      onClick={() => {
                        setShowCrateDetailsModal(true);
                        setCrateDetails(listOfValues);
                        setRoute(key);
                      }}
                    >
                      {listOfValues[0].num_of_crate || 0}
                    </Button>
                  )}
                </td>
                <td>{`${parseFloat(total_weight).toFixed(2)} Kg(s)`}</td>
                <td>{listOfValues[0].allocated_by}</td>
                <td>
                  {listOfValues[0].bag_id_update && (
                    <ReactToPrint
                      trigger={() => (
                        <Button variant="primary" onClick={() => setRoute(key)}>
                          Print
                        </Button>
                      )}
                      content={() => printRefs.current[key]}
                    />
                  )}
                </td>
                <td style={{ display: 'none' }}>
                  <HighValuePrintBody
                    ref={(el) => (printRefs.current[key] = el)}
                    data={listOfValues}
                    route={key}
                    totalWeight={total_weight}
                  />
                </td>
              </tr>
            );
          })
        );
      }
    }, [filteredRouteWiseData]);
  
    const calculateTotalWeight = (listOfValues) => {
      let total_weight = 0;
      listOfValues.forEach((list) => {
        if (list?.weight) {
          const charactersCaseConversion = list.weight.toLowerCase();
          if (charactersCaseConversion.includes("kg")) {
            const numToCalculate = parseFloat(charactersCaseConversion.split("kg")[0]);
            total_weight += numToCalculate * Number(list?.picked_qty ? list?.picked_qty : 0);
          } else if (charactersCaseConversion.includes("gm")) {
            const numToCalculate = parseFloat(charactersCaseConversion.split("gm")[0]);
            total_weight += numToCalculate * Number(list?.picked_qty ? list?.picked_qty : 0) / 1000;
          }
        }
      });
      return total_weight;
    };
  
    return (
      <>
        <tbody>{rows}</tbody>
        {showCrateDetailsModal && (
          <HighValueCrateItemsModal
            show={showCrateDetailsModal}
            onHide={() => setShowCrateDetailsModal(false)}
            crateDetails={crateDetails}
            route={route}
          />
        )}
      </>
    );
  };
  
  const HighValuePrintBody = React.forwardRef(({ data, route, totalWeight }, ref) => {

    const calculateCrateWeight = (listOfValues,crateKey) => {

      let total_weight = 0;

      listOfValues.forEach((list) => {
        if (list?.weight && list?.crates) {
          
          let qunatityInCrate = 0;
          for (const [key, value] of Object.entries(list.crates)) {
            const matchKey = key.split("-")[1];
            if (matchKey == crateKey) {
              qunatityInCrate = Number(value);
              break;
            }
          }
          
          const charactersCaseConversion = list.weight.toLowerCase();
          if (charactersCaseConversion.includes("kg")) {
            const weightToCalculate = parseFloat(charactersCaseConversion.split("kg")[0]) * qunatityInCrate;
            total_weight += weightToCalculate // * Number(list?.picked_qty ? list?.picked_qty : 0);
          } else if (charactersCaseConversion.includes("gm")) {
            const weightToCalculate = parseFloat(charactersCaseConversion.split("gm")[0])  * qunatityInCrate
            total_weight += weightToCalculate / 1000;
          }
        }
      });
      return total_weight;
    };

    const getItemQuantityInCrate =(crateItem,crateKey)=>
    {
       
      let qunatityInCrate = 0;
      for (const [key, value] of Object.entries(crateItem.crates)) {
        const matchKey = key.split("-")[1];
        if (matchKey == crateKey) {
          qunatityInCrate = Number(value);
          break;
        }
      }

      return qunatityInCrate
    }

    const tableStyle = {
      marginBottom: "2rem",
      width: "100%",
      fontSize: "26px",  // Increase font size
      borderCollapse: "collapse",
    };

    const thStyle = {
      backgroundColor: "#f2f2f2",
      border: "1px solid #ddd",
      padding: "12px",
      textAlign: "left",
      // width:"80%"
    };
  
    const tdStyle = {
      border: "1px solid #ddd",
      padding: "12px",
      // width:"80%"
    };

    return (
      
      <div ref={ref} >
          {routeAndCrateWiseData[route] && Object.entries(routeAndCrateWiseData[route]).map(([crateKey, crateData], pageIndex) => (
      <div style={{
        maxWidth: '280mm',  // A4 width
        margin: '0 auto',   // Center on page
        padding: '20mm',    // Typical printer margin
        boxSizing: 'border-box',
        pageBreakAfter: 'always', 
      }}>
        <div style={{
          display: "flex",
          flexDirection: "row",
          gap: "2rem",
          alignItems: "flex-start",
          justifyContent: "space-between",
          textAlign: "left",
          marginBottom: "2rem",
        }}>
          <div style={{ flexShrink: 0 }}>
            <QRCode
               size={256} // Reduced size to fit better
              value={`${route}-${data[0].crate_id}`}
            />
          </div>
          
          <div>
            <h2 style={{ marginTop: 0 }}><span style={{ fontWeight: "bold" }}>Route No:</span> {route}</h2>
            <h3><span style={{ fontWeight: "bold" }}>Crate Id:</span> {data[0].crate_id}</h3>
            <h3><span style={{ fontWeight: "bold" }}>Total Weight:</span> {totalWeight} Kg(s)</h3>
            <h3><span style={{ fontWeight: "bold" }}>Crate Weight:</span> {calculateCrateWeight(crateData,crateKey)} Kg(s)</h3>
          </div>
        </div>
      
        <Table striped bordered hover style={tableStyle}>
          <thead>
            <tr>
              <th style={{...thStyle,}}>Sr No.</th>
              <th style={{...thStyle}}>Description</th>
              <th style={{...thStyle}}>Picked Qty</th>
            </tr>
          </thead>
          <tbody>
            {crateData.map((crateItem, index) => (
              <tr key={index}>
                <td style={tdStyle}>{index + 1}</td>
                <td style={tdStyle}>{crateItem.long_description}</td>
                <td style={tdStyle}>x {getItemQuantityInCrate(crateItem,crateKey)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
  
        <div style={{ textAlign: 'center' }}>
          <Barcode
            value={`${route}-${data[0].crate_id}`}
            width={3}
            height={180}
            displayValue={true}
            background="transparent"
            lineColor="black"
          />
        </div>
      </div>
      ))} 
    </div>

    );

  });

  return (
    <>
    {isPageLoading ?  


      <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 9999,
      }}>
        <Spinner
          animation="border"
          role="status"
          style={{ width: '5rem', height: '5rem', marginBottom: '1rem' }}
        />
        <div style={{ color: 'white', fontSize: '1.2rem' }}>
          Loading Data Please Wait...
        </div>
      </div>

      :
    
    <div>

    <NavBar />

      <Navbar
        className="bg-light m-auto justify-content-center"
        style={{ paddingTop: 100, textAlign: "center" }}
      >
          <h3>High Value Packing</h3>
      </Navbar>

      <div className="row" style={{ fontSize: "14px" }}>
        <InputGroup size="sm" style={{ margin: 10 }}>
          <InputGroupAddon addonType="prepend">Packing Date</InputGroupAddon>
          <DatePicker
            selected={deliveryPlanDate}
            onChange={handleDeliveryPlanDateChange}
            minDate={new Date("2017/01/01")}
            aria-describedby="basic-addon1"
            dateFormat="dd/MM/yyyy"
            customInput={<CustomDateInput />}
          />


          <label style={{ marginLeft: "10px", marginRight: "10px" }}>
            Warehouse
          </label>

          <div
            style={{
              width: "130px",
              height: "20px",
              marginRight: "10px",
            }}
          >
            <Select
              options={warehouseOptionsList}
              value={warehouse}
              onChange={(val) => {
                setWarehouse(val);
                handleWareHouseFiltering(val)
              }}
            />
          </div>
          <label style={{ marginRight: "7px" }}>Route</label>

          <div
            style={{
              width: "130px",
              height: "20px",
              marginRight: 10,
            }}
          >
            <Select
              options={routeOptionsList}
              value={route}
              onChange={(val) => {
                console.log("selected route:", val);
                setRoute(val);
                handleRouteFiltering(val)
              }}
            />
          </div>


         
        
          <Button
            size="sm"
            style={{ marginLeft: 0, marginRight: 10 }}
            variant="warning"
            onClick={() => setExportData(true)}
          >
            Export
          </Button>

    
          <Button
            size="sm"
            style={{ marginRight: 10 }}
            variant="warning"
            disabled={isButtonLoading} 
            onClick={async () => {
              setIsButtonLoading(true);
              try {
                let year = new Date(deliveryPlanDate).getFullYear();
                let month = new Date(deliveryPlanDate).getMonth() + 1;
                let date = new Date(deliveryPlanDate).getDate();
                let deliveryDate = `${date}-${month}-${year}`;
                await storeCrateIdsInFb(date, month, year, deliveryDate);
                await fetchHighValueOrders(deliveryPlanDate)
                //  window.location.reload();
              } catch (error) {
                console.error("An error occurred:", error);
                // Handle the error appropriately
              } finally {
                setIsButtonLoading(false);
              }
            }}
          >
            {isButtonLoading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Assigning Ids...</span>
              </>
            ) : (
              'Assign Crate IDs'
            )}
          </Button>

        </InputGroup>

        <table
      className="table table-striped"
      style={{ marginLeft:22, marginTop: 10, fontSize: "14px" }}
        >

        <HighValueTableHeader/>

        {isDataNotFound ? <>Data Not Found</> : <HighValueTableBody
         filteredRouteWiseData={filteredRouteWiseData}
         deliveryPlanDate={deliveryPlanDate}
         />}

        </table>


      </div>

    </div>
   
            }
    </>
  
    
  )
}
