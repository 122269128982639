import React, { useState, useEffect } from 'react'
import { Table, Form, Alert, Button,Modal } from 'react-bootstrap'
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import Backdrop from '../backdrop/backdrop'
import {support_portal_url} from '../../Configs/mysqlconfig';

function formatDate(selecteddate){
  if(selecteddate){
    let dt=new Date(selecteddate);
    let monthNames =["Jan","Feb","Mar","Apr",
                        "May","Jun","Jul","Aug",
                        "Sep", "Oct","Nov","Dec"];

      let day = dt.getDate();

      let monthIndex = dt.getMonth();
      let monthName = monthNames[monthIndex];

      let year = dt.getFullYear();

      return `${day}-${monthName}-${year}`;
  }else{
    return null;
  }

}

function ShowReturnOrderItemModal({returnOrderNumber,showReturnOrderItems,...props}) {
  const [backDrop, setBackDrop] = useState(false);
  const [orderDetails,setOrderDetails]=useState({});
  const [itemDetails,setItemDetails]=useState([]);

  useEffect(()=>{
    if(showReturnOrderItems){
      setOrderDetails({});
      setItemDetails([]);
      setBackDrop(true);
      fetch(`${support_portal_url}/api/ordernumbersearch?ordernumber=${returnOrderNumber}&token=${sessionStorage.getItem("apiToken")}`).then(res => {
          return res.json()
      }).then(data => {
        if(data['success']==1){
          let orderdatalist=data.data;
          console.log(orderdatalist);
          let orderitemslist=orderdatalist["items"];
          setItemDetails(orderitemslist);
          setOrderDetails(orderdatalist);
          setBackDrop(false);
        }else{
          setOrderDetails({});
          setItemDetails([]);
          setBackDrop(false);

          let errMessage=data['message']?data['message']:"";
          alert(errMessage);
          if(data['status']==401 || data['status']==403){
            sessionStorage.clear();
            window.location.href="/"
          }
        }

      }).catch(err => {
        alert(err);
        setOrderDetails({});
        setItemDetails([]);
        setBackDrop(false);
      })
    }
  },[showReturnOrderItems])

    return (<>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    Order Information of {returnOrderNumber}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="table-wrapper" style={{marginTop:15,paddingBottom:40}}>
                  <Table className="table table-striped" style={{width:300,marginLeft:"auto",marginRight:"auto"}}>
                    <tbody>
                        <tr>
                            <td>OrderDate</td>
                            <td>{formatDate(orderDetails.order_date)}</td>
                        </tr>
                        <tr>
                            <td>OrderStatus</td>
                            <td>{orderDetails.status}</td>
                        </tr>
                        <tr>
                            <td>OrderAmount</td>
                            <td>{orderDetails.net_order_amt}</td>
                        </tr>
                        <tr>
                            <td>DeliveryDate</td>
                            <td>{orderDetails.delivery_date?formatDate(orderDetails.delivery_date):""}</td>
                        </tr>
                    </tbody>
                  </Table>
                </div>
                <Table>
                  <thead>
                    <tr>
                      <th>Item Id</th>
                      <th>Item Description</th>
                      <th>Dealer Price</th>
                      <th>Order Qty</th>
                      <th>Delivered Qty</th>
                      <th>GST Details</th>
                      <th>Total Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(itemDetails && itemDetails.length>0) ? itemDetails.map(item => (
                        <tr>
                            <td>{item['item_id']}</td>
                            <td>{item['item_disc']}</td>
                            <td>{item['dealer_price']}</td>
                            <td>{item['order_qty']}</td>
                            <td>{item['item_delivered_qty']}</td>
                            <td>{item['gst_charge']}</td>
                            <td>{item['total_amt']}</td>
                        </tr>
                      )) :null}
                  </tbody>
                </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
            {backDrop?(<Backdrop parentLoadStatus={backDrop}/>):(null)}
        </>
    )
}

export default ShowReturnOrderItemModal
