import React, { Component } from 'react'
import fire from '../Configs/firebase'
import {Form,FormGroup,Row,Col, Card,Table,Accordion,Button} from 'react-bootstrap'
import Select from 'react-select';
import NavBar from './Navigation bar/Navbar'
import FooterComponent from './Footer/footer'
export default class ShopDetails extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
             renderData : [],
             addressList1:[],
             addressList2:[],
             areaList:[],
             itemArray:[],
             itemPick:'',
             shopName:[],
             lattitude:[],
             longitude:[],
             Phoneno:[],
             Retailer_id:[],
             orderNo:'',
             requiredData:'',
             orderDate:'',
             totalAmount:'',
             deliveryStatus:''
        }
    }

    componentDidMount(){
        
        this.getShopData(this.props.location.search.split('=')[1])
        // this.getOrderDetails();
    }

    getShopData(link){
        let temp = []
        let shopName='';

        let shopdetails=link.split("/")[5];
        let shopname=shopdetails.split("-")[0]+"-"+shopdetails.split("-")[1];
        console.log(shopname)
       let  linkdata=link.split("/")[0]+"/"+link.split("/")[1]+"/"+link.split("/")[2]+"/"+link.split("/")[3]+"/"+link.split("/")[4]+"/"
       console.log(linkdata);
       
       this.setState({requiredData:linkdata})
       let linkedData2=linkdata+shopname;
           console.log(linkedData2)
           const itemList=[]
        fire.database().ref(linkdata).once('value',(snapshot)=>{
            const data = snapshot.val()
            Object.keys(data).forEach((key)=>{
              let newKey=key.split("-")[0]+"-"+key.split("-")[1]
                if(linkedData2.includes(newKey))
                {
                    const list={}
                    if(shopName===''){
                    shopName=(data[key].shop_name)
                    this.setState({
                    addressList1:data[key].address1,
                    addressList2:data[key].address2,
                    areaList:data[key].area,
                    shopName:data[key].shop_name,
                    lattitude:data[key].latitude,
                    longitude:data[key].longitude,
                    Phoneno:data[key].phone,
                    Retailer_id:data[key].retailer_id,
                    orderDate:data[key].order_date,
                    totalAmount:data[key].total_amt,
                    deliveryStatus:data[key].status,
                    orderNo:data[key].order_number
                })
                }
                list["itemNo"]=key
                list["itemId"]=data[key].item_id
                list["itemDescription"]=data[key].item_disc
                list["orderQuantity"]=data[key].order_qty
                list["mrp"]=data[key].mrp
                itemList.push(list)
                
         } })
        })
        .then(()=>{
            this.setState({itemArray:itemList})
            this.setState({renderData:temp})})
       
    
    }
    
    render() {
        return (
            <>
            <NavBar/>
            <div>
                  <Card>
                      <Card.Header>
                            STORE ADDRESS
                        </Card.Header>
                <Card.Body>
                    {this.state.shopName}<br/>
                    {this.state.addressList1}<br/>
                {this.state.addressList2}<br/>
                {this.state.areaList}<br/>
                <Table>
                    <tr>
                        <td>
                            Phone number
                        </td>
                         <td>{this.state.Phoneno}</td>
                    </tr>
                    <tr>
                        <td>
                            Retailer ID
                        </td>
                        <td>
                            {this.state.Retailer_id}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Lattitude
                        </td>
                        <td>
                            {this.state.lattitude}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Longitude
                        </td>
                        <td>
                            {this.state.longitude}
                        </td>
                    </tr>
                </Table>
               </Card.Body>
                </Card>
                <Card>
                    <Card.Header>
                        ORDER DETAILS
                   </Card.Header>
                    <Card.Body>
                        <Table>
                            <tr>
                                <td>
                                    Order number
                                </td>
                                <td>
                                    {this.state.orderNo}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Order Date
                                </td>
                                <td>
                                {this.state.orderDate}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Total amount
                                </td>
                                <td>
                                {this.state.totalAmount}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Delivery Status
                                </td>
                                <td>
                                {this.state.deliveryStatus}
                                </td>
                            </tr>
                        </Table>
                    </Card.Body></Card>
                    <Card>
                        <Card.Header>
                        ITEM LIST
                        </Card.Header><Card.Body>
                            {this.state.itemArray.map((item)=>(
                                <Accordion >
                                <Card><Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                {item.itemNo}
                                </Accordion.Toggle> 
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <Table>
                                            <tr>
                                                <td>
                                                    Item ID
                                                </td>
                                                <td>
                                                {item.itemId}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Item description
                                                </td>
                                                <td>
                                                {item.itemDescription}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Order Quantity
                                                </td>
                                                <td>
                                                {item.orderQuantity}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Item MRP
                                                </td>
                                                <td>
                                                {item.mrp}
                                                </td>
                                            </tr>
                                        </Table>
                                    </Card.Body>
                                </Accordion.Collapse>
                                </Card>
                                </Accordion>
                            ))}
                </Card.Body>
                </Card>
            </div>
            <FooterComponent/>
            </>
        )
    }
}
