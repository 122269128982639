import React, { useState, useMemo, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NavBar from "../Navigation bar/Navbar";
import axios from "axios";
import { service_url } from "../../Configs/mysqlconfig";
import fire from "../../Configs/firebase";

import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Form,
  Table,
  Dropdown,
} from "react-bootstrap";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useAsyncDebounce,
} from "react-table";

let today = new Date();
let dd = String(today.getDate()).padStart(2, "0");
let mm = String(today.getMonth() + 1).padStart(2, "0");
let yyyy = today.getFullYear();
today = yyyy + "-" + mm + "-" + dd;

function RupifiApprove() {
  const [ApproveRetailer, setApproveRetailer] = useState([]);
  const [Retailers, setRetailers] = useState("");
  const [show1, setShow1] = useState(false);
  const [TableDate, setTableData] = useState([]);
  const [TableDate2, setTableData2] = useState([]);
  const [Loading, setLoading] = useState("Loading...");
  const [SplitList, setSplitList] = useState();
  const [SwitchApprove, setSwitchApprove] = useState(true);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => {
    setShow1(true);
  };

  async function getRupifiApproveRetailer() {
    // const url1 = `${service_url}/api/superzop/rupifi/RupifiApproveRetailer?date_start=${StartDate}&date_end=${EndDate}`;
    // try {
    //   let result = await axios.get(url1);
    //   setApproveRetailer(result.data);
    // } catch (error) {
    //   console.log(error);
    // }
  }

  function getFireBaseData() {
    fire
      .database()
      .ref("Retailer_Master_New")
      .once("value")
      .then((snapshot) => {
        let list = [];
        let list2 = [];
        let snapshot_val = snapshot.val();
        console.log(Object.keys(snapshot_val).length );
        let count = 60000;
     
        setLoading("loading...  "+ Object.keys(snapshot_val).length  );
        for (let itm in snapshot_val) {
          if (snapshot_val[itm]?.super_kredit == "rupifi_approved") {
            list.push({
              retailer_id: snapshot_val[itm]?.retailer_id,
              phone: snapshot_val[itm]?.phone,
              retailer_name: snapshot_val[itm]?.retailer_name,
              shop_name: snapshot_val[itm]?.shop_name,
              super_kredit: snapshot_val[itm]?.super_kredit,
            });
          } else if (snapshot_val[itm]?.super_kredit !== "rupifi_approved" && snapshot_val[itm]?.super_kredit !== undefined) {
            list2.push({
              retailer_id: snapshot_val[itm]?.retailer_id,
              phone: snapshot_val[itm]?.phone,
              retailer_name: snapshot_val[itm]?.retailer_name,
              shop_name: snapshot_val[itm]?.shop_name,
              super_kredit: snapshot_val[itm]?.super_kredit,
            });
          }
          count--;
          if (count == 0) {
            break;
          }
        }

        setLoading("");
        setTableData(list);
        setTableData2(list2);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function PushDataToRupifi() {
    let RetailerList = Retailers.split("\n");
    const url1 = `${service_url}/api/superzop/rupifi/pushOneGmvFromDB`;
    toast("Requested....");

    try {
      let result = await axios.post(url1, { RetailerList });
      // setApproveRetailer(result.data);
      toast.success("Successful");
    } catch (error) {
      console.log(error);
      toast.error("Failure");
    }
  }

  async function finalList() {
    let RetailerList = Retailers.split("\n");
    let r = RetailerList.map((itm) => {
      return (
        <span style={{ backgroundColor: "#99FF99", marginLeft: "0.8rem" }}>
          {" "}
          {itm}{" "}
        </span>
      );
    });
    setSplitList(r);
  }

  function handelOnChange(e) {
    setRetailers(e.target.value);
  }

  useEffect(() => {
    // getRupifiApproveRetailer();
    getFireBaseData();

    return () => {};
  }, []);

  function Table1() {
    let td = [];
    if (SwitchApprove == true) {
      td = TableDate;
    } else {
      td = TableDate2;
    }

    console.log(td);
    const data = useMemo(() => td, []);

    const columns = useMemo(
      () => [
        {
          Header: "Retailer id ",
          accessor: "retailer_id",
        },
        {
          Header: "Phone",
          accessor: "phone",
        },
        {
          Header: "Retailer name",
          accessor: "retailer_name",
          Filter: SelectColumnFilter,
        },
        {
          Header: "Shop name",
          accessor: "shop_name",
          Filter: SelectColumnFilter,
        },
        {
          Header: "Super kredit",
          accessor: "super_kredit",
          Filter: SelectColumnFilter,
        },
      ],
      []
    );

    function SelectColumnFilter({
      column: { filterValue, setFilter, preFilteredRows, id },
    }) {
      const options = useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
          options.add(row.values[id]);
        });
        return [...options.values()];
      }, [id, preFilteredRows]);

      return (
        <select
          value={filterValue}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
        >
          <option value="">All</option>
          {options.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </select>
      );
    }

    const defaultColumn = useMemo(
      () => ({
        Filter: () => null,
      }),
      []
    );

    const GlobalFilter = ({ filter, setFilter }) => {
      const [value, setValue] = useState(filter);
      const onChange = useAsyncDebounce((value) => {
        setFilter(value || undefined);
      }, 1000);
      return (
        <span>
          <Form.Control
            type="text"
            placeholder="Scarch..."
            value={value || ""}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
          />
        </span>
      );
    };

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      setGlobalFilter,
      preGlobalFilteredRows,
    } = useTable({ columns, data, defaultColumn }, useFilters, useGlobalFilter);

    const { globalFilter } = state;

    return (
      <div>
        <Row>
          <Col>
            <GlobalFilter
              filter={globalFilter}
              setFilter={setGlobalFilter}
            ></GlobalFilter>
          </Col>
          <Col></Col>
          <Col></Col>
          <Col>{/* <Button variant="warning">Primary</Button>{" "} */}</Col>
          <Col>{/* <Button variant="info">Primary</Button>{" "} */}</Col>
        </Row>
        <br />

        <table
          {...getTableProps()}
          style={{ border: "solid 1px blue", width: "100%" }}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{
                      borderBottom: "solid 3px red",
                      background: "aliceblue",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {column.render("Header")}
                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: "10px",
                          border: "solid 1px gray",
                          background: "papayawhip",
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div>
      <NavBar />
      <br />
      <br />
      <br />
      <br />

      <div style={{ margin: 20 }}>
        <h2> Rupifi Approve </h2>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <Row>
          <Col>
            <Button variant="primary" onClick={handleShow1}>
              Push Retailers To Rupifi Gnv
            </Button>
          </Col>
          <Col>
            <Dropdown
              onSelect={(evt, evtKey) => {
                if (evt == "true") {
                  setSwitchApprove(true);
                } else {
                  setSwitchApprove(false);
                }
              }}
            >
              <Dropdown.Toggle variant="success">Super kredit</Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey="true"> Approved</Dropdown.Item>
                <Dropdown.Item eventKey="false"> Not approved </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col>{/* <Button variant="warning">Primary</Button>{" "} */}</Col>
          <Col>{/* <Button variant="info">Primary</Button>{" "} */}</Col>
          <Col>{/* <Button variant="info">Primary</Button>{" "} */}</Col>
          <Col>{/* <Button variant="info">Primary</Button>{" "} */}</Col>
        </Row>
        <br />

        <Row>
          <Col>
            <Table1 />
            {Loading}
          </Col>
        </Row>

        <Modal show={show1} onHide={handleClose1} size="lg">
          <Modal.Header closeButton>
            <Modal.Title> Add Retailers ids ( Line by line ) </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Enter Retailer ids </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={7}
                  onChange={handelOnChange}
                  value={Retailers}
                />
              </Form.Group>
            </Form>

            <p>Selected Ids </p>

            {SplitList}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
            <Button onClick={finalList}> Check Ids Before Push </Button>
            <Button variant="success" onClick={PushDataToRupifi}>
              {" "}
              Push data to Rupifi Gmv{" "}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default RupifiApprove;
