import React, { useState, useEffect } from 'react'
import { Table, Form, Alert, Button,Modal } from 'react-bootstrap'
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import Backdrop from '../backdrop/backdrop';
import UpdatedDate from './UpdatedDate'
import fire from '../../Configs/firebase';

function SuperKreditValueUpdatePopup({superKreditPopup,superKreditObj,getSuperZopKreditObj,superKreditEndFlag,...props}) {
  console.log(superKreditObj);
  const [loading,setLoading] = useState(false);
  const [superkreditRetailersCreditData,setSuperkreditRetailersCreditData]=useState({});
  const [internalRetailersCreditData,setInternalRetailersCreditData]=useState({});

  toast.configure({
      autoClose: 4000,
      draggable: true,
      pauseOnHover: true,
      position: toast.POSITION.TOP_LEFT,
  });

  useEffect(() => {
    setLoading(true);

    Promise.all([
      fire.database().ref("Superzop_Kredit_Retailers").once('value'),
      fire.database().ref("Superzop_Kredit_Internal").once('value')
    ]).then(([snapshot, internalcreditsnap]) => {
        let creditData=snapshot.val() || {};
        let internalCreditData=internalcreditsnap.val() || {};

        setSuperkreditRetailersCreditData(creditData);
        setInternalRetailersCreditData(internalCreditData);
        setLoading(false);
    });
  },[])

  const updateSuperKreditValue = () => {
    // props.onHide();
    let usermodifying = JSON.parse(sessionStorage.getItem("user"))[0].email

    let kreditHistoryObj={};
    let superzopKreditRetailersObj={};
    let internalKreditRetailersObj={};

    let updatedOrderNumberStr="";
    let updatedOrderAmountStr="";
    let multiRetailerOrderExternalCreditObj={};

    let missingKreditRetailer = false;
    let externalSuperkreditErrorRetailers = "";

    Object.keys(superKreditObj).map((orderID, index) => {
      let phone=superKreditObj[orderID].phone;
      let orderKey=superKreditObj[orderID].orderKey;
      let orderNumber=superKreditObj[orderID].order_number;
      let orderStatus=superKreditObj[orderID].status;

      if(orderStatus!="Cancelled"){
        kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/status`]="Delivered";
        kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/delivered_value`]=parseFloat(superKreditObj[orderID].delivered_amt);

        let deliveryyDate=superKreditObj[orderID].delivery_date;
        let deliveryDateList=deliveryyDate.split("-");
        let formattedDate=`${deliveryDateList[2]}-${deliveryDateList[1]}-${deliveryDateList[0]}`;
        kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/delivered_on`]=formattedDate;
        kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/delivered_date`]=Date.parse(deliveryyDate);

        let repayable_date = new Date(deliveryyDate)
        repayable_date.setDate(repayable_date.getDate() + 15)
        kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/repayable_date`]=Date.parse(repayable_date);

      }else{
        kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/status`]="Cancelled";
        kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/repaid_status`]="C";
      }

      kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/updated_at`] = UpdatedDate()
      kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/updated_by`] = usermodifying


      let updatedAvailableCredit = superKreditObj[orderID].payment_diff?Number(superKreditObj[orderID].payment_diff):0;

      //--------- new code ------------------
      if(!multiRetailerOrderExternalCreditObj[phone]){
        multiRetailerOrderExternalCreditObj[phone]=updatedAvailableCredit;
      }else{
        let prevRetailerOrderCredit=multiRetailerOrderExternalCreditObj[phone]?Number(multiRetailerOrderExternalCreditObj[phone]):0;
        updatedAvailableCredit=prevRetailerOrderCredit+updatedAvailableCredit;
        multiRetailerOrderExternalCreditObj[phone]=updatedAvailableCredit;
      }

      updatedAvailableCredit=updatedAvailableCredit.toFixed(2);


      let existingRetailerSuperkreditAmount=0;
      if(superkreditRetailersCreditData){
        if(superkreditRetailersCreditData[phone]){
          existingRetailerSuperkreditAmount=superkreditRetailersCreditData[phone].available_credit?Number(superkreditRetailersCreditData[phone].available_credit):0;
        }else{
          missingKreditRetailer = true;
          externalSuperkreditErrorRetailers += `${phone},`
        }
      }else{
        missingKreditRetailer = true;
        externalSuperkreditErrorRetailers += `${phone},`
      }
      //-----------------------------------------

      let finalRetailerAvailableCredit=existingRetailerSuperkreditAmount+Number(updatedAvailableCredit);
      finalRetailerAvailableCredit=finalRetailerAvailableCredit.toFixed(2);

      if(finalRetailerAvailableCredit>0){
        //update
        superzopKreditRetailersObj[`/${phone}/available_credit`]=Number(finalRetailerAvailableCredit);
        updatedOrderNumberStr=updatedOrderNumberStr+orderNumber+",";
        updatedOrderAmountStr=updatedOrderAmountStr+updatedAvailableCredit+",";
      }
    });

    //getSuperZopKreditObj
    let multipleRetailerOrderSuperkreditObj={};
    let internalSuperkreditErrorRetailers = "";

    Object.keys(getSuperZopKreditObj).map((orderID, index) => {
      let phone=getSuperZopKreditObj[orderID].phone;
      let orderKey=getSuperZopKreditObj[orderID].orderKey;
      let retailer_id=getSuperZopKreditObj[orderID].retailer_id;
      let orderNumber=getSuperZopKreditObj[orderID].order_number;
      let orderStatus=getSuperZopKreditObj[orderID].status;
      let payment_mode=getSuperZopKreditObj[orderID].payment_mode;

      if(orderStatus!="Cancelled"){
        let deliveryyDate=getSuperZopKreditObj[orderID].delivery_date;
        console.log(deliveryyDate);
        let deliveryDateList=deliveryyDate.split("-");
        let formattedDate=`${deliveryDateList[2]}-${deliveryDateList[1]}-${deliveryDateList[0]}`;
        kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/delivered_on`]=formattedDate;
        kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/delivered_date`]=Date.parse(deliveryyDate);

        let repayable_date = new Date(deliveryyDate)
        repayable_date.setDate(repayable_date.getDate() + 12)
        kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/repayable_date`]=Date.parse(repayable_date);

        kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/status`]="Delivered";
        kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/delivered_value`]=parseFloat(getSuperZopKreditObj[orderID].delivered_amt);

        if(payment_mode=="internalsuperkredit"){
          kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/cheque_number`]=getSuperZopKreditObj[orderID].cheque_number;
          kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/cheque_date`]=getSuperZopKreditObj[orderID].cheque_date;
          kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/cheque_amount`]=getSuperZopKreditObj[orderID].cheque_amt;

          if(getSuperZopKreditObj[orderID].cheque_amt2){
            kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/cheque_amount2`]=getSuperZopKreditObj[orderID].cheque_amt2;
            kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/cheque_number2`]=getSuperZopKreditObj[orderID].cheque_number2;
            kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/cheque_date2`]=getSuperZopKreditObj[orderID].cheque_date2;
          }
        }

        kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/repaid_status`]="N";
      }else{
        kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/status`]="Cancelled";
        kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/repaid_status`]="C";
      }


      kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/updated_at`] = UpdatedDate()
      kreditHistoryObj[`/${phone}/${orderKey.split("-")[0]}-${orderKey.split("-")[1]}/updated_by`] = usermodifying

      let updatedAvailableCredit = getSuperZopKreditObj[orderID].payment_diff?Number(getSuperZopKreditObj[orderID].payment_diff):0;

      //--------- new code ------------------
      if(!multipleRetailerOrderSuperkreditObj[retailer_id]){
        multipleRetailerOrderSuperkreditObj[retailer_id]=updatedAvailableCredit;
      }else{
        let prevRetailerOrderCredit=multipleRetailerOrderSuperkreditObj[retailer_id]?Number(multipleRetailerOrderSuperkreditObj[retailer_id]):0;
        updatedAvailableCredit=prevRetailerOrderCredit+updatedAvailableCredit;
        multipleRetailerOrderSuperkreditObj[retailer_id]=updatedAvailableCredit;
      }

      updatedAvailableCredit=updatedAvailableCredit.toFixed(2);


      let existingRetailerSuperkreditAmount=0;
      if(internalRetailersCreditData){
        if(internalRetailersCreditData[retailer_id]){
          if(internalRetailersCreditData[retailer_id]['approved_details']){
            existingRetailerSuperkreditAmount=internalRetailersCreditData[retailer_id]['approved_details'].available_credit?Number(internalRetailersCreditData[retailer_id]['approved_details'].available_credit):0;
          }else{
            missingKreditRetailer = true;
            internalSuperkreditErrorRetailers += `${retailer_id},`
          }
        }else{
          missingKreditRetailer = true;
          internalSuperkreditErrorRetailers += `${retailer_id},`
        }
      }else{
        missingKreditRetailer = true;
        internalSuperkreditErrorRetailers += `${retailer_id},`
      }
      //-----------------------------------------

      let finalRetailerAvailableCredit=existingRetailerSuperkreditAmount+Number(updatedAvailableCredit);
      finalRetailerAvailableCredit=finalRetailerAvailableCredit.toFixed(2);

      if(finalRetailerAvailableCredit>0){
        //update
        internalKreditRetailersObj[`/${retailer_id}/approved_details/available_credit`]=Number(finalRetailerAvailableCredit);
        updatedOrderNumberStr=updatedOrderNumberStr+orderNumber+",";
        updatedOrderAmountStr=updatedOrderAmountStr+updatedAvailableCredit+",";
      }
    });

    console.log(kreditHistoryObj);
    console.log(superzopKreditRetailersObj);
    console.log(internalKreditRetailersObj);

    if(missingKreditRetailer==true){
      if(internalSuperkreditErrorRetailers.length>0){
        internalSuperkreditErrorRetailers = internalSuperkreditErrorRetailers.slice(0,-1);
        toast(`SuperKredit - Retailers with no record : ${internalSuperkreditErrorRetailers}`, { type: toast.TYPE.ERROR });
      }

      if(externalSuperkreditErrorRetailers.length>0){
        externalSuperkreditErrorRetailers = externalSuperkreditErrorRetailers.slice(0,-1);
        toast(`Rupifi - Retailers with no record : ${externalSuperkreditErrorRetailers}`, { type: toast.TYPE.ERROR });
      }

      return;
    }


    if(Object.keys(internalKreditRetailersObj).length>0){
      fire.database().ref("Superzop_Kredit_Internal").update(internalKreditRetailersObj);
    }

    if(Object.keys(superzopKreditRetailersObj).length>0){
      fire.database().ref("Kredit_History").update(kreditHistoryObj).then(()=>{
        fire.database().ref("Superzop_Kredit_Retailers").update(superzopKreditRetailersObj).then(() => {
          console.log("successfully updated kredit orders");
          updatedOrderNumberStr=updatedOrderNumberStr.slice(0,-1);
          updatedOrderAmountStr=updatedOrderAmountStr.slice(0,-1);
          props.onHide();
          props.callback(updatedOrderNumberStr,updatedOrderAmountStr);
        });
      });
    }else if(Object.keys(kreditHistoryObj).length>0){
      fire.database().ref("Kredit_History").update(kreditHistoryObj).then(()=>{
        updatedOrderNumberStr=updatedOrderNumberStr.slice(0,-1);
        updatedOrderAmountStr=updatedOrderAmountStr.slice(0,-1);
        props.onHide();
        props.callback(updatedOrderNumberStr,updatedOrderAmountStr);
      });
    }else{
      toast("No superkredit orders with payment difference present.", { type: toast.TYPE.ERROR });
      props.onHide();
    }

  }

  function objCombine(obj, variable) {
    for (let key of Object.keys(obj)) {
      if (!variable[key]) variable[key] = {};

      for (let innerKey of Object.keys(obj[key]))
        variable[key][innerKey] = obj[key][innerKey];
    }
  }

  const KreditOrdersTableBody = () => {
    let finalZopKreditObj = {};
    objCombine(superKreditObj, finalZopKreditObj);
    objCombine(getSuperZopKreditObj, finalZopKreditObj);
    console.log(finalZopKreditObj)



    let rows = Object.keys(finalZopKreditObj).map((orderID, index) => {
          return (
              <tr key={index}>
                  <td>{finalZopKreditObj[orderID].order_number}</td>
                  <td>{finalZopKreditObj[orderID].status}</td>
                  <td>{finalZopKreditObj[orderID].credit_type}</td>
                  <td>{finalZopKreditObj[orderID].credit_amt}</td>
                  <td>{finalZopKreditObj[orderID].ordered_amt}</td>
                  <td>{finalZopKreditObj[orderID].delivered_amt}</td>
                  <td>{finalZopKreditObj[orderID].payment_diff}</td>
                  <td>{finalZopKreditObj[orderID].phone}</td>
                  <td>{finalZopKreditObj[orderID].available_credit}</td>
                  <td>{finalZopKreditObj[orderID].updated_available_credit}</td>
              </tr>
          );
      });

      return (<><tbody>{rows}</tbody></>);
  }

    return (<>
            <Modal
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                      SuperKredit Value Update
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Table>
                    <thead>
                      <tr>
                        <th>OrderNumber</th>
                        <th>Status</th>
                        <th>Credit Type</th>
                        <th>Credit Amt</th>
                        <th>Order Amt</th>
                        <th>Delivered Amt</th>
                        <th>Credit Diff</th>
                        <th>Phone</th>
                        <th>Current Available Limit</th>
                        <th>Updated Available Limit</th>
                      </tr>
                    </thead>
                    <KreditOrdersTableBody />
                </Table>

                </Modal.Body>
                <Modal.Footer>
                    {superKreditEndFlag!=true?(<Button onClick={()=>{ updateSuperKreditValue(); }}>Update SuperKredit</Button>):null}
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
            {loading ? (<Backdrop parentLoadStatus={loading} />) : (null)}
        </>
    )
}

export default SuperKreditValueUpdatePopup
