import React,{ useState,useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function CustomizedSnackbars(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [getMessage,setMessage] = React.useState()
  const [getSeverity,setSeverity] = React.useState("success")

  let message = props.message
  let type = props.type
  let canOpen = props.open

  useEffect(() => {
     setMessage(message)
  }, [message])

  useEffect(() => {
    setSeverity(type)
  }, [type])

  useEffect(() => {
      setOpen(canOpen)
  }, [canOpen])


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    props.setParentAlert(false)
  };

  return (
    <div className={classes.root}>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{vertical:'top',horizontal:'center'}}
        key={`top,center`}>
        <Alert onClose={handleClose} severity={getSeverity}>
          {getMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
