import React, { useEffect,useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import ReturnOrderListTable from './returnorderstable'
import ReturnOrdersListForm from './ReturnOrdersForm'
import ReturnOrdersListSearchForm from './ReturnOrdersSearch'
import NavBar from '../Navigation bar/Navbar'
import fire from '../../Configs/firebase'
import Backdrop from '../backdrop/backdrop'
import {support_portal_url} from '../../Configs/mysqlconfig';
import { InputGroupAddon, Input, InputGroupText, CardHeader, CardTitle, CardText } from 'reactstrap';
import {Tab,Row,Col,Nav,Form,Dropdown,ProgressBar,Tabs,Image,InputGroup,Button,DropdownButton,Breadcrumb,Table,ButtonGroup,Container, Accordion,Card, Navbar} from 'react-bootstrap'

function formatDate(date) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

const ReturnOrders = () => {

  const [ordersList, setOrdersList] = useState([])
  const [searchOrdersList, setSearchOrdersList] = useState([])
  const [originalListing, setOriginalListing] = useState([])

  const [backDrop, setBackDrop] = useState(false)


  useEffect(() => {

    let curdate=formatDate(new Date());
    let apiurlstr=`${support_portal_url}/api/returnedorders?startDate=${curdate}T00%3A00&endDate=${curdate}T23%3A59&token=${sessionStorage.getItem("apiToken")}`
    fetch(apiurlstr)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      if(result['success']==1){
        setOrdersList(result['data'])
        setSearchOrdersList(result['data'])
        setOriginalListing(result['data'])
      }else {
        setOrdersList([])
        setSearchOrdersList([])

        let errMessage=result['message']?result['message']:"";
        alert(errMessage);
        if(result['status']==401 || result['status']==403){
          sessionStorage.clear();
          window.location.href="/"
        }
      }
    })
    .catch((error) => {
      alert(error)
      setOrdersList([])
      setSearchOrdersList([])
    });

  }, [])


  const handleSubmit = (orderlistformdata) => {


    setBackDrop(false)
    let orderlistformstartdate=orderlistformdata['ordersListStartDate']
    let orderlistformenddate=orderlistformdata['ordersListEndDate']

    let apiurlstr=`${support_portal_url}/api/returnedorders?token=${sessionStorage.getItem("apiToken")}&`;
    if(orderlistformstartdate){
      apiurlstr+='startDate='
      let orderlistformstartdt=orderlistformstartdate.split('T');
      apiurlstr+=orderlistformstartdt[0];
      apiurlstr+='T';
      let orderlistformstartdatetime=orderlistformstartdt[1].split(':');
      apiurlstr+=orderlistformstartdatetime[0];
      apiurlstr+='%3A'
      apiurlstr+=orderlistformstartdatetime[1];
    }

    if(orderlistformenddate){
      apiurlstr+='&endDate='
      let orderlistformenddt=orderlistformenddate.split('T');
      apiurlstr+=orderlistformenddt[0];
      apiurlstr+='T';
      let orderlistformenddatetime=orderlistformenddt[1].split(':');
      apiurlstr+=orderlistformenddatetime[0];
      apiurlstr+='%3A'
      apiurlstr+=orderlistformenddatetime[1];
    }

    fetch(apiurlstr)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if(result['success']==1){
          console.log(result['data'])
          setOrdersList(result['data'])
          setSearchOrdersList(result['data'])
          setOriginalListing(result['data'])
        }else{
          setOrdersList([])
          setSearchOrdersList([])

          let errMessage=result['message']?result['message']:"";
          alert(errMessage);
          if(result['status']==401 || result['status']==403){
            sessionStorage.clear();
            window.location.href="/"
          }
        }
      })
      .catch((error) => {
        alert(error)
        setOrdersList([])
        setSearchOrdersList([])
      });
  }



    return (<>
      <NavBar/>
      <div className="container col-sm-12" style={{paddingTop:100}}>
        <center><h2 className="h2">Orders List</h2></center>
        <ReturnOrdersListForm  handleSubmit={handleSubmit} />
        <ReturnOrdersListSearchForm filterOrders = {setOrdersList} searchOrders = {searchOrdersList} originalOrdersList = {originalListing} />
        <ReturnOrderListTable orderListData={ordersList} searchOrdersListData={searchOrdersList}  />
        {backDrop?(<Backdrop parentLoadStatus={backDrop}/>):(null)}
      </div></>
    )
}

export default ReturnOrders
