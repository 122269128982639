import React, { useState, useEffect, useRef } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useAsyncDebounce,
} from "react-table";
import ReactToPrint from "react-to-print";

import NavBar from "../Navigation bar/Navbar";
import axios from "axios";
import Select from "react-select";
import { service_url } from "../../Configs/mysqlconfig";
import { Row, Col, Form, Button, Modal, Spinner, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";

function Stockclearance() {
  const [values, setValues] = useState([]);
  const [items, setItems] = useState([]);
  const [wareHouse, setWareHouse] = useState([]);
  const [lastDate, setLastDate] = useState("");
  const [ClearanceDates, setClearanceDates] = useState([]);
  const dataToPrint = useRef();
  const [HighlightClearanceDates, setHighlightClearanceDates] = useState([]);
  const [HighlightClearanceDatesSet, setHighlightClearanceDatesSet] = useState(
    new Set()
  );

  const [payloadsItems, setPayloadsItems] = useState({});
  const [payloadsItems2, setPayloadsItems2] = useState({});
  const setPayloadsItemsRef = useRef({});

  const [StockClearanceTableValues, setStockClearanceTableValues] = useState(
    []
  );

  const [loader, setLoader] = useState(false);

  var current_date = new Date();
  const [payloads, setPayloads] = useState({
    stock_date: current_date.toISOString().substr(0, 10),
    warehouse: "Andheri",
  });

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    var d1 = new Date(payloads.stock_date);
    var d2 = new Date(lastDate);

    if (d1 < d2) {
      toast(
        `Please chose the date after the last update, that is ${lastDate}`,
        {
          type: toast.TYPE.ERROR,
        }
      );
    } else if (payloads.warehouse == "") {
      toast(`Please chose a warehouse`, {
        type: toast.TYPE.ERROR,
      });
    } else {
      setShow(true);
    }
  };

  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_RIGHT,
  });

  useEffect(() => {
    let wh = ["Nashik", "Mira Road", "Vashi", "Wadala"].map(
      (item) => {
        return { value: item, label: item };
      }
    );

    setWareHouse(wh);

    const url = `${service_url}/api/superzop/systeminventory/getLastStockClearanceDate`;
    axios
      .get(url)
      .then(function (response) {
        setLastDate(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    const url1 = `${service_url}/api/superzop/systeminventory/getStockClearanceDates`;

    axios
      .get(url1)
      .then(function (response) {
        setClearanceDates(response.data);

        let dateset = new Set();
        let dates = response.data.map((item) => {
          dateset.add(
            item.stock_clearance_date.substring(0, 10) + "#" + item.warehouse
          );
          return new Date(item.stock_clearance_date.substring(0, 10));
        });

        setHighlightClearanceDates(dates);
        setHighlightClearanceDatesSet(dateset);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (payloads.warehouse !== "") {
      setLoader(true);
    }

    if (
      HighlightClearanceDatesSet.has(
        payloads.stock_date + "#" + payloads.warehouse
      )
    ) {
      const url = `${service_url}/api/superzop/systeminventory/getStockClearance?warehouse=${payloads.warehouse}&date=${payloads.stock_date}`;

      axios
        .get(url)
        .then(function (response) {
          setStockClearanceTableValues(response.data);
          setLoader(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      const url1 = `${service_url}/api/superzop/systeminventory/getInventoryStock?warehouse=${payloads.warehouse}&date=${payloads.stock_date}`;

      axios
        .get(url1)
        .then(function (response) {
          setValues(response.data);

          let obj = {};
          let obj2 = {};

          response.data.map((itm) => {
            if (itm.item_id != null) {
              obj2[itm.item_id] = {
                resultant_stock:
                  itm.transit_shipped_qty - itm.shipped_qty + itm.returned_qty,
                item_disc: itm.item_disc,
                variety: itm.variety,
                category: itm.category,
              };
              obj[itm.item_id] =
                itm.transit_shipped_qty - itm.shipped_qty + itm.returned_qty;
            }
          });

          setItems(response.data);
          // setPayloadsItems(obj);
          setPayloadsItems2(obj2);
          setLoader(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [payloads]);

  useEffect(() => {
    let dateset = new Set();
    let dates = ClearanceDates.map((item) => {
      if (item.warehouse == payloads.warehouse) {
        dateset.add(
          item.stock_clearance_date.substring(0, 10) + "#" + item.warehouse
        );
        return new Date(item.stock_clearance_date.substring(0, 10));
      }
    });

    setHighlightClearanceDates(dates);
    setHighlightClearanceDatesSet(dateset);

    return () => {};
  }, [payloads.warehouse]);

  function handleSaveChanges() {
    payloads["item_list"] = setPayloadsItemsRef.current;

    var d1 = new Date(payloads.stock_date);
    var d2 = new Date(lastDate);

    if (d1 > d2) {
      const url = `${service_url}/api/superzop/systeminventory/StockClearance`;

      axios
        .post(url, payloads)
        .then(function (response) {
          toast("success", {
            type: toast.TYPE.SUCCESS,
          });

          setTimeout(function () {
            window.location.reload(false);
          }, 2500);
        })
        .catch(function (error) {
          console.log(error);
          toast(error.response.statusText, {
            type: toast.TYPE.ERROR,
          });
        });
    } else {
      toast(
        `Please chose the date after the last update, that is ${lastDate} `,
        {
          type: toast.TYPE.ERROR,
        }
      );
    }
  }

  function Table2() {
    // for stock clearance table

    let cnt = 1;
    let table_data = StockClearanceTableValues.map((itm) => {
      return {
        id: cnt++,
        stock_clearance_date: itm.stock_clearance_date.slice(0, 10),
        system_stock: itm.system_stock,
        actual_stock: itm.actual_stock,
        difference: itm.difference,
        item_id: itm.item_id,
        warehouse: itm.warehouse,
        created_at: itm.created_at,
      };
    });

    const data = React.useMemo(() => table_data, []);

    const columns = React.useMemo(
      () => [
        {
          Header: "Id",
          accessor: "id",
        },
        {
          Header: "Item id",
          accessor: "item_id",
        },
        // {
        //   Header: 'Warehouse',
        //   accessor: 'warehouse',
        // },
        {
          Header: "System stock",
          accessor: "system_stock",
        },
        {
          Header: "Actual stock",
          accessor: "actual_stock",
        },
        {
          Header: "Difference",
          accessor: "difference",
        },

        // {
        //   Header: 'Created at',
        //   accessor: 'created_at',
        // },
      ],
      []
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({ columns, data });

    return (
      <div>
        <h5>
          Stock Clearance Table{" "}
          {loader && (
            <Spinner
              animation="border"
              style={{ width: "25px", height: "25px" }}
            />
          )}{" "}
        </h5>

        <table
          {...getTableProps()}
          style={{ border: "solid 1px blue", width: "100%" }}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{
                      padding: "10px",
                      color: "black",
                      fontWeight: "bold",
                      border: "solid 1px gray",
                    }}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: "10px",
                          border: "solid 1px gray",
                          background: "yellow",
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  function Table1() {
    // for inventory stock

    let c = 1;
    let table_data = values.map((item) => {
      return {
        slno: c++,
        item_id: item.item_id,
        item_disc: item.item_disc,
        variety: item.variety,
        category: item.category,
        // warehouse: item.warehouse,
        returned_qty: item.returned_qty,
        shipped_qty: item.shipped_qty,
        delivered_qty: item.delivered_qty,
        transit_shipped_qty: item.transit_shipped_qty,
        resultant_stock:
          item.transit_shipped_qty - item.shipped_qty + item.returned_qty,
      };
    });

    c = 1;
    let csv_table_data = values.map((item) => {
      return [
        c++,
        item.item_id,
        item.item_disc,
        item.variety,
        item.category,
        // item.warehouse,
        item.transit_shipped_qty,
        item.delivered_qty,
        item.shipped_qty,
        item.returned_qty,
        item.transit_shipped_qty - item.shipped_qty + item.returned_qty,
      ];
    });

    csv_table_data.unshift([
      "slno",
      "Item id",
      "Item disc",
      "Variety",
      "Category",

      // "Warehouse",
      "Transit shipped qty",
      "Delivered qty",
      "Shipped qty",
      "Returned qty",
      "Resultant Stock",
    ]);

    const data = React.useMemo(() => table_data, []);

    const columns = React.useMemo(
      () => [
        {
          Header: "Sl No",
          accessor: "slno",
        },
        {
          Header: "Item id",
          accessor: "item_id",
        },
        {
          Header: "Item disc",
          accessor: "item_disc",
          Filter: SelectColumnFilter,
        },
        {
          Header: "Variety",
          accessor: "variety",
          Filter: SelectColumnFilter,
        },
        {
          Header: "Category",
          accessor: "category",
          Filter: SelectColumnFilter,
        },
        // {
        //   Header: "Warehouse",
        //   accessor: "warehouse",
        // },
        {
          Header: "Transit shipped qty",
          accessor: "transit_shipped_qty",
        },
        {
          Header: "Delivered qty",
          accessor: "delivered_qty",
        },
        {
          Header: "Shipped qty",
          accessor: "shipped_qty",
        },
        {
          Header: "Returned qty",
          accessor: "returned_qty",
        },
        {
          Header: "Resultant Stock",
          accessor: "resultant_stock",
        },
      ],
      []
    );

    const defaultColumn = React.useMemo(
      () => ({
        Filter: () => null,
      }),
      []
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      setGlobalFilter,
      preGlobalFilteredRows,
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        initialState: {
          hiddenColumns: ["stock_clearance_date"],
        },
      },
      useFilters,
      useGlobalFilter
    );

    const GlobalFilter = ({ filter, setFilter }) => {
      return (
        <div className="search_container">
          <div style={{ margin: 20 }}>
            <Row>
              <Col>
                <CSVLink
                  className="btn btn-warning export_btn"
                  filename={"stock_clearance.csv"}
                  data={csv_table_data}
                >
                  Export Data
                </CSVLink>
              </Col>
            </Row>
          </div>
          {filter && "Filter : " + filter}
        </div>
      );
    };
    const { globalFilter } = state;

    return (
      <div>
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        <h5>
          System Stock Table{" "}
          {loader && (
            <Spinner
              animation="border"
              style={{ width: "25px", height: "25px" }}
            />
          )}{" "}
        </h5>
        {loader}

        <table
          {...getTableProps()}
          style={{ border: "solid 1px blue", width: "100%" }}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{
                      padding: "10px",
                      color: "black",
                      fontWeight: "bold",
                      border: "solid 1px gray",
                    }}
                  >
                    {column.render("Header")}
                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: "10px",
                          border: "solid 1px gray",
                          background: "yellow",
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
      <select
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  }

  const ComponentToPrint = React.forwardRef((props, ref) => {
    return (
      <div ref={ref}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Item Id</th>
              <th>Item description</th>
              <th>Variety</th>
              <th>Category</th>
              <th>Return stock</th>
            </tr>
          </thead>
          <tbody>
            {payloadsItems &&
              payloadsItems2 &&
              Object.keys(payloadsItems2).map((itm) => {
                return (
                  <tr key={itm}>
                    <td>{itm}</td>
                    <td>{payloadsItems2[itm]?.item_disc}</td>
                    <td>{payloadsItems2[itm]?.variety}</td>
                    <td>{payloadsItems2[itm]?.category}</td>
                    <td>
                      {" "}
                      <Form.Group className="mb-3" controlId="">
                        <Form.Control
                          type="number"
                          value={payloadsItems[itm]}
                          onChange={(e) => {
                            setPayloadsItemsRef.current = {
                              ...setPayloadsItemsRef.current,
                              [itm]: e.target.value,
                            };
                          }}
                        />
                      </Form.Group>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    );
  });

  return (
    <div>
      <NavBar />
      <br />
      <br />
      <br />
      <br />

      <div style={{ margin: 20 }}>
        <h2>Stock clearance</h2>
        <div style={{ background: "papayawhip", padding: "20px" }}>
          <Form>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Stock date</Form.Label>
                  <br></br>
                  <DatePicker
                    selected={new Date(payloads.stock_date)}
                    highlightDates={HighlightClearanceDates}
                    onChange={(date) => {
                      setPayloads({
                        ...payloads,
                        stock_date: date.toISOString().substring(0, 10),
                      });
                    }}
                  />

                  <Form.Text muted> {payloads.stock_date} </Form.Text>
                </Form.Group>
              </Col>

              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Warehouse</Form.Label>
                  <Select
                    defaultValue={{ label: "Andheri", value: "" }}
                    onChange={(e) => {
                      setPayloads({ ...payloads, warehouse: e.value });
                    }}
                    options={wareHouse}
                  />
                  <Form.Text muted> {payloads.warehouse} </Form.Text>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <br />
                  <Button
                    variant="primary"
                    onClick={handleShow}
                    style={{ marginTop: "8px" }}
                  >
                    Create Stock Clearance
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </div>
      </div>

      <div style={{ margin: 20 }}>
        {HighlightClearanceDatesSet.has(
          payloads.stock_date + "#" + payloads.warehouse
        ) ? (
          <Table2 />
        ) : (
          <Table1 />
        )}
      </div>

      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Item List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ComponentToPrint ref={dataToPrint} />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <ReactToPrint
            trigger={() => <Button> Print </Button>}
            content={() => {
              return dataToPrint.current;
            }}
          />
          <Button variant="primary" onClick={handleSaveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Stockclearance;
