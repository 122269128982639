import React, { useState, useEffect, useRef } from "react";
import fire from "../../Configs/firebase";
import { Table, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import szlogo from "../../assests/images/sz_logo.png";
import khetika from "../../assests/images/khetika.png";
import khetika_qr from "../../assests/images/khetika_qr.png";
import footer from "../../assests/images/footer.png";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import ReactToPrint from "react-to-print";
import Backdrop from "../backdrop/backdrop";
import AddItemModal from "../OrdersListing/AddNewItemModal";
import { IconButton } from "@material-ui/core";
import Select from "react-select";
import { validateIsAdminUser } from "../UserCredentials";
import superprimelogo from "../../assests/images/superprimelogo.png";
import priorityshipping from "../../assests/images/priority_shipping.png";
import { support_portal_url, orderingApiUrl } from "../../Configs/mysqlconfig";
import { Image } from "@mantine/core";
import { map } from "underscore";

function formatDate(selecteddate) {
  let dt = new Date(selecteddate);
  let monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  let day = dt.getDate();

  let monthIndex = dt.getMonth();
  let monthName = monthNames[monthIndex];

  let year = dt.getFullYear().toString().substr(2, 2);

  //temp solution to pre date invoice dates
  let formattedDate = `${day}-${monthName}-${year}`;
  return formattedDate;
}

function getRetailerIdFromOrderNumber(orderNumber) {
  if (!orderNumber) {
    return "";
  }

  if (orderNumber.includes("SC")) {
    let retailerStr = orderNumber.split("/")[0];
    return retailerStr.substr(retailerStr.length - 5);
  } else if (orderNumber.includes("-")) {
    return orderNumber.split("-")[0];
  } else {
    return orderNumber.split("/")[0];
  }
}

function formatPhone(phone, secondary_number) {
  let phonestr = "" + phone;
  if (secondary_number && secondary_number != "null") {
    phonestr += "," + secondary_number;
  }
  return phonestr;
}

function formatGstNumber(gst_number) {
  return `GSTIN : ${gst_number}`;
}

function formatFssai(fssai) {
  return `FSSAI : ${fssai}`;
}

function formatChequeDate(deliveredDate) {
  let tomorrow = new Date(deliveredDate);
  tomorrow.setDate(tomorrow.getDate() + 12);

  let date = tomorrow.getDate();
  let month = tomorrow.getMonth() + 1;
  let year = tomorrow.getFullYear();

  return `${date}/${month}/${year}`;
}

function formatDeliveryDate(dt) {
  let dateList = dt.split("-");
  let year = dateList[2];
  let month = "" + dateList[1];
  let date = "" + dateList[0];

  if (date.length < 2) {
    date = "0" + date;
  }

  if (month.length < 2) {
    month = "0" + month;
  }

  return `${year}-${month}-${date}`;
}

const NewBulkOrders = () => {
  let deliveryDate = sessionStorage.getItem("getDB")
    ? sessionStorage.getItem("getDB")
    : "";
  let deliveryDateList = deliveryDate.split("/");
  let finalDeliveryDate = deliveryDateList[deliveryDateList.length - 1];
  finalDeliveryDate = formatDeliveryDate(finalDeliveryDate);

  let warehouse = sessionStorage.getItem("warehouse") || "";

  const [bulkOrderDetails, setBulkOrderDetails] = useState([]);
  const [backupBulkOrderDetails, setBackupBulkOrderDetails] = useState([]);
  const [numPrint, setNumPrint] = useState(1);
  const [vehicleOptions, setVehicleOptions] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState();
  const [itemAmountErrorMessage, setItemAmountErrorMessage] = useState([]);
  const [gstChargeErrorMessage, setGstChargeErrorMessage] = useState([]);
  const [orderAmountErrorMessage, setOrderAmountErrorMessage] = useState([]);
  const [getBackDrop, setBackDrop] = useState(true);
  const [selectedVarietyFilters, setSelectedVarietyFilters] = useState([]);

  const [getPoObj, setPoObj] = useState({});
  const [getAmazonItemIdMap, setAmazonItemIdMap] = useState({});

  const [invoiceChequeMap, setInvoiceChequeMap] = useState("");
  const [invoiceSignMap, setInvoiceSignMap] = useState("");

  const [varietyList, setVarietyList] = useState([]);
  const delhiRetailerIds = [81286, 81287];

  const dataToPrint = useRef();

  let invoiceDate = new Date();
  let monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  invoiceDate = `${
    invoiceDate.getDate() < 10
      ? `0${invoiceDate.getDate()}`
      : invoiceDate.getDate()
  }-${monthNames[invoiceDate.getMonth()]}-${invoiceDate
    .getFullYear()
    .toString()
    .substr(2, 2)}`;

  const convertCity = (cityVal, retailerid) => {
    cityVal = cityVal.toLowerCase();
    if (cityVal == "pune") {
      return "Pune";
    } else if (cityVal == "surat") {
      return "Surat";
    } else if (cityVal == "nashik") {
      return "Nashik";
    } else {
      if (Number(retailerid) > 100000 || cityVal.includes("goa")) {
        return "Goa";
      } else {
        return "Mumbai";
      }
    }
  };

  let invoiceList = JSON.parse(sessionStorage.getItem("invoiceList")) || [];
  console.log(invoiceList);

  useEffect(() => {
    setBackDrop(true);

    Promise.all([
      fire.database().ref(`Order-PO`).once("value"),
      fire.database().ref(`Amazon-ItemIDs`).once("value"),
    ]).then(([poSnap, amazonItemSnap]) => {
      let poObj = poSnap.val() || {};
      let amazonItemMap = amazonItemSnap.val() || {};

      let invoiceObj = {};
      let invoicesignobj = {};
      for (let invoice of invoiceList) {
        let retailer_id = invoice.retailer_id;
        let cheque_allowed = invoice.cheque_allowed == 1 ? "Y" : "N";
        let signature_required = invoice.signature_required == 1 ? "Y" : "N";

        invoiceObj[retailer_id] = cheque_allowed;
        invoicesignobj[retailer_id] = signature_required;
      }

      setBackDrop(false);
      setPoObj(poObj);
      setAmazonItemIdMap(amazonItemMap);
      setInvoiceChequeMap(invoiceObj);
      setInvoiceSignMap(invoicesignobj);
    });
  }, []);

  useEffect(() => {
    if (selectedVehicle != "All") {
      generateRouteBillsData(selectedVehicle);
    } else {
      generateAllRouteOrdersBillsData();
    }
  }, [getPoObj]);

  const isDelhiWarehouse = (orderdetails) => { 
      return (
        delhiRetailerIds.includes((orderdetails.retailer_id)) &&
        selectedVehicle.startsWith("F4")
      );
    };

  useEffect(() => {
    let vehicleNo = sessionStorage.getItem("routeIdToPrint");
    let dateToPrintOrders = new Date(sessionStorage.getItem(`dateToPrint`));
    dateToPrintOrders = `${dateToPrintOrders.getDate()}-${
      dateToPrintOrders.getMonth() + 1
    }-${dateToPrintOrders.getFullYear()}`;
    let currentDt = `${new Date().getDate()}-${
      new Date().getMonth() + 1
    }-${new Date().getFullYear()}`;

    let ordersHistory = JSON.parse(localStorage.getItem("orderHistory"));
    let userCity = sessionStorage.getItem("userCity") || "";
    let vehRouteList = Object.keys(ordersHistory);

    let optList = [];
    optList.push({ value: "All", label: "All" });

    Object.keys(ordersHistory).map((rkey, rindex) => {
      let vehicleOrders = ordersHistory[rkey] || {};
      let isCityOrderPresent = false;
      Object.keys(vehicleOrders).map((okey, oindex) => {
        let city = vehicleOrders[okey].city || ""; //vehicleOrders[okey].actual_city
        // city = convertCity(city);

        if (
          (!validateIsAdminUser(
            JSON.parse(sessionStorage.getItem(`user`))[0].email
          ) &&
            (!userCity || (userCity && userCity == city))) ||
          (validateIsAdminUser(
            JSON.parse(sessionStorage.getItem(`user`))[0].email
          ) &&
            (!warehouse || warehouse == city || warehouse == "All"))
        ) {
          isCityOrderPresent = true;
        }
      });

      if (isCityOrderPresent) {
        optList.push({ value: rkey, label: rkey });
      }
    });

    setVehicleOptions(optList);
    setSelectedVehicle(vehicleNo);

    if (vehicleNo != "All") {
      generateRouteBillsData(vehicleNo);
    } else {
      generateAllRouteOrdersBillsData();
    }
  }, []);

  const generateAllRouteOrdersBillsData = (vehicleNo) => {
    setBackDrop(true);
    let ordersHistory = JSON.parse(localStorage.getItem("orderHistory"));

    if (Object.keys(ordersHistory).length == 0) {
      setVarietyList([]);
      setBackDrop(false);
      setItemAmountErrorMessage([]);
      setOrderAmountErrorMessage([]);
      setBulkOrderDetails([]);
      setBackupBulkOrderDetails([]);
      return;
    }

    let userCity = sessionStorage.getItem("userCity") || "";

    let orderNumberSet = new Set();
    let varietySet = new Set();
    let orderstring = "(";

    Object.keys(ordersHistory).map((rkey, rindex) => {
      let vehicleOrders = ordersHistory[rkey] || [];

      Object.keys(vehicleOrders).map((key, index) => {
        let order_number = vehicleOrders[key]["order_number"];
        let city = vehicleOrders[key]["city"] || ""; //vehicleOrders[key]["actual_city"]
        // city = convertCity(city);
        let variety = vehicleOrders[key]["variety"];

        if (
          (!validateIsAdminUser(
            JSON.parse(sessionStorage.getItem(`user`))[0].email
          ) &&
            (!userCity || (userCity && userCity == city))) ||
          (validateIsAdminUser(
            JSON.parse(sessionStorage.getItem(`user`))[0].email
          ) &&
            (!warehouse || warehouse == city || warehouse == "All"))
        ) {
          if (!orderNumberSet.has(order_number)) {
            orderNumberSet.add(order_number);
            orderstring = orderstring + `'${order_number}',`;
          }

          if (!varietySet.has(variety)) {
            varietySet.add(variety);
          }
        }
      });
    });

    if (orderstring.length > 1) {
      orderstring = orderstring.slice(0, -1);
      orderstring = orderstring + ")";
    }

    let orderNumberList = [...orderNumberSet];

    // make data
    let bulkOrdersList = [];
    for (let i = 0; i < orderNumberList.length; i++) {
      let currOrderNumber = orderNumberList[i];
      let orderDate = "";
      let priority = "";
      let currOrdersItemsData = [];
      let itemOrdersDiscrepancyData = [];
      let partiallyDeliveredCreditValue = 0;
      let sumOfItemMrp = 0;
      let orderDisc = 0;
      let cashbackRedeemed = 0;

      let address,
        status,
        address1,
        address2,
        actual_city,
        agent_id,
        apartment,
        area,
        bagdetails,
        block,
        card_amt,
        cash_amt,
        cashback_redeemed,
        city,
        credit_amt,
        customer_id,
        store_series,
        final_order_amt,
        fssai_document_number,
        gst_number,
        razorpay_id,
        razorpay_qr_url;
      let name,
        net_order_amt,
        numbags,
        order_amt,
        order_date,
        order_disc,
        order_disc_amt,
        order_numberr,
        order_payment_mode,
        order_time,
        paid_date,
        paytm_amt,
        phone,
        pincode,
        processed_date,
        retailer_id,
        secondary_number,
        shop_name,
        priority_user,
        prime_user;
      let routeID = "";
      let bagStr = "";
      let bagSet = new Set();

      Object.keys(ordersHistory).map((rkey, rindex) => {
        let vehicleOrders = ordersHistory[rkey] || [];
        Object.keys(vehicleOrders).map((key, index) => {
          let order_number = vehicleOrders[key]["order_number"];
          if (currOrderNumber == order_number) {
            routeID = rkey;
            orderDate = vehicleOrders[key]["orderDate"];
            priority = vehicleOrders[key]["priority"];

            //order level
            razorpay_id = vehicleOrders[key]["razorpay_id"]
              ? vehicleOrders[key]["razorpay_id"]
              : "";
            razorpay_qr_url = vehicleOrders[key]["razorpay_qr_url"]
              ? vehicleOrders[key]["razorpay_qr_url"]
              : "";

            address = vehicleOrders[key]["address"]
              ? vehicleOrders[key]["address"]
              : "";
            address1 = vehicleOrders[key]["address1"]
              ? vehicleOrders[key]["address1"]
              : "";
            address2 = vehicleOrders[key]["address2"]
              ? vehicleOrders[key]["address2"]
              : "";
            actual_city = vehicleOrders[key]["actual_city"]
              ? vehicleOrders[key]["actual_city"]
              : "";
            agent_id = vehicleOrders[key]["agent_id"]
              ? vehicleOrders[key]["agent_id"]
              : "";
            apartment = vehicleOrders[key]["apartment"]
              ? vehicleOrders[key]["apartment"]
              : "";
            area = vehicleOrders[key]["area"] ? vehicleOrders[key]["area"] : "";

            block = vehicleOrders[key]["block"]
              ? vehicleOrders[key]["block"]
              : "";
            card_amt = vehicleOrders[key]["card_amt"]
              ? vehicleOrders[key]["card_amt"]
              : "";
            cash_amt = vehicleOrders[key]["cash_amt"]
              ? vehicleOrders[key]["cash_amt"]
              : "";
            cashback_redeemed = vehicleOrders[key]["cashback_redeemed"]
              ? vehicleOrders[key]["cashback_redeemed"]
              : "";
            city =
              vehicleOrders[key]["actual_city"] ||
              vehicleOrders[key]["city"] ||
              "";
            credit_amt = vehicleOrders[key]["credit_amt"]
              ? vehicleOrders[key]["credit_amt"]
              : "";
            customer_id = vehicleOrders[key]["customer_id"]
              ? vehicleOrders[key]["customer_id"]
              : "";
            store_series = vehicleOrders[key]["store_series"]
              ? vehicleOrders[key]["store_series"]
              : "";
            final_order_amt = vehicleOrders[key]["final_order_amt"]
              ? vehicleOrders[key]["final_order_amt"]
              : "";
            fssai_document_number = vehicleOrders[key]["fssai_document_number"]
              ? vehicleOrders[key]["fssai_document_number"]
              : "";
            gst_number = vehicleOrders[key]["gst_number"]
              ? vehicleOrders[key]["gst_number"]
              : "";
            name = vehicleOrders[key]["name"] ? vehicleOrders[key]["name"] : "";
            net_order_amt = vehicleOrders[key]["total_amt"]
              ? vehicleOrders[key]["total_amt"]
              : 0;

            order_amt = vehicleOrders[key]["order_amt"]
              ? vehicleOrders[key]["order_amt"]
              : "";
            order_date = vehicleOrders[key]["order_date"]
              ? vehicleOrders[key]["order_date"]
              : "";
            order_disc = vehicleOrders[key]["revised_order_disc"]
              ? vehicleOrders[key]["revised_order_disc"]
              : vehicleOrders[key]["order_disc"]
              ? vehicleOrders[key]["order_disc"]
              : "";
            order_disc_amt = vehicleOrders[key]["order_disc_amt"]
              ? vehicleOrders[key]["order_disc_amt"]
              : "";
            order_numberr = vehicleOrders[key]["order_number"]
              ? vehicleOrders[key]["order_number"]
              : "";
            order_payment_mode = vehicleOrders[key]["order_payment_mode"]
              ? vehicleOrders[key]["order_payment_mode"]
              : "";
            order_time = vehicleOrders[key]["order_time"]
              ? vehicleOrders[key]["order_time"]
              : "";
            paid_date = vehicleOrders[key]["paid_date"]
              ? vehicleOrders[key]["paid_date"]
              : "";
            paytm_amt = vehicleOrders[key]["paytm_amt"]
              ? vehicleOrders[key]["paytm_amt"]
              : "";
            phone = vehicleOrders[key]["phone"]
              ? vehicleOrders[key]["phone"]
              : "";
            pincode = vehicleOrders[key]["pincode"]
              ? vehicleOrders[key]["pincode"]
              : "";
            processed_date = vehicleOrders[key]["processed_date"]
              ? vehicleOrders[key]["processed_date"]
              : "";
            retailer_id = vehicleOrders[key]["retailer_id"]
              ? vehicleOrders[key]["retailer_id"]
              : "";
            secondary_number = vehicleOrders[key]["secondary_number"]
              ? vehicleOrders[key]["secondary_number"]
              : "";
            shop_name = vehicleOrders[key]["shop_name"]
              ? vehicleOrders[key]["shop_name"]
              : "";
            prime_user = vehicleOrders[key]["prime_user"];
            priority_user = vehicleOrders[key]["is_priority"];
            status = vehicleOrders[key]["status"]
              ? vehicleOrders[key]["status"]
              : "";
            let bagId = vehicleOrders[key]["bag_id"] || "";
            if (bagId && !bagSet.has(bagId)) {
              bagStr += `${bagId},`;
              bagSet.add(bagId);
            }

            //items
            let apmc_charge = vehicleOrders[key]["apmc_charge"]
              ? Number(vehicleOrders[key]["apmc_charge"])
              : 0;
            let dealer_price = vehicleOrders[key]["dealer_price"]
              ? Number(vehicleOrders[key]["dealer_price"])
              : 0;

            if (getPoNo(order_number)) {
              dealer_price = Number(dealer_price).toFixed(2);
            }

            let gst_charge = vehicleOrders[key]["gst_charge"]
              ? Number(vehicleOrders[key]["gst_charge"])
              : 0;

            let item_disc = vehicleOrders[key]["item_disc"];
            let item_id = vehicleOrders[key]["item_id"];
            let net_amt = vehicleOrders[key]["net_amt"];
            let order_qty = vehicleOrders[key]["order_qty"]
              ? Number(vehicleOrders[key]["order_qty"])
              : 0;
            //take mrp as total_amt
            let total_amt = vehicleOrders[key]["mrp"];
            let transport_charge = vehicleOrders[key]["transport_charge"]
              ? Number(vehicleOrders[key]["transport_charge"])
              : 0;
            let weight = vehicleOrders[key]["weight"];
            let variety = vehicleOrders[key]["variety"];
            let Category = vehicleOrders[key]["category"]
              ? vehicleOrders[key]["category"]
              : "";
            let Brand = vehicleOrders[key]["brand"]
              ? vehicleOrders[key]["brand"]
              : "";
            let hsn_code = vehicleOrders[key]["hsn_code"] || "";
            let market_price = Number(vehicleOrders[key]["market_price"]) || 0;

            let item_quantity = 0;

            if (vehicleOrders[key].hasOwnProperty("item_delivered_qty")) {
              item_quantity = Number(vehicleOrders[key]["item_delivered_qty"]);
            } else if (
              vehicleOrders[key].hasOwnProperty("item_modified_qty") &&
              vehicleOrders[key].item_modified_qty != null
            ) {
              item_quantity = Number(vehicleOrders[key]["item_modified_qty"]);
            } else {
              item_quantity = order_qty;
            }

            let gst_per = Number(vehicleOrders[key]["gst_per"]) || 0;
            let initial_gst_charge = dealer_price * order_qty * (gst_per / 100);
            let modified_gst_charge = vehicleOrders[key].hasOwnProperty(
              "gst_per"
            )
              ? dealer_price * item_quantity * (gst_per / 100)
              : gst_charge;
            let calc_total_amt =
              dealer_price * item_quantity + modified_gst_charge;

            itemOrdersDiscrepancyData.push({
              apmc_charge: apmc_charge,
              dealer_price: dealer_price,
              gst_charge: gst_charge,
              item_disc: item_disc,
              item_id: item_id,
              net_amt: net_amt,
              order_qty: item_quantity,
              item_quantity: item_quantity,
              total_amt: total_amt,
              transport_charge: transport_charge,
              weight: weight,
              original_order_qty: order_qty,
              initial_gst_charge: initial_gst_charge,
              calc_total_amt: calc_total_amt,
              modified_gst_charge: modified_gst_charge,
            });

            // quantity modified case
            if (order_qty != item_quantity) {
              total_amt = (Number(total_amt) * item_quantity) / order_qty;
              gst_charge = vehicleOrders[key].hasOwnProperty("gst_per")
                ? dealer_price * item_quantity * (gst_per / 100)
                : gst_charge;
            }

            // if(gst_charge == 0 && getItemsWeight(weight,item_disc,1) <=25 && (Category == "FLOURS/SOOJI" || Category =="GRAINS/POHA" || Category == "RICE")) {
            //   gst_charge = ((dealer_price* item_quantity)+apmc_charge+transport_charge) * 5/105
            // }

            if (Number(item_quantity) > 0) {
              sumOfItemMrp += cleanNumbers(total_amt);
              let itemObj = {
                apmc_charge: apmc_charge,
                dealer_price: dealer_price,
                gst_charge: gst_charge,
                initial_gst_charge: initial_gst_charge,
                calc_total_amt: calc_total_amt,
                modified_gst_charge: modified_gst_charge,
                item_disc: item_disc,
                item_id: item_id,
                net_amt: net_amt,
                order_qty: item_quantity,
                item_quantity: item_quantity,
                total_amt: total_amt,
                transport_charge: transport_charge,
                weight: weight,
                original_order_qty: order_qty,
                market_price: market_price,
                variety: variety,
                Category: Category,
                Brand: Brand,
                hsn_code: hsn_code,
              };

              if (vehicleOrders[key].hasOwnProperty("gst_per")) {
                itemObj["gst_per"] = gst_per;
              }
              currOrdersItemsData.push(itemObj);
            }

            orderDisc = cleanNumbers(vehicleOrders[key]["order_disc"]);
            cashbackRedeemed = cleanNumbers(
              vehicleOrders[key]["cashback_redeemed"]
            );
          }
        });
      });

      partiallyDeliveredCreditValue = (
        sumOfItemMrp -
        orderDisc -
        cashbackRedeemed
      ).toFixed(2);

      if (bagStr) {
        bagStr = bagStr.slice(0, -1);
      }

      //sort by variety, category, brand
      currOrdersItemsData.sort(function (a, b) {
        if (a.variety > b.variety) {
          return 1;
        } else if (a.variety < b.variety) {
          return -1;
        } else if (a.Category > b.Category) {
          return 1;
        } else if (a.Category < b.Category) {
          return -1;
        } else if (a.Brand > b.Brand) {
          return 1;
        } else if (a.Brand < b.Brand) {
          return -1;
        } else {
          return 0;
        }
      });

      let orderele = {};
      orderele["razorpay_id"] = razorpay_id;
      orderele["razorpay_qr_url"] = razorpay_qr_url;

      orderele["address"] = address;
      orderele["address1"] = address1;
      orderele["address2"] = address2;
      orderele["actual_city"] = actual_city;
      orderele["agent_id"] = agent_id;
      orderele["apartment"] = apartment;
      orderele["area"] = area;
      orderele["bagdetails"] = bagStr;
      orderele["block"] = block;
      orderele["card_amt"] = card_amt;
      orderele["cash_amt"] = cash_amt;
      orderele["cashback_redeemed"] = cashback_redeemed;
      orderele["city"] = city;
      orderele["credit_amt"] = credit_amt;
      orderele["customer_id"] = customer_id;
      orderele["store_series"] = store_series;
      orderele["final_order_amt"] = final_order_amt;
      orderele["fssai_document_number"] = fssai_document_number;
      orderele["gst_number"] = gst_number;
      orderele["net_order_amt"] = net_order_amt;
      orderele["numbags"] = bagStr.split(",").length;
      orderele["order_amt"] = order_amt;
      orderele["order_date"] = order_date;
      orderele["order_disc"] = order_disc;
      orderele["order_disc_amt"] = order_disc_amt;
      orderele["order_number"] = order_numberr;
      orderele["order_payment_mode"] = order_payment_mode;
      orderele["order_time"] = order_time;
      orderele["paid_date"] = paid_date;
      orderele["paytm_amt"] = paytm_amt;
      orderele["phone"] = phone;
      orderele["pincode"] = pincode;
      orderele["processed_date"] = processed_date;
      orderele["retailer_id"] = retailer_id;
      orderele["secondary_number"] = secondary_number;
      orderele["shop_name"] = shop_name;
      orderele["prime_user"] = prime_user;
      orderele["is_priority"] = priority_user;
      orderele["status"] = status;
      orderele["partiallyDeliveredCreditValue"] = partiallyDeliveredCreditValue;
      orderele["items"] = currOrdersItemsData;
      orderele["discrepancyitems"] = itemOrdersDiscrepancyData;
      bulkOrdersList.push({
        orderDate: orderDate,
        orderdetails: orderele,
        orderid: currOrderNumber,
        priority: priority,
        routeID: routeID,
      });
    }

    //sort by route, priority
    bulkOrdersList.sort(function (a, b) {
      var keyA = a.priority,
        keyB = b.priority;

      if (a.routeID === b.routeID) {
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      }
      return a.routeID > b.routeID ? 1 : -1;
    });

    let orderAmountErrors = [];
    let itemAmountErrors = [];
    let gstChargeErrors = [];

    for (let j = 0; j < bulkOrdersList.length; j++) {
      let orderdata = bulkOrdersList[j]["orderdetails"];
      let itemdata = orderdata["discrepancyitems"];

      //check for discrepancies
      let order_number = orderdata["order_number"];
      let net_order_amt = orderdata["net_order_amt"]
        ? Number(orderdata["net_order_amt"])
        : 0;
      let order_disc = orderdata["order_disc"]
        ? Number(orderdata["order_disc"])
        : 0;
      let cashback_redeemed = orderdata["cashback_redeemed"]
        ? Number(orderdata["cashback_redeemed"])
        : 0;
      let sumTotalAmt = 0;

      for (let i = 0; i < itemdata.length; i++) {
        let weight = itemdata[i]["weight"];
        let category = itemdata[i]["category"];
        let item_disc = itemdata[i]["item_disc"];
        let total_amt = itemdata[i]["total_amt"]
          ? Number(itemdata[i]["total_amt"])
          : 0;
        let dealer_price = itemdata[i]["dealer_price"]
          ? Number(itemdata[i]["dealer_price"])
          : 0;

        if (getPoNo(order_number)) {
          dealer_price = Number(dealer_price).toFixed(2);
        }

        let order_qty = itemdata[i]["original_order_qty"]
          ? Number(itemdata[i]["original_order_qty"])
          : 0;
        let transport_charge = itemdata[i]["transport_charge"]
          ? Number(itemdata[i]["transport_charge"])
          : 0;
        let apmc_charge = itemdata[i]["apmc_charge"]
          ? Number(itemdata[i]["apmc_charge"])
          : 0;
        let gst_charge = itemdata[i]["gst_charge"]
          ? Number(itemdata[i]["gst_charge"])
          : 0;
        let initial_gst_charge = Number(itemdata[i]["initial_gst_charge"]) || 0;

        let calcItemValue =
          dealer_price * order_qty +
          transport_charge +
          apmc_charge +
          gst_charge;
        calcItemValue = calcItemValue.toFixed(2);
        calcItemValue = Number(calcItemValue);

        let itemValueDiff = calcItemValue - total_amt;
        if (
          calcItemValue != total_amt &&
          (itemValueDiff > 10 || itemValueDiff < -10) &&
          !order_number.includes("-R")
        ) {
          itemAmountErrors.push(
            `${order_number} : ${item_disc} has Item Value discrepancy with difference of ${itemValueDiff.toFixed(
              2
            )}`
          );
        }

        if (
          Math.abs(gst_charge - initial_gst_charge) > 1 &&
          itemdata[i].hasOwnProperty("gst_per")
        ) {
          gstChargeErrors.push(
            `${order_number} : ${item_disc} has gst charge discrepancy with difference of ${(
              initial_gst_charge - gst_charge
            ).toFixed(2)}`
          );
        }

        sumTotalAmt = sumTotalAmt + total_amt;
      }

      let calcOrderValue = sumTotalAmt - order_disc - cashback_redeemed;
      calcOrderValue = calcOrderValue.toFixed(2);
      calcOrderValue = Number(calcOrderValue);

      let orderValueDiff = calcOrderValue - net_order_amt;
      if (
        net_order_amt != calcOrderValue &&
        !order_number.includes("R") &&
        (orderValueDiff > 10 || orderValueDiff < -10)
      ) {
        orderAmountErrors.push(
          `Order number : ${order_number} has Order Value discrepancy with difference of ${orderValueDiff.toFixed(
            2
          )}`
        );
      }
    }

    let uniqueVarietyList = [...varietySet];
    let finalVarietyList = [];
    for (let z = 0; z < uniqueVarietyList.length; z++) {
      let type = uniqueVarietyList[z];
      finalVarietyList.push({ value: type, label: type });
    }
    setVarietyList(finalVarietyList);
    setBackDrop(false);
    setItemAmountErrorMessage(itemAmountErrors);
    setOrderAmountErrorMessage(orderAmountErrors);
    setGstChargeErrorMessage(gstChargeErrors);

    setBulkOrderDetails(bulkOrdersList);
    setBackupBulkOrderDetails(bulkOrdersList);

    document.getElementById("numbags").value = 1;
  };

  const generateRouteBillsData = (vehicleNo) => {
    setBackDrop(true);
    let ordersHistory = JSON.parse(localStorage.getItem("orderHistory"));

    if (Object.keys(ordersHistory).length == 0) {
      setVarietyList([]);
      setBackDrop(false);
      setItemAmountErrorMessage([]);
      setOrderAmountErrorMessage([]);
      setBulkOrderDetails([]);
      setBackupBulkOrderDetails([]);
      return;
    }

    let vehicleOrders = ordersHistory[vehicleNo];

    if (!vehicleOrders) {
      setVarietyList([]);
      setBackDrop(false);
      setItemAmountErrorMessage([]);
      setOrderAmountErrorMessage([]);
      setBulkOrderDetails([]);
      setBackupBulkOrderDetails([]);
      return;
    }

    let userCity = sessionStorage.getItem("userCity") || "";

    let orderNumberSet = new Set();
    let varietySet = new Set();
    let orderstring = "(";
    Object.keys(vehicleOrders).map((key, index) => {
      let order_number = vehicleOrders[key]["order_number"];
      let variety = vehicleOrders[key]["variety"];
      let city = vehicleOrders[key]["city"] || ""; //vehicleOrders[key]["actual_city"] || 
      // city = convertCity(city);

      if (
        (!validateIsAdminUser(
          JSON.parse(sessionStorage.getItem(`user`))[0].email
        ) &&
          (!userCity || (userCity && userCity == city))) ||
        (validateIsAdminUser(
          JSON.parse(sessionStorage.getItem(`user`))[0].email
        ) &&
          (!warehouse || warehouse == city || warehouse == "All"))
      ) {
        if (!orderNumberSet.has(order_number)) {
          orderNumberSet.add(order_number);
          orderstring = orderstring + `'${order_number}',`;
        }

        if (!varietySet.has(variety)) {
          varietySet.add(variety);
        }
      }
    });

    if (orderstring.length > 0) {
      orderstring = orderstring.slice(0, -1);
      orderstring = orderstring + ")";
    }

    let orderNumberList = [...orderNumberSet];

    // make data
    let bulkOrdersList = [];
    for (let i = 0; i < orderNumberList.length; i++) {
      let currOrderNumber = orderNumberList[i];
      let orderDate = "";
      let priority = "";
      let currOrdersItemsData = [];
      let itemOrdersDiscrepancyData = [];
      let partiallyDeliveredCreditValue = 0;
      let sumOfItemMrp = 0;
      let orderDisc = 0;
      let cashbackRedeemed = 0;

      let address,
        status,
        address1,
        address2,
        actual_city,
        agent_id,
        apartment,
        area,
        bagdetails,
        block,
        card_amt,
        cash_amt,
        cashback_redeemed,
        city,
        credit_amt,
        customer_id,
        store_series,
        final_order_amt,
        fssai_document_number,
        gst_number,
        razorpay_id,
        razorpay_qr_url;
      let name,
        net_order_amt,
        numbags,
        order_amt,
        order_date,
        order_disc,
        order_disc_amt,
        order_numberr,
        order_payment_mode,
        order_time,
        paid_date,
        paytm_amt,
        phone,
        pincode,
        processed_date,
        retailer_id,
        retailer_name,
        secondary_number,
        shop_name,
        priority_user,
        prime_user;
      let bagStr = "";
      let bagSet = new Set();

      Object.keys(vehicleOrders).map((key, index) => {
        let order_number = vehicleOrders[key]["order_number"];
        if (currOrderNumber == order_number) {
          orderDate = vehicleOrders[key]["orderDate"];
          priority = vehicleOrders[key]["priority"];

          //order level
          razorpay_id = vehicleOrders[key]["razorpay_id"]
            ? vehicleOrders[key]["razorpay_id"]
            : "";
          razorpay_qr_url = vehicleOrders[key]["razorpay_qr_url"]
            ? vehicleOrders[key]["razorpay_qr_url"]
            : "";

          address = vehicleOrders[key]["address"]
            ? vehicleOrders[key]["address"]
            : "";
          address1 = vehicleOrders[key]["address1"]
            ? vehicleOrders[key]["address1"]
            : "";
          address2 = vehicleOrders[key]["address2"]
            ? vehicleOrders[key]["address2"]
            : "";
          actual_city = vehicleOrders[key]["actual_city"]
            ? vehicleOrders[key]["actual_city"]
            : "";
          agent_id = vehicleOrders[key]["agent_id"]
            ? vehicleOrders[key]["agent_id"]
            : "";
          apartment = vehicleOrders[key]["apartment"]
            ? vehicleOrders[key]["apartment"]
            : "";
          area = vehicleOrders[key]["area"] ? vehicleOrders[key]["area"] : "";

          block = vehicleOrders[key]["block"]
            ? vehicleOrders[key]["block"]
            : "";
          card_amt = vehicleOrders[key]["card_amt"]
            ? vehicleOrders[key]["card_amt"]
            : "";
          cash_amt = vehicleOrders[key]["cash_amt"]
            ? vehicleOrders[key]["cash_amt"]
            : "";
          cashback_redeemed = vehicleOrders[key]["cashback_redeemed"]
            ? vehicleOrders[key]["cashback_redeemed"]
            : "";
          city =
            vehicleOrders[key]["actual_city"] ||
            vehicleOrders[key]["city"] ||
            "";
          credit_amt = vehicleOrders[key]["credit_amt"]
            ? vehicleOrders[key]["credit_amt"]
            : "";
          customer_id = vehicleOrders[key]["customer_id"]
            ? vehicleOrders[key]["customer_id"]
            : "";
          store_series = vehicleOrders[key]["store_series"]
            ? vehicleOrders[key]["store_series"]
            : "";
          final_order_amt = vehicleOrders[key]["final_order_amt"]
            ? vehicleOrders[key]["final_order_amt"]
            : "";
          fssai_document_number = vehicleOrders[key]["fssai_document_number"]
            ? vehicleOrders[key]["fssai_document_number"]
            : "";
          gst_number = vehicleOrders[key]["gst_number"]
            ? vehicleOrders[key]["gst_number"]
            : "";
          name = vehicleOrders[key]["name"] ? vehicleOrders[key]["name"] : "";
          net_order_amt = vehicleOrders[key]["total_amt"]
            ? vehicleOrders[key]["total_amt"]
            : 0;

          order_amt = vehicleOrders[key]["order_amt"]
            ? vehicleOrders[key]["order_amt"]
            : "";
          order_date = vehicleOrders[key]["order_date"]
            ? vehicleOrders[key]["order_date"]
            : "";
          order_disc = vehicleOrders[key]["revised_order_disc"]
            ? vehicleOrders[key]["revised_order_disc"]
            : vehicleOrders[key]["order_disc"]
            ? vehicleOrders[key]["order_disc"]
            : "";
          order_disc_amt = vehicleOrders[key]["order_disc_amt"]
            ? vehicleOrders[key]["order_disc_amt"]
            : "";
          order_numberr = vehicleOrders[key]["order_number"]
            ? vehicleOrders[key]["order_number"]
            : "";
          order_payment_mode = vehicleOrders[key]["order_payment_mode"]
            ? vehicleOrders[key]["order_payment_mode"]
            : "";
          order_time = vehicleOrders[key]["order_time"]
            ? vehicleOrders[key]["order_time"]
            : "";
          paid_date = vehicleOrders[key]["paid_date"]
            ? vehicleOrders[key]["paid_date"]
            : "";
          paytm_amt = vehicleOrders[key]["paytm_amt"]
            ? vehicleOrders[key]["paytm_amt"]
            : "";
          phone = vehicleOrders[key]["phone"]
            ? vehicleOrders[key]["phone"]
            : "";
          pincode = vehicleOrders[key]["pincode"]
            ? vehicleOrders[key]["pincode"]
            : "";
          processed_date = vehicleOrders[key]["processed_date"]
            ? vehicleOrders[key]["processed_date"]
            : "";
          retailer_id = vehicleOrders[key]["retailer_id"]
            ? vehicleOrders[key]["retailer_id"]
            : "";
          retailer_name = vehicleOrders[key]["retailer_name"]
            ? vehicleOrders[key]["retailer_name"]
            : "";
          secondary_number = vehicleOrders[key]["secondary_number"]
            ? vehicleOrders[key]["secondary_number"]
            : "";
          shop_name = vehicleOrders[key]["shop_name"]
            ? vehicleOrders[key]["shop_name"]
            : "";
          prime_user = vehicleOrders[key]["prime_user"];
          priority_user = vehicleOrders[key]["is_priority"];
          status = vehicleOrders[key]["status"]
            ? vehicleOrders[key]["status"]
            : "";

          let bagId = vehicleOrders[key]["bag_id"] || "";
          if (bagId && !bagSet.has(bagId)) {
            bagStr += `${bagId},`;
            bagSet.add(bagId);
          }

          //items
          let apmc_charge = vehicleOrders[key]["apmc_charge"]
            ? Number(vehicleOrders[key]["apmc_charge"])
            : 0;
          let dealer_price = vehicleOrders[key]["dealer_price"]
            ? Number(vehicleOrders[key]["dealer_price"])
            : 0;

          if (getPoNo(order_number)) {
            dealer_price = Number(dealer_price).toFixed(2);
          }

          let gst_charge = vehicleOrders[key]["gst_charge"]
            ? Number(vehicleOrders[key]["gst_charge"])
            : 0;
          let item_disc = vehicleOrders[key]["item_disc"];
          let item_id = vehicleOrders[key]["item_id"];
          let net_amt = vehicleOrders[key]["net_amt"];
          let order_qty = vehicleOrders[key]["order_qty"]
            ? Number(vehicleOrders[key]["order_qty"])
            : 0;
          //take mrp as total_amt
          let total_amt = vehicleOrders[key]["mrp"];
          let transport_charge = vehicleOrders[key]["transport_charge"]
            ? Number(vehicleOrders[key]["transport_charge"])
            : 0;
          let weight = vehicleOrders[key]["weight"];
          let variety = vehicleOrders[key]["variety"];
          let Category = vehicleOrders[key]["category"]
            ? vehicleOrders[key]["category"]
            : "";
          let Brand = vehicleOrders[key]["brand"]
            ? vehicleOrders[key]["brand"]
            : "";
          let hsn_code = vehicleOrders[key]["hsn_code"] || "";
          let market_price = Number(vehicleOrders[key]["market_price"]) || 0;
          let gst_per = Number(vehicleOrders[key]["gst_per"]) || 0;

          let item_quantity = 0;

          if (vehicleOrders[key].hasOwnProperty("item_delivered_qty")) {
            item_quantity = Number(vehicleOrders[key]["item_delivered_qty"]);
          } else if (
            vehicleOrders[key].hasOwnProperty("item_modified_qty") &&
            vehicleOrders[key]["item_modified_qty"] != null
          ) {
            item_quantity = Number(vehicleOrders[key]["item_modified_qty"]);
          } else {
            item_quantity = order_qty;
          }

          let initial_gst_charge = dealer_price * order_qty * (gst_per / 100);
          let modified_gst_charge = vehicleOrders[key].hasOwnProperty("gst_per")
            ? dealer_price * item_quantity * (gst_per / 100)
            : gst_charge;
          let calc_total_amt =
            dealer_price * item_quantity + modified_gst_charge;

          itemOrdersDiscrepancyData.push({
            apmc_charge: apmc_charge,
            dealer_price: dealer_price,
            gst_charge: gst_charge,
            calc_total_amt: calc_total_amt,
            modified_gst_charge: modified_gst_charge,
            initial_gst_charge: initial_gst_charge,
            item_disc: item_disc,
            item_id: item_id,
            net_amt: net_amt,
            order_qty: item_quantity,
            item_quantity: item_quantity,
            total_amt: total_amt,
            transport_charge: transport_charge,
            weight: weight,
            original_order_qty: order_qty,
          });

          // quantity modified case
          if (order_qty != item_quantity) {
            total_amt = (Number(total_amt) * item_quantity) / order_qty;
            gst_charge = (Number(gst_charge) * item_quantity) / order_qty;
          }

          if (vehicleOrders[key].hasOwnProperty("gst_per")) {
            if (
              gst_charge == 0 &&
              getItemsWeight(weight, item_disc, 1) <= 25 &&
              (Category == "FLOURS/SOOJI" ||
                Category == "GRAINS/POHA" ||
                Category == "RICE")
            ) {
              gst_charge =
                ((dealer_price * item_quantity +
                  apmc_charge +
                  transport_charge) *
                  5) /
                105;
            }
          }

          if (Number(item_quantity) > 0) {
            sumOfItemMrp += cleanNumbers(total_amt);
            let itemObj = {
              apmc_charge: apmc_charge,
              dealer_price: dealer_price,
              gst_charge: gst_charge,
              calc_total_amt: calc_total_amt,
              modified_gst_charge: modified_gst_charge,
              initial_gst_charge: initial_gst_charge,
              item_disc: item_disc,
              item_id: item_id,
              net_amt: net_amt,
              order_qty: item_quantity,
              item_quantity: item_quantity,
              total_amt: total_amt,
              transport_charge: transport_charge,
              weight: weight,
              original_order_qty: order_qty,
              market_price: market_price,
              variety: variety,
              Category: Category,
              Brand: Brand,
              hsn_code: hsn_code,
            };

            if (vehicleOrders[key].hasOwnProperty("gst_per")) {
              itemObj["gst_per"] = gst_per;
            }
            currOrdersItemsData.push(itemObj);
          }

          orderDisc = cleanNumbers(vehicleOrders[key]["order_disc"]);
          cashbackRedeemed = cleanNumbers(
            vehicleOrders[key]["cashback_redeemed"]
          );
        }
      });

      partiallyDeliveredCreditValue = (
        sumOfItemMrp -
        orderDisc -
        cashbackRedeemed
      ).toFixed(2);

      if (bagStr) {
        bagStr = bagStr.slice(0, -1);
      }

      //sort by variety, category, brand
      currOrdersItemsData.sort(function (a, b) {
        if (a.variety > b.variety) {
          return 1;
        } else if (a.variety < b.variety) {
          return -1;
        } else if (a.Category > b.Category) {
          return 1;
        } else if (a.Category < b.Category) {
          return -1;
        } else if (a.Brand > b.Brand) {
          return 1;
        } else if (a.Brand < b.Brand) {
          return -1;
        } else {
          return 0;
        }
      });

      let orderele = {};

      orderele["razorpay_id"] = razorpay_id;
      orderele["razorpay_qr_url"] = razorpay_qr_url;

      orderele["address"] = address;
      orderele["address1"] = address1;
      orderele["address2"] = address2;
      orderele["actual_city"] = actual_city;
      orderele["agent_id"] = agent_id;
      orderele["apartment"] = apartment;
      orderele["area"] = area;
      orderele["bagdetails"] = bagStr;
      orderele["block"] = block;
      orderele["card_amt"] = card_amt;
      orderele["cash_amt"] = cash_amt;
      orderele["cashback_redeemed"] = cashback_redeemed;
      orderele["city"] = city;
      orderele["credit_amt"] = credit_amt;
      orderele["customer_id"] = customer_id;
      orderele["store_series"] = store_series;
      orderele["final_order_amt"] = final_order_amt;
      orderele["fssai_document_number"] = fssai_document_number;
      orderele["gst_number"] = gst_number;
      orderele["net_order_amt"] = net_order_amt;
      orderele["numbags"] = bagStr.split(",").length;
      orderele["order_amt"] = order_amt;
      orderele["order_date"] = order_date;
      orderele["order_disc"] = order_disc;
      orderele["order_disc_amt"] = order_disc_amt;
      orderele["order_number"] = order_numberr;
      orderele["order_payment_mode"] = order_payment_mode;
      orderele["order_time"] = order_time;
      orderele["paid_date"] = paid_date;
      orderele["paytm_amt"] = paytm_amt;
      orderele["phone"] = phone;
      orderele["pincode"] = pincode;
      orderele["processed_date"] = processed_date;
      orderele["retailer_id"] = retailer_id;
      orderele["retailer_name"] = retailer_name;
      orderele["secondary_number"] = secondary_number;
      orderele["shop_name"] = shop_name;
      orderele["prime_user"] = prime_user;
      orderele["is_priority"] = priority_user;
      orderele["status"] = status;
      orderele["partiallyDeliveredCreditValue"] = partiallyDeliveredCreditValue;
      orderele["items"] = currOrdersItemsData;
      orderele["discrepancyitems"] = itemOrdersDiscrepancyData;
      bulkOrdersList.push({
        orderDate: orderDate,
        orderdetails: orderele,
        orderid: currOrderNumber,
        priority: priority,
      });
    }

    //sort by priority
    bulkOrdersList.sort(function (a, b) {
      var keyA = a.priority,
        keyB = b.priority;
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });

    let orderAmountErrors = [];
    let itemAmountErrors = [];
    let gstChargeErrors = [];

    for (let j = 0; j < bulkOrdersList.length; j++) {
      let orderdata = bulkOrdersList[j]["orderdetails"];
      let itemdata = orderdata["discrepancyitems"];

      //check for discrepancies
      let order_number = orderdata["order_number"];
      let net_order_amt = orderdata["net_order_amt"]
        ? Number(orderdata["net_order_amt"])
        : 0;
      let order_disc = orderdata["order_disc"]
        ? Number(orderdata["order_disc"])
        : 0;
      let cashback_redeemed = orderdata["cashback_redeemed"]
        ? Number(orderdata["cashback_redeemed"])
        : 0;
      let sumTotalAmt = 0;

      for (let i = 0; i < itemdata.length; i++) {
        let item_disc = itemdata[i]["item_disc"];
        let total_amt = itemdata[i]["total_amt"]
          ? Number(itemdata[i]["total_amt"])
          : 0;
        let dealer_price = itemdata[i]["dealer_price"]
          ? Number(itemdata[i]["dealer_price"])
          : 0;

        if (getPoNo(order_number)) {
          dealer_price = Number(dealer_price).toFixed(2);
        }

        let order_qty = itemdata[i]["original_order_qty"]
          ? Number(itemdata[i]["original_order_qty"])
          : 0;
        let transport_charge = itemdata[i]["transport_charge"]
          ? Number(itemdata[i]["transport_charge"])
          : 0;
        let apmc_charge = itemdata[i]["apmc_charge"]
          ? Number(itemdata[i]["apmc_charge"])
          : 0;
        let gst_charge = itemdata[i]["gst_charge"]
          ? Number(itemdata[i]["gst_charge"])
          : 0;
        let initial_gst_charge = Number(itemdata[i]["initial_gst_charge"]) || 0;

        let calcItemValue =
          dealer_price * order_qty +
          transport_charge +
          apmc_charge +
          gst_charge;
        calcItemValue = calcItemValue.toFixed(2);
        calcItemValue = Number(calcItemValue);

        let itemValueDiff = calcItemValue - total_amt;
        if (
          calcItemValue != total_amt &&
          (itemValueDiff > 10 || itemValueDiff < -10) &&
          !order_number.includes("-R")
        ) {
          itemAmountErrors.push(
            `${order_number} : ${item_disc} has Item Value discrepancy with difference of ${itemValueDiff.toFixed(
              2
            )}`
          );
        }

        if (
          Math.abs(initial_gst_charge - gst_charge) > 1 &&
          itemdata[i].hasOwnProperty("gst_per")
        ) {
          gstChargeErrors.push(
            `${order_number} : ${item_disc} has gst charge discrepancy with difference of ${(
              initial_gst_charge - gst_charge
            ).toFixed(2)}`
          );
        }

        sumTotalAmt = sumTotalAmt + total_amt;
      }

      let calcOrderValue = sumTotalAmt - order_disc - cashback_redeemed;
      calcOrderValue = calcOrderValue.toFixed(2);
      calcOrderValue = Number(calcOrderValue);

      let orderValueDiff = calcOrderValue - net_order_amt;
      if (
        net_order_amt != calcOrderValue &&
        !order_number.includes("R") &&
        (orderValueDiff > 10 || orderValueDiff < -10)
      ) {
        orderAmountErrors.push(
          `Order number : ${order_number} has Order Value discrepancy with difference of ${orderValueDiff.toFixed(
            2
          )}`
        );
      }
    }

    let uniqueVarietyList = [...varietySet];
    let finalVarietyList = [];
    for (let z = 0; z < uniqueVarietyList.length; z++) {
      let type = uniqueVarietyList[z];
      finalVarietyList.push({ value: type, label: type });
    }
    setVarietyList(finalVarietyList);
    setBackDrop(false);
    setItemAmountErrorMessage(itemAmountErrors);
    setOrderAmountErrorMessage(orderAmountErrors);
    setGstChargeErrorMessage(gstChargeErrors);

    setBulkOrderDetails(bulkOrdersList);
    setBackupBulkOrderDetails(bulkOrdersList);

    document.getElementById("numbags").value = 1;
  };

  useEffect(() => {
    if (backupBulkOrderDetails.length > 0) {
      let selectVarietyList = sessionStorage.getItem("varietyList")
        ? JSON.parse(sessionStorage.getItem("varietyList"))
        : [];
      setSelectedVarietyFilters(selectVarietyList);
    }
  }, [backupBulkOrderDetails]);

  useEffect(() => {
    filterRouteOrdersByVariety(selectedVarietyFilters);
  }, [selectedVarietyFilters]);

  useEffect(() => {
    let modifiedbulkOrderDetails = [];

    if (backupBulkOrderDetails.length > 0) {
      for (let j = 0; j < backupBulkOrderDetails.length; j++) {
        for (let i = 0; i < numPrint; i++) {
          let ele = backupBulkOrderDetails[j];
          if (numPrint > 1 && (ele["orderdetails"]["shop_name"].includes("D Mart") || ele["orderdetails"]["shop_name"].includes("DMart"))) {
            let invoicePrintType = ""
            if (i == 0 ) {
              invoicePrintType = " - ORIGINAL"
            } else if (i == 1) {
              invoicePrintType = " - DUPLICATE"
            } else if (i == 2) {
              invoicePrintType = " - TRIPLICATE"
            }
            modifiedbulkOrderDetails.push({...ele, invoicePrintType: invoicePrintType});
          } else {
            modifiedbulkOrderDetails.push(ele);
          }
        }
      }
      setBulkOrderDetails(modifiedbulkOrderDetails);
    }
  }, [numPrint]);

  useEffect(() => {
    if (selectedVehicle != "All") {
      generateRouteBillsData(selectedVehicle);
    } else {
      generateAllRouteOrdersBillsData();
    }
  }, [selectedVehicle]);

  const getItemsWeight = (indItemWeight, itemDesc, quantityOrdered) => {
    if (!indItemWeight) return 1;
    let itemWeight = Number(indItemWeight.replace(/\D/g, ""));
    if (indItemWeight.includes("gm") || indItemWeight.includes("ml")) {
      itemWeight = itemWeight / 1000;
    }

    if (
      (indItemWeight.includes("1 pc") || indItemWeight.includes("1 Pc")) &&
      itemDesc.includes("gm")
    ) {
      let itemWtExtract = itemDesc.split("gm");
      let itemWtExtract2 = itemWtExtract[0].split("-");
      itemWeight =
        parseFloat(
          itemWtExtract2[itemWtExtract2.length - 1].replace(/[^0-9]/g, "")
        ) / 1000;
    }
    if (itemWeight <= 0) {
      itemWeight = 1;
    }
    indItemWeight = itemWeight * Number(quantityOrdered);

    return `${indItemWeight.toFixed(2)}`;
  };

  const getTotalItems = (itemDetails) => {
    let sumOfItems = 0;
    itemDetails.forEach((item) => {
      sumOfItems++;
    });
    return parseInt(sumOfItems);
  };

  const getTotalItemsPcs = (itemDetails) => {
    let sumOfPcs = 0;
    itemDetails.forEach((item) => {
      sumOfPcs += item.item_quantity;
    });
    return parseInt(sumOfPcs);
  }

  const getTotalInclGST = (itemDetails, orderNo) => {
    let sumAmtInclGst = 0;
    itemDetails.forEach((item) => {
      if (item.hasOwnProperty("gst_per")) {
        sumAmtInclGst += Number(isNumber(item.calc_total_amt));
      } else {
        sumAmtInclGst += Number(isNumber(item.total_amt));
      }
    });

    if (getPoNo(orderNo)) {
      return sumAmtInclGst.toFixed(1);
    }
    return sumAmtInclGst.toFixed(2);
  };
  const getTotalExclGST = (itemDetails, orderNo) => {
    let sumGSTAmt = 0;
    let sumAmtInclGst = 0;
    itemDetails.forEach((item) => {
      if (item.hasOwnProperty("gst_per")) {
        sumAmtInclGst += Number(isNumber(item.calc_total_amt));
        sumGSTAmt += Number(isNumber(item.modified_gst_charge));
      } else {
        sumAmtInclGst += Number(isNumber(item.total_amt));
        sumGSTAmt += Number(isNumber(item.gst_charge));
      }
    });

    if (getPoNo(orderNo)) {
      return (sumAmtInclGst - sumGSTAmt).toFixed(1);
    }
    return (sumAmtInclGst - sumGSTAmt).toFixed(2);
  };

  const getTotalCGST = (itemDetails) => {
    let sumCGSTAmt = 0;
    itemDetails.forEach((item) => {
      if (item.hasOwnProperty("gst_per")) {
        sumCGSTAmt += item.modified_gst_charge
          ? Number(item.modified_gst_charge)
          : 0;
      } else {
        sumCGSTAmt += item.gst_charge ? Number(item.gst_charge) : 0;
      }
    });
    return isNumber(sumCGSTAmt);
  };
  const getTotalSGST = (itemDetails) => {
    let sumSGSTAmt = 0;
    itemDetails.forEach((item) => {
      if (item.hasOwnProperty("gst_per")) {
        sumSGSTAmt += isNumber(item.modified_gst_charge) / 2;
      } else {
        sumSGSTAmt += isNumber(item.gst_charge) / 2;
      }
    });
    return isNumber(sumSGSTAmt);
  };

  const isAlreadyPaid = (payment_mode, credit_amt) => {
    return (
      payment_mode &&
      ((payment_mode.includes("Credit") && Number(credit_amt) > 0) ||
        payment_mode.includes("RazorPay"))
    );
  };

  const isNumber = (item) => {
    return isNaN(Number(item)) ? "0" : Number(item).toFixed(2);
  };

  const isAmazonNumber = (item) => {
    return isNaN(Number(item)) ? "0" : Number(item).toFixed(1);
  };

  const cleanNumbers = (num) => {
    return num ? Number(num) : 0;
  };

  const filterRouteOrdersByVariety = (selectedVarietyList) => {
    if (selectedVarietyList && selectedVarietyList.length > 0) {
      let varietySet = new Set();

      for (let i = 0; i < selectedVarietyList.length; i++) {
        let variety = selectedVarietyList[i].value;
        if (!varietySet.has(variety)) {
          varietySet.add(variety);
        }
      }

      let filteredData = [];
      for (let i = 0; i < backupBulkOrderDetails.length; i++) {
        let orderitemdetails =
          backupBulkOrderDetails[i]["orderdetails"]["items"];
        let isVarietyPresent = false;
        for (let j = 0; j < orderitemdetails.length; j++) {
          let variety = orderitemdetails[j].variety;
          if (varietySet.has(variety)) {
            isVarietyPresent = true;
          }
        }

        if (isVarietyPresent) {
          filteredData.push(backupBulkOrderDetails[i]);
        }
      }

      setBulkOrderDetails(filteredData);
    } else {
      setBulkOrderDetails(backupBulkOrderDetails);
    }
  };

  const getSavedAmount = (updateditemList) => {
    let savedAmt = 0;

    if (updateditemList.length > 0) {
      for (let updateditem of updateditemList) {
        let mrp = Number(updateditem.total_amt) || 0;
        let market_price = Number(updateditem.market_price) || 0;
        let qty = Number(updateditem.order_qty) || 0;

        savedAmt += market_price * qty - mrp;
      }
    }

    return savedAmt.toFixed(2);
  };

  const getApartmentBlock = (apartment, block) => {
    let result = "";
    apartment = apartment || "";
    result += apartment;

    if (block) {
      result = result + ", ";
      result += block;
    }

    return result;
  };

  const getPoNo = (ordNum) => {
    let ordNumb = ordNum.replace("/", "-");
    return getPoObj[ordNumb] ? getPoObj[ordNumb].PO : "";
  };

  const mapAmazonItem = (itemId) => {
    return getAmazonItemIdMap[itemId] ? getAmazonItemIdMap[itemId].id : "";
  };

  let newItem = false;
  let printConfirm = false;
  let disablePrintIcon = true;


  const bulkOrderPrintUIItemList = ( bulkOrderDetail ,isMHGSTNumber ) => {

 
    let k =   Object
                .keys( bulkOrderDetail["orderdetails"]["items"] )      
                .map((product, index) => {return ({ [bulkOrderDetail["orderdetails"]["items"][product].item_disc] : product})})
                .sort((a, b) => {
                  const keyA = Object.keys(a)[0];
                  const keyB = Object.keys(b)[0];
                  return keyA.localeCompare(keyB);
              })


    let print_sequence = Object.values(k).map((item) =>{return (  Object.values(item)[0] ) })

  

    return (<>
      {
          print_sequence.map((product, index) => (
      <tr key={index}>
        <td
          style={{ padding: "2px", border: "none" }}
        >
          {index + 1}
        </td>
        <td
          style={{
            width: "400px",
            padding: "2px",
            textAlign: "left",
            border: "none",
            fontSize: "16px",
          }}
        > 
          {
            bulkOrderDetail["orderdetails"][
              "items"
            ][product].item_disc
          }
        </td>
        {bulkOrderDetail["orderdetails"][
          "shop_name"
        ].includes("Amazon") && (
          <td
            style={{
              width: "200px",
              padding: "2px",
              border: "none",
              fontSize: "16px",
            }}
          >
            {mapAmazonItem(
              bulkOrderDetail["orderdetails"][
                "items"
              ][product].item_id
            )}
          </td>
        )}
        {selectedVarietyFilters.length > 0 && (
          <td
            style={{
              width: "200px",
              padding: "2px",
              border: "none",
              fontSize: "16px",
            }}
          >
            {
              bulkOrderDetail["orderdetails"][
                "items"
              ][product].variety
            }
          </td>
        )}
        <td
          style={{ padding: "2px", border: "none" }}
        >
          {bulkOrderDetail["orderdetails"]["items"][
            product
          ].hsn_code || ""}
        </td>
        <td
          style={{ padding: "2px", border: "none" }}
        > 
          {Number(
            bulkOrderDetail["orderdetails"][
              "items"
            ][product].order_qty
          ).toFixed(1)}
        </td>
        {Number(
          bulkOrderDetail["orderdetails"][
            "retailer_id"
          ]
        ) == 0 && (
          <td
            style={{
              padding: "2px",
              border: "none",
            }}
          >
            {Number(
              bulkOrderDetail["orderdetails"][
                "items"
              ][product].market_price
            ) || 0}
          </td>
        )}
        <td
          style={{ padding: "2px", border: "none" }}
        > 
          {getItemsWeight(
            bulkOrderDetail["orderdetails"][
              "items"
            ][product].weight,
            bulkOrderDetail["orderdetails"][
              "items"
            ][product].item_disc,
            bulkOrderDetail["orderdetails"][
              "items"
            ][product].order_qty
          )}
        </td>
        <td
          style={{ padding: "2px", border: "none" }}
        > 
          {bulkOrderDetail["orderdetails"][
            "shop_name"
          ].includes("Amazon")
            ? bulkOrderDetail["orderdetails"][
                "items"
              ][product].hasOwnProperty("gst_per")
              ? isAmazonNumber(
                  Number(
                    bulkOrderDetail["orderdetails"][
                      "items"
                    ][product].dealer_price
                  )
                )
              : isAmazonNumber(
                  (Number(
                    bulkOrderDetail["orderdetails"][
                      "items"
                    ][product].total_amt
                  ) -
                    Number(
                      bulkOrderDetail[
                        "orderdetails"
                      ]["items"][product].gst_charge
                    )) /
                    Number(
                      bulkOrderDetail[
                        "orderdetails"
                      ]["items"][product].order_qty
                    )
                )
            : bulkOrderDetail["orderdetails"][
                "items"
              ][product].hasOwnProperty("gst_per")
            ? isNumber(
                Number(
                  bulkOrderDetail["orderdetails"][
                    "items"
                  ][product].dealer_price
                )
              )
            : isNumber(
                (Number(
                  bulkOrderDetail["orderdetails"][
                    "items"
                  ][product].total_amt
                ) -
                  Number(
                    bulkOrderDetail["orderdetails"][
                      "items"
                    ][product].gst_charge
                  )) /
                  Number(
                    bulkOrderDetail["orderdetails"][
                      "items"
                    ][product].order_qty
                  )
              )}
        </td>
        <td
          style={{ padding: "2px", border: "none" }}
        > 
          {bulkOrderDetail["orderdetails"]["items"][
            product
          ].hasOwnProperty("gst_per")
            ? isNumber(
                Number(
                  bulkOrderDetail["orderdetails"][
                    "items"
                  ][product].dealer_price
                ) *
                  Number(
                    bulkOrderDetail["orderdetails"][
                      "items"
                    ][product].order_qty
                  )
              )
            : isNumber(
                Number(
                  bulkOrderDetail["orderdetails"][
                    "items"
                  ][product].total_amt
                ) -
                  Number(
                    bulkOrderDetail["orderdetails"][
                      "items"
                    ][product].gst_charge
                  )
              )}
        </td>
        {!isMHGSTNumber && (
          <td
            style={{
              padding: "2px",
              border: "none",
            }}
          >
            {bulkOrderDetail["orderdetails"][
              "items"
            ][product].hasOwnProperty("gst_per")
              ? isNumber(
                  Number(
                    bulkOrderDetail["orderdetails"][
                      "items"
                    ][product].modified_gst_charge
                  )
                )
              : isNumber(
                  Number(
                    bulkOrderDetail["orderdetails"][
                      "items"
                    ][product].gst_charge
                  )
                )}
            {bulkOrderDetail["orderdetails"][
              "items"
            ][product].gst_per &&
              Number(
                bulkOrderDetail["orderdetails"][
                  "items"
                ][product].gst_per > 0
              ) && (
                <small>
                  {"[" +
                    Number(
                      bulkOrderDetail[
                        "orderdetails"
                      ]["items"][product].gst_per
                    ) +
                    "%]"}
                </small>
              )}
          </td>
        )}
        {isMHGSTNumber && (
          <td
            style={{
              padding: "2px",
              border: "none",
            }}
          >
            {bulkOrderDetail["orderdetails"][
              "items"
            ][product].hasOwnProperty("gst_per")
              ? isNumber(
                  Number(
                    bulkOrderDetail["orderdetails"][
                      "items"
                    ][product].modified_gst_charge /
                      2
                  )
                )
              : isNumber(
                  Number(
                    bulkOrderDetail["orderdetails"][
                      "items"
                    ][product].gst_charge / 2
                  )
                )}
            {bulkOrderDetail["orderdetails"][
              "items"
            ][product].gst_per &&
              Number(
                bulkOrderDetail["orderdetails"][
                  "items"
                ][product].gst_per > 0
              ) && (
                <small>
                  {"[" +
                    Number(
                      bulkOrderDetail[
                        "orderdetails"
                      ]["items"][product].gst_per
                    ) /
                      2 +
                    "%]"}
                </small>
              )}
          </td>
        )}
        {isMHGSTNumber && (
          <td
            style={{
              padding: "2px",
              border: "none",
            }}
          >
            {bulkOrderDetail["orderdetails"][
              "items"
            ][product].hasOwnProperty("gst_per")
              ? isNumber(
                  Number(
                    bulkOrderDetail["orderdetails"][
                      "items"
                    ][product].modified_gst_charge /
                      2
                  )
                )
              : isNumber(
                  Number(
                    bulkOrderDetail["orderdetails"][
                      "items"
                    ][product].gst_charge / 2
                  )
                )}
            {bulkOrderDetail["orderdetails"][
              "items"
            ][product].gst_per &&
              Number(
                bulkOrderDetail["orderdetails"][
                  "items"
                ][product].gst_per > 0
              ) && (
                <small>
                  {"[" +
                    Number(
                      bulkOrderDetail[
                        "orderdetails"
                      ]["items"][product].gst_per
                    ) /
                      2 +
                    "%]"}
                </small>
              )}
          </td>
        )}
        <td
          style={{ padding: "2px", border: "none" }}
        >
          {bulkOrderDetail["orderdetails"]["items"][
            product
          ].hasOwnProperty("gst_per")
            ? isNumber(
                Number(
                  bulkOrderDetail["orderdetails"][
                    "items"
                  ][product].calc_total_amt
                )
              )
            : isNumber(
                Number(
                  bulkOrderDetail["orderdetails"][
                    "items"
                  ][product].total_amt
                )
              )}
        </td>
      </tr>
    ))
      }
    </>)
  }

  const bulkOrderPrintUI = () => {
    return (
      <>
        {itemAmountErrorMessage.map((itemError, key) => {
          return (
            <div
              style={{
                display: "flex",
                backgroundColor: "#FF6347",
                padding: "10px",
                margin: "15px",
                borderRadius: "5px",
              }}
            >
              {itemError}
            </div>
          );
        })}

        {gstChargeErrorMessage.map((itemError, key) => {
          return (
            <div
              style={{
                display: "flex",
                backgroundColor: "#FF6347",
                padding: "10px",
                margin: "15px",
                borderRadius: "5px",
              }}
            >
              {itemError}
            </div>
          );
        })}

        {orderAmountErrorMessage.map((orderError, key) => {
          return (
            <div
              style={{
                display: "flex",
                backgroundColor: "#FF6347",
                padding: "10px",
                margin: "15px",
                borderRadius: "5px",
              }}
            >
              {orderError}
            </div>
          );
        })}

        <div ref={dataToPrint}>
          {bulkOrderDetails
            ? bulkOrderDetails.map((bulkOrderDetail, index) => {
                let isMHGSTNumber = true;

                if (bulkOrderDetail.orderdetails?.gst_number) {
                  if (
                    !bulkOrderDetail.orderdetails?.gst_number.startsWith("27")
                  ) {
                    isMHGSTNumber = false;
                  }
                }

                return (
                  <div style={{ pageBreakBefore: "always" }}>
                    <div
                      style={{
                        textAlign: "center",
                        width: "1000px",
                        margin: "auto",
                        border: "2px solid black",
                      }}
                    >
                      <div>
                        <Table>
                          <tr>
                            {bulkOrderDetail["orderdetails"].retailer_id > 0 &&
                              !(
                                bulkOrderDetail["orderdetails"].store_series ==
                                  "CP" ||
                                bulkOrderDetail["orderdetails"].store_series ==
                                  "M" ||
                                bulkOrderDetail["orderdetails"].store_series ==
                                  "F" ||
                                bulkOrderDetail["orderdetails"].store_series ==
                                  "O" ||
                                bulkOrderDetail["orderdetails"].store_series ==
                                  "FD" ||
                                bulkOrderDetail["orderdetails"].store_series ==
                                  "MT"
                              ) && (
                                <td>
                                  <img
                                    src={szlogo}
                                    alt={"superzopLogo"}
                                    style={{
                                      width: "230px",
                                      height: "80px",
                                      margin: "20px",
                                    }}
                                  />
                                </td>
                              )}
                            {(Number(
                              bulkOrderDetail["orderdetails"].customer_id
                            ) > 0 ||
                              bulkOrderDetail["orderdetails"].store_series ==
                                "CP" ||
                              bulkOrderDetail["orderdetails"].store_series ==
                                "M" ||
                              bulkOrderDetail["orderdetails"].store_series ==
                                "F" ||
                              bulkOrderDetail["orderdetails"].store_series ==
                                "O" ||
                              bulkOrderDetail["orderdetails"].store_series ==
                                "FD" ||
                              bulkOrderDetail["orderdetails"].store_series ==
                                "MT") && (
                              <td>
                                <img
                                  src={khetika}
                                  alt={"KhetikaLogo"}
                                  style={{
                                    width: "300px",
                                    height: "100px",
                                    margin: "20px",
                                  }}
                                />
                              </td>
                            )}
                            {bulkOrderDetail["orderdetails"].prime_user ==
                              "Y" && (
                              <td>
                                <img
                                  src={superprimelogo}
                                  alt={"superprimeLogo"}
                                  style={{
                                    width: "150px",
                                    height: "100px",
                                    margin: "20px",
                                  }}
                                />
                              </td>
                            )}
                            {bulkOrderDetail["orderdetails"].is_priority ==
                              "Y" && (
                              <td>
                                <img
                                  src={priorityshipping}
                                  alt={"priorityshipping"}
                                  style={{
                                    width: "200px",
                                    height: "80px",
                                    margin: "20px",
                                  }}
                                />
                              </td>
                            )}
                            {console.log(
                              "debug actual city",
                              bulkOrderDetail["orderdetails"]
                            )}
                            {/* {bulkOrderDetail["orderdetails"].actual_city && bulkOrderDetail["orderdetails"].actual_city !="Goa" ? (
                  <td style={{ width: "400px", textAlign: "left" }}>
                    <b>Retranz Infolabs Private Limited</b> <br />
                    Warehouse No 7, Santosh Warehousing Complex<br />Village Dhansar, Navi Mumbai, <br /> Maharashtra – 410210
                    <br />
                    Phone : 9901044177
                    <br />
                    Email : support@superzop.com
                    <br />
                    GSTIN : 27AAHCR2573F1ZA <br />
                    FSSAI : 11522998000504
                  </td>
                ) : (
                  <td style={{ width: "400px", textAlign: "left" }}>
                    <b>Retranz Infolabs Private Limited</b>
                    <br />
                    3/7693, Cartybon Factory,
                    <br /> Behind Residency Bunglow, Murida Fatorda,
                    Margao, Goa - 403602
                    <br />
                    Phone : 9901044177
                    <br />
                    Email : support@superzop.com
                    <br />
                    GSTIN : 30AAHCR2573F1ZN <br />
                    FSSAI : 11520030000203
                  </td>
                )} */}

                            <td style={{ width: "400px", textAlign: "left" }}>
                              <b>Retranz Infolabs Private Limited</b> <br />
                               {isDelhiWarehouse(bulkOrderDetail["orderdetails"]) ? (
                              <>
                              Khasra No. 241, Main G.T. Karnal Road, Village Budhpur, Bijapur, Extended Lal Dora, Delhi - 110036
                              </>
                              ) : (
                              <>
                            Warehouse No 7, Santosh Warehousing Complex Village Dhansar, Navi Mumbai – 410210
                              </>
                            )}
                             
                              <br />
                              Phone : 9901044177
                              <br />
                              Email : support@superzop.com
                              <br />
                              GSTIN : {isDelhiWarehouse(bulkOrderDetail["orderdetails"]) ? "07AAHCR2573F1ZC" : "27AAHCR2573F1ZA"} <br />
                              FSSAI : 11522998000504
                            </td>
                          </tr>
                        </Table>
                        <div>
                          <div
                            style={{
                              borderTop: "2px solid black",
                              borderBottom: "2px solid black",
                            }}
                          >
                            {Number(
                              bulkOrderDetail["orderdetails"].retailer_id
                            ) > 0 &&
                            bulkOrderDetail["orderdetails"].store_series !=
                              "O" ? (
                              <b style={{ fontSize: "20px" }}>
                                RETAILER TAX INVOICE
                              </b>
                            ) : (
                              <b style={{ fontSize: "20px" }}>TAX INVOICE {bulkOrderDetail["invoicePrintType"]}</b>
                            )}
                          </div>
                          <Table>
                            <tr>
                              <td style={{ textAlign: "left" }}>
                                <div>
                                  {bulkOrderDetail["orderdetails"]
                                    .retailer_id &&
                                  bulkOrderDetail["orderdetails"].retailer_id >
                                    0 ? (
                                    <b>
                                      {
                                        bulkOrderDetail["orderdetails"]
                                          .shop_name
                                      }
                                      <br />
                                    </b>
                                  ) : (
                                    <b>
                                      {
                                        bulkOrderDetail["orderdetails"]
                                          .retailer_name
                                      }
                                      <br />
                                    </b>
                                  )}
                                  {Number(
                                    bulkOrderDetail["orderdetails"].retailer_id
                                  ) > 0
                                    ? bulkOrderDetail["orderdetails"].address1
                                    : bulkOrderDetail["orderdetails"].address1 +
                                      ", " +
                                      getApartmentBlock(
                                        bulkOrderDetail["orderdetails"]
                                          .apartment,
                                        bulkOrderDetail["orderdetails"].block
                                      )}
                                  <br />
                                  {bulkOrderDetail["orderdetails"].address2}
                                  <br />
                                  {Number(
                                    bulkOrderDetail["orderdetails"].retailer_id
                                  ) > 0 && bulkOrderDetail["orderdetails"].area}
                                  <br />
                                  {bulkOrderDetail["orderdetails"]
                                    .actual_city &&
                                    bulkOrderDetail["orderdetails"].actual_city}
                                  -{bulkOrderDetail["orderdetails"].pincode}
                                  <br />✆
                                  {formatPhone(
                                    bulkOrderDetail["orderdetails"].phone,
                                    bulkOrderDetail["orderdetails"]
                                      .secondary_number
                                  )}
                                  <br />
                                  {bulkOrderDetail["orderdetails"].gst_number
                                    ? formatGstNumber(
                                        bulkOrderDetail["orderdetails"]
                                          .gst_number
                                      )
                                    : null}
                                  <br />
                                  {bulkOrderDetail["orderdetails"]
                                    .fssai_document_number
                                    ? formatFssai(
                                        bulkOrderDetail["orderdetails"]
                                          .fssai_document_number
                                      )
                                    : null}
                                  <br />
                                </div>
                              </td>
                              <td style={{ width: "400px", textAlign: "left" }}>
                                <div>
                                  <Table
                                    borderless
                                    style={{ cellSpacing: "0px" }}
                                  >
                                    {getPoNo(
                                      bulkOrderDetail["orderdetails"]
                                        .order_number
                                    ) && (
                                      <tr
                                        style={{
                                          cellSpacing: "0px",
                                          cellPadding: "0px",
                                        }}
                                      >
                                        <td style={{ padding: "0px" }}>
                                          PO No
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "right",
                                            padding: "0px",
                                          }}
                                        >
                                          {getPoNo(
                                            bulkOrderDetail["orderdetails"]
                                              .order_number
                                          )}
                                        </td>
                                      </tr>
                                    )}
                                    {Number(
                                      bulkOrderDetail["orderdetails"]
                                        .retailer_id
                                    ) > 0 ? (
                                      <tr
                                        style={{
                                          cellSpacing: "0px",
                                          cellPadding: "0px",
                                        }}
                                      >
                                        <td style={{ padding: "0px" }}>
                                          Retailer ID
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "right",
                                            padding: "0px",
                                          }}
                                        >
                                          {bulkOrderDetail["orderdetails"]
                                            .retailer_id ||
                                            bulkOrderDetail["orderdetails"]
                                              .customer_id}
                                        </td>
                                      </tr>
                                    ) : (
                                      <tr
                                        style={{
                                          cellSpacing: "0px",
                                          cellPadding: "0px",
                                        }}
                                      >
                                        <td style={{ padding: "0px" }}>
                                          Customer ID
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "right",
                                            padding: "0px",
                                          }}
                                        >
                                          {
                                            bulkOrderDetail["orderdetails"]
                                              .customer_id
                                          }
                                        </td>
                                      </tr>
                                    )}
                                    <tr
                                      style={{
                                        cellSpacing: "0px",
                                        cellPadding: "0px",
                                      }}
                                    >
                                      <td style={{ padding: "0px" }}>
                                        Invoice Number
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "right",
                                          padding: "0px",
                                        }}
                                      >
                                        {
                                          bulkOrderDetail["orderdetails"]
                                            .order_number
                                        }
                                      </td>
                                    </tr>
                                    <tr style={{ cellSpacing: "0px" }}>
                                      <td style={{ padding: "0px" }}>
                                        Invoice Date
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "right",
                                          padding: "0px",
                                        }}
                                      >
                                        {formatDate(finalDeliveryDate)}
                                      </td>
                                    </tr>
                                    <tr style={{ cellSpacing: "0px" }}>
                                      <td style={{ padding: "0px" }}>
                                        Order Date
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "right",
                                          padding: "0px",
                                        }}
                                      >
                                        {formatDate(
                                          bulkOrderDetail["orderdetails"]
                                            .order_date
                                        )}
                                      </td>
                                    </tr>
                                    <tr style={{ cellSpacing: "0px" }}>
                                      <td style={{ padding: "0px" }}>
                                        No of Items
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "right",
                                          padding: "0px",
                                        }}
                                      >
                                        {getTotalItems(
                                          bulkOrderDetail["orderdetails"][
                                            "items"
                                          ]
                                        )}
                                      </td>
                                    </tr>
                                    <tr style={{ cellSpacing: "0px" }}>
                                      <td style={{ padding: "0px" }}>
                                        No of Pcs
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "right",
                                          padding: "0px",
                                        }}
                                      >
                                        {getTotalItemsPcs(
                                          bulkOrderDetail["orderdetails"][
                                            "items"
                                          ]
                                        )}
                                      </td>
                                    </tr>
                                    {bulkOrderDetail["orderdetails"].numbags &&
                                    bulkOrderDetail["orderdetails"].numbags !=
                                      0 ? (
                                      <tr style={{ cellSpacing: "0px" }}>
                                        <td style={{ padding: "0px" }}>
                                          No of Loose Bags
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "right",
                                            padding: "0px",
                                          }}
                                        >
                                          {
                                            bulkOrderDetail["orderdetails"]
                                              .numbags
                                          }
                                        </td>
                                      </tr>
                                    ) : null}
                                    {bulkOrderDetail["orderdetails"]
                                      .bagdetails ? (
                                      <tr style={{ cellSpacing: "0px" }}>
                                        <td style={{ padding: "0px" }}>
                                          Loose Bag Id
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "right",
                                            padding: "0px",
                                          }}
                                        >
                                          {bulkOrderDetail["orderdetails"]
                                            .bagdetails
                                            ? bulkOrderDetail["orderdetails"]
                                                .bagdetails
                                            : ""}
                                        </td>
                                      </tr>
                                    ) : null}
                                    {Number(
                                      bulkOrderDetail["orderdetails"]
                                        .retailer_id
                                    ) > 0 && (
                                      <tr style={{ cellSpacing: "0px" }}>
                                        <td style={{ padding: "0px" }}>
                                          Sales Agent
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "right",
                                            padding: "0px",
                                          }}
                                        >
                                          {
                                            bulkOrderDetail["orderdetails"]
                                              .agent_id
                                          }
                                        </td>
                                      </tr>
                                    )}
                                  </Table>
                                </div>
                              </td>
                            </tr>
                          </Table>
                        </div>
                        <Table>
                          <thead
                            style={{
                              borderTop: "2px solid black",
                              borderBottom: "2px solid black",
                            }}
                          >
                            <tr>
                              <td style={{ paddingRight: "10px" }}>
                                <b>Sl No</b>
                              </td>
                              <td style={{ paddingRight: "10px" }}>
                                <b>Item description</b>
                              </td>
                              {bulkOrderDetail["orderdetails"][
                                "shop_name"
                              ].includes("Amazon") && (
                                <td style={{ paddingRight: "10px" }}>
                                  <b>ASIN</b>
                                </td>
                              )}
                              <td style={{ paddingRight: "10px" }}>
                                <b>HSN Code</b>
                              </td>
                              {selectedVarietyFilters.length > 0 && (
                                <td style={{ paddingRight: "10px" }}>
                                  <b>Variety</b>
                                </td>
                              )}
                              <td style={{ paddingRight: "10px" }}>
                                <b>Qty/No</b>
                              </td>
                              {Number(
                                bulkOrderDetail["orderdetails"]["retailer_id"]
                              ) == 0 && (
                                <td style={{ paddingRight: "10px" }}>
                                  <b>Mrp</b>
                                </td>
                              )}
                              <td style={{ paddingRight: "10px" }}>
                                <b>Total Weight</b>
                              </td>
                              <td style={{ paddingRight: "10px" }}>
                                <b>Dealer Price (per pack)</b>
                              </td>
                              <td style={{ paddingRight: "10px" }}>
                                <b>Amount (Rs.)</b>
                              </td>
                              {!isMHGSTNumber && (
                                <td style={{ paddingRight: "10px" }}>
                                  <b>IGST Amount</b>
                                </td>
                              )}
                              {isMHGSTNumber && (
                                <td style={{ paddingRight: "10px" }}>
                                  <b>CGST Amount</b>
                                </td>
                              )}
                              {isMHGSTNumber && (
                                <td style={{ paddingRight: "10px" }}>
                                  <b>SGST Amount</b>
                                </td>
                              )}
                              <td style={{ paddingRight: "10px" }}>
                                <b>Total Amount</b>
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            {
                            

                       
                            
                            bulkOrderDetail["orderdetails"]["items"]
                              ? bulkOrderPrintUIItemList( bulkOrderDetail , isMHGSTNumber) 
                              : ""
                            }
                          </tbody>
                        </Table>
                        <div style={{ borderTop: "1px solid black" }}>
                          <Table>
                            <tr>
                              <td style={{ textAlign: "center" }}>
                                <div>
                                  <br />
                                  <br />
                                  <i>
                                    I/We hereby certify that the goods mentioned
                                    in the invoice are warranted to be of the
                                    same nature
                                    <br />
                                    Certified that the particulars given above
                                    are true and correct
                                  </i>
                                  <br />
                                  <br />
                                  <b>
                                    <i>
                                      Goods Once Sold Will Not Be Taken Back.
                                    </i>
                                  </b>
                                  <br />
                                  Subject to Mumbai Jurisdiction
                                  <br />
                                  <i>
                                    <b>E&OE</b>
                                  </i>
                                  <br />
                                  <h3>
                                    <Badge pill variant="success">
                                      APMC Paid
                                    </Badge>
                                  </h3>
                                  {Number(
                                    bulkOrderDetail["orderdetails"][
                                      "retailer_id"
                                    ]
                                  ) == 0 && (
                                    <div>
                                      <b>
                                        You have saved Rs{" "}
                                        {getSavedAmount(
                                          bulkOrderDetail["orderdetails"][
                                            "items"
                                          ]
                                        )}{" "}
                                        by ordering on Khetika
                                      </b>
                                    </div>
                                  )}
                                  <br />
                                  <br />
                                  <i>
                                    <b>
                                      *For any queries please contact
                                      support@superzop.com or 8010173173
                                    </b>
                                  </i>
                                  <div style={{ textAlign: "center" }}>
                                    {isAlreadyPaid(
                                      bulkOrderDetail["orderdetails"][
                                        "order_payment_mode"
                                      ],
                                      bulkOrderDetail["orderdetails"][
                                        "credit_amt"
                                      ]
                                    ) ? (
                                      bulkOrderDetail["orderdetails"][
                                        "order_payment_mode"
                                      ] == "Rupifi Credits" ? (
                                        <h1>
                                          <Badge pill variant="primary">
                                            Paid - Rupifi
                                          </Badge>
                                        </h1>
                                      ) : bulkOrderDetail["orderdetails"][
                                          "order_payment_mode"
                                        ] == "Finaleap Credits" ? (
                                        <h1>
                                          <Badge pill variant="primary">
                                            Paid - Finaleap
                                          </Badge>
                                        </h1>
                                      ) : bulkOrderDetail["orderdetails"][
                                          "order_payment_mode"
                                        ] == "Internal_Credit" ? (
                                        <h1>
                                          <Badge pill variant="primary">
                                            Paid - SuperKredit
                                          </Badge>
                                        </h1>
                                      ) : bulkOrderDetail["orderdetails"][
                                          "order_payment_mode"
                                        ] == "RazorPay" ? (
                                        <h1>
                                          <Badge pill variant="primary">
                                            Paid - RazorPay
                                          </Badge>
                                        </h1>
                                      ) : (
                                        ""
                                      )
                                    ) : invoiceChequeMap[
                                        bulkOrderDetail["orderdetails"]
                                          .retailer_id
                                      ] == "Y" ? (
                                      <h1>
                                        <Badge pill variant="primary">
                                          Cheque Allowed
                                        </Badge>
                                      </h1>
                                    ) : (
                                      <h1>
                                        <Badge
                                          style={{ marginBottom: "20px" }}
                                          pill
                                          variant="primary"
                                        >
                                          Cash Only
                                        </Badge>{" "}
                                        <br />
                                        <h6>
                                          We request you to please not handover
                                          cash to the sales officers. Please
                                          transfer the amount only to authorized
                                          online channel communicated to you
                                          with the name of the company Retranz
                                          Infolabs Private Limited.
                                        </h6>
                                        <br />
                                        {bulkOrderDetail.orderdetails
                                          .razorpay_qr_url === "" ? (
                                          <div>
                                            <Image
                                              maw={0}
                                              mx="auto"
                                              height={180}
                                              src={khetika_qr}
                                              alt="image"
                                              style={{
                                                width: "195px",
                                                height: "180px",
                                              }}
                                            />
                                          </div>
                                        ) : (
                                          <div>
                                            <Image
                                              maw={0}
                                              mx="auto"
                                              width={240}
                                              height={160}
                                              src={
                                                bulkOrderDetail.orderdetails
                                                  .razorpay_qr_url
                                              }
                                              alt="image"
                                              style={{
                                                width: "230px",
                                                height: "250px",
                                              }}
                                            />
                                            <a
                                              href={
                                                bulkOrderDetail.orderdetails
                                                  .razorpay_qr_url
                                              }
                                              style={{ fontSize: 15 }}
                                            >
                                              {
                                                bulkOrderDetail.orderdetails
                                                  .razorpay_qr_url
                                              }
                                            </a>
                                          </div>
                                        )}
                                        <br />
                                        {/* <img src={ khetika_qr} alt={"KhetikaQR"} style={{  width: "200px", height: "300px" }} /> */}
                                      </h1>
                                    )}
                                  </div>
                                  <div style={{ textAlign: "center" }}>
                                    {bulkOrderDetail["orderdetails"][
                                      "order_payment_mode"
                                    ] == "Internal_Credit" ||
                                    invoiceChequeMap[
                                      bulkOrderDetail["orderdetails"]
                                        .retailer_id
                                    ] == "Y" ? (
                                      <h1>
                                        <Badge pill variant="primary">
                                          Cheque Date -{" "}
                                          {formatChequeDate(finalDeliveryDate)}
                                        </Badge>
                                      </h1>
                                    ) : null}
                                  </div>
                                  {invoiceSignMap[
                                    bulkOrderDetail["orderdetails"].retailer_id
                                  ] == "Y" ? (
                                    <ul>
                                      <li> </li>
                                      <li>Signature </li>
                                    </ul>
                                  ) : null}
                                </div>
                              </td>
                              <td
                                style={{
                                  width: "400px",
                                  textAlign: "left",
                                  cellSpacing: "0px",
                                }}
                              >
                                <div>
                                  <Table
                                    bordered
                                    style={{ border: "2px solid black" }}
                                  >
                                    <tr style={{ border: "2px solid black" }}>
                                      <td style={{ border: "2px solid black" }}>
                                        Amount (Exl GST)
                                      </td>
                                      <td style={{ border: "2px solid black" }}>
                                        {getTotalExclGST(
                                          bulkOrderDetail["orderdetails"][
                                            "items"
                                          ],
                                          bulkOrderDetail["orderdetails"]
                                            .order_number
                                        )}
                                      </td>
                                    </tr>
                                    {!isMHGSTNumber && (
                                      <tr style={{ border: "2px solid black" }}>
                                        <td
                                          style={{ border: "2px solid black" }}
                                        >
                                          IGST Amount
                                        </td>
                                        <td
                                          style={{ border: "2px solid black" }}
                                        >
                                          {getTotalCGST(
                                            bulkOrderDetail["orderdetails"][
                                              "items"
                                            ]
                                          )}
                                        </td>
                                      </tr>
                                    )}
                                    {isMHGSTNumber && (
                                      <tr style={{ border: "2px solid black" }}>
                                        <td
                                          style={{ border: "2px solid black" }}
                                        >
                                          CGST Amount
                                        </td>
                                        <td
                                          style={{ border: "2px solid black" }}
                                        >
                                          {getTotalSGST(
                                            bulkOrderDetail["orderdetails"][
                                              "items"
                                            ]
                                          )}
                                        </td>
                                      </tr>
                                    )}
                                    {isMHGSTNumber && (
                                      <tr style={{ border: "2px solid black" }}>
                                        <td
                                          style={{ border: "2px solid black" }}
                                        >
                                          SGST Amount
                                        </td>
                                        <td
                                          style={{ border: "2px solid black" }}
                                        >
                                          {getTotalSGST(
                                            bulkOrderDetail["orderdetails"][
                                              "items"
                                            ]
                                          )}
                                        </td>
                                      </tr>
                                    )}
                                    <tr style={{ border: "2px solid black" }}>
                                      <td style={{ border: "2px solid black" }}>
                                        <b>Total Amount</b>
                                      </td>
                                      <td style={{ border: "2px solid black" }}>
                                        {getTotalInclGST(
                                          bulkOrderDetail["orderdetails"][
                                            "items"
                                          ],
                                          bulkOrderDetail["orderdetails"]
                                            .order_number
                                        )}
                                      </td>
                                    </tr>
                                    <tr style={{ border: "2px solid black" }}>
                                      <td style={{ border: "2px solid black" }}>
                                        Discount/Coupon
                                      </td>
                                      <td style={{ border: "2px solid black" }}>
                                        {
                                          bulkOrderDetail["orderdetails"][
                                            "order_disc"
                                          ]
                                        }
                                      </td>
                                    </tr>
                                    <tr style={{ border: "2px solid black" }}>
                                      <td style={{ border: "2px solid black" }}>
                                        SZ Wallet Paid
                                      </td>
                                      <td style={{ border: "2px solid black" }}>
                                        {
                                          bulkOrderDetail["orderdetails"][
                                            "cashback_redeemed"
                                          ]
                                        }
                                      </td>
                                    </tr>
                                    <tr style={{ border: "2px solid black" }}>
                                      <td style={{ border: "2px solid black" }}>
                                        SuperKredit/RazorPay
                                      </td>
                                      <td
                                        style={{ bowrder: "2px solid black" }}
                                      >
                                        {bulkOrderDetail["orderdetails"][
                                          "order_payment_mode"
                                        ] != "Rupifi Credits"
                                          ? bulkOrderDetail["orderdetails"][
                                              "order_payment_mode"
                                            ] == "Internal_Credit"
                                            ? bulkOrderDetail["orderdetails"][
                                                "status"
                                              ] == "Partially-Delivered"
                                              ? bulkOrderDetail["orderdetails"][
                                                  "partiallyDeliveredCreditValue"
                                                ]
                                              : bulkOrderDetail["orderdetails"][
                                                  "status"
                                                ] == "Delivered"
                                              ? bulkOrderDetail["orderdetails"][
                                                  "credit_amt"
                                                ]
                                              : bulkOrderDetail["orderdetails"][
                                                  "partiallyDeliveredCreditValue"
                                                ]
                                            : isAlreadyPaid(
                                                bulkOrderDetail["orderdetails"][
                                                  "order_payment_mode"
                                                ],
                                                bulkOrderDetail["orderdetails"][
                                                  "credit_amt"
                                                ]
                                              )
                                            ? bulkOrderDetail["orderdetails"][
                                                "net_order_amt"
                                              ]
                                            : 0
                                          : 0}
                                      </td>
                                    </tr>
                                    <tr style={{ border: "2px solid black" }}>
                                      <td style={{ border: "2px solid black" }}>
                                        Rupifi/Finaleap
                                      </td>
                                      <td
                                        style={{ bowrder: "2px solid black" }}
                                      >
                                        {bulkOrderDetail["orderdetails"][
                                          "order_payment_mode"
                                        ] == "Rupifi Credits" ||
                                        bulkOrderDetail["orderdetails"][
                                          "order_payment_mode"
                                        ] == "Finaleap Credits"
                                          ? bulkOrderDetail["orderdetails"][
                                              "status"
                                            ] == "Delivered"
                                            ? bulkOrderDetail["orderdetails"][
                                                "credit_amt"
                                              ]
                                            : bulkOrderDetail["orderdetails"][
                                                "partiallyDeliveredCreditValue"
                                              ]
                                          : 0}
                                      </td>
                                    </tr>
                                    <tr style={{ border: "2px solid black" }}>
                                      <td style={{ border: "2px solid black" }}>
                                        <b>Net Amount Payable</b>
                                      </td>
                                      <td style={{ border: "2px solid black" }}>
                                        <b>
                                          {isAlreadyPaid(
                                            bulkOrderDetail["orderdetails"][
                                              "order_payment_mode"
                                            ],
                                            bulkOrderDetail["orderdetails"][
                                              "credit_amt"
                                            ]
                                          )
                                            ? 0
                                            : Math.round(
                                                Number(
                                                  getTotalInclGST(
                                                    bulkOrderDetail[
                                                      "orderdetails"
                                                    ]["items"],
                                                    bulkOrderDetail[
                                                      "orderdetails"
                                                    ].order_number
                                                  )
                                                ) -
                                                  Number(
                                                    Number(
                                                      bulkOrderDetail[
                                                        "orderdetails"
                                                      ]["order_disc"]
                                                    ) +
                                                      Number(
                                                        bulkOrderDetail[
                                                          "orderdetails"
                                                        ]["cashback_redeemed"]
                                                      )
                                                  )
                                              )}
                                        </b>
                                      </td>
                                    </tr>
                                  </Table>
                                  <br />
                                  <br />
                                  For Retranz Infolabs Private Limited
                                  <br />
                                  <br />
                                  <i>(Authorized Signatory)</i>
                                </div>
                              </td>
                            </tr>
                          </Table>
                          {/* <Table>
                <tr>
                  <td>
                    <img
                      src={footer}
                      alt={"Khetika"}
                      style={{ width: "100%", height: "120px" }}
                    />
                  </td>
                </tr>
              </Table> */}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            : ""}


          {getBackDrop ? <Backdrop parentLoadStatus={getBackDrop} /> : null}
          {newItem && (
            <AddItemModal show={newItem} onHide={() => (newItem = false)} />
          )}
        </div>

      </>
    );
  };

  return (
    <>
      <div>
        <div style={{ float: "right" }}>
          <center>
            <label>Print Quantity</label>
          </center>
          <select
            className="form-control"
            id="numbags"
            onChange={(e) => {
              setNumPrint(e.target.value);
            }}
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
          </select>
          {vehicleOptions.length > 0 && (
            <Select
              options={vehicleOptions}
              placeholder={selectedVehicle ? selectedVehicle : ""}
              title={selectedVehicle ? selectedVehicle : ""}
              onChange={(val) => {
                setSelectedVehicle(val.value);
                setVarietyList([]);
              }}
            />
          )}
          <center>
            <label>Variety</label>
          </center>
          <div style={{ width: "200px" }}>
            <Select
              options={varietyList}
              defaultValue={
                sessionStorage.getItem("varietyList")
                  ? JSON.parse(sessionStorage.getItem("varietyList"))
                  : []
              }
              isMulti
              onChange={(val) => {
                setSelectedVarietyFilters(val || []);
              }}
            />
          </div>
        </div>
        {itemAmountErrorMessage.length == 0 &&
          gstChargeErrorMessage.length == 0 && (
            <ReactToPrint
              trigger={() => (
                <IconButton size={"small"} style={{ float: "right" }}>
                  <FontAwesomeIcon size="3x" icon={faPrint} />
                </IconButton>
              )}
              content={() => {
                return dataToPrint.current;
              }}
              pageStyle={{ margin: "50px", border: "2px" }}
            />
          )}
        {bulkOrderDetails.length > 0 ? (
          bulkOrderPrintUI()
        ) : (
          <div style={{ textAlign: "center", padding: "20px" }}>
            {getBackDrop ? null : "No orders"}
          </div>
        )}
      </div>
      {getBackDrop ? <Backdrop parentLoadStatus={getBackDrop} /> : null}
    </>
  );
};

export default NewBulkOrders;
