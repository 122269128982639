import React from "react";
import { Table } from "react-bootstrap";
import QRCode from "react-qr-code";

const StickerPrint = React.forwardRef((props, ref) => {
  const { printData } = props;

  return (
    <div ref={ref}>
      {printData && Object.keys(printData).map((orderNumber, index) => {
        const totalPieces = printData[orderNumber].reduce((sum, item) => sum + parseInt(item.no_of_pcs, 10), 0);
        const totalBags = printData[orderNumber].reduce((sum, item) => sum + parseInt(item.no_of_bags, 10), 0);

        return (
          <div key={index} style={{ pageBreakAfter: 'always', paddingTop: '50px' }}>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
              <QRCode value={orderNumber} />
            </div>
            <p style={{ textAlign: 'center' }}>{orderNumber}</p>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Item Description</th>
                  <th>No. Of Pcs</th>
                  <th>No. of Bags</th>
                </tr>
              </thead>
              <tbody>
                {printData[orderNumber].map((item, idx) => (
                  <tr key={idx}>
                    <td>{item.item_desc}</td>
                    <td>{item.no_of_pcs}</td>
                    <td>{item.no_of_bags}</td>
                  </tr>
                ))}
                <tr>
                  <td><strong>Total</strong></td>
                  <td><strong>{totalPieces}</strong></td>
                  <td><strong>{totalBags}</strong></td>
                </tr>
              </tbody>
            </Table>
          </div>
        );
      })}
    </div>
  );
});

export default StickerPrint;
