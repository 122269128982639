import React, { useState, useEffect, useRef } from 'react'
import { Table, Form, Alert, Badge } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faTimes, faPlusCircle, faCircle, faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import khetika from '../../assests/images/khetika.png';
import khetika_qr from '../../assests/images/khetika_qr.png';
import footer from '../../assests/images/footer.png';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import ReactToPrint from 'react-to-print';
import Backdrop from '../backdrop/backdrop';
import { toast } from 'react-toastify';
import database from '../../Configs/firebase'
import {support_portal_url} from '../../Configs/mysqlconfig';



const KhetikaInvoice = () => {
    const [itemDetails, setItemDetails] = useState()
    const [updateditemDetails, setUpdatedItemDetails] = useState()
    const [orderdetails, setOrderdetails] = useState("")
    const [getBackDrop, setBackDrop] = useState(false)
    const [totalOrderQty, setTotalOrderQty] = useState("")
    const [amtExclGst, setAmtExclGst] = useState("")
    const [GSTAmt, setGSTAmt] = useState("")
    const [amtInclGst, setAmtInclGst] = useState("")

    const dataToPrint = useRef()
    const itemWeightRef = useRef([])


    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_CENTER,
    });


    let invoiceDate = new Date()
    let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    invoiceDate = `${invoiceDate.getDate()<10?`0${invoiceDate.getDate()}`:invoiceDate.getDate()}-${monthNames[invoiceDate.getMonth()]}-${invoiceDate.getFullYear().toString().substr(2,2)}`

    useEffect(() => {
        if (!sessionStorage.getItem("orderIdToPrint"))
            return;
        const orderNumberSelected = sessionStorage.getItem("orderIdToPrint");
        setBackDrop(true)
        fetch(`${support_portal_url}/api/ordersdetails?ordernumber=${orderNumberSelected}&token=${sessionStorage.getItem("apiToken")}`).then(res => {
            return res.json()
        }).then(data => {
          console.log(data.data); setBackDrop(false);
          if(data['success']==1){
             setItemDetails(data.data.items);
             setOrderdetails(data.data)
          }else{
            let errMessage=data['message']?data['message']:"";
            alert(errMessage);
            if(data['status']==401 || data['status']==403){
              sessionStorage.clear();
              window.location.href="/"
            }
          }

        }).catch(err => alert(err))

    }, [])


    useEffect(() => {
        if (itemDetails) {
            //getting the sum values of items in an order
            let sumOfItems = 0; let sumAmtInclGst = 0; let sumGST = 0;
            let itemsList = {}

            itemDetails.forEach(item => {

                sumAmtInclGst += Number(item.total_amt)
                sumGST += Number(item.gst_charge)
                sumOfItems++

            })
            console.log(sumGST)
            setUpdatedItemDetails(itemDetails)
            setTotalOrderQty(parseInt(sumOfItems))
            setAmtExclGst((sumAmtInclGst - sumGST).toFixed(2))
            setGSTAmt(sumGST)
            setAmtInclGst(sumAmtInclGst.toFixed(2))
        }
    }, [itemDetails])

    const isAlreadyPaid = (payment_mode) => {
        return payment_mode && (payment_mode.includes('Credit') || payment_mode.includes('RazorPay'))
    }

    const isNumber = (item) => {
        return isNaN(Number(item))?"0":Number(item).toFixed(2)
    }

    const getSavedAmount = () => {
      let savedAmt = 0;

      if(updateditemDetails) {
        Object.keys(updateditemDetails).map((key,index) => {
          let total_amt = Number(updateditemDetails[key].total_amt) || 0;
          let mrp = Number(updateditemDetails[key].mrp) || 0;
          let order_qty = Number(updateditemDetails[key].order_qty) || 0;

          savedAmt += ((mrp * order_qty) - total_amt);
        })
      }

      return savedAmt.toFixed(2);
    }

    const getApartmentBlock = (apartment,block) => {
      let result = "";
      apartment = apartment || "";
      result += apartment;

      if(block) {
        result = result + ", "
        result += block;
      }

      return result;
    }

    return (
        <>
            <div style={{ textAlign: "center", width: "1000px", margin: "auto", border: "2px solid black" }}>
                <ReactToPrint
                    trigger={() => <a href="#" style={{ 'float': 'right' }} ><FontAwesomeIcon size="3x" icon={faPrint} /></a>}
                    content={() => { return dataToPrint.current }}
                    pageStyle={{margin:"50px",border:"2px"}}

                />

                <div ref={dataToPrint}>
                    <Table><tr><td><img src={khetika} alt={"KhetikaLogo"} style={{  width: "300px", height: "100px" }} /></td>
                        <td style={{ width: "400px", textAlign: "left" }}><b>Retranz Infolabs Private Limited</b><br />
                          Godown D1, Indian Corporation Compound,<br />Diva - Anjur Village, Bhiwandi – 421302<br />
                Phone:  8010173173<br />
                Mail : support@superzop.com<br />
                GSTIN: 27AAHCR2573F1ZA </td>
                    </tr>
                    </Table>
                    <div><div style={{ borderTop: "2px solid black", borderBottom: "2px solid black", textAlign: "center" }}>
                        <b style={{ fontSize: "20px" }}>CUSTOMER INVOICE</b>
                    </div>
                        <Table>
                            <tr>
                                <td style={{ textAlign: "left" }}><div>
                                    <b>{orderdetails.name}</b><br />
                                    <b>{getApartmentBlock(orderdetails.apartment,orderdetails.block)}</b><br />
                                    <b>{orderdetails.address}</b><br/>
                                    {orderdetails.area}<br />
                                    {orderdetails.city}-{orderdetails.pincode}<br />
                                    <b>{orderdetails.phone}</b><br />
                                </div></td>
                                <td style={{ width: "400px", textAlign: "left" }}><div><Table borderless style={{ cellSpacing: "0px" }}>
                                    <tr style={{ cellSpacing: "0px", cellPadding: "0px" }}>
                                        <td style={{ padding: "0px" }}>Customer ID</td>
                                        <td style={{ textAlign: "right", padding: "0px", }}>{orderdetails.customer_id}</td>
                                    </tr>
                                    <tr style={{ cellSpacing: "0px", cellPadding: "0px" }}>
                                        <td style={{ padding: "0px" }}>Order Date</td>
                                        <td style={{ textAlign: "right", padding: "0px", }}>{orderdetails.order_date}</td>
                                    </tr>
                                    <tr style={{ cellSpacing: "0px", cellPadding: "0px" }}>
                                        <td style={{ padding: "0px" }}>Bill No</td>
                                        <td style={{ textAlign: "right", padding: "0px", }}>{orderdetails.order_number}</td>
                                    </tr>
                                    <tr style={{ cellSpacing: "0px" }}>
                                        <td style={{ padding: "0px" }}>Bill Date</td>
                                        <td style={{ textAlign: "right", padding: "0px", }}>{invoiceDate}</td>
                                    </tr>
                                    <tr style={{ cellSpacing: "0px" }}>
                                        <td style={{ padding: "0px" }}>No of Items</td>
                                        <td style={{ textAlign: "right", padding: "0px", }}>{totalOrderQty}</td>
                                    </tr>
                                </Table>
                                </div>
                                </td>
                            </tr>
                        </Table>
                    </div>
                    <Table>
                        <thead style={{ borderTop: "2px solid black", borderBottom: "2px solid black" }}>
                            <tr>
                                <td style={{paddingRight:"10px"}}><b>Sl No</b></td>
                                <td style={{paddingRight:"10px"}}><b>Item description</b></td>
                                <td style={{paddingRight:"10px"}}><b>Qty/No</b></td>
                                <td style={{paddingRight:"10px"}}><b>Mrp</b></td>
                                <td style={{paddingRight:"10px"}}><b>Sub Total excl GST(in Rs.)</b></td>
                                <td style={{paddingRight:"10px"}}><b>GST (in Rs.)</b></td>
                                <td style={{paddingRight:"10px"}}><b>Total Amount(in Rs.)</b></td>
                            </tr>
                        </thead>
                        <tbody style={{padding:"0"}}>
                            {(updateditemDetails) ? Object.keys(updateditemDetails).map((items, index) => (
                                <tr >
                                    <td style={{padding:"2px",border:"none"}}>{index + 1}</td>
                                    <td style={{width:"400px",padding:"2px",textAlign:"left",border:"none",fontSize:"16px"}}>{updateditemDetails[items].item_disc}</td>
                                    <td style={{padding:"2px",border:"none"}}>{updateditemDetails[items].order_qty}</td>
                                    <td style={{padding:"2px",border:"none"}}>{Number(updateditemDetails[items].mrp) || 0}</td>
                                    <td style={{padding:"2px",border:"none"}}>{((Number(updateditemDetails[items].total_amt) - (Number(updateditemDetails[items].gst_charge))).toFixed(2))>0?((Number(updateditemDetails[items].total_amt) - (Number(updateditemDetails[items].gst_charge))).toFixed(2)):""}</td>
                                    <td style={{padding:"2px",border:"none"}}>{Number(updateditemDetails[items].gst_charge).toFixed(2)}</td>
                                    <td style={{padding:"2px",border:"none"}}>{Number(updateditemDetails[items].total_amt).toFixed(2)}</td>
                                </tr>
                            )) : ""}
                        </tbody>
                    </Table>
                    <div style={{ borderTop: "1px solid black" }}>
                        <Table ><tr>
                            <td style={{ textAlign: "left" }}><div>
                                <br /><br />
                                <div ><i>I/We hereby certify that the goods mentioned in the invoice are warranted
                                to be of the same nature<br />
                                Certified that the particulars given above are true and correct</i><br /><br />
                                </div>
                                <div><b>You have saved Rs {getSavedAmount()} by ordering on Khetika</b></div>
                                <br/><br/><br/>
                                <i><b>*For any queries please contact support@superzop.com or 8010173173</b></i>
                                <div style={{textAlign:"center"}}>{(parseInt(Number(orderdetails["credit_amt"])>=0))?<h1><Badge pill variant="primary">Paid online through SuperKredit</Badge></h1>:(parseInt(Number(orderdetails["card_amt"])>=0) || orderdetails["order_payment_mode"] == 'Online pay using RazorPay')?<h1><Badge pill variant="primary">Paid Online Through RazorPay</Badge></h1>:
                                <img src={khetika_qr} alt={"KhetikaQR"} style={{  width: "200px", height: "300px" }} />}
                                </div>
                            </div></td>
                            <td style={{ width: "400px", textAlign: "left", cellSpacing: "0px" }}><div>
                                <Table bordered style={{ border: "2px solid black" }}>
                                    <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black" }}>Amount (Exl GST)</td>
                                        <td style={{ border: "2px solid black" }}>{amtExclGst && amtExclGst}</td>
                                    </tr>
                                    <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black" }}>GST Amount</td>
                                        <td style={{ border: "2px solid black" }}>{GSTAmt && GSTAmt.toFixed(2)}</td>
                                    </tr>

                                    <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black" }}><b>Total Amount</b></td>
                                        <td style={{ border: "2px solid black" }}>{amtInclGst}</td>
                                    </tr>
                                    <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black" }}>Discount/Coupon</td>
                                        <td style={{ border: "2px solid black" }}>{orderdetails["order_disc"]}</td>
                                    </tr>
                                    <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black" }}>Wallet Paid</td>
                                        <td style={{ border: "2px solid black" }}>{orderdetails["cashback_redeemed"]}</td>
                                    </tr>
                                    <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black" }}>Online Payment</td>
                                        <td style={{ border: "2px solid black" }}>{isAlreadyPaid(orderdetails["order_payment_mode"])?orderdetails['net_order_amt']:0}</td>
                                    </tr>
                                    <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black" }}>Net Amount Payable</td>
                                        <td style={{ border: "2px solid black" }}><b>{isAlreadyPaid(orderdetails["order_payment_mode"])?0:Math.round((Number(amtInclGst) - Number(Number(orderdetails["order_disc"]) + Number(orderdetails["cashback_redeemed"]) )).toFixed(2))}</b></td>
                                    </tr>

                                </Table><br /><br />
                                For Retranz Infolabs Private Limited<br /><br />
                                <i>(Authorized Signatory)</i>
                            </div></td>
                        </tr>
                      </Table>
                      <Table>
                        <tr>
                          <td><img src={footer} alt={"Khetika"} style={{  width: "100%", height: "120px" }} /></td>
                        </tr>
                      </Table>
                    </div>
                </div>
            </div>
            {getBackDrop ? (<Backdrop parentLoadStatus={getBackDrop} />) : (null)}
        </>
    )
}

export default KhetikaInvoice
