import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import { Button, Select } from "@mantine/core";

import { ItemInventoryTable } from "./ItemInventoryTable";
import NavBar from "../../Navigation bar/Navbar";
import queryString from "query-string";
import moment from "moment";
import Backdrop from "../../backdrop/backdrop";
import { toast } from "react-toastify";
import {
  fetchItemInventoryMovements,
  fetchPriceDetails,
} from "../../../services/InventoryMovements.services";
import ExportToExcel from "../../InventoryManagementExport/ExportToExcel";
import { warehouseNames } from "../../../Configs/mysqlconfig"

const Header = styled.div`
  display: flex;
  height: 15vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
`;

const PageDetailsBar = styled.div`
  width: 100%;
  height: 5vh;
  text-align: center;
`;

const PageTitle = styled.div`
  font-weight: bolder;
  font-size: 2rem;
  text-align: center;
  margin-bottom: -1rem;
`;

const TitleGradient = styled.span`
  background-image: linear-gradient(to right, blue, cyan);
  -webkit-background-clip: text;
  display: inline-block;
  color: transparent;
`;

const DateTitle = styled.div`
  text-align: center;
`;
const DateSelector = styled(DatePicker)`
  margin-left: 1rem;
  margin-right: 1rem;
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-item: center;
`;

const PageContainer = styled.div`
  width: 100%;
`;

const TableContainer = styled.div`
  width: 100%;
  padding: 1rem;
  flex: 1;
`;

const WarehouseSelectContainer = styled.div`
  margin: 0 1rem;
  align-self: center;
`;

const ExportBtnStyles = styled.div`
  margin-top: 1rem;
`;

const ButtonStyle = styled(Button)`
  margin-right: 1rem;
  margin-top: 1rem;
`;

// const warehouseList = [
//   { value: "All", label: "All" },
//   { value: "Goa", label: "Goa" },
//   { value: "Nashik", label: "Nashik" },
//   { value: "Vashi", label: "Vashi" },
//   { value: "Virar", label: "Virar" },
//   { value: "Mira Road", label: "Mira Road" },
//   { value: "Wadala", label: "Wadala" },
//   { value: "Bhiwandi", label: "Bhiwandi" },
// ];

const mapWarehouseList = (names) => {
  return names.map(name => ({
    value: name,
    label: name
  }));
};

const warehouseList = mapWarehouseList(warehouseNames);

const greenColumnHeaderHighlight = "rgba(0,200,0,.6)";
const redColumnHeaderHighlight = "rgba(200,0,0,.6)";

function getFooterSum(data, field) {
  if (!data.rows || data.rows.length === 0 || !field) {
    return 0;
  }

  let footerSum = data.rows.reduce((sum, row) => {
    return Number(row.values[field]) + sum;
  }, 0);
  return footerSum.toFixed(1);
}

const ItemInventoryMovementDetails = (props) => {
  // console.log("check props", props);
  const [tableData, setTableData] = useState([]);
  const [allWarehouseExportData, setAllWarehouseExportData] = useState({});
  const [dateRangeStart, setDateRangeStart] = useState(new Date());
  const [dateRangeEnd, setDateRangeEnd] = useState(new Date());
  const [warehouse, setWarehouse] = useState("");
  const [itemId, setItemId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [itemDisc, setItemDisc] = useState(false);

  const parentWarehouseColumns = React.useMemo(
    () => [
      {
        Header: "Inventory date",
        Footer: "Total :",
        label: "Inventory date",
        accessor: "date",
        value: "date",
      },

      {
        Header: "Last day closing inventory ",
        label: "Last day closing inventory ",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "last_day_closing_inventory"),
            [data.rows]
          );
          return <>{total}</>;
        },

        accessor: "last_day_closing_inventory",
        value: "last_day_closing_inventory",
      },
      {
        Header: "Inventory After Shipped",
        label: "Inventory After Shipped",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "inventory_after_shipped"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "inventory_after_shipped",
        value: "inventory_after_shipped",
      },
      {
        Header: "Inventory ",
        label: "Inventory ",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "resultant_inventory"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "resultant_inventory",
        value: "resultant_inventory",
      },
      {
        Header: "Inventory in KGs",
        label: "Inventory in KGs",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "resultant_inventory_weight"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "resultant_inventory_weight",
        value: "resultant_inventory_weight",
      },

      {
        Header: "GRN Qty",
        label: "GRN Qty",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "grn_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "grn_qty",
        value: "grn_qty",
        bgColor: greenColumnHeaderHighlight,
      },

      {
        Header: "Stock conversion",
        label: "Stock conversion",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "stock_transfer"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "stock_transfer",
        value: "stock_transfer",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Packaged Qty",
        label: "Packaged Qty",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "converted_stock"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "converted_stock",
        value: "converted_stock",
        bgColor: greenColumnHeaderHighlight,
      },
      {
        Header: "Shipped Qty",
        label: "Shipped Qty",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "shipped_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "shipped_qty",
        value: "shipped_qty",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Delivered Qty",
        label: "Delivered Qty",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "delivered_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "delivered_qty",
        value: "delivered_qty",
      },
      {
        Header: "Sample Qty",
        label: "Sample Qty",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "sample_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "sample_qty",
        value: "sample_qty",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Returned Qty",
        label: "Returned Qty",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "returned_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "returned_qty",
        value: "returned_qty",
        bgColor: greenColumnHeaderHighlight,
      },

      {
        Header: "Customer old returns",
        label: "Customer old returns",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "customer_returns"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "customer_returns",
        value: "customer_returns",
        bgColor: greenColumnHeaderHighlight,
      },
      {
        Header: "Shipped to depo",
        label: "Shipped to depo",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "shipped_to_child"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "shipped_to_child",
        value: "shipped_to_child",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Return from depo",
        label: "Return from depo",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "return_from_child"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "return_from_child",
        value: "return_from_child",
        bgColor: greenColumnHeaderHighlight,
      },

      {
        Header: "Dumped Qty",
        label: "Dumped Qty",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "dumped_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "dumped_qty",
        value: "dumped_qty",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Stock correction",
        label: "Stock correction",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "stock_correction"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "stock_correction",
        value: "stock_correction",
      },
      {
        Header: "Stock discrepancy",
        label: "Stock discrepancy",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "stock_discrepancy"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "stock_discrepancy",
        value: "stock_discrepancy",
      },
      {
        Header: "Stock movement",
        label: "Stock movement",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "stock_movement"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "stock_movement",
        value: "stock_movement",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Inventory Value",
        label: "Inventory Value",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "inventory_value"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "inventory_value",
        value: "inventory_value",
      },
    ],
    []
  );

  const childWarehouseColumns = React.useMemo(
    () => [
      {
        Header: "Inventory date",
        Footer: "Total :",
        label: "Inventory date",
        accessor: "date",
        value: "date",
      },
      {
        Header: "Last day closing inventory ",
        label: "Last day closing inventory ",

        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "last_day_closing_inventory"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "last_day_closing_inventory",
        value: "last_day_closing_inventory",
      },
      {
        Header: "Inventory After Shipped",
        label: "Inventory After Shipped",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "inventory_after_shipped"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "inventory_after_shipped",
        value: "inventory_after_shipped",
      },

      {
        Header: "Inventory",
        label: "Inventory",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "resultant_inventory"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "resultant_inventory",
        value: "resultant_inventory",
      },

      {
        Header: "Inventory in KGs",
        label: "Inventory in KGs",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "resultant_inventory_weight"),

            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "resultant_inventory_weight",
        value: "resultant_inventory_weight",
      },

      {
        Header: "Depo Inward",
        label: "Depo Inward",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "grn_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "grn_qty",
        value: "grn_qty",
        bgColor: greenColumnHeaderHighlight,
      },

      {
        Header: "Shipped Qty",
        label: "Shipped Qty",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "shipped_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "shipped_qty",
        value: "shipped_qty",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Delivered Qty",
        label: "Delivered Qty",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "delivered_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "delivered_qty",
        value: "delivered_qty",
      },

      {
        Header: "Returned Qty",
        label: "Returned Qty",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "returned_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "returned_qty",
        value: "returned_qty",
        bgColor: greenColumnHeaderHighlight,
      },

      {
        Header: "Customer old returns",
        label: "Customer old returns",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "customer_returns"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "customer_returns",
        value: "customer_returns",
        bgColor: greenColumnHeaderHighlight,
      },
      {
        Header: "Shipped to Bhiwandi",
        label: "Shipped to Bhiwandi",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "shipped_to_parent"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "shipped_to_parent",
        value: "shipped_to_parent",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Stock correction",
        label: "Stock correction",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "stock_correction"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "stock_correction",
        value: "stock_correction",
      },
      {
        Header: "Stock discrepancy",
        label: "Stock discrepancy",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "stock_discrepancy"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "stock_discrepancy",
        value: "stock_discrepancy",
      },
      {
        Header: "Stock movement",
        label: "Stock movement",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "stock_movement"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "stock_movement",
        value: "stock_movement",
        bgColor: greenColumnHeaderHighlight,
      },
      {
        Header: "Inventory Value",
        label: "Inventory Value",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "inventory_value"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "inventory_value",
        value: "inventory_value",
      },
    ],
    []
  );

  const goaWarehouseColumns = React.useMemo(
    () => [
      {
        Header: "Inventory date",
        Footer: "Total :",
        label: "Inventory date",
        accessor: "date",
        value: "date",
      },
      {
        Header: "Last day closing inventory ",
        label: "Last day closing inventory ",

        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "last_day_closing_inventory"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "last_day_closing_inventory",
        value: "last_day_closing_inventory",
      },
      {
        Header: "Inventory After Shipped",
        label: "Inventory After Shipped",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "inventory_after_shipped"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "inventory_after_shipped",
        value: "inventory_after_shipped",
      },

      {
        Header: "Inventory",
        label: "Inventory",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "resultant_inventory"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "resultant_inventory",
        value: "resultant_inventory",
      },

      {
        Header: "Inventory in KGs",
        label: "Inventory in KGs",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "resultant_inventory_weight"),

            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "resultant_inventory_weight",
        value: "resultant_inventory_weight",
      },

      {
        Header: "Depo Inward",
        label: "Depo Inward",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "stock_movement"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "stock_movement",
        value: "stock_movement",
        bgColor: greenColumnHeaderHighlight,
      },

      {
        Header: "Shipped Qty",
        label: "Shipped Qty",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "shipped_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "shipped_qty",
        value: "shipped_qty",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Delivered Qty",
        label: "Delivered Qty",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "delivered_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "delivered_qty",
        value: "delivered_qty",
      },

      {
        Header: "Returned Qty",
        label: "Returned Qty",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "returned_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "returned_qty",
        value: "returned_qty",
        bgColor: greenColumnHeaderHighlight,
      },

      {
        Header: "Customer old returns",
        label: "Customer old returns",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "customer_returns"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "customer_returns",
        value: "customer_returns",
        bgColor: greenColumnHeaderHighlight,
      },
      {
        Header: "Stock correction",
        label: "Stock correction",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "stock_correction"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "stock_correction",
        value: "stock_correction",
      },
      {
        Header: "Stock discrepancy",
        label: "Stock discrepancy",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "stock_discrepancy"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "stock_discrepancy",
        value: "stock_discrepancy",
      },
      {
        Header: "Inventory Value",
        label: "Inventory Value",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "inventory_value"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "inventory_value",
        value: "inventory_value",
      },
    ],
    []
  );

  const allWarehouseColumns = React.useMemo(
    () => [
      {
        Header: "Inventory date",
        Footer: "Total",
        label: "Inventory date",
        accessor: "date",
        value: "date",
      },

      {
        Header: "Last day closing inventory",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "last_day_closing_inventory"),
            [data.rows]
          );

          return <>{total}</>;
        },
        label: "Last day closing inventory ",
        accessor: "last_day_closing_inventory",
        value: "last_day_closing_inventory",
      },
      {
        Header: "Inventory After Shipped",
        label: "Inventory After Shipped",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "inventory_after_shipped"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "inventory_after_shipped",
        value: "inventory_after_shipped",
      },
      {
        Header: "Inventory ",
        label: "Inventory ",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "resultant_inventory"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "resultant_inventory",
        value: "resultant_inventory",
      },
      {
        Header: "Inventory in KGs",
        label: "Inventory in KGs",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "resultant_inventory_weight"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "resultant_inventory_weight",
        value: "resultant_inventory_weight",
      },

      {
        Header: "GRN Qty",
        label: "GRN Qty",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "grn_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "grn_qty",
        value: "grn_qty",
        bgColor: greenColumnHeaderHighlight,
      },

      {
        Header: "Depo Inward",
        label: "Depo Inward",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "child_grn"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "child_grn",
        value: "child_grn",
        bgColor: greenColumnHeaderHighlight,
      },

      {
        Header: "Stock conversion",
        label: "Stock conversion",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "stock_transfer"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "stock_transfer",
        value: "stock_transfer",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Packaged Qty",
        label: "Packaged Qty",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "converted_stock"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "converted_stock",
        value: "converted_stock",
        bgColor: greenColumnHeaderHighlight,
      },
      {
        Header: "Shipped Qty",
        label: "Shipped Qty",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "shipped_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "shipped_qty",
        value: "shipped_qty",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Delivered Qty",
        label: "Delivered Qty",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "delivered_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "delivered_qty",
        value: "delivered_qty",
      },
      {
        Header: "Sample Qty",
        label: "Sample Qty",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "sample_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "sample_qty",
        value: "sample_qty",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Returned Qty",
        label: "Returned Qty",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "returned_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "returned_qty",
        value: "returned_qty",
        bgColor: greenColumnHeaderHighlight,
      },

      {
        Header: "Customer old returns",
        label: "Customer old returns",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "customer_returns"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "customer_returns",
        value: "customer_returns",
        bgColor: greenColumnHeaderHighlight,
      },
      {
        Header: "Shipped to Bhiwandi",
        label: "Shipped to Bhiwandi",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "shipped_to_parent"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "shipped_to_parent",
        value: "shipped_to_parent",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Shipped to depo",
        label: "Shipped to depo",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "shipped_to_child"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "shipped_to_child",
        value: "shipped_to_child",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Return from depo",
        label: "Return from depo",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "return_from_child"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "return_from_child",
        value: "return_from_child",
        bgColor: greenColumnHeaderHighlight,
      },

      {
        Header: "Dumped Qty",
        label: "Dumped Qty",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "dumped_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "dumped_qty",
        value: "dumped_qty",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Stock correction",
        label: "Stock correction",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "stock_correction"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "stock_correction",
        value: "stock_correction",
      },
      {
        Header: "Stock discrepancy",
        label: "Stock discrepancy",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "stock_discrepancy"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "stock_discrepancy",
        value: "stock_discrepancy",
      },
      {
        Header: "Stock movement",
        label: "Stock movement",
        Footer: (data) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => getFooterSum(data, "stock_movement"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "stock_movement",
        value: "stock_movement",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Inventory Value",
        label: "Inventory Value",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "inventory_value"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "inventory_value",
        value: "inventory_value",
      },
      {
        Header: "Intransit transfer",
        label: "Intransit transfer",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "intransit_transfer"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "intransit_transfer",
        value: "intransit_transfer",
      },
      {
        Header: "Intransit return",
        label: "Intransit return",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "intransit_return"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "intransit_return",
        value: "intransit_return",
      },
    ],
    []
  );

  function getItemInventoryTableData() {
    setIsLoading(true);

    Promise.all([
      fetchItemInventoryMovements(
        moment(dateRangeStart).format("YYYY-MM-DD"),
        moment(dateRangeEnd).format("YYYY-MM-DD"),
        warehouse,
        itemId
      ),
      fetchPriceDetails(),
    ])

      .then((result) => {
        const itemInventoryMovements = result[0].data;
        const itemPriceDetails = result[1].data;
        // console.log("item inventory details", itemInventoryMovements);

        itemInventoryMovements.forEach((item) => {
          const item_price =
            itemPriceDetails[item.item_id]?.landing_cost || "N/A";
          item.shipped_qty = (item.shipped_qty || 0).toFixed(2);
          item.delivered_qty = (item.delivered_qty || 0).toFixed(2);
          item.customer_returns = (item.customer_returns || 0).toFixed(2);
          item.stock_correction = (item.stock_correction || 0).toFixed(2);
          item.shipped_to_parent = (item.shipped_to_parent || 0).toFixed(2);
          item.shipped_to_child = (item.shipped_to_child || 0).toFixed(2);
          item.returned_qty = (item.returned_qty || 0).toFixed(2);
          item.return_from_child = (item.return_from_child || 0).toFixed(2);
          item.dumped_qty = (item.dumped_qty || 0).toFixed(2);
          item.grn_qty = (item.grn_qty || 0).toFixed(2);
          item.stock_take = (item.stock_take || 0).toFixed(2);
          item.bag_weight_loss = (item.bag_weight_loss || 0).toFixed(2);
          item.stock_transfer = (item.stock_transfer || 0).toFixed(2);
          item.converted_stock = (item.converted_stock || 0).toFixed(2);
          item.wastage = (item.wastage || 0).toFixed(2);
          item.sample_qty = (item.sample_qty || 0).toFixed(2);
          item.inventory_value = "N/A";

          item.last_day_closing_inventory =
            item.last_day_closing_inventory.toFixed(2);
          item.inventory_after_shipped =
            item.inventory_after_shipped.toFixed(2);
          item.resultant_inventory = item.resultant_inventory.toFixed(2);
          item.resultant_inventory_weight = Number(
            item.resultant_inventory_weight
          ).toFixed(2);
          if (item_price !== "N/A") {
            item.inventory_value = Number(
              item.resultant_inventory_weight * item_price
            ).toFixed(2);
          }
        });

        setTableData(itemInventoryMovements);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast(`Error : ${err}`, {
          type: toast.TYPE.ERROR,
        });
      });
  }

  useEffect(() => {
    const { warehouse, item_id, item_disc } = queryString.parse(
      props.location.search
    );
    setItemId(item_id);
    setWarehouse(warehouse);
  }, []);

  useEffect(() => {
    if (warehouse && itemId) getItemInventoryTableData();
  }, [warehouse, itemId]);

  return (
    <>
      <NavBar />
      <br />
      <br />
      <br />
      <PageContainer>
        <Header>
          <PageTitle>
            {" "}
            <TitleGradient>Item</TitleGradient> Inventory Movements
          </PageTitle>
          <DateContainer>
            <DateTitle>Start Date</DateTitle>
            <DateSelector
              selected={dateRangeStart}
              onChange={setDateRangeStart}
              // dateFormat="dd/MM/yyyy"
            />
          </DateContainer>
          <DateContainer>
            <DateTitle>End Date</DateTitle>
            <DateSelector
              selected={dateRangeEnd}
              onChange={setDateRangeEnd}
              // dateFormat="dd/MM/yyyy"
            />
          </DateContainer>
          <WarehouseSelectContainer>
            <Select
              label="Warehouse"
              placeholder="Select"
              value={warehouse}
              data={warehouseList}
              onChange={setWarehouse}
            />
          </WarehouseSelectContainer>

          <ButtonStyle
            variant="gradient"
            gradient={{ from: "indigo", to: "cyan" }}
            onClick={() => getItemInventoryTableData()}
          >
            Fetch Records
          </ButtonStyle>
          <ExportBtnStyles>
            <ExportToExcel
              sheet1={tableData}
              filename={"Item_inventory_movements"}
              sheet1Headers={
                warehouse === "Bhiwandi"
                  ? parentWarehouseColumns
                  : warehouse === "All"
                  ? allWarehouseColumns
                  : warehouse === "Goa"
                  ? goaWarehouseColumns
                  : childWarehouseColumns
              }
              sheet1Name={warehouse}
            />
          </ExportBtnStyles>
        </Header>
        <PageDetailsBar>
          <span style={{ fontWeight: "bold" }}> {itemId}</span> ||
          {tableData[0]?.item_disc ? (
            <span style={{ fontWeight: "bold" }}>
              {`${tableData[0]?.item_disc || ""}, ${
                tableData[0]?.category || ""
              } , ${tableData[0]?.sub_category || ""}`}
            </span>
          ) : (
            " No Data Available "
          )}
          ||
          <span style={{ fontWeight: "bold" }}> {warehouse}</span>
        </PageDetailsBar>
        <TableContainer>
          <ItemInventoryTable
            tableData={tableData}
            warehouse={warehouse}
            parentWarehouseColumns={parentWarehouseColumns}
            childWarehouseColumns={childWarehouseColumns}
            goaWarehouseColumns={goaWarehouseColumns}
            allWarehouseColumns={allWarehouseColumns}
          />
        </TableContainer>
      </PageContainer>
      {isLoading ? <Backdrop parentLoadStatus={isLoading} /> : null}
    </>
  );
};

export default ItemInventoryMovementDetails;
