import React, { useState, useEffect, useRef } from "react";
import { Table, Button, Form,Spinner,Modal } from "react-bootstrap";
import NavBar from "../Navigation bar/Navbar";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactToPrint from "react-to-print";
import StickerPrint from "./StickerPrint";
import axios from "axios";
import { service_url } from "../../Configs/mysqlconfig";

function PalletSticker() {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [loading,setLoading] = useState(true);
 
  const [tableData, setTableData] = useState([]);
  const [printData, setPrintData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const printRef = useRef();


  const fetchData = async (date) => {
    setLoading(true);
    try {
      const formattedDate = moment(date).format("YYYY-MM-DD");
      const response = await axios.get(`${service_url}/api/superzop/admin/getPalletPrintDetails?palletized_date=${formattedDate}`);
      if(response.data.data){
      if(response.data.data.length>0){
          setTableData(response.data.data);
        }else{
            setTableData([]);  
        }}else{
            setTableData([]);
        }

    } catch (error) {
      console.error("Error fetching data:", error);
      setTableData([])
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(selectedDate);
  }, [selectedDate]);


  const fetchPrintData = async (palletId) => {
    try {
      const response = await axios.get(`${service_url}/api/superzop/admin/getPalletsItemsDetails?pallet_id=${palletId}`);
      if(response.data.data){
        setPrintData(response.data.data)
        setShowModal(true);
      }else{
        toast.error(`data not available for the pallet_id  ${palletId}`);
      }
      
    } catch (error) {
        toast.error("pallet item details api request failed");
      console.error("Error fetching print data:", error);
    }
  };

  return (
    <>
      <NavBar />
      <br />
      <br />
      <br />
      <br />
      <div className="container mt-5">
        <div className="row justify-content-center bg-light">
          <div className="col-auto">
            <h2>Pallet Sticker Printing</h2>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-auto">
            <Form.Group controlId="loadingDate">
              <Form.Label>Palletized Date</Form.Label>
              <DatePicker
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                dateFormat="dd/MM/yyyy"
                className="form-control"
              />
            </Form.Group>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            {loading ? (
              <div className="text-center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) :  tableData.length == 0 ? (
                <div className="text-center bg-info">
                  <p>No data available for the selected date.</p>
                </div>
              ) : (
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Sr No</th>
                    <th>PO Number</th>
                    {/* <th>Order Number</th> */}
                    <th>Pallet Id</th>
                    <th>Parent Order Number</th>
                    <th>Retailer Name</th>
                    {/* <th>Order Value</th> */}
                    <th>No. of Items</th>
                    <th>No. Of Bags</th>
                    <th>No. Of Pcs</th>
                    <th>Total Weight</th>
                    <th>Print</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((item, index) => (
                    <tr key={index}>
                      <td>{index+1}</td>
                      <td>{item.po_number}</td>
                      {/* <td>{item.order_number}</td> */}
                      <td>{item.pallet_id}</td>
                      <td>{item.parent_order_number}</td>
                      <td>{item.retailer_name}</td>
                      {/* <td>{item.orderValue}</td> */}
                      <td>{item.no_of_items}</td>
                      <td>{item.no_of_bags}</td>
                      <td>{item.no_of_pcs}</td>
                      <td>{item.total_weight}</td>
                      <td>
                        <Button variant="primary" onClick={() => fetchPrintData(item.pallet_id)}>Print</Button>
                        {/* <Button variant="primary" >Print</Button> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </div>
        </div>
      </div>
      
            {/* sticker print */}
            <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Qr details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {printData && (
              <div ref={printRef}>
                <StickerPrint printData={printData} />
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
            <ReactToPrint
              trigger={() => <Button variant="success">Print</Button>}
              content={() => printRef.current}
            />
          </Modal.Footer>
        </Modal>
    </>
  );
  
}

export default PalletSticker;
