import React, { useEffect, useState } from "react";
import { Modal, Button, Table, Figure } from "react-bootstrap";
import "./DeliverySummary.css";
import { findIfDriverVisitedLocationVicinity } from "./utils";
import correctImg from "../../assests/images/correct.png";
import crossImg from "../../assests/images/cross.png";

const StatusDetailsModal = (props) => {
  const show = props.show;
  const setShow = props.setShow;
  const tableData = props.data;
  const vehicleLocationData = props.vecLocData || {};
  const routeId = props.routeId;
  const status = props.status;
  const showDriverProximityCol = props.showDriverProximityCol || false;
  const [dataToRender, setDataToRender] = useState([]);

  useEffect(() => {
    let data = findIfDriverVisitedLocationVicinity(
      tableData,
      vehicleLocationData
    );
    setDataToRender(data);
  }, [tableData]);

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
      }}
      id="status_details_modal"
      dialogClassName="status_details_modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {routeId} | <span style={{ color: "gray" }}> {status}</span>{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Store Name</th>
              <th>Store ID</th>
              <th>Status</th>
              <th>Area</th>
              <th>Order No</th>
              <th>Order Date</th>
              <th>Order Amt</th>
              <th>Delivered Amt</th>
              {showDriverProximityCol ? (
                <th>Driver visited retailer?</th>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {Object.keys(dataToRender).map((key, index) => {
              return (
                <tr key={index}>
                  <td>{dataToRender[key].shop_name}</td>
                  <td>{dataToRender[key].retailer_id}</td>
                  <td>{dataToRender[key].status}</td>
                  <td>{dataToRender[key].area}</td>
                  <td>{dataToRender[key].order_number}</td>
                  <td>{dataToRender[key].order_date}</td>
                  <td>{dataToRender[key].total_amt}</td>
                  <td>{dataToRender[key].delivered_amt}</td>
                  {showDriverProximityCol ? (
                    <td style={{ textAlign: "center" }}>
                      {dataToRender[key].visited_proximity === true ? (
                        <Figure as="div" style={{margin:'0px'}}>
                          <Figure.Image
                            width={30}
                            height={30}
                            alt="YES"
                            src={correctImg}
                          />
                        </Figure>
                      ) : (
                        <Figure>
                          <Figure.Image
                            width={30}
                            height={30}
                            alt="NO"
                            src={crossImg}
                          />
                        </Figure>
                      )}
                    </td>
                  ) : (
                    ""
                  )}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setShow(false);
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default StatusDetailsModal;
