import React, { Component } from 'react'
import fire from '../Configs/firebase'
import { Map, GoogleApiWrapper,Polyline,Marker,InfoWindow} from 'google-maps-react';
import getShops from './Maps/getShops'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import FooterComponent from './Footer/footer';


const mapStyles = {
    width: '100%',
    height: '80vh'
  };

export class Locations extends Component {
    constructor(props) {
        super(props)
        this.location_json = this.location_json.bind(this)
        this.middleware = this.middleware.bind(this)
        this.setShopData = this.setShopData.bind(this)
        this.state = {
             lock : true,
             json_data : [],
             center:null,
             map:null,
             maps:null,
             showingInfoWindow: false,
             showingInfoWindowOrder: false,
             activeMarker: {},
             selectedPlace: {},
             selectedTime:null,
             timestamp:[],
             shop : [],
             orderLocLat: null,
             orderLocLng:null,
             mapOrderDetails:{},
             blinkingMarker:{}
        }
    }

    componentDidMount(){

       // this.location_json(this.props)
       this.middleware(this.props)

    }



    componentWillReceiveProps(nextProps){

        this.setState({lock:true})
       //this.location_json(nextProps)
        this.middleware(nextProps)

    }

    middleware(propData){
      let c = propData.location_in_session.split('/')
      getShops(c[4],c[1],c[2],c[3],propData,this.setShopData,this.location_json)
      this.displayBlinkingMarker(propData)

    }

    displayBlinkingMarker(vehicleDetails){
      const firebaseDatabaseRef = fire.database().ref();
      let vehicleLatestLocationRef = firebaseDatabaseRef.child(vehicleDetails.location_in_session);

      vehicleLatestLocationRef.orderByChild('time_stamp').limitToLast(1).once('value',(snapshot)=>{
          let vehicledata=snapshot.val();
          let keyy=Object.keys(vehicledata)[0];

          let prevLocation=this.state.blinkingMarker;
          let currLocation=vehicledata[keyy];
          if(Object.keys(prevLocation).length>0){
            console.log('hjvghvccc');
            console.log(prevLocation.lat);
            console.log(currLocation.latitude);
            console.log(prevLocation.lng);
            console.log(currLocation.longitude);
            if(prevLocation.lat!=currLocation.latitude || prevLocation.lng!=currLocation.longitude){
              console.log('hi');
              this.setState({blinkingMarker:{lat:vehicledata[keyy]['latitude'],lng:vehicledata[keyy]['longitude']}});
              setInterval(this.displayBlinkingMarker(vehicleDetails), 180000);
            }
          }else{
            this.setState({blinkingMarker:{lat:vehicledata[keyy]['latitude'],lng:vehicledata[keyy]['longitude']}});
            this.displayBlinkingMarker(vehicleDetails)
            console.log(this.state.blinkingMarker);
          }
      })
    }

    // const interval = setInterval(() => displayBlinkingMarker(), 5000)

    setShopData(data){

      this.setState({shop:data})
      // console.log (this.state.shop)
    }

    generateShopMarkers(){

      let markers = []
      let data = this.state.shop
      let delStatus=''
      let deliveryCode={
        Delivered:`http://maps.google.com/mapfiles/ms/icons/green-dot.png`,
        Cancelled:`http://maps.google.com/mapfiles/ms/icons/blue-dot.png`,
        Shop_closed:`http://maps.google.com/mapfiles/ms/icons/yellow-dot.png`,
        Default:`http://maps.google.com/mapfiles/ms/icons/red-dot.png`

      }
      for(var item in data){
        switch(data[item].del){
          case "Delivered":
            delStatus=deliveryCode.Delivered;
            break;
          case "Cancelled":
            delStatus=deliveryCode.Cancelled;
             break;
          case "Shop_closed":
            delStatus=deliveryCode.Shop_closed;
            break;
          default:
            delStatus=deliveryCode.Default

        }

        const link = 'shop?link='+data[item].link
        // console.log(link)
        markers.push(<Marker key={item}
        icon={{
          url:delStatus,
          scale:200
        }}
        position={{lat:Number(data[item].lat),lng:Number(data[item].lng)}}
        label={`${data[item].priority}`}
        />)
      }
      return markers
    }


    location_json(propData){
            var json_locations = []
            var json_time = []
            const result = JSON.parse(sessionStorage.getItem(propData.location_in_session))
            console.log("Result value is "+propData.location_in_session)
            Object.keys(result).forEach((data)=>{
                 //if not found
               json_locations.push({lat : Number(result[data].latitude),
                lng :Number(result[data].longitude)})

                json_time.push(result[data].time_stamp)
            })
            this.setState({center:json_locations[0]})
            this.setState({json_data:json_locations})
            this.setState({timestamp:json_time})
            this.setState({lock:false})


    }

    getShopLocation(){

    }

    setBounds(props){

        let bounds = new props.maps.LatLngBounds();
        for(var i=0;i<this.state.json_data.length;i++)
        bounds.extend(this.state.json_data[i]);
        return bounds


    }

    displayOrderLocation(shopOrder){
      console.log(shopOrder);
      let maptlatitude=shopOrder.lat;
      let maplongitude=shopOrder.lng;
      if(maptlatitude && maplongitude){
        this.setState({orderLocLat:maptlatitude,orderLocLng:maplongitude,mapOrderDetails:shopOrder});
      }else{
        this.setState({orderLoc:null});
      }
    }

    mapOrdersTableHeader(){
        return (
            <thead className="thead-light">
                <tr>
                    <th>OrderNumber</th>
                </tr>
            </thead>
        );
    }

    mapOrdersTableBody(shop){
      let color_code = {
          'cancelled' : 'red',
          'not visited' : 'blue',
          'hold' : 'blue',
          'shop closed' : 'blue',
          'pending' : 'blue',
          'delivered' : 'green',
          'partially-delivered' : 'yellow',
          'shipped' : 'orange',
          'shipped-modified' : 'purple',
          'default' : 'grey'
      };

      shop.sort(function(a, b) {
        let keyA = a.priority,
          keyB = b.priority;
        // Compare the 2 dates
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });

        if(shop){
            shop = shop.map((shp, index) => {
              let current_order_color= "";
              if(shp.del){
                current_order_color = shp['del'].toLowerCase() in color_code?color_code[shp['del'].toLowerCase()]:color_code['default']
              }
                return (
                    <tr key={index}>
                        <td><FontAwesomeIcon icon={faCircle} style={{'fontSize': '10px','verticalAlign': 'middle','color': current_order_color }}/><a href='#' ref={index} onClick={ (e)=>{ e.preventDefault();e.stopPropagation();
  e.nativeEvent.stopImmediatePropagation();this.displayOrderLocation(shp); e.target.style.backgroundColor="#7FFFD4"; console.log(this); }} > {shp.orderNumber} | {shp.priority}</a></td>
                    </tr>
                );
            });
        }
        return (<><tbody>{shop}</tbody></>);
    }



    generateMapMarkers(){
        //generate markers from json data
        let marker_array = []
        for(let i=1;i<this.state.json_data.length-1;i++)
            {
                marker_array.push(<Marker key={i} position={this.state.json_data[i]}
                    icon={
                        {url: `http://maps.google.com/mapfiles/ms/icons/purple-dot.png`,
                        scale:50
                        }
                      } onClick={this.onMarkerClick} name={this.state.timestamp[i]?this.state.timestamp[i]:"Undefined"}/>)
            }
        return marker_array
    }

    handleOrderMarkerClose(){
      this.setState({showingInfoWindowOrder: false});
    }

    handleDefaultMarkerClose(){
      this.setState({showingInfoWindow: false});
    }

    onMarkerClick = (props, marker, e) =>{
      this.setState({
        selectedPlace: {name:this.getReverseGeoCode(props.position,props.name)},
        activeMarker: marker,
        showingInfoWindow: true
      })
    };

    onOrderLocMarkerClick = (props, marker, e) =>{
      console.log(this.state.mapOrderDetails);
      this.setState({
        activeMarker: marker,
        showingInfoWindowOrder: true
      })
    }

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
        selectedPlace:{name:null},
        selectedTime:null,
      })
    }
  };


getReverseGeoCode(latlng,time){


    var geocoder = new this.props.google.maps.Geocoder;
    geocoder.geocode({location:latlng},(result,status)=>{
        if(result[0])
           this.setState({selectedPlace:{name:result[0].formatted_address}})
           this.setState({selectedTime:time})
    })
}


    render() {
        const {shop,orderLocLat,orderLocLng}=this.state;

        if(this.state.json_data.length>0 && !this.state.lock)
        {
            return (<>
              <div className="row" style = {{ marginTop : 10, marginRight:0}}>
                  <div className="col-md-2" style = {{ marginLeft : 10}}>
                    <div className="table-wrapper" style={{paddingBottom:60}}>
                      <table className="table table-striped">
                        {this.mapOrdersTableHeader()}
                        {this.mapOrdersTableBody(shop)}
                      </table>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <Map
                    google={this.props.google}
                    bounds={this.setBounds(this.props.google)}
                    style={mapStyles}
                    initialCenter={this.state.center}
                    onClick={this.onMapClicked}
                    >
                    {this.generateMapMarkers()}

                    {this.state.shop.length>0?this.generateShopMarkers():null}
                    {/* <Marker position={this.state.json_data[0]}  onClick={this.onMarkerClick}/>
                    <Marker position={this.state.json_data[this.state.json_data.length-1]}/> */}
                    <Marker position={{ lat: this.state.orderLocLat, lng: this.state.orderLocLng}} onClick={this.onOrderLocMarkerClick} />
                    <Marker position={this.state.blinkingMarker} icon={{url: `https://maps.google.com/mapfiles/kml/shapes/library_maps.png`}} />

                    <InfoWindow
                         marker={this.state.activeMarker}
                         visible={this.state.showingInfoWindowOrder} onCloseClick={this.state.handleOrderMarkerClose}>
                             {(this.state.mapOrderDetails)?(<div>
                            <h6>OrderNumber : <a href="/orderdetails" target="_blank" onClick={sessionStorage.setItem("orderIdToPrint",this.state.mapOrderDetails.orderNumber)}>{this.state.mapOrderDetails.orderNumber}</a></h6>
                            <h6>Status : {this.state.mapOrderDetails.del}</h6>
                            <h6>Shop : {this.state.mapOrderDetails.shop}</h6>
                            <h6>Address : {this.state.mapOrderDetails.address}</h6>
                        </div>):(<div><h6>Loading</h6></div>)}

                    </InfoWindow>


                    <InfoWindow
                         marker={this.state.activeMarker}
                         visible={this.state.showingInfoWindow} onCloseClick={this.state.handleDefaultMarkerClose}>
                             {(this.state.selectedPlace.name==null || this.state.selectedTime==null)?(<div><h6>Loading</h6></div>):(<div>
                            <h6>Address : {this.state.selectedPlace.name}</h6>
                            <h6>Time Stamp : {this.state.selectedTime}</h6>
                        </div>)}

                    </InfoWindow>

                        <Polyline path={this.state.json_data} options={{ strokeColor: "red" }} strokeOpacity={0.3}/>
                    </Map>
                  </div>
                </div>

            </>
          )

        }
        else
            return <div>Loading data</div>
    }
}



export default GoogleApiWrapper({
    apiKey: 'AIzaSyBhWjIBx_EM-hyCJlg2zbfoYpyaxmPHmKs'
  })(Locations);
