
import React, { useState,useEffect } from 'react';
import {Label, Button} from 'react-bootstrap'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import{NavBarContainer,LeftContainer,SZLogoContainer, MidContainer,RightContainer} from './wrapper'
import { Link } from 'react-router-dom';
import {useHistory} from 'react-router-dom'


const NavBar= (props)=> {
    let history=useHistory();
   
    const redirect=()=>{
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("selectedDate");
        sessionStorage.removeItem("SessionDropDown");
        sessionStorage.removeItem("SessionPhoneNumber");
        sessionStorage.clear();
        history.push('/login')
    }
    return (
        <NavBarContainer>
        <LeftContainer>
            <Link to='/home'>
            <SZLogoContainer key="0"  title="Reload Home Page" /></Link>
          
        </LeftContainer>
        <MidContainer>
           
          
        </MidContainer>
        <RightContainer>
            <Button variant="success" onClick={redirect}>
                 SignOut</Button>
        </RightContainer>
        </NavBarContainer>

    ) 
   
}

export default NavBar;
